import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import '../../style/App.scss';
import {
  Container,
  Box,
  Button,
  Typography,
  styled,
  SlideProps,
  SliderProps,
  TextField
} from '@mui/material';
import useSWR from 'swr';
import Api from '../../api';
import AutoCompleteInput from '../input/AutoCompleteInput';
import useAuthStore from '../../store/zustand/auth';
import Loader from '../shared/Loader';
import { ROLES } from '../../helpers/roles';
import useNotificationsStore from '../../store/zustand/notifications';

const BoxCentered = styled(Box)<SliderProps>(({ theme }) => ({
  alignItems: 'center',
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(2),
  height: '100%',
  justifyContent: 'center'
}));
interface Props {
  cacheWatchers?: Array<any>;
  id?: string;
  setValues: (values: any) => void;
}

const CustomerWatcherModal: React.FC<Props> = function CustomerWatcherModal({
  id,
  setValues,
  cacheWatchers
}) {
  const { t } = useTranslation();
  const { addNotification } = useNotificationsStore((state) => state);
  const { id: profileId } = useAuthStore((state) => state);
  const [operatorsApi, setOperatorsApi] = useState<boolean>(false);
  const [ownerApi, setOwnerApi] = useState<boolean>(false);
  const [watchersApi, setWatchersApi] = useState<boolean>(false);
  const [addWatcherApi, setAddWatcherApi] = useState<boolean>(false);
  const [removeWatcherApi, setRemoveWatcherApi] = useState<boolean>(false);
  const [roleOperators, setRoleOperators] = useState<any>();
  const [roleOwner, setRoleOwner] = useState<any>();
  const [error, setError] = useState<string>();
  const [valuesAutoComplete, setValuesAutoComplete] = useState<any>();
  const [valuesOwnerAutoComplete, setValuesOwnerAutoComplete] = useState<any>();
  const [loading, setLoading] = useState<boolean>(true);
  const [watchers, setWatchers] = useState<Array<any>>([]);
  const [owner, setOwner] = useState<any>();
  const [targetWatcher, setTargetWatcher] = useState<string>();

  const {
    data: dataOperators,
    error: errorOperator,
    mutate: mutateOperator
  } = useSWR(
    operatorsApi
      ? {
          roles: [ROLES.OPERATIONS],
          url: 'get/users/roles/operations'
        }
      : null,
    Api.UserResocesApi.getUsersByRole
  );
  const {
    data: dataOwner,
    error: errorOwner,
    mutate: mutateOwner
  } = useSWR(
    ownerApi
      ? {
          roles: [ROLES.SALES, ROLES.BUSINESS_DEVELOPMENT],
          url: 'get/users/roles/sales and business'
        }
      : null,
    Api.UserResocesApi.getUsersByRole
  );

  const {
    data: dataWatchers,
    error: errorWatchers,
    mutate: mutateWatchers
  } = useSWR(
    watchersApi
      ? {
          id,
          url: 'get/customer/watchers'
        }
      : null,
    Api.ClientApi.getCustomerWatchers
  );

  const { data: dataAddWatchers, error: errorAddWatchers } = useSWR(
    addWatcherApi
      ? {
          id,
          url: 'get/customer/watchers/add',
          userId: targetWatcher
        }
      : null,
    Api.ClientApi.addCustomerWatcher
  );
  const { data: dataRemoveWatchers, error: errorRemoveWatchers } = useSWR(
    removeWatcherApi
      ? {
          id,
          url: 'get/customer/watchers/remove',
          userId: targetWatcher
        }
      : null,
    Api.ClientApi.removeCustomerWatcher
  );

  // ======================================================================== FUNCTIONS

  const processWatchersFromApi = (dataWatchers: Array<any>) => {
    const watchersToAdd: any = [];
    let ownerToAdd;
    dataWatchers?.forEach((watcher: any) => {
      const { user } = watcher;
      const userName = `${user.firstName} ${user.lastName}`;
      const label = `${userName} (${user.email})`;
      const { userRoles } = user;
      userRoles?.forEach((item: any) => {
        const { role } = item;
        if (
          role?.name === ROLES.BUSINESS_DEVELOPMENT ||
          role?.name === ROLES.SALES
        ) {
          ownerToAdd = {
            id: user.id,
            label,
            name: label,
            role: role.name,
            value: user.id
          };
        } else if (role?.name === ROLES.OPERATIONS) {
          watchersToAdd.push({
            id: user.id,
            label,
            name: label,
            role: role.name,
            value: user.id
          });
        }
      });
    });
    setWatchers(watchersToAdd);
    setOwner(ownerToAdd);
    setOperatorsApi(true);
  };

  const processWatchersFromCache = (dataWatchers: Array<any>) => {
    const watchersToAdd: any = [];
    let ownerToAdd;
    dataWatchers?.forEach((watcher: any) => {
      const { id, label, name, value } = watcher;
      if (watcher.owner) {
        ownerToAdd = { id, label, name, value };
      } else {
        watchersToAdd.push({ id, label, name, value });
      }
    });
    setWatchers(watchersToAdd);
    setOwner(ownerToAdd);
    setOperatorsApi(true);
  };

  useEffect(() => {
    if (errorWatchers) {
      setWatchersApi(false);
      addNotification(t('NOTIFICATION.TOAST.generalError'), 'error');
    }
  }, [errorWatchers]);

  useEffect(() => {
    if (dataAddWatchers) {
      setAddWatcherApi(false);
      addNotification(
        t('NOTIFICATION.TOAST.addedSuccess', ['Watcher']),
        'success'
      );
    }
  }, [dataAddWatchers]);

  useEffect(() => {
    if (dataRemoveWatchers) {
      setRemoveWatcherApi(false);
      addNotification(
        t('NOTIFICATION.TOAST.deleteSuccess', ['Watcher']),
        'success'
      );
    }
  }, [dataRemoveWatchers]);

  useEffect(() => {
    if (errorAddWatchers) {
      setAddWatcherApi(false);
      addNotification(
        t('NOTIFICATION.TOAST.addingError', ['Watcher']),
        'error'
      );
    }
  }, [errorAddWatchers]);

  useEffect(() => {
    if (errorRemoveWatchers) {
      setRemoveWatcherApi(false);
      addNotification(
        t('NOTIFICATION.TOAST.deleteError', ['Watcher']),
        'error'
      );
    }
  }, [errorRemoveWatchers]);

  useEffect(() => {
    if (dataOperators) {
      setOperatorsApi(false);
      setRoleOperators(
        dataOperators.map((item: any) => {
          const userName = `${item.firstName} ${item.lastName}`;
          const userEmail = `${item.email}`;
          const label = `${userName} (${userEmail})`;
          const w: any = {
            id: item.id,
            label,
            name: label,
            value: item.id
          };
          return w;
        })
      );
      setOwnerApi(true);
    }
  }, [dataOperators]);

  useEffect(() => {
    if (dataOwner) {
      setOwnerApi(false);
      setRoleOwner(
        dataOwner.map((item: any) => {
          const userName = `${item.firstName} ${item.lastName}`;
          const userEmail = `${item.email}`;
          const label = `${userName} (${userEmail})`;
          const w: any = {
            id: item.id,
            label,
            name: label,
            value: item.id
          };
          return w;
        })
      );
      if (!id && !cacheWatchers) {
        setLoading(false);
      }
    }
  }, [dataOwner]);

  useEffect(() => {
    if (roleOperators) {
      const valuesToSet: any = [];
      roleOperators.forEach((item: any) => {
        watchers.forEach((watcher: any) => {
          if (item.value === watcher.value) {
            valuesToSet.push(item);
          }
        });
      });
      setValuesAutoComplete(valuesToSet);
    }
  }, [roleOperators]);

  useEffect(() => {
    if (roleOwner) {
      roleOwner.forEach((item: any) => {
        if (item?.id === owner?.id) {
          setValuesOwnerAutoComplete(owner);
        }
      });
      setLoading(false);
    }
  }, [roleOwner]);

  useEffect(() => {
    if (errorOperator) {
      setOperatorsApi(false);
      const message =
        errorOperator?.response?.data?.message || t('ERROR.general');
      setError(message);
    }
  }, [errorOperator]);

  useEffect(() => {
    if (errorOwner) {
      setOwnerApi(false);
      const message = errorOwner?.response?.data?.message || t('ERROR.general');
      setError(message);
    }
  }, [errorOwner]);

  useEffect(() => {
    if (dataWatchers) {
      processWatchersFromApi(dataWatchers);
    }
  }, [dataWatchers]);

  useEffect(() => {
    if (id) {
      // get user watchers
      setWatchersApi(true);
    } else if (cacheWatchers) {
      // watchers added but partner not created yet
      processWatchersFromCache(cacheWatchers);
    } else {
      // watchers NOT added but partner not created yet
      setOperatorsApi(true);
    }

    return () => {
      mutateOperator(undefined, true);
      mutateOwner(undefined, true);
      mutateWatchers(undefined, true);
    };
  }, []);

  return (
    <Container sx={{ width: '100%' }}>
      {error && (
        <BoxCentered>
          <Typography color="error.main" variant="heading_400">
            {t('ERROR.errorTitle')}
          </Typography>
          <Typography variant="primary">{error}</Typography>
        </BoxCentered>
      )}
      {!error && (
        <>
          {loading && <Loader customColor="primary" customSize={36} />}
          {!loading && (
            <Box paddingTop={4} width="100%">
              {loading && <Loader customColor="primary" customSize={24} />}
              {!loading && (
                <Box>
                  <Typography
                    color="primary"
                    fontWeight={500}
                    variant="body_300"
                  >
                    {t('CUSTOMER.owner')}
                  </Typography>
                  {roleOwner && (
                    <AutoCompleteInput
                      onChange={(event: any, newValue: any | null) => {
                        if (id) {
                          if (owner?.id !== newValue?.id) {
                            setTargetWatcher(newValue?.id);
                            setAddWatcherApi(true);
                          }
                        }
                        setValuesOwnerAutoComplete(newValue);
                        setValues([
                          ...valuesAutoComplete,
                          { ...newValue, owner: true }
                        ]);
                      }}
                      options={roleOwner}
                      renderInput={(params) => (
                        <TextField
                          sx={{
                            '.MuiOutlinedInput-notchedOutline': {
                              borderStyle: 'hidden'
                            },
                            borderStyle: 'hidden',
                            fontSize: '15px'
                          }}
                          // eslint-disable-next-line
                          {...params}
                        />
                      )}
                      value={valuesOwnerAutoComplete}
                    />
                  )}
                  <Typography
                    color="primary"
                    fontWeight={500}
                    variant="body_300"
                  >
                    {t('CUSTOMER.operators')}
                  </Typography>
                  {valuesAutoComplete && (
                    <AutoCompleteInput
                      multiple
                      defaultValue={watchers.map((item: any) => {
                        const label = `${item.userName} (${item.userEmail})`;
                        return {
                          label,
                          name: label,
                          value: item.userId
                        };
                      })}
                      onChange={(event: any, newValue: any | null) => {
                        const oldWatchers = valuesAutoComplete?.map(
                          (user: any) => user.id
                        );
                        const newWatchers = newValue?.map(
                          (user: any) => user.id
                        );
                        if (oldWatchers.length > newWatchers.length) {
                          // remove
                          const difference = oldWatchers.filter(
                            (value: string) => !newWatchers.includes(value)
                          );
                          if (id) {
                            setTargetWatcher(difference?.[0]);
                            setRemoveWatcherApi(true);
                          }
                        } else {
                          // add
                          const difference = newWatchers.filter(
                            (value: string) => !oldWatchers.includes(value)
                          );
                          if (id) {
                            setTargetWatcher(difference?.[0]);
                            setAddWatcherApi(true);
                          }
                        }
                        setValuesAutoComplete(newValue);
                        setValues(
                          valuesOwnerAutoComplete
                            ? [
                                { ...valuesOwnerAutoComplete, owner: true },
                                ...newValue
                              ]
                            : newValue
                        );
                      }}
                      options={roleOperators}
                      renderInput={(params) => (
                        <TextField
                          sx={{
                            '.MuiOutlinedInput-notchedOutline': {
                              borderStyle: 'hidden'
                            },
                            borderStyle: 'hidden',
                            fontSize: '15px'
                          }}
                          // eslint-disable-next-line
                          {...params}
                        />
                      )}
                      value={valuesAutoComplete}
                    />
                  )}
                </Box>
              )}
            </Box>
          )}
        </>
      )}
    </Container>
  );
};

CustomerWatcherModal.defaultProps = { cacheWatchers: undefined, id: undefined };

export default CustomerWatcherModal;
