/* eslint-disable react/require-default-props */
import React from 'react';
import dayjs from 'dayjs';
import { Box, Typography, useTheme } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { ServiceTime } from '../../../utils/types/ProjectType';
import { weekDaysIndex } from '../../../helpers/weekDays';

interface Props {
  data: ServiceTime;
}

const sortDays = (days: string[]): string[] => {
  return days
    ?.slice()
    ?.sort((a, b) => (weekDaysIndex[a] ?? 7) - (weekDaysIndex[b] ?? 7));
};

const ServicesSetup: React.FC<Props> = function ServicesSetup({ data }) {
  const { t } = useTranslation();
  const theme = useTheme();
  const sortedDays = sortDays(data?.frequencyDays || []);

  return (
    <Box
      display="flex"
      gap={2}
      justifyContent="space-between"
      padding={2.5}
      style={{
        backgroundColor: theme.palette.background.secondPaper,
        borderTop: '1px solid #C7CDD2'
      }}
      width="100%"
    >
      <Box display="flex" flexDirection="column" gap={3.5}>
        <Box display="flex" gap={0.5}>
          <Typography
            color="secondary.400"
            sx={{ fontSize: 14 }}
            variant="primary-bold"
          >
            {`${t('TOOLTIP.PROJECT.serviceFrequency')}:`}
          </Typography>

          <span style={{ fontSize: 14, fontWeight: 'bold' }}>
            {data.frequency || 'N/A'}
          </span>
        </Box>

        <Box display="flex" gap={0.5}>
          <Typography
            color="secondary.400"
            sx={{ fontSize: 14 }}
            variant="primary-bold"
          >
            {`${t('TOOLTIP.PROJECT.startDate')}:`}
          </Typography>

          <span style={{ fontSize: 14, fontWeight: 'bold' }}>
            {`${dayjs(data.startDate || 'N/A').format('DD-MM-YYYY')}`}
          </span>
        </Box>

        <Box display="flex" gap={0.5}>
          <Typography
            color="secondary.400"
            sx={{ fontSize: 14 }}
            variant="primary-bold"
          >
            {`${t('TOOLTIP.PROJECT.daysOfWeek')}:`}
          </Typography>

          <span style={{ fontSize: 14, fontWeight: 'bold' }}>
            {sortedDays.length > 1 ? sortedDays.join(' - ') : sortedDays}
          </span>
        </Box>
      </Box>

      <Box display="flex" flexDirection="column" gap={3.5}>
        <Box display="flex" gap={0.5}>
          <Typography
            color="secondary.400"
            sx={{ fontSize: 14 }}
            variant="primary-bold"
          >
            {`${t('TOOLTIP.PROJECT.travelTime')}:`}
          </Typography>

          <span style={{ fontSize: 14, fontWeight: 'bold' }}>
            {data.travelTime || 'N/A'}
          </span>
        </Box>

        <Box display="flex" gap={0.5}>
          <Typography
            color="secondary.400"
            sx={{ fontSize: 14 }}
            variant="primary-bold"
          >
            {`${t('TOOLTIP.PROJECT.endDate')}:`}
          </Typography>

          <span style={{ fontSize: 14, fontWeight: 'bold' }}>
            {`${dayjs(data.endDate || 'N/A').format('DD-MM-YYYY')}`}
          </span>
        </Box>
      </Box>
    </Box>
  );
};

export default ServicesSetup;
