/* eslint-disable no-nested-ternary */
/* eslint-disable react/require-default-props */
import React, { useEffect, useState } from 'react';
import {
  Box,
  Button,
  Typography,
  useTheme,
  styled,
  SliderProps,
  Collapse,
  Divider
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { isMobile } from 'react-device-detect';
import RegularInput from '../../../../components/input/RegularInput';
import {
  formatDate,
  getColor,
  getCurrency,
  getFrequencyDays
} from '../../../../utils/general';
import {
  totalValueServiceTime,
  totalValueServiceTimeCost,
  getTotalPerVisit,
  getMarkup,
  parserLeadCalculator,
  getMargin,
  getTotalProject
} from '../../../../utils/calculatorHelper';
import { fullDateParser } from '../../../../utils/widgetHelper';
import { weekDays, weekDaysIndex } from '../../../../helpers/weekDays';
import { secondaryColors, themePalette } from '../../../../theme/themeConfig';
import { ServiceTime } from '../../../../utils/types';

const MainContainer = styled(Box)<SliderProps>(({ theme }) => ({
  width: '100%'
}));

const QuotedContainer = styled(Box)<SliderProps>(({ theme }) => ({
  backgroundColor: themePalette.BACKGROUND,
  border: `1px solid ${secondaryColors.SECONDARY_1}`,
  borderRadius: theme.spacing(1),
  display: 'flex',
  flexDirection: 'column',
  gap: '40px',
  padding: '32px 24px 32px 24px',
  width: '100%'
}));

const BoxRow = styled(Box)<SliderProps>(() => ({
  alignItems: 'center',
  display: 'flex',
  flexDirection: 'column',
  gap: '16px'
}));

const QuotedSummary = styled(Box)<SliderProps>(({ theme }) => ({
  display: 'flex',
  gap: theme.spacing(2),
  justifyContent: 'space-between'
}));

const ButtonsContainer = styled(Box)<SliderProps>(() => ({
  alignItems: 'center',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center'
}));

const CostDetailsContainer = styled(Box)<SliderProps>(({ theme }) => ({
  alignItems: 'center',
  bottom: theme.spacing(0),
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  right: 0,
  width: '336px'
}));

function maskData(value: number) {
  // eslint-disable-next-line no-restricted-globals
  if (typeof value === 'number' && !isNaN(value)) {
    return value.toLocaleString('en-US', {
      maximumFractionDigits: 2,
      minimumFractionDigits: 2
    });
  }
  return 'Value no valid';
}
interface Props {
  clientView?: boolean;
  customerSummary?: boolean;
  deleteServiceTime?: (
    destinationIndex: number,
    serviceTimeIndex: number
  ) => void;
  destinationIndex: number;
  isPrintable?: boolean;
  saveStepper?: ({ next }: any) => void;
  serviceTime: any;
  serviceTimeIndex: number;
  setCurrentServiceTime?: (value: React.SetStateAction<number>) => void;
  setNewMargin: (value: React.SetStateAction<number>) => void;
  setNewMarginCost: (value: React.SetStateAction<number>) => void;
  setOpenDetails?: () => void;
  setServiceIndex?: (value: React.SetStateAction<number>) => void;
  setTotalcostProject: (value: React.SetStateAction<number>) => void;
  setTotalcostProjectCost: (value: React.SetStateAction<number>) => void;
  setTotalcostvisit: (value: React.SetStateAction<number>) => void;
  setValidQuotePrice: (value: React.SetStateAction<boolean>) => void;
  setValues?: (value: any) => void;
  totalcostProject: number;
  totalcostProjectCost: number;
  totalvisit: number;
  type: 'proposal' | 'schedule' | 'services';
  validQuotePrice: boolean;
  values: any;
}

const SummaryService: React.FC<Props> = function SummaryService({
  values,
  setValues,
  serviceTime,
  setNewMargin,
  setNewMarginCost,
  setTotalcostProject,
  setTotalcostProjectCost,
  totalcostProjectCost,
  totalcostProject,
  setTotalcostvisit,
  validQuotePrice,
  setValidQuotePrice,
  destinationIndex,
  serviceTimeIndex,
  deleteServiceTime,
  setServiceIndex,
  setCurrentServiceTime,
  saveStepper,
  setOpenDetails,
  type,
  clientView,
  customerSummary = false,
  isPrintable,
  totalvisit
}) {
  const theme = useTheme();
  const { t } = useTranslation();
  const [open, setOpen] = useState<boolean>(false);

  const travelTime: any =
    serviceTime.travelTime !== undefined ? serviceTime.travelTime : 0.0;

  const buyPrice = clientView ? 0 : serviceTime?.cost?.cost || 0;
  // const sellPrice = values?.profit
  //   ? (parseFloat(values.profit || '0') / 100) * buyPrice + buyPrice
  //   : buyPrice;

  const sellPrice = values?.profit
    ? (parseFloat(values.profit || '0') / 100) * buyPrice + buyPrice
    : buyPrice;

  // const totalBuyPrice = getTotalProject(values, t);

  const markup = getMarkup(buyPrice, sellPrice);

  // ========>>>> TODO NEW CALCULATOR VALUES
  // if (values.projectType !== 'Ad-Hoc') {
  //   const quotedSellPrice =
  //     (markup.percent / 100) * totalBuyPrice + totalBuyPrice;

  //   const percentageOfTotalBuyPrice =
  //     ((totalvisit * sellPrice) / quotedSellPrice) * 100;

  //   sellPrice =
  //     (percentageOfTotalBuyPrice / 100) *
  //     (calculatorResults.pricing.annualPrice / totalvisit);
  // }

  const [quotePrice, setQuotePrice] = useState<number>(
    serviceTime.sellPrice || sellPrice
  );

  const quotedMarkup = getMarkup(buyPrice, quotePrice);

  const getServiceTimeCost = () => {
    setTotalcostProject(
      totalValueServiceTime(
        serviceTime?.availability?.generalInfo?.daysOfServiceCount,
        serviceTime,
        travelTime,
        validQuotePrice,
        quotePrice
      )
    );

    setTotalcostProjectCost(
      totalValueServiceTimeCost(
        serviceTime?.availability?.generalInfo?.daysOfServiceCount,
        serviceTime,
        travelTime
      )
    );
    setTotalcostvisit(
      getTotalPerVisit(
        serviceTime,
        travelTime,
        1 + Number(values?.profit || 0) / 100
      )
    );
    return `${getCurrency(
      validQuotePrice
        ? quotePrice
        : maskData(
            getTotalPerVisit(
              serviceTime,
              travelTime,
              1 + Number(values?.profit || 0) / 100
            )
          )
    )} = ${getCurrency(
      totalValueServiceTime(
        serviceTime?.availability?.generalInfo?.daysOfServiceCount,
        serviceTime,
        travelTime,
        validQuotePrice,
        quotePrice,
        1 + Number(values?.profit || 0) / 100
      )
    )}`;
  };

  const getFrequency = (serviceTime: any) => {
    if (serviceTime.frequency) {
      const formattedStartDate = serviceTime.startDate
        ? new Date(serviceTime.startDate).toLocaleDateString('en-US', {
            day: '2-digit',
            month: '2-digit',
            timeZone: 'UTC',
            year: 'numeric'
          })
        : '';
      const formattedEndDate = serviceTime.endDate
        ? new Date(serviceTime.endDate).toLocaleDateString('en-US', {
            day: '2-digit',
            month: '2-digit',
            timeZone: 'UTC',
            year: 'numeric'
          })
        : '';

      return `${
        formattedStartDate === formattedEndDate ? '' : serviceTime.frequency
      }`;
    }

    return t('LEAD.CREATE.SERVICES.SUMMARY.noData');
  };

  const getServicesName = (serviceTime: any) => {
    if (serviceTime?.services) {
      return serviceTime.services
        .map((service: any) => {
          return `${service.name}`;
        })
        .toString()
        .replaceAll(',', ', ');
    }
    return t('LEAD.CREATE.SERVICES.SUMMARY.noData');
  };
  const getServices = (serviceTime: any) => {
    if (serviceTime?.services) {
      return `${serviceTime.services
        .map((service: any) => {
          return service.name;
        })
        .toString()
        .replaceAll(',', ', ')}`;
    }
    return t('LEAD.CREATE.SERVICES.SUMMARY.noData');
  };

  const getAdhockTasks = (serviceTime: any, type: string) => {
    const { adhocTasks } = serviceTime || {};
    if (type === 'services' && adhocTasks && adhocTasks.tasks) {
      return `${adhocTasks.tasks
        .map((task: any) => {
          return task.name;
        })
        .toString()
        .replaceAll(',', ', ')}`;
    }
    if (type !== 'services' && serviceTime) {
      return `${serviceTime.tasks
        .map((task: any) => {
          return task.name;
        })
        .toString()
        .replaceAll(',', ', ')}`;
    }
    return t('LEAD.CREATE.SERVICES.SUMMARY.noData');
  };

  const updateQuoteValues = (quotePrice: number, markup?: number) => {
    if (values?.destinations && setValues) {
      const destinationsCopy = values.destinations;
      destinationsCopy[destinationIndex].serviceTimes[serviceTimeIndex] = {
        ...destinationsCopy[destinationIndex].serviceTimes[serviceTimeIndex],
        baseCost: buyPrice,
        quoteMargin: getMargin(buyPrice, quotePrice).percent,
        quoteMarkup: markup || getMarkup(buyPrice, quotePrice).percent,
        quotePrice
      };
      setValues({
        ...values,
        destinations: destinationsCopy
      });
    }
  };

  const getIndividualTasks = (serviceTime: ServiceTime, type = 'services') => {
    const { individualTasks } = serviceTime || {};
    if (
      type === 'services' &&
      individualTasks &&
      individualTasks?.tasks &&
      individualTasks?.tasks?.length > 0
    ) {
      return `${individualTasks.tasks
        .map((task) => {
          return task.name;
        })
        .toString()
        .replaceAll(',', ', ')}`;
    }
    return t('LEAD.CREATE.SERVICES.SUMMARY.noData');
  };

  useEffect(() => {
    const margin = Math.floor(Math.random() * 30) + 20;
    const newServiceTime = Array.isArray(values?.destinations)
      ? values?.destinations.map((destination: any) => {
          const randomFloat = Math.random();
          const result = randomFloat * (700 - 300) + 300;
          const visit = Math.floor(Math.random() * 6) + 1;
          const valueMargin = result * (margin / 100);
          const totalMarginInclude = result + valueMargin;
          return {
            ...destination,
            serviceTimes: destination.serviceTimes.map((service: any) => ({
              ...service,
              proposalCost: totalMarginInclude,
              totalCost: result,
              visits: visit
            }))
          };
        })
      : [];

    if (setValues) {
      setValues({
        ...values,
        actualMargin: margin,
        destinations: newServiceTime,
        margin
      });
    }
    updateQuoteValues(sellPrice);
  }, []);

  // eslint-disable-next-line react/no-unstable-nested-components
  function QuotedPrice() {
    return setValues ? (
      <QuotedContainer>
        <Box>
          <Typography variant="body_400">
            {t('LEAD.CREATE.PROPOSAL.serviceSummaryCostperVisit')}
          </Typography>
          <Typography fontWeight={600} variant="body_400">
            {`(${getCurrency(buyPrice)})`}
            {' + '}
          </Typography>
          <Typography variant="body_400">
            {t('LEAD.CREATE.PROPOSAL.serviceSummaryMarkup')}
          </Typography>
          <Typography fontWeight={600} variant="body_400">
            {`(${getCurrency(markup?.amount || 0)})`}
            {' = '}
          </Typography>
          <Typography variant="body_400">
            {t('LEAD.CREATE.PROPOSAL.serviceSummarySuggestedPrice')}
          </Typography>
          <Typography fontWeight={600} variant="body_400">
            {`(${getCurrency(sellPrice)})`}
          </Typography>
        </Box>

        <QuotedSummary>
          <BoxRow alignItems="center" display="flex" flexDirection="column">
            <Typography variant="primary-bold">
              {t('LEAD.CREATE.PROPOSAL.serviceSummaryQuotedperVisit')}
            </Typography>
            {/* <RegularInput
              disabled
              customStyle={{
                width: '170px'
              }}
              onChange={(value: any) => {
                setQuotePrice(value);
                setValidQuotePrice(value !== 0);
                updateQuoteValues(value);
              }}
              type="number"
              value={quotePrice.toFixed(2)}
            /> */}
            <Typography variant="body_400">
              {getCurrency(quotePrice)}
            </Typography>
          </BoxRow>

          <Divider
            flexItem
            orientation="vertical"
            sx={{ borderColor: secondaryColors.SECONDARY_1 }}
          />

          <BoxRow>
            <Typography color="primary.main" variant="heading_300">
              {t('LEAD.CREATE.PROPOSAL.serviceSummaryActualMargin')}
            </Typography>
            <Typography
              color={getColor(markup.amount, quotedMarkup.amount)}
              variant="body_400"
            >
              {getCurrency(quotedMarkup.amount)}
            </Typography>
          </BoxRow>

          <Divider
            flexItem
            orientation="vertical"
            sx={{ borderColor: secondaryColors.SECONDARY_1 }}
          />

          <BoxRow>
            <Typography color="primary.main" variant="heading_300">
              {t('LEAD.CREATE.PROPOSAL.serviceSummaryActualpersent')}
            </Typography>
            <Typography
              color={getColor(markup.amount, quotedMarkup.amount)}
              variant="body_400"
            >
              {`${quotedMarkup.percent}%`}
            </Typography>
          </BoxRow>

          <Divider
            flexItem
            orientation="vertical"
            sx={{ borderColor: secondaryColors.SECONDARY_1 }}
          />

          <BoxRow>
            <Typography color="primary.main" variant="heading_300">
              Total in {totalvisit} visits x {getCurrency(quotePrice)} ={' '}
            </Typography>
            <Typography
              color={getColor(markup.amount, quotedMarkup.amount)}
              variant="body_400"
            >
              {getCurrency(totalvisit * quotePrice)}
            </Typography>
          </BoxRow>
        </QuotedSummary>
      </QuotedContainer>
    ) : null;
  }

  // eslint-disable-next-line react/no-unstable-nested-components
  function ScheduleButtons() {
    return (
      <ButtonsContainer>
        {setCurrentServiceTime &&
        deleteServiceTime &&
        setServiceIndex &&
        saveStepper ? (
          <>
            <Box display="flex" gap={1} justifyContent="space-between" p={1}>
              <Button
                disabled={values?.readOnly}
                onClick={() => setCurrentServiceTime(serviceTimeIndex)}
                sx={{ width: 100 }}
                variant="primary"
              >
                {t('GENERAL.editButton')}
              </Button>
              <Button
                disabled={values?.readOnly}
                onClick={() => {
                  deleteServiceTime(destinationIndex, serviceTimeIndex);
                }}
                sx={{ width: 100 }}
                variant="cancel"
              >
                {t('GENERAL.deleteButton')}
              </Button>
            </Box>
            <Box
              alignItems="center"
              borderRadius={2}
              display="flex"
              height={40}
              justifyContent="center"
              onClick={() => {
                setServiceIndex(serviceTimeIndex);
                saveStepper({ next: true });
              }}
              sx={{
                backgroundColor: serviceTime?.scheduled
                  ? theme.palette.success.main
                  : theme.palette.warning?.main,
                cursor: 'pointer'
              }}
              width={210}
            >
              <Typography color="mainText.100" variant="primary">
                {serviceTime?.scheduled
                  ? t('LEAD.CREATE.SERVICES.SUMMARY.scheduled')
                  : t('LEAD.CREATE.SERVICES.SUMMARY.pending')}
              </Typography>
            </Box>
          </>
        ) : null}
      </ButtonsContainer>
    );
  }

  // eslint-disable-next-line react/no-unstable-nested-components
  function PreviewCostDetails() {
    return (
      <CostDetailsContainer
        sx={{
          position: isMobile || clientView ? 'relative' : 'absolute',
          width: 'fit-content'
        }}
      >
        <Box sx={{ display: 'flex', gap: 1 }}>
          <Typography color="secondary.main" variant="body_200">
            {t('LEAD.CREATE.SERVICES.SUMMARY.pricePerVisit')}
          </Typography>
          <Typography color="mainText.main" fontWeight={600} variant="body_400">
            {getCurrency(
              validQuotePrice
                ? quotePrice
                : maskData(
                    getTotalPerVisit(
                      serviceTime,
                      travelTime,
                      1 + Number(values?.profit || 0) / 100
                    )
                  )
            )}
          </Typography>
        </Box>
      </CostDetailsContainer>
    );
  }

  const getWeekDays = () => {
    const newWeekDays = serviceTime.frequencyDays.map((day: string) => {
      const index = weekDaysIndex[day];
      return weekDays[index].short;
    });
    return newWeekDays.toString().replaceAll(',', ', ');
  };

  const serviceTimeInformation = () => {
    const startDate =
      values.projectType !== 'Ad-Hoc'
        ? t('LEAD.CREATE.startDate')
        : t('LEAD.CREATE.SERVICES.SUMMARY.serviceDay');

    return (
      <Box
        display="grid"
        gridTemplateColumns="190px 170px 110px 1fr"
        sx={{ gridRowGap: '32px' }}
      >
        <Typography color="primary.main" variant="heading_300">
          {startDate}:
        </Typography>

        <Typography color="mainText.300" variant="body_300">
          {formatDate(serviceTime.startDate)}
        </Typography>

        <Typography color="primary.main" variant="heading_300">
          {t('LEAD.CREATE.endDate')}:
        </Typography>

        <Typography color="mainText.300" variant="body_300">
          {formatDate(serviceTime.endDate)}
        </Typography>

        <Typography color="primary.main" variant="heading_300">
          {t('LEAD.CREATE.SERVICES.SelectedDays')}:
        </Typography>

        <Box
          gridColumn="span 3"
          style={{ fontSize: '16px', lineHeight: '20px' }}
        >
          <Typography color="mainText.300" variant="body_300">
            {values.projectType !== 'Ad-Hoc'
              ? getFrequencyDays(serviceTime)
              : serviceTime.startDate}
          </Typography>
        </Box>

        <Box
          gridColumn="span 3"
          style={{ fontSize: '16px', lineHeight: '20px' }}
        >
          <Typography color="primary.main" variant="heading_300">
            {t('LEAD.CREATE.SERVICES.SUMMARY.adhocTasks')}:
          </Typography>
        </Box>
        <Typography color="mainText.300" variant="body_300">
          {getIndividualTasks(serviceTime, 'services')}
        </Typography>
      </Box>
    );
  };

  return (
    <MainContainer
      sx={{
        ...(type === 'proposal' &&
          clientView && {
            border: `1px solid ${theme.palette.divider}`,
            borderRadius: '8px',
            marginBottom: theme.spacing(2),
            padding: theme.spacing(3)
          })
      }}
    >
      <Box
        position="relative"
        sx={{ display: 'flex', flexDirection: 'column' }}
        width="100%"
      >
        <Box
          sx={{
            alignItems: 'center',
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between'
          }}
        >
          <Box
            display="grid"
            gridTemplateColumns="166px 1fr 1fr"
            sx={{ gridColumnGap: '24px' }}
          >
            <Typography color="mainText.main" variant="secondary-semi">
              {values &&
              values.projectType !== 'adhoc' &&
              values.projectType !== 'Ad-Hoc'
                ? `${t('LEAD.CREATE.SERVICES.SUMMARY.serviceTime')} #${
                    serviceTimeIndex + 1
                  }`
                : `Ad-Hoc ${t('LEAD.CREATE.SERVICES.SUMMARY.serviceTime')}`}
            </Typography>

            {!clientView && (
              <Typography color="mainText.300" variant="body_300">
                {getServices(serviceTime)}
              </Typography>
            )}

            {!clientView && (
              <Typography color="mainText.300" variant="body_300">
                {serviceTime.frequency}
              </Typography>
            )}
          </Box>
          {!isPrintable && (
            <Button onClick={() => setOpen(!open)}>
              <i
                className="custom-icon icon-select"
                style={{
                  fontSize: 12,
                  transform: `rotate(${open ? '180deg' : '0deg'})`,
                  transitionDuration: '300ms'
                }}
              />
            </Button>
          )}
        </Box>
        {type === 'schedule' ? ScheduleButtons() : null}
        {type === 'proposal' && clientView && (
          <Box
            display="flex"
            justifyContent="space-between"
            marginTop={2}
            width="100%"
          >
            <Typography color="secondary.main" variant="body_200">
              {`${fullDateParser(serviceTime.startDate)} to ${fullDateParser(
                serviceTime.endDate
              )}`}
            </Typography>

            <Box display="flex">
              <Typography color="secondary.main" variant="body_200">
                {`${getFrequency(serviceTime)}, ${totalvisit} ${t(
                  'LEAD.CREATE.PROPOSAL.visits'
                )}`}
              </Typography>
            </Box>
            {PreviewCostDetails()}
          </Box>
        )}

        <Collapse in={isPrintable || open} orientation="vertical">
          <Box
            display="flex"
            flexDirection="column"
            gap="24px"
            marginTop="24px"
          >
            <Divider
              flexItem
              sx={{ borderColor: secondaryColors.SECONDARY_1 }}
            />

            {!clientView && serviceTimeInformation()}

            {type === 'proposal' && !clientView && QuotedPrice()}

            {clientView && (
              <Box
                display="flex"
                flexDirection="row"
                flexWrap="wrap"
                gap={2}
                justifyContent="space-between"
              >
                <Box
                  display="flex"
                  flexDirection="column"
                  gap={3}
                  justifyContent="space-between"
                >
                  <Typography color="primary.main" variant="body_300">
                    {t('LEAD.CREATE.SERVICES.SUMMARY.days')}
                  </Typography>
                  <Typography color="primary.main" variant="body_300">
                    {t('LEAD.CREATE.SERVICES.SUMMARY.services')}
                  </Typography>
                </Box>

                <Box
                  display="flex"
                  flexDirection="column"
                  gap={3}
                  justifyContent="space-between"
                >
                  <Typography
                    color={secondaryColors.SECONDARY_4}
                    variant="body_300"
                  >
                    {getWeekDays()}
                  </Typography>

                  <Typography
                    color={secondaryColors.SECONDARY_4}
                    sx={{ wordWrap: 'break-word' }}
                    variant="body_300"
                  >
                    {getServicesName(serviceTime)}
                  </Typography>
                </Box>

                {isPrintable ||
                  (customerSummary && (
                    <Box display="flex" margin={isMobile ? '0 auto' : '0'}>
                      <Button
                        onClick={() => {
                          if (setOpenDetails) {
                            setOpenDetails();
                          }
                        }}
                        sx={{ maxHeight: 28 }}
                        variant="text"
                      >
                        {t('LEAD.CREATE.SERVICES.SUMMARY.serviceDetails')}
                      </Button>
                    </Box>
                  ))}
              </Box>
            )}
          </Box>
        </Collapse>
      </Box>
    </MainContainer>
  );
};

SummaryService.defaultProps = {};

export default SummaryService;
