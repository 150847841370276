/* eslint-disable no-underscore-dangle */
/* eslint-disable react/require-default-props */
/* eslint-disable react/jsx-props-no-spreading */
import React, { useEffect, useState } from 'react';
import dayjs, { Dayjs } from 'dayjs';
import {
  Typography,
  Box,
  Checkbox,
  Popover,
  TextField,
  styled,
  Button,
  Container,
  Switch,
  MenuItem,
  TextFieldProps
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { MobileTimePicker } from '@mui/x-date-pickers/MobileTimePicker';
import { DesktopTimePicker } from '@mui/x-date-pickers/DesktopTimePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import useSWR, { useSWRConfig } from 'swr';
import {
  DayCheckBox,
  DayCheckBoxSelected,
  GridBox,
  GridBoxContainer
} from './parts';
import ButtonFormGroup from '../form/ButtonFormGroup';
import { weekDays } from '../../helpers/weekDays';
import api from '../../api';
import { FormLabel, WhiteBoxContainer } from '../form/parts';
import {
  convertUTCToLocalTimeZone,
  timeTimezoneToUTC
} from '../../utils/general';
import SelectInput from '../form/SelectInput';
import useAuthStore from '../../store/zustand/auth';
import { TimeFormatEnum } from '../../api-client/generated';
import {
  secondaryColors,
  themeFont,
  themePalette
} from '../../theme/themeConfig';

const TimePickerFileld = styled(TextField)<TextFieldProps>(() => ({
  '& .MuiOutlinedInput-root': {
    borderRadius: 8
  },
  '& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': {
    border: `1px solid ${secondaryColors.SECONDARY_1}`
  },
  backgroundColor: themePalette.BACKGROUND,
  input: {
    color: themePalette.TITLE,
    fontFamily: themeFont.FONT_SECONDARY,
    fontSize: '16px',
    fontWeight: 400,
    height: 40,
    lineHeight: 1.25,
    paddingBottom: 0,
    paddingLeft: 16,
    paddingTop: 0
  },
  marginTop: '10px',
  paddingLeft: 0,
  paddingRight: 0,
  width: '100%'
}));

const style = {
  fontSize: '14px',
  fontWeight: 'bold',
  padding: '20px'
};

interface Props {
  callBack?: (values: any) => void;
  diabledButton?: boolean;
  enable?: boolean;
  externalFormValue?: any;
  isMobile: boolean;
  profile: any;
  setFormValueExternal?: any;
  setTime?: any;
  type: 'profile' | 'company' | 'myProfile';
}

interface RequestProps {
  requestedAvailableDays: any[];
  requestedLocalTimeEnd: string;
  requestedLocalTimeStart: string;
  requestedTimeEnd: string;
  requestedTimeStart: string;
}

const AvailableDaysForm: React.FC<Props> = function AvailableDaysForm({
  isMobile,
  diabledButton,
  enable,
  setTime,
  profile,
  setFormValueExternal,
  externalFormValue,
  type,
  callBack
}) {
  // ---------------------- Constants ----------------------------
  const { t } = useTranslation();
  const userState = useAuthStore((state) => state);
  const timeFormat = userState?.timeFormat;
  const [updateFlag, setUpdateFlag] = useState<boolean>(false);
  const [requestMsg, setRequestMsg] = useState<boolean>(false);
  const [available24hr, setAvailable24hr] = useState<boolean>(false);
  const [hoursAvailable, setHoursAvailable] = useState<number>(0);
  // API triggers
  const [fireApiGetRequest, setFireApiGetRequest] = useState<boolean>(true);
  const [fireApiCancelRequest, setFireApiCancelRequest] =
    useState<boolean>(false);
  const [fireApiSetRequest, setFireApiSetRequest] = useState<boolean>(false);
  const [error, setError] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>('');
  // Pop over controller
  const label = { inputProps: { 'aria-label': 'Checkbox date' } };
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
    null
  );
  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;
  // State management
  const [userWeekDay, setUserWeekDay] = useState<string[]>(['']);
  const [requestValue, setRequestValue] = useState<any>(undefined);
  const [timeStart, setTimeStart] = React.useState<Dayjs | null>(
    dayjs().hour(8).minute(0)
  );
  const [timeEnd, setTimeEnd] = React.useState<Dayjs | null>(
    dayjs().hour(17).minute(0)
  );
  const [formValue, setFormValue] = useState<RequestProps>({
    requestedAvailableDays: [],
    requestedLocalTimeEnd: '',
    requestedLocalTimeStart: '',
    requestedTimeEnd: '',
    requestedTimeStart: ''
  });
  const [formValueToSendRequest, serFormValueToSendRequest] =
    useState<RequestProps>({
      requestedAvailableDays: [],
      requestedLocalTimeEnd: '',
      requestedLocalTimeStart: '',
      requestedTimeEnd: '',
      requestedTimeStart: ''
    });

  // ------------------------ API ---------------------------
  const { data: dataGetRequest, error: errorDataGetRequest } = useSWR(
    fireApiGetRequest ? 'get/request' : null,
    api.authApi.getRequestChangeTime
  );

  const { data: dataCancelRequest, error: errorDataCancelRequest } = useSWR(
    fireApiCancelRequest ? requestValue?.id : null,
    api.authApi.setCancelRequestChangeTime
  );

  const { data: dataSetRequest, error: errorDataSetRequest } = useSWR(
    fireApiSetRequest ? formValueToSendRequest : null,
    api.authApi.setRequestChangeTime
  );

  const { mutate } = useSWRConfig();
  const clearGetRequest = () => mutate('get/request', null, true);

  // -------------------- Functions -------------------------

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleChange = (value: string) => {
    if (userWeekDay?.includes(value)) {
      setUserWeekDay(userWeekDay.filter((item: string) => item !== value));
      return;
    }
    setUserWeekDay([...userWeekDay, value]);
  };

  const formatTime = (time: Dayjs | null) => {
    if (
      time?.hour().toString().length === 1 &&
      time?.minute().toString().length === 1
    ) {
      return `0${time?.hour()}:0${time?.minute()}`;
    }
    if (time?.hour().toString().length === 1) {
      return `0${time?.hour()}:${time?.minute()}`;
    }
    if (time?.minute().toString().length === 1) {
      return `${time?.hour()}:0${time?.minute()}`;
    }
    return `${time?.hour()}:${time?.minute()}`;
  };

  const calculateEndTime = (startTime: Dayjs | null, hours: number) => {
    if (!startTime) return '';
    const endTime = startTime.add(hours, 'hour');
    if (endTime.isBefore(startTime)) {
      endTime.add(1, 'day');
      endTime.add(hours, 'hour');
    }
    setTimeEnd(endTime);
    return endTime;
  };

  const formatHours = (time: string) => {
    return dayjs(`1/1/1 ${time}`).format(
      timeFormat === TimeFormatEnum._24h ? 'HH:mm' : 'h:mm A'
    );
  };

  const handleSubmit = () => {
    if (!timeStart || hoursAvailable === 0) {
      setError(true);
      setErrorMessage(t('PROFILE.hoursValidations') as unknown as string);
      return;
    }
    if (userWeekDay) {
      const finalDayArray = userWeekDay.filter((item: string) => item !== '');
      if (finalDayArray.length === 0) {
        setError(true);
        setErrorMessage(t('PROFILE.daysValidations') as unknown as string);
        return;
      }
      if (finalDayArray.length === 0) {
        setError(true);
        setErrorMessage(t('PROFILE.daysValidations') as unknown as string);
        return;
      }
      setError(false);
      const newValues = {
        ...formValue,
        requestedAvailableDays: finalDayArray,
        requestedLocalTimeEnd: available24hr ? '00:00' : formatTime(timeEnd),
        requestedLocalTimeStart: available24hr
          ? '00:00'
          : formatTime(timeStart),
        requestedTimeEnd: formatTime(timeEnd),
        requestedTimeStart: formatTime(timeStart)
      };
      const newValuesUTC = {
        ...formValue,
        requestedAvailableDays: finalDayArray,
        requestedLocalTimeEnd: formatTime(timeEnd),
        requestedLocalTimeStart: formatTime(timeStart),
        requestedTimeEnd: timeTimezoneToUTC(
          available24hr ? '23:59' : formatTime(timeEnd),
          profile.timezoneIana
        ),
        requestedTimeStart: timeTimezoneToUTC(
          available24hr ? '00:00' : formatTime(timeStart),
          profile.timezoneIana
        )
      };
      setFormValue(newValues);
      serFormValueToSendRequest(newValuesUTC);
      setTimeout(() => {
        if (callBack) {
          callBack(newValuesUTC);
        } else {
          setFireApiSetRequest(true);
        }
      }, 300);
    }
  };

  const formatDays = (array: any[]) => {
    return array.map((item: string) => item.replace(' ', ''));
  };

  const calculateHoursDifference = (
    startHour: dayjs.Dayjs,
    endHour: dayjs.Dayjs
  ) => {
    if (endHour.isBefore(startHour)) {
      endHour = endHour.add(1, 'day');
    }
    return endHour.diff(startHour, 'hour');
  };

  const clearTimesToActual = () => {
    if (type === 'profile' || type === 'myProfile') {
      if (
        profile &&
        profile.localAvailableTimeStart &&
        profile.localAvailableTimeEnd &&
        profile.availableDays
      ) {
        if (
          profile.localAvailableTimeStart === '00:00' &&
          profile.localAvailableTimeEnd === '00:00'
        ) {
          setAvailable24hr(true);
        }
        const startHour = dayjs()
          .hour(parseFloat(profile.localAvailableTimeStart?.split(':')[0]))
          .minute(parseFloat(profile.localAvailableTimeStart?.split(':')[1]));
        const endHour = dayjs()
          .hour(parseFloat(profile.localAvailableTimeEnd?.split(':')[0]))
          .minute(parseFloat(profile.localAvailableTimeEnd?.split(':')[1]));
        setTimeStart(startHour);
        setTimeEnd(endHour);
        setUserWeekDay(formatDays(profile.availableDays));
        const hoursDifference = calculateHoursDifference(startHour, endHour);
        setHoursAvailable(hoursDifference);
      }
    } else if (
      profile &&
      profile.defaultResourceTimeStart &&
      profile.defaultResourceTimeEnd &&
      profile.defaultAvailableDays
    ) {
      const startHour = dayjs()
        .hour(parseFloat(profile.defaultResourceTimeStart?.split(':')[0]))
        .minute(parseFloat(profile.defaultResourceTimeStart?.split(':')[1]));
      const endHour = dayjs()
        .hour(parseFloat(profile.defaultResourceTimeEnd?.split(':')[0]))
        .minute(parseFloat(profile.defaultResourceTimeEnd?.split(':')[1]));
      setTimeStart(startHour);
      setTimeEnd(endHour);
      setUserWeekDay(formatDays(profile.defaultAvailableDays));
      const hoursDifference = calculateHoursDifference(startHour, endHour);
      setHoursAvailable(hoursDifference);
    }
  };

  // ------------------ useEffects ------------------------

  useEffect(() => {
    if (dataGetRequest) {
      const requestValue = dataGetRequest.filter(
        (ele: any) => ele.status === 'Pending'
      );
      if (requestValue.length === 0) {
        setRequestValue([]);
        setRequestMsg(false);
      } else {
        let value = requestValue[0];
        const requestedTimeStartConvertedToTimeZone = profile.timezoneIana
          ? convertUTCToLocalTimeZone(
              value.requestedTimeStart,
              profile.timezoneIana
            )
          : value.availableTimeStart;
        const requestedTimeEndConvertedToTimeZone = profile.timezoneIana
          ? convertUTCToLocalTimeZone(
              value.requestedTimeEnd,
              profile.timezoneIana
            )
          : value.requestedTimeEnd;
        value = {
          ...value,
          requestedLocalTimeEnd: value.requestedLocalTimeEnd,
          requestedLocalTimeStart: value.requestedLocalTimeStart,
          requestedTimeEnd: requestedTimeEndConvertedToTimeZone,
          requestedTimeStart: requestedTimeStartConvertedToTimeZone
        };
        setRequestValue(value);
        setRequestMsg(true);
      }
      setFireApiGetRequest(false);
    }
  }, [dataGetRequest]);

  useEffect(() => {
    if (dataCancelRequest) {
      clearGetRequest();
      setFireApiGetRequest(true);
      setFireApiCancelRequest(false);
    }
  }, [dataCancelRequest]);

  useEffect(() => {
    if (enable) {
      setUpdateFlag(enable);
    } else {
      setUpdateFlag(false);
    }
  }, [enable]);

  useEffect(() => {
    if (setTime) {
      setTime(formValue);
    }
  }, [dataCancelRequest]);

  useEffect(() => {
    if (diabledButton) {
      setFormValueExternal({
        ...externalFormValue,
        defaultAvailableDays:
          userWeekDay.filter((item: string) => item !== '') ?? [],
        defaultResourceTimeEnd: dayjs(timeEnd).format('HH:mm') ?? '',
        defaultResourceTimeStart: dayjs(timeStart).format('HH:mm') ?? ''
      });
    }
  }, [userWeekDay, timeEnd, timeStart]);

  useEffect(() => {
    if (dataSetRequest) {
      clearGetRequest();
      setFireApiGetRequest(true);
      setFireApiSetRequest(false);
      setError(false);
      if (type === 'myProfile') {
        clearTimesToActual();
      }
    }
  }, [dataSetRequest]);

  useEffect(() => {
    if (errorDataGetRequest || errorDataSetRequest || errorDataCancelRequest) {
      setFireApiGetRequest(false);
      setFireApiSetRequest(false);
      setFireApiCancelRequest(false);
      setError(true);
      if (errorDataSetRequest) {
        const { response } = errorDataSetRequest;
        if (response.status === 401) {
          setErrorMessage(response.statusText);
        }
      }
    }
  }, [errorDataGetRequest, errorDataSetRequest, errorDataCancelRequest]);

  useEffect(() => {
    clearTimesToActual();
  }, [profile]);

  // ------------------ JSX FUNCTIONS -------------------

  const getError = () =>
    !error ? null : (
      <Container sx={{ mt: 1 }}>
        <Typography
          color="alert.main"
          sx={{ fontSize: 14 }}
          variant="primary-bold"
        >
          &bull; {`${errorMessage}`}
        </Typography>
      </Container>
    );

  const getDateField = (
    value: dayjs.Dayjs | null,
    setValue: React.Dispatch<React.SetStateAction<dayjs.Dayjs | null>>
  ) => {
    return isMobile ? (
      <MobileTimePicker
        ampm={timeFormat === TimeFormatEnum._12h}
        disabled={!updateFlag}
        onChange={(newValue) => {
          setValue(newValue);
        }}
        renderInput={(params) => (
          <TimePickerFileld InputProps={{ style }} {...params} />
        )}
        value={value}
      />
    ) : (
      <DesktopTimePicker
        ampm={timeFormat === TimeFormatEnum._12h}
        disabled={!updateFlag}
        onChange={(newValue) => {
          setValue(newValue);
        }}
        renderInput={(params) => (
          <TimePickerFileld InputProps={{ style }} {...params} />
        )}
        value={value}
      />
    );
  };

  return (
    <WhiteBoxContainer isMobile={isMobile} sx={{ marginTop: '24px' }}>
      <Box
        sx={{
          marginBottom: '8px',
          marginTop: '8px'
        }}
      >
        <FormLabel error={false} label={t('PROFILE.availableDays')} />
      </Box>
      <Box sx={{ display: 'flex', width: isMobile ? '80%' : '100%' }}>
        {profile &&
          weekDays.map((ele: any, index) => (
            <Checkbox
              checked={userWeekDay.includes(ele.name)}
              disabled={!updateFlag}
              // eslint-disable-next-line react/no-array-index-key
              key={`${ele.name} - ${index}`}
              sx={{
                borderRadius: '50%',
                height: isMobile ? '40px' : '64px',
                marginTop: isMobile ? '8px' : '0px',
                width: isMobile ? '44px' : '64px'
              }}
              value={ele.name}
              // eslint-disable-next-line react/jsx-props-no-spreading
              {...label}
              checkedIcon={
                <DayCheckBoxSelected isMobile={isMobile}>
                  {ele.abr}
                </DayCheckBoxSelected>
              }
              icon={<DayCheckBox isMobile={isMobile}>{ele.abr}</DayCheckBox>}
              onChange={(value) =>
                handleChange(value.currentTarget.defaultValue)
              }
            />
          ))}
      </Box>
      {/* ------------ Toggle 24hr Availability ------------ */}
      <Box
        sx={{
          alignItems: 'center',
          display: 'flex',
          marginY: '16px'
        }}
      >
        <Typography>{t('PROFILE.available24hr')}</Typography>
        <Switch
          checked={available24hr}
          disabled={!updateFlag}
          onChange={(e) => setAvailable24hr(e.target.checked)}
        />
      </Box>
      {/* ------------ Conditional Fields ------------ */}
      {!available24hr && (
        <GridBoxContainer isMobile={isMobile}>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <GridBox
              isMobile={isMobile}
              sx={{
                paddingLeft: '0px',
                paddingRight: isMobile ? '0px' : '16px'
              }}
            >
              <FormLabel
                error={false}
                label={t('PROFILE.availableTimeStart')}
              />
              <Box sx={{ marginTop: '8px' }}>
                {getDateField(timeStart, setTimeStart)}
              </Box>
            </GridBox>
            <GridBox
              isMobile={isMobile}
              sx={{
                marginBottom: '8px',
                paddingLeft: isMobile ? '0px' : '16px',
                paddingRight: '0px'
              }}
            >
              <FormLabel error={false} label={t('PROFILE.hoursAvailable')} />
              <Box sx={{ marginTop: '2px' }}>
                <SelectInput
                  backgroundColor={themePalette.BACKGROUND}
                  defaultValue={0}
                  disabled={!updateFlag}
                  height="40px"
                  onChange={(e) => {
                    setHoursAvailable(e.target.value as number);
                    calculateEndTime(timeStart, e.target.value as number);
                  }}
                  value={hoursAvailable}
                >
                  {Array.from({ length: 23 }, (_, hour) => hour + 1).map(
                    (hour) => (
                      <MenuItem key={hour} value={hour}>
                        {hour}
                      </MenuItem>
                    )
                  )}
                </SelectInput>
              </Box>
            </GridBox>
            <GridBox
              isMobile={isMobile}
              sx={{ justifyContent: 'flex-start', paddingLeft: '0px' }}
            >
              {getError()}
              {hoursAvailable > 0 && (
                <Box
                  sx={{
                    alignSelf: 'flex-start',
                    marginTop: isMobile ? '52px' : '0px'
                  }}
                >
                  <Typography color="mainText.300" variant="body2">
                    {t('PROFILE.endTimeCalculate', [
                      timeEnd?.format(
                        timeFormat === TimeFormatEnum._24h ? 'HH:mm' : 'h:mm A'
                      )
                    ])}
                  </Typography>
                </Box>
              )}
              {requestMsg && diabledButton !== true ? (
                <>
                  <Box>
                    <Typography
                      color="mainText.300"
                      sx={{ marginTop: '-10px', overflowWrap: 'break-word' }}
                      variant="body2"
                    >
                      {t('PROFILE.requestMessage')}
                      <Typography
                        color="primary.main"
                        onClick={handleClick}
                        sx={{
                          cursor: 'pointer',
                          marginLeft: '10px',
                          textDecoration: 'underline'
                        }}
                        variant="body_200"
                      >
                        {t('PROFILE.requestPop')}
                      </Typography>{' '}
                    </Typography>
                  </Box>
                  {/* Popover with Request Info */}
                  <Popover
                    anchorEl={anchorEl}
                    anchorOrigin={{
                      horizontal: 'center',
                      vertical: 'bottom'
                    }}
                    id={id}
                    onClose={handleClose}
                    open={open}
                    sx={{ margin: '10px' }}
                  >
                    <Box sx={{ padding: '20px' }}>
                      <Typography
                        color="primary.main"
                        sx={{ display: 'flex' }}
                        variant="body2"
                      >
                        {t('PROFILE.timeStart')}
                        <Typography
                          color="primary.500"
                          sx={{ marginLeft: '5px' }}
                          variant="body2"
                        >
                          {formatHours(requestValue?.requestedTimeStart)}
                        </Typography>
                      </Typography>
                      <Typography
                        color="primary.main"
                        sx={{ display: 'flex' }}
                        variant="body2"
                      >
                        {t('PROFILE.timeEnd')}
                        <Typography
                          color="primary.500"
                          sx={{ marginLeft: '5px' }}
                          variant="body2"
                        >
                          {formatHours(requestValue?.requestedTimeEnd)}
                        </Typography>
                      </Typography>
                      <Typography
                        color="primary.main"
                        sx={{ display: 'flex' }}
                        variant="body2"
                      >
                        {t('PROFILE.days')}
                        <Typography
                          color="primary.500"
                          sx={{ marginLeft: '5px' }}
                          variant="body2"
                        >
                          {requestValue?.requestedAvailableDays?.toString()}
                        </Typography>
                      </Typography>
                    </Box>
                  </Popover>
                </>
              ) : null}
            </GridBox>
            {/* ------------ Buttons section ----------- */}
          </LocalizationProvider>
        </GridBoxContainer>
      )}
      {diabledButton ? null : (
        <Box
          sx={{
            display: 'flex',
            justifyContent: updateFlag ? '' : 'flex-end',
            marginTop: 'auto',
            paddingY: isMobile ? '0px' : '8px'
          }}
        >
          {requestMsg ? (
            <Button
              onClick={() => setFireApiCancelRequest(true)}
              sx={{ width: '100%' }}
              variant="destructive"
            >
              {t('PROFILE.cancelRequest')}
            </Button>
          ) : (
            <ButtonFormGroup
              actionButtonMsg={t('PROFILE.saveInformation')}
              cancelAction={() => setUpdateFlag(false)}
              enableEditAction={() => setUpdateFlag(true)}
              enableEditButtonMsg={t('PROFILE.editInformation')}
              isMobile={isMobile}
              mainAction={() => {
                handleSubmit();
                setUpdateFlag(false);
              }}
              updateFlag={updateFlag}
            />
          )}
        </Box>
      )}
    </WhiteBoxContainer>
  );
};

export default AvailableDaysForm;
