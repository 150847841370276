import {
  Box,
  BoxProps,
  Button,
  styled,
  Typography,
  useTheme
} from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';

const FlexBox = styled(Box)<BoxProps>(() => ({
  display: 'flex'
}));

interface WelcomeProps {
  onStart: () => void;
  values: any;
}

function Welcome({ onStart, values }: WelcomeProps) {
  // Hooks
  const { t: translate } = useTranslation('translation', {
    // Feel free to create additional hooks if you need translations from a different keyPrefix...
    keyPrefix: 'CLIENT_ONBOARDING'
  });
  const theme = useTheme();

  return (
    <FlexBox alignItems="center" flexDirection="column" gap={theme.spacing(6)}>
      <Typography
        color="primary.400"
        sx={{ fontSize: '28px', lineHeight: '30px' }}
        textAlign="center"
        variant="primary-bold"
        whiteSpace="break-spaces"
      >
        {translate('welcome')}
      </Typography>
      <Typography textAlign="center">
        {translate('welcomeDescription')}
      </Typography>
      <Button
        disabled={!values}
        onClick={onStart}
        sx={{ width: '350px' }}
        type="button"
        variant="contained"
      >
        {translate('start')}
      </Button>
    </FlexBox>
  );
}

export default Welcome;
