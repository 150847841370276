/* eslint-disable no-underscore-dangle */
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import { Palette } from '@mui/material';
import {
  CalendarEventsApi,
  CalendarEventsApiCalendarEventsFindByMonthRequest,
  CreateNoteDTO,
  NoteApi,
  CalendarEventIterationApi,
  ProfileApi,
  TaskIterationChecklistDTO,
  TaskIterationStatusStatusEnum,
  TechnicianTaskManagementApi,
  TechnicianTaskManagementApiTechnicianTaskManagenmentUpdateRequest,
  TimeFormatEnum
} from '../api-client/generated/api';
import { ProjectStatus, formatDate } from '../utils/general';
import {
  getFormattedDatesList,
  formatTimesAndCalculateDifference,
  userDashboardParser,
  formatDateSimple
} from '../utils/calendarHelper';
import {
  alertColors,
  mainColors,
  successColors,
  surfaceColors
} from '../theme/themeConfig';

const calendarApi = new CalendarEventsApi();
const calendarEventIterationApi = new CalendarEventIterationApi();
const technicianTaskManagementApi = new TechnicianTaskManagementApi();
const noteApi = new NoteApi();

dayjs.extend(utc);
dayjs.extend(timezone);

const patchCalendarEventsCloseCalendarEvent = async (params: any) => {
  const { id, technicianIds } = params;
  try {
    const response = await calendarApi.calendarEventsCloseCalendarEvent({
      closeCalendarEventByTechnicianParamDTO: { technicianIds },
      id
    });
    return response.data;
  } catch (error: any) {
    throw new Error(
      `Failed to close the task: ${
        error.response?.data?.message || error.message
      }`
    );
  }
};

const patchTechnicianTaskManagenmentCloseTask = async (params: any) => {
  if (!params.taskIterationId || !params.technicianId) {
    throw new Error(
      'Invalid parameters: taskIterationId or technicianId is missing'
    );
  }
  const { taskIterationId, technicianId } = params;

  try {
    const response =
      await technicianTaskManagementApi.technicianTaskManagenmentCloseTask({
        closeTaskIterationByTechnicianParamDTO: { technicianId },
        taskIterationId
      });
    return response.data;
  } catch (error: any) {
    throw new Error(
      `Failed to close the task: ${
        error.response?.data?.message || error.message
      }`
    );
  }
};

const calendarGetCalendarEventsMobile = async (params: any) => {
  const response = await calendarApi.calendarEventsFindByTechnicianDashboard();

  return userDashboardParser(response, params?.timeFormat);
};

const calendarGetCalendarEventsByTechnician = async () => {
  const response = await calendarApi.calendarEventsFindByTechnician();
  const { data } = response;
  const calandarEvents: any[] = [];
  data.forEach((event: any) => {
    if (
      !calandarEvents.find(
        (calandarEvent) =>
          calandarEvent.date === event.start &&
          calandarEvent.leadServiceId === event.leadService.id &&
          calandarEvent.leadServiceTimeId === event.leadServiceTime.id
      )
    ) {
      const tasksList: any[] = [];
      event?.leadService?.tasks.forEach((task: any) => {
        tasksList.push({
          attendees: [
            `${event?.client?.firstName} ${event?.client?.lastName} - ${event?.client?.businessName}`
          ],
          endTime: event.leadServiceTime?.endTime,
          frequency: event.leadServiceTime.frequency,
          frequencyDays: event.leadServiceTime.frequencyDays.map((day: any) => {
            return day;
          }),
          leadServiceTaskId: task.id,
          location: event?.location,
          phone: event?.client?.phone,
          projectId: event.project.id,
          time: event.leadServiceTime.startTime,
          title: task.name
        });
      });
      calandarEvents.push({
        date: event.start,
        events: tasksList,
        id: event.id,
        leadServiceId: event.leadService.id,
        leadServiceTimeId: event.leadServiceTime.id,
        projectId: event.project.id,
        startTime: event.leadServiceTime.startTime
      });
    }
  });

  return calandarEvents;
};

const calendarGetCalendarEventsByProject = async (id: string) => {
  const response = await calendarApi.calendarEventsFindOne({ id });
  const { data } = response;

  return data;
};

const calendarGetProjectsCalendarEvents = async (params: any) => {
  const requestParams = {
    day: params?.day
  } as CalendarEventsApiCalendarEventsFindByMonthRequest;

  const response = await calendarApi.calendarEventsFindByMonth(requestParams);

  const { data } = response;
  const projects: any[] = [];
  const events: any[] = [];
  const eventsProjects: any[] = [];
  const { palette } = params as { palette: Palette };

  const auxStartDate = new Date(`${params?.day}T00:00:00Z`).toISOString();

  const profileApi = new ProfileApi();
  const responseProfile = await profileApi.profileGetProfile();
  const { data: dataProfile }: any = responseProfile;
  const currentTimeZone = dayjs().utcOffset();

  const timezone = currentTimeZone;

  data.forEach((calendarData: any, index: number) => {
    let backgroundColor = '';
    let color = '';

    const auxEventStart = new Date(
      `${formatDateSimple(new Date(calendarData?.start))}T00:00:00Z`
    ).toISOString();

    if (
      new Date(auxEventStart) >= new Date(auxStartDate) &&
      ((calendarData.state === 'Created' &&
        calendarData?.project?.status !== ProjectStatus.CANCELLED) ||
        calendarData.state === 'Completed' ||
        calendarData.state === 'Started')
    ) {
      if (!projects.find((project) => project.id === calendarData.project.id)) {
        projects.push({
          backgroundColor,
          borderColor: backgroundColor,
          borderRadius: '4px',
          color,
          id: calendarData?.project?.id,
          name: calendarData?.title
        });
      } else {
        const auxProject = projects.find(
          (project) => project.id === calendarData.project.id
        );
        backgroundColor = auxProject?.backgroundColor;
        color = auxProject?.color;
      }

      const auxMembers: any[] = [];
      const auxServices: any[] = [];
      const auxDestinations: any[] = [];

      calendarData?.leadService?.tasks.forEach((task: any) => {
        let auxStatus: string = TaskIterationStatusStatusEnum.Created;

        if (task?.taskIteration && task.taskIteration.length > 0) {
          const filterTaskIteration = task.taskIteration.filter(
            (iteration: any) =>
              calendarData?.taskIterations.filter(
                (calendartTaskIte: any) =>
                  calendartTaskIte?.id === iteration?.id
              ) && iteration.status === TaskIterationStatusStatusEnum.Completed
          );

          if (filterTaskIteration.length >= task.minimumResources) {
            auxStatus = TaskIterationStatusStatusEnum.Completed;
          } else if (filterTaskIteration.length > 0) {
            auxStatus = TaskIterationStatusStatusEnum.InProgress;
          }
        }

        auxServices.push({
          id: task.id,
          name: task.name,
          status: auxStatus
        });

        task?.leadTaskTechnicians?.forEach((technicianData: any) => {
          const technician = technicianData?.technician;

          const findEventIteration =
            calendarData?.calendarEventIterations?.find(
              (eventIteration: any) =>
                eventIteration?.technician?.id === technician?.id
            );

          if (!auxMembers.find((member) => member.id === technician.id)) {
            const red = Math.floor(Math.random() * 256);
            const green = Math.floor(Math.random() * 256);
            const blue = Math.floor(Math.random() * 256);
            auxMembers.push({
              backgroundColor: `rgb(${red}, ${green}, ${blue})`,
              borderRadius: '4px',
              calendarEventIteration: findEventIteration,
              color,
              firstName: technician?.firstName,
              id: technician?.id,
              image: {
                url: technician?.profilePic
              },
              lastName: technician?.lastName,
              name: `${technician?.firstName} ${technician?.lastName}`,
              profilePic: technician?.profilePic
            });
          }
        });
      });

      const auxToLocalStart = dayjs(calendarData?.start)
        .utcOffset(timezone)
        .format('YYYY-MM-DDTHH:mm:ss');

      const auxToLocalEnd = dayjs(calendarData?.end)
        .utcOffset(timezone)
        .format('YYYY-MM-DDTHH:mm:ss');

      if (
        !eventsProjects.find(
          (eventProject) =>
            eventProject.projectId === calendarData.project.id &&
            eventProject.startDate === calendarData?.start
        )
      ) {
        const auxServicesTimes: any[] = [];
        const cont = 0;

        let groupedDestinations = [];
        if (calendarData?.project?.proposal?.lead?.destinations?.length) {
          groupedDestinations =
            calendarData.project.proposal.lead.destinations.map(
              (destination: any, dIndex: number) => {
                const servicesTimes: any[] = [];
                destination?.serviceTimes?.forEach(
                  (serviceTime: any, sIndex: number) => {
                    const formatEndTime = dayjs(calendarData?.end)
                      .utcOffset(timezone)
                      .format(
                        params?.timeFormat &&
                          params.timeFormat === TimeFormatEnum._24h
                          ? 'HH:mm'
                          : 'h:mm A'
                      );
                    const formatStartTime = dayjs(calendarData?.start)
                      .utcOffset(timezone)
                      .format(
                        params?.timeFormat &&
                          params.timeFormat === TimeFormatEnum._24h
                          ? 'HH:mm'
                          : 'h:mm A'
                      );
                    servicesTimes.push({
                      auxToLocalStart,
                      endDate: calendarData.end,
                      endTime: serviceTime.endTime,
                      formatEndTime,
                      formatStartTime,
                      id: serviceTime.id,
                      name: `Service Time #${cont + 1}`,
                      services: auxServices,
                      startDate: calendarData.start,
                      startTime: serviceTime.startTime,
                      timezone
                    });
                  }
                );
                return {
                  address: destination.address,
                  destinationNumber: dIndex + 1,
                  services: servicesTimes
                };
              }
            );
        }

        switch (calendarData?.state) {
          case 'Completed':
            backgroundColor = palette.success?.[100] || '';
            color = palette.success?.[400] || '';
            break;
          case 'Started':
            backgroundColor = palette.warning?.[100] || '';
            color = palette.warning?.[400] || '';
            break;
          default:
            backgroundColor = palette.informative?.[100] || '';
            color = palette.informative?.[400] || '';
            break;
        }

        if (
          calendarData?.project?.status === ProjectStatus.CANCELLED &&
          calendarData.state === 'Cancelled'
        ) {
          backgroundColor = palette.alert?.[100] || '';
          color = palette.alert?.[400] || '';
        }

        if (calendarData?.project?.status === ProjectStatus.ON_HOLD) {
          backgroundColor = palette.grey?.[100] || '';
          color = palette.grey?.[400] || '';
        }

        eventsProjects.push({
          attendees: [
            `${calendarData?.client?.firstName} ${calendarData?.client?.lastName} - ${calendarData?.client?.businessName}`
          ],
          backgroundColor,
          body: `${calendarData?.title}`,
          borderRadius: '4px',
          calendarId: calendarData?.project?.id,
          category: 'allday',
          color,
          end: auxToLocalEnd,
          id: calendarData?.id,
          location: calendarData?.location,
          projectId: calendarData.project.id,
          raw: {
            assignments: calendarData?.assignments,
            calendarEventIterations: calendarData?.calendarEventIterations,
            destinations: groupedDestinations,
            index,
            members: auxMembers,
            projectId: calendarData.project.id,
            services: auxServicesTimes,
            status: calendarData?.state,
            task: calendarData?.leadService?.tasks,
            timezone
          },
          start: auxToLocalStart,
          startDate: calendarData?.start,
          title: `${calendarData?.title}`
        });
      }

      if (
        !events.find(
          (event) =>
            event.serviceId === calendarData?.leadService?.id &&
            event.serviceTimeId === calendarData?.leadServiceTime?.id &&
            event.startDate === calendarData?.start
        )
      ) {
        switch (calendarData?.state) {
          case 'Completed':
            backgroundColor = palette.success?.[100] || '';
            color = palette.success?.[400] || '';
            break;
          case 'Started':
            backgroundColor = palette.warning?.[100] || '';
            color = palette.warning?.[400] || '';
            break;
          case 'Created':
          default:
            backgroundColor = palette.informative?.[100] || '';
            color = palette.informative?.[400] || '';
            break;
        }

        if (
          calendarData?.project?.status === ProjectStatus.CANCELLED &&
          calendarData.state === 'Cancelled'
        ) {
          backgroundColor = palette.alert?.[100] || '';
          color = palette.alert?.[400] || '';
        }

        if (calendarData?.project?.status === ProjectStatus.ON_HOLD) {
          backgroundColor = palette.grey?.[100] || '';
          color = palette.grey?.[400] || '';
        }

        const calendarDataStartLength: number = calendarData?.start.length;

        events.push({
          attendees: [
            `${calendarData?.client?.firstName} ${calendarData?.client?.lastName} - ${calendarData?.client?.businessName}`
          ],
          backgroundColor,
          body: calendarData?.leadService?.name,
          borderRadius: '4px',
          calendarId: calendarData?.project?.id,
          category: 'time',
          color,
          end: calendarData?.end.substring(0, calendarDataStartLength - 5),
          id: calendarData?.id,
          location: calendarData?.location,
          projectId: calendarData.project.id,
          raw: {
            assignments: calendarData?.assignments,
            calendarEventIterations: calendarData?.calendarEventIterations,
            index,
            members: auxMembers,
            projectId: calendarData.project.id,
            services: [
              {
                id: calendarData?.leadService?.id,
                name: calendarData?.leadService?.name
              }
            ],
            status: calendarData?.state,
            task: calendarData?.leadService?.tasks,
            timezone
          },
          serviceId: calendarData?.leadService?.id,
          serviceTimeId: calendarData?.leadServiceTime?.id,
          start: dayjs(calendarData?.start)
            .utcOffset(0)
            .format('YYYY-MM-DDTHH:mm:ss'),
          startDate: calendarData?.start,
          state: calendarData?.state,
          title: `${calendarData?.title} ${calendarData?.leadService?.name} - ${calendarData?.client?.firstName} ${calendarData?.client?.lastName}`
        });
      }
    }
  });

  const mergedEventsProjects = eventsProjects.reduce(
    (acc: any[], eventProject: any) => {
      const existingEventProject = acc.find(
        (e) =>
          e.title === eventProject.title &&
          e.startDate.split('T')[0] === eventProject.startDate.split('T')[0]
      );
      if (!existingEventProject) {
        acc.push(eventProject);
      }
      return acc;
    },
    []
  );

  return {
    ...data,
    events,
    eventsProjects: mergedEventsProjects,
    projects,
    timezone
  };
};

const setEventOnRoad = async (id: string) => {
  const response = await calendarApi.calendarEventsSetTravelTimeOnRoad({ id });
  const { data } = response;

  return data;
};

const setEventArrived = async (params: any): Promise<any> => {
  const { id, calendarInProgressMutate, setGetCalendarInProgressEvents } =
    params;

  const response = await calendarApi.calendarEventsSetTravelTimeArrived({ id });
  const { data } = response;
  calendarInProgressMutate(undefined, true);
  setGetCalendarInProgressEvents(true);
  return data;
};

const setCompleteCalendarIteration = async (params: any): Promise<any> => {
  const { id, calendarInProgressMutate, setGetCalendarInProgressEvents } =
    params;

  const response =
    await calendarEventIterationApi.calendarEventIterationSetCompleteCalendarIteration(
      { id }
    );
  const { data } = response;
  calendarInProgressMutate(undefined, true);
  setGetCalendarInProgressEvents(true);
  return data;
};

const technicianTaskManagenmentUpdate = async (params: any): Promise<any> => {
  const auxParams = {
    taskIterationId: params.taskIterationId,
    taskIterationStatus: {
      geofencingApproved: params.geofencingApproved,
      lat: params.lat,
      long: params.long,
      status: params.status as TaskIterationStatusStatusEnum
    }
  } as TechnicianTaskManagementApiTechnicianTaskManagenmentUpdateRequest;

  const response =
    await technicianTaskManagementApi.technicianTaskManagenmentUpdate(
      auxParams
    );

  const { data } = response;

  return data;
};

const technicianTaskManagenmentGetTaskIterationById = async (
  params: any
): Promise<any> => {
  const response =
    await technicianTaskManagementApi.technicianTaskManagenmentGetTaskIterationById(
      { taskIterationId: params?.id }
    );

  const { data } = response;

  const auxData: any = typeof data === 'object' ? data : null;
  const auxFiles: any[] = [];

  if (params?.setTaskItearationFilesList && auxData && auxData?.fileBank) {
    auxData.fileBank.forEach((file: any) => {
      if (
        !auxFiles.find(
          (auxFile) => auxFile.fileOriginalName === file.fileOriginalName
        )
      ) {
        auxFiles.push({
          ...file,
          name: file.fileOriginalName,
          [file.photoSize]: file.fileUrl
        });
      } else {
        const fileIndex = auxFiles.findIndex(
          (auxFile) => auxFile.fileOriginalName === file.fileOriginalName
        );
        auxFiles[fileIndex] = {
          ...auxFiles[fileIndex],
          [file.photoSize]: file.fileUrl
        };
      }
    });

    params?.setTaskItearationFilesList(auxFiles);

    if (params.setCurrentTaskDetails) {
      const auxCurrentParentFiles: any =
        auxData?.leadServiceTask?.parentTask &&
        auxData?.leadServiceTask?.parentTask?.fileBank
          ? auxData?.leadServiceTask?.parentTask?.fileBank
          : null;

      params.setCurrentTaskDetails({
        ...params?.currentTaskDetails,
        closedBy: auxData?.calendarEvents?.calendarEventIterations[0].closedBy,
        closer: auxData?.calendarEvents?.calendarEventIterations[0].closer,
        parentTask: {
          ...params?.currentTaskDetails?.parentTask,
          fileBank: auxCurrentParentFiles
        },
        startedBy:
          auxData?.calendarEvents?.calendarEventIterations[0].startedBy,
        starter: auxData?.calendarEvents?.calendarEventIterations[0].starter,
        taskIteration: [
          {
            ...params?.currentTaskDetails.taskIteration[0],
            fileBank: auxFiles
          }
        ],
        technician: {
          email: auxData.technician.email,
          firstName: auxData.technician.firstName,
          id: auxData.technician.id,
          lastName: auxData.technician.lastName,
          phoneNumber: auxData.technician.phoneNumber
        }
      });
    }
  }

  return true;
};

const calendarEventsfindDotDaysByTechnician = async (): Promise<any> => {
  const response = await calendarApi.calendarEventsFindDotDaysByTechnician();
  const { data } = response;
  return data;
};

const calendarGetCalendarEventsByTechnicianByDate = async (params: any) => {
  const { day, timeFormat } = params;
  const timeZone = dayjs().utcOffset();
  const response = await calendarApi.calendarEventsFindByTechnicianAndDate({
    day,
    timeZone
  });

  const { data } = response;
  const calandarEvents: any[] = [];

  data.forEach((event: any) => {
    if (
      !calandarEvents.find(
        (calandarEvent) =>
          calandarEvent.date === event.start &&
          calandarEvent.leadServiceId === event.leadService.id &&
          calandarEvent.leadServiceTimeId === event.leadServiceTime.id
      )
    ) {
      const tasksList: any[] = [];
      event?.leadService?.tasks.forEach((task: any) => {
        let auxStatus = TaskIterationStatusStatusEnum.Created;
        const auxStatusColors = {
          backgroundColor: `${surfaceColors.SURFACE_5}`,
          color: `${mainColors.MAIN_6}`
        };

        if (task.taskIteration && task.taskIteration.length > 0) {
          auxStatus = task.taskIteration[0].status;

          if (
            task.taskIteration[0].status ===
            TaskIterationStatusStatusEnum.Completed
          ) {
            auxStatusColors.backgroundColor = `${successColors.SUCCESS_6}`;
            auxStatusColors.color = `${successColors.SUCCESS_7}`;
          }

          if (
            task.taskIteration[0].status ===
            TaskIterationStatusStatusEnum.InProgress
          ) {
            auxStatusColors.backgroundColor = `${alertColors.ALERT_6}`;
            auxStatusColors.color = `${alertColors.ALERT_7}`;
          }
        }

        const newTecnicians = task?.leadTaskTechnicians.map(
          (technicianData: any) => {
            const red = Math.floor(Math.random() * 256);
            const technician = technicianData?.technician;
            const green = Math.floor(Math.random() * 256);
            const blue = Math.floor(Math.random() * 256);

            return {
              backgroundColor: `rgb(${red}, ${green}, ${blue})`,
              firstName: technician?.firstName || technician?.name,
              id: technician?.id,
              image: {
                url: ''
              },
              lastName: technician?.lastName,
              name:
                technician?.name ||
                `${technician?.firstName} ${technician?.lastName}`,
              profilePic: null,
              rate: technician?.rate
            };
          }
        );

        const red = Math.floor(Math.random() * 256);
        const green = Math.floor(Math.random() * 256);
        const blue = Math.floor(Math.random() * 256);
        const supervisors = event?.leadServiceTime?.assignSupervisor
          ? [
              {
                backgroundColor: `rgb(${red}, ${green}, ${blue})`,
                firstName: event?.leadServiceTime?.supervisor?.firstName,
                id: event?.leadServiceTime?.supervisor?.id,
                image: {
                  url: ''
                },
                lastName: event?.leadServiceTime?.supervisor?.lastName,
                name: `${event?.leadServiceTime?.supervisor?.firstName} ${event?.leadServiceTime?.supervisor?.lastName}`,
                profilePic: null
              }
            ]
          : [];
        const taskWorkedTimes = formatTimesAndCalculateDifference(
          task?.taskIteration[0]?.startTime,
          task?.taskIteration[0]?.endTime || new Date().toISOString(),
          timeFormat
        );

        const taskDetailFormated = {
          date: formatDate(event.start),
          editable: false,
          id: task.id,
          taskDetails: {
            ...task,
            address: event?.location,
            configTravelTime: event?.leadServiceTime?.travelTime || 0,
            endTime: event.leadServiceTime.endTime,
            enforceGeofencing: event?.project.enforceGeofencing,
            geofencingMeasure: event?.company?.geofencingMeasure,
            geofencingRadius: event?.company?.geofencingRadius,
            startTime: event.leadServiceTime.startTime,
            status: auxStatus,
            supervisors,
            taskWorkedTime: taskWorkedTimes.differenceInMinutes,
            technicians: newTecnicians,
            travelTime: event.leadServiceTime.travelTime || 0
          }
        };

        tasksList.push({
          attendees: [
            `${event?.client?.firstName} ${event?.client?.lastName} - ${event?.client?.businessName}`
          ],
          date: formatDate(event.start),
          endDate: event.end,
          endTime: event.leadServiceTime?.endTime,
          frequency: event.leadServiceTime.frequency,
          frequencyDays: event.leadServiceTime.frequencyDays.map((day: any) => {
            return day;
          }),
          id: event?.leadServiceTime?.id,
          leadServiceTaskId: task.id,
          location: event?.location,
          phone: event?.client?.phone,
          projectId: event.project.id,
          startDate: event.start,
          status: auxStatus,
          statusColors: auxStatusColors,
          taskDetailFormated,
          time: event.leadServiceTime.startTime,
          title: task.name
        });
      });
      calandarEvents.push({
        date: dayjs(event.start).format('YYYY-MM-DD'),
        endDate: event.end,
        events: tasksList,
        id: event.id,
        leadServiceId: event.leadService.id,
        leadServiceTimeId: event.leadServiceTime.id,
        projectId: event.project.id,
        startDate: event.start,
        startTime: event.leadServiceTime.startTime
      });
    }
  });

  return calandarEvents;
};

const calendarEventsUpdateTaskIterationChecklistStatus = async (
  params: any
) => {
  const requestParams = {
    taskIterationCheckListId: params.checklistId,
    taskIterationChecklistDTO: {
      complete: params.status
    } as TaskIterationChecklistDTO
  };
  const response =
    await technicianTaskManagementApi.technicianTaskManagenmentUpdateTaskIterationChecklistStatus(
      requestParams
    );
  const { data } = response;

  return response.status === 200 ? true : data;
};

const noteCreate = async (params: CreateNoteDTO) => {
  const response = await noteApi.noteCreate({
    createNoteDTO: params
  });
  const { data } = response;

  return data;
};

const reassignTeam = async (params: any) => {
  const { newTechnician, previousTechnician, id } = params;
  const response: any =
    await technicianTaskManagementApi.technicianTaskManagenmentSwapTaskTechnician(
      {
        swapTaskTechnicianDTO: { newTechnician, previousTechnician },
        taskId: id
      }
    );
  return { ...response.data, status: response.status };
};

const calendarGetInProgressEvents = async (params: any) => {
  const { timeFormat } = params;
  const response =
    await calendarApi.calendarEventsFindInProgressByTechnicianDashboard();
  const { data } = response;

  const inProgressProjects = getFormattedDatesList(data, 2, timeFormat);

  return inProgressProjects;
};

const findContactInformationByCalendarEvent = async (params: any) => {
  const { id } = params;
  const response =
    await calendarApi.calendarEventsFindContactInformationByCalendarEvent({
      id
    });
  const { data } = response;
  return data;
};

export default {
  calendarEventsUpdateTaskIterationChecklistStatus,
  calendarEventsfindDotDaysByTechnician,
  calendarGetCalendarEventsByProject,
  calendarGetCalendarEventsByTechnician,
  calendarGetCalendarEventsByTechnicianByDate,
  calendarGetCalendarEventsMobile,
  calendarGetInProgressEvents,
  calendarGetProjectsCalendarEvents,
  findContactInformationByCalendarEvent,
  noteCreate,
  patchCalendarEventsCloseCalendarEvent,
  patchTechnicianTaskManagenmentCloseTask,
  reassignTeam,
  setCompleteCalendarIteration,
  setEventArrived,
  setEventOnRoad,
  technicianTaskManagenmentGetTaskIterationById,
  technicianTaskManagenmentUpdate
};
