import { Stack, styled, Typography } from '@mui/material';
import React, { useEffect, useRef, useState } from 'react';
import DropzoneLibrary from 'react-dropzone-uploader';
import { t } from 'i18next';
import profile from '../../api/profile';
import ImageCropper from '../input/CropImage';
import {
  textColors,
  mainColors,
  secondaryColors
} from '../../theme/themeConfig';

const ImageUploadInterfaceContainer = styled(Stack)(() => ({
  alignItems: 'center',
  backgroundColor: mainColors.MAIN_LIGHT,
  justifyContent: 'center'
}));

function ImageUploadContainer() {
  return (
    <ImageUploadInterfaceContainer>
      <Typography color={textColors.BLACK} fontWeight={400} variant="body_200">
        {t('LOGIN.uploadPhotoDescription')}
      </Typography>
    </ImageUploadInterfaceContainer>
  );
}

interface Props {
  callApiOnComplete?: boolean;
  disabledDrop?: boolean;
  filesList?: Array<File>;
  multiple?: boolean;
  parent: string;
  setFiles: React.Dispatch<React.SetStateAction<any>>;
  type: string;
}

const ImageUpload: React.FC<Props> = function Dropzone({
  disabledDrop,
  filesList,
  type,
  multiple,
  parent,
  setFiles,
  callApiOnComplete = true
}) {
  const [content, setContent] = useState<Array<File>>([]);
  const [intialContent, setIntialContent] = useState<Array<File>>([]);
  const [letContent, setLetContent] = useState<boolean>(false);
  const dropzoneRef = useRef<any>(null);

  useEffect(() => {
    if (filesList && filesList.length > 0 && !letContent) {
      setLetContent(true);
      const auxInitialContent = filesList.map((contentItem: any) => {
        let auxType = contentItem?.type || contentItem?.mimeType || '';

        if (contentItem?.type.includes('image') && parent === 'lead') {
          auxType = 'application/pdf';
        }

        return new File(
          [contentItem],
          contentItem.fileOriginalName || contentItem.name,
          {
            type: auxType
          }
        );
      });

      setIntialContent(auxInitialContent);
    } else {
      setIntialContent([]);
    }

    setContent(filesList || []);
  }, [filesList]);

  const convertToBase64 = (file: File): Promise<string> => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result as string);
      reader.onerror = (error) => reject(error);
    });
  };

  return (
    <>
      {type === 'image' &&
      (parent === 'profile' || parent === 'company') &&
      content.length > 0 ? (
        false
      ) : (
        <DropzoneLibrary
          canRemove
          accept={parent === 'taskDetails' ? 'image/*' : '*'}
          disabled={disabledDrop}
          initialFiles={intialContent}
          inputContent={ImageUploadContainer()}
          maxFiles={multiple ? undefined : 1}
          multiple={multiple}
          onChangeStatus={({ meta, file }: any, status: any) => {
            const idMeta =
              content.filter(
                (fileItem) =>
                  fileItem.name === meta.name && fileItem.size === meta.size
              ).length > 0;

            if (file && file.type !== '') {
              if (type === 'image' && status === 'done') {
                if (parent === 'taskDetails') {
                  setFiles([...content, file]);
                } else {
                  setContent([meta.previewUrl]);
                }
              } else if (status === 'done' && !idMeta) {
                // setContent([...content, file]);
                setFiles([...content, file]);
              } else if (status === 'removed') {
                const auxContent = content.filter((f) => f.name !== file.name);
                setFiles([...auxContent]);
              }
            }
          }}
          ref={dropzoneRef}
          styles={{
            dropzone: {
              // Exception in color cause its not a material component.
              backgroundColor: mainColors.MAIN_LIGHT,
              border: `2px dashed ${mainColors.MAIN}`,
              height: 'auto',
              minHeight: 'auto',
              overflow: 'hidden',
              padding: '32px 0px'
            },
            preview: {
              paddingBottom: '0px',
              paddingLeft: '40px',
              paddingRight: '3%',
              paddingTop: '0px'
            },
            submitButton: {
              display: 'none'
            },
            submitButtonContainer: {
              display: 'none'
            }
          }}
          submitButtonDisabled={!multiple}
        />
      )}
      {type === 'image' &&
        (parent === 'profile' || parent === 'company') &&
        content.length > 0 && (
          <ImageCropper
            hideCropper={() => {}}
            image={content[0]}
            onCropCancel={() => {
              setContent([]);
            }}
            onCropDone={async (newFile: any) => {
              const base64Image = await convertToBase64(newFile);
              if (callApiOnComplete) {
                profile.setProfilePhoto(base64Image).then((data) => {
                  setFiles(data);
                  setContent([]);
                });
              } else {
                setFiles([{ file: base64Image, fileUrl: content[0] }]);
                setContent([]);
              }
            }}
          />
        )}
    </>
  );
};

ImageUpload.defaultProps = {
  callApiOnComplete: true,
  disabledDrop: false,
  filesList: [],
  multiple: false
};

export default ImageUpload;
