import {
  Tabs,
  Tab,
  Container,
  Box,
  Typography,
  Chip,
  useTheme,
  PaletteColor
} from '@mui/material';
import { isMobile } from 'react-device-detect';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import useSWR from 'swr';
import dayjs from 'dayjs';
import GeneralInformation from './steps/GeneralInformation';
import Services from './steps/Services';
import Schedule from './steps/Schedule';
// import Resources from './steps/Resources';
import CostCalculator from './steps/CostCalculator';
import api from '../../api';
import Proposal from './steps/Proposal';
import Banner from '../../components/shared/Banner';
import { getCustomColorBasedOnStatus } from '../../utils/general';
import Loader from '../../components/shared/Loader';
import Destinations from './steps/Destinations';
// eslint-disable-next-line import/no-cycle
import Analysis from './steps/Analysis';
import Supervision from './steps/supervision/Supervision';
import useNotificationsStore from '../../store/zustand/notifications';

const MobileStyle = {
  margin: '20px',
  width: 'auto'
};

const DesktopStyle = {
  marginBottom: '20px',
  marginTop: '44px',
  marginX: '32px',
  width: 'auto'
};

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      aria-labelledby={`simple-tab-${index}`}
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      role="tabpanel"
      // eslint-disable-next-line
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

interface Props {
  leadId?: any;
  leadInfo?: any;
  newLead?: boolean;
  onClose?: () => void;
  presetStep?: number;
  setValues?: (values: any) => void;
}

const FormLead: React.FC<Props> = function FormLead({
  leadId = null,
  newLead = false,
  leadInfo,
  presetStep = 0,
  setValues,
  onClose
}) {
  const { t } = useTranslation();
  const theme = useTheme();
  const navigate = useNavigate();
  const [step, setStep] = useState<number>(0);
  const [lead, setLead] = useState<any>({});
  const [step1, setStep1] = useState<{ fire: boolean; nextStep: boolean }>({
    fire: false,
    nextStep: false
  });
  const [getCompany, askCompany] = useState<boolean>(false);
  const [fireAPIGetLead, setFireAPIGetLead] = useState<boolean>(false);
  const [fireAPIUpdateLead, setFireAPIUpdateLead] = useState<boolean>(false);
  const [fireAPICreateLead, setFireAPICreateLead] = useState<boolean>(false);
  const [fireAPIUploadDocs, setFireAPIUploadDocs] = useState<boolean>(false);
  const [currentLeadId, setCurrentLeadId] = useState<any>(null);
  const [leadFiles, setLeadFiles] = useState<any>({});
  const [initialLeadFiles, setInitialLeadFiles] = useState<any>([]);
  const [disableCalculator, setDisableCalculator] = useState<boolean>(false);
  const [disableResources, setDisableResources] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const editableStatus = ['Draft'];
  const statusColor: PaletteColor = getCustomColorBasedOnStatus(
    lead?.status,
    theme
  );

  // ======================================================================== SWR

  const { data: leadData, mutate } = useSWR(
    (leadId && !lead.id) || fireAPIGetLead ? leadId : null,
    api.leadsApi.leadApi.getLead
  );

  const { data: dataCreatedLead } = useSWR(
    fireAPICreateLead && lead ? lead : null,
    api.leadsApi.leadApi.createLead
  );

  const { data: dataCompany } = useSWR(
    getCompany ? { url: 'api/company' } : null,
    api.CompanyApi.getCurrentCompany
  );

  const { data: dataUpdateLead, error: updateClientError } = useSWR(
    lead?.status !== 'Converted' && fireAPIUpdateLead ? lead : null,
    api.leadsApi.leadApi.updateLeadGeneralInfo
  );

  const { data: fileResponse, error: errorFile } = useSWR(
    fireAPIUploadDocs && lead.id
      ? {
          entityId: lead.id,
          files: leadFiles?.length > 0 ? leadFiles : [],
          initialLeadFiles,
          relatedEntity: 'lead'
        }
      : null,
    api.StorageApi.uploadEntityFiles
  );

  const { data: step1Data } = useSWR(
    step1.fire ? lead : null,
    api.leadsApi.stepsApi.setStep1
  );

  const handleSaveLead = (lead: any) => {
    const auxLead = {
      ...lead,
      clientId: lead?.client?.id,
      companyId: lead?.company?.id
    };
    setLead(auxLead);
    setFireAPICreateLead(true);
    if (lead?.fileBank?.length > 0) {
      setLeadFiles(lead?.fileBank || null);
    }
  };

  const handleUpdateLead = (lead: any) => {
    if (lead.projectType === 'Ad-Hoc') {
      const updatedDestinations = lead.destinations.map((destination: any) => {
        if (destination.serviceTimes && destination.serviceTimes.length > 1) {
          destination.serviceTimes.slice(1).forEach((serviceTime: any) => {
            api.LeadServiceTimesApi.deleteLeadServicesTime({
              id: lead.id,
              serviceTimeId: serviceTime.id
            });
          });
          return {
            ...destination,
            serviceTimes: [destination.serviceTimes[0]]
          };
        }
        return destination;
      });
      setLead({ ...lead, destinations: updatedDestinations });
    } else {
      setLead(lead || {});
    }
    if (leadId) {
      setFireAPIUpdateLead(true);
    }

    setLeadFiles(lead?.fileBank || null);
  };

  const saveStepper = ({ next }: any) => {
    switch (step) {
      case 0:
        setStep1({ fire: true, nextStep: next });
        setIsLoading(true);
        break;
      default:
        if (Number.isNaN(next)) {
          setStep(step === 4 ? 0 : step + 1);
        } else {
          setStep(next);
        }
        setIsLoading(false);
        break;
    }
  };

  const goToListingPages = () => {
    navigate('/app/leads/listing-page');
  };

  const readOnlyFunc = (info: any) => {
    if (newLead) return false;
    if (editableStatus.includes(info?.status)) return false;
    if (info?.proposals?.length === 0) return false;
    if (info?.status === 'Working Lead') {
      const currentProposal = info?.proposals?.[0] || {};
      if (currentProposal?.status === 'created') return false;
    }
    return true;
  };

  useEffect(() => {
    setFireAPIUpdateLead(false);
    if (dataUpdateLead) {
      setFireAPIUploadDocs(true);
      setLead({
        ...lead,
        ...dataUpdateLead,
        endDate: dataUpdateLead?.projectEndDate,
        startDate: dataUpdateLead?.projectStartDate
      });
    }
    if (errorFile) {
      setFireAPIUploadDocs(false);
    }
  }, [dataUpdateLead]);

  useEffect(() => {
    if (updateClientError) {
      setFireAPIUpdateLead(false);
    }
  }, [updateClientError]);

  useEffect(() => {
    if (fileResponse && !errorFile) {
      setFireAPIUploadDocs(false);
      setLead({ ...lead, fileBank: leadFiles });
      if (fileResponse?.data && fileResponse?.data?.length > 0) {
        setInitialLeadFiles(fileResponse.data.map((file: any) => file.id));
      }
      setLeadFiles(null);
      setCurrentLeadId(lead.id);
    }
    if (errorFile) {
      setFireAPIUploadDocs(false);
    }
  }, [fileResponse]);

  useEffect(() => {
    if (lead && !lead.company) {
      askCompany(true);
    }
  }, [lead]);

  useEffect(() => {
    if (presetStep) {
      setTimeout(() => {
        setStep(presetStep);
      }, 500);
    }
  }, [presetStep]);

  useEffect(() => {
    if (dataCreatedLead?.id) {
      setFireAPICreateLead(false);
      setLead(dataCreatedLead);
      if (leadFiles?.length && leadFiles.length > 0) {
        setFireAPIUploadDocs(true);
      } else {
        setCurrentLeadId(dataCreatedLead.id);
        setStep(1);
        navigate(`/app/leads/edit-lead`, {
          state: { id: dataCreatedLead?.id, presetStep: 1 }
        });
      }
    }
  }, [dataCreatedLead]);

  useEffect(() => {
    if (step1Data) {
      if (step1?.nextStep) {
        setStep(step === 4 ? 0 : step + 1);
      } else {
        goToListingPages();
      }
      setStep1({ ...step1, fire: false });
    }
  }, [step1Data]);

  useEffect(() => {
    if (dataCompany) {
      askCompany(false);
      setLead({ ...lead, company: dataCompany });
    }
  }, [dataCompany]);

  useEffect(() => {
    if (lead) {
      if (setValues) {
        setValues({ ...lead, drawerMode: false });
      }
      const allDestinationsHaveServiceTimes =
        lead?.destinations?.length > 0 &&
        lead?.destinations?.every(
          (destination: any) => destination?.serviceTimes?.length > 0
        );
      const allDestinationsHaveResources =
        lead?.destinations?.length > 0 &&
        lead?.destinations?.every(
          (destination: any) =>
            destination?.serviceTimes?.length > 0 &&
            destination?.serviceTimes?.every((serviceTime: any) =>
              serviceTime?.services?.every((service: any) =>
                service?.tasks?.every(
                  (task: any) => task?.resources?.length > 0
                )
              )
            )
        );
      setDisableResources(!allDestinationsHaveServiceTimes);
      setDisableCalculator(!allDestinationsHaveResources);
    }
  }, [lead]);

  useEffect(() => {
    if (!dataCompany) askCompany(true);
    if (leadInfo) {
      setCurrentLeadId(leadInfo?.id);
      setLead({ ...leadInfo, drawerMode: true });
    } else {
      setLead({});
    }
  }, []);

  useEffect(() => {
    if (leadId) {
      setCurrentLeadId(leadId);
      setFireAPIGetLead(true);
    } else if (!leadInfo) {
      setLead({ id: 'newLead' });
    }
  }, [leadId]);

  useEffect(() => {
    if (leadData) {
      setFireAPIGetLead(false);
      setLeadFiles(null);
      setLead({ ...leadData, readOnly: readOnlyFunc(leadData) });
      if (newLead) {
        // setTimeout(() => {
        //   setStep(1);
        // }, 0);
      } else if (
        leadData.fileBank?.length > 0 &&
        leadData?.originalFiles?.length > 0
      ) {
        setInitialLeadFiles(leadData.originalFiles.map((file: any) => file.id));
      }
    }
  }, [leadData]);

  const getCurrentTimezone = () => {
    const timezoneOffsetLocal = new Date().getTimezoneOffset();
    return -timezoneOffsetLocal;
  };

  const differentTimezone = () => {
    if (lead?.address?.timezoneIANA) {
      return (
        getCurrentTimezone() !==
        dayjs().tz(lead?.address?.timezoneIANA).utcOffset()
      );
    }
    if (lead?.address?.timezone) {
      return getCurrentTimezone() !== lead?.address?.timezone;
    }
    return false;
  };

  return (
    <Container sx={isMobile ? MobileStyle : DesktopStyle}>
      <Box display="flex" flexDirection="column" gap="16px">
        {!lead?.drawerMode && (
          <Box display="flex" flexDirection="column" gap="16px">
            <Box alignItems="center" display="flex" gap="16px">
              <Typography variant="title">{t('LEAD.EDIT.title')}</Typography>
              {lead?.status && (
                <Chip
                  label={
                    <Typography variant="button_100">{lead?.status}</Typography>
                  }
                  style={{
                    backgroundColor: statusColor[100],
                    border: `1px solid ${statusColor.main}`,
                    borderRadius: '24px !important',
                    color: statusColor[500]
                  }}
                />
              )}
            </Box>
            <Typography color="secondary.400" variant="body_300">
              {t('LEAD.EDIT.description')}
            </Typography>
          </Box>
        )}
        {!lead?.drawerMode && (lead?.readOnly || differentTimezone()) && (
          <Box display="flex" flexDirection="column" gap="16px">
            {lead?.readOnly && (
              <Banner icon text={t('BANNER.LEAD.readOnly')} type="error" />
            )}
            {differentTimezone() && (
              <Banner
                icon
                text={t('BANNER.LEAD.differentTimezone')}
                type="warning"
              />
            )}
          </Box>
        )}

        <Box display="flex" flexDirection="column" gap="24px">
          <Tabs
            aria-label="lead tabs"
            onChange={(_: React.SyntheticEvent, newValue: number) => {
              if (newValue !== 1) {
                if (lead?.destinations?.length > 0) {
                  const cleanedDestinations = lead?.destinations?.map(
                    (destination: any) => {
                      if (destination?.serviceTimes?.length > 0) {
                        const cleanedServiceTimes =
                          destination?.serviceTimes?.filter(
                            (item: any) =>
                              item?.id && typeof item?.id === 'number'
                          );
                        return {
                          ...destination,
                          serviceTimes: cleanedServiceTimes
                        };
                      }
                      return destination;
                    }
                  );
                  setLead({ ...lead, destinations: cleanedDestinations });
                }
              }
              if (newValue === 0) {
                setIsLoading(false);
              }
              setStep(newValue);
            }}
            value={step}
          >
            <Tab
              label={t('LEAD.CREATE.TABS.generalInformation')}
              sx={{ padding: '10px' }}
              value={0}
            />
            <Tab
              disabled={!currentLeadId}
              label={t('LEAD.CREATE.TABS.destinations')}
              sx={{ padding: '10px' }}
              value={1}
            />
            {/* <Tab
              disabled={!currentLeadId}
              label={t('LEAD.CREATE.TABS.serviceTime')}
              sx={{ padding: '10px' }}
            /> */}
            <Tab
              disabled={!currentLeadId || disableResources}
              label={t('LEAD.CREATE.TABS.resources')}
              sx={{ padding: '10px' }}
              value={2}
            />
            <Tab
              disabled={!currentLeadId || disableResources}
              label={t('LEAD.CREATE.TABS.supervision')}
              sx={{ padding: '10px' }}
              value={3}
            />
            <Tab
              disabled={!currentLeadId || disableCalculator}
              label={t('LEAD.CREATE.TABS.costCalculator')}
              sx={{ padding: '10px' }}
              value={4}
            />
            {!lead?.drawerMode && (
              <Tab
                disabled={!currentLeadId || disableCalculator}
                label={t('LEAD.CREATE.TABS.analysis')}
                sx={{ padding: '10px' }}
                value={5}
              />
            )}
            {!lead?.drawerMode && (
              <Tab
                disabled={!currentLeadId || disableCalculator}
                label={t('LEAD.CREATE.TABS.proposal')}
                sx={{ padding: '10px' }}
                value={6}
              />
            )}
          </Tabs>
          <Box borderRadius={2} p={3} style={{ backgroundColor: '#FFF' }}>
            <TabPanel index={0} value={step}>
              {lead &&
                (isLoading ? (
                  <Loader customColor="primary" customSize={48} />
                ) : (
                  <GeneralInformation
                    currentLeadId={currentLeadId}
                    saveStepper={saveStepper}
                    sendValues={
                      currentLeadId ? handleUpdateLead : handleSaveLead
                    }
                    setValues={setLead}
                    values={{ ...lead }}
                  />
                ))}
            </TabPanel>
            <TabPanel index={1} value={step}>
              <Destinations
                saveStepper={saveStepper}
                sendValues={handleUpdateLead}
                setValues={setLead}
                values={lead}
              />
            </TabPanel>
            <TabPanel index={2} value={step}>
              <Schedule
                mutate={() => {
                  mutate(undefined, true);
                  setLead({});
                }}
                saveStepper={saveStepper}
                sendValues={handleUpdateLead}
                setStep={setStep}
                setValues={setLead}
                values={lead}
              />
            </TabPanel>
            <TabPanel index={3} value={step}>
              <Supervision
                mutate={() => {
                  mutate(undefined, true);
                  setLead({});
                }}
                saveStepper={saveStepper}
                setStep={setStep}
                setValues={handleUpdateLead}
                values={lead}
              />
            </TabPanel>
            <TabPanel index={4} value={step}>
              <CostCalculator
                // availability={availability}
                onCloseDrawer={() => {
                  if (onClose) {
                    onClose();
                  }
                }}
                saveStepper={saveStepper}
                setValues={handleUpdateLead}
                values={lead}
              />
            </TabPanel>
            <TabPanel index={5} value={step}>
              <Analysis
                mutate={() => {
                  mutate(undefined, true);
                  setLead({});
                }}
                saveStepper={saveStepper}
                setValues={setLead}
                values={lead}
              />
            </TabPanel>
            <TabPanel index={6} value={step}>
              <Proposal
                clientView={false}
                prevProposalData={{}}
                saveStepper={saveStepper}
                setAcceptProposal={() => {}}
                setStep={setStep}
                setValues={setLead}
                values={lead}
              />
            </TabPanel>
          </Box>
        </Box>
      </Box>
    </Container>
  );
};

TabPanel.defaultProps = {
  children: null
};

FormLead.defaultProps = {
  leadId: undefined,
  leadInfo: undefined,
  newLead: false,
  onClose: undefined,
  presetStep: 0,
  setValues: undefined
};

export default FormLead;
