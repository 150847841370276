import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import '../../style/App.scss';
import {
  Container,
  Box,
  Button,
  Typography,
  styled,
  BoxProps,
  Modal,
  useTheme,
  IconButton,
  ButtonProps
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import useSWR from 'swr';
import CounterOfferMain from '../../views/leads/steps/components/propoosal/CounterOfferMain';
import RegularInput from '../input/RegularInput';
import api from '../../api';
import Loader from '../shared/Loader';
import useNotificationsStore from '../../store/zustand/notifications';

const CloseButton = styled(IconButton)<ButtonProps>(({ theme }) => ({
  color: theme.palette.mainText?.main,
  width: 20
}));

const ModalContainer = styled(Box)<BoxProps>(({ theme }) => ({
  alignItems: 'center',
  backgroundColor: theme.palette.background.paper,
  borderRadius: '0.5em',
  boxShadow:
    '0px 4px 20px rgba(0, 0, 0, 0.1), 0px 6px 10px rgba(0, 0, 0, 0.05)',
  display: 'flex',
  flexDirection: 'column',
  padding: '40px 60px',
  position: 'absolute' as 'absolute',
  right: '50%',
  textAlign: 'center',
  top: '50%',
  transform: 'translate(50%, -50%)'
}));

interface Props {
  counterOffer: any;
  currentProposal: any;
  onClose: () => void;
  onReset: (id: string) => void;
  values: any;
}

const CounterOfferReviewModal: React.FC<Props> =
  function CounterOfferReviewModal({
    onClose,
    currentProposal,
    counterOffer,
    onReset,
    values
  }) {
    const { t } = useTranslation();
    const { addNotification } = useNotificationsStore((state) => state);
    const [decisionApi, setDecisionApi] = useState<boolean>(false);
    const [form, setForm] = useState<any>({});
    const [confirm, setConfirm] = useState<boolean>(false);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const theme = useTheme();

    const counterOfferDestinations = values.destinations
      .map((destination: any) => {
        const serviceTimes = counterOffer?.values?.counterOfferData
          ?.filter((serviceTime: any) =>
            destination.serviceTimes.some(
              (st: any) => st.id === serviceTime.serviceTimeId
            )
          )
          .map((serviceTime: any) => ({
            ...serviceTime,
            projectEnd: currentProposal?.lead?.projectEndDate,
            projectStart: currentProposal?.lead?.projectStartDate
          }));

        return {
          address: destination.address,
          contact: destination.contact,
          id: destination.id,
          serviceTimes
        };
      })
      .filter((destination: any) => destination.serviceTimes.length > 0);

    const { data: decisionData, error: decisionError } = useSWR(
      decisionApi
        ? {
            ...form,
            id: currentProposal?.id,
            url: 'proposal/counteroffer/decision'
          }
        : null,
      api.ProposalApi.counterOfferDecision
    );

    useEffect(() => {
      if (decisionData) {
        setIsLoading(false);
        addNotification(
          form.status === 'reject'
            ? t('NOTIFICATION.TOAST.counterOfferRejectSuccess')
            : t('NOTIFICATION.TOAST.counterOfferSuccess'),
          'success'
        );
        setDecisionApi(false);
        setConfirm(false);
        setForm({});
        onReset(decisionData?.lead?.id);
      }
    }, [decisionData]);

    useEffect(() => {
      if (decisionError) {
        setIsLoading(false);
        addNotification(t('NOTIFICATION.TOAST.counterOfferError'), 'error');
        setDecisionApi(false);
        setConfirm(false);
        setForm({});
      }
    }, [decisionError]);

    return (
      <Container sx={{ padding: '40px', width: 'auto' }}>
        <Modal
          open
          onClose={() => {
            onClose();
          }}
          sx={{
            alignItems: 'center',
            display: 'flex',
            height: '100%',
            justifyContent: 'center'
          }}
        >
          <ModalContainer
            sx={{
              maxHeight: '90vh',
              overflowY: 'auto',
              width: '54vw'
            }}
          >
            {!confirm && (
              <Box flexDirection="row" marginBottom={2} width="100%">
                <Box display="flex" flexDirection="column" gap={2}>
                  <Typography
                    color="primary"
                    fontSize={32}
                    variant="heading_500"
                  >
                    {t('LEAD.CREATE.PROPOSAL.COUNTEROFFER_REVIEW.title')}
                  </Typography>
                  <CounterOfferMain
                    destinations={counterOfferDestinations}
                    onClose={onClose}
                    // serviceTimes={counterOfferServiceTimes}
                  />
                </Box>
                <Box position="absolute" right={20} top={18}>
                  <CloseButton
                    aria-label="menu"
                    color="inherit"
                    onClick={() => {
                      onClose();
                    }}
                    size="medium"
                  >
                    <CloseIcon />
                  </CloseButton>
                </Box>
              </Box>
            )}
            {confirm && (
              <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                <Typography
                  color="primary"
                  fontSize={24}
                  sx={{ mb: 2 }}
                  variant="secondary"
                >
                  {`Are you sure to ${form?.status} this counter offer?`}
                </Typography>
                <Typography fontSize={16} variant="primary">
                  you can leave your reason here!
                </Typography>
                <RegularInput
                  onChange={(value: any) => {
                    setForm({ ...form, message: value });
                  }}
                  value={form?.message}
                />
              </Box>
            )}
            {counterOffer?.action === 'submit' &&
              counterOffer?.decision === null &&
              !isLoading && (
                <Box
                  sx={{
                    bottom: 0,
                    display: 'flex',
                    flexDirection: 'row',
                    gap: 2,
                    justifyContent: 'space-between'
                  }}
                >
                  <Button
                    onClick={() => {
                      if (confirm) {
                        setConfirm(false);
                      } else {
                        setConfirm(true);
                        setForm({ ...form, status: 'reject' });
                      }
                    }}
                    sx={{ width: 200 }}
                    variant="cancel"
                  >
                    <Typography variant="primary-bold">
                      {confirm
                        ? t('GENERAL.cancelButton')
                        : t('REQUEST.reject')}
                    </Typography>
                  </Button>
                  <Button
                    onClick={() => {
                      if (confirm) {
                        setDecisionApi(true);
                        setIsLoading(true);
                      } else {
                        setConfirm(true);
                        setForm({ ...form, status: 'accept' });
                      }
                    }}
                    sx={{
                      height: 46,
                      width: 200
                    }}
                    variant="primary"
                  >
                    <Typography variant="primary-bold">
                      {confirm
                        ? t('GENERAL.confirmButton')
                        : t('REQUEST.approve')}
                    </Typography>
                  </Button>
                </Box>
              )}
            {isLoading && <Loader customColor="primary" customSize={28} />}
          </ModalContainer>
        </Modal>
      </Container>
    );
  };

export default CounterOfferReviewModal;
