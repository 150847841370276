/* eslint-disable react/require-default-props */
/* eslint-disable react/jsx-props-no-spreading */
import React, { useEffect, useState } from 'react';
import { Container, Box, styled, SliderProps, Typography } from '@mui/material';
import { isMobile } from 'react-device-detect';
import { useTranslation } from 'react-i18next';
import { useLocation, useParams } from 'react-router-dom';
import Login from '../components/authenticationFlow/Login';
import bblogo from '../assets/images/Bluberry_Logo.png';
import MFA from '../components/authenticationFlow/MFA';
import ChangePassword from '../components/authenticationFlow/ChangePassword';
import ResetPassword from '../components/authenticationFlow/ResetPassword';
import ForgotPassword from '../components/authenticationFlow/ForgotPassword';
import Invitation from '../components/authenticationFlow/Invitation';
import useAuthStore from '../store/zustand/auth';
import bgInvitation from '../assets/images/login/invitation_bg.png';
import useNavigationStore from '../store/zustand/navigation';
import Impersonate from '../components/authenticationFlow/Impersonate';
import ClientLogin from '../components/authenticationFlow/ClientLogin';

interface Props {
  isCompanyInvitation?: boolean;
  startProcess?: string;
}

const getProportion = (baseSize: number, proportion: number) =>
  isMobile ? baseSize * proportion : baseSize;

const AuthProcessContainer = styled(Box)<SliderProps>(({ theme }) => ({
  alignItems: 'center',
  backgroundColor: theme.palette.mainText?.['100'],
  borderRadius: theme.spacing(1),
  boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
  display: 'flex',
  flexDirection: 'column',
  marginTop: isMobile ? theme.spacing(-25) : theme.spacing(-18),
  minHeight: 277,
  paddingTop: isMobile ? theme.spacing(3) : theme.spacing(5),
  width: getProportion(485, 0.61)
}));

const FormContainer = styled(Box)<SliderProps>(({ theme }) => ({
  alignItems: 'left',
  display: 'flex',
  flexDirection: 'column',
  padding: isMobile ? theme.spacing(4) : theme.spacing(6),
  width: '100%'
}));

const AuthProcess: React.FC<Props> = function AuthProcess({
  startProcess = '',
  isCompanyInvitation = false
}) {
  const [currentProcess, setCurrentProcess] = useState<string>(startProcess);
  const [showWelcomeImg, setShowWelcomeImg] = useState<boolean>(false);
  const navigation = useNavigationStore((state) => state);
  const { t } = useTranslation();
  const { login, setUser } = useAuthStore();

  const { state } = useLocation();

  const getCurrentProcess = () => {
    switch (currentProcess) {
      case 'login':
        return (
          <Login isMobile={isMobile} setCurrentProcess={setCurrentProcess} />
        );
      case 'mfa':
        return (
          <MFA isMobile={isMobile} setCurrentProcess={setCurrentProcess} />
        );
      case 'forgotPassword':
        return <ForgotPassword isMobile={isMobile} />;
      case 'resetPassword':
        return <ResetPassword isMobile={isMobile} />;
      case 'changePassword':
        return <ChangePassword isMobile={isMobile} />;
      case 'impersonate':
        return (
          <Impersonate
            isMobile={isMobile}
            setCurrentProcess={setCurrentProcess}
          />
        );
      case 'clientLogin':
        return (
          <ClientLogin
            isMobile={isMobile}
            setCurrentProcess={setCurrentProcess}
            tokenExpired={state?.tokenExpired}
          />
        );
      default:
        break;
    }
    return false;
  };

  useEffect(() => {
    if (startProcess) {
      setCurrentProcess(startProcess);
    }
  }, [startProcess]);

  useEffect(() => {
    navigation.setCurrentStepScreen('password');
  }, []);

  useEffect(() => {
    if (state?.tokenExpired) {
      setCurrentProcess('clientLogin');
    }
  }, [state]);

  const renderStandardProcess = () => (
    <Container
      sx={{
        alignItems: 'center',
        backgroundColor: 'background',
        display: 'flex',
        justifyContent: 'center'
      }}
    >
      <Container
        className="TopFrame"
        sx={{ backgroundColor: 'primary.main', height: 334 }}
      />
      <AuthProcessContainer>
        {showWelcomeImg && (
          <Box>
            <Typography variant="primary-bold">{t('LOGIN.welcome')}</Typography>
          </Box>
        )}
        <img
          alt="bluberry logo"
          src={bblogo}
          style={{
            height: getProportion(97, 0.8),
            width: getProportion(327, 0.8)
          }}
        />
        <FormContainer>{getCurrentProcess()}</FormContainer>
      </AuthProcessContainer>
      {showWelcomeImg && (
        <img
          alt="welcome"
          src={bgInvitation}
          style={{
            height: getProportion(381, 0.41),
            margin: isMobile ? 38 : 48,
            width: getProportion(754, 0.41)
          }}
        />
      )}
    </Container>
  );

  const renderCompanyInvitationProcess = () => (
    <Invitation
      isCompanyInvitation={isCompanyInvitation}
      login={login}
      setUser={setUser}
    />
  );

  if (currentProcess === 'invitation') {
    return renderCompanyInvitationProcess();
  }

  return renderStandardProcess();
};

AuthProcess.defaultProps = {
  startProcess: 'login'
};

export default AuthProcess;
