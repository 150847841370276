/* eslint-disable no-underscore-dangle */
import {
  Backdrop,
  Card,
  CardContent,
  Typography,
  Box,
  Button,
  Container,
  Modal,
  styled
} from '@mui/material';
import PersonOutlineIcon from '@mui/icons-material/PersonOutline';
import HomeIcon from '@mui/icons-material/Home';
import AddIcon from '@mui/icons-material/Add';
import { EventObject } from '@toast-ui/calendar';
import { useNavigate } from 'react-router-dom';
import { useState } from 'react';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';
import {
  convertUTCToLocalTimeZone,
  formatDateCalendarPopUp
} from '../../utils/general';
import AvatarGroup from '../AvatarGroup';
import useAuthStore from '../../store/zustand/auth';
import { TimeFormatEnum } from '../../api-client/generated/api';
import CompleteServiceButton from '../../views/projects/serviceTimes/components/CompleteServiceButton';
import ShowInformationIndicator from '../modals/ShowInformationIndicator';

const BoxContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  gap: 16
}));

const BoxSimple = styled(Box)(({ theme }) => ({
  display: 'flex',
  gap: 8
}));

export interface LeadServiceTask {
  id: number;
}

export interface Technician {
  email: string;
  fileBank: any[];
  firstName: string;
  id: string;
  lastName: string;
  phoneNumber: string;
  rate: string;
}

export interface Assignments {
  id: string;
  leadServiceTask: LeadServiceTask;
  technician: Technician;
}

export interface UserType {
  email: string;
  firstName: string;
  id: string;
  lastName: string;
  phoneNumber: string;
}

export interface CalendarEventIteration {
  closedBy: string;
  closer: UserType;
  id: number;
  startedBy: string;
  starter: UserType;
  technician: UserType;
}

interface Props {
  calendarEventsData: any;
  detailPopUpData: EventObject;
  isOpen: boolean;
  // eslint-disable-next-line react/require-default-props
  mutate?: () => void;
  popUpPosition: { x: number; y: number };
  selectedView: string;
  setOpenDetailPopup: React.Dispatch<React.SetStateAction<boolean>>;
}

function CalendarDetailPopup({
  isOpen,
  detailPopUpData,
  calendarEventsData,
  popUpPosition,
  selectedView,
  setOpenDetailPopup,
  mutate = () => {}
}: Props) {
  const { t } = useTranslation();

  const { title, attendees, location, raw } = detailPopUpData;
  const [auxManualClose, setAuxManualClose] = useState<boolean>(true);
  const navigate = useNavigate();
  const startDate = new Date(detailPopUpData?.start?.d?.d || '');
  const userState = useAuthStore((state) => state);
  const formattedDate = dayjs(detailPopUpData?.start).format('M/D/YYYY');
  const formattedDateDayFirst = dayjs(detailPopUpData?.start).format(
    'D/M/YYYY'
  );
  const DateONstarted = dayjs(detailPopUpData?.start).format('YYYY-MM-DD');

  let eventFounded = null;
  // eslint-disable-next-line no-restricted-syntax
  for (const key in calendarEventsData) {
    if (
      dayjs(calendarEventsData[key].start).format('YYYY-MM-DD') ===
        DateONstarted ||
      calendarEventsData[key].start === DateONstarted
    ) {
      eventFounded = calendarEventsData[key];
      break; // Terminar el bucle una vez que se encuentre el evento
    }
  }
  const utcUpadtedAt = eventFounded?.updatedAt;

  const technicians = raw?.assignments?.map((assignment: Assignments) => {
    const calendarEvent = raw?.calendarEventIterations?.find(
      (event: CalendarEventIteration) =>
        event?.technician?.id === assignment?.technician?.id
    );

    return {
      closedBy: calendarEvent?.closedBy,
      closer: calendarEvent?.closer,
      startedBy: calendarEvent?.startedBy,
      starter: calendarEvent?.starter,
      technician: {
        email: assignment?.technician?.email,
        firstName: assignment?.technician?.firstName,
        id: assignment?.technician?.id,
        lastName: assignment?.technician?.lastName,
        phoneNumber: assignment?.technician?.phoneNumber
      }
    };
  });

  return (
    <Container
      sx={{
        alignItems: 'normal',
        margin: '40px'
        // padding: '40px'
      }}
    >
      <Modal
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500
        }}
        onClose={() => {
          setOpenDetailPopup(false);
          setAuxManualClose(false);
        }}
        open={auxManualClose}
        sx={{
          alignItems: 'center'
        }}
      >
        <Card
          sx={{
            borderTop: `16px solid ${detailPopUpData.backgroundColor}`,
            display: isOpen ? 'block' : 'none',
            left: popUpPosition.x,
            position: 'sticky',
            top: popUpPosition.y,
            width: '363px'
          }}
        >
          <CardContent
            className="scrollbar"
            sx={{
              maxHeight: '95vh'
            }}
          >
            <BoxContainer flexDirection="column">
              <BoxContainer flexDirection="row">
                <Typography
                  color="primary.500"
                  key="calendar-detail-popup-start-date"
                  variant="heading_300"
                >
                  {formatDateCalendarPopUp(startDate)}
                </Typography>
                <Typography
                  color="primary.400"
                  key="calendar-detail-popup-title"
                  variant="heading_300"
                >
                  {title}
                </Typography>
              </BoxContainer>
              <BoxSimple flexDirection="column">
                {Boolean(attendees.length) && (
                  <BoxSimple alignItems="center" color="surface.main">
                    <PersonOutlineIcon />
                    <Typography color="surface.main" variant="body_200">
                      {attendees.join(', ')}
                    </Typography>
                  </BoxSimple>
                )}

                {selectedView === 'month'
                  ? Boolean(raw?.destinations?.length) && (
                      <Box flexDirection="column" mt={1}>
                        {raw.destinations.map((dest: any) => {
                          return (
                            <Box key={dest.destinationNumber} mb={1}>
                              <Typography
                                color="primary.main"
                                variant="heading_300"
                              >
                                Destination #{dest.destinationNumber}:{' '}
                              </Typography>
                              <Typography
                                alignItems="center"
                                color="surface.main"
                                display="flex"
                                gap={1}
                                variant="body_200"
                              >
                                <HomeIcon fontSize="small" />
                                {typeof dest.address === 'object'
                                  ? dest.address.address || dest.address.name
                                  : dest.address}
                              </Typography>
                              <Box alignItems="center" display="flex" gap={1}>
                                <Typography
                                  color="primary.main"
                                  variant="heading_300"
                                >
                                  'Services'
                                </Typography>
                                <ShowInformationIndicator
                                  technicians={technicians}
                                />
                              </Box>
                              {dest.services?.map((service: any) => (
                                <Box color="surface.main" key={service.id}>
                                  <Typography
                                    fontSize={14}
                                    key="calendar-detail-popup-service-time-name"
                                    variant="secondary-bold"
                                  >
                                    {service.name}
                                  </Typography>
                                  <Typography
                                    fontSize="14px"
                                    fontWeight={700}
                                    key="calendar-detail-popup-starttime-endtime"
                                  >
                                    {service?.formatStartTime ||
                                      convertUTCToLocalTimeZone(
                                        service?.startTime,
                                        undefined,
                                        userState?.timeFormat ===
                                          TimeFormatEnum._24h
                                      )}{' '}
                                    -{' '}
                                    {service?.formatEndTime ||
                                      convertUTCToLocalTimeZone(
                                        service?.endTime,
                                        undefined,
                                        userState?.timeFormat ===
                                          TimeFormatEnum._24h
                                      )}
                                  </Typography>
                                  {service?.services?.map(
                                    (auxServices: any) => (
                                      <Typography
                                        fontSize="14px"
                                        fontWeight={700}
                                        key="calendar-detail-popup-service-name"
                                      >
                                        - {auxServices.name}
                                      </Typography>
                                    )
                                  )}
                                </Box>
                              ))}
                            </Box>
                          );
                        })}
                      </Box>
                    )
                  : Boolean(raw?.services?.length) && (
                      <Box flexDirection="column" mt={2}>
                        {location && (
                          <Typography
                            alignItems="center"
                            color="surface.main"
                            display="flex"
                            gap={1}
                            variant="body_200"
                          >
                            <HomeIcon fontSize="small" />
                            {location}
                          </Typography>
                        )}
                        <Box alignItems="center" display="flex" gap={1}>
                          <Typography
                            color="primary.main"
                            variant="heading_300"
                          >
                            'Tasks'
                          </Typography>
                          <ShowInformationIndicator technicians={technicians} />
                        </Box>
                        {raw.services.map((service: any) => (
                          <Box color="surface.main" key={service.id} mb={1}>
                            <Typography
                              fontSize={14}
                              key="calendar-detail-popup-service-time-name"
                              variant="secondary-bold"
                            >
                              - {service.name}
                            </Typography>
                          </Box>
                        ))}
                      </Box>
                    )}
                {Boolean(raw?.members.length) && (
                  <BoxSimple flexDirection="column">
                    <Typography
                      color="primary.main"
                      fontWeight={700}
                      variant="button_200"
                    >
                      Team:
                    </Typography>
                    <BoxSimple alignItems="center">
                      <AvatarGroup max={10} users={raw.members} />
                    </BoxSimple>
                  </BoxSimple>
                )}
              </BoxSimple>
              <Box
                display="flex"
                flexDirection="column"
                gap={1}
                justifyContent="center"
              >
                {/* {detailPopUpData?.raw?.members.length > 0 && (
                  <CompleteServiceButton
                    calendarView
                    calendarEventId={detailPopUpData.id}
                    daySelected={formattedDateDayFirst}
                    modalDescription={`${t(
                      'PROJECT.SERVICEMODAL.CALENDAR.completeVisitDescription'
                    )}`}
                    modalTitle={t(
                      'PROJECT.SERVICEMODAL.CALENDAR.completeVisit'
                    )}
                    mutate={mutate}
                    projectName={detailPopUpData?.title}
                    state={detailPopUpData?.raw?.status}
                    tasksAmount={detailPopUpData?.raw?.task?.length}
                    technicians={detailPopUpData?.raw?.members}
                  />
                )} */}
                <Button
                  fullWidth
                  onClick={() => {
                    navigate(
                      `/app/projects/detail/${detailPopUpData.raw.projectId}`,
                      {
                        state: {
                          dayChecked: formattedDate,
                          id: detailPopUpData.raw.projectId,
                          lastPage: '/app/calendar',
                          type: 'calendar',
                          utcUpadtedAt
                        }
                      }
                    );
                  }}
                  sx={{
                    alignItems: 'center',
                    display: 'flex',
                    gap: 1
                  }}
                  variant="primary"
                >
                  <AddIcon />
                  Details
                </Button>
              </Box>
            </BoxContainer>
          </CardContent>
        </Card>
      </Modal>
    </Container>
  );
}

export default CalendarDetailPopup;
