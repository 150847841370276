/* eslint-disable react/require-default-props */
import React from 'react';
import { Box, IconButton, Typography, useTheme } from '@mui/material';
import BorderColorOutlinedIcon from '@mui/icons-material/BorderColorOutlined';

interface Props {
  legendColor: string;
  onEdit?: () => void;
  title: string;
  value: string | number;
}

const InformationItem: React.FC<Props> = function InformationItem({
  legendColor = '',
  title = '',
  value = '',
  onEdit
}) {
  const { palette } = useTheme();

  return (
    <Box
      alignItems="center"
      display="flex"
      gap="8px"
      justifyContent="space-around"
      sx={{
        border: `1px solid ${palette.divider}`,
        borderRadius: '8px',
        paddingX: '16px',
        paddingY: '12px',
        width: '49%'
      }}
    >
      <Box
        sx={{
          backgroundColor: legendColor,
          height: '24px',
          marginRight: '12px',
          width: '24px'
        }}
      />
      <Box
        sx={{
          width: '350px'
        }}
      >
        <Typography variant="primary-bold">{title}</Typography>
      </Box>
      <Box
        sx={{
          width: '70px'
        }}
      >
        <Typography>{value}</Typography>
      </Box>

      <Box
        sx={{
          alignSelf: 'end',
          height: '40px',
          width: '40px'
        }}
      >
        {onEdit && (
          <IconButton onClick={onEdit}>
            <BorderColorOutlinedIcon fontSize="small" />
          </IconButton>
        )}
      </Box>
    </Box>
  );
};

export default InformationItem;
