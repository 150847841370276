/* eslint-disable react/jsx-props-no-spreading */
import React, { useEffect, useState } from 'react';
import { Box, Divider, styled, Typography } from '@mui/material';
import { Container, Stack } from '@mui/system';
import { useTranslation } from 'react-i18next';
import {
  Route,
  Routes,
  useLocation,
  useNavigate,
  useParams
} from 'react-router-dom';
import useSWR from 'swr';
import Api from '../../api';
import Password from './invitationFlow/Password';
import Welcome from './invitationFlow/Welcome';
import { removeLocalStorage } from '../../utils/authHelper';
import ProfileInfo from './invitationFlow/ProfileInfo';
import MfaRegistration from './invitationFlow/MfaRegistration';
import bgInvitation from '../../assets/images/invitations/welcomeInvitation.png';
import bluberryLogo from '../../assets/images/Bluberry_Logo.png';
import { themePalette } from '../../theme/themeConfig';
import CompanySettings from './invitationFlow/CompanySettings';
import MfaSuccess from './invitationFlow/MfaSuccess';
import OnboardingComplete from './invitationFlow/OnboardingComplete';
import Stepper from '../Stepper';

interface Props {
  isCompanyInvitation: boolean;
  login: () => void;
  setUser: (user: any) => void;
}

const InvitationContainer = styled(Stack)(() => ({
  height: '100dvh'
}));

const LeftSideContainer = styled(Box)(() => ({
  '@media (max-width: 768px)': {
    display: 'none'
  },
  backgroundColor: themePalette.BACKGROUND,
  display: 'block',
  flex: 1,
  height: '100dvh',
  padding: '32px',
  width: '100%'
}));

const LeftSideContainerImage = styled('img')(() => ({
  height: '100%',
  objectFit: 'contain',
  width: '100%'
}));

const RightSideContainer = styled(Stack)(() => ({
  backgroundColor: '#FFF',
  flex: 1,
  height: '100%',
  width: '100%'
}));

const HeaderContainer = styled(Box)(() => ({
  height: 'fit-content',
  width: '100%'
}));

const HeaderContainerImage = styled('img')(() => ({
  height: '50px',
  objectFit: 'contain'
}));

const FooterContainer = styled(Stack)(() => ({
  borderTop: '1px solid #DDDDDD',
  flexDirection: 'row',
  justifyContent: 'space-between',
  padding: '32px'
}));

const ContentContainer = styled(Box)(() => ({
  '@media (max-width: 768px)': {
    padding: '16px'
  },
  height: '100%',
  padding: '32px',
  width: '100%'
}));

interface InvitationRoutesProps {
  isCompanyInvitation: boolean;
  login: () => void;
  navigate: (data: any, options: any) => void;
  setErrorApi: React.Dispatch<React.SetStateAction<boolean>>;
  setIsStepperDisabled: (isDisabled: boolean) => void;
  setUser: (user: any) => void;
  token: string | undefined;
}

function InvitationRoutes({
  isCompanyInvitation,
  login,
  setUser,
  setErrorApi,
  token,
  setIsStepperDisabled,
  navigate
}: InvitationRoutesProps) {
  return (
    <Routes>
      <Route
        element={<Welcome isCompany={isCompanyInvitation} token={token} />}
        path="/1"
      />
      <Route
        element={
          <Password
            isCompany={isCompanyInvitation}
            setErrorApi={setErrorApi}
            setIsStepperDisabled={setIsStepperDisabled}
            token={token}
          />
        }
        path="/2"
      />
      <Route
        element={
          <ProfileInfo
            isCompany={isCompanyInvitation}
            setErrorApi={setErrorApi}
            setIsStepperDisabled={setIsStepperDisabled}
            token={token}
          />
        }
        path="/3"
      />
      {isCompanyInvitation && (
        <Route
          element={
            <CompanySettings
              setIsStepperDisabled={setIsStepperDisabled}
              token={token}
            />
          }
          path="/4"
        />
      )}
      <Route
        element={
          <MfaRegistration
            isCompany={isCompanyInvitation}
            login={login}
            setErrorApi={setErrorApi}
            setUser={setUser}
            token={token}
          />
        }
        path="/5"
      />
      <Route
        element={
          <MfaSuccess
            isCompany={isCompanyInvitation}
            login={login}
            setErrorApi={setErrorApi}
            setIsStepperDisabled={setIsStepperDisabled}
            setUser={setUser}
            token={token}
          />
        }
        path="/6"
      />
      <Route
        element={
          <OnboardingComplete
            login={login}
            navigate={navigate}
            setUser={setUser}
          />
        }
        path="/7"
      />
    </Routes>
  );
}

const Invitation: React.FC<Props> = function Invitation({
  login,
  isCompanyInvitation,
  setUser
}) {
  const { t } = useTranslation();
  const [error, setError] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>('');
  const { token } = useParams();
  const [fireApiToken, setFireApiToken] = useState<boolean>(false);
  const [errorApi, setErrorApi] = useState<boolean>(false);
  const navigate = useNavigate();
  const location = useLocation();
  const [currentStep, setCurrentStep] = useState<number>(2);
  const [isStepperDisabled, setIsStepperDisabled] = useState<boolean>(true);
  const [currentUrlStep, setCurrentUrlStep] = useState<string | number>('');

  // It will change the endpoint according if its normal invitation or company invitation
  const validationTokenAPI = isCompanyInvitation
    ? Api.authApi.validCompanyInvitation
    : Api.authApi.validInvitation;

  const { data: dataToken, error: errorToken } = useSWR(
    fireApiToken ? token : null,
    validationTokenAPI
  );

  useEffect(() => {
    if (location.pathname) {
      if (isCompanyInvitation) {
        setCurrentUrlStep(location.pathname.split('/')[5]);
      } else {
        setCurrentUrlStep(location.pathname.split('/')[4]);
      }
    }
  }, [location.pathname]);

  const getError = () =>
    !error ? (
      false
    ) : (
      <Container sx={{ mt: 1 }}>
        <Typography
          color="alert.main"
          sx={{ fontSize: 14 }}
          variant="primary-bold"
        >
          &bull; {errorMessage}
        </Typography>
      </Container>
    );

  useEffect(() => {
    setError(false);
    removeLocalStorage('accessToken');
    if (!token) {
      setError(true);
      setErrorMessage(`${t('LOGIN.invalidToken')}`);
      setTimeout(() => {
        navigate('/login');
      }, 5000);
    } else {
      setFireApiToken(true);
    }
  }, []);

  useEffect(() => {
    if (dataToken && dataToken.status === 200) {
      setFireApiToken(false);
      if (isCompanyInvitation) {
        navigate(`/company/invitation/accept-invitation/${token}/1`);
      } else {
        navigate(`/invitation/accept-invitation/${token}/1`);
      }
    }
  }, [dataToken]);

  useEffect(() => {
    if (errorToken && errorToken.response) {
      const { data } = errorToken.response;
      setError(true);
      setErrorMessage(`${data.message}`);
      setFireApiToken(false);
      setErrorApi(true);
      setTimeout(() => {
        navigate('/login');
      }, 5000);
    }
  }, [errorToken]);

  useEffect(() => {
    if (currentUrlStep) {
      if (currentUrlStep !== '6') {
        setIsStepperDisabled(true);
      } else {
        setIsStepperDisabled(false);
      }

      setCurrentStep(Number(currentUrlStep || 0));
    }
  }, [currentUrlStep]);

  const invitationRouteProps = {
    isCompanyInvitation,
    login,
    navigate,
    onSkipClick: () => {
      navigate('/company/invitation/accept-invitation/7');
    },
    setErrorApi,
    setIsStepperDisabled,
    setUser,
    token
  };

  const navigateOnboarding = (navigation: number) => {
    navigate(
      `/${
        isCompanyInvitation ? 'company/' : ''
      }invitation/accept-invitation/${token}/${currentStep + navigation}`
    );
    setCurrentStep((prev) => prev + 1);
  };

  return (
    <InvitationContainer direction="row" gap="12">
      <LeftSideContainer>
        <LeftSideContainerImage alt="Welcome Onboarding" src={bgInvitation} />
      </LeftSideContainer>

      <RightSideContainer>
        <HeaderContainer>
          <Box padding="32px">
            <HeaderContainerImage src={bluberryLogo} />
          </Box>
          <Divider sx={{ borderColor: '#DDDDDD' }} />
        </HeaderContainer>

        <ContentContainer>
          {errorApi ? (
            getError()
          ) : (
            <InvitationRoutes {...invitationRouteProps} />
          )}
        </ContentContainer>
        {currentUrlStep !== '1' && currentUrlStep !== '7' && (
          <FooterContainer>
            <Stepper
              currentStep={currentStep - 2}
              disableNextButton={isStepperDisabled}
              disablePreviousButton={currentStep === 2}
              onNext={() => {
                if (!isCompanyInvitation && currentStep === 3) {
                  navigateOnboarding(2);
                } else {
                  navigateOnboarding(1);
                }
              }}
              onPrevious={() => {
                if (!isCompanyInvitation && currentStep === 5) {
                  navigateOnboarding(-2);
                } else {
                  navigateOnboarding(-1);
                }
              }}
              steps={
                isCompanyInvitation
                  ? [
                      'Password',
                      'Admin Information',
                      'Company Settings',
                      'Multi-factor Authentication',
                      'Multi-factor Success'
                    ]
                  : [
                      'Password',
                      'Admin Information',
                      'Multi-factor Authentication',
                      'Multi-factor Success'
                    ]
              }
            />
          </FooterContainer>
        )}
      </RightSideContainer>
    </InvitationContainer>
  );
};

export default Invitation;
