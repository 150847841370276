/* eslint-disable typescript-sort-keys/interface */
/* eslint-disable react/require-default-props */
import React from 'react';
import { Box, Typography, Button } from '@mui/material';
import { useTranslation } from 'react-i18next';
import CardStimated, { ICardStimated } from './CardStimated';
import { ReactComponent as ChevronUpIcon } from '../../../assets/icons/chevron-up.svg';
import { ReactComponent as ChevronDownIcon } from '../../../assets/icons/chevron-down.svg';
import CardServiceFrequency from './CardServiceFrequency';
import CompleteServiceButton from './components/CompleteServiceButton';

export interface AssignmentsTechnician {
  email: string;
  firstName: string;
  id: string;
  lastName: string;
  phoneNumber: string;
}
export interface Assignments {
  id: string;
  leadServiceTask: number;
  technician: AssignmentsTechnician;
}

export interface Closer {
  email: string;
  firstName: string;
  id: string;
  lastName: string;
  phoneNumber: string;
}

export interface Technician {
  firstName: string;
  id: string;
  lastName: string;
  email: string;
  phoneNumber: string;
}

type CompleteVisit = {
  daySelected?: string;
  projectName?: string;
  tasksAmount?: number;
  technicians?: Technician[];
  mutate?: () => void;
};

interface ICardHeader {
  componentType: any;
  isServiceOpen?: boolean;
  setIsServiceOpen?: () => void;
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  title: string;
  propsTime?: ICardStimated;
  propsType?: string;
  projectType: string;
  detailInfo?: any;
  completeVisit?: CompleteVisit;
}

const CardHeader: React.FC<ICardHeader> = function CardHeader({
  componentType,
  completeVisit,
  title,
  isServiceOpen,
  setIsServiceOpen,
  isOpen,
  setIsOpen,
  propsType,
  propsTime,
  projectType,
  detailInfo
}) {
  const { t } = useTranslation();

  const isExpanded = isServiceOpen !== undefined ? isServiceOpen : isOpen;

  const toggleExpand = () => {
    if (setIsServiceOpen) {
      setIsServiceOpen();
    } else {
      setIsOpen((prev) => !prev);
    }
  };

  const componentTypes: any = {
    estimated: (
      <CardStimated
        estimated={propsTime?.estimated || ''}
        projectType={projectType}
        total={propsTime?.total || ''}
      />
    ),
    service: ['Weekly', 'Biweekly', 'Monthly'].includes(propsType || '') ? (
      <CardServiceFrequency type={propsType || ''} />
    ) : null
  };

  return (
    <Box
      onClick={toggleExpand}
      style={{
        alignContent: 'center',
        alignItems: 'center',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        padding: '8px'
      }}
    >
      <Box alignItems="center" display="flex" gap={1}>
        <Typography sx={{ flexGrow: 1 }} variant="primary-bold">
          {title}
        </Typography>
        {completeVisit?.projectName && (
          <Box sx={{ flexGrow: 0 }}>
            <CompleteServiceButton
              calendarEventId={detailInfo?.id}
              daySelected={completeVisit?.daySelected}
              modalDescription={t(
                'PROJECT.SERVICEMODAL.PROJECT.completeVisitDescription'
              )}
              modalTitle={t('PROJECT.SERVICEMODAL.PROJECT.completeVisit')}
              mutate={completeVisit?.mutate}
              projectName={completeVisit.projectName}
              state={detailInfo?.state}
              tasksAmount={completeVisit?.tasksAmount}
              technicians={completeVisit?.technicians}
            />
          </Box>
        )}
      </Box>
      <Box
        style={{
          alignContent: 'center',
          alignItems: 'center',
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between'
        }}
      >
        {componentTypes[componentType]}
        <Box
          height={30}
          style={{
            alignItems: 'center',
            display: 'flex',
            justifyContent: 'center',
            overflow: 'hidden'
          }}
          width={30}
        >
          <Button
            size="small"
            style={{
              height: '30px',
              width: '30px'
            }}
          >
            {isExpanded ? <ChevronUpIcon /> : <ChevronDownIcon />}
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

export default CardHeader;
