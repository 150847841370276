import { Box, BoxProps, styled, Typography, useTheme } from '@mui/material';
import { useTranslation } from 'react-i18next';
import React, { FC } from 'react';
import { FormLabel } from '../../../../components/form/parts';
import RegularInput from '../../../../components/input/RegularInput';
import AutoCompletePlaces from '../../../../components/input/AutoCompletePlaces';

const FlexBox = styled(Box)<BoxProps>(() => ({
  display: 'flex'
}));

interface props {
  setValues: React.Dispatch<React.SetStateAction<any>>;
  values: any;
}

const BillingPreferences: React.FC<props> = function BillingPreferences({
  setValues,
  values
}) {
  // Hooks
  const theme = useTheme();
  const { t: translate } = useTranslation('translation', {
    // Feel free to create additional hooks if you need translations from a different keyPrefix...
    keyPrefix: 'CLIENT_ONBOARDING'
  });

  // Constants
  const inputFields: { key: string; required?: boolean; type?: string }[] = [
    {
      key: 'billingEmail',
      type: 'email'
    }
  ];

  return (
    <FlexBox
      flexDirection="column"
      sx={{
        gap: theme.spacing(3),
        width: '100%'
      }}
    >
      {/* Title */}
      <Typography
        alignSelf="flex-start"
        color="primary.400"
        sx={{ fontSize: '28px', lineHeight: '30px' }}
        textAlign="center"
        variant="primary-bold"
        whiteSpace="break-spaces"
      >
        {translate('billingPreferencesTitle')}
      </Typography>

      {/* Subtitle */}
      <Typography alignSelf="flex-start">
        {translate('billingPreferencesDescription')}
      </Typography>

      {/* Inputs */}
      <FlexBox flexDirection="column">
        <FlexBox
          flexDirection="column"
          key="billingEmail"
          sx={{ width: '100%' }}
        >
          <FormLabel label={translate(`fields.${'billingEmail'}`)} />
          <RegularInput
            customStyle={{
              width: '100%'
            }}
            name="billingEmail"
            onChange={(value) =>
              setValues({ ...values, billingEmail: [value] })
            }
            type="email"
            value={values.billingEmail}
          />
        </FlexBox>
        <AutoCompletePlaces
          keyName="billingAddress"
          onChange={(value: any) => {
            setValues({ ...values, billingAddress: value.value });
          }}
          setSelected={() => {}}
          value={values.billingAddress}
        />
      </FlexBox>
    </FlexBox>
  );
};

export default BillingPreferences;
