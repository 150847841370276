import {
  Box,
  Typography,
  Checkbox,
  FormControlLabel,
  Button,
  Modal,
  Container,
  Snackbar,
  TextField,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Tooltip,
  styled,
  TextFieldProps
} from '@mui/material';
import { useState, useEffect } from 'react';
import { isMobile } from 'react-device-detect';
import { useTranslation } from 'react-i18next';
import useSWR, { mutate } from 'swr';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Info } from '@mui/icons-material';
import RegularInput from '../input/RegularInput';
import SortableTable from '../list/sortableTable';
import api from '../../api';
import {
  FormLabel,
  HeaderFormContainer,
  TitleButtonsBox,
  TitleFormBox,
  WhiteBoxContainer
} from '../form/parts';
import ButtonFormGroup from '../form/ButtonFormGroup';
import AvailableDaysForm from '../profile/AvailableDaysForm';
import { IOSSwitch } from './parts';
import ViewLoader from '../shared/ViewLoader';
import MinuteComponent from '../input/MinuteComponent';
import {
  CompanyRemindersDTO,
  CompanyTaxDTO,
  GeofencingMeasureEnum
} from '../../api-client/generated/api';
import AutoCompleteInput from '../input/AutoCompleteInput';
import { convertDistance } from '../../utils/geofencingHelper';
import {
  mainColors,
  secondaryColors,
  themeFont,
  themePalette
} from '../../theme/themeConfig';
import CardWithChildren from '../card/CardWithChildren';

interface RequestProps {
  defaultAvailableDays: any[];
  defaultResourceTimeEnd: string;
  defaultResourceTimeStart: string;
}

const GeofencingTextField = styled(TextField)<TextFieldProps>(() => ({
  '& .MuiOutlinedInput-root': {
    borderRadius: 8
  },
  '& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': {
    border: `1px solid ${secondaryColors.SECONDARY_1}`
  },
  backgroundColor: themePalette.BACKGROUND,
  input: {
    color: themePalette.TITLE,
    fontFamily: themeFont.FONT_SECONDARY,
    fontSize: '16px',
    fontWeight: 400,
    height: 40,
    lineHeight: 1.25,
    paddingBottom: '0 !important',
    paddingLeft: 16,
    paddingTop: '0 !important'
  },
  paddingLeft: 0,
  paddingRight: 0
}));

const companyStyle = {
  alignItems: 'flex-start !important',
  display: 'flex',
  flexDirection: 'row !important',
  flexWrap: 'wrap',
  gap: '24px',
  justifyContent: 'flex-start !important',
  width: '100% !important'
};

const style = {
  bgcolor: 'background.paper',
  borderRadius: '0.5em',
  boxShadow: '24',
  left: '50%',
  padding: isMobile ? '40px 30px' : '40px 70px',
  position: 'absolute' as 'absolute',
  top: '50%',
  transform: 'translate(-50%, -50%)',
  width: isMobile ? '90%' : 500
};

function CompanySettings() {
  const { t } = useTranslation();
  const [isEditActive, setIsEditActive] = useState<boolean>(false);
  const [openSuccessful, setOpenSuccessful] = useState<boolean>(false);
  const [openSnackFile, setOpenSnackFile] = useState<boolean>(false);
  const [fireFileApi, setFireFileApi] = useState<boolean>(false);
  const [fireBrochureApi, setFireBrochureApi] = useState<boolean>(false);
  const [fireDeleteApi, setFireDeleteApi] = useState<boolean>(false);
  const [deleteID, setDeleteId] = useState<string>('');
  const [termFile, setTermFile] = useState<any>(null);
  const [brochureFile, setBrochureFile] = useState<any>(null);
  const [formTimeValue, setFormTimeValue] = useState<RequestProps>({
    defaultAvailableDays: [],
    defaultResourceTimeEnd: '',
    defaultResourceTimeStart: ''
  });
  const [formValues, setFormValues] = useState<{
    allowAudit?: boolean;
    brochure?: any;
    defaultAvailableDays?: any[];
    defaultResourceTimeEnd?: any;
    defaultResourceTimeStart?: any;
    enforceGeofencing?: boolean;
    extraHourlyRate?: number;
    fileBank?: any;
    files?: any;
    geofencingMeasure?: string;
    geofencingRadius?: number;
    inactiveTimeLimitDays?: number;
    locationHistoryRetentionDays?: number;
    maxHoursWithoutSupervision?: number;
    minimumInvoice?: number;
    officePhoneNumber?: string;
    paymentTerms?: number;
    reminders?: CompanyRemindersDTO;
    taxes?: CompanyTaxDTO[];
    termRenewalIncrease?: number;
    varianceDown?: number;
    varianceUp?: number;
  }>({
    allowAudit: false,
    brochure: {},
    defaultAvailableDays: [],
    defaultResourceTimeEnd: '',
    defaultResourceTimeStart: '',
    enforceGeofencing: true,
    extraHourlyRate: 0,
    files: [],
    geofencingMeasure: GeofencingMeasureEnum.Miles,
    geofencingRadius: 2,
    inactiveTimeLimitDays: 180,
    locationHistoryRetentionDays: 30,
    maxHoursWithoutSupervision: 0,
    minimumInvoice: 0,
    officePhoneNumber: '',
    paymentTerms: 0,
    reminders: [] as CompanyRemindersDTO,
    taxes: [] as CompanyTaxDTO[],
    termRenewalIncrease: 0,
    varianceDown: 0,
    varianceUp: 0
  });
  const [actionUpdate, setActionUpdate] = useState<boolean>(false);
  const [snackMsgFlag, setSnackMsgFlag] = useState<boolean>(false);
  const [actionCurrentCompany, setActionCurrentCompany] =
    useState<boolean>(true);
  const [error, setError] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string[]>([
    'Something go wrong'
  ]);
  const [disableHours, setDisableHours] = useState<boolean>(false);
  const [errorFields, setErrorFields] = useState<any>([]);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars, no-unused-vars
  const [variantErrorMessage, setVariantErrorMessage] = useState('');

  const { data: currentCompany } = useSWR(
    actionCurrentCompany ? 'api/currentCompany/settings' : null,
    api.CompanyApi.getCurrentCompany
  );

  const { data: updatedCompany, error: updateError } = useSWR(
    actionUpdate ? ['update/currentCompany', { ...formValues }] : null,
    api.CompanyApi.updateCurrentCompany
  );

  const { data: fileResponse, error: errorFile } = useSWR(
    fireFileApi ? { files: [termFile.target.files[0]] } : null,
    api.StorageApi.uploadCompanyTerms
  );

  const { data: brochureResponse, error: errorBrochure } = useSWR(
    fireBrochureApi ? { file: brochureFile.target.files[0] } : null,
    api.StorageApi.uploadCompanyBrochure
  );

  const { data: deleteResponse, error: errorDelete } = useSWR(
    fireDeleteApi
      ? { fileId: deleteID, metaDescription: 'termsAndConditions' }
      : null,
    api.StorageApi.deleteFile
  );

  const clearClient = () => mutate('api/currentCompany/settings', null, true);

  // --------------- FUNCTIONS ---------------
  const onChange = (name: string, value: any) => {
    setFormValues({
      ...formValues,
      [name]: value
    });
  };

  const resetCompanyValues = () => {
    if (currentCompany) {
      const termsConditionArray = currentCompany.fileBank
        ? currentCompany.fileBank
            ?.filter(
              (file: any) => file.metaDescription === 'termsAndConditions'
            )
            .map((values: any) => {
              return {
                description: values.description,
                fileId: values.id,
                fileName: values.fileOriginalName,
                name: values.fileOriginalName
              };
            })
        : [];

      const taxesArray = currentCompany.taxes
        ? currentCompany.taxes.map((tax: any) => {
            return {
              id: tax.id,
              name: tax.name,
              tax: Number(tax.tax),
              type: tax.type
            };
          })
        : [];

      const brochure = currentCompany.fileBank
        ? currentCompany.fileBank.filter(
            (file: any) => file.metaDescription === 'companyBrouchure'
          )
        : [''];

      setFormValues({
        ...formValues,
        brochure: brochure[0],
        defaultAvailableDays: currentCompany?.defaultAvailableDays,
        defaultResourceTimeEnd: currentCompany?.defaultResourceTimeEnd,
        defaultResourceTimeStart: currentCompany?.defaultResourceTimeStart,
        enforceGeofencing: currentCompany.enforceGeofencing,
        extraHourlyRate: currentCompany?.extraHourlyRate,
        files: termsConditionArray,
        geofencingMeasure: currentCompany.geofencingMeasure,
        geofencingRadius: currentCompany.geofencingRadius,
        inactiveTimeLimitDays: currentCompany.inactiveTimeLimitDays,
        locationHistoryRetentionDays:
          currentCompany.locationHistoryRetentionDays,
        maxHoursWithoutSupervision: currentCompany.maxHoursWithoutSupervision,
        minimumInvoice: currentCompany.minimumInvoice,
        officePhoneNumber: currentCompany?.officePhoneNumber,
        paymentTerms: currentCompany?.paymentTerms,
        reminders: {
          invoices: currentCompany.companyReminders?.invoices,
          proposals: currentCompany.companyReminders?.proposals,
          upcomingProjects: currentCompany.companyReminders?.upcomingProjects
        },
        taxes: taxesArray,
        termRenewalIncrease: currentCompany?.termRenewalIncrease,
        varianceDown: currentCompany?.varianceDown,
        varianceUp: currentCompany?.varianceUp
      });
    }
  };

  const handleCancel = () => {
    resetCompanyValues();
    setErrorFields([]);
    setDisableHours(false);
    setIsEditActive(false);
  };
  const handleSubmit = () => {
    const temporalErrorArray: string[] = [];
    const {
      minimumInvoice,
      maxHoursWithoutSupervision,
      reminders,
      extraHourlyRate,
      termRenewalIncrease,
      varianceUp,
      varianceDown,
      paymentTerms
    } = formValues;

    if (minimumInvoice && minimumInvoice < 0) {
      temporalErrorArray.push('minimumInvoice');
    }

    if (paymentTerms && paymentTerms < 0) {
      temporalErrorArray.push('paymentTerms');
    }

    if (extraHourlyRate && extraHourlyRate < 0) {
      temporalErrorArray.push('extraHourlyRate');
    }

    if (termRenewalIncrease && termRenewalIncrease < 0) {
      temporalErrorArray.push('termRenewalIncrease');
    }

    if (maxHoursWithoutSupervision && maxHoursWithoutSupervision < 0) {
      temporalErrorArray.push('maxHoursWithoutSupervision');
    }

    if (reminders) {
      const { upcomingProjects, proposals, invoices } = reminders;

      if (upcomingProjects !== undefined && upcomingProjects <= 0) {
        temporalErrorArray.push('upcomingProjects');
      }

      if (proposals !== undefined && proposals <= 0) {
        temporalErrorArray.push('proposals');
      }

      if (invoices !== undefined && invoices <= 0) {
        temporalErrorArray.push('invoices');
      }
    }

    // validation if integer number
    const isInteger = (value: number) => {
      return Number.isInteger(value);
    };

    if (varianceUp !== undefined) {
      if (varianceUp < 0 || !Number.isInteger(varianceUp)) {
        temporalErrorArray.push('varianceUp');
        setVariantErrorMessage('Field should be a non-negative integer');
      }
    }

    if (varianceDown !== undefined) {
      if (varianceDown < 0 || !Number.isInteger(varianceDown)) {
        temporalErrorArray.push('varianceDown');
        setVariantErrorMessage('Field should be a non-negative integer');
      }
    }

    setErrorFields(temporalErrorArray);

    if (temporalErrorArray.length === 0) {
      delete formValues.brochure;
      const termsConditionArray = formValues.files.map((values: any) => {
        return {
          description: values.description,
          fileId: values.fileId,
          name: values.name
        };
      });
      setFormValues({
        ...formValues,
        defaultAvailableDays: formTimeValue.defaultAvailableDays,
        defaultResourceTimeEnd: formTimeValue.defaultResourceTimeEnd,
        defaultResourceTimeStart: formTimeValue.defaultResourceTimeStart,
        files: termsConditionArray
      });
      setErrorFields([]);
      setActionUpdate(true);
    } else {
      setError(true);
    }
  };

  const getError = () =>
    !error ? (
      false
    ) : (
      <Container sx={{ mt: 1 }}>
        <Typography
          color="alert.main"
          sx={{ fontSize: 14 }}
          variant="primary-bold"
        >
          {errorMessage?.map((error, index) => (
            <span key={`error-${index + 1}`}>
              &bull; &nbsp;
              {error}
              <br />
            </span>
          ))}
        </Typography>
      </Container>
    );

  // --------------- USEEFFECTS ---------------
  useEffect(() => {
    if (currentCompany) {
      resetCompanyValues();
      setActionCurrentCompany(false);
    }
  }, [currentCompany]);

  useEffect(() => {
    if (updateError || errorFile || errorBrochure || errorDelete) {
      const error = [updateError, errorFile, errorBrochure, errorDelete].find(
        (param) => param !== undefined
      );
      const response = error?.response?.data?.message;
      setErrorMessage(typeof response === 'string' ? [response] : response);
      setError(true);
      setActionUpdate(false);
      setOpenSuccessful(true);
    }
  }, [updateError, errorFile, errorBrochure, errorDelete]);

  useEffect(() => {
    if (termFile?.target) {
      setFireFileApi(true);
    }
  }, [termFile]);

  useEffect(() => {
    if (deleteID) {
      setFireDeleteApi(true);
    }
  }, [deleteID]);

  useEffect(() => {
    if (brochureFile?.target) {
      setFireBrochureApi(true);
    }
  }, [brochureFile]);

  useEffect(() => {
    if (updatedCompany) {
      setActionUpdate(false);
      setError(false);
      setOpenSuccessful(true);
      setIsEditActive(false);
      resetCompanyValues();
    }
  }, [updatedCompany]);

  useEffect(() => {
    if (fileResponse) {
      setError(false);
      setSnackMsgFlag(true);
      setFireFileApi(false);
      setOpenSnackFile(true);
      clearClient();
      setTimeout(() => {
        clearClient();
        setActionCurrentCompany(true);
      }, 1000);
    }
  }, [fileResponse]);

  useEffect(() => {
    if (brochureResponse) {
      setError(false);
      setSnackMsgFlag(true);
      setFireBrochureApi(false);
      setOpenSnackFile(true);
      clearClient();
    }
  }, [brochureResponse]);

  useEffect(() => {
    if (deleteResponse) {
      setError(false);
      setSnackMsgFlag(false);
      setFireDeleteApi(false);
      setDeleteId('');
      setOpenSnackFile(true);
      clearClient();
    }
  }, [deleteResponse]);

  if (JSON.stringify(formValues) === '{}') {
    return <ViewLoader />;
  }

  return (
    <Container>
      <Box sx={{ wdith: '100%' }}>
        <HeaderFormContainer isMobile={isMobile}>
          <TitleFormBox isMobile={isMobile} />
          <TitleButtonsBox isEditActive={isEditActive} isMobile={isMobile}>
            <ButtonFormGroup
              actionButtonMsg={t('PROFILE.saveInformation')}
              cancelAction={() => handleCancel()}
              enableEditAction={() => setIsEditActive(true)}
              enableEditButtonMsg={t('PROFILE.editInformation')}
              isMobile={isMobile}
              mainAction={() => {
                handleSubmit();
              }}
              updateFlag={isEditActive}
            />
          </TitleButtonsBox>
        </HeaderFormContainer>
      </Box>

      {/* -------------- Company FORM -------------- */}
      <Container sx={companyStyle}>
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: '24px' }}>
          <Box>
            {/* -------------- INFORMATION -------------- */}
            <WhiteBoxContainer
              display="flex"
              flexDirection="column"
              gap={2}
              isMobile={isMobile}
            >
              <Typography color="mainText.main" variant="secondary">
                {t('COMPANY_SETTINGS.informationTitle')}
              </Typography>
              <Box display="flex" gap="16px">
                <Box width="95%">
                  <CardWithChildren
                    showInTextFormat
                    description=""
                    isEditActive={isEditActive}
                    labelType="Currency"
                    value={formValues.minimumInvoice?.toString()}
                  >
                    <FormLabel
                      error={errorFields.includes('minimumInvoice')}
                      label={t('COMPANY_SETTINGS.minimumInvoice')}
                    />
                    <RegularInput
                      customStyle={{ width: '100%' }}
                      disabled={!isEditActive}
                      error={errorFields.includes('minimumInvoice')}
                      onChange={(event) => {
                        onChange('minimumInvoice', Number(event));
                      }}
                      type="number"
                      value={formValues.minimumInvoice?.toString()}
                    />
                  </CardWithChildren>
                </Box>

                <Box width="100%">
                  <CardWithChildren
                    showInTextFormat
                    description=""
                    isEditActive={isEditActive}
                    labelType=""
                    value={formValues.maxHoursWithoutSupervision?.toString()}
                  >
                    <FormLabel
                      error={errorFields.includes('maxHoursWithoutSupervision')}
                      label={t('COMPANY_SETTINGS.maxHours')}
                    />
                    <RegularInput
                      customStyle={{ width: '100%' }}
                      disabled={!isEditActive || disableHours}
                      error={errorFields.includes('maxHoursWithoutSupervision')}
                      onChange={(event) => {
                        onChange('maxHoursWithoutSupervision', Number(event));
                      }}
                      type="number"
                      value={formValues.maxHoursWithoutSupervision?.toString()}
                    />
                  </CardWithChildren>
                </Box>
                <Box sx={{ width: '100%' }}>
                  <CardWithChildren
                    description=""
                    isEditActive={isEditActive}
                    labelType=""
                    showInTextFormat={false}
                    value={formValues.maxHoursWithoutSupervision}
                  >
                    <Typography color="mainText.400" variant="heading_300">
                      {t('COMPANY_SETTINGS.disableHours')}
                    </Typography>
                    <Checkbox
                      checked={disableHours}
                      disabled={!isEditActive}
                      onChange={(event) => {
                        if (event.target.checked) {
                          onChange('maxHoursWithoutSupervision', 0);
                        } else {
                          onChange(
                            'maxHoursWithoutSupervision',
                            formValues.maxHoursWithoutSupervision
                          );
                        }

                        setDisableHours(event.target.checked);
                      }}
                    />
                  </CardWithChildren>
                </Box>
              </Box>

              <Box width="100%">
                <CardWithChildren
                  showInTextFormat
                  description=""
                  isEditActive={isEditActive}
                  labelType=""
                  value={formValues.officePhoneNumber?.toString()}
                >
                  <FormLabel
                    error={errorFields.includes('officePhoneNumber')}
                    label={`
                    ${t('COMPANY_SETTINGS.officePhoneNumber')}
                    `}
                  />
                  <RegularInput
                    disabled={!isEditActive}
                    error={errorFields.includes('officePhoneNumber')}
                    onChange={(officePhoneNumber) => {
                      onChange('officePhoneNumber', officePhoneNumber);
                    }}
                    type="phone"
                    value={formValues.officePhoneNumber?.toString()}
                  />
                </CardWithChildren>
              </Box>
            </WhiteBoxContainer>
          </Box>

          {/* -------------- REMINDERS -------------- */}
          <WhiteBoxContainer isMobile={isMobile}>
            <Typography color="mainText.main" variant="secondary">
              {t('COMPANY_SETTINGS.remindersTitle')}
            </Typography>
            <Box alignItems="center" display="flex" gap="16px" marginTop="16px">
              <Box width="95%">
                <CardWithChildren
                  showInTextFormat
                  description=""
                  isEditActive={isEditActive}
                  labelType=""
                  value={formValues.reminders?.upcomingProjects?.toString()}
                >
                  <FormLabel
                    error={errorFields.includes('upcomingProjects')}
                    label={t('COMPANY_SETTINGS.upcomingProjects')}
                  />
                  <RegularInput
                    customStyle={{ width: '100%' }}
                    disabled={!isEditActive}
                    error={errorFields.includes('upcomingProjects')}
                    onChange={(event) => {
                      onChange('reminders', {
                        ...formValues.reminders,
                        upcomingProjects: Number(event)
                      });
                    }}
                    type="number"
                    value={formValues.reminders?.upcomingProjects?.toString()}
                  />
                </CardWithChildren>
              </Box>

              <Box width="100%">
                <CardWithChildren
                  showInTextFormat
                  description=""
                  isEditActive={isEditActive}
                  labelType=""
                  value={formValues.reminders?.invoices?.toString()}
                >
                  <FormLabel
                    error={errorFields.includes('invoices')}
                    label={t('COMPANY_SETTINGS.invoices')}
                  />
                  <RegularInput
                    customStyle={{ width: '100%' }}
                    disabled={!isEditActive}
                    error={errorFields.includes('invoices')}
                    onChange={(event) => {
                      onChange('reminders', {
                        ...formValues.reminders,
                        invoices: Number(event)
                      });
                    }}
                    type="number"
                    value={formValues.reminders?.invoices?.toString()}
                  />
                </CardWithChildren>
              </Box>

              <Box width="100%">
                <CardWithChildren
                  showInTextFormat
                  description=""
                  isEditActive={isEditActive}
                  labelType=""
                  value={formValues.reminders?.proposals?.toString()}
                >
                  <FormLabel
                    error={errorFields.includes('proposals')}
                    label={t('COMPANY_SETTINGS.proposals')}
                  />
                  <RegularInput
                    customStyle={{ width: '100%' }}
                    disabled={!isEditActive}
                    error={errorFields.includes('proposals')}
                    onChange={(event) => {
                      onChange('reminders', {
                        ...formValues.reminders,
                        proposals: Number(event)
                      });
                    }}
                    type="number"
                    value={formValues.reminders?.proposals?.toString()}
                  />
                </CardWithChildren>
              </Box>
            </Box>
          </WhiteBoxContainer>

          {/* -------------- VARIANTS -------------- */}
          <WhiteBoxContainer isMobile={isMobile}>
            <Typography color="mainText.main" variant="secondary">
              {t('COMPANY_SETTINGS.variantsTitle')}
            </Typography>
            <Box alignItems="center" display="flex" gap="16px" marginTop="16px">
              <Box width="95%">
                <CardWithChildren
                  description=""
                  isEditActive={isEditActive}
                  labelType=""
                  showInTextFormat={false}
                  value={formValues.varianceUp || 0.0}
                >
                  <FormLabel
                    error={errorFields.includes('varianceUp')}
                    label={t('COMPANY_SETTINGS.varianceUp')}
                  />
                  <Box>
                    <MinuteComponent
                      disabled={!isEditActive}
                      setTime={(v) => {
                        onChange('varianceUp', v);
                        setVariantErrorMessage('');
                      }}
                      time={formValues.varianceUp || 0.0}
                    />
                    {errorFields.includes('varianceUp') && (
                      <Typography
                        color="alert.main"
                        sx={{ fontSize: '11px' }}
                        variant="primary-bold"
                      >
                        {variantErrorMessage}
                      </Typography>
                    )}
                  </Box>
                </CardWithChildren>
              </Box>

              <Box width="100%">
                <CardWithChildren
                  description=""
                  isEditActive={isEditActive}
                  labelType=""
                  showInTextFormat={false}
                  value={formValues.varianceDown || 0.0}
                >
                  <FormLabel
                    error={errorFields.includes('varianceDown')}
                    label={t('COMPANY_SETTINGS.varianceDown')}
                  />
                  <Box>
                    <MinuteComponent
                      disabled={!isEditActive}
                      setTime={(v) => {
                        onChange('varianceDown', v);
                        setVariantErrorMessage('');
                      }}
                      time={formValues.varianceDown || 0.0}
                    />
                    {errorFields.includes('varianceDown') && (
                      <Typography
                        color="alert.main"
                        sx={{ fontSize: '11px' }}
                        variant="primary-bold"
                      >
                        {variantErrorMessage}
                      </Typography>
                    )}
                  </Box>
                </CardWithChildren>
              </Box>
            </Box>
          </WhiteBoxContainer>

          {/* --------------  AVAILBLE DAYS -------------- */}
          <AvailableDaysForm
            diabledButton
            enable={isEditActive}
            externalFormValue={formTimeValue}
            isMobile={isMobile}
            profile={formValues}
            setFormValueExternal={setFormTimeValue}
            type="company"
          />

          {/* -------------- OTHER -------------- */}
          <WhiteBoxContainer
            display="flex"
            flexDirection="column"
            gap={2}
            isMobile={isMobile}
          >
            <Typography color="mainText.main" variant="secondary">
              {t('COMPANY_SETTINGS.other')}
            </Typography>
            <Box alignItems="center" display="flex" gap="16px">
              <Box width="95%">
                <CardWithChildren
                  showInTextFormat
                  description=""
                  isEditActive={isEditActive}
                  labelType=""
                  value={formValues.paymentTerms?.toString()}
                >
                  <FormLabel
                    error={errorFields.includes('paymentTerms')}
                    label={t('COMPANY_SETTINGS.paymentTermsLabel')}
                  />
                  <RegularInput
                    customStyle={{ width: '100%' }}
                    disabled={!isEditActive}
                    error={errorFields.includes('paymentTerms')}
                    onChange={(paymentTerms) => {
                      onChange('paymentTerms', Number(paymentTerms));
                    }}
                    type="number"
                    value={formValues.paymentTerms?.toString()}
                  />
                </CardWithChildren>
              </Box>

              <Box width="100%">
                <CardWithChildren
                  showInTextFormat
                  description=""
                  isEditActive={isEditActive}
                  labelType="Percentage"
                  value={formValues.termRenewalIncrease?.toString()}
                >
                  <FormLabel
                    error={errorFields.includes('termRenewalIncrease')}
                    label={t('COMPANY_SETTINGS.termRenewalLabel')}
                  />
                  <RegularInput
                    customStyle={{ width: '100%' }}
                    disabled={!isEditActive}
                    error={errorFields.includes('termRenewalIncrease')}
                    onChange={(termRenewalIncrease) => {
                      onChange(
                        'termRenewalIncrease',
                        Number(termRenewalIncrease)
                      );
                    }}
                    type="number"
                    value={formValues.termRenewalIncrease?.toString()}
                  />
                </CardWithChildren>
              </Box>

              <Box width="100%">
                <CardWithChildren
                  showInTextFormat
                  description=""
                  isEditActive={isEditActive}
                  labelType="Percentage"
                  value={formValues.extraHourlyRate?.toString()}
                >
                  <FormLabel
                    error={errorFields.includes('extraHourlyRate')}
                    label={t('COMPANY_SETTINGS.extraHourlyRateLabel')}
                  />
                  <RegularInput
                    customStyle={{ width: '100%' }}
                    disabled={!isEditActive}
                    error={errorFields.includes('extraHourlyRate')}
                    onChange={(extraHourlyRate) => {
                      onChange('extraHourlyRate', Number(extraHourlyRate));
                    }}
                    type="number"
                    value={formValues.extraHourlyRate?.toString()}
                  />
                </CardWithChildren>
              </Box>
            </Box>
            <Box>
              <FormControlLabel
                control={<IOSSwitch defaultChecked sx={{ m: 1 }} />}
                disabled={!isEditActive}
                label="Allow Audit"
                onChange={() => {
                  onChange('allowAudit', !formValues.allowAudit);
                }}
              />
            </Box>
          </WhiteBoxContainer>

          {/* -------------- GEOFENCING -------------- */}
          <WhiteBoxContainer
            display="flex"
            flexDirection="column"
            gap={2}
            isMobile={isMobile}
          >
            <Typography color="mainText.main" variant="secondary">
              {t('COMPANY_SETTINGS.geofencingTitle')}
            </Typography>

            <Box alignItems="center" display="flex" gap="16px">
              <Box width="95%">
                <CardWithChildren
                  showInTextFormat
                  description=""
                  isEditActive={isEditActive}
                  labelType=""
                  value={formValues.geofencingMeasure}
                >
                  <FormLabel
                    error={errorFields.includes('geofencingMeasure')}
                    label={t('COMPANY_SETTINGS.geofencingMeasure')}
                  />
                  <AutoCompleteInput
                    closeOnSelect
                    disableClearable
                    disableCreate
                    disabled={!isEditActive}
                    onChange={(event: any, newValue: any | null) => {
                      onChange('geofencingMeasure', newValue.name);
                    }}
                    options={[
                      {
                        id: GeofencingMeasureEnum.Miles,
                        name: GeofencingMeasureEnum.Miles
                      },
                      {
                        id: GeofencingMeasureEnum.Kilometer,
                        name: GeofencingMeasureEnum.Kilometer
                      }
                    ]}
                    renderInput={(params) => (
                      <GeofencingTextField
                        placeholder="Name"
                        // eslint-disable-next-line
                        {...params}
                      />
                    )}
                    value={{
                      id: formValues.geofencingMeasure,
                      name: formValues.geofencingMeasure
                    }}
                  />
                </CardWithChildren>
              </Box>

              <Box width="100%">
                <CardWithChildren
                  showInTextFormat
                  description=""
                  isEditActive={isEditActive}
                  labelType=""
                  value={formValues.geofencingRadius?.toString()}
                >
                  <Box
                    sx={{
                      alignItems: 'center',
                      display: 'flex',
                      justifyContent: 'space-between'
                    }}
                  >
                    <FormLabel
                      error={errorFields.includes('geofencingRadius')}
                      label={`
                    ${t('COMPANY_SETTINGS.geofencingRadius')}
                    ${
                      formValues?.geofencingMeasure !==
                      GeofencingMeasureEnum.Kilometer
                        ? '(km)'
                        : '(mi)'
                    }
                    `}
                    />
                    <Tooltip
                      placement="top"
                      title={
                        formValues?.geofencingMeasure !==
                        GeofencingMeasureEnum.Kilometer
                          ? `${convertDistance(
                              formValues.geofencingRadius,
                              GeofencingMeasureEnum.Kilometer
                            )} mi`
                          : `${convertDistance(
                              formValues.geofencingRadius,
                              GeofencingMeasureEnum.Miles
                            )} km`
                      }
                    >
                      <Info
                        sx={{
                          color: mainColors.MAIN,
                          fontSize: 20,
                          position: 'relative'
                        }}
                      />
                    </Tooltip>
                  </Box>
                  <RegularInput
                    customStyle={{ width: '100%' }}
                    disabled={!isEditActive}
                    error={errorFields.includes('geofencingRadius')}
                    onChange={(geofencingRadius) => {
                      onChange('geofencingRadius', Number(geofencingRadius));
                    }}
                    type="number"
                    value={formValues.geofencingRadius?.toString()}
                  />
                </CardWithChildren>
              </Box>

              <Box width="100%">
                <CardWithChildren
                  showInTextFormat
                  description=""
                  isEditActive={isEditActive}
                  labelType=""
                  value={formValues.locationHistoryRetentionDays?.toString()}
                >
                  <FormLabel
                    error={errorFields.includes('locationHistoryRetentionDays')}
                    label={`
                    ${t('COMPANY_SETTINGS.locationHistoryRetentionDays')}
                    `}
                  />
                  <RegularInput
                    customStyle={{ width: '100%' }}
                    disabled={!isEditActive}
                    error={errorFields.includes('locationHistoryRetentionDays')}
                    onChange={(locationHistoryRetentionDays) => {
                      onChange(
                        'locationHistoryRetentionDays',
                        Number(locationHistoryRetentionDays)
                      );
                    }}
                    type="number"
                    value={formValues.locationHistoryRetentionDays?.toString()}
                  />
                </CardWithChildren>
              </Box>
            </Box>

            <Box>
              <FormControlLabel
                control={
                  <IOSSwitch
                    checked={formValues.enforceGeofencing}
                    sx={{ m: 1 }}
                  />
                }
                disabled={!isEditActive}
                label={t('COMPANY_SETTINGS.enforceGeofencing')}
                onChange={() => {
                  onChange('enforceGeofencing', !formValues.enforceGeofencing);
                }}
              />
            </Box>
          </WhiteBoxContainer>

          {/* -------------- TIMINGS -------------- */}
          <Accordion
            sx={{
              padding: isMobile ? '24px' : '16px 62px',
              width: isMobile ? '80%' : '920px'
            }}
          >
            <AccordionSummary
              aria-controls="panel3a-content"
              expandIcon={<ExpandMoreIcon />}
              id="panel3a-header"
            >
              <Typography color="mainText.main" variant="secondary">
                {t('COMPANY_SETTINGS.timingsTitle')}
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Box
                alignItems="center"
                display="flex"
                gap="16px"
                marginTop="8px"
              >
                <Box width="30%">
                  <CardWithChildren
                    showInTextFormat
                    description=""
                    isEditActive={isEditActive}
                    labelType=""
                    value={formValues.inactiveTimeLimitDays?.toString()}
                  >
                    <FormLabel
                      error={errorFields.includes('inactiveTimeLimitDays')}
                      label={t('COMPANY_SETTINGS.inactiveTimeLimit')}
                    />
                    <RegularInput
                      disabled={!isEditActive}
                      error={errorFields.includes('inactiveTimeLimitDays')}
                      onChange={(inactiveTimeLimitDays) => {
                        onChange(
                          'inactiveTimeLimitDays',
                          inactiveTimeLimitDays
                        );
                      }}
                      type="number"
                      value={formValues.inactiveTimeLimitDays?.toString()}
                    />
                  </CardWithChildren>
                </Box>
              </Box>
            </AccordionDetails>
          </Accordion>

          {/* -------------- TERM & CONDITIONS -------------- */}
          <Accordion
            sx={{
              padding: isMobile ? '24px' : '16px 62px',
              width: isMobile ? '80%' : '920px'
            }}
          >
            <AccordionSummary
              aria-controls="panel6a-content"
              expandIcon={<ExpandMoreIcon />}
              id="panel6a-header"
            >
              <Typography color="mainText.main" variant="secondary">
                {t('COMPANY_SETTINGS.termConditionLabel')}
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Box display="flex" marginTop="8px">
                {[
                  t('COMPANY_SETTINGS.name'),
                  t('COMPANY_SETTINGS.description'),
                  t('COMPANY_SETTINGS.filesTableLabel')
                ].map((item) => (
                  <Box
                    sx={{
                      marginLeft: item !== 'Name' ? '152px' : '0px',
                      width: '100%'
                    }}
                  >
                    <Typography color="mainText.500" variant="primary-bold">
                      {item}
                    </Typography>
                  </Box>
                ))}
              </Box>
              <Box>
                <SortableTable
                  disableSort
                  addManagement={isEditActive}
                  bodyKeys={['name', 'description', 'files']}
                  disabledTable={!isEditActive}
                  handleDelete={setDeleteId}
                  handleFileChange={setTermFile}
                  onChange={(newTermList) => {
                    setFormValues({
                      ...formValues,
                      files: newTermList
                    });
                  }}
                  type="generic-input"
                  values={formValues.files ?? []}
                />
              </Box>
              <Box
                sx={{
                  alignItems: 'left',
                  display: 'flex',
                  flexDirection: 'column',
                  marginTop: '16px'
                }}
              >
                <Box
                  sx={{
                    alignItems: 'left',
                    display: 'flex'
                  }}
                >
                  <Typography color="mainText.500" variant="primary-bold">
                    {t('COMPANY_SETTINGS.brochure')}
                  </Typography>
                </Box>

                <Box marginTop="4px" maxHeight="100px">
                  {formValues?.brochure && formValues?.brochure.fileName ? (
                    <Box>
                      <Typography variant="body_200">
                        <b>Actual brochure:</b> {formValues?.brochure.fileName}
                      </Typography>
                    </Box>
                  ) : null}
                  <input
                    accept="application/vnd.ms-powerpoint, application/pdf"
                    className="custom-file-input-table"
                    disabled={!isEditActive}
                    id="brochure"
                    onChange={setBrochureFile}
                    type="file"
                  />
                </Box>
              </Box>
            </AccordionDetails>
          </Accordion>
          <Modal
            aria-describedby="modal-modal-description-profile"
            aria-labelledby="modal-modal-title-profile"
            onClose={() => setOpenSuccessful(false)}
            open={openSuccessful}
          >
            <Box sx={style}>
              <Box
                sx={{
                  alignItems: 'center',
                  display: 'flex',
                  flexDirection: 'column'
                }}
              >
                <Box sx={{ textAlign: 'center' }}>
                  {error ? (
                    <>
                      <Typography
                        color="alert.main"
                        sx={{ fontSize: 22 }}
                        variant="primary-bold"
                      >
                        Error!
                      </Typography>
                      {getError()}
                    </>
                  ) : (
                    <Typography
                      color="success.main"
                      sx={{ fontSize: 22 }}
                      variant="primary-bold"
                    >
                      {t('PROFILE.successfulMsg')}
                    </Typography>
                  )}
                </Box>
                <br />
                <Button
                  onClick={() => {
                    setOpenSuccessful(false);
                  }}
                  sx={{ width: isMobile ? '100%' : '50%' }}
                  variant="contained"
                >
                  <Typography variant="primary-bold">
                    {t('GENERAL.done')}
                  </Typography>
                </Button>
              </Box>
            </Box>
          </Modal>
          <Snackbar
            anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
            autoHideDuration={3000}
            key="snack-key"
            message={snackMsgFlag ? 'File updated!' : 'Deleted!'}
            onClose={() => setOpenSnackFile(false)}
            open={openSnackFile}
          />
        </Box>
      </Container>
    </Container>
  );
}

export default CompanySettings;
