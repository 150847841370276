import React, { Dispatch, SetStateAction, useState } from 'react';
import {
  Box,
  Grid,
  Typography,
  styled,
  Button,
  useTheme,
  CircularProgress
} from '@mui/material';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import { useTranslation } from 'react-i18next';
import ImageViewer from './ImageViewer';
import { ClientImages } from '../utils/types/ClientOnboardingImages';
import LazyImage from './LazyImage';

const VisuallyHiddenInput = styled('input')({
  bottom: 0,
  clip: 'rect(0 0 0 0)',
  clipPath: 'inset(50%)',
  height: 1,
  left: 0,
  overflow: 'hidden',
  position: 'absolute',
  whiteSpace: 'nowrap',
  width: 1
});

interface ImageGridProps {
  deleteId: string;
  images: ClientImages[];
  isDeletingFile: boolean;
  isUploadingPhoto: boolean;
  onDelete: (id: string) => void;
  setFile: Dispatch<SetStateAction<FileList | null>>;
}

interface SelectedImage {
  image: ClientImages;
  index: number;
}

const ImageGrid: React.FC<ImageGridProps> = function ImageGrid({
  deleteId,
  images,
  isDeletingFile,
  isUploadingPhoto,
  onDelete,
  setFile
}) {
  const { t } = useTranslation();
  const theme = useTheme();
  const [imageSelected, setImageSelected] = useState<SelectedImage | undefined>(
    undefined
  );

  const handlePrevImage = () => {
    if (imageSelected && imageSelected.index > 0) {
      setImageSelected({
        image: images[imageSelected.index - 1],
        index: imageSelected.index - 1
      });
    }
  };

  const handleNextImage = () => {
    if (imageSelected && imageSelected.index + 1 !== images.length) {
      setImageSelected({
        image: images[imageSelected.index + 1],
        index: imageSelected.index + 1
      });
    }
  };

  const handleKeyDown = (event: React.KeyboardEvent) => {
    switch (event.key) {
      case 'ArrowLeft':
        handlePrevImage();
        break;
      case 'ArrowRight':
        handleNextImage();
        break;
      default:
        break;
    }
  };

  return (
    <Box paddingBottom={1}>
      <Box alignItems="center" display="flex" justifyContent="space-between">
        <Typography
          color={theme.palette.mainText.main}
          sx={{ textDecoration: 'underline' }}
          variant="secondary-semi"
        >
          {`${t('COMPANY_SETTINGS.images')} ${images?.length ?? 0}`}
        </Typography>
        <Button
          component="label"
          disabled={isUploadingPhoto}
          size="small"
          startIcon={
            isUploadingPhoto ? (
              <CircularProgress size={14} />
            ) : (
              <CloudUploadIcon />
            )
          }
          variant="primary-text"
        >
          {isUploadingPhoto
            ? t('COMPANY_SETTINGS.uploadingImage')
            : t('COMPANY_SETTINGS.uploadImage')}
          <VisuallyHiddenInput
            // multiple
            accept="application/vnd.ms-powerpoint, application/pdf, image/png, image/jpeg"
            onChange={(event) => {
              if (event?.target?.files) {
                setFile(event.target.files);
              }
            }}
            type="file"
          />
        </Button>
      </Box>

      <Grid container mt={2} spacing={2}>
        {images?.length > 0 &&
          images.map((image, index) => (
            <Grid item key={image.id} md={3} sm={6} xs={12}>
              <LazyImage
                alt={image.description || `Image ${index + 1}`}
                aria-label={`Select ${
                  image.description || `Image ${index + 1}`
                }`}
                onClick={() => setImageSelected({ image, index })}
                src={image.fileUrl}
              />
              <Button
                fullWidth
                color="error"
                disabled={isDeletingFile && image?.id === deleteId}
                onClick={() => onDelete(image.id)}
                variant="outlined"
              >
                {t('COMPANY_SETTINGS.delete')}
              </Button>
            </Grid>
          ))}
      </Grid>

      {imageSelected && (
        <Box onKeyDown={handleKeyDown} tabIndex={0}>
          <ImageViewer
            isOpen
            onNext={handleNextImage}
            onPrev={handlePrevImage}
            setIsOpen={() => setImageSelected(undefined)}
            url={imageSelected.image.fileUrl}
          />
        </Box>
      )}
    </Box>
  );
};

export default ImageGrid;
