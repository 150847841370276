/* eslint-disable react/no-array-index-key */
import React, { useEffect, useState } from 'react';
import { Box, useTheme } from '@mui/system';
import { Button, Tab, Tabs, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import useSWR from 'swr';
import { isMobile } from 'react-device-detect';
import CounterOfferCard from './CounterOfferCard';
import DestinationCard from './DestinationCard';
import api from '../../../../../api';
import useNavigationStore from '../../../../../store/zustand/navigation';
import useNotificationsStore from '../../../../../store/zustand/notifications';

interface Props {
  clientView?: boolean;
  destinations?: Array<{ serviceTimes: Array<any> }>;
  mutate?: () => void;
  onClose: () => void;
  serviceTimes?: Array<any>;
  token?: string;
}

const CounterOfferMain: React.FC<Props> = function CounterOfferMain({
  destinations,
  serviceTimes,
  onClose,
  token,
  clientView,
  mutate
}) {
  // =========================================================================== VARIABLES

  const { t } = useTranslation();
  const { setConfirmModal } = useNavigationStore();
  const { addNotification } = useNotificationsStore((state) => state);
  const theme = useTheme();
  const [activeDestination, setActiveDestination] = useState<number>(0);
  const [activeServiceTime, setActiveServiceTime] = useState<number>(0);
  const [loading, setLoading] = useState<boolean>(true);
  const [deleteServiceTime, setDeleteServiceTime] = useState<Array<any>>([]);
  const [recalculateApi, setRecalculateApi] = useState<boolean>(false);
  const [submitApi, setSubmitApi] = useState<boolean>(false);
  const hasDestinations = destinations && destinations.length > 0;

  const [destinationServices, setDestinationServices] = useState<{
    [key: number]: any[];
  }>(() => {
    if (destinations && destinations.length > 0) {
      const initial: { [key: number]: any[] } = {};
      destinations.forEach((dest, index) => {
        initial[index] = [...dest.serviceTimes];
      });
      return initial;
    }
    return { 0: [...(serviceTimes || [])] };
  });

  // =========================================================================== SWR

  const { data: counterOfferData, error: counterOfferError } = useSWR(
    recalculateApi || submitApi
      ? {
          deleted: deleteServiceTime,
          notModified: [],
          services: Object.values(destinationServices).flat(),
          token,
          url: 'client/proposal/recalculate/submit'
        }
      : null,
    recalculateApi
      ? api.ProposalApi.counterOfferRecalculate
      : api.ProposalApi.counterOfferSubmit
  );

  // =========================================================================== FUNCTIONS

  const removeServiceTime = () => {
    setLoading(true);
    const currentServices = [...destinationServices[activeDestination]];
    setDeleteServiceTime([
      ...deleteServiceTime,
      currentServices[activeServiceTime].id
    ]);
    currentServices.splice(activeServiceTime, 1);
    setDestinationServices({
      ...destinationServices,
      [activeDestination]: currentServices
    });
    if (activeServiceTime !== 0) {
      setActiveServiceTime(activeServiceTime - 1);
    }
    setTimeout(() => {
      setLoading(false);
    }, 1000);
  };

  const resetAllData = () => {
    setLoading(true);
    if (destinations) {
      setDestinationServices({
        ...destinationServices,
        [activeDestination]: [...destinations[activeDestination].serviceTimes]
      });
    } else {
      setDestinationServices({
        ...destinationServices,
        [activeDestination]: [...(serviceTimes || [])]
      });
    }
    setDeleteServiceTime([]);
    setTimeout(() => {
      setLoading(false);
    }, 1000);
  };

  // =========================================================================== LIFECYCLE

  useEffect(() => {
    if (!Number.isNaN(activeServiceTime)) {
      setLoading(true);
    }
    if (destinationServices[activeDestination][activeServiceTime]) {
      setTimeout(() => {
        setLoading(false);
      }, 1000);
    }
  }, [activeServiceTime]);

  useEffect(() => {
    if (
      destinations &&
      !Object.prototype.hasOwnProperty.call(
        destinationServices,
        activeDestination
      )
    ) {
      setDestinationServices((prev) => ({
        ...prev,
        [activeDestination]: [...destinations[activeDestination].serviceTimes]
      }));
    }
    setActiveServiceTime(0);
  }, [activeDestination, destinations]);

  useEffect(() => {
    if (counterOfferData) {
      if (!recalculateApi) {
        onClose();
        if (mutate) {
          mutate();
        }
      }
      setRecalculateApi(false);
      setSubmitApi(false);
      const data = counterOfferData?.values?.counterOfferData;
      setDestinationServices((prev) => ({
        ...prev,
        [activeDestination]: prev[activeDestination].map((service: any) => {
          let index = -1;
          data?.forEach((item: any, i: number) => {
            if (service.id === item.serviceTimeId) {
              index = i;
            }
          });

          if (index >= 0) {
            return {
              ...service,
              actionToCounterOffer: data[index].actionToCounterOffer,
              availability: {
                ...service?.availability,
                generalInfo: {
                  ...service?.availability?.generalInfo,
                  daysOfServiceCount: data[index].numberOfVisits
                }
              },
              sellPrice: data[index].pricePerVisit
            };
          }
          return service;
        })
      }));
    }
  }, [counterOfferData]);

  useEffect(() => {
    if (counterOfferError) {
      const error =
        counterOfferError?.response?.data?.message ||
        t('NOTIFICATION.TOAST.generalError');
      addNotification(error, 'error');
      setRecalculateApi(false);
      setSubmitApi(false);
    }
  }, [counterOfferError]);

  // =========================================================================== RENDER

  return (
    <Box>
      {hasDestinations && (
        <Tabs
          aria-label="destination tabs"
          onChange={(_: React.SyntheticEvent, newValue: number) => {
            setActiveDestination(newValue);
          }}
          value={activeDestination}
        >
          {destinations?.map((_, index) => (
            <Tab
              key={`destination-${index + 1}`}
              label={`${t('LEAD.CREATE.SERVICES.SUMMARY.destination')} #${
                index + 1
              }`}
            />
          ))}
        </Tabs>
      )}
      {hasDestinations && (
        <DestinationCard destination={destinations[activeDestination]} />
      )}
      {destinationServices[activeDestination]?.length > 0 && (
        <Box>
          <Tabs
            aria-label="service time tabs"
            onChange={(_: React.SyntheticEvent, newValue: number) => {
              setActiveServiceTime(newValue);
            }}
            value={activeServiceTime}
          >
            {destinationServices[activeDestination]?.map(
              (serviceTime, index) => (
                <Tab
                  key={`${t('LEAD.CREATE.SERVICES.SUMMARY.serviceTime')}-${
                    index + 1
                  }`}
                  label={`${t('LEAD.CREATE.SERVICES.SUMMARY.serviceTime')} #${
                    index + 1
                  }`}
                />
              )
            )}
          </Tabs>
          <Typography variant="primary">
            <CounterOfferCard
              clientView={clientView}
              deleteServiceTime={removeServiceTime}
              resetValues={() => {
                resetAllData();
              }}
              serviceTime={
                destinationServices[activeDestination][activeServiceTime]
              }
              setRecalculateApi={() => {
                setRecalculateApi(true);
              }}
              setServiceTime={(serviceTime: any) => {
                const updated = [...destinationServices[activeDestination]];
                updated[activeServiceTime] = serviceTime;
                setDestinationServices({
                  ...destinationServices,
                  [activeDestination]: updated
                });
              }}
            />
            {clientView && (
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: isMobile ? 'column' : 'row',
                  gap: 2,
                  justifyContent: 'space-between'
                }}
              >
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    gap: 2,
                    justifyContent: 'space-between',
                    width: isMobile ? '100%' : '65%'
                  }}
                >
                  <Button
                    onClick={() => {
                      onClose();
                    }}
                    sx={{ width: 200 }}
                    variant="cancel"
                  >
                    {t('GENERAL.cancelButton')}
                  </Button>
                  <Button
                    onClick={() => resetAllData()}
                    sx={{ width: 200 }}
                    variant="primary-outlined"
                  >
                    {t('GENERAL.resetButton')}
                  </Button>
                </Box>
                <Button
                  onClick={() => {
                    setConfirmModal(
                      () => {
                        setSubmitApi(true);
                      },
                      t('GENERAL.confirmButton'),
                      () => {},
                      '',
                      <Box
                        sx={{
                          alignItems: 'end',
                          display: 'flex',
                          height: 50,
                          justifyContent: 'center'
                        }}
                      >
                        <Typography
                          color="primary"
                          fontSize={24}
                          variant="heading_400"
                        >
                          {t('LEAD.CREATE.PROPOSAL.confirmSendCounterOffer')}
                        </Typography>
                      </Box>
                    );
                  }}
                  sx={{ width: isMobile ? '100%' : 200 }}
                  variant="primary"
                >
                  {t('GENERAL.submitButton')}
                </Button>
              </Box>
            )}
          </Typography>
        </Box>
      )}
      {(clientView && !destinationServices[activeDestination]) ||
        (destinationServices[activeDestination]?.length === 0 && (
          <Box sx={{ display: 'flex', justifyContent: 'center' }}>
            <Button
              onClick={() => resetAllData()}
              sx={{ width: 150 }}
              variant="primary"
            >
              {t('GENERAL.resetButton')}
            </Button>
          </Box>
        ))}
    </Box>
  );
};

CounterOfferMain.defaultProps = {
  clientView: false,
  destinations: [],
  mutate: undefined,
  serviceTimes: [],
  token: ''
};

export default CounterOfferMain;
