import { Box, BoxProps, styled, Typography, useTheme } from '@mui/material';
import { useTranslation } from 'react-i18next';
import React, { FC } from 'react';
import { isMobile } from 'react-device-detect';
import {
  FlexContainer,
  FormLabel,
  WhiteBoxContainer
} from '../../../../components/form/parts';
import RegularInput from '../../../../components/input/RegularInput';
import SortableTable from '../../../../components/list/sortableTable';

const FlexBox = styled(Box)<BoxProps>(() => ({
  display: 'flex'
}));

interface props {
  setValues: React.Dispatch<React.SetStateAction<any>>;
  values: any;
}

const CommunicationMethod: React.FC<props> = function CommunicationMethod({
  setValues,
  values
}) {
  // Hooks
  const theme = useTheme();
  const { t } = useTranslation('translation', {
    // Feel free to create additional hooks if you need translations from a different keyPrefix...
    keyPrefix: 'CLIENT_ONBOARDING'
  });

  // Constants
  const inputFields: { key: string; required?: boolean }[] = [
    {
      key: 'addressNickname'
    },
    {
      key: 'address'
    }
  ];

  const customStyles = {
    margin: '34px 0px',
    padding: 0,
    width: '100%'
  };

  return (
    <FlexBox
      flexDirection="column"
      sx={{
        gap: theme.spacing(3),
        width: '100%'
      }}
    >
      {/* Title */}
      <Typography
        alignSelf="flex-start"
        color="primary.400"
        sx={{ fontSize: '28px', lineHeight: '30px' }}
        textAlign="center"
        variant="primary-bold"
        whiteSpace="break-spaces"
      >
        {t('communicationMethodTitle')}
      </Typography>

      {/* Subtitle */}
      <Typography alignSelf="flex-start">
        {t('communicationMethodDescription')}
      </Typography>

      {/* Input fields */}
      <WhiteBoxContainer customStyles={customStyles} isMobile={isMobile}>
        <Box>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'left',
              marginTop: '16px'
            }}
          >
            <SortableTable
              addManagement
              disableSort
              bodyKeys={['type', 'value', 'name', 'isPrimary']}
              handleDelete={(id: string) => {
                setValues({
                  ...values,
                  communicationMethodsDeleted:
                    values?.communicationMethodsDeleted
                      ? [...values.communicationMethodsDeleted, id]
                      : [id]
                });
              }}
              name="communicationMethods"
              onChange={(communicationMethods: any) => {
                setValues({
                  ...values,
                  communicationMethods: [...communicationMethods]
                });
              }}
              type="communication-input"
              values={values?.communicationMethods || []}
            />
          </Box>
        </Box>
      </WhiteBoxContainer>
    </FlexBox>
  );
};

export default CommunicationMethod;
