/* eslint-disable import/no-cycle */
/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/no-array-index-key */
/* eslint-disable react/require-default-props */
import React, { useEffect, useRef, useState } from 'react';
import { Box, Typography, Collapse } from '@mui/material';
import dayjs from 'dayjs';
import { useLocation } from 'react-router-dom';
import CardContainerServices from './CardContainerServices';
import { convertDecimalToHora } from '../../../../utils/general';
import Loader from '../../../../components/shared/Loader';
import CollapseBox from '../../CollapseBox';
import { Destination } from '../../../../utils/types/ProjectType';

interface Props {
  dayChecked: string;
  destinations: Destination[];
  detailProjectId: string;
  projectName?: string;
  projectType: string;
  typeView: any;
  utcUpadtedAt: string;
}
const ServicesTimeContainer: React.FC<Props> = function ServicesTimeContainer({
  detailProjectId,
  projectType,
  typeView,
  dayChecked,
  utcUpadtedAt,
  destinations,
  projectName
}) {
  const location = useLocation();
  const currentUrl = location.pathname;
  const firstRender = useRef(true);
  const isClientPage = currentUrl.includes('customer-page');
  const currentDate = new Date();
  const formattedDateToday = dayjs(currentDate).format('D/M/YYYY');

  const [openDestination, setOpenDestination] = useState<{
    [destinationId: string]: boolean;
  }>({});

  const [openServiceId, setOpenServiceId] = useState<number | null>(null);

  // Open the first collapse on initial render
  useEffect(() => {
    if (firstRender.current) {
      firstRender.current = false;
      return;
    }
    if (destinations?.length > 0) {
      const firstDestinationId = String(destinations[0].id);
      setOpenDestination({ [firstDestinationId]: true });
    }
    if (
      destinations?.length > 0 &&
      destinations[0].serviceTimes[0].services.length > 0
    ) {
      setOpenServiceId(destinations[0].serviceTimes[0].id);
    }
  }, [destinations]);

  // Ensure only one collapse is open at a time
  const toggleDestination = (destinationId: string) => {
    setOpenDestination((prev) => {
      const isCurrentlyOpen = !!prev[destinationId];
      return { [destinationId]: !isCurrentlyOpen };
    });
  };

  const toggleService = (serviceId: number) => {
    setOpenServiceId((prevId) => (prevId === serviceId ? null : serviceId));
  };

  const headerServiceTme = () => {
    return (
      <Box display="flex" flexDirection="column" gap={1}>
        <Typography variant="primary-bold">
          {projectType === 'Regular' ? 'Regular' : 'Ad-hoc'} Service times
        </Typography>

        {projectType === 'Ad-Hoc' && (
          <Box display="flex" gap={0.5}>
            <Typography color="secondary.400" fontSize={12} variant="secondary">
              {`Travel time (round trip): `}
            </Typography>
            <Typography
              color="secondary.500"
              fontSize={13}
              variant="primary-bold"
            >
              {`${convertDecimalToHora(
                Number(destinations[0]?.serviceTimes[0]?.travelTime)
              )}`}
            </Typography>
          </Box>
        )}
      </Box>
    );
  };

  if (destinations?.length <= 0 || !projectType) {
    return (
      <Box
        style={{ width: '100%' }}
        sx={{
          display: 'flex',
          flexGrow: 1,
          padding: '5px'
        }}
      >
        <Loader customColor="primary" />
      </Box>
    );
  }

  // eslint-disable-next-line react/no-unstable-nested-components
  return (
    <Box display="flex" flexDirection="column" gap={2}>
      <Box display="flex" flexDirection="column">
        {headerServiceTme()}
      </Box>

      <Box display="flex" flexDirection="column" gap={2}>
        {destinations.map((destination) => (
          <CollapseBox
            destination={destination}
            isCollapseOpen={openDestination[destination.id]}
            key={destination.id}
            projectSetupTab="serviceAndTask"
            projectType={projectType}
            toggleDestination={() => toggleDestination(String(destination.id))}
          >
            <Collapse
              unmountOnExit
              in={!!openDestination[String(destination.id)]}
              timeout="auto"
            >
              <Box
                display="flex"
                flexDirection="column"
                gap={2.5}
                marginTop={2.5}
              >
                {destination &&
                  destination.serviceTimes?.length > 0 &&
                  destination.serviceTimes.map((serviceTime, index: number) => {
                    return (
                      <CardContainerServices
                        count={`${index + 1}`}
                        data={serviceTime}
                        dayChecked={
                          typeView === 'calendar'
                            ? dayjs(dayChecked).format('D/M/YYYY')
                            : formattedDateToday
                        }
                        detailProjectId={detailProjectId}
                        isOpen={openServiceId === serviceTime.id}
                        key={serviceTime.id}
                        projectName={projectName}
                        projectType={projectType}
                        setIsOpen={() => toggleService(serviceTime.id)}
                        type="serviceTime"
                        typeView={typeView}
                        utcUpadtedAt={utcUpadtedAt}
                      />
                    );
                  })}
              </Box>
            </Collapse>
          </CollapseBox>
        ))}
      </Box>
    </Box>
  );
};
export default ServicesTimeContainer;
