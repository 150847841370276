import React from 'react';
import { Box, Grid, Typography, useTheme } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { secondaryColors } from '../../../theme/themeConfig';
import { getCurrency } from '../../../utils/general';
import ShowInformationIndicator from '../../../components/modals/ShowInformationIndicator';

type UserType = {
  email: string;
  firstName: string;
  id: string;
  lastName: string;
  phoneNumber: string;
};

interface DataList {
  actualEnd: string;
  actualStart: string;
  actualTotal: number;
  clientName: string;
  closedBy?: string;
  closer?: UserType;
  companyName: string;
  day: string;
  id: number;
  laborHourCost: string;
  laborTotalCost: string;
  millisecondsWorked: string;
  projectName: string;
  scheduledEnd: string;
  scheduledStart: string;
  scheduledTotal: number;
  startedBy?: string;
  starter?: UserType;
  status: string;
  taskName: string;
  technician: string;
  technicianInformation: UserType;
  travelTime: number;
  travelTimeInMilliseconds: string;
  type: string;
  typeText: string;
  variance: number;
  varianceActual: string;
  variancePercentage: string;
}

interface TimesheetExpandedReviewRowProps {
  dataList: DataList;
}

const TimesheetExpandedReviewRow: React.FC<TimesheetExpandedReviewRowProps> =
  function TimeSheetCollapsedView({ dataList }) {
    // Hooks
    const theme = useTheme();
    const { t } = useTranslation();

    const technicians = [
      {
        closedBy: dataList?.closedBy,
        closer: dataList?.closer,
        startedBy: dataList?.startedBy,
        starter: dataList?.starter,
        technician: dataList?.technicianInformation
      }
    ];

    // Render
    return (
      <Box
        bgcolor="#FCFCFC"
        display="flex"
        flexDirection="column"
        gap={theme.spacing(3)}
        paddingX={theme.spacing(1)}
        paddingY={theme.spacing(3)}
      >
        {/* Project information */}
        <Box>
          <Box alignItems="center" display="flex" gap={1}>
            <Typography fontSize={18} variant="primary-bold">
              {t('TIMESHEET.projectInformationTitle')}
            </Typography>
            <ShowInformationIndicator technicians={technicians} />
          </Box>
          <Grid
            container
            justifyContent="space-between"
            paddingX={theme.spacing(1)}
            paddingY={theme.spacing(3)}
            xs={12}
          >
            <Grid
              item
              xs
              display="flex"
              flexDirection="column"
              gap={theme.spacing(1)}
            >
              <Typography color={secondaryColors.SECONDARY} fontSize={14}>
                {t('TIMESHEET.taskName')}
              </Typography>
              <Typography fontSize={16} variant="primary-bold">
                {dataList.taskName}
              </Typography>
            </Grid>
            <Grid
              item
              xs
              display="flex"
              flexDirection="column"
              gap={theme.spacing(1)}
            >
              <Typography color={secondaryColors.SECONDARY} fontSize={14}>
                {t('TIMESHEET.client')}
              </Typography>
              <Typography fontSize={16} variant="primary-bold">
                {dataList.clientName}
              </Typography>
            </Grid>
            <Grid
              item
              xs
              display="flex"
              flexDirection="column"
              gap={theme.spacing(1)}
            >
              <Typography color={secondaryColors.SECONDARY} fontSize={14}>
                {t('TIMESHEET.companyName')}
              </Typography>
              <Typography fontSize={16} variant="primary-bold">
                {dataList.companyName}
              </Typography>
            </Grid>
            <Grid
              item
              xs
              display="flex"
              flexDirection="column"
              gap={theme.spacing(1)}
            >
              <Typography color={secondaryColors.SECONDARY} fontSize={14}>
                {t('TIMESHEET.laborHourCost')}
              </Typography>
              <Typography fontSize={16} variant="primary-bold">
                {getCurrency(dataList.laborHourCost)}
              </Typography>
            </Grid>
            <Grid
              item
              xs
              display="flex"
              flexDirection="column"
              gap={theme.spacing(1)}
            >
              <Typography color={secondaryColors.SECONDARY} fontSize={14}>
                {t('TIMESHEET.totalCost')}
              </Typography>
              <Typography fontSize={16} variant="primary-bold">
                {getCurrency(dataList.laborTotalCost)}
              </Typography>
            </Grid>
          </Grid>
        </Box>

        {/* Time Track */}
        <Box>
          <Typography fontSize={18} variant="primary-bold">
            {t('TIMESHEET.timeTrackTitle')}
          </Typography>
          <Grid
            container
            justifyContent="space-between"
            paddingX={theme.spacing(1)}
            paddingY={theme.spacing(3)}
            xs={12}
          >
            <Grid
              item
              xs
              display="flex"
              flexDirection="column"
              gap={theme.spacing(1)}
            >
              <Typography color={secondaryColors.SECONDARY} fontSize={14}>
                {t('TIMESHEET.actualStart')}
              </Typography>
              <Typography fontSize={16} variant="primary-bold">
                {dataList.actualStart}
              </Typography>
            </Grid>
            <Grid
              item
              xs
              display="flex"
              flexDirection="column"
              gap={theme.spacing(1)}
            >
              <Typography color={secondaryColors.SECONDARY} fontSize={14}>
                {t('TIMESHEET.actualEnd')}
              </Typography>
              <Typography fontSize={16} variant="primary-bold">
                {dataList.actualEnd}
              </Typography>
            </Grid>
            <Grid
              item
              xs
              display="flex"
              flexDirection="column"
              gap={theme.spacing(1)}
            >
              <Typography color={secondaryColors.SECONDARY} fontSize={14}>
                {t('TIMESHEET.scheduleStart')}
              </Typography>
              <Typography fontSize={16} variant="primary-bold">
                {dataList.scheduledStart}
              </Typography>
            </Grid>
            <Grid
              item
              xs
              display="flex"
              flexDirection="column"
              gap={theme.spacing(1)}
            >
              <Typography color={secondaryColors.SECONDARY} fontSize={14}>
                {t('TIMESHEET.scheduleEnd')}
              </Typography>
              <Typography fontSize={16} variant="primary-bold">
                {dataList.scheduledEnd}
              </Typography>
            </Grid>
            <Grid
              item
              xs
              display="flex"
              flexDirection="column"
              gap={theme.spacing(1)}
            >
              <Typography color={secondaryColors.SECONDARY} fontSize={14}>
                {t('TIMESHEET.projectType')}
              </Typography>
              <Typography fontSize={16} variant="primary-bold">
                {dataList.typeText}
              </Typography>
            </Grid>
          </Grid>
        </Box>
      </Box>
    );
  };

export default TimesheetExpandedReviewRow;
