import React, { useEffect, useMemo, useRef, useState } from 'react';
import {
  Avatar,
  Box,
  Button,
  TypographyProps,
  Collapse,
  styled,
  BoxProps,
  Typography,
  useTheme
} from '@mui/material';
import CallOutlinedIcon from '@mui/icons-material/CallOutlined';
import SendIcon from '@mui/icons-material/Send';
import useSWR from 'swr';
import { useTranslation } from 'react-i18next';
import { jwtDecode } from 'jwt-decode';
import { isMobile } from 'react-device-detect';
import RegularInput from '../../components/input/RegularInput';
import api from '../../api';
import { formatDateTime, sortByDay } from '../../utils/general';
import DeleteButton from '../../components/list/DeleteButton';
import DeleteModal from '../../components/modals/DeleteModal';
import { dayFullDateParser } from '../../utils/widgetHelper';
import {
  mainColors,
  secondaryColors,
  textColors,
  themeFont,
  themePalette
} from '../../theme/themeConfig';
import useNotificationsStore from '../../store/zustand/notifications';

type BearerContents = { companyId: string; roles: string[]; userId: string };

interface ScrollableMessageContainerProps extends BoxProps {
  technician: boolean | undefined;
}

const ScrollableMessageContainer = styled(Box)<ScrollableMessageContainerProps>(
  ({ theme, technician }) => ({
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(2),
    marginTop: technician ? theme.spacing(0) : theme.spacing(2),
    maxHeight: technician ? '300px' : '450px',
    padding: technician ? theme.spacing(2) : 0
  })
);

const MsgContainer = styled(Box)<BoxProps>(({ theme }) => ({
  border: `1px solid ${theme.palette.primary.main}`,
  borderRadius: theme.spacing(1),
  padding: theme.spacing(2)
}));

const ContactText = styled(Typography)<TypographyProps>(({ theme }) => ({
  color: textColors.DARK_TEXT,
  fontFamily: themeFont.FONT_SECONDARY,
  fontSize: '14px',
  fontWeight: '400',
  lineHeight: '20px'
}));

const ContactContact = styled(Box)<BoxProps>(({ theme }) => ({
  alignItems: 'center',
  borderRadius: '8px',
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  minHeight: '40px !important'
}));

interface DataEntity {
  createdAt: string;
  id: string;
  noteText: string;
  user: {
    fileBank: { fileUrl: string }[];
    firstName: string;
    lastName: string;
    userRoles: { role: { name: string } }[];
  };
}

function parseEntityData(data: DataEntity[]) {
  return data.map((item) => ({
    createdAt: item.createdAt,
    fileUrl: item.user.fileBank[0]?.fileUrl,
    id: item.id,
    name: `${item.user.firstName} ${item.user.lastName}`,
    noteText: item.noteText,
    sendByType:
      item.user.userRoles.length > 0 ? item.user.userRoles[0].role.name : null
  }));
}

interface DataNote {
  createdAt: string;
  id: string;
  noteText: string;
  operator: {
    fileBank: { fileUrl: string }[];
    firstName: string;
    lastName: string;
    userRoles: { role: { name: string } }[];
  };
}

function parseNoteData(data: DataNote[]) {
  return data.map((note) => {
    return {
      createdAt: note.createdAt,
      fileUrl: note.operator?.fileBank?.[0]?.fileUrl ?? '',
      id: note.id,
      name: `${note.operator.firstName} ${note.operator.lastName}`,
      noteText: note.noteText,
      sendByType: note.operator?.userRoles?.[0].role.name
    };
  });
}

interface Props {
  entity?: string;
  // can be the proposal id or the token
  eventId?: string;
  hideDeleteButton?: boolean;
  id?: string;
  technicianNotes?: boolean;
  technicianView?: boolean;
}

const Notes: React.FC<Props> = function Notes({
  eventId,
  hideDeleteButton,
  id,
  entity,
  technicianView,
  technicianNotes
}) {
  const { t } = useTranslation();
  const theme = useTheme();
  const { addNotification } = useNotificationsStore((state) => state);
  const deleteAction = api.ProjectApi.deleteNotes;
  const [postMessageApi, setPostMessageApi] = useState<boolean>(false);
  const [notesData, setNotesData] = useState([]);
  const [message, setMessage] = useState<string>('');
  const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);
  const [openContactInfo, setOpenContactInfo] = useState<boolean>(false);
  const [deleteItem, setDelete] = useState<string | null>(null);
  const messageRef = useRef<any>();
  const [contactInformation, setContactInformation] = useState<any>();
  const [
    fireFindContactInformationByCalendarEvent,
    setFireFindContactInformationByCalendarEvent
  ] = useState<boolean>(true);

  const containerRef = useRef<HTMLDivElement>(null);

  const bearerToken = useMemo(
    () => `Bearer ${localStorage.getItem('accessToken')?.replaceAll('"', '')}`,
    []
  );
  const { roles: tokenRoles }: BearerContents = jwtDecode(bearerToken);

  // Get messages
  const getAction = entity
    ? api.NotesApi.getAllByEntity
    : api.ProjectApi.getNotes;

  const getObject = entity
    ? { entity, entityId: id, url: `notes/${entity}/${id}` }
    : { id, url: `projects/notes/${id}` };

  const { data: notesResponse, mutate: mutateNotes } = useSWR(
    id ? getObject : null,
    getAction
  );

  // Post message
  const postAction = entity ? api.NotesApi.postNote : api.ProjectApi.postNote;

  const postObject = entity
    ? {
        entity,
        entityId: `${id}`,
        noteText: message,
        taskIterationId: id ? parseInt(id, 10) : 0
      }
    : { id, text: message, title: '', url: 'postNote' };

  const {
    data: postMessageData,
    mutate: postMessageMutate,
    error: postMessageError
  } = useSWR(postMessageApi ? postObject : null, postAction);

  const { data: contactInformationResponse } = useSWR(
    fireFindContactInformationByCalendarEvent && eventId
      ? { id: eventId }
      : null,
    api.CalendarApi.findContactInformationByCalendarEvent
  );

  const clearApiResponse = () => {
    postMessageMutate(undefined, true);
  };

  // Scroll to the bottom when messages update
  useEffect(() => {
    if (containerRef.current) {
      containerRef.current.scrollTo({
        behavior: 'smooth',
        top: containerRef.current.scrollHeight
      });
    }
  }, [notesData]);

  useEffect(() => {
    if (notesResponse) {
      setNotesData(notesResponse);
    }
  }, [notesResponse]);

  useEffect(() => {
    if (postMessageData) {
      setMessage('');
      setPostMessageApi(false);
      mutateNotes();
      addNotification(t('NOTIFICATION.TOAST.messageSent'), 'success');
    }
  }, [postMessageData]);

  useEffect(() => {
    if (postMessageError) {
      setPostMessageApi(false);
      addNotification(t('NOTIFICATION.TOAST.messageFail'), 'error');
    }
  }, [postMessageError]);

  useEffect(() => {
    setFireFindContactInformationByCalendarEvent(true);
  }, [id]);

  useEffect(() => {
    if (contactInformationResponse) {
      setFireFindContactInformationByCalendarEvent(false);
      setContactInformation(contactInformationResponse);
    }
  }, [contactInformationResponse]);

  const dateText = (date: string) => {
    return (
      <Typography
        color="secondary"
        display="flex"
        flex="none"
        variant="body_200"
      >
        {date}
      </Typography>
    );
  };

  // We only can reset the component content disabled enable it
  const inputBox = () => {
    return (
      <Box alignItems="center" display="flex" gap={2}>
        <Box display="flex" justifyContent="center" width="100%">
          {postMessageApi && (
            <RegularInput
              disabled
              customStyle={{
                backgroundColor: `${theme.palette.background.paper} !important`,
                border: `1px solid ${theme.palette.secondary[100]}`,
                height: '46px',
                width: '100%'
              }}
            />
          )}
          {!postMessageApi && (
            <RegularInput
              customStyle={{
                backgroundColor: `${theme.palette.background.paper} !important`,
                border: `1px solid ${theme.palette.secondary[100]}`,
                height: '46px',
                width: '100%'
              }}
              onChange={(value: any) => {
                setMessage(value);
              }}
              value={message}
            />
          )}
        </Box>
        <Button
          onClick={() => {
            if (!message || message === '') {
              addNotification(t('NOTIFICATION.TOAST.messageEmpty'), 'error');
            } else {
              clearApiResponse();
              setPostMessageApi(true);
            }
          }}
          sx={{ width: isMobile ? 50 : 150 }}
          variant="primary"
        >
          <Typography
            sx={{ display: 'flex', flexDirection: 'row', gap: 2 }}
            variant="primary-bold"
          >
            {isMobile ? (
              <SendIcon fontSize="small" />
            ) : (
              t('LEAD.CREATE.PROPOSAL.CHAT.button')
            )}
          </Typography>
        </Button>
      </Box>
    );
  };

  const messageItem = (
    avatar: string,
    name: string,
    date: string,
    text: string,
    sendByType: string,
    id: string
  ) => {
    const order = tokenRoles.includes(sendByType) ? 0 : 1;
    return (
      <MsgContainer>
        <Box
          display="flex"
          flexDirection={isMobile ? 'column' : 'row'}
          justifyContent="space-between"
          width="100%"
        >
          <Box
            alignItems="center"
            display="flex"
            gap={2}
            order={order}
            width="100%"
          >
            <Box alignItems="center" display="flex" gap={2} order={order}>
              <Avatar alt={name} src={avatar} />
            </Box>
            <Box
              display="flex"
              flexDirection={isMobile ? 'column' : 'row'}
              justifyContent="space-between"
              width="100%"
            >
              <Typography
                color="primary"
                textAlign={order ? 'left' : 'right'}
                variant="heading_300"
              >
                {name}
              </Typography>
              <Typography color="secondary" variant="body_200">
                {date}
              </Typography>
            </Box>
          </Box>
        </Box>

        <Box
          display="flex"
          flexDirection={isMobile ? 'column' : 'row'}
          justifyContent="space-between"
          width="100%"
        >
          <Box mt={2} textAlign={order ? 'right' : 'left'} width="100%">
            <Typography variant="body_200">{text}</Typography>
          </Box>
          {!hideDeleteButton && !technicianView && (
            <DeleteButton
              deleteFn={() => {
                setDelete(id);
                setShowDeleteModal(true);
              }}
            />
          )}
        </Box>
      </MsgContainer>
    );
  };

  const getMessages = () => {
    const getDataParsed = entity
      ? parseEntityData(notesData)
      : parseNoteData(notesData);

    const getMessagesSorted = sortByDay(getDataParsed, 'createdAt');
    const keys = Object.keys(getMessagesSorted);

    return keys.map((date: string) => (
      <Box key={date} sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
        <Box sx={{ display: 'flex', justifyContent: 'center' }} width="100%">
          <Typography color="secondary" variant="secondary">
            {dayFullDateParser(date)}
          </Typography>
        </Box>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: 3
          }}
        >
          {getMessagesSorted?.[date].map((item: any) => {
            return messageItem(
              item?.fileUrl,
              item.name,
              formatDateTime(item?.sentAt),
              item?.noteText,
              item?.sendByType,
              item?.id
            );
          })}
        </Box>
      </Box>
    ));
  };

  const getTechnicianMessages = () => {
    const getMessagesParsed = sortByDay(notesData, 'createdAt');
    const keys = Object.keys(getMessagesParsed);

    const taskList = keys.map((date: string) => {
      const noteTextList = getMessagesParsed?.[date].map((item: any) => {
        return (
          <Box
            key={date}
            sx={{
              alignItems: 'center',
              backgroundColor: themePalette.BACKGROUND,
              borderRadius: '8px',
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
              mb: '8px',
              minHeight: '52px',
              padding: '16px'
            }}
          >
            <ContactText>{item?.noteText}</ContactText>
          </Box>
        );
      });

      return noteTextList;
    });

    return (
      <Box
        sx={{
          border: `1px solid ${secondaryColors.SECONDARY_2}`,
          borderRadius: '8px',
          height: '236px',
          overflowY: 'scroll',
          padding: '16px 16px 8px 16px'
        }}
      >
        {taskList}
      </Box>
    );
  };

  const getContactInfo = () => {
    return (
      <Box
        sx={{
          backgroundColor: theme.palette.background.paper,
          border: `1px solid ${theme.palette.primary[100]}`,
          borderRadius: '8px',
          padding: '16px'
        }}
      >
        <Box
          sx={{
            alignItems: 'center',
            boxSizing: 'border-box',
            display: 'flex',
            justifyContent: 'space-between',
            width: '100%'
          }}
        >
          <Typography
            fontFamily={themeFont.FONT_SECONDARY}
            fontSize={14}
            fontWeight={400}
            lineHeight="20px"
            textAlign="justify"
          >
            Support
          </Typography>
          <Box display="inline-flex">
            <Button
              onClick={() => {
                setOpenContactInfo(!openContactInfo);
              }}
              sx={{ minWidth: 'auto', padding: '0px' }}
            >
              <i
                className="custom-icon icon-select"
                style={{
                  color: secondaryColors.SECONDARY,
                  fontSize: 9,
                  padding: '0px',
                  transform: `rotate(${openContactInfo ? '180deg' : '0deg'})`
                }}
              />
            </Button>
          </Box>
        </Box>
        <Collapse in={openContactInfo}>
          <Box display="flex" flexDirection="column" gap={1} mt={1.5}>
            {contactInformation?.supervisorPhoneNumber && (
              <ContactContact>
                <ContactText>
                  {t('MODAL.supervisorNumber', [
                    contactInformation?.supervisorPhoneNumber
                  ])}
                </ContactText>
                <CallOutlinedIcon sx={{ fill: mainColors.MAIN }} />
              </ContactContact>
            )}
            {contactInformation?.officePhoneNumber && (
              <ContactContact>
                <ContactText>
                  {t('MODAL.officeNumber', [
                    contactInformation?.officePhoneNumber
                  ])}
                </ContactText>
                <CallOutlinedIcon sx={{ fill: mainColors.MAIN }} />
              </ContactContact>
            )}
            {contactInformation?.contactPhoneNumber && (
              <ContactContact>
                <ContactText>
                  {t('MODAL.contactNumber', [
                    contactInformation?.contactPhoneNumber
                  ])}
                </ContactText>
                <CallOutlinedIcon sx={{ fill: mainColors.MAIN }} />
              </ContactContact>
            )}
          </Box>
        </Collapse>
      </Box>
    );
  };

  return (
    <Box pr={2} pt={4} width="100%">
      {!technicianView && !technicianNotes && (
        <Typography pl={2} variant="heading_400">
          {t('NOTES.title')}
        </Typography>
      )}
      <ScrollableMessageContainer
        className="scrollbar"
        ref={containerRef}
        technician={technicianView}
      >
        {!technicianView && notesData?.length > 0 && getMessages()}
        {technicianView && notesData?.length > 0 && getTechnicianMessages()}
        {technicianView &&
          contactInformation?.officePhoneNumber &&
          getContactInfo()}
        <Box ref={messageRef} />
      </ScrollableMessageContainer>

      {!technicianView && inputBox()}

      {showDeleteModal && (
        <DeleteModal
          action={deleteAction}
          id={{ id: deleteItem, url: `/delete/projects-notes-${deleteItem}` }}
          mutate={() => {
            setShowDeleteModal(false);
            mutateNotes();
          }}
          setShowModal={setShowDeleteModal}
        />
      )}
    </Box>
  );
};

Notes.defaultProps = {
  entity: '',
  eventId: '',
  hideDeleteButton: false,
  id: '',
  technicianNotes: false,
  technicianView: false
};

export default Notes;
