import {
  Box,
  Button,
  Divider,
  Typography,
  useTheme,
  CircularProgress
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import useSWR from 'swr';
import useNavigationStore from '../../../store/zustand/navigation';
import Api from '../../../api';
import Banner from '../../../components/shared/Banner';
import Destination from './destinations/Destination';
import Services from './Services';
import {
  Destination as DestinationType,
  ServiceTime
} from '../../../utils/types/DestinationsType';
import useNotificationsStore from '../../../store/zustand/notifications';

interface Props {
  saveStepper: ({ next }: any) => void;
  sendValues: React.Dispatch<React.SetStateAction<any>>;
  setValues: React.Dispatch<React.SetStateAction<any>>;
  values: any;
}

const Destinations: React.FC<Props> = function Destinations({
  saveStepper,
  sendValues,
  setValues,
  values
}) {
  const theme = useTheme();
  const { t } = useTranslation();
  const { addNotification } = useNotificationsStore((state) => state);
  const [screen, setScreen] = useState<
    'summary' | 'add' | 'serviceTime' | 'edit'
  >('summary');
  const [letDeleteDestinationId, setLetDeleteDestinationId] =
    useState<number>(-1);
  const [destinations, setDestinations] = useState<any[]>([]);
  const [destinationIndexSelected, setDestinationIndexSelected] =
    useState<number>(0);
  const [serviceTimeApproved, setServiceTimeApproved] =
    useState<boolean>(false);
  const { setConfirmModal } = useNavigationStore((state) => state);

  const {
    data: deleteResult,
    error: errorDeleteDestinations,
    isValidating
  } = useSWR(
    letDeleteDestinationId > -1 ? { id: letDeleteDestinationId } : null,
    Api.leadsApi.leadApi.deleteDestinations
  );

  useEffect(() => {
    if (values?.destinations) {
      setDestinations(values.destinations);
    }
  }, [values]);

  useEffect(() => {
    if (errorDeleteDestinations) {
      setLetDeleteDestinationId(-1);
      addNotification(t('NOTIFICATION.TOAST.deleteError'), 'error');
    }
  }, [errorDeleteDestinations]);

  useEffect(() => {
    if (deleteResult === 200) {
      addNotification(
        t('NOTIFICATION.TOAST.deleteSuccess', ['Item']),
        'success'
      );
      const updatedDestinations = values.destinations.filter(
        (dest: DestinationType) => dest.id !== letDeleteDestinationId
      );
      setValues({ ...values, destinations: updatedDestinations });
      setDestinations(updatedDestinations);
      if (destinationIndexSelected >= updatedDestinations.length) {
        setDestinationIndexSelected(0);
      }
      setLetDeleteDestinationId(-1);
    }
  }, [deleteResult]);

  useEffect(() => {
    let auxServiceTimeApproved = true;
    if (values?.destinations && values?.destinations?.length > 0) {
      values.destinations.forEach((destination: DestinationType) => {
        if (
          destination?.serviceTimes &&
          destination?.serviceTimes?.length > 0
        ) {
          destination.serviceTimes.forEach((serviceTime: ServiceTime) => {
            if (!serviceTime?.scheduled && !serviceTime?.isScheduled) {
              auxServiceTimeApproved = false;
            }
          });
        } else {
          auxServiceTimeApproved = false;
        }
      });
    } else {
      auxServiceTimeApproved = false;
    }
    setServiceTimeApproved(auxServiceTimeApproved);
  }, [values]);

  const areAllAddressesUsed = (clientAddresses: any[], destinations: any[]) => {
    if (!clientAddresses?.length) return true;
    const usedAddressIds = new Set(destinations.map((d) => d.address.id));
    return clientAddresses.every((address) => usedAddressIds.has(address.id));
  };

  const deleteDestination = (index: number) => {
    setConfirmModal(
      () => {
        const newDestinations = [...destinations];
        setLetDeleteDestinationId(newDestinations[index].id);
      },
      `${t('GENERAL.confirmButton')}`,
      () => {},
      `${t('GENERAL.cancelButton')}`,
      <Box display="flex" flexDirection="column" pl={8} pr={8}>
        <Box p={2}>
          <i className="custom-icon icon-info" style={{ fontSize: 48 }} />
        </Box>
        <Typography variant="primary">
          {t('LEAD.CREATE.SERVICES.SUMMARY.confirmDeleteDestination')}
        </Typography>
      </Box>
    );
  };

  const getServices = (serviceTimes: any[] | any): string => {
    const serviceTimeArray = Array.isArray(serviceTimes)
      ? serviceTimes
      : [serviceTimes];
    const serviceNames = new Set<string>();
    serviceTimeArray.forEach((st) => {
      if (st && st.services && Array.isArray(st.services)) {
        st.services.forEach((service: any) => {
          if (service?.name && service.name !== '') {
            serviceNames.add(service.name);
          }
        });
      }
    });
    return serviceNames.size > 0
      ? Array.from(serviceNames).join(', ')
      : t('LEAD.CREATE.SERVICES.SUMMARY.noData');
  };

  const getSummary = () => {
    return (
      <Box display="flex" flexDirection="column" gap="48px" width="100%">
        <Box display="flex" gap="40px" justifyContent="space-between">
          <Box display="flex" flexDirection="column" gap={2}>
            <Typography color="primary.main" variant="heading_500">
              {t('LEAD.CREATE.DESTINATIONS.destinations')}
            </Typography>
            <Typography color="secondary.400" variant="body_200">
              {t('LEAD.CREATE.SERVICES.SUMMARY.descriptionDestination')}
            </Typography>
          </Box>

          <Box alignItems="flex-end" display="flex" flexShrink={0}>
            <Button
              disabled={
                values?.readOnly ||
                areAllAddressesUsed(
                  values?.client?.addresses,
                  values?.destinations
                )
              }
              onClick={() => {
                setScreen('add');
              }}
              sx={{ padding: '12px 16px 12px 16px' }}
              variant="primary"
            >
              <Box alignItems="center" display="flex" gap="10px">
                <i className="custom-icon icon-add" />
                <Typography variant="heading_328">
                  {t('LEAD.CREATE.SERVICES.SUMMARY.addServiceAddressButton')}
                </Typography>
              </Box>
            </Button>
          </Box>
        </Box>
        {destinations && destinations?.length > 0 && (
          <Box display="flex" flexDirection="column" gap="24px" width="100%">
            {destinations
              .sort((a: DestinationType, b: DestinationType) => a.id - b.id)
              .map((destination: DestinationType, index: number) => {
                const destinationConfigured =
                  destination?.serviceTimes?.length > 0 &&
                  destination?.serviceTimes?.every(
                    (st: ServiceTime) => st?.scheduled || st?.isScheduled
                  );

                const deletingDestination =
                  letDeleteDestinationId === destinations[index].id &&
                  isValidating;

                return (
                  <Box
                    display="flex"
                    flexDirection="column"
                    gap="24px"
                    key={`min-box-${destination?.id}`}
                    padding="16px 24px 16px 24px"
                    sx={{
                      border: 1,
                      borderColor: theme.palette.secondary[100],
                      borderRadius: 1
                    }}
                    width="100%"
                  >
                    <Box
                      alignItems="center"
                      display="flex"
                      gap="16px"
                      justifyContent="space-between"
                    >
                      <Box
                        display="grid"
                        gridTemplateColumns="174px 1fr 1fr"
                        sx={{ gridColumnGap: '16px' }}
                      >
                        <Typography variant="secondary-semi">
                          {`${t('LEAD.CREATE.DESTINATIONS.destination')} #${
                            index + 1
                          }`}
                        </Typography>

                        <Typography color="mainText.300" variant="body_300">
                          {destination.address.name}
                        </Typography>
                        {/* 
                      <Typography color="mainText.300" variant="body_300">
                        {serviceTime.frequency}
                      </Typography> */}
                      </Box>

                      <Box display="flex" gap="20px">
                        <Button
                          disabled={values?.readOnly}
                          onClick={() => {
                            if (!values?.readOnly) {
                              setScreen('serviceTime');
                              setDestinationIndexSelected(index);
                            }
                          }}
                          variant={
                            destinationConfigured
                              ? 'tertiary-text'
                              : 'warning-text'
                          }
                        >
                          <Box
                            alignItems="center"
                            display="flex"
                            gap="10px"
                            whiteSpace="nowrap"
                          >
                            <i className="custom-icon icon-settings" />
                            <Typography variant="heading_328">
                              {destinationConfigured
                                ? `${t(
                                    'LEAD.CREATE.SERVICES.SUMMARY.serviceTime'
                                  )} ${t(
                                    'LEAD.CREATE.DESTINATIONS.configured'
                                  )}`
                                : `${t(
                                    'LEAD.CREATE.DESTINATIONS.configure'
                                  )} ${t(
                                    'LEAD.CREATE.SERVICES.SUMMARY.serviceTime'
                                  )}`}
                            </Typography>
                          </Box>
                        </Button>
                        <Button
                          disabled={values?.readOnly}
                          onClick={() => {
                            setScreen('edit');
                            setDestinationIndexSelected(index);
                          }}
                          sx={{ minWidth: '114px' }}
                          variant="text"
                        >
                          <Box alignItems="center" display="flex" gap="10px">
                            <i className="custom-icon icon-edit" />
                            <Typography variant="heading_328">
                              {t('GENERAL.editButton')}
                            </Typography>
                          </Box>
                        </Button>

                        <Button
                          disabled={values?.readOnly || deletingDestination}
                          onClick={() => {
                            deleteDestination(index);
                          }}
                          variant="cancel-text"
                        >
                          <Box alignItems="center" display="flex" gap="10px">
                            {deletingDestination ? (
                              <CircularProgress size={14} />
                            ) : (
                              <i className="custom-icon icon-delete" />
                            )}
                            <Typography variant="heading_328">
                              {t('GENERAL.deleteButton')}
                            </Typography>
                          </Box>
                        </Button>
                      </Box>
                    </Box>

                    <Divider
                      flexItem
                      sx={{ borderColor: theme.palette.secondary[100] }}
                    />

                    <Box
                      display="flex"
                      flexDirection="column"
                      sx={{ gap: '32px' }}
                    >
                      <Box
                        display="flex"
                        flexDirection="row"
                        sx={{ gap: '22px' }}
                      >
                        <Typography color="primary.main" variant="heading_300">
                          {`${t('LEAD.CREATE.DESTINATIONS.address')}:`}
                        </Typography>
                        <Typography color="mainText.300" variant="body_300">
                          {`${destination.address.address}`}
                        </Typography>
                      </Box>

                      <Box
                        display="flex"
                        flexDirection="row"
                        sx={{ gap: '22px' }}
                      >
                        <Typography color="primary.main" variant="heading_300">
                          {`${t('LEAD.CREATE.DESTINATIONS.contact')}:`}
                        </Typography>
                        <Typography color="mainText.300" variant="body_300">
                          {`${destination.contact.name}`}
                        </Typography>
                      </Box>
                      <Box
                        display="flex"
                        flexDirection="row"
                        sx={{ gap: '22px' }}
                      >
                        <Typography color="primary.main" variant="heading_300">
                          {t('LEAD.CREATE.SERVICES.SUMMARY.services')}:
                        </Typography>
                        <Typography color="mainText.300" variant="body_300">
                          {getServices(destination.serviceTimes)}
                        </Typography>
                      </Box>
                    </Box>
                  </Box>
                );
              })}
          </Box>
        )}
        {values?.destinations?.length > 0 && (
          <Box alignItems="center" display="flex" gap="16px" width="100%">
            {!serviceTimeApproved && (
              <Banner
                icon
                text={t('LEAD.CREATE.SERVICES.SUMMARY.warning')}
                textCustomStyle={{ fontSize: '1rem', fontWeight: '400' }}
                type="warning"
              />
            )}
            <Box sx={{ ml: 'auto' }}>
              <Button
                disabled={!serviceTimeApproved || values?.readOnly}
                onClick={() => {
                  saveStepper({ next: 3 });
                }}
                sx={{ width: 300 }}
                variant="primary"
              >
                <Typography variant="primary">
                  {t('LEAD.CREATE.SERVICES.SUMMARY.continueButton')}
                </Typography>
              </Button>
            </Box>
          </Box>
        )}
      </Box>
    );
  };

  const getScreen = () => {
    switch (screen) {
      case 'add':
        return (
          <Destination
            destinationIndex={
              values.destinations ? values.destinations.length : 0
            }
            destinationsValues={destinations}
            setCloseFormDestination={() => {
              setScreen('summary');
            }}
            setError={(text: string) => addNotification(text, 'error')}
            setServiceTimeUpdate={() => {}}
            setValues={(newValues: any) => {
              setValues(newValues);
            }}
            values={values}
          />
        );
      case 'edit':
        return (
          <Destination
            destinationIndex={destinationIndexSelected}
            destinationsValues={destinations}
            setCloseFormDestination={() => {
              setScreen('summary');
            }}
            setError={() =>
              addNotification(t('NOTIFICATION.TOAST.generalError'), 'error')
            }
            setServiceTimeUpdate={() => {}}
            setValues={(newValues: any) => {
              setValues(newValues);
            }}
            values={values}
          />
        );
      case 'serviceTime':
        return (
          <Services
            destinationIndex={destinationIndexSelected}
            handleCancel={() => setScreen('summary')}
            saveStepper={({ next }: { next?: number }) => {
              setScreen('summary');
              if (next) {
                saveStepper({ next });
              }
            }}
            sendValues={sendValues}
            setValues={setValues}
            values={values}
          />
        );
      case 'summary':
      default:
        return getSummary();
    }
  };

  return (
    <Box>
      <Box>{getScreen()}</Box>
    </Box>
  );
};

export default Destinations;
