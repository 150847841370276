import { ZoomIn, ZoomOut } from '@mui/icons-material';
import {
  Backdrop,
  Box,
  Button,
  Modal,
  Slider,
  SliderProps,
  Stack,
  styled
} from '@mui/material';
import React, { useState } from 'react';
import Cropper from 'react-easy-crop';
import { useTranslation } from 'react-i18next';

const ModalContainer = styled(Box)<SliderProps>(({ theme }) => ({
  alignItems: 'center',
  backgroundColor: theme.palette.background.paper,
  borderRadius: '0.5em',
  bottom: 0,
  boxShadow: '24',
  display: 'flex',
  flexDirection: 'column',
  height: theme.spacing(70),
  justifyContent: 'center',
  left: 0,
  margin: 'auto',
  position: 'absolute' as 'absolute',
  right: 0,
  top: 0,
  width: theme.spacing(70)
}));

interface Props {
  hideCropper: any;
  image: any;
  onCropCancel: any;
  onCropDone: any;
}

const ImageCropper: React.FC<Props> = function ImageCropper({
  image,
  onCropDone,
  onCropCancel,
  hideCropper
}) {
  const { t } = useTranslation();
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [croppedArea, setCroppedArea] = useState<any>(null);

  const handleChange = (event: Event, newValue: number | number[]) => {
    setZoom(newValue as number);
  };

  const onCropComplete = (
    croppedAreaPercentage: any,
    croppedAreaPixels: any
  ) => {
    setCroppedArea(croppedAreaPixels);
  };

  return (
    <Modal
      open
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500
      }}
      onClose={() => hideCropper()}
    >
      <ModalContainer>
        <Box>
          <Cropper
            aspect={1}
            crop={crop}
            cropShape="round"
            image={image}
            onCropChange={setCrop}
            onCropComplete={onCropComplete}
            onZoomChange={setZoom}
            style={{
              containerStyle: {
                alignItems: 'center',
                backgroundColor: 'white',
                display: 'flex',
                justifyContent: 'center',
                margin: 'auto',
                marginBottom: 16,
                marginLeft: 'auto',
                marginRight: 'auto',
                marginTop: 16,
                maxHeight: '72%',
                maxWidth: '96%'
              }
            }}
            zoom={zoom}
          />
        </Box>
        <Box sx={{ color: 'mainText.main', marginTop: '344px', width: 200 }}>
          <Stack alignItems="center" direction="row" spacing={2} sx={{ mb: 1 }}>
            <ZoomOut />
            <Slider
              aria-label="Zoom"
              max={6}
              min={1}
              onChange={handleChange}
              step={0.000005}
              value={zoom}
            />
            <ZoomIn />
          </Stack>
        </Box>
        <Box
          className="action-btns"
          style={{
            bottom: 8,
            display: 'flex',
            justifyContent: 'space-between',
            left: 0,
            marginLeft: 'auto',
            marginRight: 'auto',
            position: 'absolute',
            right: 0,
            textAlign: 'center',
            width: 184
          }}
        >
          <Button
            className="btn btn-outline"
            onClick={() => {
              onCropCancel();
              hideCropper();
            }}
            variant="cancel"
          >
            {t('GENERAL.cancelButton')}
          </Button>

          <Button
            onClick={() => {
              const canvasEle = document.createElement('canvas');
              canvasEle.width = croppedArea.width;
              canvasEle.height = croppedArea.height || 0;

              const context = canvasEle.getContext('2d');

              const imageObj1 = new Image();
              imageObj1.src = image;
              imageObj1.onload = function () {
                context?.drawImage(
                  imageObj1,
                  croppedArea.x,
                  croppedArea.y,
                  croppedArea.width,
                  croppedArea.height,
                  0,
                  0,
                  croppedArea.width,
                  croppedArea.height
                );

                const dataURL = canvasEle.toDataURL('image/jpeg');

                const blobBin = atob(dataURL.split(',')[1]);
                const array = [];
                for (let i = 0; i < blobBin.length; i += 1) {
                  array.push(blobBin.charCodeAt(i));
                }
                const file = new Blob([new Uint8Array(array)], {
                  type: 'image/jpeg'
                });
                const formdata = new File([file], 'profilepicture.jpeg', {
                  type: 'image/jpeg'
                });
                onCropDone(formdata);
              };
            }}
            variant="primary"
          >
            {t('GENERAL.confirmButton')}
          </Button>
        </Box>
      </ModalContainer>
    </Modal>
  );
};

export default ImageCropper;
