import {
  TaskDecisionBodyDTO,
  TimeFormatEnum,
  TimesheetApi,
  TimesheetApiTimesheetGetTimesheetDashboardRequest,
  TimesheetApiTimesheetUpdateTaskDecisionRequest
} from '../api-client/generated/api';
import {
  formatMillisecondsToHHMMSS,
  formatSumTime,
  formattedTime,
  formattedTimeDateTime
} from '../utils/calendarHelper';
import {
  convertUTCToLocalTimeZone,
  convertUTCToLocalTimeZoneHHMMSS,
  formatDate
} from '../utils/general';

const apiCaller = new TimesheetApi();

const reviewHeader = [
  { align: 'center', columnName: 'technician', text: 'Technician' },
  { align: 'center', columnName: 'taskName', text: 'Item' },
  { align: 'center', columnName: 'typeText', text: 'Project type' },
  { align: 'center', columnName: 'day', text: 'Day' },
  { align: 'center', columnName: 'travelTime', text: 'Travel time' },
  // { align: 'center', columnName: 'scheduledStart', text: 'Scheduled Start' },
  { align: 'center', columnName: 'actualStart', text: 'Actual Start' },
  // { align: 'center', columnName: 'scheduledEnd', text: 'Scheduled End' },
  { align: 'center', columnName: 'actualEnd', text: 'Actual End' },
  { align: 'center', columnName: 'scheduledTotal', text: 'Scheduled Total' },
  { align: 'center', columnName: 'actualTotal', text: 'Actual Total' },
  { align: 'center', columnName: 'variance', text: 'Variance %' },
  { align: 'center', columnName: 'varianceActual', text: 'Variance Actual' },
  { align: 'center', text: '' }
];

export const taskListFormater = (response: any, timeFormat?: string) => {
  return response.data.map((taskIteration: any) => {
    const auxTastDataList = taskIteration?.tasks.map(
      (taskIterationData: any) => {
        const auxServiceTime = taskIterationData?.leadServiceTask
          ? taskIterationData?.leadServiceTask?.service?.serviceTime
          : taskIterationData?.leadServiceTime;
        const auxTravelTIme =
          taskIterationData?.calendarEvent?.travelTimeIterations[0]
            ?.travelTimeInHours ||
          auxServiceTime?.travelTime ||
          '0.00';
        const travelTimeInMilliseconds = formatMillisecondsToHHMMSS(
          taskIterationData?.calendarEvent?.travelTimeIterations[0]
            ?.travelTimeInMilliseconds
        );
        const timezone =
          (taskIterationData?.calendarEvent &&
            taskIterationData?.calendarEvent?.leadServiceTime?.lead?.address
              ?.timezoneIANA) ||
          (taskIterationData?.calendarEvent &&
            taskIterationData?.calendarEvent?.leadServiceTime?.lead?.address
              ?.timezone) ||
          (taskIterationData?.leadServiceTask &&
            taskIterationData?.leadServiceTask?.service?.serviceTime?.lead
              ?.address?.timezoneIANA) ||
          Intl.DateTimeFormat().resolvedOptions().timeZone;
        const task = {
          actualEnd: convertUTCToLocalTimeZoneHHMMSS(
            taskIterationData?.endTime,
            timezone,
            // eslint-disable-next-line no-underscore-dangle
            timeFormat === TimeFormatEnum._24h
          ),

          actualStart: convertUTCToLocalTimeZoneHHMMSS(
            taskIterationData?.startTime,
            timezone,
            // eslint-disable-next-line no-underscore-dangle
            timeFormat === TimeFormatEnum._24h
          ),
          actualTotal: Number(taskIterationData.hoursWorked),
          clientName: `${taskIterationData?.calendarEvent?.leadServiceTime?.lead?.client?.firstName}
            ${taskIterationData?.calendarEvent?.leadServiceTime?.lead?.client?.lastName}`,
          closedBy: taskIterationData?.closedBy,
          closer: taskIterationData?.closer,
          companyName:
            taskIterationData?.calendarEvent?.leadServiceTime?.lead?.client
              ?.businessName,
          day: formatDate(taskIterationData.startTime),
          id: taskIterationData.id,
          laborHourCost: taskIterationData?.laborHourCost || 0,
          laborTotalCost: taskIterationData.laborTotalCost,
          millisecondsWorked: formatMillisecondsToHHMMSS(
            taskIterationData.millisecondsWorked
          ),
          originalTask:
            taskIterationData.type === 'Regular'
              ? taskIterationData?.calendarEvent?.leadService?.tasks
              : taskIterationData?.leadServiceTask?.name,
          projectName:
            taskIterationData?.calendarEvent?.leadServiceTime?.lead?.name,
          projectType: taskIterationData.type,
          scheduledEnd: formattedTime(
            auxServiceTime?.endTime,
            undefined,
            timeFormat
          ),
          scheduledStart: formattedTime(
            auxServiceTime?.startTime,
            undefined,
            timeFormat
          ),
          scheduledTotal: Number(taskIterationData.hoursScheduled),
          serviceName:
            taskIterationData.type === 'Regular'
              ? taskIterationData?.calendarEvent?.leadService?.name
              : taskIterationData?.leadServiceTask?.service.name,
          startedBy: taskIterationData?.startedBy,
          starter: taskIterationData?.starter,
          status: taskIterationData.status,
          taskName: taskIterationData?.leadServiceTask
            ? taskIterationData?.leadServiceTask?.name
            : taskIterationData?.calendarEvent?.leadService?.name,
          technician: `${taskIteration?.technician?.firstName} ${taskIteration?.technician?.lastName}`,
          technicianInformation: taskIteration?.technician,
          travelTime: auxTravelTIme,
          travelTimeInMilliseconds,
          type: taskIterationData?.leadServiceTask
            ? 'taskIteration'
            : 'calendarEventIteration',
          typeText: taskIterationData?.type,
          variance: Number(taskIterationData.variance),
          varianceActual: taskIterationData.variance,
          variancePercentage: (
            ((taskIterationData.hoursWorked -
              taskIterationData.hoursScheduled) /
              taskIterationData.hoursScheduled) *
            100
          ).toFixed(0)
        };

        if (task.variancePercentage.endsWith('.00')) {
          task.variancePercentage = task.variancePercentage.slice(0, -3);
        }

        return task;
      }
    );

    return {
      dataList: auxTastDataList,
      email: taskIteration?.technician?.email,
      hourlyRate: taskIteration?.technician?.rate,
      id: taskIteration?.technician?.id,
      phoneNumber: taskIteration?.technician?.phoneNumber,
      technician: `${taskIteration?.technician?.firstName} ${taskIteration?.technician?.lastName}`,
      technicianPaymentTotal:
        typeof taskIteration?.values?.technicianPaymentTotal === 'string'
          ? Number(taskIteration?.values?.technicianPaymentTotal)
          : taskIteration?.values?.technicianPaymentTotal || 0,
      totalHoursScheduled:
        Number(taskIteration?.values?.totalHoursScheduled) || 0,
      totalHoursWorked: Number(taskIteration?.values?.totalHoursWorked)
    };
  });
};

const getTimesheetDashboard = async (params: any): Promise<any> => {
  const auxParams: TimesheetApiTimesheetGetTimesheetDashboardRequest = {
    fromDate: params.fromDate,
    toDate: params.toDate
  };

  const response = await apiCaller.timesheetGetTimesheetDashboard(auxParams);
  const { data } = response;
  return data;
};

const getTechnicianTimesheetDashboard = async (params: any): Promise<any> => {
  const auxParams: TimesheetApiTimesheetGetTimesheetDashboardRequest = {
    fromDate: params.fromDate,
    toDate: params.toDate
  };

  const response = await apiCaller.timesheetGetTechnicianTimesheetDashboard(
    auxParams
  );
  const { data } = response;
  return data;
};

const getTimesheetTasks = async (params: any): Promise<any> => {
  const auxParams: TimesheetApiTimesheetGetTimesheetDashboardRequest = {
    fromDate: params.fromDate,
    toDate: params.toDate
  };

  const response = await apiCaller.timesheetGetTimesheetTasks(auxParams);
  const taskIterationsList = taskListFormater(response, params?.timeFormat);

  return taskIterationsList;
};

const getTechnicianTimesheetTasks = async (params: any): Promise<any> => {
  const auxParams: TimesheetApiTimesheetGetTimesheetDashboardRequest = {
    fromDate: params.fromDate,
    toDate: params.toDate
  };

  const response = await apiCaller.timesheetGetTechnicianTimesheetTasks(
    auxParams
  );
  const taskIterationsList = taskListFormater(response, params?.timeFormat);

  return taskIterationsList;
};

const getTaskToReviewForOperator = async (params: any): Promise<any> => {
  const response = await apiCaller.timesheetGetTaskToReviewForOperator();

  const taskIterationsList = taskListFormater(response, params?.timeFormat);

  return taskIterationsList;
};

const getTechnicianTaskToReviewForOperator = async (
  params: any
): Promise<any> => {
  const response =
    await apiCaller.timesheetGetTechnicianTaskToReviewForOperator();
  const taskIterationsList = taskListFormater(response, params?.timeFormat);

  return taskIterationsList;
};

const updateTaskDecision = async (params: any): Promise<any> => {
  const auxParams: TimesheetApiTimesheetUpdateTaskDecisionRequest = {
    taskDecisionBodyDTO: {
      actualTotal: params.actualTotal,
      approvalStatus: params.approvalStatus
    } as TaskDecisionBodyDTO,
    taskIterationId: params.taskIterationId
  };

  if (params.type === 'calendarEventIteration') {
    const response =
      await apiCaller.timesheetUpdateCalendarEventIterationDecision(auxParams);
    const { data } = response;

    return data;
  }

  const response = await apiCaller.timesheetUpdateTaskDecision(auxParams);
  const { data } = response;

  return data;
};

export default {
  getTaskToReviewForOperator,
  getTechnicianTaskToReviewForOperator,
  getTechnicianTimesheetDashboard,
  getTechnicianTimesheetTasks,
  getTimesheetDashboard,
  getTimesheetTasks,
  reviewHeader,
  updateTaskDecision
};
