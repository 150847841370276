/* eslint-disable default-param-last */
/* eslint-disable no-unused-vars */
import create from 'zustand';
import { devtools, persist } from 'zustand/middleware';
import { Bounce, toast } from 'react-toastify';

interface NotificationsState {
  addNotification: (
    message: string,
    type: 'info' | 'success' | 'warning' | 'error' | 'default',
    options?: any
  ) => void;
}

const useNotificationsStore = create<NotificationsState>()(
  devtools(
    persist(
      (set) => ({
        addNotification: (message, type = 'default', options) => {
          toast(message, {
            type,
            ...options
          });
        }
      }),
      {
        name: 'Notifications-storage'
      }
    )
  )
);

export default useNotificationsStore;
