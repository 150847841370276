import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';

type BoxProps = {
  isMobile: boolean;
};

export const GridBox = styled(Box)<BoxProps>(({ isMobile }) => ({
  display: 'flex',
  flexDirection: 'column',
  marginTop: '8px',
  paddingLeft: isMobile ? '24px' : '16px',
  paddingRight: isMobile ? '24px' : '16px',
  width: 'auto'
}));

export const GridBoxContainer = styled(Box)<BoxProps>(({ isMobile }) => ({
  backgroundColor: 'white',
  display: 'grid',
  gridAutoRows: isMobile ? '104px 40px' : '96px 72px',
  gridTemplateColumns: isMobile ? '100%' : '50% 50%',
  rowGap: '8px'
}));

type SpanProps = {
  disabled?: boolean;
  isMobile: boolean;
};

export const DayCheckBox = styled('span')<SpanProps>(({ theme, disabled }) => ({
  alignItems: 'center',
  backgroundColor: disabled
    ? theme.palette.secondary.light
    : theme.palette.primary.light,
  border: `1px solid ${theme.palette.primary[400]}`,
  borderRadius: '100%',
  color: theme.palette.primary[400],
  display: 'flex',
  fontWeight: 'bold',
  height: '34px',
  justifyContent: 'center',
  width: '34px'
}));

DayCheckBox.defaultProps = {
  disabled: undefined
};

export const DayCheckBoxSelected = styled('span')<SpanProps>(
  ({ theme, disabled }) => ({
    alignItems: 'center',
    backgroundColor: disabled
      ? theme.palette.secondary.main
      : theme.palette.primary[100],
    border: `1px solid ${theme.palette.primary[400]}`,
    borderRadius: '100%',
    color: theme.palette.primary[400],
    display: 'flex',
    fontWeight: 'bold',
    height: '34px',
    justifyContent: 'center',
    width: '34px'
  })
);

DayCheckBoxSelected.defaultProps = {
  disabled: undefined
};

// ------------
export const DayCheckBoxSquared = styled('span')<SpanProps>(
  ({ theme, isMobile, disabled }) => ({
    alignItems: 'center',
    backgroundColor: disabled
      ? theme.palette.secondary.main
      : theme.palette.primary.main,
    borderRadius: '50%',
    color: 'white',
    display: 'flex',
    height: isMobile ? '30px' : '40px',
    justifyContent: 'center',
    opacity: '0.5',
    width: isMobile ? '30px' : '100%'
  })
);

DayCheckBoxSquared.defaultProps = {
  disabled: undefined
};

export const DayCheckBoxSquaredSelected = styled('span')<SpanProps>(
  ({ theme, isMobile, disabled }) => ({
    alignItems: 'center',
    backgroundColor: disabled
      ? theme.palette.secondary.main
      : theme.palette.primary.main,
    borderRadius: '50%',
    color: 'white',
    display: 'flex',
    height: isMobile ? '30px' : '40px',
    justifyContent: 'center',
    width: isMobile ? '30px' : '100%'
  })
);

DayCheckBoxSquaredSelected.defaultProps = {
  disabled: undefined
};
