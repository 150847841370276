/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/destructuring-assignment */
import {
  Box,
  Typography,
  SliderProps,
  styled,
  useTheme,
  Button
} from '@mui/material';

import React, { useEffect, useRef, useState } from 'react';

import { useTranslation } from 'react-i18next';
import useSWR from 'swr';
import ResourceServiceTimeList from './components/ResourceServiceTimeList';
import Api from '../../../api';
import { CreateLead } from '../../../utils/types';
import Loader from '../../../components/shared/Loader';

const style = {
  fontSize: '14px',
  fontWeight: 'bold',
  padding: '20px'
};

interface Props {
  mutate: () => void;
  saveStepper: ({ next }: any) => void;
  // eslint-disable-next-line react/require-default-props
  sendValues?: React.Dispatch<React.SetStateAction<any>>;
  setStep: React.Dispatch<React.SetStateAction<number>>;
  setValues: React.Dispatch<React.SetStateAction<any>>;
  values: CreateLead;
}

export const ServicesDots = styled('span')<SliderProps>(() => ({
  alignItems: 'center',
  borderRadius: '50%',
  color: 'white',
  display: 'flex',
  height: '40px',
  justifyContent: 'center',
  width: '40px'
}));

const Schedule: React.FC<Props> = function Schedule({
  mutate,
  saveStepper,
  values,
  setValues,
  sendValues,
  setStep
}) {
  const [serviceTimesList, setServiceTimesList] = useState<any>([]);
  const [serviceTimeUpdate, setServiceTimeUpdate] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);

  const { t } = useTranslation();
  const theme = useTheme();
  const { data, error } = useSWR(
    serviceTimeUpdate
      ? {
          destinations: values.destinations,
          idLead: values.id
        }
      : null,
    Api.LeadServiceTimesApi.updateMultipleLeadServicesTime
  );

  useEffect(() => {
    if (data) {
      setTimeout(() => {
        setServiceTimeUpdate(false);
        const auxSL = serviceTimesList.filter(
          (serviceTime: any) =>
            !serviceTime?.scheduled && !serviceTime?.isScheduled
        );
        if (
          (`${values?.manualPricing}` === 'true' && auxSL.length === 0) ||
          values?.projectType === 'Ad-Hoc'
        ) {
          setStep(3);
          saveStepper({ next: 3 });
        } else {
          saveStepper({ next: 1 });
          setStep(1);
        }
      }, 1000);
    }
  }, [data]);

  // useEffect(() => {
  //   if (serviceTimeUpdate) {
  //     const auxSL = serviceTimesList.filter(
  //       (serviceTime: any) =>
  //         !serviceTime?.scheduled && !serviceTime?.isScheduled
  //     );
  //     setServiceTimeUpdate(false);
  //     if (`${values?.manualPricing}` === 'true' && auxSL.length === 0) {
  //       setStep(3);
  //       saveStepper({ next: 3 });
  //     } else {
  //       saveStepper({ next: 1 });
  //       setStep(1);
  //     }
  //   }
  // }, [serviceTimeUpdate]);

  const colorsRef = useRef<any>([]);
  function randomColor() {
    const red = Math.floor(Math.random() * 256);
    const green = Math.floor(Math.random() * 256);
    const blue = Math.floor(Math.random() * 256);
    return `rgba(${red}, ${green}, ${blue}, 0.3)`;
  }

  if (!colorsRef.current.length) {
    for (let i = 0; i < serviceTimesList?.length; i += 1) {
      colorsRef.current.push(randomColor());
    }
  }

  useEffect(() => {
    if (values?.destinations?.length > 0) {
      const allServiceTimes = values.destinations.flatMap(
        (destination) => destination.serviceTimes
      );
      setServiceTimesList(allServiceTimes);
    }
  }, [values?.destinations]);

  function scheduleServices() {
    return (
      <Box display="flex" flexDirection="column" gap="16px">
        <Box>
          <Typography color="primary.main" variant="heading_500">
            {t('LEAD.CREATE.SERVICES.SCHEDULE.scheduledTitle')}{' '}
          </Typography>
        </Box>
        <Typography color="mainText.300" variant="body_200">
          {t('LEAD.CREATE.SERVICES.SCHEDULE.scheduledDescription')}
        </Typography>
      </Box>
    );
  }

  const validateAllServiceTimes = (): boolean => {
    const serviceTimes = values?.destinations?.flatMap(
      (destination) => destination.serviceTimes
    );

    if (
      !serviceTimes ||
      !Array.isArray(serviceTimes) ||
      serviceTimes.length === 0
    )
      return false;

    return serviceTimes.every((serviceTime) => {
      // 1. First Condition: time configured and service window
      if (serviceTime?.scheduleTimeError) return false;
      // 2. Second Condition: tasks must have resources
      const hasTasksWithResources = serviceTime.services?.some((service: any) =>
        service.tasks?.some(
          (task: any) => task.resources && task.resources.length > 0
        )
      );

      if (!hasTasksWithResources) return false;

      // 3. Third Condition: resourcesAssigned >= minimumResources
      const areResourcesValid = serviceTime.services?.every((service: any) =>
        service.tasks?.every(
          (task: any) => task.resourcesAssigned >= task.minimumResources
        )
      );

      if (!areResourcesValid) return false;

      return true; // All validations passed for this serviceTime
    });
  };

  useEffect(() => {
    return () => {
      mutate();
    };
  }, []);

  function buttons() {
    return (
      <Box
        sx={{
          alignSelf: 'flex-end',
          display: 'flex',
          width: 300
        }}
      >
        <Button
          fullWidth
          disabled={
            values?.readOnly || !validateAllServiceTimes() || serviceTimeUpdate
          }
          onClick={() => {
            setValues({
              ...values,
              destinations: values.destinations.map((destination) => ({
                ...destination,
                serviceTimes: destination.serviceTimes.map(
                  (serviceTime: any) => {
                    if (
                      typeof serviceTime?.id === 'number' &&
                      serviceTime?.services?.length > 0
                    ) {
                      let validSchedule = true;

                      serviceTime.services.forEach((service: any) => {
                        const { tasks } = service;
                        if (tasks?.length > 0) {
                          tasks.forEach((task: any) => {
                            if (
                              !task.resources ||
                              (task.resources && task.resources.length === 0) ||
                              task.minimumResources > task.resources.length
                            ) {
                              validSchedule = false;
                            }
                          });
                        } else {
                          validSchedule = false;
                        }
                      });

                      return {
                        projectType: values.projectType,
                        ...serviceTime,
                        isScheduled: validSchedule,
                        scheduled: validSchedule
                      };
                    }
                    return serviceTime;
                  }
                )
              }))
            });

            setServiceTimeUpdate(true);
          }}
          sx={{ m: 1 }}
          variant="primary"
        >
          {serviceTimeUpdate ? <Loader /> : t('GENERAL.saveScheduled')}
        </Button>
      </Box>
    );
  }

  function serviceTimeList() {
    return (
      <Box sx={{ width: '100%' }} width="100%">
        <ResourceServiceTimeList
          saveStepper={saveStepper}
          sendValues={sendValues}
          setStep={setStep}
          setValues={(values: any) => {
            setLoading(true);
            setValues({ ...values });
            setTimeout(() => {
              setLoading(false);
            }, 500);
          }}
          values={values}
        />
      </Box>
    );
  }

  return (
    <Box display="flex" flexDirection="column" gap="48px">
      {values &&
      (values.projectType === 'adhoc' || values.projectType === 'Ad-Hoc')
        ? null
        : scheduleServices()}
      <Box display="flex" flexDirection="column" gap="24px">
        {serviceTimeList()}
        {!loading && buttons()}
      </Box>
    </Box>
  );
};

export default Schedule;
