import {
  Box,
  BoxProps,
  Button,
  IconButton,
  styled,
  Typography,
  useTheme
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import CorporateFareOutlinedIcon from '@mui/icons-material/CorporateFareOutlined';
import EditIcon from '@mui/icons-material/EditOutlined';
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined';
import StarIcon from '@mui/icons-material/GradeOutlined';
import PlaceOutlinedIcon from '@mui/icons-material/PlaceOutlined';
import PaymentsOutlinedIcon from '@mui/icons-material/PaymentsOutlined';
import LocalPhoneOutlinedIcon from '@mui/icons-material/LocalPhoneOutlined';
import useSWR from 'swr';
import { b2bSteps, b2cSteps } from '../../../../components/client/ClientForm';
import api from '../../../../api';

const FlexBox = styled(Box)<BoxProps>(() => ({
  display: 'flex'
}));

interface SummaryProps {
  setStep: (step: number) => void;
  values: any;
}

function Summary({ values, setStep }: SummaryProps) {
  // Hooks
  const { t: translate } = useTranslation('translation', {
    // Feel free to create additional hooks if you need translations from a different keyPrefix...
    keyPrefix: 'CLIENT_ONBOARDING'
  });
  const theme = useTheme();
  const { t } = useTranslation();
  const [updateClientApi, setUpdateClientApi] = useState<boolean>(false);

  const { data: clientData, error: clientError } = useSWR(
    updateClientApi ? { url: 'client/portal/update', ...values } : null,
    api.ClientPageApi.onboardingClient
  );

  const getSummary = () => {
    return (
      <Box
        sx={{
          backgroundColor: theme.palette.background.box,
          borderRadius: 2,
          display: 'flex',
          flexDirection: 'column'
        }}
      >
        {values.relationship === 'B2B' ? (
          <>
            <Box
              sx={{
                border: `1px solid ${theme.palette.secondary[100]}`,
                borderRadius: 2,
                mb: 3,
                padding: 2
              }}
            >
              <Box
                alignItems="center"
                display="flex"
                justifyContent="space-between"
                mb={2}
              >
                <Box alignItems="center" display="flex" gap={1}>
                  <CorporateFareOutlinedIcon color="primary" fontSize="small" />
                  <Typography color="primary" gap={1} variant="heading_400">
                    {t('CUSTOMER.STEPS.companyInfo.title')}
                  </Typography>
                </Box>
                <IconButton onClick={() => setStep(-5)}>
                  <EditIcon color="primary" fontSize="small" />
                </IconButton>
              </Box>
              <Box display="flex" flexDirection="column">
                <Box
                  display="flex"
                  flexDirection="row"
                  justifyContent="space-between"
                >
                  <Typography
                    sx={{ fontWeight: 'bold', marginBottom: '10px' }}
                    variant="subtitle2"
                  >
                    {t('CUSTOMER.companyName')}
                  </Typography>
                  <Typography variant="body2">{values.businessName}</Typography>
                </Box>
                <Box
                  display="flex"
                  flexDirection="row"
                  justifyContent="space-between"
                >
                  <Typography
                    sx={{ fontWeight: 'bold', marginBottom: '10px' }}
                    variant="subtitle2"
                  >
                    {t('CUSTOMER.jobTitle')}
                  </Typography>
                  <Typography variant="body2">{values.jobTitle}</Typography>
                </Box>
              </Box>
            </Box>
            <Box
              sx={{
                border: `1px solid ${theme.palette.secondary[100]}`,
                borderRadius: 2,
                mb: 3,
                padding: 2
              }}
            >
              <Box
                alignItems="center"
                display="flex"
                justifyContent="space-between"
                mb={2}
              >
                <Box alignItems="center" display="flex" gap={1}>
                  <PersonOutlineOutlinedIcon color="primary" fontSize="small" />
                  <Typography color="primary" gap={1} variant="heading_400">
                    {t('CUSTOMER.STEPS.b2bMainContact.title')}
                  </Typography>
                </Box>
                <IconButton onClick={() => setStep(-5)}>
                  <EditIcon color="primary" fontSize="small" />
                </IconButton>
              </Box>
              <Box display="flex" flexDirection="column">
                <Box
                  display="flex"
                  flexDirection="row"
                  justifyContent="space-between"
                >
                  <Typography
                    sx={{ fontWeight: 'bold', marginBottom: '10px' }}
                    variant="subtitle2"
                  >
                    {t('CUSTOMER.firstName')}
                  </Typography>
                  <Typography variant="body2">{values.firstName}</Typography>
                </Box>
                <Box
                  display="flex"
                  flexDirection="row"
                  justifyContent="space-between"
                >
                  <Typography
                    sx={{ fontWeight: 'bold', marginBottom: '10px' }}
                    variant="subtitle2"
                  >
                    {t('CUSTOMER.lastName')}
                  </Typography>
                  <Typography variant="body2">{values.lastName}</Typography>
                </Box>
                <Box
                  display="flex"
                  flexDirection="row"
                  justifyContent="space-between"
                >
                  <Typography
                    sx={{ fontWeight: 'bold', marginBottom: '10px' }}
                    variant="subtitle2"
                  >
                    {t('CUSTOMER.emailAddress')}
                  </Typography>
                  <Typography variant="body2">{values.email}</Typography>
                </Box>
                <Box
                  display="flex"
                  flexDirection="row"
                  justifyContent="space-between"
                >
                  <Typography
                    sx={{ fontWeight: 'bold', marginBottom: '10px' }}
                    variant="subtitle2"
                  >
                    {t('CUSTOMER.phoneNumber')}
                  </Typography>
                  <Typography variant="body2">{values.phone}</Typography>
                </Box>
              </Box>
              <Box
                sx={{
                  border: `1px solid ${theme.palette.secondary[100]}`,
                  borderRadius: 2,
                  padding: 2
                }}
              >
                <Box
                  alignItems="center"
                  display="flex"
                  justifyContent="space-between"
                  mb={2}
                >
                  <Typography color="primary" variant="heading_400">
                    {t('CUSTOMER.STEPS.communicationMethods.title')}
                  </Typography>
                  <IconButton onClick={() => setStep(-3)}>
                    <EditIcon color="primary" fontSize="small" />
                  </IconButton>
                </Box>
                <Box display="flex" flexDirection="column">
                  {values?.communicationMethods?.map((method: any) => (
                    <Box
                      display="flex"
                      flexDirection="row"
                      justifyContent="space-between"
                    >
                      <Typography
                        sx={{ fontWeight: 'bold', marginBottom: '10px' }}
                        variant="subtitle2"
                      >
                        {method.type}
                      </Typography>
                      <Typography variant="body2">{method.name}</Typography>
                      <Box alignItems="center" display="flex" gap={1}>
                        <Typography variant="body2">{method.value}</Typography>
                        {method.isPrimary && <StarIcon fontSize="small" />}
                      </Box>
                    </Box>
                  ))}
                </Box>
              </Box>
            </Box>
          </>
        ) : (
          <Box
            sx={{
              border: `1px solid ${theme.palette.secondary[100]}`,
              borderRadius: 2,
              mb: 3,
              padding: 2
            }}
          >
            <Box
              alignItems="center"
              display="flex"
              justifyContent="space-between"
              mb={2}
            >
              <Box alignItems="center" display="flex" gap={1}>
                <PersonOutlineOutlinedIcon color="primary" fontSize="small" />
                <Typography color="primary" gap={1} variant="heading_400">
                  {t('CUSTOMER.STEPS.b2cMainContact.title')}
                </Typography>
              </Box>
              <IconButton onClick={() => setStep(-4)}>
                <EditIcon color="primary" fontSize="small" />
              </IconButton>
            </Box>
            <Box display="flex" flexDirection="column">
              <Box
                display="flex"
                flexDirection="row"
                justifyContent="space-between"
              >
                <Typography
                  sx={{ fontWeight: 'bold', marginBottom: '10px' }}
                  variant="subtitle2"
                >
                  {t('CUSTOMER.firstName')}
                </Typography>
                <Typography variant="body2">{values.firstName}</Typography>
              </Box>
              <Box
                display="flex"
                flexDirection="row"
                justifyContent="space-between"
              >
                <Typography
                  sx={{ fontWeight: 'bold', marginBottom: '10px' }}
                  variant="subtitle2"
                >
                  {t('CUSTOMER.lastName')}
                </Typography>
                <Typography variant="body2">{values.lastName}</Typography>
              </Box>
              <Box
                display="flex"
                flexDirection="row"
                justifyContent="space-between"
              >
                <Typography
                  sx={{ fontWeight: 'bold', marginBottom: '10px' }}
                  variant="subtitle2"
                >
                  {t('CUSTOMER.emailAddress')}
                </Typography>
                <Typography variant="body2">{values.email}</Typography>
              </Box>
              <Box
                display="flex"
                flexDirection="row"
                justifyContent="space-between"
              >
                <Typography
                  sx={{ fontWeight: 'bold', marginBottom: '10px' }}
                  variant="subtitle2"
                >
                  {t('CUSTOMER.phoneNumber')}
                </Typography>
                <Typography variant="body2">{values.phone}</Typography>
              </Box>
            </Box>
            <Box
              sx={{
                border: `1px solid ${theme.palette.secondary[100]}`,
                borderRadius: 2,
                padding: 2
              }}
            >
              <Box
                alignItems="center"
                display="flex"
                justifyContent="space-between"
                mb={2}
              >
                <Typography color="primary" variant="heading_400">
                  {t('CUSTOMER.STEPS.communicationMethods.title')}
                </Typography>
                <IconButton onClick={() => setStep(-3)}>
                  <EditIcon color="primary" fontSize="small" />
                </IconButton>
              </Box>
              <Box display="flex" flexDirection="column">
                {values?.communicationMethods?.map((method: any) => (
                  <Box
                    display="flex"
                    flexDirection="row"
                    justifyContent="space-between"
                  >
                    <Typography
                      sx={{ fontWeight: 'bold', marginBottom: '10px' }}
                      variant="subtitle2"
                    >
                      {method.type}
                    </Typography>
                    <Typography variant="body2">{method.name}</Typography>
                    <Box alignItems="center" display="flex" gap={1}>
                      <Typography variant="body2">{method.value}</Typography>
                      {method.isPrimary && <StarIcon fontSize="small" />}
                    </Box>
                  </Box>
                ))}
              </Box>
            </Box>
          </Box>
        )}

        {/* Billing Information */}
        <Box
          sx={{
            border: `1px solid ${theme.palette.secondary[100]}`,
            borderRadius: 2,
            mb: 3,
            padding: 2
          }}
        >
          <Box
            alignItems="center"
            display="flex"
            justifyContent="space-between"
            mb={2}
          >
            <Box alignItems="center" display="flex" gap={1}>
              <PaymentsOutlinedIcon color="primary" fontSize="small" />
              <Typography color="primary" variant="heading_400">
                {t('CUSTOMER.billingInformation')}
              </Typography>
            </Box>
            <IconButton onClick={() => setStep(-4)}>
              <EditIcon color="primary" fontSize="small" />
            </IconButton>
          </Box>
          <Box display="flex" flexDirection="column">
            <Box
              display="flex"
              flexDirection="row"
              justifyContent="space-between"
            >
              <Typography
                sx={{ fontWeight: 'bold', marginBottom: '10px' }}
                variant="subtitle2"
              >
                {t('CUSTOMER.billingAddress')}
              </Typography>
              <Typography variant="body2">{values.billingAddress}</Typography>
            </Box>
            <Box
              display="flex"
              flexDirection="row"
              justifyContent="space-between"
            >
              <Typography
                sx={{ fontWeight: 'bold', marginBottom: '10px' }}
                variant="subtitle2"
              >
                {t('CUSTOMER.validWorkOrderLabel')}
              </Typography>
              <Box display="flex" flexDirection="column">
                {values?.workOrderEmails?.map((email: any) => (
                  <Typography variant="body2">{email}</Typography>
                ))}
              </Box>
            </Box>
            <Box
              display="flex"
              flexDirection="row"
              justifyContent="space-between"
            >
              <Typography
                sx={{ fontWeight: 'bold', marginBottom: '10px' }}
                variant="subtitle2"
              >
                {t('CUSTOMER.billingEmail')}
              </Typography>
              <Box display="flex" flexDirection="column">
                {values?.billingEmail?.map((email: any) => (
                  <Typography variant="body2">{email}</Typography>
                ))}
              </Box>
            </Box>
          </Box>
        </Box>

        {/* Service Address */}
        <Box
          sx={{
            border: `1px solid ${theme.palette.secondary[100]}`,
            borderRadius: 2,
            mb: 3,
            padding: 2
          }}
        >
          <Box
            alignItems="center"
            display="flex"
            justifyContent="space-between"
            mb={2}
          >
            <Box alignItems="center" display="flex" gap={1}>
              <PlaceOutlinedIcon color="primary" fontSize="small" />
              <Typography color="primary" variant="heading_400">
                {t('CUSTOMER.STEPS.serviceAddress.title')}
              </Typography>
            </Box>
            <IconButton onClick={() => setStep(-2)}>
              <EditIcon color="primary" fontSize="small" />
            </IconButton>
          </Box>
          <Box display="flex" flexDirection="column">
            {values?.addresses?.map((address: any, index: number) => (
              <Box
                display="flex"
                flexDirection="row"
                justifyContent="space-between"
              >
                <Typography
                  sx={{ fontWeight: 'bold', marginBottom: '10px' }}
                  variant="subtitle2"
                >
                  {`#${index + 1}`}
                </Typography>
                <Typography variant="body2">{address.name}</Typography>
                <Typography variant="body2">{address.phone}</Typography>
                <Typography variant="body2">{address.address}</Typography>
              </Box>
            ))}
          </Box>
        </Box>

        {/* Contacts */}
        <Box
          sx={{
            border: `1px solid ${theme.palette.secondary[100]}`,
            borderRadius: 2,
            mb: 3,
            padding: 2
          }}
        >
          <Box
            alignItems="center"
            display="flex"
            justifyContent="space-between"
            mb={2}
          >
            <Box alignItems="center" display="flex" gap={1}>
              <LocalPhoneOutlinedIcon color="primary" fontSize="small" />
              <Typography color="primary" variant="heading_400">
                {t('CUSTOMER.STEPS.pointOfContact.title')}
              </Typography>
            </Box>
            <IconButton onClick={() => setStep(-1)}>
              <EditIcon color="primary" fontSize="small" />
            </IconButton>
          </Box>
          <Box display="flex" flexDirection="column">
            {values.contacts.map((contact: any, index: number) => (
              <Box
                display="flex"
                flexDirection="row"
                justifyContent="space-between"
              >
                <Typography
                  sx={{ fontWeight: 'bold', marginBottom: '10px' }}
                  variant="subtitle2"
                >
                  {`${t('CUSTOMER.contactN')}${index + 1}`}
                </Typography>
                <Typography variant="body2">{contact.name}</Typography>
                <Typography variant="body2">{contact.phoneNumber}</Typography>
              </Box>
            ))}
          </Box>
        </Box>
        <Box display="flex" justifyContent="right">
          <Button
            onClick={() => setUpdateClientApi(true)}
            sx={{ width: '380px' }}
            variant="primary"
          >
            {t('CUSTOMER.save')}
          </Button>
        </Box>
      </Box>
    );
  };

  useEffect(() => {
    if (clientData) {
      setUpdateClientApi(false);
      setStep(1);
    }
  }, [clientData]);

  return (
    <FlexBox alignItems="left" flexDirection="column" gap={2}>
      <Typography
        color="primary.400"
        sx={{ fontSize: '28px', lineHeight: '30px' }}
        textAlign="left"
        variant="primary-bold"
        whiteSpace="break-spaces"
      >
        {translate('summary')}
      </Typography>
      <Typography textAlign="left">
        {translate('summaryDescription')}
      </Typography>
      {getSummary()}
    </FlexBox>
  );
}

export default Summary;
