/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable react/jsx-props-no-spreading */
import { Box, Button, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import useSWR from 'swr';
import DatePickerTextField from '../components/DatePickerTextField';
import { FormLabel } from '../../../../components/form/parts';
import { secondaryColors } from '../../../../theme/themeConfig';
import AutoCompleteInput from '../../../../components/input/AutoCompleteInput';
import { capitalizeString } from '../../../../utils/general';
import api from '../../../../api';
import Dropzone from '../../../../components/input/Dropzone';
import {
  Address,
  Destination as DestinationType
} from '../../../../utils/types/DestinationsType';
import ViewLoader from '../../../../components/shared/ViewLoader';

interface Props {
  destinationIndex: number;
  destinationsValues: any;
  setCloseFormDestination: React.Dispatch<React.SetStateAction<void>>;
  setError: any;
  setServiceTimeUpdate: React.Dispatch<React.SetStateAction<boolean>>;
  setValues: React.Dispatch<React.SetStateAction<any>>;
  values: any;
}

const Destination: React.FC<Props> = function Destination({
  values,
  setValues,
  setCloseFormDestination,
  setServiceTimeUpdate,
  destinationIndex,
  destinationsValues,
  setError
}) {
  const { t } = useTranslation();
  const [destination, setDestination] = useState<any>({});
  const [createDestination, setCreateDestination] = useState<boolean>(false);
  const [updateDestination, setUpdateDestination] = useState<boolean>(false);
  const [fireAPIUploadDocs, setFireAPIUploadDocs] = useState<boolean>(false);
  const [fireAPIGetDocs, setFireAPIGetDocs] = useState<boolean>(true);
  const [files, setFiles] = useState<any>([]);
  const [initialFiles, setInitialFiles] = useState<any>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const { data: dataCreate, error: errorCreate } = useSWR(
    createDestination
      ? {
          addressId: destination?.address?.id,
          contactId: destination?.contact?.id,
          leadId: values.id
        }
      : null,
    api.leadsApi.leadApi.createDestination
  );

  const { data: dataUpdate, error: errorUpdate } = useSWR(
    updateDestination
      ? {
          addressId: destination?.address?.id,
          contactId: destination?.contact?.id,
          destinationId: destination?.id
        }
      : null,
    api.leadsApi.leadApi.updateDestination
  );

  const { data: fileUpload, error: errorFile } = useSWR(
    fireAPIUploadDocs && destination.id
      ? {
          entityId: destination.id,
          files: files?.length > 0 ? files : [],
          initialFiles,
          relatedEntity: 'destination'
        }
      : null,
    api.StorageApi.uploadEntityFiles
  );

  const { data: fileResponse, error: errorGetFiles } = useSWR(
    fireAPIGetDocs && destination.id
      ? {
          entityId: destination.id.toString(),
          relatedEntity: 'destination'
        }
      : null,
    api.StorageApi.getEntityFiles
  );

  const handleFiles = () => {
    setIsLoading(true);
    if (files.length > 0) {
      setFireAPIUploadDocs(true);
    } else {
      setCloseFormDestination();
    }
  };

  useEffect(() => {
    if (fileResponse) {
      setFireAPIGetDocs(false);
      setInitialFiles(fileResponse.data);
    }
  }, [fileResponse]);

  useEffect(() => {
    if (errorCreate) {
      setCreateDestination(false);
      setError('Error creating destination');
    }
    if (errorUpdate) {
      setUpdateDestination(false);
      setError('Error updating destination');
    }
  }, [errorCreate, errorUpdate]);

  useEffect(() => {
    if (dataCreate) {
      setCreateDestination(false);
      setDestination(dataCreate);
      handleFiles();
      setValues({
        ...values,
        destinations: [...values.destinations, dataCreate]
      });
      setIsLoading(false);
    }
  }, [dataCreate]);

  useEffect(() => {
    if (dataUpdate) {
      setDestination(dataUpdate);
      setUpdateDestination(false);
      handleFiles();
      setValues({
        ...values,
        destinations: values.destinations.map(
          (destination: any, index: number) =>
            index === destinationIndex ? { ...dataUpdate } : destination
        )
      });
      setIsLoading(false);
    }
  }, [dataUpdate]);

  useEffect(() => {
    if (!values?.destinations || !values.destinations[destinationIndex]) return;
    const destinationId = values.destinations[destinationIndex]?.id;
    const foundDestination = destinationsValues.find(
      (destValue: any) => destValue.id === destinationId
    );
    setDestination(foundDestination);
  }, [destinationIndex, values?.destinations]);

  useEffect(() => {
    if (fileUpload && !errorFile) {
      setIsLoading(false);
      setFireAPIUploadDocs(false);
      if (fileUpload?.data && fileUpload?.data?.length > 0) {
        setInitialFiles(fileUpload.data.map((file: any) => file.id));
      }
      setFiles(null);
      setCloseFormDestination();
    }
    if (errorFile) {
      setIsLoading(false);
      setFireAPIUploadDocs(false);
      setError('Error uploading files');
    }
  }, [fileUpload, errorFile]);

  const handleDestination = () => {
    setIsLoading(true);
    if (values?.destinations?.length === destinationIndex) {
      setCreateDestination(true);
      return;
    }
    setUpdateDestination(true);
  };

  function ServiceHeader() {
    return (
      <Box display="flex" gap="40px" justifyContent="space-between">
        <Box display="flex" flexDirection="column" gap="16px">
          <Typography color="primary.main" variant="heading_500">
            {`${t('LEAD.CREATE.DESTINATIONS.destination')} #${
              destinationIndex + 1
            }`}
          </Typography>
          <Typography color="secondary.400" variant="body_200">
            {t('LEAD.CREATE.DESTINATIONS.destinationDescription')}
          </Typography>
        </Box>
      </Box>
    );
  }

  const autoCompleteCustom = (
    key: string,
    disabled: boolean,
    options: any[],
    localError: boolean,
    placeHolder: string,
    value: any
  ) => {
    const helper =
      (options &&
        options.length &&
        value?.id &&
        options.find((option) => option.id === value?.id)) ||
      null;
    return (
      <AutoCompleteInput
        closeOnSelect
        disabled={!!disabled}
        onChange={(event: any, newValue: any | null) => {
          setDestination({ ...destination, [key]: newValue });
        }}
        options={options}
        renderInput={(params) => (
          <DatePickerTextField
            error={localError}
            placeholder={placeHolder}
            {...params}
          />
        )}
        sx={{
          margin: 0
        }}
        value={helper}
      />
    );
  };

  function ServiceTimeConfiguration() {
    return (
      <Box display="flex" flexDirection="column" gap="48px">
        {ServiceHeader()}
        <div
          style={{
            backgroundColor: secondaryColors.SECONDARY_3,
            height: '1px',
            width: '100%'
          }}
        />

        <Box
          style={{
            display: 'flex',
            flexDirection: 'row',
            gap: '48px',
            justifyContent: 'space-between'
          }}
        >
          <Box display="flex" flex={1} flexDirection="column" gap="4px">
            <FormLabel label={t('LEAD.CREATE.GENERAL_INFORMATION.contact')} />
            {autoCompleteCustom(
              'contact',
              !values.client?.contacts,
              values.client?.contacts?.map((item: any) => {
                return {
                  ...item,
                  name: `${item.name}`
                };
              }),
              !values.client?.contacts,
              capitalizeString(
                `${t('LEAD.CREATE.GENERAL_INFORMATION.select')} ${t(
                  'LEAD.CREATE.GENERAL_INFORMATION.contact'
                )}`
              ),
              destination.contact
            )}
          </Box>
          <Box display="flex" flex={1} flexDirection="column" gap="4px">
            <FormLabel label={t('LEAD.CREATE.GENERAL_INFORMATION.address')} />
            {autoCompleteCustom(
              'address',
              values?.readOnly,
              values.client?.addresses
                ?.filter(
                  (address: Address) =>
                    !values?.destinations?.some(
                      (destination: DestinationType) =>
                        destination.address.id === address.id
                    ) || address.id === destination.address?.id
                )
                ?.map((item: any) => {
                  return {
                    ...item,
                    name: `${item.name}`
                  };
                }),
              !values.client?.addresses,
              capitalizeString(
                `${t('LEAD.CREATE.GENERAL_INFORMATION.select')} ${t(
                  'LEAD.CREATE.GENERAL_INFORMATION.address'
                )}`
              ),
              destination.address
            )}
          </Box>
        </Box>
      </Box>
    );
  }

  function Buttons() {
    return (
      <Box display="flex" gap="16px" justifyContent="flex-end">
        <Button
          fullWidth
          disabled={values?.readOnly}
          onClick={() => setCloseFormDestination()}
          sx={{ width: '304px' }}
          variant="cancel"
        >
          {t('GENERAL.cancelButton')}
        </Button>
        <Button
          fullWidth
          disabled={values?.readOnly}
          onClick={() => {
            handleDestination();
          }}
          sx={{ width: '304px' }}
          variant="primary"
        >
          {t('GENERAL.saveButton')}
        </Button>
      </Box>
    );
  }

  function documents() {
    return (
      <Box display="flex" flexDirection="column" gap="8px">
        <FormLabel
          label={t('LEAD.CREATE.GENERAL_INFORMATION.uploadDocuments')}
        />
        <Dropzone
          multiple
          filesList={[...initialFiles, ...files]}
          isMobile={false}
          parent="lead"
          setFiles={(newFiles) => {
            setFiles([...newFiles]);
          }}
          type="file"
        />
      </Box>
    );
  }

  if (isLoading) {
    return <ViewLoader />;
  }

  return (
    <Box>
      {values && (
        <Box display="flex" flexDirection="column" gap="48px">
          {ServiceTimeConfiguration()}
          {documents()}
          {Buttons()}
        </Box>
      )}
    </Box>
  );
};

export default Destination;
