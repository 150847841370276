/* eslint-disable no-nested-ternary */
/* eslint-disable react/require-default-props */
import { useState } from 'react';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Typography,
  useTheme
} from '@mui/material';
import { useTranslation } from 'react-i18next';

type UserType = {
  email: string;
  firstName: string;
  id: string;
  lastName: string;
  phoneNumber: string;
};

type Technicians = {
  closedBy?: string;
  closer?: UserType;
  startedBy?: string;
  starter?: UserType;
  technician?: UserType;
};

type ShowInformationIndicatorProps = {
  technicians: Technicians[];
};

const ShowInformationIndicator: React.FC<ShowInformationIndicatorProps> =
  function ShowInformationIndicator({
    technicians
  }: ShowInformationIndicatorProps) {
    const { t } = useTranslation();
    const theme = useTheme();
    const [open, setOpen] = useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    // Disable button if we have 0 technicians or none have started a task
    const isButtonDisabled = () =>
      technicians?.length === 0 ||
      !technicians?.some(({ startedBy }) => startedBy);

    const iconColor = () => {
      if (isButtonDisabled() || technicians?.length === 0)
        return 'secondary.200';

      // If all closedBy and startedBy are "Technician", return success.main.
      const allTechnicians = technicians?.every(
        ({ closedBy, startedBy }) =>
          closedBy === 'Technician' && startedBy === 'Technician'
      );

      // If there's at least one "Operator" in closedBy or startedBy, return warning.400.
      const anyOperator = technicians?.some(
        ({ closedBy, startedBy }) =>
          closedBy === 'Operator' || startedBy === 'Operator'
      );

      return anyOperator
        ? theme.palette.warning[400]
        : allTechnicians
        ? theme.palette.success.main
        : theme.palette.warning[400];
    };

    return (
      <Box>
        {/* Button to Open Modal */}
        <Button
          color="primary"
          disabled={isButtonDisabled()}
          onClick={handleOpen}
          sx={{ minWidth: 30, padding: 0.5 }}
          variant="primary-text"
        >
          <i
            className="custom-icon icon-taskSuccess"
            style={{
              color: iconColor(),
              fontSize: '20px'
            }}
          />
        </Button>

        {/* Modal */}
        <Dialog
          fullWidth
          PaperProps={{
            sx: {
              borderRadius: '8px',
              padding: '40px'
            }
          }}
          maxWidth="sm"
          onClose={handleClose}
          open={open}
        >
          <DialogContent
            className="scrollbar"
            sx={{
              textAlign: 'center'
            }}
          >
            <Box display="flex" flexDirection="column" gap={3}>
              {technicians.length > 0 ? (
                technicians.map(
                  (
                    { closedBy, closer, startedBy, starter, technician },
                    index: number
                  ) => {
                    const sameStarter = starter?.id === technician?.id;
                    const sameCloser = closer?.id === technician?.id;
                    return (
                      <Box
                        display="flex"
                        flexDirection="column"
                        gap={1}
                        key={technician?.id || `tech-${index}`}
                      >
                        {/* Title */}
                        {technician?.id ? (
                          <Typography
                            color="secondary.400"
                            variant="heading_300"
                          >
                            {`${technician?.firstName} ${technician?.lastName}`}
                          </Typography>
                        ) : (
                          <Typography
                            color="secondary.400"
                            variant="heading_300"
                          >
                            {`${t('INFORMATIONINDICATOR.noInformation')}`}
                          </Typography>
                        )}

                        {starter?.id ? (
                          <Typography color="secondary.400" variant="body_200">
                            {`${t('INFORMATIONINDICATOR.startedBy')}: ${
                              starter?.firstName
                            } ${starter?.lastName}`}
                            {!sameStarter && (
                              <span style={{ color: 'secondary.400' }}>
                                {` ${starter?.email} ${starter?.phoneNumber} (${startedBy})`}
                              </span>
                            )}
                          </Typography>
                        ) : (
                          <Typography color="secondary.400" variant="body_200">
                            {`${t('INFORMATIONINDICATOR.startedBy')}: ${t(
                              'INFORMATIONINDICATOR.noStartedBy'
                            )}`}
                          </Typography>
                        )}

                        {closer?.id ? (
                          <Typography color="secondary.400" variant="body_200">
                            {`${t('INFORMATIONINDICATOR.closedBy')}: ${
                              closer?.firstName
                            } ${closer?.lastName}`}
                            {!sameCloser && (
                              <span style={{ color: 'secondary.400' }}>
                                {` ${closer?.email} ${closer?.phoneNumber} (${closedBy})`}
                              </span>
                            )}
                          </Typography>
                        ) : (
                          <Typography color="secondary.400" variant="body_200">
                            {`${t('INFORMATIONINDICATOR.closedBy')}: ${t(
                              'INFORMATIONINDICATOR.noClosedBy'
                            )}`}
                          </Typography>
                        )}
                      </Box>
                    );
                  }
                )
              ) : (
                <Typography color="secondary.400" variant="heading_300">{`${t(
                  'INFORMATIONINDICATOR.noInformation'
                )}.`}</Typography>
              )}
            </Box>
          </DialogContent>

          <DialogActions sx={{ gap: 1, justifyContent: 'center', mt: 1.5 }}>
            <Button
              onClick={handleClose}
              sx={{ width: 200 }}
              variant="contained"
            >
              {t('GENERAL.closeButton')}
            </Button>
          </DialogActions>
        </Dialog>
      </Box>
    );
  };

export default ShowInformationIndicator;
