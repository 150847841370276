/* eslint-disable max-len */
import React, { useCallback, useEffect, useState } from 'react';
import { Button, styled, Typography, Stack } from '@mui/material';
import { Container } from '@mui/system';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import useSWR from 'swr';
import PasswordInput from '../../input/PasswordInput';
import api from '../../../api';
import { validRegexStringRules } from '../../../utils/general';
import { secondaryColors, themePalette } from '../../../theme/themeConfig';

interface Props {
  isCompany: boolean;
  setErrorApi: React.Dispatch<React.SetStateAction<boolean>>;
  setIsStepperDisabled: (isDisabled: boolean) => void;
  token: string | undefined;
}

const PasswordContainer = styled(Stack)(() => ({
  alignItems: 'start',
  flexDirection: 'column',
  gap: '50px',
  height: 'inherit',
  width: '100%'
}));

const PasswordFormContainer = styled(Stack)(() => ({
  alignItems: 'center',
  gap: '24px',
  width: '100%'
}));

const PasswordInputContainer = styled(Stack)(() => ({
  width: '100%'
}));

const SaveButton = styled(Button)(() => ({
  fontWeight: 'bold',
  width: '100%'
}));

const inputStyle = {
  backgroundColor: themePalette.BACKGROUND,
  border: '1px solid',
  borderColor: secondaryColors.SECONDARY_2,
  color: themePalette.TITLE,
  margin: 0,
  padding: '4px 16px',
  placeholderColor: secondaryColors.SECONDARY_3
};

const Password: React.FC<Props> = function Password({
  token,
  setErrorApi,
  isCompany,
  setIsStepperDisabled
}) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [password, setPassword] = useState<string>('');
  const [confirmPassword, setConfirmPassword] = useState<string>('');
  const [fireApiPassword, setFireApiPassword] = useState<boolean>(false);
  const [fireApiCompanyPassword, setFireApiCompanyPassword] =
    useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [error, setError] = useState<boolean>(false);
  const [rules, setRules] = useState<Array<any>>([]);
  const [isDisabled, setIsDisabled] = useState<boolean>(true);

  const { data: dataPassword, error: errorPassword } = useSWR(
    fireApiPassword ? { password, token } : null,
    api.authApi.setAcceptInvitation
  );

  const { data: dataPasswordCompany, error: errorPasswordCompany } = useSWR(
    fireApiCompanyPassword ? { companyDescription: '', password, token } : null,
    api.authApi.setAcceptCompanyInvitation
  );

  const { data: rulesResponse, error: rulesError } = useSWR(
    !rules.length ? 'api/getPasswordRules' : null,
    api.ConfigApi.getPasswordRules
  );

  const getError = () =>
    !error ? (
      false
    ) : (
      <Container sx={{ mt: 1 }}>
        <Typography
          color="alert.main"
          sx={{ fontSize: 14 }}
          variant="primary-bold"
        >
          &bull; {errorMessage}
        </Typography>
      </Container>
    );

  const validateFields = useCallback(() => {
    if (password === '' || confirmPassword === '') {
      setError(true);
      setErrorMessage(`${t('LOGIN.invitationErrorEmpty')}`);
      return false;
    }
    if (password !== confirmPassword) {
      setError(true);
      setErrorMessage(`${t('LOGIN.invitationError')}`);
      return false;
    }
    return true;
  }, [password, confirmPassword, t]);

  const executeFunctionality = () => {
    if (token) {
      if (isCompany) {
        setFireApiCompanyPassword(true);
      } else {
        setFireApiPassword(true);
      }
    }
  };

  const confirmInvitation = () => {
    if (!validateFields()) {
      return;
    }
    executeFunctionality();
  };

  useEffect(() => {
    if (dataPassword && dataPassword.accessToken) {
      navigate(`/invitation/accept-invitation/${token}/3`);
    }
  }, [dataPassword]);

  useEffect(() => {
    if (dataPasswordCompany && dataPasswordCompany.accessToken) {
      navigate(`/company/invitation/accept-invitation/${token}/3`);
    }
  }, [dataPasswordCompany]);

  useEffect(() => {
    if (rulesResponse) {
      setRules(rulesResponse.data);
    }
  }, [rulesResponse]);

  useEffect(() => {
    if (errorPassword || rulesError) {
      setError(true);
      setErrorMessage(
        `${
          errorPassword && errorPassword.message
            ? errorPassword.message
            : t('LOGIN.invitationErrorEndpoint')
        }`
      );
      setFireApiPassword(false);
      setErrorApi(true);
    }
  }, [errorPassword, rulesError]);

  useEffect(() => {
    if (errorPasswordCompany || rulesError) {
      setError(true);
      setErrorMessage(
        `${
          errorPasswordCompany && errorPasswordCompany.message
            ? errorPasswordCompany.message
            : t('LOGIN.invitationErrorEndpoint')
        }`
      );
      setFireApiPassword(false);
      setErrorApi(true);
    }
  }, [errorPasswordCompany, rulesError]);

  useEffect(() => {
    const isValid =
      password !== '' && confirmPassword !== '' && password === confirmPassword;
    const validInput = validRegexStringRules(rules, password);
    if (validInput.error) {
      setError(validInput.error);
      setErrorMessage(validInput.errorMessage);
      return;
    }
    setIsDisabled(!isValid);
    setIsStepperDisabled(!isValid);
  }, [password, confirmPassword]);

  useEffect(() => {
    if (password) {
      const validInput = validRegexStringRules(rules, password);
      setError(validInput.error);
      setErrorMessage(validInput.errorMessage);
      if (!validInput.error && confirmPassword !== '') {
        setError(confirmPassword !== password);
        setErrorMessage(
          `${confirmPassword !== password ? t('LOGIN.invitationError') : ''}`
        );
      }
    }
  }, [password]);

  useEffect(() => {
    if (confirmPassword) {
      setError(confirmPassword !== password);
      setErrorMessage(
        `${confirmPassword !== password ? t('LOGIN.invitationError') : ''}`
      );
    }
  }, [confirmPassword]);

  return (
    <PasswordContainer>
      <Stack gap="8px">
        <Typography
          color="primary"
          textTransform="capitalize"
          variant="heading_500"
        >
          {t('LOGIN.invitationTitle')}
        </Typography>
        <Typography color="secondary" variant="body_200">
          {t('LOGIN.companyPasswordDescription')}
        </Typography>
      </Stack>

      <PasswordFormContainer>
        <PasswordInputContainer>
          <Typography color="primary" variant="primary-bold">
            {t('LOGIN.resetPasswordNew')}
          </Typography>
          <PasswordInput
            customStyle={{ ...inputStyle }}
            error={error}
            onChange={(value) => setPassword(value)}
            submitEvent={confirmInvitation}
          />
        </PasswordInputContainer>
        <PasswordInputContainer>
          <Typography color="primary" variant="primary-bold">
            {t('LOGIN.resetPasswordConfirm')}
          </Typography>
          <PasswordInput
            customStyle={{ ...inputStyle }}
            error={error}
            onChange={(value) => setConfirmPassword(value)}
            submitEvent={confirmInvitation}
          />
        </PasswordInputContainer>
        {getError()}
      </PasswordFormContainer>

      <SaveButton
        disabled={isDisabled}
        onClick={confirmInvitation}
        variant="primary"
      >
        {t('GENERAL.saveButton')}
      </SaveButton>
    </PasswordContainer>
  );
};

export default Password;
