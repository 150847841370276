import React, { useEffect, useState } from 'react';
import useSWR from 'swr';
import { Button, Typography, Container, Stack, styled } from '@mui/material';
import { useTranslation } from 'react-i18next';
import GppGoodOutlinedIcon from '@mui/icons-material/GppGoodOutlined';
import { isMobile } from 'react-device-detect';
import api from '../../../api';
import BasePageMFA from '../../shared/mfaForms/BasePageMFA';

interface Props {
  isCompany: boolean;
  login: () => void;
  setErrorApi: React.Dispatch<React.SetStateAction<boolean>>;
  setIsStepperDisabled: (isDisabled: boolean) => void;
  setUser: (data: any) => void;
  token: string | undefined;
}

const MfaRegistrationContainer = styled(Stack)(() => ({
  flexDirection: 'column',
  height: 'inherit',
  justifyContent: 'space-between',
  width: '100%'
}));

const CustomButton = styled(Button)(() => ({
  fontWeight: 'bold',
  textTransform: 'capitalize',
  width: '100%'
}));

const MfaSuccess: React.FC<Props> = function MfaSuccess({
  setUser,
  setIsStepperDisabled,
  token,
  isCompany,
  login,
  setErrorApi
}) {
  const [showMFA, setShowMFA] = useState<boolean>(false);

  const { t } = useTranslation();
  const { data: profile, error } = useSWR(
    'api/profile',
    api.authApi.getProfile
  );

  const setProfile = () => {
    if (!error) {
      if (profile) {
        const {
          roles,
          email,
          mfa,
          block,
          active,
          firstName,
          id,
          phoneNumber,
          timeFormat,
          company
        } = profile;
        const { plan } = company;
        const rolesName = roles.map((ele: any) => ele.name);
        setUser({
          active,
          block,
          email,
          firstName,
          id,
          mfa,
          phoneNumber,
          plan,
          roles: rolesName,
          timeFormat
        });
      }
    }
  };

  useEffect(() => {
    setProfile();
    setIsStepperDisabled(false);
  }, []);

  return (
    <MfaRegistrationContainer>
      <Container sx={{ display: 'flex' }}>
        <Stack gap="88px">
          <Typography color="primary" variant="heading_400">
            {t('LOGIN.successTitle')}
          </Typography>

          <Stack gap="55px">
            <GppGoodOutlinedIcon
              color="success"
              sx={{ fontSize: '80px', mx: 'auto' }}
            />

            <Stack gap="40px">
              <Typography color="secondary.500" variant="body_300">
                {t('LOGIN.mfaSetUpSuccessDescriptionOne')}
                <br />
                <br />
                {t('LOGIN.mfaSetUpSuccessDescriptionTwo')}
              </Typography>
              <CustomButton
                onClick={() => setShowMFA(!showMFA)}
                variant="outlined"
              >
                {t('GENERAL.setUpMfaSMSAuthButton', [
                  profile?.twoFactor?.method === 'Email' ? 'SMS' : 'Email'
                ])}
              </CustomButton>
            </Stack>
          </Stack>
        </Stack>
      </Container>
      {showMFA && (
        <BasePageMFA
          isCompany={isCompany}
          isMobile={isMobile}
          login={login}
          setErrorApi={setErrorApi}
          setUser={setUser}
          token={token}
        />
      )}
    </MfaRegistrationContainer>
  );
};

export default MfaSuccess;
