import { lazy, Suspense } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import { isMobile } from 'react-device-detect';
import {
  AUTHORIZED_VIEW_NAMES,
  DEFAULT_SCREENS,
  GLOBAL
} from '../helpers/routesConstants';
import ScreenLoader from '../components/shared/ScreenLoader';
// import OnboardingClient from './clientPage/OnboardingClient';
import OnboardingClient from './clientPage/onboarding/OnboardingClient';

function ClientContainerUnauthenticated() {
  return (
    <Routes>
      <Route
        element={
          <Suspense fallback={<ScreenLoader />}>
            <OnboardingClient />
          </Suspense>
        }
        path={AUTHORIZED_VIEW_NAMES.ONBOARDING}
      />
    </Routes>
  );
}

export default ClientContainerUnauthenticated;
