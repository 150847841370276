/* eslint-disable react/require-default-props */
/* eslint-disable import/no-cycle */
import React from 'react';

import ServicesTimeContainer from './components/ServicesTimeContainer';
import { Destination } from '../../../utils/types/ProjectType';

export interface Props {
  dayChecked: string;
  destinations: Destination[];
  detailProjectId: string;
  projectName?: string;
  projectType: string;
  typeView: string;
  utcUpadtedAt: string;
}

const InvoiceServiceTimes: React.FC<Props> = function InvoiceServiceTimes({
  detailProjectId,
  projectType,
  typeView,
  dayChecked,
  utcUpadtedAt,
  destinations,
  projectName
}) {
  return (
    <ServicesTimeContainer
      dayChecked={dayChecked}
      destinations={destinations}
      detailProjectId={detailProjectId}
      projectName={projectName}
      projectType={projectType}
      typeView={typeView}
      utcUpadtedAt={utcUpadtedAt}
    />
  );
};

export default InvoiceServiceTimes;
