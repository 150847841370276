/* eslint no-use-before-define: ["error", { "functions": false }] */
import React, { FC, useEffect, useState } from 'react';
import {
  Box,
  BoxProps,
  Button,
  Divider,
  MobileStepper,
  styled,
  useTheme
} from '@mui/material';
import { KeyboardArrowLeft, KeyboardArrowRight } from '@mui/icons-material';
import { isMobile } from 'react-device-detect';
import useSWR from 'swr';
import sideBannerFallback from '../../../assets/images/clientOnboarding/sideBannerFallback.jpeg';
import logo from '../../../assets/images/Bluberry_Logo.png';
import ImageSlider from './components/ImageSlider';
import { secondaryColors } from '../../../theme/themeConfig';
import Welcome from './steps/Welcome';
import CompleteInformation from './steps/CompleteInformation';
import BillingPreferences from './steps/BillingPreferences';
import ServiceLocationDetails from './steps/ServiceLocation';
import ContactInformation from './steps/ContactInformation';
import api from '../../../api';
import Success from './steps/Success';
import Summary from './steps/Summary';
import CommunicationMethod from './steps/CommunicationMethod';

// Styled components
const FlexBox = styled(Box)<BoxProps>(() => ({
  display: 'flex'
}));

const Container = styled(FlexBox)<BoxProps>(({ theme }) => ({
  flexDirection: 'row',
  gap: theme.spacing(1),
  height: '100vh',
  justifyContent: 'center',
  overflowY: 'scroll',
  width: '100vw'
}));

// Constants
const MIN_STEP = -1;

const OnboardingClient: FC = function OnboardingClient() {
  // State
  const [step, setStep] = React.useState<number>(MIN_STEP); // -1 == Welcome
  const [showStepper, setShowStepper] = useState<boolean>(false);
  const [images, setImages] = useState<any>();
  const [clientInfo, setClientInfo] = useState<any>();

  // SWR
  const { data: imagesData, error: imagesError } = useSWR(
    !images ? { url: 'client/onboarding/images' } : null,
    api.StorageApi.getClientOnboardingImages
  );

  const { data: clientData, error: clientError } = useSWR(
    !clientInfo ? { url: 'client/info' } : null,
    api.ClientPageApi.getClientInformation
  );
  // Handlers
  const handleNextStep = () => {
    if (step === MIN_STEP) setShowStepper(true);
    setStep(step + 1);
  };
  const handlePreviousStep = () => {
    if (step === 0) setShowStepper(false);
    setStep(step === MIN_STEP ? step : step - 1);
  };

  // Hooks
  const theme = useTheme();

  const steps = [
    {
      Component: <Welcome onStart={handleNextStep} values={clientInfo} />,
      stepperLabel: ''
    },
    {
      Component: (
        <CompleteInformation setValues={setClientInfo} values={clientInfo} />
      ),
      stepperLabel: ''
    },
    {
      Component: (
        <BillingPreferences setValues={setClientInfo} values={clientInfo} />
      ),
      stepperLabel: ''
    },
    {
      Component: (
        <CommunicationMethod setValues={setClientInfo} values={clientInfo} />
      ),
      stepperLabel: ''
    },
    {
      Component: (
        <ServiceLocationDetails setValues={setClientInfo} values={clientInfo} />
      ),
      stepperLabel: ''
    },
    {
      Component: (
        <ContactInformation setValues={setClientInfo} values={clientInfo} />
      ),
      stepperLabel: ''
    },
    {
      Component: (
        <Summary
          setStep={(s: number) => setStep(step + s)}
          values={clientInfo}
        />
      ),
      stepperLabel: ''
    },
    {
      Component: <Success onStart={handleNextStep} />,
      stepperLabel: ''
    }
  ];

  useEffect(() => {
    if (imagesData) {
      const imagesArray: any = imagesData;
      setImages(imagesArray?.map((image: any) => image.fileUrl));
    }
  }, [imagesData]);

  useEffect(() => {
    if (clientData) {
      setClientInfo(clientData);
    }
  }, [clientData]);

  useEffect(() => {
    if (step !== undefined) {
      if (
        step === steps.length - 2 ||
        step === steps.length - 3 ||
        step === -1
      ) {
        setShowStepper(false);
      } else {
        setShowStepper(true);
      }
    }
  }, [step]);

  return (
    <Container>
      {/* Left side */}
      {/* Todo: media queries... */}
      {!isMobile && (
        <Box
          sx={{
            padding: '30px',
            width: '100%'
          }}
        >
          <Box
            sx={{
              borderRadius: '8px',
              height: '100%',
              width: '100%'
            }}
          >
            <ImageSlider images={images || []} />
          </Box>
        </Box>
      )}

      {/* Right side / form (step) wrapper */}
      <FlexBox
        flexDirection="column"
        sx={{
          backgroundColor: '#fff',
          overflowY: 'scroll',
          position: 'relative',
          width: '100%'
        }}
      >
        {/* This box grows/pushes down the stepper below */}
        <FlexBox alignItems="center" flexDirection="column" flexGrow={2}>
          {/* Logo box */}
          <Box alignSelf="flex-start" sx={{ padding: '30px' }}>
            <img
              alt="logo"
              className="logo-image"
              src={logo}
              style={{ width: 200 }}
            />
          </Box>
          <Divider
            sx={{ borderColor: secondaryColors.SECONDARY_1, width: '100%' }}
          />
          {/* Dynamic rendering of forms */}
          <FlexBox
            marginX={theme.spacing(4)}
            marginY="auto"
            paddingX={theme.spacing(4)}
            paddingY={theme.spacing(3)}
            width="100%"
          >
            {steps.find((_, index) => index - 1 === step)?.Component ?? null}
          </FlexBox>
        </FlexBox>

        {/* Stepper */}
        {showStepper && (
          <MobileStepper
            activeStep={step}
            backButton={
              <Button onClick={handlePreviousStep}>
                <KeyboardArrowLeft />
              </Button>
            }
            nextButton={
              <Button onClick={handleNextStep}>
                <KeyboardArrowRight />
              </Button>
            }
            position="bottom"
            steps={steps.length - 1}
            sx={{
              position: 'sticky',
              width: '100%'
            }}
            variant="dots"
          />
        )}
      </FlexBox>
    </Container>
  );
};

export default OnboardingClient;
