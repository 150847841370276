import React, { useEffect, useState } from 'react';

import useSWR from 'swr';
import Api from '../../../../api';

interface CreateLeadSWRProps {
  createServiceTime: boolean;
  currentServiceTime: number;
  destinationId: number;
  id: string;
  setDataResult: React.Dispatch<React.SetStateAction<any>>;
  values: any;
}

export function CreateLeadSWR({
  id,
  values,
  createServiceTime,
  currentServiceTime,
  setDataResult,
  destinationId
}: CreateLeadSWRProps) {
  const { data: dataResult, error } = useSWR(
    createServiceTime
      ? [
          id,
          {
            ...values[currentServiceTime],
            destinationId: destinationId.toString()
          }
        ]
      : null,
    Api.LeadServiceTimesApi.createLeadServicesTime
  );
  if (error) {
    return <div>Ocurrió un error: {error.message}</div>;
  }
  useEffect(() => {
    setDataResult(dataResult);
  }, [dataResult]);

  return null;
}

interface EditLeadSWRProps {
  currentServiceTime: number;
  destinationId: number;
  editServiceTime: boolean;
  leadId: string;
  serviceTimeId: number;
  setDataResultEdit: React.Dispatch<React.SetStateAction<any>>;
  values: any;
}

export function EditLeadSWR({
  leadId,
  values,
  editServiceTime,
  currentServiceTime,
  serviceTimeId,
  setDataResultEdit,
  destinationId
}: EditLeadSWRProps) {
  const { data: dataResult, error } = useSWR(
    editServiceTime
      ? [
          leadId,
          serviceTimeId,
          {
            ...values[currentServiceTime],
            destinationId: destinationId.toString(),
            id: serviceTimeId
          }
        ]
      : null,
    Api.LeadServiceTimesApi.updateLeadServicesTime
  );

  if (error) {
    return <div>Ocurrió un error: {error.message}</div>;
  }
  useEffect(() => {
    setDataResultEdit(dataResult);
  }, [dataResult]);

  return null;
}
