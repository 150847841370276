import React, { useEffect, useState } from 'react';
import {
  Box,
  BoxProps,
  Button,
  CircularProgress,
  Grid,
  styled,
  Typography,
  TypographyProps
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Insights } from '@mui/icons-material';
import useSWR from 'swr';
import { useNavigate } from 'react-router-dom';
import { secondaryColors } from '../../../theme/themeConfig';
import { getFormatedTimeByTimeZone } from '../../../utils/general';
import { TimeFormatEnum } from '../../../api-client/generated';
import useAuthStore from '../../../store/zustand/auth';
import ImageStrip from '../../../components/ImageStrip';
import useNavigationStore from '../../../store/zustand/navigation';
import api from '../../../api';
import useNotificationsStore from '../../../store/zustand/notifications';

const Text: React.FC<TypographyProps> = function Text({ children, color }) {
  return (
    <Typography
      color={color ?? `${secondaryColors.SECONDARY}`}
      fontSize={12}
      variant="secondary"
    >
      {children}
    </Typography>
  );
};

const Row = styled(Box)<BoxProps>(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  marginBlock: theme.spacing(1)
}));

// ================================================================== MAIN COMPONENT

interface Props {
  callBack: () => void;
  iteration: any;
  technician: any;
}
const ProjectTaskDetails: React.FC<Props> = function ProjectTaskDetails({
  iteration,
  technician,
  callBack
}) {
  // ================================================================== CONST
  const { t } = useTranslation('translation', {
    keyPrefix: 'PROJECT.TASKDETAILSMODAL'
  });
  const { t: translation } = useTranslation();
  const { setConfirmModal } = useNavigationStore((state) => state);
  const userState = useAuthStore((state) => state);
  const navigate = useNavigate();
  const { addNotification } = useNotificationsStore((state) => state);

  // ================================================================== STATES

  const [completeApi, setCompleteApi] = useState<boolean>(false);

  // ================================================================== FUCNTIONS

  const { data: completeData, error: completeError } = useSWR(
    completeApi
      ? { taskIterationId: iteration?.id, technicianId: technician?.id }
      : null,
    api.CalendarApi.patchTechnicianTaskManagenmentCloseTask
  );

  // ================================================================== FUCNTIONS

  const valueProcessor = (value: any) => {
    if (typeof value === 'boolean') {
      return value ? t('confirmed') : t('noConfirmed');
    }
    return value || t('noInformation');
  };

  // ================================================================== LIFECYCLE

  useEffect(() => {
    if (completeData) {
      setCompleteApi(false);
      addNotification(translation('PROJECT.TOAST.SUCCESS'), 'success');
      callBack();
    }
  }, [completeData]);

  useEffect(() => {
    if (completeError) {
      setCompleteApi(false);
      addNotification(translation('PROJECT.TOAST.ERROR'), 'error');
    }
  }, [completeError]);

  // ================================================================== MAIN RENDER

  return (
    <Grid container spacing={6} width="80vw">
      <Grid
        item
        alignItems="center"
        direction="column"
        display="flex"
        justifyContent="center"
        width="100%"
        xs={3}
      >
        <Row width="100%">
          <Button
            onClick={() => {
              window.location.href = `tel:${technician?.phoneNumber}`;
            }}
            sx={{
              display: 'flex',
              flexDirection: 'row',
              fontSize: 14,
              gap: 2,
              height: 36,
              textAlign: 'center',
              width: '100%'
            }}
            variant="secondary-outlined"
          >
            <i className="custom-icon icon-classic-phone" />
            {t('callTechnician')}
          </Button>
        </Row>
        <Row width="100%">
          <Button
            onClick={() => {
              navigate(`/app/resources/insights/${technician?.id}`, {
                state: { user: { ...technician } }
              });
            }}
            sx={{
              display: 'flex',
              flexDirection: 'row',
              fontSize: 14,
              gap: 2,
              height: 36,
              textAlign: 'center',
              width: '100%'
            }}
            variant="secondary-outlined"
          >
            <Insights />
            {t('technicianInsights')}
          </Button>
        </Row>
      </Grid>
      <Grid item xs alignContent="center">
        <Row>
          <Text>{t('geofencing')}</Text>
          <Text>{valueProcessor(iteration?.geofencingApproved)}</Text>
        </Row>
        <Row>
          <Text>{t('checklist')}</Text>
          <Text>
            {`${
              iteration?.checkList.filter((item: any) => item.checked).length
            }/${iteration?.checkList?.length}`}
          </Text>
        </Row>
        <Row>
          <Text>{t('startTime')}</Text>
          <Text>
            {getFormatedTimeByTimeZone(
              iteration?.startTime,
              0,
              // eslint-disable-next-line no-underscore-dangle
              userState.timeFormat === TimeFormatEnum._24h
            )}
          </Text>
        </Row>
        <Row>
          <Text>{t('endTime')}</Text>
          <Text>
            {getFormatedTimeByTimeZone(
              iteration?.endTime,
              0,
              // eslint-disable-next-line no-underscore-dangle
              userState.timeFormat === TimeFormatEnum._24h
            )}
          </Text>
        </Row>
      </Grid>
      <Grid item xs>
        <Row>
          <Text>{t('startedBy')}</Text>
          <Text>{valueProcessor(iteration?.startedBy)}</Text>
        </Row>
        <Row>
          <Text>{t('starter')}</Text>
          <Text>
            {valueProcessor(
              iteration?.starter
                ? `${iteration?.starter?.firstName} ${iteration?.starter?.lastName}`
                : valueProcessor(undefined)
            )}
          </Text>
        </Row>
        <Row>
          <Text>{t('closedBy')}</Text>
          <Text>{valueProcessor(iteration?.closedBy)}</Text>
        </Row>
        <Row>
          <Text>{t('closer')}</Text>
          <Text>
            {valueProcessor(
              iteration?.closer
                ? `${iteration?.closer?.firstName} ${iteration?.closer?.lastName}`
                : valueProcessor(undefined)
            )}
          </Text>
        </Row>
      </Grid>
      <Grid item justifyContent="center" xs={12}>
        <ImageStrip
          images={iteration?.fileBank?.map((file: any) => file) ?? []}
        />
      </Grid>
      {iteration?.status !== 'completed' && (
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'flex-end',
            paddingTop: 2,
            width: '100%'
          }}
        >
          <Button
            disabled={completeApi}
            onClick={() => {
              setConfirmModal(
                async () => {
                  setCompleteApi(true);
                },
                `${translation('GENERAL.confirmButton')}`,
                () => {},
                `${translation('GENERAL.cancelButton')}`,
                <Box
                  alignItems="center"
                  display="flex"
                  flexDirection="column"
                  gap={2}
                  justifyContent="center"
                  pt={2}
                  width={350}
                >
                  <Typography variant="heading_500">
                    {t('completeTask')}
                  </Typography>

                  <Typography variant="body_300">
                    <Typography
                      sx={{ whiteSpace: 'pre-line' }}
                      variant="body_300"
                    >
                      {t('completeTaskDescription')}
                    </Typography>
                  </Typography>
                </Box>
              );
            }}
            sx={{ height: 40, width: 200 }}
            variant="primary-outlined"
          >
            <Box alignItems="center" display="flex" gap={2}>
              {completeApi ? (
                <CircularProgress size={14} />
              ) : (
                <Typography fontSize="16px" variant="heading_328">
                  {t('completeTaskButton')}
                </Typography>
              )}
            </Box>
          </Button>
        </Box>
      )}
    </Grid>
  );
};

export default ProjectTaskDetails;
