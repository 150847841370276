import { styled, Box, Typography, IconButton, Stack } from '@mui/material';
import { ArrowBackIosNew, ArrowForwardIos } from '@mui/icons-material';

interface StepperProps {
  currentStep?: number;
  disableNextButton?: boolean;
  disablePreviousButton?: boolean;
  onNext?: () => void;
  onPrevious?: () => void;
  steps: string[];
}

const StepperContainer = styled(Stack)(() => ({
  alignItems: 'center',
  flexDirection: 'row',
  justifyContent: 'center',
  width: '100%'
}));

const StepIndicatorContainer = styled(Stack)(() => ({
  alignItems: 'center',
  flex: 1,
  flexDirection: 'column',
  gap: '1rem'
}));

const StepDotContainer = styled(Stack)(() => ({
  alignItems: 'center',
  flexDirection: 'row',
  gap: '0.5rem',
  justifyContent: 'center'
}));

const StepDot = styled(Box)<{ $isActive: boolean }>(({ $isActive }) => ({
  backgroundColor: $isActive ? '#4b5563' : '#d1d5db',
  borderRadius: '9999px',
  height: '0.5rem',
  width: '0.5rem'
}));

export default function Stepper({
  steps,
  currentStep = 0,
  onNext,
  onPrevious,
  disableNextButton,
  disablePreviousButton
}: StepperProps) {
  return (
    <StepperContainer>
      <IconButton disabled={disablePreviousButton} onClick={onPrevious}>
        <ArrowBackIosNew />
      </IconButton>

      <StepIndicatorContainer>
        <StepDotContainer>
          {steps.map((_, index) => (
            <StepDot $isActive={index === currentStep} key={steps[index]} />
          ))}
        </StepDotContainer>
        <Typography color="secondary" variant="body_200">
          {steps[currentStep]}
        </Typography>
      </StepIndicatorContainer>

      <IconButton disabled={disableNextButton} onClick={onNext}>
        <ArrowForwardIos />
      </IconButton>
    </StepperContainer>
  );
}

Stepper.defaultProps = {
  currentStep: 0,
  disableNextButton: false,
  disablePreviousButton: false,
  onNext: () => {},
  onPrevious: () => {}
};
