/* eslint-disable react/jsx-props-no-spreading */
import {
  Box,
  Button,
  SliderProps,
  SlideProps,
  Typography,
  styled,
  Modal,
  Container,
  MenuItem
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import useSWR from 'swr';
import { useTranslation } from 'react-i18next';
import api from '../../api';
import RegularInput from '../input/RegularInput';
import { FormLabel } from '../form/parts';
import SelectInput from '../form/SelectInput';
import { planEnum, planOptions } from '../../utils/companyHelper';
import { CreateCompanyInvitationDTOPlanEnum } from '../../api-client/generated';
import useNotificationsStore from '../../store/zustand/notifications';

const ModalContainer = styled(Box)<SliderProps>(({ theme }) => ({
  alignItems: 'center',
  backgroundColor: theme.palette.background.paper,
  borderRadius: '0.5em',
  boxShadow: '24',
  display: 'flex',
  flexDirection: 'column',
  gap: 16,
  height: 460,
  padding: '40px 60px',
  position: 'absolute' as 'absolute',
  right: '50%',
  textAlign: 'center',
  top: '50%',
  transform: 'translate(50%, -50%)',
  width: 600
}));

const SubTitle = styled(Typography)<SliderProps>(({ theme }) => ({
  marginBottom: '-12px',
  marginTop: '14px'
}));

const ButtonContainerModal = styled(Box)<SlideProps>(() => ({
  display: 'flex',
  justifyContent: 'center',
  width: '100%'
}));

const DrawerButton = styled(Button)<SlideProps>(() => ({
  color: 'white',
  marginBottom: '15px',
  marginLeft: '5px',
  marginRight: '5px',
  width: '100%'
}));

interface Props {
  closeModal: () => void;
  item: any;
  setShowModal: (item: boolean) => void;
}
interface FormValuess {
  plan: planEnum;
  widgetsAllowed: number;
}

const CompanyConfigurationsModal: React.FC<Props> =
  function CompanyConfigurationsModal({ closeModal, item, setShowModal }) {
    const { t } = useTranslation();
    const { addNotification } = useNotificationsStore((state) => state);
    const [formValues, setFormValues] = useState<FormValuess>({
      plan: CreateCompanyInvitationDTOPlanEnum.Calculator,
      widgetsAllowed: item?.widgetsAllowed || 0
    });
    const [updateCompanyApi, setUpdateCompanyApi] = useState<boolean>(false);

    //  ================================================================================= SWR

    const { data, error } = useSWR(
      updateCompanyApi ? [item.id, formValues] : null,
      api.CompanyApi.updateCompanyById
    );

    //  ================================================================================= FUNCTIONS

    //  ================================================================================= USEEFFECTS

    useEffect(() => {
      if (item?.widgetsAllowed) {
        setFormValues(item);
      }
    }, [item]);

    useEffect(() => {
      if (data) {
        setUpdateCompanyApi(false);
        addNotification(
          t('NOTIFICATION.TOAST.editSuccess', [t('INVITATION.company')]),
          'success'
        );
        setTimeout(() => {
          closeModal();
        }, 1000);
      }
    }, [data]);

    useEffect(() => {
      if (error) {
        addNotification(t('NOTIFICATION.TOAST.generalError'), 'error');
      }
    }, [error]);

    //  ================================================================================= RENDER

    return (
      <Container sx={{ margin: '40px', width: 'auto' }}>
        <Modal
          open
          onClose={() => {
            setShowModal(false);
          }}
        >
          <ModalContainer>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                gap: 2,
                width: '100%'
              }}
            >
              <Typography variant="title">
                {t('COMPANY_SETTINGS_MODAL.title')}
              </Typography>
              <Typography
                color="secondary.main"
                textAlign="left"
                variant="body_200"
              >
                {t('COMPANY_SETTINGS_MODAL.description')}
              </Typography>
            </Box>
            <Box
              className="scrollbar"
              sx={{
                display: 'flex',
                flexDirection: 'column',
                gap: 1,
                width: '100%'
              }}
            >
              <Box width="100%">
                <FormLabel
                  error={false}
                  label={t('COMPANY_SETTINGS_MODAL.widgetsAllowed')}
                />
                <RegularInput
                  customStyle={{ width: '100%' }}
                  onChange={(widgetsAllowed: string) => {
                    setFormValues({
                      ...formValues,
                      widgetsAllowed: Number(widgetsAllowed)
                    });
                  }}
                  type="number"
                  value={formValues.widgetsAllowed.toString()}
                />
              </Box>
              <Box width="100%">
                <FormLabel
                  error={false}
                  label={t('COMPANY_SETTINGS_MODAL.plan')}
                />
                <SelectInput
                  onChange={(event: any) => {
                    setFormValues({ ...formValues, plan: event.target.value });
                  }}
                  value={formValues.plan}
                >
                  {planOptions.map((item: any) => {
                    return (
                      <MenuItem key={item} value={item}>
                        <Typography variant="primary">{item}</Typography>
                      </MenuItem>
                    );
                  })}
                </SelectInput>
              </Box>
              <ButtonContainerModal>
                <>
                  <DrawerButton
                    onClick={() => {
                      setShowModal(false);
                    }}
                    sx={{ borderColor: 'primary.main', height: 46, width: 114 }}
                    variant="outlined"
                  >
                    <Typography color="primary.main">
                      {t('MODAL.cancel')}
                    </Typography>
                  </DrawerButton>
                  <DrawerButton
                    color="primary"
                    disabled={updateCompanyApi}
                    onClick={() => {
                      setUpdateCompanyApi(true);
                    }}
                    sx={{ height: 46, width: 114 }}
                    variant="contained"
                  >
                    <Typography>{t('GENERAL.confirmButton')}</Typography>
                  </DrawerButton>
                </>
              </ButtonContainerModal>
            </Box>
          </ModalContainer>
        </Modal>
      </Container>
    );
  };

export default CompanyConfigurationsModal;
