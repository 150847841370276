/* eslint-disable no-nested-ternary */
/* eslint-disable react/require-default-props */
import React, { useEffect, useState } from 'react';
import dayjs from 'dayjs';
import {
  Box,
  Button,
  Checkbox,
  CheckboxProps,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  styled,
  Typography,
  CircularProgress,
  BoxProps,
  Grid,
  useTheme
} from '@mui/material';
import useSWR from 'swr';
import { useTranslation } from 'react-i18next';
import CloseIcon from '@mui/icons-material/Close';
import Api from '../../../../api';
import { isFutureDate, getTimeDifference } from '../../../../utils/general';
import useNotificationsStore from '../../../../store/zustand/notifications';

interface CheckboxItemProps extends CheckboxProps {
  isSelected?: boolean;
}

const CheckboxItem = styled(Checkbox)<CheckboxItemProps>(({ isSelected }) => ({
  '& .MuiSvgIcon-root': {
    fontSize: 16
  },
  '&.Mui-checked': {
    color: 'informative.main'
  },
  '&.Mui-checked.Mui-disabled': {
    color: `${'secondary.400'} !important`,
    cursor: 'not-allowed !important'
  },
  '&.Mui-disabled': {
    color: 'secondary.400',
    cursor: 'not-allowed'
  },
  color: 'informative.main',
  height: 16,
  width: 16
}));

const CheckBoxText = styled(Typography)(() => ({
  color: 'secondary.400',
  fontFamily: 'Lexend',
  fontSize: '14px',
  fontWeight: '400',
  lineHeight: '20px'
}));

interface ListItemProps extends BoxProps {
  disabled?: boolean;
  isSelected?: boolean;
}

const ListItem = styled(Box)<ListItemProps>(
  ({ theme, isSelected, disabled }) => ({
    alignItems: 'center',
    backgroundColor: disabled
      ? theme.palette.background.secondPaper
      : isSelected
      ? theme.palette.background.secondPaper
      : 'transparent',
    border: `1px solid ${theme.palette.secondary[100]}`,
    borderRadius: '8px',
    borderWidth: '1px',
    display: 'flex',
    justifyContent: 'space-between',
    minHeight: '52px',
    padding: '16px',
    width: '100%'
  })
);

export interface Closer {
  email: string;
  firstName: string;
  id: string;
  lastName: string;
  phoneNumber: string;
}
export interface CalendarEventIteration {
  closedBy?: string;
  closer?: Closer;
  endTime?: string;
  id?: number;
  startTime?: string;
  startedBy?: string;
  starter?: Closer;
  technician?: Closer;
}

export interface Technician {
  calendarEventIteration?: CalendarEventIteration;
  email: string;
  firstName: string;
  id: string;
  lastName: string;
  phoneNumber: string;
}

type CompleteVisitModalProps = {
  daySelected?: string;
  handleClose: () => void;
  handleCompleteTask: () => void;
  loading: boolean;
  modalDescription: string;
  modalTitle: string;
  open: boolean;
  projectName?: string;
  selectedTechnicians: string[];
  setSelectedTechnicians: React.Dispatch<React.SetStateAction<string[]>>;
  tasksAmount?: number;
  technicians: Technician[];
};

const CompleteVisitModal: React.FC<CompleteVisitModalProps> =
  function CompleteVisitModal({
    daySelected,
    handleClose,
    handleCompleteTask,
    loading,
    modalDescription,
    modalTitle,
    open,
    projectName = '',
    selectedTechnicians,
    setSelectedTechnicians,
    tasksAmount = 0,
    technicians
  }: CompleteVisitModalProps) {
    const { t } = useTranslation();
    const theme = useTheme();

    const handleSelectAll = (event: React.ChangeEvent<HTMLInputElement>) => {
      if (event.target.checked) {
        // Select only technicians without 'calendarEventIteration' or no closer
        setSelectedTechnicians(
          technicians
            .filter(
              (tech) =>
                !tech.calendarEventIteration ||
                (tech.calendarEventIteration &&
                  !tech.calendarEventIteration.closer &&
                  !tech.calendarEventIteration.closedBy)
            )
            .map((t) => t.id)
        );
      } else {
        setSelectedTechnicians([]);
      }
    };

    return (
      <Dialog
        fullWidth
        PaperProps={{
          sx: {
            borderRadius: '8px',
            display: 'flex',
            flexDirection: 'column',
            gap: 3,
            height: '85vh',
            maxHeight: '90vh',
            padding: '24px 40px 24px 40px'
          }
        }}
        maxWidth="lg"
        onClick={(e) => {
          e.stopPropagation();
        }}
        onClose={handleClose}
        open={open}
      >
        <DialogTitle sx={{ position: 'relative', textAlign: 'center' }}>
          <Typography
            color="primary.200"
            lineHeight="22px"
            variant="heading_400"
          >
            {modalTitle}
          </Typography>

          <Box
            onClick={handleClose}
            sx={{
              color: 'secondary.400',
              cursor: 'pointer',
              position: 'absolute',
              right: 10,
              top: 12
            }}
          >
            <CloseIcon />
          </Box>
        </DialogTitle>

        <DialogContent
          className="scrollbar"
          sx={{
            textAlign: 'center'
          }}
        >
          <Box display="flex" flexDirection="column" gap={3}>
            <Typography color="secondary.400" variant="body_300">
              {modalDescription}
            </Typography>

            <Box
              display="flex"
              gap={5}
              justifyContent="space-between"
              sx={{
                backgroundColor: theme.palette.background.default,
                border: `1px solid ${theme.palette.secondary[600]}`,
                borderRadius: '8px',
                height: '52px',
                padding: '16px'
              }}
            >
              <Typography color="primary.200" variant="body_200">
                {projectName}
              </Typography>
              <Typography color="primary.200" variant="body_200">
                {daySelected
                  ? dayjs(daySelected, 'D/M/YYYY').format('MM/DD/YY')
                  : ''}
              </Typography>
              <Typography color="primary.200" variant="body_200">
                {`${t('PROJECT.SERVICEMODAL.tasks')}: ${tasksAmount}`}
              </Typography>
              <Typography color="primary.200" variant="body_200">
                {`${t('PROJECT.SERVICEMODAL.technicians')}: ${
                  technicians?.length
                }`}
              </Typography>
              <Box alignItems="center" display="flex" gap="4px">
                <CheckboxItem
                  checked={
                    selectedTechnicians.length ===
                    technicians?.filter(
                      (tech) => !tech.calendarEventIteration?.closedBy
                    ).length
                  }
                  indeterminate={
                    selectedTechnicians.length > 0 &&
                    selectedTechnicians.length <
                      technicians.filter(
                        (tech) => !tech.calendarEventIteration?.closedBy
                      ).length
                  }
                  onChange={handleSelectAll}
                />
                <Typography color="primary.200" variant="body_200">
                  {t('PROJECT.SERVICEMODAL.selectAllTechnicians')}
                </Typography>
              </Box>
            </Box>

            {/* Technician List */}
            <Grid container spacing={2}>
              {technicians?.map(
                ({ firstName, id, lastName, calendarEventIteration }) => {
                  const isDisabled = !!calendarEventIteration?.closedBy;
                  const isChecked =
                    selectedTechnicians.includes(id) || isDisabled;

                  return (
                    <Grid item key={id} sm={6} xs={12}>
                      <ListItem disabled={isDisabled} isSelected={isChecked}>
                        <Box alignItems="center" display="flex" gap={1}>
                          <CheckboxItem
                            checked={isChecked}
                            disabled={isDisabled}
                            id={`checkbox-${id}`}
                            isSelected={isChecked}
                            onChange={(
                              e: React.ChangeEvent<HTMLInputElement>
                            ) => {
                              const { checked } = e.target;
                              setSelectedTechnicians((prev) =>
                                checked
                                  ? [...prev, id]
                                  : prev.filter(
                                      (technicianId) => technicianId !== id
                                    )
                              );
                            }}
                          />
                          <label
                            htmlFor={`checkbox-${id}`}
                            style={{
                              color: isDisabled ? 'secondary.400' : 'inherit',
                              cursor: isDisabled ? 'not-allowed' : 'pointer'
                            }}
                          >
                            <CheckBoxText>{`${firstName} ${lastName}`}</CheckBoxText>
                          </label>
                        </Box>

                        <Box display="flex" gap={2}>
                          <Box display="flex" gap={0.5}>
                            <Typography
                              color="secondary.400"
                              fontSize="14px"
                              variant="secondary"
                            >
                              {t('PROJECT.SERVICEMODAL.totalTime')}:
                            </Typography>
                            <Typography
                              color="secondary.400"
                              fontSize="14px"
                              variant="secondary"
                            >
                              {getTimeDifference(
                                calendarEventIteration?.startTime,
                                calendarEventIteration?.endTime
                              )}
                            </Typography>
                          </Box>
                          {isDisabled && (
                            <Box
                              alignItems="center"
                              display="flex"
                              padding="2px 8px 2px 8px"
                              sx={{
                                backgroundColor: theme.palette.success[400],
                                borderRadius: '6px',
                                height: '22px'
                              }}
                            >
                              <Typography
                                color={theme.palette.background.secondPaper}
                                lineHeight="18px"
                                variant="body_100"
                              >
                                {t('PROJECT.SERVICEMODAL.visitCompleted')}
                              </Typography>
                            </Box>
                          )}
                        </Box>
                      </ListItem>
                    </Grid>
                  );
                }
              )}
            </Grid>
          </Box>
        </DialogContent>

        <DialogActions sx={{ gap: 3, justifyContent: 'center' }}>
          <Button
            onClick={handleClose}
            sx={{
              padding: '9px',
              width: '50%'
            }}
            variant="cancel"
          >
            {t('GENERAL.cancelButton')}
          </Button>
          <Button
            disabled={loading || selectedTechnicians.length === 0}
            onClick={handleCompleteTask}
            sx={{ padding: '9px', width: '50%' }}
            variant="contained"
          >
            <Box alignItems="center" display="flex" gap="10px">
              <CircularProgress
                size={14}
                sx={{ display: loading ? 'block' : 'none' }}
              />
              {t('GENERAL.confirmButton')}
            </Box>
          </Button>
        </DialogActions>
      </Dialog>
    );
  };

type ServiceButtonProps = {
  calendarEventId: string;
  calendarView?: boolean;
  daySelected?: string;
  modalDescription: string;
  modalTitle: string;
  mutate?: () => void;
  projectName?: string;
  state?: string;
  tasksAmount?: number;
  technicians?: Technician[];
};

const CompleteServiceButton: React.FC<ServiceButtonProps> =
  function CompleteServiceButton({
    calendarEventId,
    calendarView = false,
    modalDescription,
    modalTitle,
    mutate,
    projectName,
    state = '',
    tasksAmount,
    technicians,
    daySelected
  }: ServiceButtonProps) {
    const { t } = useTranslation();
    const { addNotification } = useNotificationsStore((state) => state);
    const [open, setOpen] = useState(false);
    const [visitCompleted, setVisitCompleted] = useState(false);
    const [selectedTechnicians, setSelectedTechnicians] = useState<string[]>(
      []
    );

    const [completeApi, setCompleteApi] = useState<boolean>(false);

    const { data: completeData, error: completeError } = useSWR(
      completeApi
        ? { id: calendarEventId, technicianIds: selectedTechnicians }
        : null,
      Api.CalendarApi.patchCalendarEventsCloseCalendarEvent
    );

    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    useEffect(() => {
      setVisitCompleted(false);
      setSelectedTechnicians([]);
    }, [daySelected]);

    useEffect(() => {
      if (completeData) {
        setCompleteApi(false);
        setVisitCompleted(true);
        if (mutate) {
          mutate();
        }
        handleClose(); // Close modal after visit completion
        addNotification(t('PROJECT.TOAST.VISIT.SUCCESS'), 'success');
      }
    }, [completeData]);

    useEffect(() => {
      if (completeError) {
        setCompleteApi(false);
        setVisitCompleted(false);
        addNotification(t('PROJECT.TOAST.VISIT.FAIL'), 'error');
      }
    }, [completeError]);

    // This button is enabled when a team has been assigned, a day has been selected (not in the future),
    // and the visit has not yet been completed.
    const isbuttonEnabled =
      technicians &&
      technicians?.length > 0 &&
      state !== 'Completed' &&
      !!daySelected &&
      !isFutureDate(daySelected);

    return (
      <Box
        sx={{
          width: '100%'
        }}
      >
        {/* Button to Open Modal */}
        <Button
          color="primary"
          disabled={!isbuttonEnabled || visitCompleted}
          onClick={(e) => {
            e.stopPropagation();
            handleOpen();
          }}
          sx={{ width: calendarView ? '100%' : '120px' }}
          variant="primary-text"
        >
          <Box alignItems="center" display="flex" gap="10px">
            <CircularProgress
              size={14}
              sx={{ display: completeApi ? 'block' : 'none' }}
            />
            <Typography
              fontSize={calendarView ? '16px' : '12px'}
              variant="heading_328"
            >
              {t('PROJECT.SERVICEMODAL.completeVisitButton')}
            </Typography>
          </Box>
        </Button>

        {isbuttonEnabled && (
          <CompleteVisitModal
            daySelected={daySelected}
            handleClose={handleClose}
            handleCompleteTask={() => setCompleteApi(true)}
            loading={completeApi}
            modalDescription={modalDescription}
            modalTitle={modalTitle}
            open={open}
            projectName={projectName}
            selectedTechnicians={selectedTechnicians}
            setSelectedTechnicians={setSelectedTechnicians}
            tasksAmount={tasksAmount}
            technicians={technicians}
          />
        )}
      </Box>
    );
  };

export default CompleteServiceButton;
