import {
  Box,
  BoxProps,
  Button,
  styled,
  Typography,
  useTheme
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import useSWR from 'swr';
import api from '../../../../api';
import useAuthStore from '../../../../store/zustand/auth';

const FlexBox = styled(Box)<BoxProps>(() => ({
  display: 'flex'
}));

interface SuccessProps {
  onStart: () => void;
}

function Success({ onStart }: SuccessProps) {
  // Hooks
  const { t: translate } = useTranslation('translation', {
    // Feel free to create additional hooks if you need translations from a different keyPrefix...
    keyPrefix: 'CLIENT_ONBOARDING'
  });
  const [getProfile, setGetProfile] = useState<boolean>(false);
  const userState = useAuthStore((state) => state);
  const theme = useTheme();
  const navigate = useNavigate();

  const { data: profile, mutate } = useSWR(
    getProfile ? '/clients-portal/information' : null,
    api.ClientPageApi.getClientInformation
  );

  useEffect(() => {
    if (profile) {
      setGetProfile(false);
      if (profile.onboardingComplete) {
        const { email, firstName, lastName, phone, id } = profile;
        userState.setUser({
          active: true,
          block: false,
          email,
          firstName,
          id,
          lastName,
          mfa: false,
          phoneNumber: phone,
          roles: ['Client']
        });
        userState.login();
        navigate('/customer/landing');
      } else {
        navigate('/customer/onboarding', { state: profile });
      }
    }
  }, [profile]);

  return (
    <FlexBox alignItems="center" flexDirection="column" gap={theme.spacing(6)}>
      <Typography
        color="primary.400"
        sx={{ fontSize: '28px', lineHeight: '30px' }}
        textAlign="center"
        variant="primary-bold"
        whiteSpace="break-spaces"
      >
        {translate('successTitle')}
      </Typography>
      <Typography textAlign="center">
        {translate('successDescription')}
      </Typography>
      <Button
        onClick={() => setGetProfile(true)}
        sx={{ width: '350px' }}
        type="button"
        variant="contained"
      >
        {translate('successButton')}
      </Button>
    </FlexBox>
  );
}

export default Success;
