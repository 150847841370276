/* eslint-disable react/require-default-props */
import React, { useEffect, useRef, useState } from 'react';
import '../../style/App.scss';
import {
  Container,
  Typography,
  Box,
  Button,
  Modal,
  FormControlLabel,
  Checkbox,
  IconButton,
  MenuItem,
  Select,
  FormControl,
  InputLabel,
  Tooltip,
  MobileStepper,
  useTheme
} from '@mui/material';
import { cloneDeep } from 'lodash';
import { isMobile } from 'react-device-detect';
import { useLocation, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import useSWR from 'swr';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import Chart from 'react-apexcharts';
import { width } from '@mui/system';
import { KeyboardArrowLeft, KeyboardArrowRight } from '@mui/icons-material';
import DomainIcon from '@mui/icons-material/Domain';
import PersonOutlineIcon from '@mui/icons-material/PersonOutline';
import EditIcon from '@mui/icons-material/EditOutlined';
import StarIcon from '@mui/icons-material/GradeOutlined';
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined';
import CorporateFareOutlinedIcon from '@mui/icons-material/CorporateFareOutlined';
import PlaceOutlinedIcon from '@mui/icons-material/PlaceOutlined';
import PaymentsOutlinedIcon from '@mui/icons-material/PaymentsOutlined';
import LocalPhoneOutlinedIcon from '@mui/icons-material/LocalPhoneOutlined';
import api from '../../api';
import RegularInput from '../input/RegularInput';
import {
  ACCOUNT_B2B_FIELDS,
  ACCOUNT_INFO_FIELDS
} from '../../helpers/client-data';
import ButtonFormGroup from '../form/ButtonFormGroup';
import SortableTable from '../list/sortableTable';
import AutoCompletePlaces from '../input/AutoCompletePlaces';
import {
  FlexBoxAutoComplete,
  FlexContainer,
  FormLabel,
  GridBoxInput,
  GridContainer,
  WhiteBoxContainer,
  HeaderFormContainer,
  TitleFormBox
} from '../form/parts';
import ViewLoader from '../shared/ViewLoader';
import EditContent from '../form/EditContent';
import {
  cleanPhoneNumber,
  getLocationTimeZone,
  statusStyle
} from '../../utils/general';
import {
  calculateCanDelete,
  customerRelationship,
  customerType
} from '../../utils/customerHelper';
import SelectInput from '../form/SelectInput';
import { CreateClientDTORelationshipEnum } from '../../api-client/generated';
import WatchersModal from '../modals/WatchersModal';
import CustomerWatcherModal from '../modals/CustomerWatcherModal';
import DeleteButton from '../list/DeleteButton';
import { colorsParser } from '../../utils/widgetHelper';
import ColumnChart from '../widget/ColumnChart';
import { RowContainer } from '../../views/dashboard/Dashboard';
import useUserRole from '../../hooks/useUserRoles';
import { ROLES } from '../../helpers/roles';
import useNotificationsStore from '../../store/zustand/notifications';

const style = {
  bgcolor: 'background.paper',
  borderRadius: '0.5em',
  boxShadow: '24',
  left: '50%',
  padding: isMobile ? '40px 30px' : '40px 40px',
  position: 'absolute' as 'absolute',
  top: '50%',
  transform: 'translate(-50%, -50%)',
  width: isMobile ? '90%' : 350
};

const styleModalInfo = {
  bgcolor: 'background.paper',
  borderRadius: '0.5em',
  boxShadow: '24',
  left: '50%',
  padding: isMobile ? '40px 30px' : '40px 40px',
  position: 'absolute' as 'absolute',
  top: '50%',
  transform: 'translate(-50%, -50%)',
  width: isMobile ? '90%' : 800
};
interface clientInterface {
  clientId?: string;
  isClient?: boolean;
  isNew?: boolean;
  type: 'summary' | 'form';
}

type Mode = 'form' | 'summary';

const clientStyle = {
  alignItems: 'flex-start !important',
  display: 'flex',
  flexDirection: 'row !important',
  flexWrap: 'wrap',
  gap: '24px',
  justifyContent: 'flex-start !important',
  marginBottom: '20px',
  width: '100% !important'
};

export const b2bSteps = [
  'businessType',
  'companyInfo',
  'b2bMainContact',
  'communicationMethods',
  'billingPreferences',
  'serviceAddress',
  'pointOfContact'
];
export const b2cSteps = [
  'businessType',
  'b2cMainContact',
  'communicationMethods',
  'billingPreferences',
  'serviceAddress',
  'pointOfContact'
];

const ClientForm: React.FC<clientInterface> = function ClientForm({
  type,
  clientId,
  isClient = false,
  isNew
}) {
  // ============================================================================= CONSTANTS
  const { t } = useTranslation();
  const navigate = useNavigate();
  const defaultFormValue = {
    addresses: [],
    billingAddress: '',
    billingEmail: [],
    businessName: '',
    contacts: [],
    email: '',
    firstName: '',
    jobTitle: '',
    lastName: '',
    notes: '',
    phone: '',
    timeZone: '',
    workOrderEmails: []
  };
  const isOperator = useUserRole([ROLES.OPERATIONS]);
  const theme = useTheme();
  const { addNotification } = useNotificationsStore((state) => state);

  // ============================================================================= STATES

  const [mode, setMode] = useState<Mode>(type);
  const [step, setStep] = useState<number>(0);

  const { state: params } = useLocation();
  const [showWatchers, setShowWatchers] = useState<boolean>(false);
  const [updateFlag, setUpdateFlag] = useState<boolean>(mode === 'form');
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [billingAddres, setBillingAddres] = useState<any>(null);
  const [error, setError] = useState<boolean>(false);
  const [addressDeleteId, setAddressDeleteId] = useState<string>('');
  const [contactDeleteId, setContactDeleteId] = useState<string>('');
  const [clientCreated, setClientCreated] = useState<any>();
  const [fireDeleteContactApi, setFireDeleteContactApi] =
    useState<boolean>(false);
  const [fireDeleteAddressApi, setFireDeleteAddressApi] =
    useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string[]>([
    'Something go wrong'
  ]);
  const [errorFields, setErrorFields] = useState<string[]>([]);
  const [formFunction, setFormFunction] = useState<string>('');
  const [errorMsgs, setErrorMsgs] = useState<string[]>(['']);
  const [openInfoModal, setOpenInfoModal] = useState(false);
  const [nonZeroValue, setNonZeroValue] = useState<string[]>([]);
  const [chartData, setChartData] = useState<any>();
  const [chartTitle, setChartTitle] = useState<string>();
  const reference = useRef(0);
  //  ---------------- API Section ----------------
  const [fireAPICreateClient, setFireAPICreateClient] =
    useState<boolean>(false);
  const [fireAPIGetClient, setFireAPIGetClient] = useState<boolean>(false);
  const [fireGetClientInformation, setFireGetClientInformation] =
    useState<boolean>(false);
  const [updateClientInformation, setUpdateClientInformation] =
    useState<boolean>(false);
  const [fireAPIUpdateClient, setFireAPIUpdateClient] =
    useState<boolean>(false);
  const [selectedAddress, setSelectedAddress] = useState<boolean>(false);
  const [copybillingAddress, setCopybillingAddress] = useState<boolean>(false);
  const [timeZoneBillingAddres, setTimeZoneBillingAddres] = useState<any>(null);
  const [formValue, setFormValue] = useState<any>(defaultFormValue);

  //  ---------------- API Request ----------------
  const { data: dataCreatedClient, error: createdClientError } = useSWR(
    fireAPICreateClient ? formValue : null,
    api.ClientApi.createClient
  );

  const {
    data: dataGetClient,
    error: getClientError,
    mutate: mutateCustomer
  } = useSWR(fireAPIGetClient ? clientId : null, api.ClientApi.getClient);

  const { data: dataUpdateClient, error: updateClientError } = useSWR(
    fireAPIUpdateClient ? formValue : null,
    api.ClientApi.updateClient
  );

  const {
    data: deleteAddress,
    isValidating: isValidatingDeleteAddress,
    error: deleteAddressError
  } = useSWR(
    fireDeleteAddressApi
      ? {
          clientId: formValue.id,
          deleteAddressBodyDTO: { ids: [addressDeleteId] }
        }
      : null,
    api.ClientApi.deleteAddress
  );

  const {
    data: deleteContact,
    isValidating: isValidatingDeleteContact,
    error: deleteContactError
  } = useSWR(
    fireDeleteContactApi
      ? {
          clientId: formValue.id,
          deleteContactBodyDTO: { ids: [contactDeleteId] }
        }
      : null,
    api.ClientApi.deleteContact
  );

  const { data: dataInformation } = useSWR(
    fireGetClientInformation ? 'clients-portal/information' : null,
    api.ClientPageApi.getClientInformation
  );

  const { data: dataUpdateInformation } = useSWR(
    updateClientInformation ? formValue : null,
    api.ClientPageApi.onboardingClient
  );

  //  ---------------- Functions ----------------

  const onChange = (name: string, value: string | any[]) => {
    setFormValue({
      ...formValue,
      [name]: value
    });
  };

  const setTimeZone = () => {
    setFormValue({
      ...formValue,
      timeZone: dayjs.utc().format().toString()
    });
  };

  const checkErrorFields = () => {
    const inputs = document.querySelectorAll('input');
    const auxFormValue = formValue;

    inputs.forEach((input) => {
      const auxArrayName = input.name.split('-');

      if (auxArrayName[1] && input.value !== '') {
        if (auxArrayName[0] === 'address') {
          auxFormValue.addresses[auxArrayName[1]] = {
            ...auxFormValue.addresses[auxArrayName[1]],
            address: input.value
          };

          const auxAddress = formValue.addresses;

          auxAddress[auxArrayName[1]] = {
            ...auxAddress[auxArrayName[1]],
            address: input.value
          };

          setFormValue({
            ...formValue,
            addresses: auxAddress
          });
        } else if (auxArrayName[0] === 'phone') {
          auxFormValue.addresses[auxArrayName[1]] = {
            ...auxFormValue.addresses[auxArrayName[1]],
            phone: cleanPhoneNumber(input.value)
          };
          const auxAddress = formValue.addresses;
          auxAddress[auxArrayName[1]] = {
            ...auxAddress[auxArrayName[1]],
            phone: cleanPhoneNumber(input.value)
          };
          setFormValue({
            ...formValue,
            addresses: auxAddress
          });
        } else if (auxArrayName[0] === 'contactsphoneNumber') {
          auxFormValue.contacts[auxArrayName[1]] = {
            ...auxFormValue.contacts[auxArrayName[1]],
            phoneNumber: cleanPhoneNumber(input.value)
          };

          const auxContacts = formValue.contacts;

          auxContacts[auxArrayName[1]] = {
            ...auxContacts[auxArrayName[1]],
            phoneNumber: cleanPhoneNumber(input.value)
          };

          setFormValue({
            ...formValue,
            contacts: auxContacts
          });
        } else if (auxArrayName[0] === 'name') {
          auxFormValue.addresses[auxArrayName[1]] = {
            ...auxFormValue.addresses[auxArrayName[1]],
            name: input.value
          };

          const auxAddress = formValue.addresses;

          auxAddress[auxArrayName[1]] = {
            ...auxAddress[auxArrayName[1]],
            name: input.value
          };

          setFormValue({
            ...formValue,
            addresses: auxAddress
          });
        } else if (auxArrayName[0] === 'contactsname') {
          auxFormValue.contacts[auxArrayName[1]] = {
            ...auxFormValue.contacts[auxArrayName[1]],
            name: input.value
          };

          const auxContacts = formValue.contacts;

          auxContacts[auxArrayName[1]] = {
            ...auxContacts[auxArrayName[1]],
            name: input.value
          };

          setFormValue({
            ...formValue,
            contacts: auxContacts
          });
        }
      } else if (
        undefined !== auxFormValue[input?.name] &&
        formValue[input?.name] === '' &&
        input.value !== '' &&
        input.name !== ''
      ) {
        let auxValue = input.value;

        if (input.name === 'phone') auxValue = cleanPhoneNumber(input.value);

        auxFormValue[input?.name] = auxValue;
        setFormValue({
          ...formValue,
          [input?.name]: auxValue
        });
      }
    });

    const temporalErrorArray: string[] = [];
    const labelErrorArray: string[] = [];
    ACCOUNT_INFO_FIELDS.forEach((value) => {
      const test = value;
      if (!test.pattern.test(auxFormValue[value?.name]) && test.required) {
        temporalErrorArray.push(value?.name);
        labelErrorArray.push(value?.validationLabel);
      }
    });
    auxFormValue.billingEmail.forEach((email: string) => {
      const test = ACCOUNT_INFO_FIELDS[2];
      if (!test.pattern.test(email) && test.required) {
        temporalErrorArray.push('billingEmail');
        labelErrorArray.push(ACCOUNT_INFO_FIELDS[2].validationLabel);
      }
    });
    auxFormValue.workOrderEmails.forEach((email: string) => {
      const test = ACCOUNT_INFO_FIELDS[2];
      if (!test.pattern.test(email) && test.required) {
        temporalErrorArray.push('workOrderEmails');
        labelErrorArray.push(ACCOUNT_INFO_FIELDS[2].validationLabel);
      }
    });
    // if (auxFormValue.billingAddress === '') {
    //   temporalErrorArray.push('billingAddress');
    //   labelErrorArray.push('Billing address is required');
    // } else if (selectedAddress === false && billingAddres) {
    //   temporalErrorArray.push('billingAddress');
    //   labelErrorArray.push(
    //     'Billing address must be selected from the list of options'
    //   );
    // }
    // if (formValue.notes === '') {
    //   temporalErrorArray.push('notes');
    //   labelErrorArray.push('(*) is required');
    // }
    // if (
    //   auxFormValue.billingEmail.length === 0 ||
    //   auxFormValue.billingEmail[0] === ''
    // ) {
    //   temporalErrorArray.push('billingEmail');
    //   labelErrorArray.push(
    //     'Billing email should have the format example@mail.com'
    //   );
    // }
    // if (
    //   auxFormValue?.workOrderEmails.length === 0 ||
    //   auxFormValue?.workOrderEmails[0] === ''
    // ) {
    //   temporalErrorArray.push('workOrderEmails');
    //   labelErrorArray.push(
    //     'Work order email should have the format example@mail.com'
    //   );
    // }
    // if (auxFormValue.addresses.length === 0) {
    //   temporalErrorArray.push('placeValue');
    //   labelErrorArray.push('(*) is required');
    // }
    // auxFormValue.addresses.forEach((value: any) => {
    //   if (value?.phone === '' || !value?.phone) {
    //     temporalErrorArray.push('placeValue');
    //     labelErrorArray.push(ACCOUNT_INFO_FIELDS[3].validationLabel);
    //   }
    //   if (value?.name === '' || !value?.name) {
    //     temporalErrorArray.push('placeValue');
    //     labelErrorArray.push(ACCOUNT_INFO_FIELDS[0].validationLabel);
    //   }
    //   if (value?.address === '' || !value?.address) {
    //     temporalErrorArray.push('placeValue');
    //     labelErrorArray.push('address is required');
    //   }
    // });
    // if (auxFormValue.contacts.length === 0) {
    //   temporalErrorArray.push('contacts');
    //   labelErrorArray.push('(*) is required');
    // }
    // auxFormValue.contacts.forEach((value: any) => {
    //   if (value?.phoneNumber === '') {
    //     temporalErrorArray.push('placeValue');
    //     labelErrorArray.push(ACCOUNT_INFO_FIELDS[3].validationLabel);
    //   }
    //   if (value?.name === '') {
    //     temporalErrorArray.push('placeValue');
    //     labelErrorArray.push(ACCOUNT_INFO_FIELDS[0].validationLabel);
    //   }
    // });
    setErrorFields(temporalErrorArray);
    // Remove duplicated error values.
    const newLabelErrorArray = labelErrorArray.filter((ele, index) => {
      return labelErrorArray.indexOf(ele) === index;
    });
    setErrorMsgs(newLabelErrorArray);
    return newLabelErrorArray;
  };
  const handleCheckboxChange = (event: any) => {
    setCopybillingAddress(event.target.checked);
  };

  const handleSubmit = () => {
    const labelError = checkErrorFields();
    // Check if ErrorArray is empty
    if (labelError.length === 0) {
      if (formFunction === 'add') {
        setTimeZone();
        setFireAPICreateClient(true);
      } else if (isClient) {
        setUpdateClientInformation(true);
      } else if (clientId) {
        setFireAPIUpdateClient(true);
      } else {
        setFireAPICreateClient(true);
      }
    } else {
      setError(true);
    }
  };

  const getError = () =>
    !error ? (
      false
    ) : (
      <Container sx={{ mt: 1 }}>
        <Typography
          color="alert.main"
          sx={{ fontSize: 14 }}
          variant="primary-bold"
        >
          {errorMessage?.map((error, index) => (
            <span key={`error-${index + 1}`}>
              &bull; &nbsp;
              {error}
              <br />
            </span>
          ))}
        </Typography>
      </Container>
    );

  // ---------------- UseEffect ----------------

  useEffect(() => {
    if (dataCreatedClient) {
      setError(false);
      setFireAPICreateClient(false);
      setOpenModal(true);
      setIsLoading(false);
      setClientCreated(dataCreatedClient);
      addNotification(t('NOTIFICATION.TOAST.customerCreated'), 'success');
      navigate(`/app/crm/edit/${dataCreatedClient.id}`, {
        state: { showCreationSuccessfulToast: true }
      });
    }
  }, [dataCreatedClient]);

  useEffect(() => {
    if (dataUpdateClient) {
      setError(false);
      setFireAPIUpdateClient(false);
      setOpenModal(true);
      setIsLoading(false);
      addNotification(t('NOTIFICATION.TOAST.customerUpdated'), 'success');
    }
  }, [dataUpdateClient]);

  useEffect(() => {
    if (dataUpdateInformation) {
      setError(false);
      setUpdateClientInformation(false);
      setOpenModal(true);
      setIsLoading(false);
    }
  }, [dataUpdateInformation]);

  useEffect(() => {
    if (deleteAddress) {
      setError(false);
      setFireDeleteAddressApi(false);
      setOpenModal(true);
      setIsLoading(false);
    }
  }, [deleteAddress]);

  useEffect(() => {
    if (deleteContact) {
      setError(false);
      setFireDeleteContactApi(false);
      setOpenModal(true);
      setIsLoading(false);
    }
  }, [deleteContact]);

  useEffect(() => {
    if (dataGetClient) {
      const originalObject = dataGetClient;
      const clonedObject = cloneDeep(originalObject);
      const updatedAddresses = clonedObject.addresses?.map((address: any) => ({
        ...address,
        canDelete: calculateCanDelete(address.extraInformation)
      }));
      const updatedContacts = clonedObject.contacts?.map((contact: any) => ({
        ...contact,
        canDelete: calculateCanDelete(contact.extraInformation)
      }));
      setFormValue({
        ...clonedObject,
        addresses: updatedAddresses,
        contacts: updatedContacts
      });
      setIsLoading(false);
    } else {
      setIsLoading(true);
    }
  }, [dataGetClient]);

  useEffect(() => {
    if (dataInformation) {
      const originalObject = dataInformation;
      const clonedObject = cloneDeep(originalObject);
      setFormValue({ ...clonedObject });
      setIsLoading(false);
      setFireAPIGetClient(false);
    }
  }, [dataInformation]);

  // Control if Edit or Add view.
  useEffect(() => {
    // If add set timezone.
    window.localStorage.removeItem('client-process');
    setFormFunction(type);
    if (mode === 'form') {
      dayjs.extend(utc);
      setUpdateFlag(true);
      setIsLoading(false);
    } else if (isClient) {
      setFireGetClientInformation(true);
    } else {
      setFireAPIGetClient(true);
      setSelectedAddress(true);
    }
    return () => {
      mutateCustomer(undefined, true);
      setFormValue(defaultFormValue);
    };
  }, []);

  useEffect(() => {
    if (addressDeleteId) {
      setFireDeleteAddressApi(true);
    }
  }, [addressDeleteId]);

  useEffect(() => {
    if (contactDeleteId) {
      setFireDeleteContactApi(true);
    }
  }, [contactDeleteId]);

  // Prepopulate Billing email, just one time at begining,
  useEffect(() => {
    if (
      formValue.email !== '' &&
      reference.current === 0 &&
      formValue.billingEmail.length === 0 &&
      formValue.workOrderEmails.length === 0
    ) {
      setTimeout(() => {
        const emails = formValue.billingEmail;
        emails.push(formValue.email);
        setFormValue({
          ...formValue,
          billingEmail: emails,
          workOrderEmails: emails
        });
        reference.current = 1;
      }, 2000);
    }
  }, [formValue.email]);

  useEffect(() => {
    const newObject = JSON.parse(
      window.localStorage.getItem('client-process') as unknown as string
    );
    if (newObject) {
      switch (newObject.process) {
        case 'address':
          setFormFunction('edit');
          setFormValue({ ...formValue, id: newObject.response.idClient });
          break;
        case 'contacts':
          setFormFunction('edit');
          setFormValue({ ...formValue, id: newObject.response.idClient });
          break;
        default:
          break;
      }
    }
    if (createdClientError || updateClientError) {
      const error = [createdClientError, updateClientError].find(
        (param) => param !== undefined
      );
      const response = error?.response?.data?.message;
      const errors = error?.response?.data?.errors;
      const errorArray =
        typeof response === 'string'
          ? [response, errors]
          : response.push(errors);
      setErrorMessage(errorArray);
      setFireAPICreateClient(false);
      setFireDeleteContactApi(false);
      setFireDeleteAddressApi(false);
      setFireAPIUpdateClient(false);
      setOpenModal(true);
      setError(true);
      setIsLoading(false);
    }
    if (getClientError) {
      setFireAPIGetClient(false);
      setOpenModal(true);
      setError(true);
      setIsLoading(false);
    }
  }, [createdClientError, getClientError, updateClientError]);

  useEffect(() => {
    if (deleteAddressError || deleteContactError) {
      const error = [deleteAddressError, deleteContactError].find(
        (param) => param !== undefined && param !== null
      );
      const response = error?.response?.data?.message;
      const errorArray = [response];
      setErrorMessage(errorArray);
      setFireAPICreateClient(false);
      setFireDeleteContactApi(false);
      setFireDeleteAddressApi(false);
      setFireAPIUpdateClient(false);
      setOpenModal(true);
      setError(true);
      setFireGetClientInformation(false);
      setIsLoading(false);
    }
  }, [deleteAddressError, deleteContactError]);

  useEffect(() => {
    if (params?.showCreationSuccessfulToast) {
      addNotification(t('NOTIFICATION.TOAST.customerCreated'), 'success');
      params.showCreationSuccessfulToast = false;
    }
  }, []);

  useEffect(() => {
    if (mode === 'form') {
      setUpdateFlag(true);
    }
  }, [mode]);

  if (isLoading) {
    return <ViewLoader />;
  }

  // eslint-disable-next-line react/no-unstable-nested-components
  function Autocomplete() {
    return (
      <AutoCompletePlaces
        disabled={!updateFlag}
        error={errorFields.includes('billingAddress')}
        keyName="billingAddress"
        onChange={(values: any) => {
          const timezone = getLocationTimeZone(
            values.coords[1],
            values.coords[0]
          );
          setTimeZoneBillingAddres(timezone);
          onChange(values.keyName, values.value);
          setBillingAddres(values);
        }}
        setSelected={setSelectedAddress}
        value={formValue.billingAddress}
      />
    );
  }

  const handleInfoClick = (data: any, title: string) => {
    const extraData = data.extraInformation;
    setChartData(extraData);
    setChartTitle(title);

    setOpenInfoModal(true);
  };

  // ============================================================================= COMPONENTS

  const getStep = (stepName: string) => {
    const customStyles = {
      margin: '34px 0px',
      padding: 0,
      width: '100%'
    };

    switch (stepName) {
      case 'businessType':
        return (
          <WhiteBoxContainer customStyles={customStyles} isMobile={isMobile}>
            <Box
              sx={{
                alignItems: 'center',
                display: 'flex',
                gap: '24px',
                justifyContent: 'space-between',
                marginTop: '24px'
              }}
            >
              <Box
                onClick={() => {
                  setFormValue({
                    ...formValue,
                    relationship: CreateClientDTORelationshipEnum.B2B
                  });
                }}
                sx={{
                  alignItems: 'center',
                  background: '#F5F7FB',
                  border: `1px solid ${
                    formValue.relationship ===
                    CreateClientDTORelationshipEnum.B2B
                      ? '#59A9CF'
                      : '#E0E0E0'
                  }`,
                  borderRadius: '8px',
                  cursor: 'pointer',
                  display: 'flex',
                  gap: 4,
                  height: '224px',
                  justifyContent: 'center',
                  width: '580px'
                }}
              >
                <Box>
                  <DomainIcon
                    style={{ color: '#005DA2', height: 80, width: 80 }}
                  />
                </Box>
                <Box>
                  <Typography color="primary" variant="primary-bold">
                    {t('CUSTOMER.businessToBusinessTitle')}
                  </Typography>
                  <Typography color="secondary" variant="body2">
                    {t('CUSTOMER.businessToBusinessSubTitle')}
                  </Typography>
                </Box>
              </Box>
              <Box
                onClick={() => {
                  setFormValue({
                    ...formValue,
                    relationship: CreateClientDTORelationshipEnum.B2C
                  });
                }}
                sx={{
                  alignItems: 'center',
                  background: '#F5F7FB',
                  border: `1px solid ${
                    formValue.relationship ===
                    CreateClientDTORelationshipEnum.B2C
                      ? '#59A9CF'
                      : '#E0E0E0'
                  }`,
                  borderRadius: '8px',
                  cursor: 'pointer',
                  display: 'flex',
                  gap: 4,
                  height: '224px',
                  justifyContent: 'center',
                  width: '580px'
                }}
              >
                <Box>
                  <PersonOutlineIcon
                    style={{ color: '#005DA2', height: 80, width: 80 }}
                  />
                </Box>
                <Box>
                  <Typography color="primary" variant="primary-bold">
                    {t('CUSTOMER.businessToConsumerTitle')}
                  </Typography>
                  <Typography color="secondary" variant="body2">
                    {t('CUSTOMER.businessToConsumerSubTitle')}
                  </Typography>
                </Box>
              </Box>
            </Box>
          </WhiteBoxContainer>
        );
      case 'companyInfo':
        return (
          <WhiteBoxContainer customStyles={customStyles} isMobile={isMobile}>
            <GridContainer
              isMobile={isMobile}
              sx={{
                gap: 2,
                gridTemplateColumns: isMobile ? '100%' : '1fr 1fr',
                width: '100%'
              }}
            >
              {ACCOUNT_B2B_FIELDS.map((value) => (
                <GridBoxInput
                  isMobile={isMobile}
                  key={value?.name}
                  sx={{ width: '100%' }}
                >
                  <FormLabel
                    label={value?.required ? `${value?.label}*` : value?.label}
                  />
                  <RegularInput
                    customStyle={{
                      width: '100%'
                    }}
                    disabled={!updateFlag}
                    error={errorFields.includes(value?.name) && value?.required}
                    name={value?.name}
                    onChange={(inputValue) => onChange(value.name, inputValue)}
                    type={value?.type}
                    value={formValue[value?.name]}
                  />
                </GridBoxInput>
              ))}
            </GridContainer>
          </WhiteBoxContainer>
        );
      case 'b2bMainContact':
      case 'b2cMainContact':
        return (
          <WhiteBoxContainer customStyles={customStyles} isMobile={isMobile}>
            <GridContainer
              isMobile={isMobile}
              sx={{
                gap: 2,
                gridTemplateColumns: isMobile ? '100%' : '1fr 1fr',
                width: '100%'
              }}
            >
              {ACCOUNT_INFO_FIELDS.map((value) => (
                <GridBoxInput
                  isMobile={isMobile}
                  key={value?.name}
                  sx={{ width: '100%' }}
                >
                  <FormLabel
                    label={value?.required ? `${value?.label}*` : value?.label}
                  />
                  <RegularInput
                    customStyle={{
                      width: '100%'
                    }}
                    disabled={!updateFlag}
                    error={errorFields.includes(value?.name) && value?.required}
                    name={value?.name}
                    onChange={(inputValue) => onChange(value.name, inputValue)}
                    type={value?.type}
                    value={formValue[value?.name]}
                  />
                </GridBoxInput>
              ))}
            </GridContainer>
          </WhiteBoxContainer>
        );
      case 'communicationMethods':
        return (
          <WhiteBoxContainer customStyles={customStyles} isMobile={isMobile}>
            <Box>
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'left',
                  marginTop: '16px'
                }}
              >
                <SortableTable
                  addManagement
                  disableSort
                  bodyKeys={['type', 'value', 'name', 'isPrimary']}
                  disabledTable={!updateFlag}
                  handleDelete={(id: string) => {
                    setFormValue({
                      ...formValue,
                      communicationMethodsDeleted:
                        formValue?.communicationMethodsDeleted
                          ? [...formValue.communicationMethodsDeleted, id]
                          : [id]
                    });
                  }}
                  name="communicationMethods"
                  onChange={(communicationMethods: any) => {
                    setFormValue({
                      ...formValue,
                      communicationMethods: [...communicationMethods]
                    });
                  }}
                  type="communication-input"
                  values={formValue?.communicationMethods || []}
                />
              </Box>
            </Box>
          </WhiteBoxContainer>
        );
      case 'billingPreferences':
        return (
          <WhiteBoxContainer customStyles={customStyles} isMobile={isMobile}>
            <FlexContainer>
              {/* ---- Billing Address ---- */}
              <Box mt={1}>
                <FormLabel label={`${t('CUSTOMER.billingAddress')}*`} />
                <FlexBoxAutoComplete>{Autocomplete()}</FlexBoxAutoComplete>
              </Box>

              {formFunction === 'add' ? (
                <FormControlLabel
                  value
                  checked={copybillingAddress ? selectedAddress : false}
                  control={<Checkbox />}
                  disabled={!selectedAddress}
                  label={
                    <Typography fontSize={12} variant="primary-bold">
                      {t('MODAL.usePrimaryServicesAddress')}
                    </Typography>
                  }
                  onChange={handleCheckboxChange}
                  style={{
                    marginTop: '-21px'
                  }}
                />
              ) : null}

              {/* ---- Valid work order emails ---- */}
              <Box
                sx={{
                  columnGap: '24px',
                  display: 'grid',
                  gridTemplateColumns: isMobile ? '100%' : '50% 50%',
                  marginY: '8px',
                  width: '100%'
                }}
              >
                <Box>
                  <FormLabel label={`${t('CUSTOMER.validWorkOrderLabel')}*`} />
                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      justifyContent: 'left',
                      marginTop: '16px',
                      width: '90%'
                    }}
                  >
                    <SortableTable
                      addManagement
                      disableSort
                      bodyKeys={['validEmails']}
                      disabledTable={!updateFlag}
                      name="workOrderEmails"
                      onChange={(workOrderEmails: any) => {
                        setFormValue({
                          ...formValue,
                          workOrderEmails: [...workOrderEmails]
                        });
                      }}
                      type="input"
                      values={formValue.workOrderEmails}
                    />
                  </Box>
                </Box>

                {/* ---- Billing Email ---- */}
                <Box>
                  <FormLabel label={`${t('CUSTOMER.billingEmail')}*`} />
                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      justifyContent: 'left',
                      marginTop: '16px',
                      width: '90%'
                    }}
                  >
                    <SortableTable
                      addManagement
                      disableSort
                      bodyKeys={['email']}
                      disabledTable={!updateFlag}
                      name="billingEmail"
                      onChange={(newEmail: any) => {
                        setFormValue({
                          ...formValue,
                          billingEmail: [...newEmail]
                        });
                      }}
                      type="input"
                      values={formValue.billingEmail}
                    />
                  </Box>
                </Box>
              </Box>
            </FlexContainer>
          </WhiteBoxContainer>
        );
      case 'serviceAddress':
        return (
          <WhiteBoxContainer customStyles={customStyles} isMobile={isMobile}>
            <FlexContainer>
              {/* ---- Client Addresses ---- */}
              <Box sx={{ marginTop: '8px' }}>
                <FormLabel
                  error={errorFields.includes('placeValue')}
                  label={t('LEAD.CREATE.GENERAL_INFORMATION.address')}
                />
                <Box sx={{ display: 'flex', marginTop: '4px' }}>
                  <SortableTable
                    addManagement
                    disableSort
                    isAddress
                    billingAddress={formValue.billingAddress}
                    bodyKeys={['name', 'phone', 'address']}
                    copybillingAddress={copybillingAddress}
                    disabledTable={!updateFlag}
                    handleDelete={setAddressDeleteId}
                    handleInfoModal={(val: any) => {
                      handleInfoClick(
                        val,
                        t('CUSTOMER.dataUsedTitle', [
                          t('LISTING_PAGES.address')
                        ])
                      );
                    }}
                    name="addresses"
                    onChange={(newAddresses: any) => {
                      setFormValue({
                        ...formValue,
                        addresses: newAddresses
                      });
                    }}
                    selectedAddress={selectedAddress}
                    setCopybillingAddress={setCopybillingAddress}
                    timeZoneBillingAddres={timeZoneBillingAddres || undefined}
                    type="addresses"
                    values={formValue.addresses}
                  />
                </Box>
              </Box>
            </FlexContainer>
          </WhiteBoxContainer>
        );
      case 'pointOfContact':
        return (
          <WhiteBoxContainer customStyles={customStyles} isMobile={isMobile}>
            <Box
              sx={{
                marginY: '16px'
              }}
            >
              <FormLabel
                error={errorFields.includes('contacts')}
                label={t('CUSTOMER.contactsLabel')}
              />
              <Box mt={2}>
                <SortableTable
                  addManagement
                  disableSort
                  bodyKeys={['name', 'phoneNumber']}
                  disabledTable={!updateFlag}
                  handleDelete={setContactDeleteId}
                  handleInfoModal={(val: any) => {
                    handleInfoClick(
                      val,
                      t('CUSTOMER.dataUsedTitle', [t('LISTING_PAGES.contact')])
                    );
                  }}
                  name="contacts"
                  onChange={(newContactList: any) => {
                    setFormValue({ ...formValue, contacts: newContactList });
                  }}
                  type="generic-input"
                  values={formValue.contacts}
                />
              </Box>
            </Box>
          </WhiteBoxContainer>
        );

      case 'watchers':
        return (
          <CustomerWatcherModal
            cacheWatchers={formValue?.watchers}
            id={clientId}
            setValues={(watchers: any) => {
              setFormValue({ ...formValue, watchers });
            }}
          />
        );
      default:
        return null;
    }
  };

  const getSummary = () => {
    const goStep = (step: string) => {
      const steps = formValue.relationship === 'B2B' ? b2bSteps : b2cSteps;
      const stepIndex = steps.indexOf(step);
      if (stepIndex === -1) {
        return;
      }
      setMode('form');
      setStep(stepIndex);
    };
    return (
      <Box
        sx={{
          backgroundColor: theme.palette.background.box,
          borderRadius: 2,
          display: 'flex',
          flexDirection: 'column',
          marginTop: 2,
          maxWidth: '92%',
          padding: 4
        }}
      >
        <Box sx={{ marginBottom: 4 }}>
          <Typography color="primary" fontSize={24} variant="heading_400">
            {t('CUSTOMER.SUMMARY.title')}
          </Typography>
          <Typography color="textSecondary" variant="subtitle2">
            {t('CUSTOMER.SUMMARY.subtitle')}
          </Typography>
        </Box>
        {formValue.relationship === 'B2B' ? (
          <>
            <Box
              sx={{
                border: `1px solid ${theme.palette.secondary[100]}`,
                borderRadius: 2,
                mb: 3,
                padding: 2
              }}
            >
              <Box
                alignItems="center"
                display="flex"
                justifyContent="space-between"
                mb={2}
              >
                <Box alignItems="center" display="flex" gap={1}>
                  <CorporateFareOutlinedIcon color="primary" fontSize="small" />
                  <Typography color="primary" gap={1} variant="heading_400">
                    {t('CUSTOMER.STEPS.companyInfo.title')}
                  </Typography>
                </Box>
                <IconButton onClick={() => goStep('companyInfo')}>
                  <EditIcon color="primary" fontSize="small" />
                </IconButton>
              </Box>
              <Box display="flex" flexDirection="column">
                <Box
                  display="flex"
                  flexDirection="row"
                  justifyContent="space-between"
                >
                  <Typography
                    sx={{ fontWeight: 'bold', marginBottom: '10px' }}
                    variant="subtitle2"
                  >
                    {t('CUSTOMER.companyName')}
                  </Typography>
                  <Typography variant="body2">
                    {formValue.businessName}
                  </Typography>
                </Box>
                <Box
                  display="flex"
                  flexDirection="row"
                  justifyContent="space-between"
                >
                  <Typography
                    sx={{ fontWeight: 'bold', marginBottom: '10px' }}
                    variant="subtitle2"
                  >
                    {t('CUSTOMER.jobTitle')}
                  </Typography>
                  <Typography variant="body2">{formValue.jobTitle}</Typography>
                </Box>
              </Box>
            </Box>
            <Box
              sx={{
                border: `1px solid ${theme.palette.secondary[100]}`,
                borderRadius: 2,
                mb: 3,
                padding: 2
              }}
            >
              <Box
                alignItems="center"
                display="flex"
                justifyContent="space-between"
                mb={2}
              >
                <Box alignItems="center" display="flex" gap={1}>
                  <PersonOutlineOutlinedIcon color="primary" fontSize="small" />
                  <Typography color="primary" gap={1} variant="heading_400">
                    {t('CUSTOMER.STEPS.b2bMainContact.title')}
                  </Typography>
                </Box>
                <IconButton onClick={() => goStep('b2bMainContact')}>
                  <EditIcon color="primary" fontSize="small" />
                </IconButton>
              </Box>
              <Box display="flex" flexDirection="column">
                <Box
                  display="flex"
                  flexDirection="row"
                  justifyContent="space-between"
                >
                  <Typography
                    sx={{ fontWeight: 'bold', marginBottom: '10px' }}
                    variant="subtitle2"
                  >
                    {t('CUSTOMER.firstName')}
                  </Typography>
                  <Typography variant="body2">{formValue.firstName}</Typography>
                </Box>
                <Box
                  display="flex"
                  flexDirection="row"
                  justifyContent="space-between"
                >
                  <Typography
                    sx={{ fontWeight: 'bold', marginBottom: '10px' }}
                    variant="subtitle2"
                  >
                    {t('CUSTOMER.lastName')}
                  </Typography>
                  <Typography variant="body2">{formValue.lastName}</Typography>
                </Box>
                <Box
                  display="flex"
                  flexDirection="row"
                  justifyContent="space-between"
                >
                  <Typography
                    sx={{ fontWeight: 'bold', marginBottom: '10px' }}
                    variant="subtitle2"
                  >
                    {t('CUSTOMER.emailAddress')}
                  </Typography>
                  <Typography variant="body2">{formValue.email}</Typography>
                </Box>
                <Box
                  display="flex"
                  flexDirection="row"
                  justifyContent="space-between"
                >
                  <Typography
                    sx={{ fontWeight: 'bold', marginBottom: '10px' }}
                    variant="subtitle2"
                  >
                    {t('CUSTOMER.phoneNumber')}
                  </Typography>
                  <Typography variant="body2">{formValue.phone}</Typography>
                </Box>
              </Box>
              <Box
                sx={{
                  border: `1px solid ${theme.palette.secondary[100]}`,
                  borderRadius: 2,
                  padding: 2
                }}
              >
                <Box
                  alignItems="center"
                  display="flex"
                  justifyContent="space-between"
                  mb={2}
                >
                  <Typography color="primary" variant="heading_400">
                    {t('CUSTOMER.STEPS.communicationMethods.title')}
                  </Typography>
                  <IconButton onClick={() => goStep('communicationMethods')}>
                    <EditIcon color="primary" fontSize="small" />
                  </IconButton>
                </Box>
                <Box display="flex" flexDirection="column">
                  {formValue?.communicationMethods?.map((method: any) => (
                    <Box
                      display="flex"
                      flexDirection="row"
                      justifyContent="space-between"
                    >
                      <Typography
                        sx={{ fontWeight: 'bold', marginBottom: '10px' }}
                        variant="subtitle2"
                      >
                        {method.type}
                      </Typography>
                      <Typography variant="body2">{method.name}</Typography>
                      <Box alignItems="center" display="flex" gap={1}>
                        <Typography variant="body2">{method.value}</Typography>
                        {method.isPrimary && <StarIcon fontSize="small" />}
                      </Box>
                    </Box>
                  ))}
                </Box>
              </Box>
            </Box>
          </>
        ) : (
          <Box
            sx={{
              border: `1px solid ${theme.palette.secondary[100]}`,
              borderRadius: 2,
              mb: 3,
              padding: 2
            }}
          >
            <Box
              alignItems="center"
              display="flex"
              justifyContent="space-between"
              mb={2}
            >
              <Box alignItems="center" display="flex" gap={1}>
                <PersonOutlineOutlinedIcon color="primary" fontSize="small" />
                <Typography color="primary" gap={1} variant="heading_400">
                  {t('CUSTOMER.STEPS.b2cMainContact.title')}
                </Typography>
              </Box>
              <IconButton onClick={() => goStep('b2cMainContact')}>
                <EditIcon color="primary" fontSize="small" />
              </IconButton>
            </Box>
            <Box display="flex" flexDirection="column">
              <Box
                display="flex"
                flexDirection="row"
                justifyContent="space-between"
              >
                <Typography
                  sx={{ fontWeight: 'bold', marginBottom: '10px' }}
                  variant="subtitle2"
                >
                  {t('CUSTOMER.firstName')}
                </Typography>
                <Typography variant="body2">{formValue.firstName}</Typography>
              </Box>
              <Box
                display="flex"
                flexDirection="row"
                justifyContent="space-between"
              >
                <Typography
                  sx={{ fontWeight: 'bold', marginBottom: '10px' }}
                  variant="subtitle2"
                >
                  {t('CUSTOMER.lastName')}
                </Typography>
                <Typography variant="body2">{formValue.lastName}</Typography>
              </Box>
              <Box
                display="flex"
                flexDirection="row"
                justifyContent="space-between"
              >
                <Typography
                  sx={{ fontWeight: 'bold', marginBottom: '10px' }}
                  variant="subtitle2"
                >
                  {t('CUSTOMER.emailAddress')}
                </Typography>
                <Typography variant="body2">{formValue.email}</Typography>
              </Box>
              <Box
                display="flex"
                flexDirection="row"
                justifyContent="space-between"
              >
                <Typography
                  sx={{ fontWeight: 'bold', marginBottom: '10px' }}
                  variant="subtitle2"
                >
                  {t('CUSTOMER.phoneNumber')}
                </Typography>
                <Typography variant="body2">{formValue.phone}</Typography>
              </Box>
            </Box>
            <Box
              sx={{
                border: `1px solid ${theme.palette.secondary[100]}`,
                borderRadius: 2,
                padding: 2
              }}
            >
              <Box
                alignItems="center"
                display="flex"
                justifyContent="space-between"
                mb={2}
              >
                <Typography color="primary" variant="heading_400">
                  {t('CUSTOMER.STEPS.communicationMethods.title')}
                </Typography>
                <IconButton onClick={() => goStep('communicationMethods')}>
                  <EditIcon color="primary" fontSize="small" />
                </IconButton>
              </Box>
              <Box display="flex" flexDirection="column">
                {formValue?.communicationMethods?.map((method: any) => (
                  <Box
                    display="flex"
                    flexDirection="row"
                    justifyContent="space-between"
                  >
                    <Typography
                      sx={{ fontWeight: 'bold', marginBottom: '10px' }}
                      variant="subtitle2"
                    >
                      {method.type}
                    </Typography>
                    <Typography variant="body2">{method.name}</Typography>
                    <Box alignItems="center" display="flex" gap={1}>
                      <Typography variant="body2">{method.value}</Typography>
                      {method.isPrimary && <StarIcon fontSize="small" />}
                    </Box>
                  </Box>
                ))}
              </Box>
            </Box>
          </Box>
        )}

        {/* Billing Information */}
        <Box
          sx={{
            border: `1px solid ${theme.palette.secondary[100]}`,
            borderRadius: 2,
            mb: 3,
            padding: 2
          }}
        >
          <Box
            alignItems="center"
            display="flex"
            justifyContent="space-between"
            mb={2}
          >
            <Box alignItems="center" display="flex" gap={1}>
              <PaymentsOutlinedIcon color="primary" fontSize="small" />
              <Typography color="primary" variant="heading_400">
                {t('CUSTOMER.billingInformation')}
              </Typography>
            </Box>
            <IconButton onClick={() => goStep('billingPreferences')}>
              <EditIcon color="primary" fontSize="small" />
            </IconButton>
          </Box>
          <Box display="flex" flexDirection="column">
            <Box
              display="flex"
              flexDirection="row"
              justifyContent="space-between"
            >
              <Typography
                sx={{ fontWeight: 'bold', marginBottom: '10px' }}
                variant="subtitle2"
              >
                {t('CUSTOMER.billingAddress')}
              </Typography>
              <Typography variant="body2">
                {formValue.billingAddress}
              </Typography>
            </Box>
            <Box
              display="flex"
              flexDirection="row"
              justifyContent="space-between"
            >
              <Typography
                sx={{ fontWeight: 'bold', marginBottom: '10px' }}
                variant="subtitle2"
              >
                {t('CUSTOMER.validWorkOrderLabel')}
              </Typography>
              <Box display="flex" flexDirection="column">
                {formValue?.workOrderEmails?.map((email: any) => (
                  <Typography variant="body2">{email}</Typography>
                ))}
              </Box>
            </Box>
            <Box
              display="flex"
              flexDirection="row"
              justifyContent="space-between"
            >
              <Typography
                sx={{ fontWeight: 'bold', marginBottom: '10px' }}
                variant="subtitle2"
              >
                {t('CUSTOMER.billingEmail')}
              </Typography>
              <Box display="flex" flexDirection="column">
                {formValue?.billingEmail?.map((email: any) => (
                  <Typography variant="body2">{email}</Typography>
                ))}
              </Box>
            </Box>
          </Box>
        </Box>

        {/* Service Address */}
        <Box
          sx={{
            border: `1px solid ${theme.palette.secondary[100]}`,
            borderRadius: 2,
            mb: 3,
            padding: 2
          }}
        >
          <Box
            alignItems="center"
            display="flex"
            justifyContent="space-between"
            mb={2}
          >
            <Box alignItems="center" display="flex" gap={1}>
              <PlaceOutlinedIcon color="primary" fontSize="small" />
              <Typography color="primary" variant="heading_400">
                {t('CUSTOMER.STEPS.serviceAddress.title')}
              </Typography>
            </Box>
            <IconButton onClick={() => goStep('serviceAddress')}>
              <EditIcon color="primary" fontSize="small" />
            </IconButton>
          </Box>
          <Box display="flex" flexDirection="column">
            {formValue?.addresses?.map((address: any, index: number) => (
              <Box
                display="flex"
                flexDirection="row"
                justifyContent="space-between"
              >
                <Typography
                  sx={{ fontWeight: 'bold', marginBottom: '10px' }}
                  variant="subtitle2"
                >
                  {`#${index + 1}`}
                </Typography>
                <Typography variant="body2">{address.name}</Typography>
                <Typography variant="body2">{address.phone}</Typography>
                <Typography variant="body2">{address.address}</Typography>
              </Box>
            ))}
          </Box>
        </Box>

        {/* Contacts */}
        <Box
          sx={{
            border: `1px solid ${theme.palette.secondary[100]}`,
            borderRadius: 2,
            mb: 3,
            padding: 2
          }}
        >
          <Box
            alignItems="center"
            display="flex"
            justifyContent="space-between"
            mb={2}
          >
            <Box alignItems="center" display="flex" gap={1}>
              <LocalPhoneOutlinedIcon color="primary" fontSize="small" />
              <Typography color="primary" variant="heading_400">
                {t('CUSTOMER.STEPS.pointOfContact.title')}
              </Typography>
            </Box>
            <IconButton onClick={() => goStep('pointOfContact')}>
              <EditIcon color="primary" fontSize="small" />
            </IconButton>
          </Box>
          <Box display="flex" flexDirection="column">
            {formValue.contacts.map((contact: any, index: number) => (
              <Box
                display="flex"
                flexDirection="row"
                justifyContent="space-between"
              >
                <Typography
                  sx={{ fontWeight: 'bold', marginBottom: '10px' }}
                  variant="subtitle2"
                >
                  {`${t('CUSTOMER.contactN')}${index + 1}`}
                </Typography>
                <Typography variant="body2">{contact.name}</Typography>
                <Typography variant="body2">{contact.phoneNumber}</Typography>
              </Box>
            ))}
          </Box>
        </Box>
        <Box display="flex" justifyContent="right">
          <Button
            onClick={() => handleSubmit()}
            sx={{ width: '380px' }}
            variant="primary"
          >
            {t('CUSTOMER.save')}
          </Button>
        </Box>
      </Box>
    );
  };

  const getForm = () => {
    const availableSteps =
      formValue.relationship === 'B2B' ? b2bSteps : b2cSteps;
    if (isOperator && !availableSteps.includes('watchers')) {
      availableSteps.push('watchers');
    }
    return (
      <Box
        sx={{
          backgroundColor: theme.palette.background.box,
          borderRadius: 2,
          display: 'flex',
          flexDirection: 'column',
          minHeight: 600,
          padding: 8
        }}
      >
        <Typography color="primary" variant="heading_500">
          {t(`CUSTOMER.STEPS.${availableSteps[step]}.title`)}
        </Typography>
        <Typography color="secondary" variant="body_200">
          {t(`CUSTOMER.STEPS.${availableSteps[step]}.subtitle`)}
        </Typography>
        {getStep(availableSteps[step])}
        <MobileStepper
          activeStep={step}
          backButton={
            <Button
              disabled={step === 0}
              onClick={() => {
                setStep(step - 1);
              }}
              size="small"
              sx={{ width: 120 }}
            >
              <KeyboardArrowLeft />
              {t('GENERAL.backButton')}
            </Button>
          }
          nextButton={
            <Button
              disabled={step === availableSteps.length}
              onClick={() => {
                if (step === availableSteps.length - 1) {
                  setMode('summary');
                } else {
                  setStep(step + 1);
                }
              }}
              size="small"
              sx={{ width: 120 }}
              variant="primary"
            >
              {step === availableSteps.length - 1
                ? t('GENERAL.summaryButton')
                : t('GENERAL.nextButton')}
              <KeyboardArrowRight />
            </Button>
          }
          position="static"
          steps={availableSteps.length}
          sx={{ alignItems: 'center', display: 'flex', marginTop: 'auto' }}
          variant="dots"
        />
        <Box />
      </Box>
    );
  };

  // ============================================================================= RETURN

  return (
    <Box width="100%">
      <HeaderFormContainer
        isMobile={false}
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
          paddingTop: 4
        }}
      >
        {/* ---------------- Header ---------------- */}
        <Box
          sx={{ alignItems: 'center', display: 'flex', flexDirection: 'row' }}
        >
          {params?.lastPage ? (
            <Button
              onClick={() => {
                navigate(params.lastPage, { state: params });
              }}
            >
              <i className="custom-icon icon-arrow-left"> </i>
            </Button>
          ) : (
            <IconButton
              onClick={() => {
                navigate(-1);
              }}
            >
              <ArrowBackIcon fill="grey" />
            </IconButton>
          )}
          <TitleFormBox
            customStyles={{ paddingLeft: '8px' }}
            isMobile={isMobile}
          >
            <Typography sx={{ marginBottom: '4px' }} variant="title">
              {isNew
                ? t('CUSTOMER.createClient')
                : t('CUSTOMER.customerInformation')}
            </Typography>
            <Typography color="secondary.400" variant="body_300">
              {isNew ? t('CUSTOMER.createSubtitle') : t('CUSTOMER.subtitle')}
            </Typography>
          </TitleFormBox>
        </Box>
        {/* <Box
          sx={{
            alignItems: 'center',
            display: 'flex',
            justifyContent: 'center',
            padding: 2
          }}
        >
          <Typography color="secondary.main" variant="primary">
            {formValue?.status}
          </Typography>
          {isOperator && (
            <Tooltip title={t('TOOLTIP.PROPOSAL.watchers')}>
              <Button
                onClick={() => {
                  setShowWatchers(true);
                }}
                variant="table"
              >
                <i className="custom-icon icon-eye" />
              </Button>
            </Tooltip>
          )}
          {isOperator && (
            <DeleteButton
              deleteFn={() => {
                setFormValue({ ...formValue, archived: !formValue.archived });
              }}
              icon={formValue.archived ? 'unarchived' : 'archived'}
              tooltip={`${
                formValue.archived
                  ? t('TOOLTIP.GENERAL.unarchive')
                  : t('TOOLTIP.GENERAL.archive')
              }`}
            />
          )}
        </Box> */}
      </HeaderFormContainer>
      {mode === 'summary' && getSummary()}
      {mode === 'form' && getForm()}

      <Modal
        aria-describedby="modal-modal-description-profile"
        aria-labelledby="modal-modal-title-profile"
        onClose={() => setOpenInfoModal(false)}
        open={openInfoModal}
      >
        <Box sx={{ ...styleModalInfo, width: '90%' }}>
          <Box
            sx={{
              alignItems: 'center',
              display: 'flex',
              flexDirection: 'column'
            }}
          >
            <Box
              sx={{
                alignItems: 'center',
                display: 'flex',
                flexDirection: 'column',
                gap: 4,
                justifyContent: 'center',
                textAlign: 'center'
              }}
            >
              <Typography sx={{ fontSize: 22 }} variant="primary-bold">
                {chartTitle}
              </Typography>
              <Box sx={{ display: 'flex', flexDirection: 'row' }}>
                <ColumnChart
                  hideXLabels
                  showTitle
                  data={chartData?.lead}
                  name="Lead"
                  type="Historical"
                />
                <ColumnChart
                  hideXLabels
                  showTitle
                  data={chartData?.proposal}
                  name="Proposal"
                  type="Historical"
                />
                <ColumnChart
                  hideXLabels
                  showTitle
                  data={chartData?.project}
                  name="Project"
                  type="Historical"
                />
              </Box>
            </Box>
            <br />
            <Button
              onClick={() => {
                setOpenInfoModal(false);
              }}
              sx={{ width: isMobile ? '100%' : 200 }}
              variant="outlined"
            >
              <Typography variant="primary-bold">
                {t('GENERAL.closeButton')}
              </Typography>
            </Button>
          </Box>
        </Box>
      </Modal>

      {error && (
        <Modal
          aria-describedby="modal-modal-description-profile"
          aria-labelledby="modal-modal-title-profile"
          onClose={() => setOpenModal(false)}
          open={openModal}
        >
          <Box sx={style}>
            <Box
              sx={{
                alignItems: 'center',
                display: 'flex',
                flexDirection: 'column'
              }}
            >
              <Box
                sx={{
                  alignItems: 'center',
                  justifyContent: 'center',
                  textAlign: 'center'
                }}
              >
                <>
                  <Typography
                    color="alert.main"
                    sx={{ fontSize: 22 }}
                    variant="primary-bold"
                  >
                    Error!
                  </Typography>
                  {getError()}
                </>
              </Box>
              <br />
              <Button
                onClick={() => {
                  setOpenModal(false);
                }}
                sx={{ width: isMobile ? '100%' : '50%' }}
                variant="contained"
              >
                <Typography variant="primary-bold">
                  {error ? t('GENERAL.closeButton') : t('GENERAL.done')}
                </Typography>
              </Button>
            </Box>
          </Box>
        </Modal>
      )}
    </Box>
  );

  // return (
  //   <Container>
  //     <Box sx={{ padding: '0px 0px 8px 0px', width: '100%' }}>
  //     </Box>
  //     <Container sx={clientStyle}>
  //       {/* ---- Personal Information ---- */}
  //       <Box>
  //         {/* ---- Personal Information ---- */}
  //         <WhiteBoxContainer isMobile={isMobile}>
  //           <GridContainer
  //             isMobile={isMobile}
  //             sx={{
  //               gap: 2,
  //               gridTemplateColumns: isMobile ? '100%' : '1fr 1fr',
  //               width: '100%'
  //             }}
  //           >
  //             {ACCOUNT_INFO_FIELDS.map((value) => (
  //               <GridBoxInput
  //                 isMobile={isMobile}
  //                 key={value?.name}
  //                 sx={{ width: '100%' }}
  //               >
  //                 <FormLabel
  //                   label={value?.required ? `${value?.label}*` : value?.label}
  //                 />
  //                 <RegularInput
  //                   customStyle={{
  //                     width: '100%'
  //                   }}
  //                   disabled={!updateFlag}
  //                   error={errorFields.includes(value?.name) && value?.required}
  //                   name={value?.name}
  //                   onChange={(inputValue) => onChange(value.name, inputValue)}
  //                   type={value?.type}
  //                   value={formValue[value?.name]}
  //                 />
  //               </GridBoxInput>
  //             ))}
  //           </GridContainer>
  //         </WhiteBoxContainer>
  //       </Box>
  //       {/* ---- Extra info ----- */}
  //       <WhiteBoxContainer
  //         isMobile={isMobile}
  //         sx={{ display: 'flex', flexDirection: 'row', gap: 2 }}
  //       >
  //         <Box sx={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
  //           <FormLabel label={t('CUSTOMER.type')} />
  //           <SelectInput
  //             disabled={!updateFlag}
  //             labelId="demo-simple-select-label"
  //             onChange={(event) => {
  //               setFormValue({
  //                 ...formValue,
  //                 type: event.target.value
  //               });
  //             }}
  //             selectvariant="secondary"
  //             value={formValue.type}
  //           >
  //             {customerType.map((type: string) => (
  //               <MenuItem key={type} value={type}>
  //                 {type}
  //               </MenuItem>
  //             ))}
  //           </SelectInput>
  //         </Box>
  //         <Box sx={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
  //           <FormLabel label={t('CUSTOMER.relationship')} />
  //           <SelectInput
  //             disabled={!updateFlag}
  //             labelId="demo-simple-select-label"
  //             onChange={(event) => {
  //               setFormValue({
  //                 ...formValue,
  //                 relationship: event.target.value
  //               });
  //             }}
  //             selectvariant="secondary"
  //             value={formValue.relationship}
  //           >
  //             {customerRelationship.map((type: string) => (
  //               <MenuItem key={type} value={type}>
  //                 {type}
  //               </MenuItem>
  //             ))}
  //           </SelectInput>
  //         </Box>
  //       </WhiteBoxContainer>

  //       {formValue?.relationship === CreateClientDTORelationshipEnum.B2B && (
  //         <WhiteBoxContainer isMobile={isMobile}>
  //           <GridContainer
  //             isMobile={isMobile}
  //             sx={{
  //               gap: 2,
  //               gridTemplateColumns: isMobile ? '100%' : '1fr 1fr',
  //               width: '100%'
  //             }}
  //           >
  //             {ACCOUNT_B2B_FIELDS.map((value) => (
  //               <GridBoxInput
  //                 isMobile={isMobile}
  //                 key={value?.name}
  //                 sx={{ width: '100%' }}
  //               >
  //                 <FormLabel
  //                   label={value?.required ? `${value?.label}*` : value?.label}
  //                 />
  //                 <RegularInput
  //                   customStyle={{
  //                     width: '100%'
  //                   }}
  //                   disabled={!updateFlag}
  //                   error={errorFields.includes(value?.name) && value?.required}
  //                   name={value?.name}
  //                   onChange={(inputValue) => onChange(value.name, inputValue)}
  //                   type={value?.type}
  //                   value={formValue[value?.name]}
  //                 />
  //               </GridBoxInput>
  //             ))}
  //           </GridContainer>
  //         </WhiteBoxContainer>
  //       )}
  //       <WhiteBoxContainer isMobile={isMobile}>
  //         <Box>
  //           <FormLabel label={`${t('CUSTOMER.communicationMethods')}*`} />
  //           <Box
  //             sx={{
  //               display: 'flex',
  //               flexDirection: 'column',
  //               justifyContent: 'left',
  //               marginTop: '16px'
  //             }}
  //           >
  //             <SortableTable
  //               addManagement
  //               disableSort
  //               bodyKeys={['type', 'value', 'name', 'isPrimary']}
  //               disabledTable={!updateFlag}
  //               handleDelete={(id: string) => {
  //                 setFormValue({
  //                   ...formValue,
  //                   communicationMethodsDeleted:
  //                     formValue?.communicationMethodsDeleted
  //                       ? [...formValue.communicationMethodsDeleted, id]
  //                       : [id]
  //                 });
  //               }}
  //               name="communicationMethods"
  //               onChange={(communicationMethods: any) => {
  //                 setFormValue({
  //                   ...formValue,
  //                   communicationMethods: [...communicationMethods]
  //                 });
  //               }}
  //               type="communication-input"
  //               values={formValue?.communicationMethods || []}
  //             />
  //           </Box>
  //         </Box>
  //       </WhiteBoxContainer>

  //       {/* ---- Billing Section ---- */}
  //       <WhiteBoxContainer isMobile={isMobile}>
  //         <FlexContainer>
  //           {/* ---- Billing Address ---- */}
  //           <Box mt={1}>
  //             <FormLabel label={`${t('CUSTOMER.billingAddress')}*`} />
  //             <FlexBoxAutoComplete>{Autocomplete()}</FlexBoxAutoComplete>
  //           </Box>

  //           {type === 'add' ? (
  //             <FormControlLabel
  //               value
  //               checked={copybillingAddress ? selectedAddress : false}
  //               control={<Checkbox />}
  //               disabled={!selectedAddress}
  //               label={
  //                 <Typography fontSize={12} variant="primary-bold">
  //                   {t('MODAL.usePrimaryServicesAddress')}
  //                 </Typography>
  //               }
  //               onChange={handleCheckboxChange}
  //               style={{
  //                 marginTop: '-21px'
  //               }}
  //             />
  //           ) : null}

  //           {/* ---- Valid work order emails ---- */}
  //           <Box
  //             sx={{
  //               columnGap: '24px',
  //               display: 'grid',
  //               gridTemplateColumns: isMobile ? '100%' : '50% 50%',
  //               marginY: '8px',
  //               width: '100%'
  //             }}
  //           >
  //             <Box>
  //               <FormLabel label={`${t('CUSTOMER.validWorkOrderLabel')}*`} />
  //               <Box
  //                 sx={{
  //                   display: 'flex',
  //                   flexDirection: 'column',
  //                   justifyContent: 'left',
  //                   marginTop: '16px',
  //                   width: '376px'
  //                 }}
  //               >
  //                 <SortableTable
  //                   addManagement
  //                   disableSort
  //                   bodyKeys={['validEmails']}
  //                   disabledTable={!updateFlag}
  //                   name="workOrderEmails"
  //                   onChange={(workOrderEmails: any) => {
  //                     setFormValue({
  //                       ...formValue,
  //                       workOrderEmails: [...workOrderEmails]
  //                     });
  //                   }}
  //                   type="input"
  //                   values={formValue.workOrderEmails}
  //                 />
  //               </Box>
  //             </Box>

  //             {/* ---- Billing Email ---- */}
  //             <Box>
  //               <FormLabel label={`${t('CUSTOMER.billingEmail')}*`} />
  //               <Box
  //                 sx={{
  //                   display: 'flex',
  //                   flexDirection: 'column',
  //                   justifyContent: 'left',
  //                   marginTop: '16px',
  //                   width: '376px'
  //                 }}
  //               >
  //                 <SortableTable
  //                   addManagement
  //                   disableSort
  //                   bodyKeys={['email']}
  //                   disabledTable={!updateFlag}
  //                   name="billingEmail"
  //                   onChange={(newEmail: any) => {
  //                     setFormValue({
  //                       ...formValue,
  //                       billingEmail: [...newEmail]
  //                     });
  //                   }}
  //                   type="input"
  //                   values={formValue.billingEmail}
  //                 />
  //               </Box>
  //             </Box>
  //           </Box>

  //           {/* ---- Client Addresses ---- */}
  //           <Box sx={{ marginTop: '8px' }}>
  //             <FormLabel
  //               error={errorFields.includes('placeValue')}
  //               label={t('LEAD.CREATE.GENERAL_INFORMATION.address')}
  //             />
  //             <Box sx={{ display: 'flex', marginTop: '4px' }}>
  //               <SortableTable
  //                 addManagement
  //                 disableSort
  //                 isAddress
  //                 billingAddress={formValue.billingAddress}
  //                 bodyKeys={['name', 'phone', 'address']}
  //                 copybillingAddress={copybillingAddress}
  //                 disabledTable={!updateFlag}
  //                 handleDelete={setAddressDeleteId}
  //                 handleInfoModal={(val: any) => {
  //                   handleInfoClick(
  //                     val,
  //                     t('CUSTOMER.dataUsedTitle', [t('LISTING_PAGES.address')])
  //                   );
  //                 }}
  //                 name="addresses"
  //                 onChange={(newAddresses: any) => {
  //                   setFormValue({
  //                     ...formValue,
  //                     addresses: newAddresses
  //                   });
  //                 }}
  //                 selectedAddress={selectedAddress}
  //                 setCopybillingAddress={setCopybillingAddress}
  //                 timeZoneBillingAddres={timeZoneBillingAddres || undefined}
  //                 type="addresses"
  //                 values={formValue.addresses}
  //               />
  //             </Box>
  //           </Box>

  //           {/* ---- Client Contacts ---- */}
  //           <Box
  //             sx={{
  //               marginY: '16px'
  //             }}
  //           >
  //             <FormLabel
  //               error={errorFields.includes('contacts')}
  //               label={t('CUSTOMER.contactsLabel')}
  //             />
  //             <Box mt={2}>
  //               <SortableTable
  //                 addManagement
  //                 disableSort
  //                 bodyKeys={['name', 'phoneNumber']}
  //                 disabledTable={!updateFlag}
  //                 handleDelete={setContactDeleteId}
  //                 handleInfoModal={(val: any) => {
  //                   handleInfoClick(
  //                     val,
  //                     t('CUSTOMER.dataUsedTitle', [t('LISTING_PAGES.contact')])
  //                   );
  //                 }}
  //                 name="contacts"
  //                 onChange={(newContactList: any) => {
  //                   setFormValue({ ...formValue, contacts: newContactList });
  //                 }}
  //                 type="generic-input"
  //                 values={formValue.contacts}
  //               />
  //             </Box>
  //           </Box>

  //           {/* ---- Button Section ---- */}
  //           <Box
  //             sx={{
  //               display: 'flex',
  //               flexDirection: 'row',
  //               justifyContent: isMobile ? 'center' : 'flex-end',
  //               width: '100%'
  //             }}
  //           >
  //             <Box
  //               sx={{
  //                 '& button': { width: '100%' },
  //                 display: 'flex',
  //                 width: isMobile ? '100%' : '50%'
  //               }}
  //             >
  //               {type === 'add' ? (
  //                 <Button onClick={() => handleSubmit()} variant="primary">
  //                   {t('CUSTOMER.addCustomerButton')}
  //                 </Button>
  //               ) : (
  //                 <ButtonFormGroup
  //                   actionButtonMsg={t('PROFILE.saveInformation')}
  //                   cancelAction={() => {
  //                     setUpdateFlag(false);
  //                     const originalObject = isClient
  //                       ? dataInformation
  //                       : dataGetClient;
  //                     const clonedObject = cloneDeep(originalObject);
  //                     setFormValue({ ...clonedObject });
  //                     setErrorMsgs(['']);
  //                     setErrorFields([]);
  //                     setError(false);
  //                   }}
  //                   enableEditAction={() => setUpdateFlag(true)}
  //                   enableEditButtonMsg={<EditContent />}
  //                   isMobile={isMobile}
  //                   mainAction={() => {
  //                     handleSubmit();
  //                   }}
  //                   updateFlag={updateFlag}
  //                 />
  //               )}
  //             </Box>
  //           </Box>
  //         </FlexContainer>
  //       </WhiteBoxContainer>

  //       {/* ---- Modal Section ---- */}
  //       <Modal
  //         aria-describedby="modal-modal-description-profile"
  //         aria-labelledby="modal-modal-title-profile"
  //         onClose={() => setOpenModal(false)}
  //         open={openModal}
  //       >
  //         <Box sx={style}>
  //           <Box
  //             sx={{
  //               alignItems: 'center',
  //               display: 'flex',
  //               flexDirection: 'column'
  //             }}
  //           >
  //             <Box
  //               sx={{
  //                 alignItems: 'center',
  //                 justifyContent: 'center',
  //                 textAlign: 'center'
  //               }}
  //             >
  //               {error ? (
  //                 <>
  //                   <Typography
  //                     color="alert.main"
  //                     sx={{ fontSize: 22 }}
  //                     variant="primary-bold"
  //                   >
  //                     Error!
  //                   </Typography>
  //                   {getError()}
  //                 </>
  //               ) : (
  //                 <Typography
  //                   color="success.main"
  //                   sx={{ fontSize: 22 }}
  //                   variant="primary-bold"
  //                 >
  //                   {type === 'add'
  //                     ? t('CUSTOMER.customerCreated')
  //                     : t('CUSTOMER.customerUpdated')}
  //                 </Typography>
  //               )}
  //             </Box>
  //             <br />
  //             <Button
  //               onClick={() => {
  //                 if (error) {
  //                   setOpenModal(false);
  //                   // navigate(-1);
  //                 } else {
  //                   setOpenModal(false);
  //                   if (params && params.lastPage) {
  //                     navigate(params.lastPage, {
  //                       state: { clientCreated }
  //                     });
  //                   } else if (isClient) {
  //                     setUpdateFlag(false);
  //                     setFireGetClientInformation(true);
  //                   } else {
  //                     navigate(-1);
  //                   }
  //                 }
  //               }}
  //               sx={{ width: isMobile ? '100%' : '50%' }}
  //               variant="contained"
  //             >
  //               <Typography variant="primary-bold">
  //                 {error ? t('GENERAL.closeButton') : t('GENERAL.done')}
  //               </Typography>
  //             </Button>
  //           </Box>
  //         </Box>
  //       </Modal>
  //     </Container>
  //     {showWatchers && clientId && (
  //       <CustomerWatcherModal
  //         id={clientId}
  //         onClose={() => setShowWatchers(false)}
  //       />
  //     )}
  //   </Container>
  // );
};

ClientForm.defaultProps = {
  clientId: undefined,
  isClient: false,
  isNew: false
};

export default ClientForm;
