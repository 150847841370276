import React, { useState } from 'react';
import {
  Box,
  Typography,
  useTheme,
  RadioGroup,
  FormControlLabel
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import AutoCompleteSupervisor from '../../../../components/input/AutoCompleteSupervisor';
import { FormLabel } from '../../../../components/form/parts';
import RegularInput from '../../../../components/input/RegularInput';
import CustomRadio from '../../../../components/form/CustomRadio';
import { secondaryColors } from '../../../../theme/themeConfig';
import Banner from '../../../../components/shared/Banner';
import Filter from '../../../../components/list/Filter';

interface Props {
  saveStepper: ({ next }: any) => void;
  setStep: React.Dispatch<React.SetStateAction<number>>;
  setValues: React.Dispatch<React.SetStateAction<any>>;
  values: any;
}
export enum SupervisorFrequencyEnum {
  ANNUALLY = 'Annually',
  BIANNUALLY = 'Biannually',
  BIWEEKLY = 'Biweekly',
  FULL_TIME = 'FullTime',
  MONTHLY = 'Monthly',
  QUARTERLY = 'Quarterly',
  WEEKLY = 'Weekly'
}

const AssignSupervisor: React.FC<Props> = function AssignSupervisor({
  saveStepper,
  setValues,
  setStep,
  values
}) {
  const { t } = useTranslation();
  const theme = useTheme();
  const [enableSupervisor, setEnableSupervisor] = useState<
    'enabled' | 'disabled'
  >(
    values?.supervisorEnabled === 'enabled' || values?.supervisor
      ? 'enabled'
      : 'disabled'
  );

  const [showWarning, setShowWarning] = useState(false);
  const Frequencies = [
    SupervisorFrequencyEnum.WEEKLY,
    SupervisorFrequencyEnum.BIWEEKLY,
    SupervisorFrequencyEnum.MONTHLY,
    SupervisorFrequencyEnum.QUARTERLY,
    SupervisorFrequencyEnum.BIANNUALLY,
    SupervisorFrequencyEnum.ANNUALLY,
    SupervisorFrequencyEnum.FULL_TIME
  ];

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    const newValues = { ...values };

    if (value === 'enabled') {
      setEnableSupervisor('enabled');
    } else {
      delete newValues.supervisor;
      delete newValues.supervisorTime;
      delete newValues.supervisorFrequency;
      setEnableSupervisor('disabled');
    }
    setValues({
      ...newValues,
      supervisorEnabled: value === 'enabled'
    });
  };

  return (
    <Box
      display="flex"
      flexDirection="column"
      gap="32px"
      key="assign-supervisor-div-main"
    >
      <Box
        display="flex"
        flexDirection="column"
        gap={4}
        justifyContent="space-between"
      >
        <RadioGroup onChange={handleChange} value={enableSupervisor}>
          <Box display="flex" flexDirection="row" gap="24px">
            <FormControlLabel
              control={<CustomRadio />}
              disabled={values?.readOnly}
              label={
                <Typography
                  color={secondaryColors.SECONDARY_3}
                  variant="body_300"
                >
                  {t('LEAD.CREATE.SERVICES.SCHEDULE.assingSupervisor')}
                </Typography>
              }
              sx={{ margin: 0 }}
              value="enabled"
            />
            <FormControlLabel
              control={<CustomRadio />}
              disabled={values?.readOnly}
              label={
                <Typography
                  color={secondaryColors.SECONDARY_3}
                  variant="body_300"
                >
                  {t('LEAD.CREATE.SERVICES.SCHEDULE.dontAssingSupervisor')}
                </Typography>
              }
              sx={{ margin: 0 }}
              value="disabled"
            />
          </Box>
        </RadioGroup>

        {enableSupervisor === 'enabled' && (
          <Box sx={{ display: 'flex', flexDirection: 'row', gap: 4 }}>
            <Box display="flex" flex={1} flexDirection="column" gap="4px">
              <Typography color="primary.main" variant="heading_300">
                {t(
                  'LEAD.CREATE.SERVICES.SCHEDULE.assingSupervisorAutocomplete'
                )}
              </Typography>
              <AutoCompleteSupervisor
                disabled={values?.readOnly}
                multiple={false}
                setValues={(supervisor) => {
                  setValues({ ...values, supervisor });
                }}
                type="supervisor"
                values={values || []}
              />
            </Box>
            <Box
              display="flex"
              flexDirection="column"
              gap="4px"
              sx={{
                minWidth: '318px'
              }}
            >
              <FormLabel
                label={t('LEAD.CREATE.SERVICES.SCHEDULE.assingSupervisorHours')}
              />
              <RegularInput
                customStyle={{
                  '& .MuiInputBase-input': { height: '40px' },
                  margin: 0
                }}
                disabled={values?.readOnly}
                onChange={(supervisorTime) => {
                  setValues({ ...values, supervisorTime });
                }}
                type="number"
                value={values?.supervisorTime}
              />
            </Box>
            <Box display="flex" flexDirection="column" gap={0.5}>
              <FormLabel label={t('LEAD.CREATE.SUPERVISION.frecuency')} />
              <Filter
                arrayInput={Frequencies.map((frequency: string) =>
                  t(`WIDGETS.OPTIONS.INTERVAL.${frequency}`)
                )}
                backgroundColor={theme.palette.background.default}
                customStyle={{ height: 56, margin: 0 }}
                disabled={values?.readOnly}
                selectInput={
                  values?.supervisorFrequency
                    ? t(
                        `WIDGETS.OPTIONS.INTERVAL.${values?.supervisorFrequency}`
                      )
                    : ''
                }
                setSelectInput={(supervisorFrequency: any) => {
                  setValues({
                    ...values,
                    supervisorFrequency: supervisorFrequency.replace(/\s+/g, '')
                  });
                }}
                title="Frequency"
              />
            </Box>
          </Box>
        )}
      </Box>
      {showWarning && (
        <Banner
          icon
          text={t('LEAD.CREATE.SERVICES.SCHEDULE.assingSupervisorRequired')}
          type="error"
        />
      )}
    </Box>
  );
};

export default AssignSupervisor;
