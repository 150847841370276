import React from 'react';
import { Button, Stack, styled, Typography } from '@mui/material';
import { Container } from '@mui/system';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

interface Props {
  isCompany: boolean;
  token: string | undefined;
}

const WelcomeContainer = styled(Container)(() => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '50px',
  height: 'inherit',
  justifyContent: 'center',
  width: 'inherit'
}));

const StartButton = styled(Button)(() => ({
  maxWidth: '400px',
  width: '100%'
}));

const Welcome: React.FC<Props> = function Welcome({
  token,
  isCompany = false
}) {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const navigateNext = isCompany
    ? `/company/invitation/accept-invitation/${token}/2`
    : `/invitation/accept-invitation/${token}/2`;

  const navigateNextStep = () => {
    navigate(navigateNext);
  };

  return (
    <WelcomeContainer>
      <Stack alignItems="center" gap="50px">
        <Typography color="primary" variant="heading_500">
          {t('INVITATION.welcomeToBluberry')}
        </Typography>
        <Typography color="secondary" textAlign="center" variant="body_200">
          {t('INVITATION.welcomeDescription')}
        </Typography>
      </Stack>

      <StartButton onClick={navigateNextStep} variant="primary">
        {t('INVITATION.start')}
      </StartButton>
    </WelcomeContainer>
  );
};

export default Welcome;
