/* eslint-disable react/require-default-props */
import { ReactNode, useMemo } from 'react';
import { PhoneOutlined, PlaceOutlined } from '@mui/icons-material';
import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined';

import { useTranslation } from 'react-i18next';
import { Box, Button, Grid, Typography, useTheme } from '@mui/material';
import { Destination } from '../../utils/types/ProjectType';

function ClientInformationSection({
  label,
  IconComponent
}: {
  IconComponent: ReactNode;
  label: string;
}) {
  return (
    <Box color="secondary.main" display="flex" gap="0.5em">
      {IconComponent}
      <Typography variant="body_200">{label}</Typography>
    </Box>
  );
}

type CollapseBoxProps = {
  children: React.ReactNode;
  destination: Destination;
  isCollapseOpen?: boolean;
  projectSetupTab?: 'serviceSetup' | 'serviceAndTask' | 'assignments';
  projectType?: string;
  toggleDestination: () => void;
};

const CollapseBox: React.FC<CollapseBoxProps> = function CollapseBox({
  children,
  destination,
  projectSetupTab,
  isCollapseOpen,
  toggleDestination,
  projectType
}: CollapseBoxProps) {
  const { t } = useTranslation();
  const theme = useTheme();

  const projectData = useMemo(
    () => [
      {
        labels: [
          {
            icon: <PlaceOutlined fontSize="small" />,
            label: destination.address.address
          },
          {
            icon: <PhoneOutlined fontSize="small" />,
            label: destination.address.phone
          }
        ],
        title: t('CUSTOMER.projectAddressInformation')
      },
      {
        labels: [
          {
            icon: <AccountCircleOutlinedIcon fontSize="small" />,
            label: destination.contact.name
          },
          {
            icon: <PhoneOutlined fontSize="small" />,
            label: destination.contact.phoneNumber
          }
        ],
        title: t('CUSTOMER.projectContactInformation')
      }
    ],
    [destination, t]
  );

  const showExtendedHeader = useMemo(
    () =>
      projectSetupTab === 'serviceSetup' ||
      (projectSetupTab === 'serviceAndTask' && projectType !== 'Regular'),
    [projectSetupTab, projectType]
  );

  return (
    <Box
      gap={8}
      padding="24px"
      sx={{
        border: `1px solid ${theme.palette.secondary[100]}`,
        borderRadius: '8px'
      }}
    >
      <Grid container columnSpacing={1}>
        {showExtendedHeader ? (
          projectData.map(({ title, labels }, index: number) => (
            <Grid item key={title} xs={index === 0 ? 6 : 5}>
              <Typography variant="heading_300">{title}</Typography>
              <Box display="flex" flexDirection="column" gap={0.5}>
                {labels.map(({ label, icon }) => (
                  <ClientInformationSection
                    IconComponent={icon}
                    key={label}
                    label={label}
                  />
                ))}
              </Box>
            </Grid>
          ))
        ) : (
          <Grid item xs={11}>
            <Typography color="secondary.500" variant="heading_300">
              {destination.address.address}
            </Typography>
          </Grid>
        )}

        <Grid item display="flex" justifyContent="flex-end" xs={1}>
          <Button onClick={() => toggleDestination()} sx={{ padding: 1 }}>
            <i
              className="custom-icon icon-select"
              style={{
                color: 'secondary.400',
                fontSize: '14px',
                transform: `rotate(${isCollapseOpen ? '180deg' : '0deg'})`,
                transitionDuration: '300ms'
              }}
            />
          </Button>
        </Grid>
      </Grid>

      {children}
    </Box>
  );
};

export default CollapseBox;
