import {
  Box,
  BoxProps,
  Button,
  IconButton,
  styled,
  Typography,
  useTheme
} from '@mui/material';
import { DeleteOutlineOutlined } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import React, { FC, useState } from 'react';
import { FormLabel } from '../../../../components/form/parts';
import RegularInput from '../../../../components/input/RegularInput';
import { mainColors } from '../../../../theme/themeConfig';
import SortableTable from '../../../../components/list/sortableTable';

const FlexBox = styled(Box)<BoxProps>(() => ({
  display: 'flex'
}));

const FormGroup = styled(FlexBox)`
  position: relative;
  width: 100%;
  border-radius: 8px;
  border: 1px solid #ededed;

  &:hover .remove-button {
    opacity: 1;
  }

  .remove-button {
    position: absolute;
    top: 20px;
    right: 10px;
    transform: translateY(-50%);
    bottom: 10px;
    transition: opacity 0.2s ease-in-out;
  }
`;

interface FormGroupData {
  contactName: string;
  contactPhoneNumber: string;
}
interface props {
  setValues: React.Dispatch<React.SetStateAction<any>>;
  values: any;
}

const ContactInformation: React.FC<props> = function ContactInformation({
  setValues,
  values
}) {
  const theme = useTheme();
  const { t: translate } = useTranslation('translation', {
    keyPrefix: 'CLIENT_ONBOARDING'
  });

  const [formGroups, setFormGroups] = useState<FormGroupData[]>([
    { contactName: '', contactPhoneNumber: '' }
  ]);

  const inputFields = [
    { key: 'contactPhoneNumber', type: 'phone' },
    { key: 'contactName', type: 'text' }
  ];

  const handleAddGroup = () => {
    setFormGroups((prev) => [
      ...prev,
      { contactName: '', contactPhoneNumber: '' }
    ]);
  };

  const handleRemoveGroup = (groupIndex: number) => {
    if (formGroups.length > 1) {
      setFormGroups((prev) => prev.filter((_, index) => groupIndex !== index));
    }
  };

  const handleInputChange = (
    groupIndex: number,
    fieldKey: string,
    value: string
  ) => {
    setFormGroups((prev) =>
      prev.map((group, index) =>
        index === groupIndex ? { ...group, [fieldKey]: value } : group
      )
    );
  };

  return (
    <FlexBox
      flexDirection="column"
      sx={{
        gap: theme.spacing(3),
        width: '100%'
      }}
    >
      {/* Title */}
      <Typography
        alignSelf="flex-start"
        color="primary.400"
        sx={{ fontSize: '28px', lineHeight: '30px' }}
        textAlign="center"
        variant="primary-bold"
        whiteSpace="break-spaces"
      >
        {translate('contactInformationTitle')}
      </Typography>

      {/* Subtitle */}
      <Typography alignSelf="flex-start">
        {translate('contactInformationDescription')}
      </Typography>

      {/* Render contactName form groups */}
      <SortableTable
        addManagement
        disableSort
        bodyKeys={['name', 'phoneNumber']}
        handleInfoModal={(val: any) => {}}
        name="contacts"
        onChange={(newContactList: any) => {
          setValues({ ...values, contacts: newContactList });
        }}
        type="generic-input"
        values={values.contacts}
      />
    </FlexBox>
  );
};

export default ContactInformation;
