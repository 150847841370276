/* eslint-disable no-unused-vars */
/* eslint-disable react/jsx-props-no-spreading */
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import useSWR from 'swr';
import '../../style/App.scss';
import {
  Box,
  Button,
  Modal,
  Fade,
  Backdrop,
  Typography,
  SlideProps,
  styled,
  SliderProps,
  TextField,
  Container,
  Grid,
  MenuItem
} from '@mui/material';
import HighlightOffOutlinedIcon from '@mui/icons-material/HighlightOffOutlined';
import RegularInput from '../input/RegularInput';
import AutoCompleteInput from '../input/AutoCompleteInput';
import { capitalizeString } from '../../utils/general';
import Scroll from '../form/Scroll';
import api from '../../api';
import Loader from '../shared/Loader';
import ConfirmModal from './ConfirmModal';
import { ROLES } from '../../helpers/roles';
// import { ServiceDTO } from '../../api-client/generated/api';
import { type toastNotification } from '../../utils/notificationHelper';
import SelectInput from '../form/SelectInput';
import { planOptions } from '../../utils/companyHelper';
import useAuthStore from '../../store/zustand/auth';
import useNotificationsStore from '../../store/zustand/notifications';

const ServiceButton = styled(Button)<SlideProps>(() => ({
  color: 'white',
  marginBottom: '15px',
  width: '216px'
}));

const ModalContainer = styled(Box)<SliderProps>(({ theme }) => ({
  backgroundColor: theme.palette.background.paper,
  borderRadius: '0.5em',
  boxShadow: '24',
  display: 'flex',
  flexDirection: 'column',
  height: '100%',
  maxHeight: '90%',
  padding: '40px 70px',
  position: 'absolute' as 'absolute',
  right: '50%',
  top: '50%',
  transform: 'translate(50%, -50%)',
  width: 600
}));

const SubTitle = styled(Typography)<SliderProps>(({ theme }) => ({
  color: theme.palette.primary.main,
  fontSize: '17px',
  fontWeight: 'bolder',
  marginBottom: '-10px'
}));

const SectionContainer = styled(Box)<SliderProps>(() => ({
  alignItems: 'center',
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  marginTop: '10px'
}));

interface ModalProps {
  action: (params: any) => Promise<any>;
  handleClose: () => void;
  mutateAction: () => void;
  openValue: boolean;
  roles?: Array<any>;
  type: 'company' | 'user';
}

const InviteModal: React.FC<ModalProps> = function InviteModal({
  openValue,
  handleClose,
  action,
  type,
  mutateAction,
  roles
}) {
  // We upperCase arrayType to use it in the placeHolder
  const { t } = useTranslation();
  const { addNotification } = useNotificationsStore((state) => state);
  const userState = useAuthStore((state) => state);
  const [open, setOpen] = useState<boolean>(openValue);
  const [error, setError] = useState<boolean>(false);
  const [errorMsg, setErrorMsg] = useState<string>('');
  const [create, setCreate] = useState<boolean>(false);
  const [canCreate, setCanCreate] = useState<boolean>(false);
  const [skillIds, setSkillIds] = useState<any>();
  const [createSkill, setCreateSkill] = useState<boolean>(false);
  // const [getSkills, setSkills] = useState<boolean>(false);
  const [valuesAutocomplete, setValuesAutocomplete] = useState<Array<any>>([]);
  const [valuesAutocompleteSkills, setValuesAutocompleteSkills] = useState<
    Array<any>
  >([]);
  const [fireConfirmExit, setFireConfirmExit] = useState<boolean>(false);
  const [fireClose, setFireClose] = useState<boolean>(false);
  const [showOkModal, setShowOkModal] = useState<boolean>(false);

  const { data: skills, isValidating: skillsLoading } = useSWR(
    { url: 'api/AllSkills' },
    api.skillApi.getAllSkills
  );

  // User invitation structure
  const user = {
    email: '',
    firstName: '',
    jobTitle: '',
    lastName: '',
    phoneNumber: '',
    rate: '',
    roleIds: [],
    skillIds: []
  };

  // Company invitation structure
  const company = {
    administratorEmail: '',
    administratorFirstName: '',
    administratorLastName: '',
    companyDescription: '',
    companyName: ''
  };

  const [value, setValue] = useState<any>(type === 'user' ? user : company);

  const [valueSkill, setValueSkill] = useState<any>({
    category: null,
    description: '',
    name: ''
  });

  const nameSkill =
    valuesAutocompleteSkills && valuesAutocompleteSkills[0]?.createValue;

  const { data: skillNameResponse } = useSWR(
    canCreate && valuesAutocompleteSkills[0]?.createValue !== undefined
      ? { name: nameSkill }
      : null,
    api.skillApi.getSkillByName
  );

  useEffect(() => {
    if (skillNameResponse) {
      setCreateSkill(true);
      setCanCreate(false);
      const formatSkill = {
        categoryId: null,
        description: nameSkill,
        name: nameSkill
      };
      setValueSkill(formatSkill);
    }
  }, [skillNameResponse]);

  const { data: createDataSkill, error: createErrorSkill } = useSWR(
    createSkill ? valueSkill : null,
    api.skillApi.createSkill
  );

  useEffect(() => {
    if (createDataSkill) {
      setValueSkill({
        category: null,
        description: '',
        name: ''
      });
      setCreateSkill(false);
      setCanCreate(false);
      setSkillIds(createDataSkill?.data?.id);
    }
  }, [createDataSkill, valuesAutocompleteSkills]);

  // ================================================== SWR

  const { data: createData, error: createError } = useSWR(
    create ? value : null,
    action
  );

  // =================================================== FUNCTIONS

  const handleCreate = async () => {
    const technicianRoleId = roles?.find(
      (role) => role.name === ROLES.TECHNICIAN
    )?.id;
    const supervisorRoleId = roles?.find(
      (role) => role.name === ROLES.SUPERVISOR
    )?.id;

    if (type === 'user') {
      if (
        value.email === '' ||
        value.firstName === '' ||
        value.lastName === '' ||
        value.jobTitle === '' ||
        value.rate <= 0.0 ||
        value.roleIds.length === 0 ||
        (value.roleIds.includes(technicianRoleId) &&
          value.skillIds.length === 0)
      ) {
        setErrorMsg(`${t('ERROR.inputRequired')}`);
        setError(true);
        return;
      }
    } else if (type === 'company') {
      if (
        value.administratorEmail === '' ||
        value.administratorFirstName === '' ||
        value.administratorLastName === '' ||
        value.companyDescription === '' ||
        value.companyName === '' ||
        value.plan === ''
      ) {
        setErrorMsg(`${t('ERROR.inputRequired')}`);
        setError(true);
        return;
      }
      setCreate(true);
      return;
    }

    setError(false);

    setCreateSkill(false);
    setCanCreate(false);

    if (value.roleIds && value.roleIds.includes(technicianRoleId)) {
      setValue({
        ...value,
        skillIds: createDataSkill
          ? [skillIds]
          : valuesAutocompleteSkills.map((i) => i.id)
      });
    } else {
      setValue({
        ...value,
        skillIds: []
      });
    }

    const selectedRoles = value.roleIds;
    const isSpecialRole: boolean =
      selectedRoles &&
      selectedRoles.length > 0 &&
      selectedRoles.every(
        (roleId: string) =>
          roleId === technicianRoleId || roleId === supervisorRoleId
      );

    if (isSpecialRole && userState.plan === 'Calculator' && type === 'user') {
      setValue({
        ...value,
        specialRole: true
      });
    }

    setCreate(true);
  };

  const errorStringValidate = () => {
    const toBack = [];
    if (type === 'user') {
      if (value.email === '') {
        toBack.push(`${t('ERROR.emailFormat')}`);
      }
      if (
        (value.rate !== undefined || value.rate !== null) &&
        value.rate <= 0
      ) {
        toBack.push(`${t('ERROR.numberInt', ['rate'])}`);
      }
      if (value.roleIds && value.roleIds.length === 0) {
        toBack.push(`${t('ERROR.rolesRequired')}`);
      }

      const technicianRolId = roles?.find(
        (role) => role.name === ROLES.TECHNICIAN
      )?.id;

      if (
        value.skillIds &&
        value.skillIds.length === 0 &&
        value.roleIds.includes(technicianRolId)
      ) {
        toBack.push(`${t('ERROR.skillsRequired')}`);
      }
    } else if (value.administratorEmail === '') {
      toBack.push(`${t('ERROR.emailFormat')}`);
    }
    toBack.push(`${errorMsg}`);
    return toBack.map((item: string, index: number) => (
      <span key={`error-${index + 1}`}>
        &bull; &nbsp;
        {item}
        <br />
      </span>
    ));
  };

  const getError = () =>
    !error ? (
      false
    ) : (
      <Container sx={{ mt: 2 }}>
        <Typography
          color="alert.main"
          sx={{ fontSize: 14 }}
          variant="primary-bold"
        >
          {errorStringValidate()}
        </Typography>
      </Container>
    );

  const getUserInviteContent = () => {
    if (roles && roles?.length > 0) {
      return (
        <Box sx={{ marginTop: '30px' }}>
          <Grid container spacing={2}>
            <Grid item sm={6} xs={12}>
              <SubTitle>{capitalizeString(t('GENERAL.firstName'))}*</SubTitle>
              <Box sx={{ margin: '0px 0px 5px' }}>
                <RegularInput
                  customStyle={{ fontSize: '15px', width: '100%' }}
                  error={error && value.firstName === ''}
                  inputInvalid={setError}
                  onChange={(event: any) =>
                    setValue({ ...value, firstName: event })
                  }
                />
              </Box>
            </Grid>
            <Grid item sm={6} xs={12}>
              <SubTitle>{capitalizeString(t('GENERAL.lastName'))}*</SubTitle>
              <Box sx={{ margin: '0px 0px 5px' }}>
                <RegularInput
                  customStyle={{ fontSize: '15px', width: '100%' }}
                  error={error && value.lastName === ''}
                  inputInvalid={setError}
                  onChange={(event: any) =>
                    setValue({ ...value, lastName: event })
                  }
                />
              </Box>
            </Grid>
          </Grid>
          <SubTitle>{capitalizeString(t('GENERAL.email'))}*</SubTitle>
          <Box sx={{ margin: '0px 0px 5px' }}>
            <RegularInput
              customStyle={{ fontSize: '15px', width: '100%' }}
              error={error && value.email === ''}
              inputInvalid={setError}
              onChange={(event: any) => setValue({ ...value, email: event })}
              type="email"
            />
          </Box>
          <SubTitle>{capitalizeString(t('GENERAL.phoneNumber'))}*</SubTitle>
          <Box sx={{ margin: '0px 0px 5px' }}>
            <RegularInput
              customStyle={{ fontSize: '15px', width: '100%' }}
              error={error && value.phoneNumber === ''}
              inputInvalid={setError}
              onChange={(event: any) =>
                setValue({ ...value, phoneNumber: event })
              }
              type="phone"
            />
          </Box>
          <Grid container spacing={2}>
            <Grid item sm={6} xs={12}>
              <SubTitle>{capitalizeString(t('INVITATION.rate'))}*</SubTitle>
              <Box sx={{ margin: '0px 0px 5px' }}>
                <RegularInput
                  float
                  customStyle={{ fontSize: '15px', width: '100%' }}
                  error={error && value.rate === ''}
                  inputInvalid={setError}
                  onChange={(event: any) =>
                    setValue({
                      ...value,
                      rate: parseFloat(parseFloat(event).toFixed(2))
                    })
                  }
                  placeholder="1.00"
                  type="number"
                />
              </Box>
            </Grid>
            <Grid item sm={6} xs={12}>
              <SubTitle>{capitalizeString(t('INVITATION.jobTitle'))}*</SubTitle>
              <Box sx={{ margin: '0px 0px 5px' }}>
                <RegularInput
                  customStyle={{ fontSize: '15px', width: '100%' }}
                  error={error && value.jobTitle === ''}
                  inputInvalid={setError}
                  onChange={(event: any) =>
                    setValue({ ...value, jobTitle: event })
                  }
                />
              </Box>
            </Grid>
          </Grid>
          {type === 'user' && roles && roles.length > 0 && (
            <>
              <SubTitle>{capitalizeString(t('INVITATION.roles'))}*</SubTitle>
              <Box sx={{ margin: '0px 0px 5px' }}>
                <AutoCompleteInput
                  multiple
                  onChange={(event: any, newValue: any | null) => {
                    setValuesAutocomplete(newValue);
                  }}
                  options={roles}
                  renderInput={(params) => (
                    <TextField
                      error={value.roles && value.roles.length === 0 && error}
                      placeholder={
                        value.roles && value.roles.length === 0
                          ? capitalizeString('roles')
                          : ''
                      }
                      sx={{
                        '.MuiOutlinedInput-notchedOutline': {
                          borderStyle: 'hidden'
                        },
                        borderStyle: 'hidden',
                        fontSize: '15px'
                      }}
                      {...params}
                    />
                  )}
                  value={valuesAutocomplete}
                />
              </Box>
              {value.roleIds.includes(
                roles?.find((role) => role.name === ROLES.TECHNICIAN)?.id
              ) && (
                <>
                  <SubTitle>
                    {capitalizeString(t('INVITATION.skills'))}*
                  </SubTitle>
                  <Box sx={{ margin: '0px 0px 5px' }}>
                    {skillsLoading && <Loader customColor="primary" />}
                    {!skillsLoading && skills && (
                      <AutoCompleteInput
                        multiple
                        onChange={(event: any, newValue: any | null) => {
                          setValuesAutocompleteSkills(newValue);
                          setCanCreate(true);
                        }}
                        options={skills}
                        renderInput={(params) => (
                          <TextField
                            error={
                              value.skills && value.skills.length === 0 && error
                            }
                            placeholder={
                              value.skills && value.skills.length === 0
                                ? capitalizeString('skills')
                                : ''
                            }
                            sx={{
                              '.MuiOutlinedInput-notchedOutline': {
                                borderStyle: 'hidden'
                              },
                              borderStyle: 'hidden',
                              fontSize: '15px'
                            }}
                            {...params}
                          />
                        )}
                        value={valuesAutocompleteSkills}
                      />
                    )}
                  </Box>
                </>
              )}
            </>
          )}
        </Box>
      );
    }
    return <Loader customColor="primary" customSize={48} />;
  };

  const getCompanyInviteContent = () => (
    <Box sx={{ marginTop: '30px' }}>
      <SubTitle>{capitalizeString(t('INVITATION.companyName'))}*</SubTitle>
      <Box sx={{ margin: '0px 0px 5px' }}>
        <RegularInput
          customStyle={{ fontSize: '15px', width: '100%' }}
          error={error && value.companyName === ''}
          inputInvalid={setError}
          onChange={(event: any) => setValue({ ...value, companyName: event })}
        />
      </Box>
      <SubTitle>
        {capitalizeString(t('INVITATION.companyDescription'))}*
      </SubTitle>
      <Box sx={{ margin: '0px 0px 5px' }}>
        <RegularInput
          multiline
          customStyle={{ fontSize: '15px', width: '100%' }}
          error={error && value.companyDescription === ''}
          inputInvalid={setError}
          onChange={(event: any) =>
            setValue({ ...value, companyDescription: event })
          }
        />
      </Box>
      <SubTitle>
        {`${capitalizeString(t('INVITATION.administrator'))} ${capitalizeString(
          t('GENERAL.email')
        )}`}
        *
      </SubTitle>
      <Box sx={{ margin: '0px 0px 5px' }}>
        <RegularInput
          customStyle={{ fontSize: '15px', width: '100%' }}
          error={error && value.administratorEmail === ''}
          inputInvalid={setError}
          onChange={(event: any) =>
            setValue({ ...value, administratorEmail: event })
          }
          type="email"
        />
      </Box>
      <SubTitle>
        {`${capitalizeString(t('INVITATION.administrator'))} ${capitalizeString(
          t('INVITATION.firstName')
        )}`}
        *
      </SubTitle>
      <Box sx={{ margin: '0px 0px 5px' }}>
        <RegularInput
          customStyle={{ fontSize: '15px', width: '100%' }}
          error={error && value.administratorFirstName === ''}
          inputInvalid={setError}
          onChange={(event: any) =>
            setValue({ ...value, administratorFirstName: event })
          }
        />
      </Box>
      <SubTitle>
        {`${capitalizeString(t('INVITATION.administrator'))} ${capitalizeString(
          t('INVITATION.lastName')
        )}`}
        *
      </SubTitle>
      <Box sx={{ margin: '0px 0px 5px' }}>
        <RegularInput
          customStyle={{ fontSize: '15px', width: '100%' }}
          error={error && value.administratorLastName === ''}
          inputInvalid={setError}
          onChange={(event: any) =>
            setValue({ ...value, administratorLastName: event })
          }
        />
      </Box>
      <Box width="100%">
        <SubTitle>{`${capitalizeString(t('INVITATION.plan'))}`}*</SubTitle>
        <SelectInput
          onChange={(event: any) => {
            setValue({ ...value, plan: event.target.value });
          }}
          value={value?.plan}
        >
          {planOptions.map((item: any) => {
            return (
              <MenuItem key={item} value={item}>
                <Typography variant="primary">{item}</Typography>
              </MenuItem>
            );
          })}
        </SelectInput>
      </Box>
    </Box>
  );

  const beforeLeave = () => {
    let banLeave = 0;

    Object.keys(value).forEach((key: any) => {
      if (
        (Array.isArray(value[key]) && value[key].length > 0) ||
        (value[key] && !Array.isArray(value[key]) && value[key] !== '')
      ) {
        banLeave = 1;
      }
    });

    if (
      banLeave &&
      setFireConfirmExit &&
      setFireConfirmExit instanceof Function
    ) {
      setFireConfirmExit(true);
    } else {
      handleClose();
    }
  };

  //= ================================================== USEEFFECTS

  useEffect(() => {
    setOpen(openValue);
  }, [openValue]);

  useEffect(() => {
    if (createError) {
      setCreate(false);
      if (createData?.status === 201) {
        handleClose();
      } else {
        setError(true);
        setErrorMsg(
          createError.response.data.message ||
            'There was an error with the server'
        );
      }
    }
  }, [createError]);

  useEffect(() => {
    if (createData) {
      setCreate(false);
      mutateAction();
      if (type === 'company') {
        addNotification(
          t('NOTIFICATION.TOAST.createCompany', { service: type }),
          'success'
        );
      }
      setTimeout(() => {
        handleClose();
      }, 500);
    }
  }, [createData]);

  useEffect(() => {
    if (valuesAutocomplete) {
      setValue({ ...value, roleIds: valuesAutocomplete.map((i) => i.id) });
    }
  }, [valuesAutocomplete]);

  useEffect(() => {
    if (valuesAutocompleteSkills) {
      setValue({
        ...value,
        skillIds: valuesAutocompleteSkills.map((i) => i.id)
      });
    }
  }, [valuesAutocompleteSkills, createDataSkill]);

  useEffect(() => {
    setValue(type === 'user' ? user : company);
  }, []);

  useEffect(() => {
    if (fireClose) {
      handleClose();
      setFireClose(false);
      setFireConfirmExit(false);
    }
  }, [fireClose]);

  useEffect(() => {
    if (createErrorSkill) {
      setCreate(false);
    }
  }, [createErrorSkill]);

  return (
    <>
      <Modal
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500
        }}
        onClose={beforeLeave}
        open={open}
        sx={{ maxHeight: '100%' }}
      >
        <Fade in={openValue}>
          <ModalContainer>
            <SectionContainer>
              <Typography
                sx={{
                  fontSize: '24px',
                  fontWeight: 'bolder',
                  variant: 'title'
                }}
              >
                {`${capitalizeString(t('MODAL.create'))} ${capitalizeString(
                  type
                )} ${capitalizeString(t('INVITATION.invitation'))}`}
              </Typography>
              <HighlightOffOutlinedIcon
                onClick={beforeLeave}
                sx={{ color: 'mainText.main', cursor: 'pointer' }}
              />
            </SectionContainer>
            <Box
              sx={{
                height: '75%',
                marginTop: 3
              }}
            >
              <Scroll>
                <>
                  {type === 'user' && getUserInviteContent()}
                  {type !== 'user' && getCompanyInviteContent()}
                  {getError()}
                </>
              </Scroll>
            </Box>
            <SectionContainer>
              <ServiceButton
                color="error"
                onClick={() => {
                  handleClose();
                  setError(false);
                }}
                variant="contained"
              >
                <Typography>{t('MODAL.cancel')}</Typography>
              </ServiceButton>
              <ServiceButton
                color="success"
                onClick={() => handleCreate()}
                variant="contained"
              >
                <Typography>{t('MODAL.send')}</Typography>
              </ServiceButton>
            </SectionContainer>
          </ModalContainer>
        </Fade>
      </Modal>
      {fireConfirmExit && (
        <ConfirmModal
          modalText={t('MODAL.confirmModalExitTask')}
          modalType="user"
          setSentFlag={setShowOkModal}
          setShowModal={setFireConfirmExit}
          setShowOkModal={setFireClose}
        />
      )}
    </>
  );
};

InviteModal.defaultProps = {
  roles: []
};

export default InviteModal;
