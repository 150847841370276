import React, { useState } from 'react';
import { Box } from '@mui/system';
import { Button, Collapse, Typography, useTheme } from '@mui/material';
import { CircleRounded } from '@mui/icons-material';
import {
  Service,
  Task,
  Checklist
} from '../../../../../utils/types/ClientServiceDetail';
import blueBerryLogoLeters from '../../../../../assets/images/bluberry.png';
import blueBerryLogoMini from '../../../../../assets/images/bluberry_logo_mini.png';

interface Props {
  service: Service;
}

const ServiceDetail: React.FC<Props> = function ServiceDetail({ service }) {
  const theme = useTheme();
  const [currentTask, setCurrentTask] = useState<number>();

  const getBorderCustom = (index: number, total: number) => {
    if (total === 1) {
      return '16px 16px 16px 16px';
    }
    if (index === 0) {
      return '16px 16px 0 0';
    }
    if (index + 1 === total) {
      return '0 0 16px 16px';
    }
    return 0;
  };

  const taskWithChecklist = (task: Task | undefined) => {
    if (!task?.checklist) return false;
    return task?.checklist?.length > 0;
  };

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
      <Typography variant="primary-bold">{service?.name}</Typography>

      <Box>
        {service?.tasks?.map((task: Task, index: number) => (
          <Box
            key={task?.id}
            sx={{
              border: `1px solid ${theme.palette.divider}`,
              borderRadius: getBorderCustom(index, service?.tasks?.length),
              display: 'flex',
              flexDirection: 'column',
              overflow: 'hidden'
            }}
          >
            <Box
              sx={{
                alignItems: 'center',
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
                padding: 2
              }}
            >
              <Typography
                color="secondary.400"
                fontSize={14}
                variant="secondary"
              >
                {task?.name}
              </Typography>
              {taskWithChecklist(task) && (
                <Button
                  onClick={() => {
                    if (index === currentTask) {
                      setCurrentTask(undefined);
                    } else {
                      setCurrentTask(index);
                    }
                  }}
                >
                  <i
                    className="custom-icon icon-select"
                    style={{
                      fontSize: 12,
                      transform: `rotate(${
                        currentTask === index ? '180deg' : '0deg'
                      })`
                    }}
                  />
                </Button>
              )}
            </Box>

            {taskWithChecklist(task) && (
              <Collapse in={currentTask === index} orientation="vertical">
                <Box
                  sx={{
                    backgroundColor: theme.palette.background.secondPaper,
                    display: 'flex',
                    flexDirection: 'column',
                    gap: 1,
                    padding: 4
                  }}
                >
                  {task?.checklist?.map((item: Checklist) => (
                    <Typography
                      color="secondary.500"
                      key={item.id}
                      variant="body_200"
                    >
                      <Box
                        sx={{
                          alignItems: 'center',
                          display: 'flex',
                          flexDirection: 'row',
                          gap: 1
                        }}
                      >
                        <CircleRounded sx={{ fontSize: 6 }} />
                        {item?.name}
                      </Box>
                    </Typography>
                  ))}
                </Box>
              </Collapse>
            )}
          </Box>
        ))}
      </Box>
    </Box>
  );
};

export default ServiceDetail;
