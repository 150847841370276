/* eslint-disable react/jsx-props-no-spreading */
import React, { useState } from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import { styled } from '@mui/material/styles';

const ImageWrapper = styled('div')({
  borderRadius: 4,
  display: 'inline-block',
  height: 180,
  overflow: 'hidden',
  position: 'relative',
  width: '100%'
});

const StyledImage = styled('img')({
  '&:hover': {
    transform: 'scale(1.05)',
    transition: 'transform 0.2s ease-in-out'
  },
  cursor: 'pointer',
  display: 'block',
  height: '100%',
  objectFit: 'cover',
  transition: 'opacity 0.3s ease-in-out',
  width: '100%'
});

interface LazyImageProps extends React.ImgHTMLAttributes<HTMLImageElement> {
  alt: string;
  src: string;
}

function LazyImage({ src, alt, ...rest }: LazyImageProps) {
  const [loading, setLoading] = useState(true);

  return (
    <ImageWrapper>
      {loading && (
        <CircularProgress
          size={24}
          style={{
            left: '50%',
            position: 'absolute',
            top: '50%',
            transform: 'translate(-50%, -50%)'
          }}
        />
      )}
      <StyledImage
        alt={alt}
        onError={() => setLoading(false)}
        onLoad={() => setLoading(false)}
        src={src}
        style={{ opacity: loading ? 0 : 1 }}
        {...rest}
      />
    </ImageWrapper>
  );
}

export default LazyImage;
