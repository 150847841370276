import React, { useEffect } from 'react';
import '../../style/App.scss';
import { Box, Button, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import useNavigationStore from '../../store/zustand/navigation';
import logo from '../../assets/images/Bluberry_Logo.png';

const MobileRestriction: React.FC = function MobileRestriction() {
  const navigation = useNavigationStore((state) => state);
  const navigate = useNavigate();
  const { t } = useTranslation();

  // ====================================================== LIFECYCLE
  useEffect(() => {
    navigation.setCurrentStepScreen('noAuthorized');
  }, []);

  const handleLogOut = () => {
    setTimeout(() => {
      navigate('/login');
    }, 500);
  };

  return (
    <Box
      alignItems="center"
      bgcolor="#f9f9f9"
      display="flex"
      flexDirection="column"
      height="100vh"
      justifyContent="flex-start"
      padding={4}
      textAlign="center"
    >
      <img
        alt="logo"
        className="logo-image"
        src={logo}
        style={{ marginBottom: '100px', width: '150px' }}
      />
      <ErrorOutlineIcon sx={{ color: 'grey', fontSize: 80, mb: 2 }} />
      <Typography
        paragraph
        color="textSecondary"
        sx={{ mt: 2 }}
        variant="body_200"
      >
        {t('NO_AUTHORIZED.mobileRestriction')}
      </Typography>
      <Button
        color="primary"
        onClick={handleLogOut}
        sx={{ mt: 2 }}
        variant="contained"
      >
        {t('NO_AUTHORIZED.backToLogin')}
      </Button>
    </Box>
  );
};

export default MobileRestriction;
