/* eslint-disable no-loop-func */
import {
  Box,
  Button,
  Collapse,
  Divider,
  SliderProps,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  styled,
  useTheme
} from '@mui/material';
import React, { useEffect, useState, useRef } from 'react';
import { useTranslation } from 'react-i18next';
// import useSWR from 'swr';
import { ServiceCollapse } from '../../views/leads/steps/components/ServiceCollapse';
import TableComponentEditable from '../shared/TableComponentEditable';
import {
  capitalizeString,
  getVisitInfo,
  getCurrency,
  getFrequency
} from '../../utils/general';
import {
  parserLeadCalculator,
  parserServiceCalculator,
  getServiceCost,
  getTimeCost,
  getResourceCost,
  getTotalPerVisit,
  totalValueServiceTime,
  getTravelCost,
  getCalculateLaborTaxes
} from '../../utils/calculatorHelper';
import {
  secondaryColors,
  textColors,
  themePalette
} from '../../theme/themeConfig';
import { evaluateFormula } from '../../utils/calculatorProHelper';
// import Api from '../../api';

const RowElement = styled(TableCell)<SliderProps>(({ theme }) => ({
  alignItems: 'center',
  borderBottom: 'none',
  color: theme.palette.primary.main,
  display: 'flex',
  fontSize: '18px',
  fontWeight: 'bold',
  textOverflow: 'ellipsis',
  width: '100%'
}));

interface Props {
  index: number;
  onServiceTimeCostChange: (newCost: number) => void;
  setValues: React.Dispatch<React.SetStateAction<any>>;
  totalServiceTimeCost: any;
  values: any;
}

const ServiceTimeCalculator: React.FC<Props> = function ServiceTimeCalculator({
  index,
  values,
  totalServiceTimeCost,
  setValues,
  onServiceTimeCostChange
}) {
  const { t } = useTranslation();
  const [servicesList, setServicesList] = useState<any>([]);
  const [individualTasksList, setIndividualTasksList] = useState<any>([]);
  const prevServiceTimeCostRef = useRef<number | null>(null);

  const travelTime: any =
    values.travelTime !== undefined ? values.travelTime : 0.0;
  const [open, setOpen] = useState<boolean>(false);
  const theme = useTheme();
  const getVisits = () => {
    return getVisitInfo(values).visits;
  };

  const getNumericService1Length = () => {
    return values?.availability?.generalInfo?.daysOfServiceCount || 0;
  };
  useEffect(() => {
    if (values?.services) {
      setServicesList(
        values.services?.map((service: any) => {
          return { ...service, company: values?.company };
        })
      );
    }
    if (values?.individualTasks) {
      setIndividualTasksList(values.individualTasks);
    }
  }, [values?.services, values?.individualTasks]);

  useEffect(() => {
    const newServiceTimeCost =
      getTotalPerVisit(values, travelTime) * getNumericService1Length();

    if (newServiceTimeCost !== prevServiceTimeCostRef.current) {
      onServiceTimeCostChange(newServiceTimeCost);
      prevServiceTimeCostRef.current = newServiceTimeCost;
    }
  }, [values?.services, values?.individualTasks]);

  const getServiceTimeCost = () => {
    return `${getCurrency(
      getTotalPerVisit(parserLeadCalculator(values, t), travelTime)
    )} = ${getCurrency(
      getTotalPerVisit(parserLeadCalculator(values, t), travelTime) *
        parseInt(values?.availability?.generalInfo?.daysOfServiceCount || 1, 10)
    )}`;
  };

  const transportationTime = () => {
    const travelCost = getTravelCost(values, travelTime);
    const setupCost = getTimeCost(individualTasksList, servicesList, 'setup');
    const teardownCost = getTimeCost(
      individualTasksList,
      servicesList,
      'teardown'
    );

    return [
      {
        cost: travelCost.toFixed(2),
        name: t('LEAD.CREATE.COST_CALCULATOR.travelTimeCost')
      },
      {
        cost: setupCost.toFixed(2),
        name: t('LEAD.CREATE.COST_CALCULATOR.setupTimeCost')
      },
      {
        cost: teardownCost.toFixed(2),
        name: t('LEAD.CREATE.COST_CALCULATOR.teardownTimeCost')
      }
    ];
  };

  const totalCostPerService = [
    {
      detail: (
        <Typography
          color={textColors.DARK_TEXT}
          fontWeight={700}
          variant="body_400"
        >{`${getCurrency(
          getTotalPerVisit(parserLeadCalculator(values, t), travelTime)
        )}`}</Typography>
      ),
      name: (
        <Typography
          color={textColors.DARK_TEXT}
          fontWeight={700}
          variant="body_400"
        >
          {t('LEAD.CREATE.COST_CALCULATOR.totalCostPerVisit')}
        </Typography>
      )
    }
  ];

  const summary = [
    {
      detail: (
        <Typography variant="primary-bold">
          {t('LEAD.CREATE.COST_CALCULATOR.visitsInPeriod')}
        </Typography>
      ),
      name: (
        <Typography variant="primary-bold">
          {t('LEAD.CREATE.COST_CALCULATOR.serviceFrequency')}
        </Typography>
      )
    },
    {
      detail: parseInt(
        values?.availability?.generalInfo?.daysOfServiceCount,
        10
      ),
      name: (
        <Typography variant="primary">
          {capitalizeString(getFrequency(values, t))}
        </Typography>
      )
    },
    {
      detail: (
        <Typography variant="primary-bold">{`${getCurrency(
          getTotalPerVisit(parserLeadCalculator(values, t), travelTime) *
            parseInt(values?.availability?.generalInfo?.daysOfServiceCount, 10)
        )}`}</Typography>
      ),
      name: (
        <Typography variant="primary-bold">
          {t('LEAD.CREATE.COST_CALCULATOR.grandTotal')}
        </Typography>
      )
    }
  ];

  useEffect(() => {
    if (servicesList || individualTasksList) {
      if (getVisits() === 0) {
        // setValues({
        //   ...values,
        //   serviceTimeCost: getTotalPerVisit(values, travelTime)
        // });
      } else {
        const calculatedCost =
          getTotalPerVisit(values, travelTime) * getVisits();
        if (values.serviceTimeCost !== calculatedCost) {
          setValues({
            ...values,
            serviceTimeCost: calculatedCost
          });
        }
      }
    }
  }, [values]);

  const serviceOverviewCardDivider = () => {
    return (
      <Divider
        flexItem
        orientation="vertical"
        sx={{ borderColor: secondaryColors.SECONDARY_1 }}
      />
    );
  };

  const serviceOverviewCardColum = (
    title: string,
    subtitle: string,
    center: boolean
  ) => {
    return (
      <Box
        alignItems={center ? 'center' : 'stretch'}
        display="flex"
        flexDirection="column"
        gap="8px"
      >
        <Typography color="primary.main" variant="heading_300">
          {title}
        </Typography>
        <Typography
          color={textColors.DARK_TEXT}
          fontWeight={500}
          variant="body_400"
        >
          {subtitle}
        </Typography>
      </Box>
    );
  };

  const serviceOverviewCard = () => {
    return (
      <Box
        border={`1px solid ${secondaryColors.SECONDARY_1}`}
        borderRadius="8px"
        display="flex"
        flexDirection="column"
        gap="32px"
        padding="32px 24px 32px 24px"
      >
        <Typography color={textColors.DARK_TEXT} variant="secondary-semi">
          {t('LEAD.CREATE.COST_CALCULATOR.serviceOverview')}
        </Typography>

        <Box
          alignItems="center"
          display="flex"
          gap="16px"
          height={76}
          justifyContent="space-between"
          maxWidth="90%"
        >
          {serviceOverviewCardColum(
            t('LEAD.CREATE.COST_CALCULATOR.serviceFrequency'),
            capitalizeString(getFrequency(values, t)),
            false
          )}

          {serviceOverviewCardDivider()}

          {serviceOverviewCardColum(
            t('LEAD.CREATE.COST_CALCULATOR.totalVisits'),
            values?.availability?.generalInfo?.daysOfServiceCount || 0,
            true
          )}

          {serviceOverviewCardDivider()}

          {serviceOverviewCardColum(
            t('LEAD.CREATE.COST_CALCULATOR.totalForService'),
            `${getCurrency(
              getTotalPerVisit(parserLeadCalculator(values, t), travelTime) *
                parseInt(
                  values?.availability?.generalInfo?.daysOfServiceCount,
                  10
                )
            )}`,
            true
          )}
        </Box>
      </Box>
    );
  };

  return (
    <Box
      padding="16px 24px 16px 24px"
      sx={{
        border: `1px solid ${secondaryColors.SECONDARY_1}`,
        borderRadius: '8px'
      }}
    >
      <TableHead
        sx={{
          display: 'flex',
          width: '100%'
        }}
      >
        <TableRow
          sx={{
            alignItems: 'center',
            display: 'flex',
            height: '56px',
            width: '100%'
          }}
        >
          <RowElement onClick={() => {}} padding="none">
            <Box
              alignItems="center"
              display="flex"
              justifyContent="space-between"
              width="100%"
            >
              <Typography color={textColors.DARK_TEXT} variant="secondary-semi">
                {t('LEAD.CREATE.COST_CALCULATOR.serviceTime')} #{index + 1}
              </Typography>

              <Box alignItems="center" display="flex" gap="24px">
                <Typography color="primary.main" variant="heading_300">
                  {values?.availability?.generalInfo?.daysOfServiceCount || 0}{' '}
                  {t('LEAD.CREATE.COST_CALCULATOR.visits')} x{' '}
                  {getServiceTimeCost()}
                </Typography>

                <Button onClick={() => setOpen(!open)}>
                  <i
                    className="custom-icon icon-select"
                    style={{
                      color: secondaryColors.SECONDARY_4,
                      fontSize: '14px',
                      transform: `rotate(${open ? '180deg' : '0deg'})`,
                      transitionDuration: '300ms'
                    }}
                  />
                </Button>
              </Box>
            </Box>
          </RowElement>
        </TableRow>
      </TableHead>

      {/* ----------------- COLLAPSE BODY ----------------- */}

      <Collapse in={open}>
        <Box display="flex" flexDirection="column" gap="24px" marginTop="24px">
          <Box display="flex" flexDirection="column" gap="24px">
            <Divider
              flexItem
              sx={{ borderColor: secondaryColors.SECONDARY_1 }}
            />
            {servicesList.map((service: any, index: number) => {
              return (
                <ServiceCollapse
                  editableFields={[]}
                  headKeys={['task', 'totalCost', 'calculator']}
                  manualPricing={values.manualPricing}
                  screen="calculator"
                  service={parserServiceCalculator(service, values.company, t)}
                  setService={(newValue: any) => {
                    const newService = servicesList;
                    newService[index] = newValue;
                    setValues({ ...values, services: newService });
                  }}
                />
              );
            })}
          </Box>
          {individualTasksList && individualTasksList?.length > 0 && (
            <Box>
              <ServiceCollapse
                editableFields={[]}
                headKeys={['task', 'totalCost', 'calculator']}
                manualPricing={values.manualPricing}
                screen="calculator"
                service={{
                  ...individualTasksList,
                  company: values.company,
                  name: 'Individual Tasks'
                }}
                setService={(newValue: any) => {
                  setValues({ ...values, individualTasks: newValue });
                }}
              />
            </Box>
          )}
          <TableComponentEditable
            disableAdd
            hideTableHeader
            action={() => {}}
            alignRightFields={['cost']}
            handleOpenDraw={() => {}}
            headKeys={['name', 'cost']}
            onChange={() => {}}
            valuesArray={{
              data: transportationTime()
            }}
          />

          <Box>
            <TableComponentEditable
              disableAdd
              hideTableHeader
              action={() => {}}
              alignRightFields={['detail']}
              customRowColor={`${themePalette.PAPER}`}
              handleOpenDraw={() => {}}
              headKeys={['name', 'detail']}
              onChange={() => {}}
              valuesArray={{
                data: totalCostPerService
              }}
            />
          </Box>

          {serviceOverviewCard()}
        </Box>
      </Collapse>
    </Box>
  );
};

export default ServiceTimeCalculator;
