/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { Typography, Box, useTheme } from '@mui/material';
import { useTranslation } from 'react-i18next';

interface Props {
  destination: any;
}

const DestinationCard: React.FC<Props> = function DestinationCard({
  destination
}) {
  const { t } = useTranslation();
  const { palette } = useTheme();

  return (
    <Box
      sx={{
        border: 1,
        borderColor: palette.divider,
        borderRadius: 4,
        mb: 2,
        mt: 2,
        p: 3
      }}
    >
      <Box display="flex" flexDirection="column" gap={4}>
        <Box alignItems="center" display="flex">
          <Typography
            color="primary"
            fontWeight={700}
            sx={{ width: '30%' }}
            variant="heading_300"
          >
            {t('LEAD.CREATE.SERVICES.SUMMARY.address')}:
          </Typography>
          <Typography
            color="secondary.main"
            sx={{ width: '70%' }}
            variant="body_300"
          >
            {destination?.address.name}: {destination?.address.address}
          </Typography>
        </Box>

        <Box alignItems="center" display="flex">
          <Typography
            color="primary"
            fontWeight={700}
            sx={{ width: '30%' }}
            variant="heading_300"
          >
            {t('LEAD.CREATE.SERVICES.SUMMARY.contact')}:
          </Typography>
          <Typography
            color="secondary.main"
            sx={{ width: '70%' }}
            variant="body_300"
          >
            {destination?.contact.name}: {destination?.contact.phoneNumber}
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};

export default DestinationCard;
