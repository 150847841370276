import React, { useEffect, useState } from 'react';
import { Box, Button, Stack, styled, Typography } from '@mui/material';
import { Container } from '@mui/system';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import 'react-dropzone-uploader/dist/styles.css';
import useSWR, { useSWRConfig } from 'swr';
import RegularInput from '../../input/RegularInput';
import api from '../../../api';
import { secondaryColors, themePalette } from '../../../theme/themeConfig';
import ImageUpload from '../../shared/ImageUpload';

interface Props {
  isCompany: boolean;
  setErrorApi: React.Dispatch<React.SetStateAction<boolean>>;
  setIsStepperDisabled: (isDisabled: boolean) => void;
  token: string | undefined;
}

interface FormValues {
  email: string;
  firstName: string;
  lastName: string;
  phoneNumber: string;
}

const ProfileInformationContainer = styled(Stack)(() => ({
  alignItems: 'start',
  flexDirection: 'column',
  gap: '24px',
  height: 'inherit',
  width: '100%'
}));

const ProfileInformationForm = styled(Stack)(() => ({
  gap: '24px',
  width: 'inherit'
}));

const inputStyle = {
  backgroundColor: themePalette.BACKGROUND,
  border: '1px solid',
  borderColor: secondaryColors.SECONDARY_2,
  margin: 0,
  placeholderColor: secondaryColors.SECONDARY_3,
  width: '100%'
};

const SaveButton = styled(Button)(() => ({
  fontWeight: 'bold',
  width: '100%'
}));

const ProfileInfo: React.FC<Props> = function ProfileInfo({
  token,
  setErrorApi,
  isCompany,
  setIsStepperDisabled
}) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [values, setValues] = useState<FormValues>({
    email: '',
    firstName: '',
    lastName: '',
    phoneNumber: ''
  });
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [error, setError] = useState<boolean>(false);
  const [fireProfile, setFireProfile] = useState<boolean>(true);
  const [fireUpdateProfile, setFireUpdateProfile] = useState<boolean>(false);
  const [image, setImage] = useState<Array<any>>([]);
  const { mutate: updateTimezoneForCurrentUserMutate } = useSWRConfig();
  const [isDisabled, setIsDisabled] = useState<boolean>(true);

  const { data: profileUpdated, error: errorProfileUpdated } = useSWR(
    fireUpdateProfile ? { ...values, photo: image?.[0]?.file } : null,
    api.authApi.setUpdateProfile
  );

  const {
    data: profileInfo,
    error: errorProfileInfo,
    mutate
  } = useSWR(fireProfile ? '/profile' : null, api.authApi.getProfile);

  const handleChange = (prop: keyof FormValues) => (value: string) => {
    setValues({ ...values, [prop]: value === '' ? undefined : value });
  };

  const getError = () =>
    !error ? (
      false
    ) : (
      <Container sx={{ mt: 1 }}>
        <Typography
          color="alert.main"
          sx={{ fontSize: 14 }}
          variant="primary-bold"
        >
          &bull; {errorMessage}
        </Typography>
      </Container>
    );

  const next = () => {
    if (values.email === '') {
      setError(true);
      setErrorMessage('Email is required');
      return;
    }
    setFireUpdateProfile(true);
  };

  const shareLabelProps = {
    mt: 1
  };

  useEffect(() => {
    setErrorApi(false);
  }, []);

  useEffect(() => {
    if (profileInfo) {
      setFireProfile(false);
      setValues({
        email: profileInfo.email,
        firstName: profileInfo.firstName,
        lastName: profileInfo.lastName,
        phoneNumber: profileInfo.phoneNumber
      });
    }
  }, [profileInfo]);

  useEffect(() => {
    if (errorProfileInfo) {
      setErrorApi(true);
      setFireProfile(false);
    }
  }, [errorProfileInfo]);

  useEffect(() => {
    if (errorProfileUpdated) {
      setError(true);
      setFireUpdateProfile(false);
      const { data } = errorProfileUpdated.response;
      setErrorMessage(data.message);
    }
  }, [errorProfileUpdated]);

  useEffect(() => {
    // Update timezone for user when entering in the screen after set his password
    updateTimezoneForCurrentUserMutate(
      '/profile/timezone/current',
      api.authApi.updateTimezoneForCurrentUser
    );
    setFireProfile(true);
  }, []);

  useEffect(() => {
    if (profileUpdated) {
      setFireUpdateProfile(false);
      if (profileUpdated.error) {
        setError(true);
        setErrorMessage(`${(t('ERROR.updateError'), ['Profile'])}`);
      } else if (isCompany) {
        navigate(`/company/invitation/accept-invitation/${token}/4`, {
          state: { ...profileUpdated }
        });
      } else {
        mutate(profileUpdated);
        navigate(`/invitation/accept-invitation/${token}/5`, {
          state: { ...profileUpdated }
        });
      }
    }
  }, [profileUpdated]);

  useEffect(() => {
    if (
      values.email === '' ||
      values.firstName === '' ||
      values.lastName === '' ||
      values.phoneNumber === ''
    ) {
      setIsDisabled(true);
      setIsStepperDisabled(true);
    } else {
      setIsDisabled(false);
      setIsStepperDisabled(false);
    }
  }, [values]);

  return (
    <ProfileInformationContainer>
      <Stack gap="8px">
        <Typography
          color="primary"
          textTransform="capitalize"
          variant="heading_500"
        >
          {t('LOGIN.completeYourInformation')}
        </Typography>
        <Typography color="secondary" variant="body_200">
          {t('LOGIN.completeYourInformationDescription')}
        </Typography>
      </Stack>

      <ProfileInformationForm>
        <Box>
          <Typography
            color={`${error ? 'alert.main' : 'primary'}`}
            sx={shareLabelProps}
            variant="primary-bold"
          >
            {t('GENERAL.email')}
          </Typography>
          <RegularInput
            customStyle={{ ...inputStyle }}
            onChange={handleChange('email')}
            value={values.email}
          />
        </Box>
        <Box>
          <Typography
            color={`${error ? 'alert.main' : 'primary'}`}
            sx={shareLabelProps}
            variant="primary-bold"
          >
            {t('GENERAL.firstName')}
          </Typography>
          <RegularInput
            customStyle={{ ...inputStyle }}
            error={error}
            onChange={handleChange('firstName')}
            value={values.firstName}
          />
        </Box>
        <Box>
          <Typography
            color={`${error ? 'alert.main' : 'primary'}`}
            sx={shareLabelProps}
            variant="primary-bold"
          >
            {t('GENERAL.lastName')}
          </Typography>
          <RegularInput
            customStyle={{ ...inputStyle }}
            error={error}
            onChange={handleChange('lastName')}
            value={values.lastName}
          />
        </Box>

        <Box>
          <Typography
            color={`${error ? 'alert.main' : 'primary'}`}
            sx={shareLabelProps}
            variant="primary-bold"
          >
            {t('GENERAL.phoneNumber')}
          </Typography>
          <RegularInput
            customStyle={{ ...inputStyle }}
            error={error}
            onChange={handleChange('phoneNumber')}
            type="phone"
            value={values.phoneNumber}
          />
        </Box>
        {getError()}
        <Container>
          {image?.[0]?.fileUrl && (
            <Box
              style={{
                alignItems: 'center',
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'center',
                marginBottom: 10,
                zIndex: 100
              }}
            >
              <img
                alt="profile"
                src={image[0].fileUrl}
                style={{
                  borderRadius: 70,
                  height: 140,
                  width: 140
                }}
              />
            </Box>
          )}

          <ImageUpload
            callApiOnComplete={false}
            multiple={!isCompany}
            parent="profile"
            setFiles={setImage}
            type="image"
          />
        </Container>
      </ProfileInformationForm>
      <SaveButton
        disabled={isDisabled}
        onClick={next}
        sx={{ fontWeight: 'bold' }}
        variant="primary"
      >
        {t('GENERAL.saveButton')}
      </SaveButton>
    </ProfileInformationContainer>
  );
};

export default ProfileInfo;
