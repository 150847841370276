import React, { useState, useEffect } from 'react';
import { Middleware, SWRConfig, SWRHook } from 'swr';
import { useNavigate } from 'react-router-dom';
import { Bounce, ToastContainer, toast } from 'react-toastify';
import useAuthStore from './store/zustand/auth';
import 'mapbox-gl/dist/mapbox-gl.css';
import './style/App.scss';
import AuthenticatedApp from './routes/AuthenticatedApp';
import UnauthenticatedApp from './routes/UnauthenticatedApp';
import appConfig from './config';
import { localCache } from './utils/cache';
import { Message } from './types';
import useNotificationsStore from './store/zustand/notifications';

const App: React.FC = function App() {
  const { isAuthenticated, logout } = useAuthStore((state) => state);
  const { addNotification } = useNotificationsStore((state) => state);
  const navigate = useNavigate();

  const swrMiddleware: Middleware =
    (useSWRNext: SWRHook) => (key, fetcher, config) => {
      // action before runs a swr
      const swr = useSWRNext(key, fetcher, config);
      // action after runs a swr hook
      return swr;
    };

  const apiErrorHandling = (error: any) => {
    if (typeof error === 'string') {
      addNotification(error, 'error');
    }
    const response = error?.response || error;
    console.log('==>> GLOBAL ERROR HANLDING', error, response?.status);
    const { data } = response || {};
    if (response && response.status === 401) {
      if (data?.requestChangePassword) {
        setTimeout(() => {
          navigate('/changePassword');
        }, 2000);
        addNotification(data?.message, 'warning');
      }
      if (data?.userBlock) {
        addNotification(data?.message, 'error');
      }
      logout();
      if (data?.noAuthorized) {
        navigate('/no-authorized');
        return;
      }
      setTimeout(() => {
        navigate('/login');
      }, 500);
      throw data?.message || 'authentication error';
    } else if (response?.status === 500) {
      if (data?.message !== '') {
        addNotification(data?.message, 'error');
      }
    } else if (error && error.code && error.code === 'ERR_NETWORK') {
      // handling error for network
    }
  };

  return (
    <SWRConfig
      value={{
        onError: apiErrorHandling,
        provider: () => localCache,
        use: [swrMiddleware]
      }}
    >
      {!isAuthenticated ? <UnauthenticatedApp /> : <AuthenticatedApp />}
      <ToastContainer
        draggable
        newestOnTop
        pauseOnFocusLoss
        pauseOnHover
        autoClose={5000}
        closeOnClick={false}
        hideProgressBar={false}
        limit={20}
        position="bottom-right"
        rtl={false}
        theme="light"
        transition={Bounce}
      />
    </SWRConfig>
  );
};

export default App;
