import React, { useState } from 'react';
import { Button, Stack, styled, Typography } from '@mui/material';
import { t } from 'i18next';
import ChevronRightRoundedIcon from '@mui/icons-material/ChevronRightRounded';
import { isMobile } from 'react-device-detect';
import { useNavigate } from 'react-router-dom';
import BasePageMFA from '../../shared/mfaForms/BasePageMFA';

interface Props {
  isCompany: boolean;
  login: () => void;
  setErrorApi: React.Dispatch<React.SetStateAction<boolean>>;
  setUser: (data: any) => void;
  token: string | undefined;
}

interface SetUpMFAStepOneProps {
  onClickSetUp: () => any;
  onClickSkip: () => any;
}

const MfaRegistrationContainer = styled(Stack)(() => ({
  flexDirection: 'column',
  height: 'inherit',
  justifyContent: 'space-between',
  width: '100%'
}));

const CustomButton = styled(Button)(() => ({
  fontWeight: 'bold',
  textTransform: 'capitalize',
  width: '100%'
}));

const SkipButton = styled(Button)(() => ({
  alignItems: 'center',
  display: 'flex',
  fontWeight: 'bold',
  gap: '4px',
  width: '100%'
}));

function SetUpMfaStepOne({ onClickSetUp, onClickSkip }: SetUpMFAStepOneProps) {
  return (
    <>
      <Stack gap="24px">
        <Stack gap="8px">
          <Typography color="primary" variant="heading_500">
            {t('LOGIN.setUpMfa')}
          </Typography>
          <Typography color="secondary" variant="body_200">
            {t('LOGIN.setUpMfaDescriptionOne')}
          </Typography>
          <Typography color="secondary" variant="body_200">
            {t('LOGIN.setUpMfaDescriptionTwo')}
          </Typography>
        </Stack>

        <CustomButton onClick={onClickSetUp} variant="primary">
          {t('GENERAL.setUpButton')}
        </CustomButton>
      </Stack>
      <SkipButton onClick={onClickSkip}>
        {t('GENERAL.skipButton')}
        <ChevronRightRoundedIcon />
      </SkipButton>
    </>
  );
}

const MfaRegistration: React.FC<Props> = function MfaRegistration({
  token,
  setErrorApi,
  login,
  isCompany,
  setUser
}) {
  const [showMFA, setShowMFA] = useState<boolean>(false);
  const navigate = useNavigate();

  const handleSkip = () => {
    navigate(`/company/invitation/accept-invitation/${token}/7`);
  };

  return (
    <>
      <MfaRegistrationContainer>
        <SetUpMfaStepOne
          onClickSetUp={() => setShowMFA(!showMFA)}
          onClickSkip={handleSkip}
        />
      </MfaRegistrationContainer>
      {showMFA && (
        <BasePageMFA
          isCompany={isCompany}
          isMobile={isMobile}
          login={login}
          setErrorApi={setErrorApi}
          setUser={setUser}
          token={token}
        />
      )}
    </>
  );
};

export default MfaRegistration;
