import { Box, BoxProps, styled, Typography, useTheme } from '@mui/material';
import { useTranslation } from 'react-i18next';
import React, { FC } from 'react';
import { isMobile } from 'react-device-detect';
import {
  FlexContainer,
  FormLabel,
  WhiteBoxContainer
} from '../../../../components/form/parts';
import RegularInput from '../../../../components/input/RegularInput';
import SortableTable from '../../../../components/list/sortableTable';

const FlexBox = styled(Box)<BoxProps>(() => ({
  display: 'flex'
}));

interface props {
  setValues: React.Dispatch<React.SetStateAction<any>>;
  values: any;
}

const ServiceLocationDetails: React.FC<props> =
  function ServiceLocationDetails({ setValues, values }) {
    // Hooks
    const theme = useTheme();
    const { t } = useTranslation('translation', {
      // Feel free to create additional hooks if you need translations from a different keyPrefix...
      keyPrefix: 'CLIENT_ONBOARDING'
    });

    // Constants
    const inputFields: { key: string; required?: boolean }[] = [
      {
        key: 'addressNickname'
      },
      {
        key: 'address'
      }
    ];

    const customStyles = {
      margin: '34px 0px',
      padding: 0,
      width: '100%'
    };

    return (
      <FlexBox
        flexDirection="column"
        sx={{
          gap: theme.spacing(3),
          width: '100%'
        }}
      >
        {/* Title */}
        <Typography
          alignSelf="flex-start"
          color="primary.400"
          sx={{ fontSize: '28px', lineHeight: '30px' }}
          textAlign="center"
          variant="primary-bold"
          whiteSpace="break-spaces"
        >
          {t('serviceLocationTitle')}
        </Typography>

        {/* Subtitle */}
        <Typography alignSelf="flex-start">
          {t('serviceLocationDescription')}
        </Typography>

        {/* Input fields */}
        <WhiteBoxContainer customStyles={customStyles} isMobile={isMobile}>
          <FlexContainer>
            {/* ---- Client Addresses ---- */}
            <Box sx={{ marginTop: '8px' }}>
              <Box sx={{ display: 'flex', marginTop: '4px' }}>
                <SortableTable
                  addManagement
                  disableSort
                  isAddress
                  billingAddress={values.billingAddress}
                  bodyKeys={['name', 'phone', 'address']}
                  handleDelete={() => {}}
                  handleInfoModal={(val: any) => {}}
                  name="addresses"
                  onChange={(newAddresses: any) => {
                    setValues({
                      ...values,
                      addresses: newAddresses
                    });
                  }}
                  type="addresses"
                  values={values.addresses}
                />
              </Box>
            </Box>
          </FlexContainer>
        </WhiteBoxContainer>
      </FlexBox>
    );
  };

export default ServiceLocationDetails;
