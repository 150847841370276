import { Box, Button, Stack, styled, Typography } from '@mui/material';
import { t } from 'i18next';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import useSWR from 'swr';
import { secondaryColors, themePalette } from '../../../theme/themeConfig';
import RegularInput from '../../input/RegularInput';
import api from '../../../api';

const CompanySettingsContainer = styled(Stack)(() => ({
  alignItems: 'start',
  flexDirection: 'column',
  gap: '24px',
  height: 'inherit',
  width: '100%'
}));

const SaveButton = styled(Button)(() => ({
  fontWeight: 'bold',
  width: '100%'
}));

interface Props {
  setIsStepperDisabled: (isDisabled: boolean) => void;
  token: string | undefined;
}

interface FormValues {
  companyDescription: string;
  companyName: string;
}

function CompanySettings({ token, setIsStepperDisabled }: Props) {
  const [values, setValues] = useState<FormValues>({
    companyDescription: '',
    companyName: ''
  });
  const [isDisabled, setIsDisabled] = useState<boolean>(true);
  const [companyApi, setCompanyApi] = useState<boolean>(true);
  const [companyUpdateApi, setCompanyUpdateApi] = useState<boolean>(false);
  const navigate = useNavigate();

  const inputStyle = {
    backgroundColor: themePalette.BACKGROUND,
    border: '1px solid',
    borderColor: secondaryColors.SECONDARY_2,
    margin: 0,
    placeholderColor: secondaryColors.SECONDARY_3,
    width: '100%'
  };

  const {
    data: companyData,
    error: companyError,
    mutate: companyMutate
  } = useSWR(
    companyApi ? 'current-company' : null,
    api.CompanyApi.getCurrentCompany
  );

  const { data: companyUpdateData, error: companyUpdateError } = useSWR(
    companyUpdateApi ? ['update/company', values] : null,
    api.CompanyApi.updateCurrentCompany
  );

  const handleChange = (prop: keyof any) => (value: string) => {
    setValues({ ...values, [prop]: value === '' ? undefined : value });
  };

  const handleNext = () => {
    setCompanyUpdateApi(true);
  };

  useEffect(() => {
    if (values.companyName === '' || values.companyName === undefined) {
      setIsDisabled(true);
      setIsStepperDisabled(true);
    } else {
      setIsDisabled(false);
      setIsStepperDisabled(false);
    }
  }, [values.companyName]);

  useEffect(() => {
    if (companyData) {
      setCompanyApi(false);
      setValues({
        companyDescription: companyData?.companyDescription || '',
        companyName: companyData?.companyName || ''
      });
    }
  }, [companyData]);

  useEffect(() => {
    if (companyUpdateData) {
      setCompanyUpdateApi(false);
      navigate(`/company/invitation/accept-invitation/${token}/5`);
    }
  }, [companyUpdateData]);

  useEffect(() => {
    return () => {
      companyMutate(undefined, true);
    };
  }, []);

  return (
    <CompanySettingsContainer>
      <Stack gap="8px">
        <Typography
          color="primary"
          textTransform="capitalize"
          variant="heading_500"
        >
          {t('LOGIN.companySettings')}
        </Typography>
        <Typography color="secondary" variant="body_200">
          {t('LOGIN.companySettingsDescription')}
        </Typography>
      </Stack>

      <Stack gap="24px" width="inherit">
        <Box>
          <Stack direction="row" gap="4px" width="inherit">
            <Typography color="primary" component="span" variant="primary-bold">
              {t('LOGIN.companyName')}
            </Typography>
            <Typography
              color="alert.main"
              component="span"
              variant="primary-bold"
            >
              *
            </Typography>
          </Stack>
          <RegularInput
            customStyle={{ ...inputStyle }}
            onChange={handleChange('companyName')}
            value={values.companyName}
          />
        </Box>
        <Box>
          <Typography color="primary" variant="primary-bold">
            {t('LOGIN.companyDescriptionInput')}
          </Typography>
          <RegularInput
            customStyle={{ ...inputStyle }}
            onChange={handleChange('companyDescription')}
            value={values.companyDescription}
          />
        </Box>
      </Stack>

      <SaveButton
        disabled={isDisabled}
        onClick={handleNext}
        sx={{ fontWeight: 'bold' }}
        variant="primary"
      >
        {t('GENERAL.saveButton')}
      </SaveButton>
    </CompanySettingsContainer>
  );
}

export default CompanySettings;
