import { useEffect, useState } from 'react';
import styled from '@emotion/styled';
import { Box, IconButton, MobileStepper } from '@mui/material';
import { KeyboardArrowLeft, KeyboardArrowRight } from '@mui/icons-material';

export type TransitionType = 'fade' | 'slide';

export interface ImageCarouselProps {
  // Whether to allow user navigation with arrows
  allowUserInput?: boolean;
  // Custom height for the carousel
  height?: string | number;
  // Array of image URLs to display
  images: string[];
  // Time in milliseconds between automatic transitions
  interval?: number;
  // Whether to show the stepper indicator at the bottom
  showStepper?: boolean;
  // Type of transition between images
  transition?: TransitionType;
  // Custom width for the carousel
  width?: string | number;
}

const CarouselContainer = styled(Box)<{
  height?: string | number;
  width?: string | number;
}>`
  position: relative;
  width: ${({ width }) => width || '100%'};
  height: ${({ height }) => height || '100%'};
  overflow: hidden;
  border-radius: 8px;
`;

const ImageContainer = styled.div<{ transition: TransitionType }>`
  display: flex;
  height: 100%;
  transition: transform 0.5s ease-in-out;
  ${({ transition }) =>
    transition === 'slide'
      ? `& > img {
          min-width: 100%;
          flex-shrink: 0;
        }`
      : ''}
`;

const Image = styled.img<{ transition: TransitionType }>`
  width: 100%;
  height: 100%;
  object-fit: cover;
  ${({ transition }) =>
    transition === 'fade'
      ? `
        position: absolute;
        opacity: 0;
        transition: opacity 0.5s ease-in-out;
        &.active {
          opacity: 1;
        }
      `
      : ''}
`;

const NavigationButton = styled(IconButton)`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  z-index: 1;
  color: white;
  background-color: rgba(0, 0, 0, 0.3);

  &:hover {
    background-color: rgba(0, 0, 0, 0.5);
  }
`;

function ImageSlider({
  images,
  interval = 5000,
  showStepper = false,
  allowUserInput = false,
  height,
  width,
  transition = 'fade'
}: ImageCarouselProps) {
  const [activeStep, setActiveStep] = useState(0);

  useEffect(() => {
    if (interval <= 0) return;

    const timer = setInterval(() => {
      setActiveStep((prevStep) => (prevStep + 1) % images.length);
    }, interval);

    // useEffect clean up...
    // eslint-disable-next-line consistent-return
    return () => clearInterval(timer);
  }, [interval, images.length]);

  const handleNext = () => {
    setActiveStep((prevStep) => (prevStep + 1) % images.length);
  };

  const handleBack = () => {
    setActiveStep((prevStep) => (prevStep - 1 + images.length) % images.length);
  };

  return (
    <CarouselContainer height={height} width={width}>
      <ImageContainer
        style={
          transition === 'slide'
            ? {
                transform: `translateX(-${activeStep * 100}%)`
              }
            : undefined
        }
        transition={transition}
      >
        {images.map((image, index) => (
          <Image
            alt={`slide-${index}`}
            className={
              transition === 'fade' && index === activeStep ? 'active' : ''
            }
            // eslint-disable-next-line react/no-array-index-key
            key={`${image}-${index}`}
            src={image}
            transition={transition}
          />
        ))}
      </ImageContainer>

      {allowUserInput && (
        <>
          <NavigationButton onClick={handleBack} sx={{ left: 8 }}>
            <KeyboardArrowLeft />
          </NavigationButton>
          <NavigationButton onClick={handleNext} sx={{ right: 8 }}>
            <KeyboardArrowRight />
          </NavigationButton>
        </>
      )}

      {showStepper && (
        <MobileStepper
          activeStep={activeStep}
          // In case you need to render actual next/previous buttons here's where...
          backButton={<div />}
          nextButton={<div />}
          position="static"
          steps={images.length}
          sx={{
            bottom: 0,
            position: 'absolute',
            width: '100%'
          }}
        />
      )}
    </CarouselContainer>
  );
}

ImageSlider.defaultProps = {
  allowUserInput: false,
  height: '100%',
  interval: 5000,
  showStepper: false,
  transition: 'slide',
  width: '100%'
};

export default ImageSlider;
