import React from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import { GLOBAL } from '../../helpers/routesConstants';
import NoAuthorized from './NoAuthorized';
import MobileRestriction from './MobileRestriction';

const NoAuthorizedView: React.FC = function NoAuthorizedView() {
  return (
    <Routes>
      <Route element={<NoAuthorized />} path={GLOBAL.DEFAULT} />
      <Route element={<NoAuthorized />} path={GLOBAL.NO_AUTHORIZED} />
      <Route element={<MobileRestriction />} path={GLOBAL.MOBILE_RESTRICTION} />
    </Routes>
  );
};

export default NoAuthorizedView;
