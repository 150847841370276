/* eslint-disable react/jsx-props-no-spreading */
import {
  Box,
  Button,
  Container,
  FormControl,
  FormControlLabel,
  RadioGroup,
  SliderProps,
  Tooltip,
  Typography,
  styled,
  useTheme
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import useSWR from 'swr';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { useLocation, useNavigate } from 'react-router-dom';
import dayjs from 'dayjs';
import { isMobile } from 'react-device-detect';
import AutoCompleteInput from '../../../components/input/AutoCompleteInput';
import { capitalizeString } from '../../../utils/general';
import { FormLabel } from '../../../components/form/parts';
import api from '../../../api';
import RegularInput from '../../../components/input/RegularInput';
import CustomRadio from '../../../components/form/CustomRadio';
import Dropzone from '../../../components/input/Dropzone';
import useNavigationStore from '../../../store/zustand/navigation';
import ManualServiceCatalogModal from '../../../components/modals/ManualServiceCatalogModal';
import Loader from '../../../components/shared/Loader';
import DatePickerTextField from './components/DatePickerTextField';

const BoxCentered = styled(Box)<SliderProps>(() => ({
  alignItems: 'center',
  display: 'flex',
  height: '100%',
  justifyContent: 'center',
  width: '100%'
}));

interface Props {
  currentLeadId: any;
  saveStepper: ({ next }: any) => void;
  sendValues: React.Dispatch<React.SetStateAction<any>>;
  setValues: React.Dispatch<React.SetStateAction<any>>;
  values: any;
}

const GeneralInformation: React.FC<Props> = function GeneralInformation({
  currentLeadId = null,
  values,
  setValues,
  saveStepper,
  sendValues
}) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { state: params } = useLocation();
  const { setConfirmModal } = useNavigationStore((state) => state);
  const [clients, setClients] = useState<Array<any>>([]);
  const [error, setError] = useState<boolean>(false);
  const [errorMsg, setErrorMsg] = useState<string>('');
  const [askClients, setAskClients] = useState<boolean>(false);
  const [showManualPricingModal, setShowManualPricingModal] =
    useState<boolean>(false);
  const [validData, setValidData] = useState<boolean>(true);
  const theme = useTheme();
  const { getClientsForLead } = api.ClientApi;

  //   SWR ==========================================================================================

  const { data: clientsData, error: clientsError } = useSWR(
    askClients ? { url: 'get/allClients/forLead' } : null,
    getClientsForLead
  );

  //   FUNCTIONS ==========================================================================================

  const getError = () =>
    error ? (
      <Container>
        <Typography
          color="alert.main"
          sx={{ fontSize: 14 }}
          variant="primary-bold"
        >
          &bull; {errorMsg}
        </Typography>
      </Container>
    ) : null;

  const autoCompleteCustom = (
    key: string,
    disabled: boolean,
    options: any[],
    localError: boolean,
    placeHolder: string,
    value: any
  ) => {
    const helper =
      (options &&
        options.length &&
        value?.id &&
        options.find((option) => option.id === value?.id)) ||
      null;
    return (
      <AutoCompleteInput
        closeOnSelect
        disabled={!!disabled}
        onChange={(event: any, newValue: any | null) => {
          if (newValue?.createValue) {
            navigate('/app/clients/new', {
              state: { ...values, lastPage: '/app/leads/new' }
            });
          } else {
            setValues({ ...values, [key]: newValue });
          }
        }}
        options={options}
        renderInput={(params) => (
          <DatePickerTextField
            error={localError}
            placeholder={placeHolder}
            {...params}
          />
        )}
        sx={{
          margin: 0
        }}
        value={helper}
      />
    );
  };

  const getClientInput = () => {
    let showAutoComplete = false;
    if (params?.clientCreated) {
      if (values.clientReady && values?.client?.id && clients?.length > 0) {
        showAutoComplete = true;
      }
    } else if (values?.client?.id || clients?.length > 0) {
      showAutoComplete = true;
    }
    if (showAutoComplete) {
      return autoCompleteCustom(
        'client',
        clients?.length === 0 || currentLeadId,
        clients?.length > 0 ? clients : [values.client],
        !values.client && error,
        capitalizeString(
          `${t('LEAD.CREATE.GENERAL_INFORMATION.select')} ${t(
            'LEAD.CREATE.GENERAL_INFORMATION.client'
          )}`
        ),
        values.client
      );
    }
    return (
      <RegularInput
        disabled
        customStyle={{
          '& .MuiInputBase-input': {
            height: 40
          },
          margin: 0
        }}
        placeholder={
          clientsData
            ? `${t(
                'GENERAL.loading',
                t('LEAD.CREATE.GENERAL_INFORMATION.client')
              )}`
            : `${t('LEAD.CREATE.GENERAL_INFORMATION.addClientFirst')}`
        }
      />
    );
  };

  function clientAndContact() {
    return (
      <Box
        style={{
          display: 'flex',
          flexDirection: 'row',
          gap: '48px',
          justifyContent: 'space-between'
        }}
      >
        <Box display="flex" flex={1} flexDirection="column" gap="4px">
          <FormLabel label={t('LEAD.CREATE.GENERAL_INFORMATION.projectName')} />
          <RegularInput
            customStyle={{
              '& .MuiInputBase-input': {
                height: 40
              },
              margin: 0
            }}
            disabled={values?.readOnly}
            onChange={(name) => setValues({ ...values, name })}
            value={values.name}
          />
        </Box>
        <Box display="flex" flex={1} flexDirection="column" gap="4px">
          <Box display="flex" flexDirection="row" gap={1}>
            <FormLabel label={t('LEAD.CREATE.GENERAL_INFORMATION.client')} />
            <Tooltip title={t('LEAD.CREATE.GENERAL_INFORMATION.clientTooltip')}>
              <Typography color="secondary" fontSize={8}>
                <i className="custom-icon icon-info" style={{ fontSize: 20 }} />
              </Typography>
            </Tooltip>
          </Box>
          {getClientInput()}
        </Box>
      </Box>
    );
  }

  function description() {
    return (
      <Box display="flex" flex={1} flexDirection="column" gap="4px">
        <FormLabel
          label={t('LEAD.CREATE.GENERAL_INFORMATION.projectDescription')}
        />
        <RegularInput
          multiline
          customStyle={{
            '& .MuiInputBase-input': {
              minHeight: 30
            },
            margin: 0
          }}
          disabled={values?.readOnly}
          onChange={(description) => setValues({ ...values, description })}
          value={values.description || ''}
        />
      </Box>
    );
  }

  function projectDuration() {
    return (
      <Box display="flex" justifyContent="space-between">
        <Box alignItems="center" display="flex" gap="16px">
          <FormLabel
            customStyle={{ marginBottom: '0px' }}
            label={t('LEAD.CREATE.GENERAL_INFORMATION.projectType')}
          />
          <FormControl disabled={values?.readOnly}>
            <RadioGroup
              row
              aria-labelledby="project-duration-radio-buttons-group"
              name="project-duration-radio-buttons-group"
              onChange={(event: any) => {
                const today = dayjs();
                setValues({
                  ...values,
                  endDate:
                    values.endDate && dayjs(values.endDate) >= today
                      ? values.endDate
                      : today,
                  projectType: event.target.value,
                  startDate:
                    values.startDate && dayjs(values.startDate) >= today
                      ? values.startDate
                      : today
                });
              }}
            >
              <Box display="flex" gap="16px">
                <FormControlLabel
                  checked={
                    values.projectType === 'Regular' ||
                    values.projectType === 'regular'
                  }
                  control={<CustomRadio />}
                  label={
                    <Typography color="mainText.200" variant="body_300">
                      {t('LEAD.CREATE.GENERAL_INFORMATION.regular')}
                    </Typography>
                  }
                  sx={{ margin: 0 }}
                  value="Regular"
                />
                <FormControlLabel
                  checked={
                    values.projectType === 'adhoc' ||
                    values.projectType === 'Ad-Hoc'
                  }
                  control={<CustomRadio />}
                  label={
                    <Typography color="mainText.200" variant="body_300">
                      {t('LEAD.CREATE.GENERAL_INFORMATION.adhoc')}
                    </Typography>
                  }
                  sx={{ margin: 0 }}
                  value="Ad-Hoc"
                />
              </Box>
            </RadioGroup>
          </FormControl>
        </Box>
        <Box display="flex" gap="48px">
          <Box display="flex" flexDirection="column" gap="4px" width={250}>
            <FormLabel label={t('LEAD.CREATE.startDate')} />
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                disablePast
                disabled={values.readOnly || !values.projectType}
                onChange={(startDate) => {
                  const formattedStartDate =
                    dayjs(startDate).format('YYYY-MM-DD');

                  if (
                    values.projectType === 'adhoc' ||
                    values.projectType === 'Ad-Hoc'
                  ) {
                    setValues({
                      ...values,
                      endDate: formattedStartDate,
                      startDate: formattedStartDate
                    });
                    return;
                  }

                  setValues({
                    ...values,
                    endDate:
                      values.endDate && formattedStartDate > values.endDate
                        ? formattedStartDate
                        : values.endDate,
                    startDate: formattedStartDate
                  });
                }}
                renderInput={(params) => <DatePickerTextField {...params} />}
                value={values.startDate}
              />
            </LocalizationProvider>
          </Box>
          {values.projectType !== 'adhoc' &&
            values.projectType !== 'Ad-Hoc' && (
              <Box display="flex" flexDirection="column" gap="4px" width={250}>
                <FormLabel label={t('LEAD.CREATE.endDate')} />
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    disablePast
                    disabled={values.readOnly || !values.projectType}
                    minDate={values.startDate}
                    onChange={(endDate) => {
                      const formattedEndDate =
                        dayjs(endDate).format('YYYY-MM-DD');
                      setValues({ ...values, endDate: formattedEndDate });
                    }}
                    renderInput={(params) => (
                      <DatePickerTextField {...params} />
                    )}
                    value={values.endDate || values.startDate}
                  />
                </LocalizationProvider>
              </Box>
            )}
        </Box>
      </Box>
    );
  }

  function documents() {
    return (
      <Box display="flex" flexDirection="column" gap="8px">
        <FormLabel
          label={t('LEAD.CREATE.GENERAL_INFORMATION.uploadDocuments')}
        />
        <Dropzone
          multiple
          filesList={values?.fileBank || []}
          isMobile={false}
          parent="lead"
          setFiles={(files) => {
            setValues({
              ...values,
              fileBank: files
            });
          }}
          type="file"
        />
      </Box>
    );
  }

  function SaveBeforeLeaveModal() {
    setConfirmModal(
      () => {
        saveStepper({});
      },
      `${t('GENERAL.confirmButton')}`,
      () => {
        navigate('/app/leads/listing-page');
      },
      `${t('GENERAL.cancelButton')}`,
      <Box>
        <Box p={2}>
          <i
            className="custom-icon icon-info"
            style={{ color: theme.palette.surface?.main, fontSize: 34 }}
          />
        </Box>
        {`${t('LEAD.CREATE.saveProgress')}`}
      </Box>
    );
  }

  function buttons() {
    return (
      <Box
        sx={{
          alignSelf: 'flex-end',
          display: 'flex',
          gap: '16px'
        }}
      >
        <Button
          fullWidth
          disabled={values?.readOnly}
          onClick={() => {
            if (Object.keys(values).length > 0) {
              SaveBeforeLeaveModal();
              return;
            }
            navigate('/app/leads/listing-page');
          }}
          sx={{ width: 304 }}
          variant="cancel"
        >
          <Typography variant="heading_328">
            {t('GENERAL.cancelButton')}
          </Typography>
        </Button>

        <Button
          fullWidth
          disabled={!validData || values?.readOnly}
          key="next-button-general-info"
          onClick={() => {
            if (Object.keys(values).length === 0) {
              saveStepper({ next: true });
            } else if (
              values.projectType === 'regular' ||
              values.projectType === 'Regular'
            ) {
              setValues({ ...values, manualPricing: false });
              sendValues(values);
              saveStepper({ next: true });
            } else if (!currentLeadId) {
              setShowManualPricingModal(true);
            } else {
              sendValues(values);
              saveStepper({ next: true });
            }
          }}
          sx={{ width: 304 }}
          variant="primary"
        >
          <Typography variant="heading_328">
            {t('LEAD.CREATE.GENERAL_INFORMATION.continue')}
          </Typography>
        </Button>
      </Box>
    );
  }

  function header() {
    return (
      <Box display="flex" gap="40px" justifyContent="space-between">
        <Box display="flex" flexDirection="column" gap="16px">
          <Typography color="primary" variant="heading_500">
            {`${t('LEAD.CREATE.GENERAL_INFORMATION.description')}`}
          </Typography>
          <Typography color="secondary.400" variant="body_200">
            {`${t('LEAD.CREATE.GENERAL_INFORMATION.leadClientWarning')}`}
          </Typography>
        </Box>

        <Box alignItems="flex-end" display="flex" flexShrink={0}>
          <Button
            disabled={values?.readOnly}
            onClick={() =>
              navigate('/app/crm/new', {
                state: { ...values, lastPage: '/app/leads/new' }
              })
            }
            sx={{ minWidth: 187 }}
            variant="primary"
          >
            <Box alignItems="center" display="flex" gap="10px">
              <i className="custom-icon icon-add" />
              <Typography variant="heading_328">
                {t('CUSTOMER.createClient')}
              </Typography>
            </Box>
          </Button>
        </Box>
      </Box>
    );
  }

  //   USEEFFECTS ==========================================================================================

  useEffect(() => {
    setError(false);
    setErrorMsg('');
    setClients([]);
    setAskClients(true);
    if (values?.startDate) {
      const today = dayjs();
      setValues({
        ...values,
        endDate:
          values.endDate && dayjs(values.endDate) >= today
            ? values.endDate
            : today,
        startDate:
          values.startDate && dayjs(values.startDate) >= today
            ? values.startDate
            : today
      });
    }
  }, []);

  useEffect(() => {
    if (clientsError) {
      setClients([]);
    }
  }, [clientsError]);

  useEffect(() => {
    if (clientsData) {
      // setAskClients(false);
      // setAskContacts(true);
      setClients(
        clientsData.map((item: any) => {
          return {
            ...item,
            name: `${item.firstName} ${item.lastName} from ${item.businessName}`
          };
        })
      );
    }
  }, [clientsData]);

  useEffect(() => {
    if (clients?.length > 0) {
      if (params?.clientCreated) {
        const { clientCreated } = params;
        clients.forEach((client: any) => {
          if (client.id === clientCreated.id) {
            setValues({ ...values, client, clientReady: true });
          }
        });
      } else if (values.client) {
        setValues({ ...values, clientReady: true });
      }
    }
  }, [clients, params]);

  useEffect(() => {
    if (
      values?.name &&
      values?.projectType &&
      (values?.projectStartDate || values?.startDate)
    ) {
      setValidData(true);
    } else {
      setValidData(false);
    }
  }, [values]);

  //   RENDER ==========================================================================================
  if (!values?.id) {
    return (
      <BoxCentered>
        <Loader customColor="primary" customSize={48} />
      </BoxCentered>
    );
  }

  return (
    <Box sx={{ pointerEvents: values?.readOnly ? 'none' : '' }}>
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: '48px' }}>
        {header()}
        {clientAndContact()}
        {description()}
        {projectDuration()}
        {documents()}
        {getError()}
        {buttons()}
      </Box>
      {showManualPricingModal && (
        <ManualServiceCatalogModal
          saveStepper={saveStepper}
          sendValues={sendValues}
          setShowManualPricingModal={setShowManualPricingModal}
          setValues={setValues}
          values={values}
        />
      )}
    </Box>
  );
};

export default GeneralInformation;
