/* eslint-disable no-loop-func */
/* eslint-disable import/prefer-default-export */

const getTotalSomething = (values: any, something: string) => {
  let totalSomething = 0.0;
  values?.destinations?.forEach((destination: any) => {
    if (destination?.serviceTimes?.length > 0) {
      destination.serviceTimes.forEach((serviceTime: any) => {
        totalSomething += serviceTime[something] || 0;
      });
    }
  });
  return totalSomething;
};

export const getTotalQuoteMarkup = (values: any) => {
  let counterServiceTimes = 0;
  values?.destinations?.forEach((destination: any) => {
    counterServiceTimes += destination?.serviceTimes?.length || 0;
  });
  counterServiceTimes = counterServiceTimes || 1;
  return getTotalSomething(values, 'quoteMarkup') / counterServiceTimes;
};

export const getTotalQuotePrice = (values: any) => {
  return getTotalSomething(values, 'quotePrice');
};

export const getQuotedTotal = (values: any) => {
  let totalAmount = 0.0;
  values?.destinations?.forEach((destination: any) => {
    destination?.serviceTimes?.forEach((service: any) => {
      const amount = service?.quotePrice
        ? service.quotePrice *
          parseInt(
            service?.availability?.generalInfo?.daysOfServiceCount || 1,
            10
          )
        : 0;
      totalAmount += amount;
    });
  });
  return totalAmount;
};

export const counterOfferServiceTimeParser = (serviceTime: any) => {
  return {
    endDate: serviceTime?.endDate,
    frequency: serviceTime?.frequency,
    frequencyDays: serviceTime?.frequencyDays,
    frequencyWeek: serviceTime?.frequencyWeek,
    serviceTimeId: serviceTime?.id,
    startDate: serviceTime?.startDate
  };
};
