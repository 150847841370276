import React, { useEffect, useRef, useState } from 'react';
import {
  Box,
  Container,
  IconButton,
  Stack,
  styled,
  TextField,
  Typography
} from '@mui/material';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { mainColors } from '../theme/themeConfig';
import ImageViewer from './ImageViewer';

// Constants
// Feel free to customize this through props if you need to
const IMAGE_WIDTH = 230;
const IMAGE_HEIGHT = 123;

// Types
interface ImageStripProps {
  images: any[];
}

// Styled components
const StripContainer = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.background.default,
  padding: theme.spacing(2),
  position: 'relative',
  width: '100%'
}));

const ScrollContainer = styled(Box)(({ theme }) => ({
  marginTop: theme.spacing(1),
  overflowX: 'hidden'
}));

const ImageContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  gap: theme.spacing(1),
  transition: 'transform 0.3s ease-in-out'
}));

const StripImage = styled('img')({
  flexShrink: 0,
  height: IMAGE_HEIGHT,
  objectFit: 'cover',
  width: IMAGE_WIDTH
});

const ControlsContainer = styled(Stack)(() => ({
  alignItems: 'center',
  flexDirection: 'row'
}));

const ImageStrip: React.FC<ImageStripProps> = function ImageStrip({ images }) {
  const scrollContainerRef = useRef<HTMLDivElement>(null);
  const [canScrollLeft, setCanScrollLeft] = useState(false);
  const [canScrollRight, setCanScrollRight] = useState(false);
  const [scrollPosition, setScrollPosition] = useState(0);
  const [imageSelected, setImageSelected] = useState<any>();

  const updateScrollButtons = () => {
    if (!scrollContainerRef.current) return;

    const { scrollWidth, clientWidth } = scrollContainerRef.current;
    setCanScrollLeft(scrollPosition > 0);
    setCanScrollRight(scrollPosition < scrollWidth - clientWidth);
  };

  const scroll = (direction: 'left' | 'right') => {
    if (!scrollContainerRef.current) return;

    const scrollAmount = IMAGE_WIDTH + 8; // 8px === theme.spacing(1)
    const newPosition =
      direction === 'left'
        ? scrollPosition - scrollAmount
        : scrollPosition + scrollAmount;

    setScrollPosition(newPosition);
    scrollContainerRef.current.scrollTo({
      behavior: 'smooth',
      left: newPosition
    });
  };

  const handleKeyDown = (event: any) => {
    switch (event.key) {
      case 'ArrowLeft':
        if (imageSelected?.index) {
          setImageSelected({
            image: images[imageSelected.index - 1]?.large,
            index: imageSelected.index - 1
          });
        }
        break;
      case 'ArrowRight':
        if ((imageSelected?.index || 0) + 1 !== images.length) {
          setImageSelected({
            image: images[imageSelected.index + 1]?.large,
            index: imageSelected.index + 1
          });
        }
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    updateScrollButtons();
  }, [scrollPosition, images]);

  return (
    <StripContainer>
      <Box alignItems="center" display="flex" justifyContent="space-between">
        <Typography
          color={mainColors.MAIN}
          fontSize={12}
          sx={{ textDecoration: 'underline' }}
          variant="secondary"
        >
          Images ({images.length})
        </Typography>

        <ControlsContainer>
          <IconButton
            disabled={!canScrollLeft}
            onClick={() => scroll('left')}
            size="small"
          >
            <ChevronLeftIcon />
          </IconButton>
          <IconButton
            disabled={!canScrollRight}
            onClick={() => scroll('right')}
            size="small"
          >
            <ChevronRightIcon />
          </IconButton>
        </ControlsContainer>
      </Box>

      <ScrollContainer ref={scrollContainerRef}>
        <ImageContainer>
          {images.map((image: any, index: number) => (
            <StripImage
              alt={`Image ${index + 1}`}
              key={`${index + 1}`}
              loading="lazy"
              onClick={() => setImageSelected({ image: image?.large, index })}
              src={image.medium}
              sx={{ cursor: 'pointer' }}
            />
          ))}
        </ImageContainer>
      </ScrollContainer>
      {imageSelected && (
        // eslint-disable-next-line jsx-a11y/no-static-element-interactions
        <div onKeyDown={handleKeyDown}>
          <ImageViewer
            isOpen
            setIsOpen={() => {
              setImageSelected(undefined);
            }}
            url={imageSelected.image}
          />
        </div>
      )}
    </StripContainer>
  );
};

export default ImageStrip;
