import React, { useState, useEffect } from 'react';
import '../../style/App.scss';
import { Box, Container, Typography, styled } from '@mui/material';
import MuiContainer from '@mui/material/Container';
import CssBaseline from '@mui/material/CssBaseline';
import { isMobile } from 'react-device-detect';
import NavBar from './NavBar';
import useNavigationStore from '../../store/zustand/navigation';
import MobileSidebar from './sidebars/MobileSidebar';
import SideBar from './sidebars/SideBar';
import {
  navBarHeight,
  sideBarExpandedWidth,
  sideBarNormalWidth
} from '../../helpers/constants';
import { getLocalStorage } from '../../utils/authHelper';

interface Props {
  children: React.ReactNode;
}

const ExpandableContainer = styled(MuiContainer, {
  shouldForwardProp: (prop: any) => prop !== 'open'
})<{
  open?: boolean;
}>(({ theme, open }) => ({
  height: `calc(100% - ${navBarHeight}px)`,
  marginLeft: `${sideBarNormalWidth}px`,
  marginTop: `${navBarHeight}px`,
  overflow: 'scroll',
  padding: '0px',
  position: 'absolute',
  transition: theme.transitions.create(['width', 'margin'], {
    duration: theme.transitions.duration.leavingScreen,
    easing: theme.transitions.easing.sharp
  }),
  width: open
    ? `calc(100% - ${sideBarExpandedWidth}px)`
    : `calc(100% - ${sideBarNormalWidth}px)`,
  ...(open && {
    marginLeft: sideBarExpandedWidth,
    transition: theme.transitions.create(['width', 'margin'], {
      duration: theme.transitions.duration.enteringScreen,
      easing: theme.transitions.easing.sharp
    }),
    width: `calc(100% - ${sideBarExpandedWidth}px)`
  })
}));

const NavigationWrapper: React.FC<Props> = function AppContainer({ children }) {
  const [expandedSidebar, setExpandedSidebar] = useState<boolean>(false);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars, no-unused-vars
  const [permanentSidebar, setPermanentSidebar] = useState<boolean>(false);
  const isImpersonated = !!getLocalStorage('accessTokenImpersonate');
  const noFrameScreens = [
    'password',
    'proposal-client-view',
    'company-onboarding'
  ];
  const { currentStepScreen, setSidebarOpen } = useNavigationStore(
    (state) => state
  );

  if (noFrameScreens.includes(currentStepScreen)) {
    return <div>{children}</div>;
  }

  const handleOpenSidebar = (flag: boolean) => {
    setExpandedSidebar(flag);
    setSidebarOpen(flag);
  };

  return (
    <Container sx={{ height: '100%', width: '100vw' }}>
      {isMobile ? (
        <>
          <MobileSidebar />
          <Container>{children}</Container>
        </>
      ) : (
        <>
          <SideBar
            expandSidebar={handleOpenSidebar}
            expandSidebarFlag={expandedSidebar}
            // permanentSidebar={permanentSidebar}
            // setPermanentSidebar={setPermanentSidebar}
          />
          <NavBar
            expandSidebar={handleOpenSidebar}
            isImpersonated={isImpersonated}
            open={expandedSidebar}
            tokenImpersonated={getLocalStorage('accessTokenImpersonate')}
          />
          <CssBaseline />
          <ExpandableContainer open={expandedSidebar}>
            {children}
          </ExpandableContainer>
        </>
      )}
    </Container>
  );
};

export default NavigationWrapper;
