import React, { useEffect, useState } from 'react';
import { Box, Typography, Button, Container, Snackbar } from '@mui/material';
import { isMobile } from 'react-device-detect';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import useSWR from 'swr';
import api from '../../../api';
import ConfirmationCode from './ConfirmationCode';
import UpdateField from './UpdateField';
import SelectMethod from './SelectMethod';

const style = {
  alignItems: 'center',
  bgcolor: 'background.paper',
  borderRadius: '0.5em',
  boxShadow: '24',
  display: 'flex',
  flexDirection: 'column',
  height: isMobile ? '634px' : '472px',
  left: '50%',
  padding: isMobile ? '40px 30px' : '40px 70px',
  position: 'absolute' as 'absolute',
  top: '50%',
  transform: 'translate(-50%, -50%)',
  width: isMobile ? '90%' : 500
};

const styleUpdate = {
  alignItems: 'center',
  bgcolor: 'background.paper',
  borderRadius: '0.5em',
  boxShadow: '24',
  display: 'flex',
  flexDirection: 'column',
  left: '50%',
  padding: isMobile ? '40px 30px' : '40px 70px',
  position: 'absolute' as 'absolute',
  top: '50%',
  transform: 'translate(-50%, -50%)',
  width: isMobile ? '90%' : 500
};

interface Props {
  isCompany?: boolean;
  isMobile?: boolean;
  login?: () => void;
  openPasswordMFA?: boolean;
  profile?: any;
  setErrorApi?: any;
  setOpenMFA?: any;
  setUser?: (data: any) => void;
  token?: string | undefined;
  updateFlag?: boolean;
}

const BasePageMFA: React.FC<Props> = function BasePageMFA({
  isMobile,
  updateFlag = false,
  profile: profileToUpdate,
  openPasswordMFA,
  setOpenMFA,
  isCompany,
  setUser = () => {},
  setErrorApi,
  login = () => {},
  token
}) {
  const navigate = useNavigate();
  const [error, setError] = useState<boolean>(false);
  const [inputMethodSelected, setInputMethodSelected] = useState<number>(1);
  const [methodSelected, setMethodSelected] = useState<number>(0);
  const { t } = useTranslation();
  const [updateSuccesful, setUpdateSuccesful] = useState<boolean>(false);
  const [openSnackFile, setOpenSnackFile] = useState<boolean>(false);
  const [code, setCode] = useState<string>('');
  const { state: userInfo } = useLocation();
  const [fireApiSendCode, setFireApiSendCode] = useState<boolean>(false);
  const [fireApiUpdateSendCode, setFireApiUpdateSendCode] =
    useState<boolean>(false);
  const [errorMessage, setErrorMessage] =
    useState<string>('Something go wrong');
  const [fireApiConfirmCode, setFireApiConfirmCode] = useState<boolean>(false);
  const [fireApiResendCode, setFireApiResendCode] = useState<boolean>(false);
  const [isWaitingConfirmation, setIsWaitingConfirmation] =
    useState<boolean>(false);
  const [dataToSend, setDataToSend] = useState<any>(userInfo);

  // --------------- Endpoints Section --------------

  // Endpoint to send data & register mfa for 1st time.
  const { data: dataMfaSendCode, error: errorMfaSendCode } = useSWR(
    fireApiSendCode
      ? { dataToSend, methodSelected, url: '/mfaSendCode' }
      : null,
    api.authApi.sendInfoToMFA
  );

  // Endpoint to send the data & register mfa for 2nd time.
  const { data: dataMfaUpdateSendCode, error: errorMfaUpdateSendCode } = useSWR(
    fireApiUpdateSendCode
      ? { dataToSend, methodSelected, url: '/mfaSendCode' }
      : null,
    api.authApi.sendUpdateInfoToMFA
  );

  // Endpoint to confirm code.
  const { data: dataConfirmCode, error: errorConfirmCode } = useSWR(
    fireApiConfirmCode ? code : null,
    api.authApi.confirmCode
  );

  // Endpoint to RE-SEND the data & register mfa for 1st time.
  const { data: dataResendCode, error: errorResendCode } = useSWR(
    fireApiResendCode ? '/mfaSendCode' : null,
    api.authApi.sendCodeMFA
  );

  // Endpoint to obtain dataUser in case theres no updateFlag.
  const { data: profile, error: errorProfile } = useSWR(
    'api/profile',
    api.authApi.getProfile
  );

  // --------------- Functions Section --------------

  const confirmMFA = () => {
    setIsWaitingConfirmation(true);
    setFireApiConfirmCode(true);
  };

  const resendCode = () => {
    setFireApiResendCode(true);
  };

  const backMFA = () => {
    setIsWaitingConfirmation(false);
    setInputMethodSelected(1);
  };

  const confirmCode = () => {
    // Es nuestro punto de partida. Aqui se hara toda la logica. El resto en adelante es igual.
    if (inputMethodSelected === 1) {
      if (profileToUpdate?.twoFactor?.method && methodSelected !== 0) {
        setInputMethodSelected(2);
        setInputMethodSelected(2);
        return;
      }
      if (!profileToUpdate?.twoFactor?.method && methodSelected === 1) {
        setInputMethodSelected(2);
        return;
      }
    }
    setFireApiSendCode(true);
  };

  const setProfile = () => {
    if (!errorProfile) {
      if (profile) {
        const { roles, email, mfa, block, active, firstName, id, timeFormat } =
          profile;
        const rolesName = roles.map((ele: any) => ele.name);
        setUser({
          active,
          block,
          email,
          firstName,
          id,
          mfa,
          roles: rolesName,
          timeFormat
        });
      }
    }
  };

  const skip = async () => {
    await setProfile();
    await login();
    await navigate('/app');
  };

  const handleError = (message: string) => {
    setError(true);
    setErrorMessage(message);
  };

  // --------------- useEffect Section --------------
  useEffect(() => {
    // Initial Values Setup
    if (openPasswordMFA === false) {
      setInputMethodSelected(1);
      setMethodSelected(0);
    }
  }, [openPasswordMFA]);

  useEffect(() => {
    if (profileToUpdate) {
      setDataToSend(profileToUpdate);
      if (
        profileToUpdate?.twoFactor &&
        !profileToUpdate?.twoFactor?.confirmed
      ) {
        setInputMethodSelected(3);
        setIsWaitingConfirmation(true);
      }
    }
  }, [profileToUpdate]);

  useEffect(() => {
    if (dataConfirmCode && dataConfirmCode.status === 201) {
      setFireApiConfirmCode(false);
      if (updateFlag) {
        setUpdateSuccesful(true);
        return;
      }
      if (isCompany) {
        navigate(`/company/invitation/accept-invitation/${token}/6`);
      } else {
        navigate(`/invitation/accept-invitation/${token}/5`);
      }
    } else if (dataConfirmCode && dataConfirmCode.status === 400) {
      handleError(dataConfirmCode.statusText);
      setIsWaitingConfirmation(true);
      setFireApiConfirmCode(false);
    }
  }, [dataConfirmCode]);

  useEffect(() => {
    if (dataResendCode && dataResendCode.status === 201) {
      setFireApiResendCode(false);
      setOpenSnackFile(true);
    }
  }, [dataResendCode]);

  useEffect(() => {
    if (dataMfaSendCode && dataMfaSendCode.status === 201) {
      setFireApiSendCode(false);
      setIsWaitingConfirmation(true);
      setError(false);
    }
  }, [dataMfaSendCode]);

  useEffect(() => {
    if (dataMfaUpdateSendCode) {
      setFireApiUpdateSendCode(false);
      setIsWaitingConfirmation(true);
      setError(false);
    }
  }, [dataMfaUpdateSendCode]);

  useEffect(() => {
    if (
      errorMfaSendCode ||
      errorResendCode ||
      errorConfirmCode ||
      errorMfaUpdateSendCode
    ) {
      setError(true);
      setErrorMessage(`${t('LOGIN.invitationErrorEndpoint')}`);
      setFireApiSendCode(false);
      setFireApiConfirmCode(false);
      setFireApiResendCode(false);
      setFireApiUpdateSendCode(false);
      // Viene de afuera condicional en caso que no sea updateFlag
      if (!updateFlag) {
        setErrorApi(true);
      }
    }
  }, [errorResendCode]);

  useEffect(() => {
    if (errorMfaSendCode) {
      const { response } = errorMfaSendCode;
      setError(true);
      setErrorMessage(`${response.data.message}`);
      setFireApiSendCode(false);
      setFireApiConfirmCode(false);
      setFireApiResendCode(false);
    }
  }, [errorMfaSendCode]);

  useEffect(() => {
    if (errorMfaUpdateSendCode) {
      const { response } = errorMfaUpdateSendCode;
      setError(true);
      setErrorMessage(`${response.data.message}`);
      setFireApiUpdateSendCode(false);
      setFireApiConfirmCode(false);
      setFireApiResendCode(false);
    }
  }, [errorMfaUpdateSendCode]);

  // --------------- Function compoenntes ----------

  const getError = () =>
    !error ? (
      false
    ) : (
      <Container sx={{ mt: 1 }}>
        <Typography
          color="alert.main"
          sx={{ fontSize: 14 }}
          variant="primary-bold"
        >
          &bull; {errorMessage}
        </Typography>
      </Container>
    );

  const getSelectMethod = () => (
    <SelectMethod
      alterAction={updateFlag ? () => setOpenMFA(false) : () => skip()}
      confirmCode={confirmCode}
      getError={getError}
      methodSelected={methodSelected}
      mfa={profileToUpdate?.twoFactor}
      // phoneNumber={profile?.phoneNumber}
      setMethodSelected={setMethodSelected}
      updateFlag={updateFlag}
    />
  );

  const getUpdateField = () => (
    <UpdateField
      cancelAction={() => {
        if (updateFlag) {
          setOpenMFA(false);
        } else {
          setInputMethodSelected(1);
        }
      }}
      confirmCode={() => {
        if (profile?.twoFactor) {
          setFireApiUpdateSendCode(true);
        } else {
          setFireApiSendCode(true);
        }
      }}
      getError={getError}
      methodSelected={methodSelected}
      setDataToSend={setDataToSend}
      setError={setError}
    />
  );

  const getMethodMasked = () => {
    if (methodSelected === 2) {
      const email = updateFlag ? profile?.email : userInfo?.email;
      return email;
    }
    const phoneNumber = updateFlag
      ? profile?.phoneNumber
      : userInfo?.phoneNumber;
    const last4Digits = updateFlag
      ? phoneNumber?.slice(-4)
      : phoneNumber?.slice(-4);
    return last4Digits?.padStart(phoneNumber.length, '*');
  };

  const getConfirmationCode = () => (
    <ConfirmationCode
      confirmMFA={confirmMFA}
      getError={getError}
      getMethodMasked={getMethodMasked}
      handleBack={backMFA}
      methodSelected={methodSelected}
      resendCode={resendCode}
      setCode={setCode}
      setError={setError}
    />
  );

  return (
    //  ----- Main Section -----
    <Box>
      {updateSuccesful && updateFlag ? (
        <Box sx={updateFlag ? styleUpdate : style}>
          <Box
            sx={{
              alignItems: 'center',
              display: 'flex',
              flexDirection: 'column',
              height: '100%',
              justifyContent: 'center'
            }}
          >
            <Box sx={{ textAlign: 'center' }}>
              <Typography
                color="success.main"
                sx={{ fontSize: 24 }}
                variant="primary-bold"
              >
                {t('PROFILE.mfaUpdateSuccessMsg')}
              </Typography>
            </Box>
            <br />
            <Button
              onClick={() => {
                setUpdateSuccesful(false);
                setInputMethodSelected(3);
                setIsWaitingConfirmation(false);
                setOpenMFA(false);
              }}
              sx={{ width: isMobile ? '100%' : '50%' }}
              variant="contained"
            >
              <Typography variant="primary-bold">
                {t('GENERAL.done')}
              </Typography>
            </Button>
          </Box>
        </Box>
      ) : (
        <Box sx={updateFlag ? styleUpdate : style}>
          <Box
            sx={{
              alignItmes: 'center',
              display: 'flex',
              height: updateFlag ? 'auto' : '32%',
              justifyContent: 'center',
              width: '100%'
            }}
          >
            <Typography
              color="primary"
              sx={{ fontWeight: 'bold', marginBottom: '30px' }}
              variant="h6"
            >
              {t('MODAL.mfaUpdateModalsMsg')}
            </Typography>
          </Box>
          {!isWaitingConfirmation &&
            inputMethodSelected === 1 &&
            getSelectMethod()}
          {!isWaitingConfirmation &&
            inputMethodSelected === 2 &&
            getUpdateField()}
          {isWaitingConfirmation && getConfirmationCode()}
        </Box>
      )}
      <Snackbar
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
        autoHideDuration={3000}
        key="snack-key"
        message={
          methodSelected === 1
            ? 'The code was sent. Please check your phone.'
            : 'The code was sent. Please check your email.'
        }
        onClose={() => setOpenSnackFile(false)}
        open={openSnackFile}
      />
    </Box>
  );
};

BasePageMFA.defaultProps = {
  isCompany: false,
  isMobile: false,
  login: () => {},
  openPasswordMFA: false,
  profile: {},
  setErrorApi: () => {},
  setOpenMFA: () => {},
  setUser: () => {},
  token: '',
  updateFlag: false
};

export default BasePageMFA;
