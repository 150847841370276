/* eslint-disable react/no-array-index-key */
import { useState, useEffect } from 'react';
import { isMobile } from 'react-device-detect';
import { useTranslation } from 'react-i18next';
import useSWR from 'swr';
import { Box, Container, Skeleton, Snackbar, Typography } from '@mui/material';

import {
  CompanyTaxDTO,
  StaticCostResponseDto
} from '../../api-client/generated/api';
import api from '../../api';
import {
  HeaderFormContainer,
  TitleButtonsBox,
  TitleFormBox,
  WhiteBoxContainer
} from '../form/parts';
import SortableTable from '../list/sortableTable';
import ButtonFormGroup from '../form/ButtonFormGroup';

function LoadingSqueleton() {
  return (
    <Box display="flex" flexDirection="column" gap={3} marginY={1} width="100%">
      {Array.from({ length: 6 }).map((_, index) => (
        <Box display="flex" gap={1} key={index}>
          <Skeleton height={40} variant="rounded" width={240} />
          <Skeleton height={40} variant="rounded" width={240} />
          <Skeleton height={40} variant="rounded" width={240} />
        </Box>
      ))}
    </Box>
  );
}

export interface TaxType {
  createdAt: string;
  deletedAt: string;
  id: string;
  name: string;
  tax: string;
  type: string;
  updatedAt: string;
}

const CompanyTaxes: React.FC = function CompanyTaxes() {
  const { t } = useTranslation();

  const [errorMessages, setErrorMessages] = useState<string[]>([]);
  const [openSnack, setOpenSnack] = useState<boolean>(false);
  const [snackMsgFlag, setSnackMsgFlag] = useState<boolean>(false);
  const [actionUpdate, setActionUpdate] = useState<boolean>(false);
  const [isEditActive, setIsEditActive] = useState<boolean>(false);
  const [taxesList, setTaxesList] = useState<CompanyTaxDTO[]>([]);
  const [staticList, setStaticList] = useState<StaticCostResponseDto[]>([]);
  const [error, setError] = useState<boolean>(false);
  const [taxId, setTaxId] = useState<string>('');
  const [getCurrentCompany, setGetCurrentCompany] = useState<boolean>(true);

  const taxHeader = [
    t('COMPANY_SETTINGS.name'),
    `${t('COMPANY_SETTINGS.tax')} (%)`,
    t('COMPANY_SETTINGS.type'),
    ''
  ];

  const staticHeaders = [
    t('COMPANY_SETTINGS.staticCostName'),
    t('COMPANY_SETTINGS.value'),
    t('COMPANY_SETTINGS.periodType'),
    t('COMPANY_SETTINGS.canOverwrite')
  ];

  // SWR
  const { data: currentCompany, mutate } = useSWR(
    getCurrentCompany ? 'api/currentCompany/settings' : null,
    api.CompanyApi.getCurrentCompany
  );

  const { data: updatedCompany, error: updateError } = useSWR(
    actionUpdate
      ? [
          'update/currentCompany',
          {
            taxes: [...taxesList],
            staticCosts: staticList.map((item) => {
              return {
                value: item.value,
                id: item.id,
                canOverwrite: item.canOverwrite,
                periodType: item.periodType
              };
            })
          }
        ]
      : null,
    async (url: string, params: { taxes: CompanyTaxDTO[] }) => {
      await api.CompanyApi.updateCurrentCompany(url, params);
      await mutate(undefined, true);
      setError(false);
      setActionUpdate(false);
      setIsEditActive(false);
      setOpenSnack(true);
      setSnackMsgFlag(true);
      setGetCurrentCompany(true);
    }
  );

  const { data: deleteTax, error: deleteTaxError } = useSWR(
    taxId || null,
    async (params) => {
      await api.CompanyApi.deleteTax(params);
      await mutate(undefined, true);
      setError(false);
      setTaxId('');
      setOpenSnack(true);
      setSnackMsgFlag(false);
      setGetCurrentCompany(true);
    }
  );

  // UseEffects
  useEffect(() => {
    if (updateError || deleteTaxError) {
      const errorObj = updateError || deleteTaxError;
      const response = errorObj?.response?.data?.message;
      setErrorMessages(
        typeof response === 'string'
          ? [response]
          : response || ['Something went wrong']
      );
      setError(true);
      setActionUpdate(false);
      setGetCurrentCompany(true);
    }
  }, [updateError, deleteTaxError]);

  useEffect(() => {
    if (currentCompany) {
      setGetCurrentCompany(false);
      const taxes =
        currentCompany?.taxes?.filter(
          (tax) => tax?.type === 'payroll' || tax?.type === 'other'
        ) ?? [];
      console.log('STATICCOST', currentCompany.staticCosts);

      //  const staticTax =
      //   currentCompany?.taxes?.filter((tax) => tax?.type === 'static') ?? [];

      // let staticTaxToAdd = staticTaxesNames;

      // staticTax.forEach((tax) => {
      //   if (staticTaxToAdd.includes(tax?.name)) {
      //     staticTaxToAdd = staticTaxToAdd.filter((name) => name !== tax?.name);
      //   }
      // });

      // staticTaxToAdd.forEach((name) => {
      //   staticTax.push({
      //     name,
      //     tax: 0,
      //     type: 'static'
      //   });
      // });

      setTaxesList(taxes);
      setStaticList(currentCompany.staticCosts);
    }
  }, [currentCompany]);

  const handleCancel = () => {
    setErrorMessages([]);
    setIsEditActive(false);
    mutate(undefined, true);
  };

  const handleSubmit = () => {
    const errorSet = new Set<string>();

    // Validate taxesList (editable taxes)
    if (taxesList?.length) {
      if (taxesList.some((tax) => !tax.name)) {
        errorSet.add(t('COMPANY_SETTINGS.errorNamesEmpty'));
      }
      if (taxesList.some((tax) => tax.tax === undefined)) {
        errorSet.add(t('COMPANY_SETTINGS.errorTaxEmpty'));
      }
      if (taxesList.some((tax) => tax.tax < 0.01 || tax.tax > 100)) {
        errorSet.add(t('COMPANY_SETTINGS.errorTaxRange'));
      }
      if (taxesList.some((tax) => !tax.type)) {
        errorSet.add(t('COMPANY_SETTINGS.errorTypeEmpty'));
      }
    }

    // Validate staticList (static taxes)
    // if (staticList?.length) {
    //   if (staticList.some((tax) => !tax.name)) {
    //     errorSet.add(t('COMPANY_SETTINGS.errorNamesEmpty'));
    //   }
    //   if (staticList.some((tax) => tax.tax === undefined)) {
    //     errorSet.add(t('COMPANY_SETTINGS.errorTaxEmpty'));
    //   }
    //   if (staticList.some((tax) => tax.tax < 0.01 || tax.tax > 100)) {
    //     errorSet.add(t('COMPANY_SETTINGS.errorTaxRange'));
    //   }
    //   if (staticList.some((tax) => !tax.type)) {
    //     errorSet.add(t('COMPANY_SETTINGS.errorTypeEmpty'));
    //   }
    // }

    const validationErrors = Array.from(errorSet);
    setErrorMessages(validationErrors);

    if (validationErrors.length === 0) {
      setError(false);
      setActionUpdate(true);
    } else {
      setError(true);
      setOpenSnack(true);
    }
  };

  const getSnackbarMessage = (): string => {
    if (error) {
      return errorMessages.join(' - ');
    }
    return snackMsgFlag
      ? t('COMPANY_SETTINGS.taxesUpdated')
      : t('COMPANY_SETTINGS.taxesDeleted');
  };

  return (
    <Container>
      <Box sx={{ wdith: '100%' }}>
        <HeaderFormContainer isMobile={isMobile}>
          <TitleFormBox isMobile={isMobile} />
          <TitleButtonsBox isEditActive={isEditActive} isMobile={isMobile}>
            <ButtonFormGroup
              actionButtonMsg={t('PROFILE.saveInformation')}
              cancelAction={() => handleCancel()}
              enableEditAction={() => setIsEditActive(true)}
              enableEditButtonMsg={t('PROFILE.editInformation')}
              isMobile={isMobile}
              mainAction={() => {
                handleSubmit();
              }}
              updateFlag={isEditActive}
            />
          </TitleButtonsBox>
        </HeaderFormContainer>
      </Box>
      <WhiteBoxContainer isMobile={isMobile}>
        <Box display="flex" flexDirection="column" gap={2}>
          <Typography color="mainText.main" variant="secondary">
            {t('COMPANY_SETTINGS.directIndirectCostsTitle')}
          </Typography>
          <Box>
            <Box display="flex">
              {taxHeader.map((item, index: number) => (
                <Box
                  key={item}
                  sx={{
                    marginLeft: '10px',
                    width: index + 1 === taxHeader.length ? '20%' : '100%'
                  }}
                >
                  <Typography color="mainText.500" variant="primary-bold">
                    {item}
                  </Typography>
                </Box>
              ))}
            </Box>
            {taxesList?.length <= 0 ? (
              <LoadingSqueleton />
            ) : (
              <SortableTable
                disableSort
                addManagement={isEditActive}
                bodyKeys={['name', 'tax', 'type']}
                disabledTable={!isEditActive}
                handleDelete={setTaxId}
                onChange={(newTaxesList) => {
                  const formatTaxes = newTaxesList.map((item: TaxType) => ({
                    ...item,
                    tax: Number(item.tax)
                  }));
                  setTaxesList(formatTaxes);
                }}
                type="tax-input"
                values={taxesList || []}
              />
            )}
            <Typography color="mainText.main" variant="secondary">
              {t('COMPANY_SETTINGS.staticRateCosts')}
            </Typography>
            <Box display="flex">
              {staticHeaders.map((item, index: number) => (
                <Box
                  key={item}
                  sx={{
                    marginLeft: '1px',
                    width: index + 1 === staticHeaders.length ? '80%' : '100%'
                  }}
                >
                  <Typography color="mainText.500" variant="primary-bold">
                    {item}
                  </Typography>
                </Box>
              ))}
            </Box>
            <SortableTable
              disableSort
              addManagement={false}
              bodyKeys={['static', 'value', 'periodType', 'canOverwrite']}
              disabledTable={!isEditActive}
              handleDelete={setTaxId}
              onChange={(newTaxesList) => {
                console.log({ newTaxesList });
                const formatTaxes = newTaxesList.map((item: any) => ({
                  value: item.value,
                  id: item.id,
                  canOverwrite: item.canOverwrite,
                  periodType: item.periodType,
                  name: item.name
                }));
                setStaticList(formatTaxes);
              }}
              type="static-input"
              values={staticList || []}
            />
          </Box>
        </Box>
        <Snackbar
          anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
          autoHideDuration={3000}
          key="company-taxes"
          message={getSnackbarMessage()}
          onClose={() => setOpenSnack(false)}
          open={openSnack}
        />
      </WhiteBoxContainer>
    </Container>
  );
};

export default CompanyTaxes;
