/* eslint-disable react/require-default-props */
/* eslint-disable react/no-array-index-key */
import React, { RefObject, useEffect, useRef, useState, useMemo } from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  Box,
  useTheme,
  Collapse,
  Button,
  Modal,
  styled,
  SliderProps,
  SlideProps,
  Tooltip,
  Grid
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { isMobile } from 'react-device-detect';
import RegularInput from '../../../components/input/RegularInput';
import Tile from '../../../components/dashboard/tile';
import {
  decimalsToHours,
  formatSumTime,
  hoursToDecimals
} from '../../../utils/calendarHelper';
import { ReactComponent as ChevronUpIcon } from '../../../assets/icons/chevron-up.svg';
import { ReactComponent as ChevronDownIcon } from '../../../assets/icons/chevron-down.svg';
import CustomTooltip from '../../../components/shared/CustomTooltip';
import { FormLabel } from '../../../components/form/parts';
import FilterSelect from '../../../components/filters/FilterSelect';
import TimesheetExpandedReviewRow from './TimesheetExpandedReviewRow';

const ButtonContainerModal = styled(Box)<SliderProps>(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  width: theme.spacing(30)
}));

const ModalContainer = styled(Box)<SliderProps>(({ theme }) => ({
  alignItems: 'center',
  backgroundColor: theme.palette.background.paper,
  borderRadius: '0.5em',
  boxShadow: '24px',
  display: 'flex',
  flexDirection: 'column',
  height: '280px',
  justifyContent: 'center',
  padding: '20px',
  position: 'absolute',
  right: '50%',
  textAlign: 'center',
  top: '50%',
  transform: 'translate(50%, -50%)',
  width: '420px'
}));

const DrawerButton = styled(Button)<SlideProps>(({ theme }) => ({
  color: 'white',
  marginLeft: theme.spacing(0.5),
  marginRight: theme.spacing(0.5),
  width: '100%'
}));

type UserType = {
  email: string;
  firstName: string;
  id: string;
  lastName: string;
  phoneNumber: string;
};

interface DataList {
  actualEnd: string;
  actualStart: string;
  actualTotal: number;
  clientName: string;
  closedBy?: string;
  closer?: UserType;
  companyName: string;
  day: string;
  id: number;
  laborHourCost: string;
  laborTotalCost: string;
  millisecondsWorked: string;
  projectName: string;
  projectType: string;
  scheduledEnd: string;
  scheduledStart: string;
  scheduledTotal: number;
  startedBy?: string;
  starter?: UserType;
  status: string;
  taskName: string;
  technician: string;
  technicianInformation: UserType;
  travelTime: number;
  travelTimeInMilliseconds: string;
  type: string;
  typeText: string;
  variance: number;
  varianceActual: string;
  variancePercentage: string;
}

interface Technician {
  dataList: DataList[];
  email: string;
  hourlyRate: string;
  id: string;
  phoneNumber: string;
  technician: string;
  technicianPaymentTotal: string;
  totalHoursScheduled: number;
  totalHoursWorked: number;
}

interface TimesheetReviewTableProps {
  data: Technician[];
  isLoading: boolean;
  isTechnician?: boolean;
  setUpdateDecisionData: React.Dispatch<any>;
  setUserIndexSelect: React.Dispatch<number>;
  technician: Technician;
}

const smallCell = '1%';

function CustomTable(props: {
  boxHeight: number;
  confirmModal: (val: any) => void;
  isTechnician: boolean;
  rows: DataList[];
  technician?: Technician;
}) {
  const { rows, confirmModal, boxHeight, isTechnician, technician } = props;
  const expandedCellRef: RefObject<HTMLDivElement> =
    useRef<HTMLDivElement>(null);
  const [expandedCellHeight, setExpandedCellHeight] = React.useState<number>(0);
  const theme = useTheme();
  const { t } = useTranslation();
  const [openRow, setOpenRow] = useState<number | null>(null);
  const [inputValues, setInputValues] = useState<{
    [key: number]: number | string;
  }>({});

  const toggleRow = (index: number) => {
    if (openRow === index) {
      setOpenRow(null);
      return;
    }
    setOpenRow(index);
  };

  const handleInputChange = (index: number, value: string) => {
    setInputValues((prevValues) => ({
      ...prevValues,
      [index]: value
    }));
  };

  useEffect(() => {
    if (rows) {
      rows.map((item, index) =>
        handleInputChange(index, item.millisecondsWorked)
      );
    }
  }, [rows]);

  function ListServiceAndTasks({ dataRow }: { dataRow: DataList[] }) {
    return (
      <Box display="flex" flexDirection="column" padding="0px 10px 10px 10px">
        {dataRow.map((item: any, index: number) => (
          <Typography fontSize={15} variant="body1">
            <strong>Service: </strong>{' '}
            {`${item.serviceName || 'No data to display'}`} <br />
            <strong>Task: </strong>{' '}
            {`${
              item.projectType === 'Regular'
                ? item.originalTask
                    .map((element: any) => element.name)
                    ?.join(', ') || 'No data to display'
                : item.originalTask || 'No data to display'
            }`}
          </Typography>
        ))}
      </Box>
    );
  }

  const handleValueInput = (value: any) => {
    let millisecondsWorked = value;
    if (typeof millisecondsWorked === 'string') {
      if (!millisecondsWorked.includes(':')) {
        millisecondsWorked = decimalsToHours(millisecondsWorked);
      }
    }
    if (typeof millisecondsWorked === 'number') {
      millisecondsWorked = decimalsToHours(millisecondsWorked);
    }
    return millisecondsWorked;
  };

  return (
    <TableContainer
      style={{
        border: `1px solid ${theme.palette.secondary[600]}`,
        marginTop: 20,
        maxHeight: '100vh',
        overflowX: isMobile ? 'scroll' : 'initial'
      }}
    >
      <Table stickyHeader>
        <TableHead
          sx={[
            !isMobile && {
              position: 'sticky',
              top: `${boxHeight + 90}px`,
              zIndex: 1
            }
          ]}
        >
          <TableRow>
            <TableCell align="center"> {`${t('TIMESHEET.date')}`}</TableCell>
            <TableCell align="center">
              {' '}
              {`${t('TIMESHEET.assignment')}`}
            </TableCell>
            <TableCell align="center">
              {`${t('TIMESHEET.travelTime')}`}
              <br />
              {`${t('TIMESHEET.hhmmss')}`}
            </TableCell>
            <TableCell align="center">
              {`${t('TIMESHEET.schedule')}`}
              <br />
              {`${t('TIMESHEET.hhmm')}`}
            </TableCell>
            <TableCell align="center">
              {`${t('TIMESHEET.actual')}`}
              <br />
              {`${t('TIMESHEET.hhmmss')}`}
            </TableCell>
            <TableCell align="center">
              {`${t('TIMESHEET.variance')}`}
              <br />
              {`${t('TIMESHEET.hhmm')}`}
            </TableCell>
            {!isTechnician && <TableCell width={smallCell} />}
            {!isTechnician && <TableCell width={smallCell} />}
            <TableCell width={smallCell} />
          </TableRow>
        </TableHead>
        {rows.length > 0 ? (
          <TableBody>
            {rows.map((item, index) => {
              return (
                <>
                  <TableRow
                    sx={{
                      '& > *': { borderBottom: 'unset' },
                      ...(!isMobile &&
                        openRow === index && {
                          '&::after': {
                            bottom: `-${expandedCellHeight}px`,
                            boxShadow: '#DEDEDE 0px 0px 20px',
                            content: "''",
                            left: 0,
                            pointerEvents: 'none',
                            position: 'absolute',
                            right: 0,
                            top: 0
                          }
                        }),
                      position: 'relative'
                    }}
                  >
                    <TableCell align="center" onClick={() => toggleRow(index)}>
                      {item?.day}
                    </TableCell>

                    <TableCell align="center" onClick={() => toggleRow(index)}>
                      {`${item?.clientName}  ${item?.projectName}  ${
                        item?.projectType === 'Ad-Hoc'
                          ? ` - ${t('TIMESHEET.adhoc')}`
                          : ''
                      }   `}
                      <CustomTooltip
                        backgroundColor={theme.palette.grey[600]}
                        borderColor={theme.palette.grey[600]}
                        component={<ListServiceAndTasks dataRow={[item]} />}
                      >
                        <div
                          style={{
                            display: 'inline-flex'
                          }}
                        >
                          <i
                            className="custom-icon icon-info"
                            style={{
                              color: `${theme.palette.informative[600]}`,
                              fontSize: 21,
                              position: 'relative',

                              top: '3px'
                            }}
                          />
                        </div>
                      </CustomTooltip>
                    </TableCell>

                    <TableCell align="center" onClick={() => toggleRow(index)}>
                      {item?.travelTimeInMilliseconds}
                    </TableCell>
                    <TableCell align="center" onClick={() => toggleRow(index)}>
                      {decimalsToHours(item?.scheduledTotal)}
                    </TableCell>
                    <TableCell align="center">
                      {isTechnician ? (
                        // decimalsToHours(item?.actualTotal)
                        item?.millisecondsWorked
                      ) : (
                        <RegularInput
                          customStyle={{ width: '100px' }}
                          onChange={(e) => handleInputChange(index, e)}
                          type="text"
                          value={handleValueInput(inputValues[index]) || ''}
                        />
                      )}
                    </TableCell>
                    <TableCell align="center" onClick={() => toggleRow(index)}>
                      {item?.projectType === 'Ad-Hoc'
                        ? decimalsToHours(item?.variance)
                        : formatSumTime(item?.variance)}
                    </TableCell>

                    {!isTechnician && (
                      <TableCell align="center">
                        <Tooltip arrow title="Reject Actual">
                          <Button
                            onClick={() => {
                              setOpenRow(null);
                              let actualTotal = item.scheduledTotal;
                              if (actualTotal < 0) {
                                actualTotal *= -1;
                              }
                              actualTotal = Number(actualTotal.toFixed(2));
                              confirmModal({
                                actualTotal,
                                approvalStatus: 'Denied',
                                taskIterationId: item?.id,
                                type: item?.type
                              });
                            }}
                          >
                            <Typography
                              color="alert.main"
                              variant="primary-bold"
                            >
                              <i className="custom-icon icon-clear" />
                            </Typography>
                          </Button>
                        </Tooltip>
                      </TableCell>
                    )}

                    {!isTechnician && (
                      <TableCell align="center">
                        <Tooltip arrow title="Accept Actual">
                          <Button
                            onClick={() => {
                              setOpenRow(null);
                              let actualTotal = inputValues[index];
                              if (typeof actualTotal === 'string') {
                                if (actualTotal.includes(':')) {
                                  actualTotal = hoursToDecimals(actualTotal);
                                }
                                actualTotal = Number(actualTotal);
                              }
                              if (actualTotal < 0) {
                                actualTotal *= -1;
                              }
                              actualTotal = Number(actualTotal.toFixed(2));
                              confirmModal({
                                actualTotal,
                                approvalStatus: 'Approved',
                                taskIterationId: item?.id,
                                type: item?.type
                              });
                            }}
                          >
                            <Typography
                              color="success.main"
                              variant="primary-bold"
                            >
                              <i className="custom-icon icon-active" />
                            </Typography>
                          </Button>
                        </Tooltip>
                      </TableCell>
                    )}

                    <TableCell align="center" onClick={() => toggleRow(index)}>
                      {openRow === index ? (
                        <ChevronUpIcon />
                      ) : (
                        <ChevronDownIcon />
                      )}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell
                      colSpan={9}
                      style={{
                        backgroundColor: `${theme.palette.background.table}`,
                        marginBottom: 10,
                        paddingBottom: 0,
                        paddingTop: 0
                      }}
                    >
                      <Collapse
                        unmountOnExit
                        in={openRow === index}
                        onEntered={() =>
                          setExpandedCellHeight(
                            expandedCellRef.current?.clientHeight ?? 0
                          )
                        }
                        ref={expandedCellRef}
                        timeout="auto"
                      >
                        <TimesheetExpandedReviewRow dataList={item} />
                      </Collapse>
                    </TableCell>
                  </TableRow>
                </>
              );
            })}
          </TableBody>
        ) : (
          /* Empty state */
          <TableBody>
            <TableRow>
              <TableCell colSpan={9} sx={{ textAlign: 'center' }}>
                <Box
                  display="flex"
                  flexDirection="column"
                  gap={theme.spacing(1)}
                  padding={theme.spacing(8)}
                >
                  <Typography
                    color={theme.palette.secondary.main}
                    variant="primary-bold"
                  >
                    {t('TIMESHEET.noTimeVariance', [
                      technician?.technician ?? 'This Technician'
                    ])}
                  </Typography>
                  <Typography
                    color={theme.palette.secondary.main}
                    variant="primary"
                  >
                    {t('TIMESHEET.noTimeVarianceDescription')}
                  </Typography>
                </Box>
              </TableCell>
            </TableRow>
          </TableBody>
        )}
      </Table>
    </TableContainer>
  );
}

const TimesheetReviewTable: React.FC<TimesheetReviewTableProps> =
  function TimesheetReviewTable({
    data,
    setUpdateDecisionData,
    isLoading,
    isTechnician = false,
    setUserIndexSelect,
    technician
  }) {
    const boxRef: RefObject<HTMLDivElement> = useRef<HTMLDivElement>(null);
    const theme = useTheme();
    const { t } = useTranslation();
    const [confirmModal, setConfirmModal] = useState<boolean>(false);
    const [decisionData, setDecisionData] = useState<any>({});
    const [boxHeight, setBoxHeight] = useState<number>(0);
    const [projectType, setProjectType] = useState<string>('All');

    const adhocTasks = technician?.dataList?.filter(
      (item) => item.type === 'taskIteration'
    );
    const reoccurringTasks = technician?.dataList?.filter(
      (item) => item.type === 'calendarEventIteration'
    );
    const tableDataSet = useMemo(() => {
      if (projectType === 'taskIteration') return adhocTasks;
      if (projectType === 'calendarEventIteration') return reoccurringTasks;
      return technician?.dataList ?? [];
    }, [projectType, adhocTasks, reoccurringTasks]);
    const variances = technician?.dataList?.map((item) => {
      let formatedVariance = item.varianceActual
        ? Number(item.varianceActual)
        : 0;
      if (item.projectType === 'Regular') {
        formatedVariance = hoursToDecimals(formatSumTime(formatedVariance));
      }
      return formatedVariance;
    });
    const totalVariance = variances?.reduce((acc, variance) => {
      if (variance <= 0) {
        return acc + -1 * variance;
      }
      return acc + variance;
    }, 0);
    const varianceAverage = totalVariance / (technician?.dataList?.length || 0);

    const handleOpenModal = (data: any) => {
      setConfirmModal(true);
      setDecisionData(data);
    };

    useEffect(() => {
      const updateBoxHeight = () => {
        if (boxRef.current) {
          setBoxHeight(boxRef?.current?.clientHeight);
        }
      };
      updateBoxHeight();
      window.addEventListener('resize', updateBoxHeight);
      return () => {
        window.removeEventListener('resize', updateBoxHeight);
      };
    }, []);

    return (
      <Box>
        {isTechnician ? (
          <Box
            alignItems="center"
            display="flex"
            height="54px"
            justifyContent="flex-start"
            marginTop="20px"
            sx={{
              position: 'sticky',
              top: 0,
              zIndex: 2
            }}
            width="100%"
          >
            <Typography variant="h5">{technician.technician}</Typography>
          </Box>
        ) : (
          <Box
            alignItems="center"
            display="flex"
            flexDirection="row"
            gap={2}
            justifyContent="flex-start"
            sx={{
              backgroundColor: theme.palette.common.white,
              position: 'sticky',
              top: 0,
              zIndex: 2
            }}
            width="100%"
          >
            {/* Resources */}
            <Box>
              <FormLabel label={t('TIMESHEET.resources')} />
              <FilterSelect
                label={t('TIMESHEET.resources')}
                onChange={(event) =>
                  setUserIndexSelect(
                    data.findIndex((val) => val.id === event.target.value)
                  )
                }
                options={data.map((item) => ({
                  ...item,
                  name: `${item.technician} (${item.dataList?.length ?? 0})`
                }))}
                sx={{
                  backgroundColor: theme.palette.background.default
                }}
                value={technician.id}
                width="100%"
              />
            </Box>

            {/* Project Type */}
            <Box>
              <FormLabel label={t('TIMESHEET.projectType')} />
              <FilterSelect
                label={t('TIMESHEET.projectType')}
                onChange={(event) => {
                  setProjectType(event.target.value);
                }}
                options={[
                  { id: 'All', name: 'All' },
                  { id: 'taskIteration', name: 'Ad-Hoc' },
                  { id: 'calendarEventIteration', name: 'Regular' }
                ]}
                sx={{
                  backgroundColor: theme.palette.background.default
                }}
                value={projectType}
                width="100%"
              />
            </Box>

            {/* Phone */}
            <Button
              href={`tel:${technician.phoneNumber}`}
              sx={[!isMobile && { marginLeft: 'auto' }]}
            >
              <Typography color="secondary.400" variant="primary-bold">
                <i className="custom-icon icon-classic-phone" />
              </Typography>
            </Button>

            {/* Email */}
            <Button href={`mailto:${technician.email}`}>
              <Typography color="secondary.400" variant="primary-bold">
                <i className="custom-icon icon-email" />
              </Typography>
            </Button>
          </Box>
        )}
        <Grid
          container
          paddingY={theme.spacing(3)}
          ref={boxRef}
          sx={[
            {
              backgroundColor: theme.palette.common.white,
              gap: theme.spacing(4)
            },
            !isMobile && {
              position: 'sticky',
              top: '90px',
              zIndex: 2
            }
          ]}
          xs={12}
        >
          <Grid item xs sx={{ display: 'inline-flex' }}>
            <Tile
              isLoading={isLoading}
              key="dashboard-tile-hoursSheduled"
              title={t('TIMESHEET.totalTasks')}
              type="Counter"
              value={technician.dataList.length.toString()}
            />
          </Grid>
          <Grid item xs sx={{ display: 'inline-flex' }}>
            <Tile
              isLoading={isLoading}
              key="dashboard-tile-hoursSheduled"
              title={t('TIMESHEET.totalTime')}
              type="Counter"
              value={decimalsToHours(technician.totalHoursScheduled)}
            />
          </Grid>
          <Grid item xs sx={{ display: 'inline-flex' }}>
            <Tile
              isLoading={isLoading}
              key="dashboard-tile-hoursSheduled"
              title={t('TIMESHEET.totalWorked')}
              type="Counter"
              value={decimalsToHours(technician.totalHoursWorked)}
            />
          </Grid>
          <Grid item xs sx={{ display: 'inline-flex' }}>
            <Tile
              isLoading={isLoading}
              key="dashboard-tile-hoursWorked"
              title={t('TIMESHEET.varianceAverage')}
              type="Counter"
              value={decimalsToHours(varianceAverage)}
            />
          </Grid>
          <Grid item xs sx={{ display: 'inline-flex' }}>
            <Tile
              isLoading={isLoading}
              key="dashboard-tile-hoursWorked"
              title={t('TIMESHEET.varianceTotal')}
              type="Counter"
              value={decimalsToHours(totalVariance)}
            />
          </Grid>
        </Grid>

        <CustomTable
          boxHeight={boxHeight}
          confirmModal={(val: any) => handleOpenModal(val)}
          isTechnician={isTechnician}
          rows={tableDataSet}
          technician={technician}
        />

        <Modal onClose={() => setConfirmModal(false)} open={confirmModal}>
          <ModalContainer>
            <Typography color="secondary.400" variant="modalTitle">
              <i
                className="custom-icon icon-info"
                style={{
                  color:
                    decisionData.approvalStatus === 'Approved'
                      ? theme.palette.primary.main
                      : theme.palette.error.main,
                  fontSize: 40
                }}
              />
              <br />
              <br />
              {`
            ${decimalsToHours(
              decisionData.actualTotal
            )} will set as actual time`}
            </Typography>
            <br />
            <ButtonContainerModal>
              <>
                <DrawerButton
                  onClick={() => {
                    setConfirmModal(false);
                    setDecisionData({});
                  }}
                  sx={{ height: 46, width: 114 }}
                  variant={
                    decisionData.approvalStatus === 'Approved'
                      ? 'primary-outlined'
                      : 'destructive-outlined'
                  }
                >
                  <Typography
                    color={
                      decisionData.approvalStatus === 'Approved'
                        ? 'primary'
                        : 'error'
                    }
                  >
                    {t('MODAL.cancel')}
                  </Typography>
                </DrawerButton>
                <DrawerButton
                  onClick={() => {
                    setUpdateDecisionData(decisionData);
                    setConfirmModal(false);
                    setDecisionData({});
                  }}
                  sx={{ height: 46, width: 114 }}
                  variant={
                    decisionData.approvalStatus === 'Approved'
                      ? 'primary'
                      : 'destructive'
                  }
                >
                  <Typography>
                    {decisionData.approvalStatus === 'Approved'
                      ? 'Confirm'
                      : 'Reject'}
                  </Typography>
                </DrawerButton>
              </>
            </ButtonContainerModal>
          </ModalContainer>
        </Modal>
      </Box>
    );
  };

export default TimesheetReviewTable;
