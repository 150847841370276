/* eslint-disable no-underscore-dangle */
/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-unused-vars */
/* eslint-disable no-nested-ternary */
/* eslint-disable react/require-default-props */
/* eslint-disable react/no-array-index-key */

import React, { useEffect, useState } from 'react';
import {
  Backdrop,
  Box,
  Container,
  Modal,
  Typography,
  styled,
  TableHead,
  Checkbox,
  Avatar,
  Button,
  Tooltip,
  TableContainer,
  Table,
  TableRow,
  TableCell,
  TableBody,
  CircularProgress,
  IconButton,
  Collapse,
  useTheme
} from '@mui/material';
import useSWR from 'swr';

import { useTranslation } from 'react-i18next';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import {
  accentColors,
  mainColors,
  secondaryColors,
  successColors,
  surfaceColors,
  textColors,
  themePalette,
  warningColors
} from '../../../theme/themeConfig';
import ImageViewer from '../../../components/ImageViewer';
import Api from '../../../api';
import {
  DrawerButton,
  ModalContainer
} from '../../../components/modals/GlobalConfirmModal';
import { TimeFormatEnum } from '../../../api-client/generated/api';
import {
  capitalizeString,
  downloadAll,
  getFormatedTimeByTimeZone,
  resourseColors,
  taskInteractionStyles,
  TaskStatus,
  TaskStyle
} from '../../../utils/general';
import TimeComponent from '../../../components/input/TimeComponent';
import ImageCarousel from './components/ImageCarousel';
import { getWorkedTimeAverageTime } from '../../../utils/calculatorHelper';
import useAuthStore from '../../../store/zustand/auth';
import Notes from '../Notes';
import useNavigationStore from '../../../store/zustand/navigation';
import ShowInformationIndicator from '../../../components/modals/ShowInformationIndicator';
import ProjectTaskDetails from './ProjectTaskDetails';
import useNotificationsStore from '../../../store/zustand/notifications';

const TaskImageListContainer = styled(Box)(({ theme }) => ({
  display: 'grid',
  gap: theme.spacing(1.5),
  gridTemplateColumns: `repeat(auto-fill, minmax(${theme.spacing(7)}, 2fr))`,
  padding: theme.spacing(0.5)
}));

const ListHead = styled(TableHead)(({ theme }) => ({
  '.MuiTableHead-root': { boxShadow: 0 },
  display: 'flex',
  width: '100%'
}));

const ListTitle = styled(Typography)(({ theme }) => ({
  color: textColors.DARK_TEXT,
  fontFamily: 'Lexend',
  fontSize: '28px',
  fontWeight: '600',
  marginLeft: '1rem'
}));

const TaskImageContainer = styled(Box)(({ theme }) => ({
  '&:hover': {
    transform: 'scale(1.1)'
  },
  alignContent: 'center',
  alignItems: 'center',
  border: `1px solid ${theme.palette.secondary[100]}`,
  cursor: 'pointer',
  display: 'flex',
  height: theme.spacing(7),
  justifyContent: 'center',
  marginRight: '5px',
  overflow: 'hidden',
  transition: '1s ease',
  width: theme.spacing(7)
}));

interface IServiceTableBlockCheck {
  isCheked: boolean;
  text: string;
}

export interface NoteResponseDTO {
  createdAt: string;
  id: string;
  noteText: string;
  updatedAt: string;
  user: object;
}

interface IServiceTableBlock {
  checklist?: Array<IServiceTableBlockCheck> | [];
  images?: any;
  materials?: any;
  note?: any;
  taskIterationID?: any;
  title: string;
  type: string;
}
type VariantType = 'secondary-bold' | 'secondary';

interface IServiceTableRowTD {
  children?: any;
  colorH: string;
  fontSize: string;
  text?: string;
  typeTx?: VariantType;
  // width: number;
}

interface Props {
  calendarEvenState: string | undefined;
  dashboard: any;
  data: any;
  mutateAction: any;
  onlyTask: boolean;
  serviceInfo: any;
  setShowModal: (v: boolean) => void;
}

const ProjectTaskDetailsModal: React.FC<Props> =
  function ProjectTaskDetailsModal({
    calendarEvenState,
    dashboard,
    data,
    onlyTask,
    setShowModal,
    serviceInfo,
    mutateAction
  }) {
    const { t } = useTranslation();
    const theme = useTheme();
    const { addNotification } = useNotificationsStore((state) => state);
    const [openRow, setOpenRow] = useState<number | null>(null);
    const { setConfirmModal } = useNavigationStore((state) => state);
    const [auxManualClose, setAuxManualClose] = useState<boolean>(true);
    const userState = useAuthStore((state) => state);
    const getTaskStyle = (task: TaskStatus): TaskStyle => {
      return taskInteractionStyles[task] || taskInteractionStyles.awaiting;
    };
    const [loading, setLoading] = useState<boolean>(false);

    const toggleRow = (index: number) => {
      if (openRow === index) {
        setOpenRow(null);
        return;
      }
      setOpenRow(index);
    };

    const showTaskIterationButton = () => {
      // Check if at least one task iteration exists and is not completed
      const hasIncompleteTaskIteration = data?.taskIteration?.some(
        (task: { status: string }) => task?.status !== 'completed'
      );
      // Show the button only if there is an incomplete task iteration
      return !!data?.taskIteration?.length && hasIncompleteTaskIteration;
    };

    const handleCompleteTask = async (index: number) => {
      try {
        if (
          !data.taskIteration[index].id ||
          !data.taskIteration[index].technician.id
        ) {
          throw new Error('Task iteration or technician not found');
        }

        setLoading(true);

        await Api.CalendarApi.patchTechnicianTaskManagenmentCloseTask({
          taskIterationId: data?.taskIteration[index].id,
          technicianId: data?.taskIteration[index].technician.id
        });

        mutateAction();
        addNotification(t('PROJECT.TOAST.SUCCESS'), 'success');

        setLoading(false);
        setShowModal(false);
      } catch (error: any) {
        addNotification(t('PROJECT.TOAST.FAIL'), 'error');

        setLoading(false);
      }
    };

    function GetCompleteTaskButton(index: number) {
      return (
        <Button
          onClick={() => {
            setConfirmModal(
              async () => {
                await handleCompleteTask(index);
              },
              `${t('GENERAL.confirmButton')}`,
              () => {},
              `${t('GENERAL.cancelButton')}`,
              <Box
                alignItems="center"
                display="flex"
                flexDirection="column"
                gap={2}
                justifyContent="center"
                pt={2}
                width={350}
              >
                <Typography variant="heading_500">
                  {t('PROJECT.TASKDETAILSMODAL.completeTask')}
                </Typography>

                <Typography sx={{ whiteSpace: 'pre-line' }} variant="body_300">
                  {t('PROJECT.TASKDETAILSMODAL.completeTaskDescription')}
                </Typography>
              </Box>
            );
          }}
          sx={{ maxWidth: '150px' }}
          variant="primary-text"
        >
          <Box alignItems="center" display="flex" gap="10px">
            <CircularProgress
              size={14}
              sx={{ display: loading ? 'block' : 'none' }}
            />
            <Typography fontSize="12px" variant="heading_328">
              {t('PROJECT.TASKDETAILSMODAL.completeTaskButton')}
            </Typography>
          </Box>
        </Button>
      );
    }

    function ServiceTableBlockCheck({
      isCheked,
      text
    }: IServiceTableBlockCheck) {
      return (
        <Box
          style={{
            alignContent: 'center',
            alignItems: 'center',
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'flex-start'
          }}
        >
          <Checkbox
            disabled
            checked={isCheked}
            onChange={() => {}}
            size="small"
          />
          <Typography
            align="left"
            style={{ color: '#4B4D56', fontSize: '12px' }}
          >
            {text}
          </Typography>
        </Box>
      );
    }

    function ServiceTableBlock({
      title,
      type,
      materials,
      taskIterationID,
      checklist,
      note,
      images
    }: IServiceTableBlock) {
      const [modalOpen, setModalOpen] = useState(false); // Estado para controlar si el modal está abierto
      const [selectedNote, setSelectedNote] = useState<NoteResponseDTO[]>([]);
      const [fireCreateNote, setFireCreateNote] = useState<boolean>(false);
      const [noteText, setNoteText] = useState<string>('');
      const [currentTaskDetails, setCurrentTaskDetails] = useState<any>({});
      const [imgData, setImgData] = useState('');
      const [isImageViewerOpen, setIsImageViewerOpen] = useState(false);

      const { data: createTaskNoteData, error: createTaskNoteDataError } =
        useSWR(
          fireCreateNote && noteText !== '' && taskIterationID
            ? {
                entity: 'taskIteration',
                entityId: `${taskIterationID}`,
                noteText: `${noteText}`,
                taskIterationId: Number(taskIterationID) ?? 0
              }
            : null,
          Api.CalendarApi.noteCreate
        );

      // Función para manejar el clic en el icono de agregar
      const handleAddIconClick = (note: any) => {
        setSelectedNote(note); // Establece la nota seleccionada
        setModalOpen(true); // Abre el modal
      };

      useEffect(() => {
        if (createTaskNoteData && !createTaskNoteDataError) {
          const auxNotes = [...selectedNote, createTaskNoteData];
          setSelectedNote(auxNotes);
          setNoteText('');
          setFireCreateNote(false);
        }
      }, [createTaskNoteData, createTaskNoteDataError, selectedNote, noteText]);

      return (
        <Box>
          {type === 'materials' && (
            <Box style={{ padding: '12px' }}>
              {materials &&
                materials?.map((index: any, key: number) => (
                  <Typography
                    component="li"
                    key={key}
                    style={{
                      color: `${secondaryColors.SECONDARY_5}`,
                      fontSize: '12px'
                    }}
                    variant="secondary"
                  >
                    {index?.name}
                  </Typography>
                ))}
            </Box>
          )}

          {type === 'note' && (
            <>
              <Box>
                <Box
                  style={{
                    display: 'flex'
                  }}
                >
                  {calendarEvenState !== 'Created' && (
                    <Box display="flex" flexDirection="column">
                      <Typography
                        color={`${secondaryColors.SECONDARY}`}
                        fontSize={12}
                        variant="secondary"
                      >
                        {/* Todo: translation... */}
                        Notes
                      </Typography>
                      <Box
                        alignItems="center"
                        display="flex"
                        gap={theme.spacing(2)}
                        justifyContent="center"
                      >
                        <Typography
                          color={`${textColors.BLACK}`}
                          fontSize={12}
                          variant="secondary"
                        >{`${note?.length ?? 0}`}</Typography>
                        <IconButton
                          aria-label="view notes"
                          onClick={() => handleAddIconClick(note)}
                          size="small"
                          sx={{ height: '20px', width: '20px' }}
                        >
                          <VisibilityOutlinedIcon
                            color="primary"
                            sx={{ height: '20px', width: '20px' }}
                          />
                        </IconButton>
                      </Box>
                    </Box>
                  )}
                </Box>
              </Box>
              <Box>
                {modalOpen && (
                  <Modal
                    onClose={() => {
                      setModalOpen(false);
                    }}
                    open={modalOpen}
                    sx={{
                      alignItems: 'center',
                      display: 'flex',
                      justifyContent: 'center'
                    }}
                  >
                    <Box
                      sx={{
                        backgroundColor: surfaceColors.SURFACE_6,
                        borderRadius: 2,
                        height: 600,
                        padding: '32px 32px 16px 32px',
                        width: 800
                      }}
                    >
                      <Notes
                        hideDeleteButton
                        technicianNotes
                        entity="taskIteration"
                        id={taskIterationID}
                      />
                    </Box>
                  </Modal>
                )}
              </Box>
            </>
          )}

          {type === 'checklist' && (
            <Box>
              {checklist &&
                checklist?.map((index: any, key: number) => {
                  return (
                    <ServiceTableBlockCheck
                      isCheked={index?.checked}
                      key={key}
                      text={index?.name}
                    />
                  );
                })}
            </Box>
          )}

          {type === 'images' && (
            <Box display="flex" flexDirection="column">
              <Typography
                color={`${secondaryColors.SECONDARY}`}
                fontSize={12}
                variant="secondary"
              >
                Images
              </Typography>
              <Typography
                color={`${textColors.BLACK}`}
                fontSize={12}
                variant="secondary"
              >{`${images.length ?? 0}`}</Typography>

              {/* Todo: Move to collapsed view... */}
              {/*
              <ImageCarousel
                images={images}
                openViewer={setIsImageViewerOpen}
                setImageUrlViewer={setImgData}
              /> */}
              {/* <TaskImageListContainer>
                {images.map((image: any, key: number) => {
                  return (
                    <Box
                      style={{
                        alignContent: 'center',
                        alignItems: 'center',
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'flex-start'
                      }}
                    >
                      <TaskImageContainer
                        onClick={() => {
                          setIsImageViewerOpen(true);
                          setImgData(image?.large || image?.fileUrl);
                        }}
                      >
                        <img
                          alt={image.fileName}
                          key={`taskIteration-image-${image.id}}`}
                          src={image.small || image.fileUrl}
                        />
                      </TaskImageContainer>
                    </Box>
                  );
                })}
              </TaskImageListContainer> */}
              {/* Move to collapsed view... */}
              {/* <Box>
                {isImageViewerOpen && imgData !== '' && (
                  <ImageViewer
                    isOpen={isImageViewerOpen}
                    setIsOpen={setIsImageViewerOpen}
                    url={imgData}
                  />
                )}
              </Box> */}
            </Box>
          )}
        </Box>
      );
    }

    function ServiceTableRowTD({
      fontSize,
      colorH,
      typeTx,
      text,
      children
    }: IServiceTableRowTD) {
      return (
        <Box
          style={{
            // border: 'solid 0.1px rgba(203, 204, 211, 0.7)',
            // color: `${secondaryColors.SECONDARY}`,
            height: '100%'
            // width: `${width}%`
          }}
        >
          <Typography color={colorH} fontSize={fontSize} variant={typeTx}>
            {text}
            {children}
          </Typography>
        </Box>
      );
    }

    // Note: unused in Feb. 2025 redesign...
    function ServiceTableHeader() {
      return (
        <TableRow>
          <TableCell align="center" colSpan={0} width={120}>
            <ServiceTableRowTD
              colorH={`${themePalette.TITLE}`}
              fontSize="12px"
              text="TECHNICIAN"
              typeTx="secondary-bold"
            />
          </TableCell>
          <TableCell
            align="center"
            colSpan={0}
            sx={{ padding: '5px' }}
            width={70}
          >
            <ServiceTableRowTD
              colorH={`${themePalette.TITLE}`}
              fontSize="12px"
              text="ESTIMATED TIME"
              typeTx="secondary-bold"
            />
          </TableCell>
          <TableCell
            align="center"
            colSpan={0}
            sx={{ padding: '5px' }}
            width={70}
          >
            <ServiceTableRowTD
              colorH={`${themePalette.TITLE}`}
              fontSize="12px"
              text="STATUS"
              typeTx="secondary-bold"
            />
          </TableCell>
          <TableCell
            align="center"
            colSpan={0}
            sx={{ padding: '5px' }}
            width={140}
          >
            <ServiceTableRowTD
              colorH={`${themePalette.TITLE}`}
              fontSize="12px"
              text="CHECKLIST"
              typeTx="secondary-bold"
            />
          </TableCell>
          <TableCell
            align="center"
            colSpan={0}
            sx={{ padding: '5px' }}
            width={80}
          >
            <ServiceTableRowTD
              colorH={`${themePalette.TITLE}`}
              fontSize="12px"
              text="TIME"
              typeTx="secondary-bold"
            />
          </TableCell>
          <TableCell
            align="center"
            colSpan={0}
            sx={{ padding: '5px' }}
            width={140}
          >
            <ServiceTableRowTD
              colorH={`${themePalette.TITLE}`}
              fontSize="12px"
              text="GEOFENCING"
              typeTx="secondary-bold"
            />
          </TableCell>
          <TableCell
            align="center"
            colSpan={0}
            sx={{ padding: '5px' }}
            width={140}
          >
            <ServiceTableRowTD
              colorH={`${themePalette.TITLE}`}
              fontSize="12px"
              text="IMAGES"
              typeTx="secondary-bold"
            />
          </TableCell>
          <TableCell
            align="center"
            colSpan={0}
            sx={{ padding: '5px', width: '120px' }}
          >
            <ServiceTableRowTD
              colorH={`${themePalette.TITLE}`}
              fontSize="12px"
              text="NOTES"
              typeTx="secondary-bold"
            />
          </TableCell>
          {showTaskIterationButton() && (
            <TableCell
              align="center"
              colSpan={0}
              sx={{ padding: '5px', width: '150px' }}
            >
              <ServiceTableRowTD
                colorH={`${themePalette.TITLE}`}
                fontSize="12px"
                text=""
                typeTx="secondary-bold"
              />
            </TableCell>
          )}
        </TableRow>
      );
    }

    const sumWorkedTime =
      data?.taskIteration?.length > 0
        ? getWorkedTimeAverageTime(data?.taskIteration)
        : 0;

    return (
      <Container
        sx={{
          alignItems: 'normal',
          margin: '40px',
          // padding: '40px'
          width: '90% !important'
        }}
      >
        <Modal
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500
          }}
          onClose={() => {
            setShowModal(false);
          }}
          open={auxManualClose}
          sx={{
            alignItems: 'center'
          }}
        >
          <ModalContainer
            sx={{
              '@media (min-width: 600px)': {
                minWidth: '600px'
              },
              alignItems: 'center',
              // padding: '7rem',
              position: 'fixed',
              width: '90%  !important'
            }}
          >
            <Box
              justifyContent="space-between"
              sx={{
                display: 'flex',
                justifyContent: 'center',
                width: '100% !important'
              }}
            >
              <Box
                style={{
                  textAlign: 'left',
                  width: '90% '
                }}
              >
                <Box>
                  <Typography
                    color="#005DA2"
                    fontSize={14}
                    style={{
                      lineHeight: '20px'
                    }}
                    variant="secondary-bold"
                  >
                    {data.name} {t('PROJECT.TASKDETAILSMODAL.details')}
                  </Typography>
                </Box>
                <Box sx={{ display: 'flex', marginBottom: '1rem' }}>
                  <Typography
                    fontSize={12}
                    fontWeight={800}
                    sx={{ marginRight: '0.5rem' }}
                    variant="primary-bold"
                  >
                    Total task estimated time:{' '}
                  </Typography>
                  <Typography fontSize={12} fontWeight={600} variant="primary">
                    <TimeComponent
                      readOnly
                      setTime={() => {}}
                      time={
                        data.totalTimeInHours /
                          (data?.technicians.length || 1) || 0.0
                      }
                    />
                  </Typography>
                  <Typography
                    fontSize={12}
                    fontWeight={800}
                    sx={{ marginLeft: '2rem', marginRight: '0.5rem' }}
                    variant="primary-bold"
                  >
                    Total worked time:{' '}
                  </Typography>
                  <Typography fontSize={12} fontWeight={600} variant="primary">
                    <TimeComponent
                      readOnly
                      setTime={() => {}}
                      time={sumWorkedTime / 60 || 0.0}
                    />
                  </Typography>
                </Box>

                <TableContainer
                  sx={{
                    border: 1,
                    borderColor: theme.palette.grey[100],
                    borderRadius: 2,
                    maxHeight: '70vh',
                    padding: 2
                  }}
                >
                  <Table>
                    <TableBody>
                      {data?.technicians?.map((technician: any, index: any) => {
                        const auxName =
                          technician?.name ||
                          `${technician.firstName} ${technician.lastName}`;
                        const initials = auxName
                          ?.split(' ')
                          ?.slice(0, 2)
                          ?.map((word: string) => word[0]?.toUpperCase())
                          ?.join('');
                        const auxKey = `detail-project-team-${technician.id}`;
                        const iteration =
                          data?.taskIteration?.find(
                            (taskIte: any) =>
                              taskIte.technician.id === technician.id
                          ) || null;
                        const taskIterationTechnician = [
                          {
                            closedBy: iteration?.closedBy,
                            closer: iteration?.closer,
                            startedBy: iteration?.startedBy,
                            starter: iteration?.starter,
                            technician: iteration?.technician
                          }
                        ];

                        return (
                          <>
                            <TableRow>
                              <TableCell
                                align="center"
                                colSpan={3}
                                sx={{
                                  flexGrow: 1,
                                  padding: '5px'
                                }}
                              >
                                <Box
                                  key={auxKey}
                                  sx={{
                                    alignItems: 'center',
                                    display: 'flex'
                                  }}
                                >
                                  {initials && (
                                    <Tooltip arrow title={technician.name}>
                                      <Avatar
                                        alt={
                                          technician.name
                                            ? `${technician.name}`
                                            : `${technician.firstName} ${technician.lastName}`
                                        }
                                        sx={{
                                          backgroundColor:
                                            resourseColors[index],
                                          cursor: 'pointer',
                                          fontSize: '13px',
                                          height: 30,
                                          marginRight: '0.25px',
                                          width: 30
                                        }}
                                      >
                                        {technician.profilePic
                                          ? null
                                          : initials}
                                      </Avatar>
                                    </Tooltip>
                                  )}
                                  <Typography
                                    color={`${secondaryColors.SECONDARY}`}
                                    fontSize={onlyTask ? '10px' : '12px'}
                                    style={{
                                      alignItems: 'center',
                                      marginLeft: '0.5rem'
                                    }}
                                    variant="secondary"
                                  >{`${auxName}`}</Typography>
                                </Box>
                              </TableCell>
                              <TableCell
                                align="center"
                                colSpan={0}
                                sx={{
                                  maxWidth: '55px !important',
                                  padding: '5px'
                                }}
                              >
                                <Typography
                                  color={`${secondaryColors.SECONDARY}`}
                                  fontSize={12}
                                  style={{
                                    alignItems: 'center'
                                  }}
                                  variant="secondary"
                                >
                                  <TimeComponent
                                    readOnly
                                    short
                                    containerProps={{
                                      gap: 0
                                    }}
                                    setTime={() => {}}
                                    time={
                                      data.totalTimeInHours /
                                        (data?.technicians.length || 1) || 0.0
                                    }
                                  />
                                </Typography>
                              </TableCell>
                              <TableCell
                                align="center"
                                colSpan={0}
                                sx={{ padding: '5px' }}
                              >
                                <Box
                                  sx={{
                                    alignItems: 'center',
                                    display: 'flex',
                                    flexDirection: 'row',
                                    gap: 1
                                  }}
                                >
                                  <Box
                                    style={{
                                      backgroundColor: getTaskStyle(
                                        !iteration?.status
                                          ? 'awaiting'
                                          : iteration?.status
                                      )?.backgroundColor,
                                      border: `solid 2px ${
                                        getTaskStyle(
                                          !iteration?.status
                                            ? 'awaiting'
                                            : iteration?.status
                                        )?.borderColor
                                      }`,
                                      borderRadius: '12px',
                                      display: 'flex',
                                      height: '100%',
                                      justifyContent: 'center',
                                      width: '100%'
                                    }}
                                  >
                                    {iteration &&
                                    iteration?.status === 'completed' &&
                                    iteration?.workedTime ? (
                                      <Typography
                                        fontSize={11}
                                        style={{
                                          color: getTaskStyle(iteration?.status)
                                            .textColor
                                        }}
                                      >
                                        <TimeComponent
                                          readOnly
                                          short
                                          containerProps={{
                                            gap: 0
                                          }}
                                          setTime={() => {}}
                                          time={
                                            parseFloat(iteration.workedTime) /
                                              60 || 0.0
                                          }
                                        />
                                      </Typography>
                                    ) : (
                                      <Typography
                                        fontSize={11}
                                        style={{
                                          color: getTaskStyle(
                                            !iteration?.status
                                              ? 'awaiting'
                                              : iteration?.status
                                          ).textColor
                                        }}
                                      >
                                        {!iteration ||
                                        iteration?.status === 'created'
                                          ? 'Awaiting'
                                          : capitalizeString(
                                              !iteration?.status
                                                ? 'awaiting'
                                                : iteration?.status ===
                                                  'inProgress'
                                                ? 'In Progress'
                                                : iteration?.status
                                            )}
                                      </Typography>
                                    )}
                                  </Box>
                                  {iteration?.approvalStatus ===
                                    'ApprovalRequired' && (
                                    <Typography
                                      color="warning.main"
                                      sx={{
                                        alignItems: 'center',
                                        display: 'flex'
                                      }}
                                      variant="primary"
                                    >
                                      <Tooltip
                                        title={t(
                                          'TOOLTIP.PROJECT.taskApprovalRequired'
                                        )}
                                      >
                                        <i className="custom-icon icon-warning" />
                                      </Tooltip>
                                    </Typography>
                                  )}
                                </Box>
                              </TableCell>
                              <TableCell
                                align="center"
                                colSpan={0}
                                sx={{
                                  maxWidth: '65px !important',
                                  padding: '5px'
                                }}
                              >
                                {iteration?.startTime ? (
                                  <>
                                    <Typography
                                      color={`${secondaryColors.SECONDARY}`}
                                      fontSize={12}
                                      style={{
                                        alignItems: 'center'
                                      }}
                                      variant="secondary"
                                    >
                                      Start time:{' '}
                                      {getFormatedTimeByTimeZone(
                                        iteration?.startTime,
                                        0,
                                        userState.timeFormat ===
                                          TimeFormatEnum._24h
                                      )}
                                    </Typography>
                                    {iteration?.endTime && <br />}
                                    {iteration?.endTime && (
                                      <Typography
                                        color={`${secondaryColors.SECONDARY}`}
                                        fontSize={12}
                                        style={{
                                          alignItems: 'center'
                                        }}
                                        variant="secondary"
                                      >
                                        End time:{' '}
                                        {getFormatedTimeByTimeZone(
                                          iteration?.endTime,
                                          0,
                                          userState.timeFormat ===
                                            TimeFormatEnum._24h
                                        )}
                                      </Typography>
                                    )}
                                  </>
                                ) : (
                                  <Typography
                                    color={`${secondaryColors.SECONDARY}`}
                                    fontSize={12}
                                    variant="secondary"
                                  >
                                    --
                                  </Typography>
                                )}
                              </TableCell>
                              {/* Todo: move to collapsed view */}
                              {/* <TableCell
                              align="center"
                              colSpan={0}
                              sx={{ padding: '5px' }}
                            >
                              {iteration && (
                                <Typography
                                  color={
                                    iteration.geofencingApproved
                                      ? successColors.SUCCESS
                                      : warningColors.ERROR
                                  }
                                  fontSize={12}
                                  variant="secondary"
                                >
                                  <Tooltip
                                    title={`Latitude: ${
                                      iteration.lat || '-'
                                    }, Longitude: ${iteration.long || '-'}`}
                                  >
                                    <i className="custom-icon icon-location" />
                                  </Tooltip>
                                </Typography>
                              )}
                            </TableCell> */}
                              <TableCell
                                align="center"
                                colSpan={0}
                                sx={{ padding: '5px' }}
                              >
                                {iteration && (
                                  <Box
                                    key={iteration?.id}
                                    sx={{
                                      display: 'flex',
                                      justifyContent: 'center'
                                    }}
                                  >
                                    {/* Todo: remove this or move to collapsed view */}
                                    <ServiceTableBlock
                                      images={iteration?.fileBank}
                                      title="IMAGES"
                                      type="images"
                                    />
                                  </Box>
                                )}
                              </TableCell>
                              <TableCell
                                align="center"
                                colSpan={0}
                                sx={{ padding: '5px' }}
                              >
                                {iteration &&
                                  iteration?.status !== 'created' && (
                                    <React.Fragment key={iteration?.id}>
                                      <ServiceTableBlock
                                        note={iteration?.notes}
                                        taskIterationID={iteration?.id}
                                        title="NOTES"
                                        type="note"
                                      />
                                    </React.Fragment>
                                  )}
                              </TableCell>
                              <TableCell
                                align="center"
                                colSpan={0}
                                sx={{
                                  padding: '5px',
                                  textAlign: 'right'
                                }}
                              >
                                {iteration && (
                                  <IconButton
                                    aria-label="expand row"
                                    onClick={() => toggleRow(index)}
                                    size="small"
                                  >
                                    {openRow === index ? (
                                      <KeyboardArrowUpIcon />
                                    ) : (
                                      <KeyboardArrowDownIcon />
                                    )}
                                  </IconButton>
                                )}
                              </TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell
                                colSpan={9}
                                style={{
                                  border: 'unset',
                                  paddingBottom: 0,
                                  paddingTop: 0
                                }}
                                sx={{ maxWidth: '100%', width: '100%' }}
                              >
                                <Container>
                                  <Collapse
                                    unmountOnExit
                                    in={openRow === index}
                                    timeout="auto"
                                  >
                                    <ProjectTaskDetails
                                      callBack={() => {
                                        mutateAction();
                                        setShowModal(false);
                                      }}
                                      iteration={iteration}
                                      technician={technician}
                                    />
                                  </Collapse>
                                </Container>
                              </TableCell>
                            </TableRow>
                          </>
                        );
                      })}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Box>
            </Box>
          </ModalContainer>
        </Modal>
      </Container>
    );
  };

export default ProjectTaskDetailsModal;
