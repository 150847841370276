import {
  LeadManagementApi,
  LeadManagementApiLeadFindAllPaginateRequest,
  LeadServiceTimesApi,
  LeadServiceTimesApiLeadServiceTimeCreateRequest,
  DestinationApi,
  DestinationApiDestinationCreateNewDestinationRequest
} from '../../api-client/generated/api';
import { weekDays } from '../../helpers/weekDays';

const getAllLead = async () => {
  return {};
};

// TODO: Enviar junto con respuesta un string de donde se quedo.
const createLead = async (params: any) => {
  if (!params) {
    return null;
  }
  const {
    clientId,
    description,
    manualPricing,
    name,
    endDate,
    startDate,
    projectType
  } = params;

  const apiCaller = new LeadManagementApi();
  let newEndDate;
  if (endDate && typeof endDate === 'string') {
    newEndDate = endDate;
  } else if (endDate) {
    newEndDate = endDate.format('YYYY-MM-DD');
  } else {
    newEndDate = null;
  }
  const responseData = await apiCaller.leadCreate({
    createLeadGeneralInfoDto: {
      clientId,
      description,
      manualPricing,
      name,
      projectEndDate: newEndDate,
      projectStartDate:
        typeof startDate === 'string'
          ? startDate
          : startDate.format('YYYY-MM-DD'),
      projectType
    }
  });
  const { data } = responseData;
  return data;
};
const getLeadsPaginated = async (
  params: LeadManagementApiLeadFindAllPaginateRequest
) => {
  if (!params) {
    return null;
  }
  const apiCaller = new LeadManagementApi();
  const { data }: any = await apiCaller.leadGetLeadsPaginatedForListing(params);
  return data;
};

const getLead = async (id: string) => {
  if (!id) {
    return null;
  }
  const apiCaller = new LeadManagementApi();
  const response = await apiCaller.leadFindOne({ id });
  const { data }: any = response;
  const dataClient = data.client
    ? {
        ...data.client,
        name: `${data.client.firstName} ${data.client.lastName} from ${data.client.businessName}`
      }
    : null;
  const newDestinations = data.destinations.map((destination: any) => {
    const newServiceTimes = destination.serviceTimes.map((serviceTime: any) => {
      const auxIndividualTasks = {
        ...serviceTime.individualTasks,
        tasks:
          serviceTime.individualTasks.length > 0
            ? serviceTime.individualTasks.map((task: any) => {
                let materials = [{}];
                if (task?.taskMaterials && task?.taskMaterials.length > 0) {
                  materials = task.taskMaterials.map((materialData: any) => {
                    return {
                      cost: parseFloat(materialData.cost),
                      materialId: materialData.id,
                      name: materialData.material?.name
                    };
                  });
                }
                return {
                  ...task,
                  checklist: task?.taskChecklist || [],
                  materials,
                  skills: task?.skills || []
                };
              })
            : []
      };
      let sumTotalServiceTimeHours = 0;
      return {
        ...serviceTime,
        availability: serviceTime.availability,
        individualTasks: auxIndividualTasks,
        services: serviceTime.services.map((service: any) => {
          let sumTotalServiceHours = 0;
          const checkListService =
            service?.checklist?.length > 0
              ? service?.checklist.map((checkItem: any) => checkItem.name)
              : [];
          const tasksService = service.tasks.map((task: any) => {
            let taskCheckList = [];

            if (task?.checklist && task?.checklist.length > 0) {
              taskCheckList = task?.checklist;
            } else if (service?.checklist?.length > 0) {
              taskCheckList = checkListService;
            }

            const quantity = task.quantity || 0;
            const measurePerTime = parseFloat(task.measurePerHour || 0);
            const minRequired = Number(task?.minimumResources) ?? 1;
            const cantTechnicians =
              task?.technicians?.length > 0
                ? task?.technicians?.length
                : task?.resources?.length || 1;
            const totalTime =
              task?.measure === 'Unit'
                ? quantity * measurePerTime
                : quantity / (measurePerTime || 1);
            const totalTimeNumber =
              Number((totalTime * minRequired) / cantTechnicians) || 0;

            sumTotalServiceHours += totalTimeNumber;
            return {
              ...task,
              checklist: taskCheckList,
              materials: task?.materials || [],
              quantity: task.quantity || 0,
              resources: task.technicians || [],
              skills: task?.skills || [],
              taskHoursPerDay: totalTimeNumber || 0,
              time: task.quantity || 0,
              totalTime: totalTimeNumber || 0
            };
          });
          sumTotalServiceTimeHours += sumTotalServiceHours;
          return {
            ...service,
            checklist: checkListService,
            tasks: tasksService,
            totalTime: sumTotalServiceHours
          };
        }),
        totalTime: sumTotalServiceTimeHours
      };
    });
    return {
      ...destination,
      serviceTimes: newServiceTimes
    };
  });
  const originalFiles = data?.fileBank || [];
  const newFiles: File[] =
    data?.fileBank && data?.fileBank.length > 0
      ? data.fileBank.map((file: any) => {
          return new File([file], file.fileOriginalName, {
            type: file.mimeType
          });
        })
      : [];

  return {
    ...data,
    client: dataClient,
    destinations: newDestinations,
    endDate: data.projectEndDate,
    fileBank: newFiles,
    originalFiles,
    profit: Number(data?.profitPercentage),
    startDate: data.projectStartDate
  };
};
const updateLeadGeneralInfo = async (params: any) => {
  if (!params) {
    return null;
  }
  const {
    clientId,
    description,
    id,
    manualPricing,
    name,
    endDate,
    startDate,
    projectType,
    profitPercentage,
    profit,
    supervisorFrequency: sf,
    supervisor,
    supervisorTime: st
  } = params;
  const apiCaller = new LeadManagementApi();
  let newEndDate;
  if (endDate && typeof endDate === 'string') {
    newEndDate = endDate;
  } else if (endDate) {
    newEndDate = endDate.format('YYYY-MM-DD');
  } else {
    newEndDate = null;
  }

  const { id: supervisorId } = supervisor || {};
  const supervisorTime = st ? Number(st) : undefined;
  const supervisorFrequency = sf ? sf.replace(/\s+/g, '') : undefined;

  const response = await apiCaller.leadUpdate({
    id,
    updateLeadGeneralInfoDto: {
      clientId,
      description,
      manualPricing,
      name,
      profitPercentage: Number(profit || profitPercentage),
      projectEndDate: newEndDate,
      projectStartDate:
        typeof startDate === 'string'
          ? startDate
          : startDate.format('YYYY-MM-DD'),
      projectType,
      supervisorFrequency,
      supervisorId,
      supervisorTime
    }
  });
  const { data } = response;

  return data;
};

const createServiceTimeForManualPricing = async (params: any) => {
  const apiLeadServicesCaller = new LeadServiceTimesApi();
  const { id, startDate, endDate, manualPricing, destinations } = params;
  if (manualPricing) {
    const date = new Date(
      typeof startDate === 'string' ? startDate : startDate.format('YYYY-MM-DD')
    );
    date.setDate(date.getDate() + 1);
    const weekDay = weekDays[date.getDay()];
    const requestParameters: LeadServiceTimesApiLeadServiceTimeCreateRequest = {
      createLeadServiceTimeDto: {
        destinationId: destinations[0].id.toString(),
        endDate,
        endTime: '18:00',
        frequency: 'Weekly',
        frequencyDays: [weekDay.name],
        individualTasks: [],
        services: manualPricing
          ? [
              {
                description: 'General Service',
                name: 'General Service',
                setupTime: null,
                tasks: [],
                teardownTime: null
              }
            ]
          : [],
        startDate:
          typeof startDate === 'string'
            ? startDate
            : startDate.format('YYYY-MM-DD'),
        startTime: '08:00'
      },
      id
    } as any;
    const serviceTimeResponse =
      await apiLeadServicesCaller.leadServiceTimeCreate(requestParameters);

    return serviceTimeResponse.data;
  }
  return false;
};

const setLead = (params: any) => {
  return { params };
};

const deleteLead = (id: string) => {
  return { id };
};

const convertToProject = (id: string) => {
  return { id };
};

const getLeadAnalytics = async (params: any) => {
  const { id, startDate, endDate, profit, totalProjectPrice } = params;
  if (!id) {
    return null;
  }
  const apiCaller = new LeadManagementApi();
  const response = await apiCaller.leadGetProposalCalculator({
    endDate,
    id,
    profit,
    startDate,
    totalProjectPrice
  });
  const { data } = response;
  return data;
};

const getLeadDestinations = async (params: any) => {
  const { id } = params;
  if (!id) {
    return null;
  }
  const apiCaller = new DestinationApi();
  const { data } = await apiCaller.destinationGetDestinationsByLead({ id });
  return data;
};

const createDestination = async (params: any) => {
  if (!params.leadId || !params.addressId || !params.contactId) {
    return null;
  }
  const { addressId, contactId, leadId } = params;
  const apiCaller = new DestinationApi();
  const { data } = await apiCaller.destinationCreateNewDestination({
    addressId,
    contactId,
    leadId
  });
  return data;
};

const deleteDestinations = async (params: any) => {
  const { id } = params;
  if (!id) {
    return null;
  }
  const apiCaller = new DestinationApi();
  const { status } = await apiCaller.destinationDeleteDestination({ id });
  return status;
};

const updateDestination = async (params: any) => {
  if (!params.addressId || !params.contactId || !params.destinationId) {
    return null;
  }
  const { addressId, contactId, destinationId } = params;
  const apiCaller = new DestinationApi();
  const { data } = await apiCaller.destinationUpdateDestination({
    id: destinationId,
    updateDestinationBody: {
      addressId: addressId.toString(),
      contactId: contactId.toString()
    }
  });
  return data;
};

const deleteSupervisor = async (params: any) => {
  const { id } = params;
  const apiCaller = new LeadManagementApi();
  const response = await apiCaller.leadRemoveSupervisorFromLead({ id });
  return { status: response.status };
};

const updateStaticCostApi = async (params: any) => {
  const { id } = params;
  const { staticCosts } = params;
  const apiCaller = new LeadManagementApi();
  const response = await apiCaller.leadUpdateStaticCostsValues({
    id,
    updateStaticCostInLeadDTO: staticCosts
  });
  return response?.data;
};

const resetLeadValues = async (params: any) => {
  const { id } = params;

  const apiCaller = new LeadManagementApi();
  const response = await apiCaller.leadCopyStaticCostsValues({
    id
  });
  return response?.status;
};

export default {
  convertToProject,
  createDestination,
  createLead,
  createServiceTimeForManualPricing,
  deleteDestinations,
  deleteLead,
  deleteSupervisor,
  getAllLead,
  getLead,
  getLeadAnalytics,
  getLeadDestinations,
  getLeadsPaginated,
  resetLeadValues,
  setLead,
  updateDestination,
  updateLeadGeneralInfo,
  updateStaticCostApi
};
