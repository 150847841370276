import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import '../../style/App.scss';
import {
  Container,
  Box,
  Button,
  Typography,
  styled,
  SliderProps,
  Modal,
  Backdrop,
  useTheme
} from '@mui/material';
import TextareaAutosize from '@mui/material/TextareaAutosize';
import { capitalizeString } from '../../utils/general';

const ModalContainer = styled(Box)<SliderProps>(({ theme }) => ({
  alignItems: 'center',
  backgroundColor: theme.palette.background.paper,
  borderRadius: '0.5em',
  boxShadow: '24',
  display: 'flex',
  flexDirection: 'column',
  height: 254,
  padding: '40px 70px',
  position: 'absolute' as 'absolute',
  right: '50%',
  textAlign: 'center',
  top: '50%',
  transform: 'translate(50%, -50%)',
  width: 407
}));

interface Props {
  modalText: string;
  modalType: string;
  // Nueva prop para pasar el texto del modal
  setInput?: React.Dispatch<React.SetStateAction<string>>;
  // Nueva prop para pasar el texto del modal
  setSentFlag: React.Dispatch<React.SetStateAction<boolean>>;
  setSentInfo?: React.Dispatch<React.SetStateAction<boolean>>;
  setShowModal: React.Dispatch<React.SetStateAction<boolean>>;
  // Nueva prop para pasar el texto del modal
  setShowOkModal: React.Dispatch<React.SetStateAction<boolean>>;
}

const ConfirmModal: React.FC<Props> = function ConfirmModal({
  setShowModal,
  setShowOkModal,
  setSentFlag,
  setSentInfo,
  modalType,
  modalText,
  setInput
}) {
  const { t } = useTranslation();
  const theme = useTheme();
  useEffect(() => {}, []);

  return (
    <Container sx={{ padding: '40px', width: 'auto' }}>
      <Modal
        open
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500
        }}
        onClose={() => {
          setShowModal(false);
        }}
      >
        <ModalContainer sx={{ height: 'fit-content', width: 'fit-content' }}>
          <Typography color="primary" variant="heading_400">
            {modalType !== 'task' && modalType !== 'proposalMessagesTemplate'
              ? capitalizeString(`${modalType} ${t('MODAL.proposal')}`)
              : ''}
          </Typography>
          <Box>
            <Box mt={2}>
              <Typography variant="body_200">
                {modalType === 'recall' ? t('MODAL.confirModal3') : ''}
              </Typography>
            </Box>
            <Box mb={2} mt={2}>
              <Typography variant="body_200">
                {modalType === 'recall' ? t('MODAL.confirModal4') : ''}
              </Typography>
            </Box>
            <Box mb={2} mt={2}>
              {modalType === 'recall' && (
                <TextareaAutosize
                  aria-label="empty textarea"
                  minRows={3}
                  onChange={(value: any) => {
                    if (setInput) {
                      setInput(value?.target?.value);
                    }
                  }}
                  style={{
                    color: theme.palette.secondary[400],
                    fontFamily: theme.typography.primaryBase.font,
                    padding: '8px',
                    width: '100%'
                  }}
                  // placeholder="Placeholder"
                />
              )}
            </Box>
            <Box mb={2} mt={2}>
              {modalText}
            </Box>
          </Box>
          <br />
          <Box
            sx={{
              bottom: 0,
              display: 'flex',
              gap: 2,
              justifyContent: 'space-between'
            }}
          >
            <Button
              onClick={() => {
                setShowModal(false);
                setSentFlag(false);
              }}
              sx={{ width: 200 }}
              variant="cancel"
            >
              <Typography variant="primary-bold">
                {modalType === 'recall'
                  ? t('MODAL.cancel')
                  : t('MODAL.confirmModalNo')}
              </Typography>
            </Button>
            <Button
              onClick={() => {
                setSentFlag(true);
                setShowOkModal(true);
                setSentInfo?.(true);
              }}
              sx={{
                height: 46,
                width: 200
              }}
              variant="primary"
            >
              <Typography variant="primary-bold">
                {t('MODAL.confirModalYes')}
              </Typography>
            </Button>
          </Box>
        </ModalContainer>
      </Modal>
    </Container>
  );
};

ConfirmModal.defaultProps = {
  setInput: () => {},
  setSentInfo: () => {}
};

export default ConfirmModal;
