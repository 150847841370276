import React, { useEffect, useState } from 'react';
import { Box, Collapse } from '@mui/material';
import CollapseBox from './CollapseBox';
import Assignments from './Assignments';
import { Destination } from '../../utils/types/ProjectType';

interface DestinationAssignmentsCollapseListProps {
  destinations: Destination[];
  projectEndDate: string;
  projectId: string;
  projectStartDate: string;
  projectStatus: string;
  refresh: () => void;
}

const DestinationAssignmentsCollapseList: React.FC<DestinationAssignmentsCollapseListProps> =
  function DestinationAssignmentsCollapseList({
    destinations,
    projectId,
    projectStatus,
    projectStartDate,
    projectEndDate,
    refresh
  }) {
    const [openDestination, setOpenDestination] = useState<{
      [destinationId: string]: boolean;
    }>({});

    // Open the first collapse on initial render
    useEffect(() => {
      if (destinations?.length > 0) {
        const firstDestinationId = String(destinations[0].id);
        setOpenDestination({ [firstDestinationId]: true });
      }
    }, [destinations]);

    // Ensure only one collapse is open at a time
    const toggleDestination = (destinationId: string) => {
      setOpenDestination((prev) => {
        const isCurrentlyOpen = !!prev[destinationId];
        return { [destinationId]: !isCurrentlyOpen }; // Close others, open new one
      });
    };

    return (
      <Box display="flex" flexDirection="column" gap={2}>
        {destinations.map((destination) => (
          <CollapseBox
            destination={destination}
            isCollapseOpen={openDestination[destination.id]}
            key={destination.id}
            projectSetupTab="assignments"
            toggleDestination={() => toggleDestination(String(destination.id))}
          >
            <Collapse
              unmountOnExit
              in={!!openDestination[String(destination.id)]}
              timeout="auto"
            >
              <Box marginTop={1}>
                <Assignments
                  destinationId={destination.id}
                  id={projectId}
                  projectEndDate={projectEndDate}
                  projectStartDate={projectStartDate}
                  projectStatus={projectStatus}
                  refresh={refresh}
                />
              </Box>
            </Collapse>
          </CollapseBox>
        ))}
      </Box>
    );
  };

export default DestinationAssignmentsCollapseList;
