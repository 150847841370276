/* eslint-disable react/require-default-props */
import React, { useState } from 'react';
import {
  Box,
  Button,
  TableHead,
  TableRow,
  SliderProps,
  TableCell,
  Typography,
  styled,
  Collapse,
  useTheme,
  Divider
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import ServiceSummary from './ServiceSummary';
import ServiceWindow from './ServiceWindow';
import AssignResources from './AssignResources';
import ServiceTimeDuration from './ServiceTimeDuration';
import { getServices } from '../../../../utils/general';
import {
  accentColors,
  secondaryColors,
  textColors,
  warningColors
} from '../../../../theme/themeConfig';
import { CreateLead } from '../../../../utils/types';

export const ServicesDots = styled('span')(() => ({
  alignItems: 'center',
  borderRadius: '50%',
  color: 'white',
  display: 'flex',
  height: '44px',
  justifyContent: 'center',
  width: '44px'
}));

const RowElement = styled(TableCell)<SliderProps>(({ theme }) => ({
  alignItems: 'center',
  borderBottom: 'none',
  color: theme.palette.primary.main,
  display: 'flex',
  fontSize: '18px',
  fontWeight: 'bold',
  textOverflow: 'ellipsis',
  width: '100%'
}));

interface Props {
  saveStepper: ({ next }: any) => void;
  sendValues?: React.Dispatch<React.SetStateAction<any>>;
  setStep: React.Dispatch<React.SetStateAction<number>>;
  setValues: React.Dispatch<React.SetStateAction<any>>;
  values: CreateLead;
}

function ResourceServiceTimeList({
  sendValues,
  saveStepper,
  values,
  setValues,
  setStep
}: Props) {
  const [open, setOpen] = useState<{ [key: string]: boolean }>({});
  const { t } = useTranslation();
  const theme = useTheme();

  const serviceSummary = (index: any, destinationIndex: number) => (
    <ServiceSummary
      destinationIndex={destinationIndex}
      saveStepper={saveStepper}
      serviceIndex={index}
      setStep={setStep}
      setValues={setValues}
      values={values}
    />
  );

  const serviceWindow = (index: any, destinationIndex: number) => (
    <ServiceWindow
      destinationIndex={destinationIndex}
      saveStepper={saveStepper}
      serviceIndex={index}
      setStep={setStep}
      setValues={setValues}
      values={values}
    />
  );

  const assignResources = (index: any, destinationIndex: number) => (
    <AssignResources
      destinationIndex={destinationIndex}
      saveStepper={saveStepper}
      serviceIndex={index}
      setStep={setStep}
      setValues={setValues}
      values={values}
    />
  );

  const serviceTimeDuration = (index: any, destinationIndex: number) => (
    <ServiceTimeDuration
      destinationIndex={destinationIndex}
      saveStepper={saveStepper}
      serviceIndex={index}
      setStep={setStep}
      setValues={setValues}
      values={values}
    />
  );

  const auxOpen = (
    destinationIndex: number,
    serviceTimeIndex: number
  ): boolean => {
    const key = `${destinationIndex}-${serviceTimeIndex}`;
    const newOpen = { ...open, [key]: !open[key] };
    setOpen(newOpen);
    return !newOpen[key];
  };

  const serviceTimeHeader = (
    destinationIndex: number,
    serviceTimeIndex: number,
    serviceTime: any
  ) => {
    const key = `${destinationIndex}-${serviceTimeIndex}`;
    if (undefined === open[key]) {
      setOpen((prevOpen) => ({ ...prevOpen, [key]: false }));
    }

    const validateData = (): boolean => {
      if (!serviceTime) return false;

      // 2. Second Condition: tasks must have resources
      const hasTasksWithResources = serviceTime.services?.some((service: any) =>
        service.tasks?.some(
          (task: any) => task.resources && task.resources.length > 0
        )
      );

      if (!hasTasksWithResources) return false;

      // 3. Third Condition: resourcesAssigned >= minimumResources
      const areResourcesValid = serviceTime.services?.every((service: any) =>
        service.tasks?.every(
          (task: any) => task.resourcesAssigned >= task.minimumResources
        )
      );

      if (!areResourcesValid) return false;

      return true;
    };

    return (
      <Box
        padding="24px"
        sx={{
          border: `1px solid ${secondaryColors.SECONDARY_1}`,
          borderRadius: '8px'
        }}
      >
        <TableHead
          sx={{
            borderRadius: '8px',
            display: 'flex',
            width: '100%'
          }}
        >
          {serviceTime && (
            <TableRow
              sx={{
                alignItems: 'center',
                display: 'flex',
                width: '100%'
              }}
            >
              <RowElement onClick={() => {}} padding="none">
                <Box
                  sx={{
                    alignItems: 'center',
                    display: 'flex',
                    justifyContent: 'space-between',
                    width: '100%'
                  }}
                >
                  <Box
                    display="grid"
                    gridTemplateColumns="174px 1fr 1fr"
                    sx={{ gridColumnGap: '16px' }}
                  >
                    <Typography
                      color={textColors.DARK_TEXT}
                      variant="secondary-semi"
                    >
                      {values &&
                      values.projectType !== 'adhoc' &&
                      values.projectType !== 'Ad-Hoc'
                        ? `${t('LEAD.CREATE.SERVICES.SUMMARY.serviceTime')} #${
                            serviceTimeIndex + 1
                          }`
                        : `Ad-Hoc ${t(
                            'LEAD.CREATE.SERVICES.SUMMARY.serviceTime'
                          )} #${serviceTimeIndex + 1}`}
                    </Typography>

                    <Typography color="mainText.300" variant="body_300">
                      {getServices(serviceTime)}
                    </Typography>

                    <Typography color="mainText.300" variant="body_300">
                      {values?.projectType !== 'Ad-Hoc'
                        ? serviceTime.frequency
                        : serviceTime.startDate}
                    </Typography>
                  </Box>

                  <Box display="flex" gap="24px">
                    {validateData() ? (
                      <ServicesDots
                        sx={{ backgroundColor: accentColors.ACCENT_4 }}
                      >
                        <i className="custom-icon icon-active" />
                      </ServicesDots>
                    ) : (
                      <ServicesDots
                        sx={{ backgroundColor: warningColors.ERROR_4 }}
                      >
                        <i className="custom-icon icon-warningTriangle" />
                      </ServicesDots>
                    )}

                    <Button
                      onClick={() =>
                        auxOpen(destinationIndex, serviceTimeIndex)
                      }
                      sx={{ padding: 0 }}
                    >
                      <i
                        className="custom-icon icon-select"
                        style={{
                          color: `${secondaryColors.SECONDARY_4}`,
                          fontSize: '14px',
                          transform: `rotate(${open[key] ? '180deg' : '0deg'})`
                        }}
                      />
                    </Button>
                  </Box>
                </Box>
              </RowElement>
            </TableRow>
          )}
        </TableHead>

        <Collapse in={open[key]}>
          <Box
            display="flex"
            flexDirection="column"
            gap="24px"
            marginTop="24px"
          >
            <Box
              display="flex"
              flexDirection="column"
              gap="24px"
              padding="32px 24px 32px 24px"
              sx={{
                border: 1,
                borderColor: secondaryColors.SECONDARY_1,
                borderRadius: '8px'
              }}
            >
              {serviceSummary(serviceTimeIndex, destinationIndex)}

              <Divider
                flexItem
                sx={{ borderColor: secondaryColors.SECONDARY_1 }}
              />

              {serviceWindow(serviceTimeIndex, destinationIndex)}
            </Box>
            <Box
              display="flex"
              flexDirection="column"
              gap="32px"
              padding="32px 24px 32px 24px"
              sx={{
                border: 1,
                borderColor: secondaryColors.SECONDARY_1,
                borderRadius: '8px'
              }}
            >
              {assignResources(serviceTimeIndex, destinationIndex)}
            </Box>
            {serviceTimeDuration(serviceTimeIndex, destinationIndex)}
          </Box>
        </Collapse>
      </Box>
    );
  };

  return (
    <Box display="flex" flexDirection="column" gap="24px">
      {values?.destinations?.length > 0 &&
        values.destinations.map((destination: any, destinationIndex: number) =>
          destination.serviceTimes.map(
            (serviceTime: any, serviceTimeIndex: number) => {
              const auxKey = `schedule-serviceTime-${serviceTimeIndex}`;
              if (typeof serviceTime?.id === 'number') {
                return (
                  <React.Fragment key={`${auxKey}`}>
                    {serviceTimeIndex === 0 && (
                      <Typography variant="secondary-semi">
                        {`${t('LEAD.CREATE.DESTINATIONS.destination')} #${
                          destinationIndex + 1
                        }`}
                        :{' '}
                        <Typography color="mainText.300" variant="body_300">
                          {destination.address.name}
                        </Typography>
                      </Typography>
                    )}
                    {serviceTimeHeader(
                      destinationIndex,
                      serviceTimeIndex,
                      serviceTime
                    )}
                  </React.Fragment>
                );
              }

              return null;
            }
          )
        )}
    </Box>
  );
}

export default ResourceServiceTimeList;
