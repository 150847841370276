/* eslint-disable react/jsx-props-no-spreading */
import React, { useEffect, useState } from 'react';
import {
  styled,
  Input,
  InputProps,
  InputAdornment,
  Tooltip
} from '@mui/material';
import isEmail from 'validator/lib/isEmail';
import { isValidPhoneNumber } from 'react-phone-number-input';
import { IMaskInput } from 'react-imask';
import { cleanPhoneNumber } from '../../utils/general';
import {
  secondaryColors,
  themeFont,
  themePalette
} from '../../theme/themeConfig';

interface Props {
  align?: 'left' | 'center' | 'right';
  autofill?: boolean;
  customStyle?: object;
  defaultValue?: string;
  disabled?: boolean;
  error?: boolean;
  float?: boolean;
  inputInvalid?: React.Dispatch<React.SetStateAction<boolean>>;
  minVal?: number;
  multiline?: boolean;
  name?: string;
  onChange?: React.Dispatch<React.SetStateAction<any>>;
  placeholder?: string;
  required?: boolean;
  success?: boolean;
  tooltip?: string;
  type?: string;
  value?: string;
}

interface State {
  input: string;
}

interface CustomProps {
  name: string;
  onChange: (event: { target: { name: string; value: string } }) => void;
}

const PhoneMask = React.forwardRef<HTMLElement, CustomProps>(function PhoneMask(
  props,
  ref
) {
  const { onChange, ...other } = props;
  return (
    <IMaskInput
      ref={ref}
      {...other}
      overwrite
      definitions={{
        '#': /[1]/
      }}
      mask="+(1) 000-000-0000"
      onAccept={(value: any) =>
        onChange({ target: { name: props.name, value } })
      }
    />
  );
});

const InputStyled = styled(Input)<InputProps>(() => ({
  '& .MuiInputBase-input': {
    '&:-webkit-autofill': {
      '-webkit-box-shadow': `0 0 0px 1000px ${themePalette.BACKGROUND} inset`,
      '-webkit-text-fill-color': themePalette.TITLE,
      boxShadow: `0 0 0px 1000px ${themePalette.BACKGROUND} inset`
    },
    borderRadius: 8,
    color: themePalette.TITLE,
    fontFamily: themeFont.FONT_SECONDARY,
    fontSize: '16px',
    fontWeight: 400,
    lineHeight: '20px',
    paddingBottom: 8,
    paddingLeft: 16,
    paddingRight: 4,
    paddingTop: 8
  },
  '&.Mui-error': {
    border: '1px solid',
    borderColor: secondaryColors.SECONDARY_1
  },
  backgroundColor: themePalette.BACKGROUND,
  border: `1px solid ${secondaryColors.SECONDARY_1}`,
  paddingLeft: 0,
  paddingRight: 0
}));

const RegularInput: React.FC<Props> = function MRegularInputFA({
  customStyle,
  error = false,
  success = false,
  onChange,
  value,
  name,
  type,
  required,
  inputInvalid,
  disabled,
  placeholder,
  defaultValue,
  autofill,
  float,
  tooltip,
  minVal = 0,
  multiline,
  align
}) {
  const [localError, setLocalError] = useState<boolean>(false);
  const secondaryState = error || success || localError;
  const [localValue, setLocalValue] = useState<string>(value || '');
  const [alreadyUpdated, setAlreadyUpdated] = useState<boolean>(false);

  const getMainColor = () => {
    if (secondaryState) {
      return error || localError
        ? 'alert.main'
        : `${secondaryColors.SECONDARY_3}`;
    }
    return `${secondaryColors.SECONDARY_3}`;
  };

  const handleChange =
    (prop: keyof State) => (event: React.ChangeEvent<HTMLInputElement>) => {
      if (prop && onChange) {
        setLocalValue(event.target.value);
      }
    };

  const sendOnChange = () => {
    let toSend: any =
      type === 'phone' ? cleanPhoneNumber(localValue) : localValue;
    let errorValid = false;
    switch (type) {
      case 'email':
        {
          const isValid = !isEmail(localValue);
          errorValid = isValid;
        }
        break;
      case 'number':
        {
          toSend = parseFloat(toSend) > 0 ? parseFloat(toSend) : 0;
          const isValid = parseFloat(toSend);
          errorValid = isValid < 0 || localValue === '';
        }
        break;
      case 'phone':
        errorValid = !isValidPhoneNumber(cleanPhoneNumber(localValue));
        break;
      default:
        errorValid = !!(localValue === '' && required);
        break;
    }
    if (onChange) {
      onChange(toSend);
      setAlreadyUpdated(true);
      setLocalValue(toSend);
    }
    if (inputInvalid) {
      inputInvalid(errorValid);
    }
    setLocalError(errorValid);
  };

  const handleKeyDown = (event: any) => {
    if (event.key === 'Enter') {
      sendOnChange();
    }
  };

  const getInput = () => (
    <InputStyled
      disableUnderline
      aria-describedby="my-helper-text"
      defaultValue={defaultValue}
      disabled={disabled}
      endAdornment={
        type === 'number' && placeholder === 'Last days' ? (
          <InputAdornment position="end">Last days</InputAdornment>
        ) : null
      }
      error={secondaryState}
      inputComponent={type === 'phone' ? (PhoneMask as any) : undefined}
      inputProps={{
        min: minVal > 0 && type === 'number' ? minVal : 0,
        step: float ? '0.01' : '1',
        style: {
          textAlign: align
        }
      }}
      key={name}
      multiline={multiline}
      name={name}
      onBlur={() => sendOnChange()}
      onChange={handleChange('input')}
      onKeyDown={handleKeyDown}
      placeholder={`${placeholder}`}
      startAdornment={
        type === 'number' && placeholder === 'Cost' ? (
          <InputAdornment position="start">$</InputAdornment>
        ) : null
      }
      sx={{
        '&.Mui-error': {
          border: '1px solid',
          borderColor: error || localError ? 'alert.main' : 'success.main'
        },
        color: getMainColor(),
        ...customStyle
      }}
      type={type}
      value={localValue}
    />
  );

  const getInputMask = () => {
    return getInput();
  };

  useEffect(() => {
    if (required) {
      setLocalError(required);
    }
  }, []);

  useEffect(() => {
    setLocalError(error);
  }, [error]);

  useEffect(() => {
    if (autofill && onChange) {
      const toSend =
        type === 'phone' ? cleanPhoneNumber(localValue) : localValue;
      onChange(toSend);
      setAlreadyUpdated(true);
    }
  }, [localValue]);

  useEffect(() => {
    if (value) {
      setLocalValue(value);
    }
  }, [value, alreadyUpdated]);

  return (
    <>
      {tooltip !== '' && <Tooltip title={tooltip}>{getInputMask()}</Tooltip>}
      {tooltip === '' && getInputMask()}
    </>
  );
};

RegularInput.defaultProps = {
  align: 'left',
  autofill: false,
  customStyle: {},
  defaultValue: '',
  disabled: false,
  error: false,
  float: false,
  inputInvalid: () => {},
  minVal: 0,
  multiline: false,
  name: '',
  onChange: () => {},
  placeholder: '',
  required: false,
  success: false,
  tooltip: '',
  type: '',
  value: ''
};

export default RegularInput;
