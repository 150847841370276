import { Box, Checkbox } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { weekDays } from '../../helpers/weekDays';
import {
  DayCheckBoxSquared,
  DayCheckBoxSquaredSelected
} from '../profile/parts';

interface Props {
  disabled?: boolean;
  setValues: (days: Array<string>) => void;
  type: string;
  values: any;
}

const DaysOfTheWeek: React.FC<Props> = function DaysOfTheWeek({
  type,
  values,
  setValues,
  disabled
}) {
  const label = { inputProps: { 'aria-label': 'Checkbox date' } };
  const { t } = useTranslation();

  const handleChange = (value: string) => {
    if (values.includes(value)) {
      setValues(values.filter((item: string) => item !== value));
      return;
    }
    setValues([...values, value]);
  };

  const getStyles = () => {
    switch (type) {
      case 'box':
        return {
          borderRadius: '8px',
          fontFamily: 'Lexend',
          fontSize: '14px',
          height: '36px',
          lineHeight: '20px',
          padding: '8px',
          textSize: 'Short',
          width: '72px'
        };
      case 'square':
        return {
          borderRadius: '0px',
          fontFamily: 'Lexend',
          fontSize: '14px',
          height: '36px',
          padding: '8px',
          textSize: 'Short',
          width: '42px'
        };
      case 'squareSmall':
        return {
          borderRadius: '8px',
          fontFamily: 'Lexend',
          fontSize: '14px',
          height: '36px',
          padding: '6px',
          textSize: 'Short',
          width: '52px'
        };
      default:
        return {
          borderRadius: '50%',
          height: '64px',
          padding: 9,
          textSize: 'L',
          width: '64px'
        };
    }
  };

  const styles = getStyles();
  const getGap = type === 'squareSmall' ? '2px' : '6px';

  return (
    <Box display="flex" gap={getGap}>
      {true &&
        weekDays.map((ele: any, index: number) => (
          <Checkbox
            checked={values.includes(ele.name)}
            disabled={disabled}
            // eslint-disable-next-line react/no-array-index-key
            key={`${ele.name} - ${index}`}
            sx={{
              height: styles.height,
              padding: 0.5,
              width: styles.width
            }}
            value={ele.name}
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...label}
            checkedIcon={
              <DayCheckBoxSquaredSelected
                disabled={disabled}
                isMobile={false}
                sx={{
                  borderRadius: styles.borderRadius,
                  fontFamily: styles.fontFamily,
                  fontSize: styles.fontSize,
                  lineHeight: styles.lineHeight,
                  p: styles.padding
                }}
              >
                {t(`DAYS.${ele.name.toLowerCase()}${styles.textSize}`)}
              </DayCheckBoxSquaredSelected>
            }
            icon={
              <DayCheckBoxSquared
                disabled={disabled}
                isMobile={false}
                sx={{
                  borderRadius: styles.borderRadius,
                  fontFamily: styles.fontFamily,
                  fontSize: styles.fontSize,
                  lineHeight: styles.lineHeight,
                  p: styles.padding
                }}
              >
                {t(`DAYS.${ele.name.toLowerCase()}${styles.textSize}`)}
              </DayCheckBoxSquared>
            }
            onChange={(value) => {
              handleChange(value.currentTarget.defaultValue);
            }}
          />
        ))}
    </Box>
  );
};

DaysOfTheWeek.defaultProps = {
  disabled: false
};

export default DaysOfTheWeek;
