/* eslint-disable react/require-default-props */
import {
  Box,
  Button,
  Container,
  Drawer,
  IconButton,
  Modal,
  SlideProps,
  styled,
  Tab,
  Tabs,
  Typography,
  useTheme
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import useSWR from 'swr';
import dayjs from 'dayjs';
import SettingsIcon from '@mui/icons-material/Settings';
import Chart from 'react-apexcharts';
import Api from '../../../api';
import ServiceTimeCalculator from '../../../components/calculator/ServiceTimeCalculator';
import TableComponentEditable from '../../../components/shared/TableComponentEditable';
import DeleteButton from '../../../components/list/DeleteButton';
import { getCurrency, getSalesTaxableItems } from '../../../utils/general';
import {
  getCalculatedMarkup,
  getMargin,
  getMarkup,
  getTotalProject
} from '../../../utils/calculatorHelper';
import GeneralDrawer from '../../../components/GeneralDrawer';
// eslint-disable-next-line import/no-cycle
import FormLead from '../FormLead';
import Loader from '../../../components/shared/Loader';
import InformationItem from './components/analysis/InformationItem';
import Tile from './components/analysis/Tile';
import { getCalculatorResults } from '../../../utils/calculatorProHelper';
import { IconBox } from '../../../components/shared/sidebars/parts';
import {
  DrawerButton,
  ModalContainer
} from '../../../components/modals/GlobalConfirmModal';
import SortableTable from '../../../components/list/sortableTable';
import lead from '../../../api/lead/lead';

interface Props {
  // availability: any;
  mutate: () => void;
  saveStepper: ({ next }: any) => void;
  setValues: React.Dispatch<React.SetStateAction<any>>;
  values: any;
}

const ButtonContainerModal = styled(Box)<SlideProps>(() => ({
  display: 'flex',
  justifyContent: 'space-between',
  marginTop: '20px',
  width: '216px'
}));

const Analysis: React.FC<Props> = function Analysis({
  mutate,
  values,
  setValues,
  saveStepper
  // availability
}) {
  const { t } = useTranslation();
  const theme = useTheme();
  const { palette } = theme;
  const [getCalculator, setGetCalculator] = useState<boolean>(true);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [refreshData, setRefreshData] = useState<boolean>(false);
  const [currentData, setCurrentData] = useState<any>({});
  const [presetStep, setPresetStep] = useState<any>(0);
  const [taxes, setTaxes] = useState<any>([]);
  const [staticCosts, setStaticCosts] = useState<any>([]);
  const [updateStaticCost, setUpdateStaticCost] = useState<boolean>(false);
  const [resetStaticCosts, setResetStaticCosts] = useState<boolean>(false);
  const [openDrawer, setOpenDrawer] = useState<boolean>(false);

  const buyPrice = getTotalProject(values, t);
  const sellPrice = values?.profit
    ? (parseFloat(values.profit || 0) / 100) * buyPrice + buyPrice
    : buyPrice;
  const margin = getMargin(buyPrice, sellPrice);
  const markup = getMarkup(buyPrice, sellPrice);

  // const {
  //   data: currentCalculatorResponse,
  //   error: currentCalculatorError,
  //   mutate: currentCalculatorMutate
  // } = useSWR(
  //   getCalculator
  //     ? {
  //         endDate: values.endDate,
  //         id: values.id,
  //         profit: markup.amount,
  //         startDate: values.startDate,
  //         totalProjectPrice: sellPrice
  //       }
  //     : null,
  //   Api.leadsApi.leadApi.getLeadAnalytics
  // );

  const {
    data: updateStaticCostsData,
    error: updateStaticCostsError,
    mutate: updateStaticCostsMutate
  } = useSWR(
    updateStaticCost
      ? {
          id: values.id,
          staticCosts,
          url: 'api/lead-static-cost-update'
        }
      : null,
    Api.leadsApi.leadApi.updateStaticCostApi
  );

  const {
    data: reseStaticCostsData,
    error: resetStaticCostsError,
    mutate: resetStaticCostsMutate
  } = useSWR(
    resetStaticCosts
      ? {
          id: values.id,

          url: 'api/lead-static-cost-reset'
        }
      : null,
    Api.leadsApi.leadApi.resetLeadValues
  );

  const updateCalculator = () => {
    if (!values?.staticCosts?.length) {
      setResetStaticCosts(true);
    } else {
      if (!staticCosts.length) {
        setStaticCosts(values?.staticCosts);
      }

      if (values?.staticCosts) {
        const calculatorResults = getCalculatorResults(values);
        if (!taxes.length) {
          setTaxes([]);
        }
        setCurrentData(calculatorResults);
        setIsLoading(false);
      }
    }
  };

  useEffect(() => {
    if (updateStaticCostsData) {
      setGetCalculator(false);
      setUpdateStaticCost(false);
      mutate();
      setTimeout(() => {
        updateCalculator();
      }, 1000);
    }
  }, [updateStaticCostsData]);

  useEffect(() => {
    if (reseStaticCostsData) {
      setGetCalculator(false);
      setResetStaticCosts(false);
      setTimeout(() => {
        updateCalculator();
      }, 1000);
    }
  }, [reseStaticCostsData]);

  // useEffect(() => {
  //   if (reseStaticCostsData) {
  //     setGetCalculator(false);
  //     setResetStaticCosts(false);
  //     setUpdateStaticCost(false);
  //     mutate();
  //     setTimeout(() => {
  //       updateCalculator();
  //     }, 1000);
  //   }
  // }, [reseStaticCostsData]);

  // useEffect(() => {
  //   if (currentCalculatorError) {
  //     setGetCalculator(false);
  //     setIsLoading(false);
  //     setCurrentData({});
  //   }
  // }, [currentCalculatorError]);

  useEffect(() => {
    if (refreshData) {
      setGetCalculator(true);
      setRefreshData(false);
    }
  }, [refreshData]);

  const handleOpenDrawer = (presetStep = 0) => {
    setPresetStep(presetStep);
    setOpenDrawer(true);
  };

  const handleOpenModal = () => {
    setOpenModal(true);
  };

  const informationItems = [
    {
      legendColor: palette.mainText[200],
      onEdit: () => {
        handleOpenDrawer(2);
      },
      title: t('LEAD.CREATE.ANALYSIS.NUMBER_OF_EMPLOYEES'),
      value: currentData?.information?.employeesNeeded
    },
    {
      legendColor: palette.secondary[100],
      onEdit: () => {
        handleOpenDrawer(2);
      },
      title: t('LEAD.CREATE.ANALYSIS.SUPERVISION_HOURS_ASSIGNED'),
      value: Number(currentData?.information?.totalSupervisorTime).toFixed(2)
    },
    {
      legendColor: palette.mainText[200],
      title: t('LEAD.CREATE.ANALYSIS.EMPLOYEE_LABOR_RATE'),
      value: getCurrency(currentData?.information?.employeesLaborRate)
    },
    {
      legendColor: palette.secondary[100],
      onEdit: () => {
        handleOpenDrawer(1);
      },
      title: t('LEAD.CREATE.ANALYSIS.SUPERVISION_RATE'),
      value: getCurrency(currentData?.pricing?.supervisionCost)
    },

    {
      legendColor: palette.accent?.[200],
      title: t('LEAD.CREATE.ANALYSIS.GROSS_PROFIT_MARKUP'),
      value:
        values.projectType === 'Ad-Hoc'
          ? `${markup.percent}%`
          : // : currentData?.analytics?.grossMarginAtAccount
            `${markup.percent}%`
    },
    {
      legendColor: palette.success[400],
      onEdit: () => {
        handleOpenDrawer(1);
      },
      title: t('LEAD.CREATE.ANALYSIS.suppliesCost'),
      value: getCurrency(currentData?.pricing?.supplies)
    },
    {
      legendColor: '#B57DFF',
      onEdit: () => {
        setOpenModal(true);
      },
      title: t('LEAD.CREATE.ANALYSIS.configurationCost'),
      value: getCurrency(currentData?.pricing?.configurationCosts)
    },
    {
      legendColor: palette.informative[400],
      title: t('LEAD.CREATE.ANALYSIS.logisticCost'),
      value: getCurrency(currentData?.pricing?.logisticCost)
    }
  ];

  const [tabIndex, setTabIndex] = useState(0);

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setTabIndex(newValue);
  };

  const totalCosts = currentData?.pricing?.totalCosts || 0;
  const directLaborCost = currentData?.pricing?.directLaborCost || 0;
  const supervisionCost = currentData?.pricing?.supervisionCost || 0;
  const annualGrossProfit = currentData?.pricing?.annualGrossProfit || 0;
  const totalPrice = currentData?.pricing?.annualPrice || 0;
  const suppliesCost = currentData?.pricing?.supplies || 0;
  const configurationCosts = currentData?.pricing?.configurationCosts || 0;
  const supplies = currentData?.pricing?.supplies || 0;
  const payrollExpense = currentData?.pricing?.payrollExpense || 0;
  const uniformsBadgesHiring = currentData?.pricing?.uniformsBadgesHiring || 0;
  const logisticCost = currentData?.pricing?.logisticCost || 0;

  const radarChartOptions = {
    chart: {
      toolbar: {
        show: false
      },
      type: 'radar' as const
    },
    fill: {
      opacity: 0.2
    },
    markers: {
      size: 4
    },
    stroke: {
      width: 2
    },
    tooltip: {
      enabled: true
    },
    xaxis: {
      categories: [
        t('LEAD.CREATE.ANALYSIS.configurationCost'),
        t('LEAD.CREATE.ANALYSIS.SUPPLIES'),
        t('LEAD.CREATE.ANALYSIS.UNIFORMS_BADGES_HIRING'),
        t('LEAD.CREATE.ANALYSIS.PAYROLL_EXPENSE'),
        t('LEAD.CREATE.ANALYSIS.SUPERVISION_COST'),
        t('LEAD.CREATE.ANALYSIS.DIRECT_LABOR_COST'),
        t('LEAD.CREATE.ANALYSIS.suppliesCost'),
        t('LEAD.CREATE.ANALYSIS.logisticCost')
      ],
      labels: {
        style: {
          colors: new Array(6).fill(palette.text.primary)
        }
      }
    },
    yaxis: {
      show: false
    }
  };

  const radarChartData = [
    {
      data: [
        (configurationCosts / totalCosts) * 100,
        (supplies / totalCosts) * 100,
        (payrollExpense / totalCosts) * 100,
        (uniformsBadgesHiring / totalCosts) * 100,
        (supervisionCost / totalCosts) * 100,
        (directLaborCost / totalCosts) * 100,
        (suppliesCost / totalCosts) * 100,
        (logisticCost / totalCosts) * 100
      ],
      name: 'Analysis'
    }
  ];

  const barChartOptions = {
    chart: {
      height: 350,
      horizontal: true,
      stacked: true,
      toolbar: {
        show: false
      },
      type: 'bar' as const
    },
    colors: [
      '#B57DFF',
      palette.mainText[400],
      palette.accent?.[300],
      palette.warning[400],
      palette.success[400],
      palette.informative[400]
    ],
    dataLabels: {
      enabled: false
    },
    fill: {
      opacity: 0.2
    },
    markers: {
      size: 4
    },
    plotOptions: {
      bar: {
        borderRadius: 4,
        borderRadiusApplication: 'end' as const,
        distributed: true,
        horizontal: true // Add this line to distribute colors
      }
    },
    stroke: {
      width: 2
    },
    tooltip: {
      enabled: true
    },
    xaxis: {
      categories: [
        t('LEAD.CREATE.ANALYSIS.configurationCosts'),
        t('LEAD.CREATE.ANALYSIS.ANNUAL_PRICE'),
        t('LEAD.CREATE.ANALYSIS.ANNUAL_GROSS_PROFIT'),
        t('LEAD.CREATE.ANALYSIS.ANNUAL_NET_PROFIT'),
        t('LEAD.CREATE.ANALYSIS.suppliesCost'),
        t('LEAD.CREATE.ANALYSIS.logisticCost')
      ]
    },
    yaxis: {
      show: true
    } // Add this line to specify colors
  };

  const barChartData = [
    {
      data: [
        currentData?.pricing?.configurationCosts,
        currentData?.pricing?.annualPrice,
        currentData?.pricing?.annualGrossProfit,
        currentData?.pricing?.annualNetProfit,
        currentData?.pricing?.suppliesCost,
        currentData?.pricing?.logisticCost
      ],
      name: 'Analysis'
    }
  ];

  const colorPercentages = [
    {
      color: palette.mainText[200],
      percentage: (directLaborCost / totalPrice) * 100
    },
    {
      color: palette.secondary[100],
      percentage: (supervisionCost / totalPrice) * 100
    },
    {
      color: '#B57DFF',
      percentage: (configurationCosts / totalPrice) * 100
    },
    {
      color: palette.success[400],
      percentage: (suppliesCost / totalPrice) * 100
    },
    {
      color: palette.informative[400],
      percentage: (logisticCost / totalPrice) * 100
    },
    {
      color: palette.accent?.[200],
      percentage: (annualGrossProfit / totalPrice) * 100
    }
  ];

  useEffect(() => {
    updateCalculator();
    return () => {
      // currentCalculatorMutate(null, true);
    };
  }, []);

  return (
    <Box display="flex" flexDirection="column" gap="48px">
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between'
        }}
      >
        <Box>
          <Typography fontWeight="bold" variant="h5">
            {t('LEAD.CREATE.ANALYSIS.TOTAL', [
              getCurrency(isLoading ? 0 : currentData?.pricing?.annualPrice)
            ])}
          </Typography>
        </Box>
        <Box sx={{ display: 'flex' }}>
          <IconButton onClick={() => handleOpenModal()}>
            <SettingsIcon />
          </IconButton>
          <Tabs
            aria-label="analysis-tabs"
            onChange={handleTabChange}
            value={tabIndex}
          >
            <Tab label="Profitability" />
            {/* <Tab label="Analysis" /> */}
          </Tabs>
        </Box>
      </Box>
      {tabIndex === 0 && (
        <>
          <Box sx={{ display: 'flex', gap: '16px' }}>
            {values?.projectType === 'Regular' && (
              <Tile
                isLoading={isLoading}
                key="analysis-tile-profitPerMonth"
                title={t('LEAD.CREATE.ANALYSIS.PROFIT_PER_MONTH')}
                type="Currency"
                value={getCurrency(currentData?.pricing?.pricePerMonth)}
              />
            )}
            <Tile
              isLoading={isLoading}
              key="analysis-tile-profitPerProject"
              title={t('LEAD.CREATE.ANALYSIS.PROFIT_PER_RPOJECT')}
              type="Currency"
              value={getCurrency(currentData?.pricing?.annualGrossProfit)}
            />
            <Tile
              isLoading={isLoading}
              key="analysis-tile-profitPercent"
              title={t('LEAD.CREATE.ANALYSIS.PROFIT_PERCENT')}
              type="Percentage"
              value={currentData?.pricing?.profitPercent}
            />
            <Tile
              isLoading={isLoading}
              key="analysis-tile-totalCost"
              title={t('LEAD.CREATE.ANALYSIS.TOTAL_COST')}
              type="Currency"
              value={getCurrency(currentData?.pricing?.totalCosts)}
            />
          </Box>
          <Box display="flex" width="100%">
            {colorPercentages.map((segment, index) => (
              <Box
                sx={{
                  backgroundColor: segment.color,
                  height: '54px',
                  width: `${segment.percentage}%`
                }}
              />
            ))}
          </Box>

          <Box
            sx={{
              border: `1px solid ${palette.divider}`,
              borderRadius: '8px',
              padding: '24px'
            }}
          >
            <Box mb={4}>
              <Typography fontWeight="bold" variant="h6">
                {t('LEAD.CREATE.ANALYSIS.PROJECT_COST_ANALYSIS_TITLE')}
              </Typography>
              <Typography>
                {t('LEAD.CREATE.ANALYSIS.PROJECT_COST_ANALYSIS_DESCRIPTION')}
              </Typography>
            </Box>
            <Box
              sx={{
                display: 'flex',
                flexWrap: 'wrap',
                gap: '16px'
              }}
            >
              {informationItems.map((item, index) => (
                <InformationItem
                  legendColor={item.legendColor || ''}
                  onEdit={item.onEdit}
                  title={item.title}
                  value={item.value}
                />
              ))}
            </Box>
          </Box>

          {openDrawer && (
            <GeneralDrawer
              onClose={() => {
                // currentCalculatorMutate(null, true);
                setOpenDrawer(false);
                setGetCalculator(true);
              }}
              width="70%"
            >
              <FormLead
                leadInfo={values}
                onClose={() => {
                  setOpenDrawer(false);
                }}
                presetStep={presetStep}
                setValues={setValues}
              />
            </GeneralDrawer>
          )}
        </>
      )}

      {tabIndex === 1 && (
        <>
          <Box
            sx={{
              border: `1px solid ${palette.divider}`,
              borderRadius: '8px',
              display: 'flex',
              gap: '24px',
              padding: '24px'
            }}
          >
            <Box
              sx={{
                border: `1px solid ${palette.divider}`,
                borderRadius: '8px',
                padding: '24px',
                width: '50%'
              }}
            >
              <Chart
                height={400}
                options={radarChartOptions}
                series={radarChartData}
                type="radar"
              />
            </Box>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                gap: '38px',
                width: '50%'
              }}
            >
              <Tile
                isLoading={isLoading}
                key="analysis-tile-hoursSheduled"
                tileContainerPropSx={{ paddingBottom: theme.spacing(8) }}
                title={t('LEAD.CREATE.ANALYSIS.GROSS_MARGIN_AT_ACCOUNT')}
                type="Percentage"
                value={currentData?.pricing?.profitPercent}
              />
              <Box
                sx={{
                  display: 'flex',
                  gap: '24px'
                }}
              >
                <Tile
                  isLoading={isLoading}
                  key="analysis-tile-hoursSheduled"
                  tileContainerPropSx={{ paddingBottom: theme.spacing(8) }}
                  title={t('LEAD.CREATE.ANALYSIS.OVERHEAD_PERCENT')}
                  type="Percentage"
                  value={currentData?.analytics?.overheadPercent?.replace(
                    '%',
                    ''
                  )}
                />
                <Tile
                  isLoading={isLoading}
                  key="analysis-tile-hoursSheduled"
                  tileContainerPropSx={{ paddingBottom: theme.spacing(8) }}
                  title={t('LEAD.CREATE.ANALYSIS.MARK_UP_PERCENT')}
                  type="Percentage"
                  value={currentData?.analytics?.markUpPercent?.replace(
                    '%',
                    ''
                  )}
                />
              </Box>
            </Box>
          </Box>

          <Box
            sx={{
              border: `1px solid ${palette.divider}`,
              borderRadius: '8px',
              padding: '24px'
            }}
          >
            <Box
              sx={{
                border: `1px solid ${palette.divider}`,
                borderRadius: '8px',
                padding: '24px'
                // width: '50%'
              }}
            >
              <Chart
                height={350}
                options={barChartOptions}
                series={barChartData}
                type="bar"
              />
            </Box>
          </Box>
        </>
      )}
      <Box display="flex" justifyContent="flex-end" width="100%">
        <Button
          onClick={() => saveStepper({ next: 6 })}
          sx={{ width: 304 }}
          variant="contained"
        >
          <Typography sx={{ 'text-transform': 'capitalize' }} variant="primary">
            {t('LEAD.CREATE.COST_CALCULATOR.goToProposalButton')}
          </Typography>
        </Button>
      </Box>

      {openModal && (
        <Container sx={{ margin: '40px', width: 'auto' }}>
          <Modal
            open
            onClose={() => {
              setOpenModal(false);
            }}
          >
            <ModalContainer>
              <Box>
                <Typography color="mainText.main" variant="secondary">
                  {t('COMPANY_SETTINGS.staticRateCosts')}
                </Typography>
                <SortableTable
                  disableSort
                  addManagement={false}
                  bodyKeys={['static', 'value', 'periodType']}
                  disabledTable={false}
                  // handleDelete={setTaxId}
                  onChange={(newStaticCostList) => {
                    const updateStaticCostList = staticCosts.map(
                      (staticCost: any) => {
                        const newStaticCost = newStaticCostList.find(
                          (item: any) => item.id === staticCost.id
                        );
                        return newStaticCost
                          ? {
                              ...newStaticCost,
                              value: Number(newStaticCost.value)
                            }
                          : staticCost;
                      }
                    );
                    setStaticCosts(updateStaticCostList);

                    // setTaxes(updatedTaxes);
                  }}
                  type="static-input"
                  values={values.staticCosts}
                />
              </Box>
              <ButtonContainerModal>
                <>
                  <DrawerButton
                    color="error"
                    onClick={() => setOpenModal(false)}
                    sx={{
                      borderColor: 'primary.red',
                      color: 'red',
                      height: 46,
                      width: 114
                    }}
                    variant="outlined"
                  >
                    <Typography color="primary.red">
                      {t('MODAL.cancel')}
                    </Typography>
                  </DrawerButton>
                  <DrawerButton
                    onClick={() => {
                      setValues({
                        ...values,
                        staticCosts: values?.company?.staticCosts
                      });
                      setIsLoading(true);
                      setOpenModal(false);
                      setResetStaticCosts(true);
                    }}
                    sx={{ borderColor: 'primary.main', height: 46, width: 114 }}
                    variant="outlined"
                  >
                    <Typography color="primary.main">{t('Reset')}</Typography>
                  </DrawerButton>
                  <DrawerButton
                    color="info"
                    onClick={() => {
                      setIsLoading(true);
                      setOpenModal(false);
                      setUpdateStaticCost(true);
                    }}
                    sx={{ height: 46, width: 114 }}
                    variant="contained"
                  >
                    <Typography>{t('MODAL.save')}</Typography>
                  </DrawerButton>
                </>
              </ButtonContainerModal>
            </ModalContainer>
          </Modal>
        </Container>
      )}
    </Box>
  );
};

export default Analysis;
