import React, { FC } from 'react';
import { Box, BoxProps, styled, Typography, useTheme } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { FormLabel } from '../../../../components/form/parts';
import RegularInput from '../../../../components/input/RegularInput';

const FlexBox = styled(Box)<BoxProps>(() => ({
  display: 'flex'
}));

interface props {
  setValues: React.Dispatch<React.SetStateAction<any>>;
  values: any;
}

const CompleteInformation: React.FC<props> = function CompleteInformation({
  values,
  setValues
}) {
  // Hooks
  const theme = useTheme();
  const { t: translate } = useTranslation('translation', {
    // Feel free to create additional hooks if you need translations from a different keyPrefix...
    keyPrefix: 'CLIENT_ONBOARDING'
  });

  // Constants
  const inputFields: { key: string; required?: boolean; type?: string }[] = [
    {
      key: 'firstName',
      required: true
    },
    {
      key: 'lastName'
    },
    {
      key: 'email',
      required: true,
      type: 'email'
    },
    {
      key: 'phone',
      type: 'phone'
    }
  ];

  if (values?.relationship === 'B2B') {
    inputFields.push({
      key: 'businessName'
    });
    inputFields.push({
      key: 'jobTitle'
    });
  }

  return (
    <FlexBox
      flexDirection="column"
      sx={{
        gap: theme.spacing(3),
        width: '100%'
      }}
    >
      {/* Title */}
      <Typography
        alignSelf="flex-start"
        color="primary.400"
        sx={{ fontSize: '28px', lineHeight: '30px' }}
        textAlign="center"
        variant="primary-bold"
        whiteSpace="break-spaces"
      >
        {translate('completeInformationTitle')}
      </Typography>

      {/* Subtitle */}
      <Typography alignSelf="flex-start">
        {translate('completeInformationDescription')}
      </Typography>

      {/* Input fields */}
      <FlexBox flexDirection="column">
        {inputFields.map((field) => (
          <FlexBox
            flexDirection="column"
            key={field.key}
            sx={{ width: '100%' }}
          >
            <FormLabel
              label={
                translate(`fields.${field.key}`) + (field.required ? ' *' : '')
              }
            />
            <RegularInput
              customStyle={{
                width: '100%'
              }}
              name={field.key}
              onChange={(value) => setValues({ ...values, [field.key]: value })}
              type={field.type ?? 'text'}
              value={values[field.key]}
            />
          </FlexBox>
        ))}
      </FlexBox>
    </FlexBox>
  );
};

export default CompleteInformation;
