import { Stack, styled, Button, Typography } from '@mui/material';
import VerifiedIcon from '@mui/icons-material/Verified';
import { t } from 'i18next';
import useSWR from 'swr';
import { useEffect } from 'react';
import { accentColors } from '../../../theme/themeConfig';
import api from '../../../api';
import { loginLandingByRole } from '../../../helpers/routesConstants';
import { priorityRoleLogin } from '../../../helpers/roles';

const OnboardingCompleteContainer = styled(Stack)(() => ({
  alignItems: 'center',
  flexDirection: 'column',
  gap: '50px',
  height: '100%',
  justifyContent: 'center',
  width: '100%'
}));

const TextContainer = styled(Stack)(() => ({
  alignItems: 'center',
  flexDirection: 'column',
  gap: '32px',
  width: '100%'
}));

const CustomButton = styled(Button)(() => ({
  fontWeight: 'bold',
  maxWidth: '400px',
  textTransform: 'capitalize',
  width: '100%'
}));

interface Props {
  login: () => void;
  navigate: (data: any, options: any) => void;
  setUser: (data: any) => void;
}

function OnboardingComplete({ login, setUser, navigate }: Props) {
  const { data: profile, error } = useSWR(
    'api/profile',
    api.authApi.getProfile
  );

  const setProfile = () => {
    if (!error) {
      if (profile) {
        const {
          roles,
          email,
          mfa,
          block,
          active,
          firstName,
          id,
          phoneNumber,
          timeFormat,
          company
        } = profile;
        const { plan } = company;
        const rolesName = roles.map((ele: any) => ele.name);
        setUser({
          active,
          block,
          email,
          firstName,
          id,
          mfa,
          phoneNumber,
          plan,
          roles: rolesName,
          timeFormat
        });
      }
    }
  };

  const handleFinishOnboarding = async () => {
    const role = priorityRoleLogin(
      profile?.roles?.map((item: any) => item.name),
      true
    );
    await login();
    navigate(loginLandingByRole[role as keyof typeof loginLandingByRole], {
      replace: true
    });
  };

  useEffect(() => {
    if (profile) {
      setProfile();
    }
  }, [profile]);

  return (
    <OnboardingCompleteContainer>
      <VerifiedIcon sx={{ color: accentColors.ACCENT_4, fontSize: '72px' }} />
      <TextContainer>
        <Typography color="primary" variant="heading_500">
          {t('LOGIN.onboardingComplete')}
        </Typography>
        <Typography
          color="secondary.400"
          mx={8}
          textAlign="center"
          variant="body_200"
        >
          {t('LOGIN.onboardingCompleteDescription')}
        </Typography>
      </TextContainer>

      <CustomButton onClick={handleFinishOnboarding} variant="primary">
        {t('LOGIN.finishOnboardingButton')}
      </CustomButton>
    </OnboardingCompleteContainer>
  );
}

export default OnboardingComplete;
