import React, { useEffect, useState } from 'react';
import { Box, Typography, useTheme } from '@mui/material';
import { useTranslation } from 'react-i18next';
import dayjs, { Dayjs } from 'dayjs';
import {
  getFrequencyDays,
  getFrequencyDate,
  getFrequency
} from '../../../../utils/general';
import TimeComponent from '../../../../components/input/TimeComponent';
import { secondaryColors } from '../../../../theme/themeConfig';

interface Props {
  destinationIndex: number;
  saveStepper: ({ next }: any) => void;
  serviceIndex: number;
  setStep: React.Dispatch<React.SetStateAction<number>>;
  setValues: React.Dispatch<React.SetStateAction<any>>;
  values: any;
}

const ServiceTimeDuration: React.FC<Props> = function ServiceTimeDuration({
  saveStepper,
  serviceIndex: index,
  setValues,
  setStep,
  values,
  destinationIndex
}) {
  const { t } = useTranslation();
  const theme = useTheme();
  const [scheduleTimeError, setScheduleTimeError] = useState<boolean>(false);
  const [totalServiceTime, setTotalServiceTime] = useState<any>(0);
  const adHoc =
    values?.destinations[destinationIndex]?.serviceTimes?.[index]?.adhocTasks;

  const services =
    index >= 0 && values?.destinations[destinationIndex]?.serviceTimes?.[index]
      ? values?.destinations[destinationIndex]?.serviceTimes?.[index]?.services
      : values?.destinations[destinationIndex]?.serviceTimes?.[0]?.services;

  const [timeStart, setTimeStart] = React.useState<Dayjs | null>(
    dayjs().hour(8).minute(0)
  );
  const [timeEnd, setTimeEnd] = React.useState<Dayjs | null>(
    dayjs().hour(9).minute(0)
  );

  const finalHour = () => {
    const hours = Math.floor(totalServiceTime);
    const minutes = Math.round((totalServiceTime - hours) * 60);
    const finalTime = timeStart
      ? timeStart.add(hours, 'hour').add(minutes, 'minute')
      : null;
    const newDate = finalTime ? finalTime.format('h:mm A') : '';
    return newDate;
  };

  useEffect(() => {
    setTimeStart(
      dayjs()
        .hour(parseFloat(services?.[index]?.startTime?.split(':')[0]))
        .minute(parseFloat(services?.[index]?.startTime?.split(':')[1]))
    );
    setTimeEnd(
      dayjs()
        .hour(parseFloat(services?.[index]?.endTime?.split(':')[0]))
        .minute(parseFloat(services?.[index]?.endTime?.split(':')[1]))
    );

    const totalValue = services
      ? services
          .flatMap((array: any) => array.tasks)
          .reduce(
            (accumulator: any, item: any) =>
              accumulator + (item?.totalTime || 0),
            0
          )
      : 0;

    const adHocValueTime = adHoc?.totalTime ? adHoc.totalTime : 0;
    const totalTimeValue = totalValue + adHocValueTime;

    setTotalServiceTime(totalTimeValue);
  }, [values]);

  useEffect(() => {
    if (
      typeof values.destinations[destinationIndex]?.serviceTimes[index]
        .startTime === 'string'
    ) {
      const startTime = dayjs()
        .hour(
          parseFloat(
            values.destinations[destinationIndex]?.serviceTimes[
              index
            ]?.startTime?.split(':')[0]
          )
        )
        .minute(
          parseFloat(
            values.destinations[destinationIndex]?.serviceTimes[
              index
            ]?.startTime?.split(':')[1]
          )
        );
      const endTime = dayjs()
        .hour(
          parseFloat(
            values.destinations[destinationIndex]?.serviceTimes[
              index
            ]?.endTime?.split(':')[0]
          )
        )
        .minute(
          parseFloat(
            values.destinations[destinationIndex]?.serviceTimes[
              index
            ]?.endTime?.split(':')[1]
          )
        );
      setTimeStart(startTime);
      setTimeEnd(endTime);
    }
  }, [values]);

  useEffect(() => {
    // This is to show the user a message indicating that the service time is greater than the time window
    const hours = Math.floor(totalServiceTime);
    const minutes = Math.round((totalServiceTime - hours) * 60);
    const finalTime = timeStart
      ? timeStart.add(hours, 'hour').add(minutes, 'minute')
      : null;
    if (finalTime && timeEnd) {
      const finalArriveTime = timeEnd.hour() + timeEnd.minute() / 100;
      const finalCalculatedTime = finalTime.hour() + finalTime.minute() / 100;
      if (finalCalculatedTime > finalArriveTime) {
        setScheduleTimeError(true);
      } else {
        setScheduleTimeError(false);
      }
    }
  }, [values, timeStart, timeEnd]);

  useEffect(() => {
    if (scheduleTimeError !== undefined) {
      const copyValues = values;
      if (copyValues?.destinations?.[destinationIndex]?.serviceTimes?.[index]) {
        copyValues.destinations[destinationIndex].serviceTimes[
          index
        ].scheduleTimeError = scheduleTimeError;
        setValues(values);
      }
    }
  }, [scheduleTimeError]);

  return (
    <Box
      display="flex"
      flexDirection="column"
      gap="32px"
      padding="32px 24px 32px 24px"
      sx={{
        border: scheduleTimeError ? 2 : 1,
        borderColor: scheduleTimeError
          ? theme.palette.error.main
          : secondaryColors.SECONDARY_1,
        borderRadius: 1
      }}
    >
      <Typography variant="secondary-semi">
        {t('LEAD.CREATE.SERVICES.SCHEDULE.totalDurationMsg1')}
      </Typography>

      <Box
        display="grid"
        gridTemplateColumns="245px 252px 100px 1fr"
        sx={{ gridRowGap: '32px' }}
      >
        <Typography color="primary.main" variant="heading_300">
          {t('LEAD.CREATE.SERVICES.SCHEDULE.serviceDurationPerVisit')}
        </Typography>

        <Box gridColumn="span 3">
          <TimeComponent
            readOnly
            setTime={() => {}}
            textColor={secondaryColors.SECONDARY_4}
            time={totalServiceTime || 0.0}
          />
        </Box>

        {values &&
        values.projectType === 'adhoc' &&
        values.projectType === 'Ad-Hoc' ? null : (
          <>
            <Typography color="primary.main" variant="heading_300">
              {t('LEAD.CREATE.SERVICES.date')}:
            </Typography>

            <Typography color="mainText.300" variant="body_300">
              {' '}
              {values?.destinations[destinationIndex]?.serviceTimes
                ? getFrequencyDate(
                    values?.destinations[destinationIndex]?.serviceTimes[index],
                    t
                  )
                : 'frequency'}
            </Typography>

            <Typography color="primary.main" variant="heading_300">
              {t('LEAD.CREATE.SERVICES.frequency')}:
            </Typography>

            <Typography color="mainText.300" variant="body_300">
              {' '}
              {values?.destinations[destinationIndex]?.serviceTimes
                ? getFrequency(
                    values?.destinations[destinationIndex]?.serviceTimes[index],
                    t
                  )
                : 'frequency'}
            </Typography>

            <Typography color="primary.main" variant="heading_300">
              {t('LEAD.CREATE.SERVICES.SelectedDays')}:
            </Typography>

            <Box gridColumn="span 3">
              <Typography color="mainText.300" variant="body_300">
                {values?.destinations[destinationIndex]?.serviceTimes
                  ? getFrequencyDays(
                      values?.destinations[destinationIndex]?.serviceTimes[
                        index
                      ]
                    )
                  : 'frequency'}
              </Typography>
            </Box>
          </>
        )}

        {scheduleTimeError ? (
          <Box gridColumn="span 4">
            <Typography color={theme.palette.error.main} variant="body_300">
              * {t('LEAD.CREATE.SERVICES.SCHEDULE.moreHoursMsg')}
            </Typography>
          </Box>
        ) : null}
      </Box>
    </Box>
  );
};

export default ServiceTimeDuration;
