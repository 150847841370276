/* eslint-disable react/jsx-props-no-spreading */
import { Radio, styled } from '@mui/material';
import React from 'react';
import { mainColors, secondaryColors } from '../../theme/themeConfig';

const BpIcon = styled('span')(({ theme }) => ({
  backgroundColor: '#FFF',
  border: `5px solid ${secondaryColors.SECONDARY_7}`,
  borderRadius: '50%',
  height: 16,
  'input:disabled ~ &': {
    border: `5px solid ${secondaryColors.SECONDARY_5}`
  },
  'input:hover ~ &': {
    opacity: 0.7
  },
  transition: 'opacity 0.5s, border-color 0.5s',
  width: 16
}));

const BpCheckedIcon = styled(BpIcon)({
  '&:before': {
    content: '""',
    display: 'block',
    height: 16,
    width: 16
  },
  border: `5px solid ${mainColors.MAIN}`,
  'input:disabled ~ &': {
    border: `5px solid ${secondaryColors.SECONDARY}`
  },
  'input:hover ~ &': {
    opacity: 0.7
  }
});

const CustomRadio: React.FC = function CustomRadio(props) {
  return (
    <Radio
      checkedIcon={<BpCheckedIcon />}
      icon={<BpIcon />}
      sx={{ padding: '8px' }}
      {...props}
    />
  );
};

export default CustomRadio;
