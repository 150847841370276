import { Backdrop, Box, Button, Drawer, Typography } from '@mui/material';
import React from 'react';

interface Props {
  children?: React.ReactNode;
  onClose: () => void;
  position?: 'bottom' | 'left' | 'right' | 'top';
  width?: string;
}

const GeneralDrawer: React.FC<Props> = function GeneralDrawer({
  width = '500px',
  children,
  onClose,
  position = 'right'
}) {
  return (
    <Drawer
      open
      PaperProps={{ sx: { width } }}
      anchor={position}
      onClose={onClose}
      sx={(theme) => ({ zIndex: theme.zIndex.drawer + 1 })}
    >
      {children}
    </Drawer>
  );
};

GeneralDrawer.defaultProps = {
  children: undefined,
  position: 'right',
  width: '70%'
};

export default GeneralDrawer;
