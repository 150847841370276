import React from 'react';
import { useLocation } from 'react-router-dom';
import FormLead from './FormLead';

const EditLead: React.FC = function EditLead() {
  const { state: params } = useLocation();
  return (
    <FormLead
      leadId={params.id}
      newLead={params?.newLead}
      presetStep={params?.presetStep}
    />
  );
};

export default EditLead;
