/* eslint-disable react/jsx-props-no-spreading */
import React, { useState, useEffect } from 'react';
import '../../style/App.scss';
import {
  styled,
  Autocomplete,
  Checkbox,
  Typography,
  TextField,
  TextFieldProps
} from '@mui/material';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import { Box } from '@mui/system';
import useSWR from 'swr';
import Api from '../../api';
import {
  secondaryColors,
  themeFont,
  themePalette
} from '../../theme/themeConfig';

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const AutoTextField = styled(TextField)<TextFieldProps>(() => ({
  '& .MuiOutlinedInput-root': {
    borderRadius: 8
  },
  '& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': {
    border: `1px solid ${secondaryColors.SECONDARY_1}`
  },
  '.MuiOutlinedInput-notchedOutline': {
    borderStyle: 'hidden'
  },
  backgroundColor: themePalette.BACKGROUND,
  input: {
    color: themePalette.TITLE,
    fontFamily: themeFont.FONT_SECONDARY,
    fontSize: '16px',
    fontWeight: 400,
    height: 40,
    lineHeight: 1.25,
    paddingBottom: 8,
    paddingLeft: 16,
    paddingTop: 8
  },
  paddingLeft: 0,
  paddingRight: 0
}));

export const AutoCompleteStyled = styled(Autocomplete)(() => ({
  '& .MuiOutlinedInput-root': {
    paddingBottom: 0,
    paddingLeft: 0,
    paddingTop: 0
  },
  '& input': {
    fontSize: '14px'
  },
  '& li': {
    fontSize: '12px'
  },

  borderRadius: '0.5em'
}));

interface AutocompleteSupervisor {
  // eslint-disable-next-line react/require-default-props
  disabled?: boolean;
  // eslint-disable-next-line react/require-default-props
  multiple?: boolean;
  setValues: React.Dispatch<React.SetStateAction<any>>;
  type: string;
  // eslint-disable-next-line react/require-default-props
  values: any;
  // eslint-disable-next-line react/require-default-props
  width?: number;
}

const AutoCompleteSupervisor: React.FC<AutocompleteSupervisor> =
  function AutoCompleteSupervisor({
    type,
    values,
    setValues,
    disabled,
    width,
    multiple = true
  }) {
    const [currentSupervisor, setCurrentSupervisor] = useState<any>(
      values.supervisor || null
    );
    const [filteredOptions, setFilteredOptions] = useState<any>();

    const { data: supervisorsList, error: mainError } = useSWR(
      !filteredOptions
        ? { roles: ['Supervisor'], url: 'get/users/roles' }
        : null,
      Api.UserResocesApi.getUsersByRole
    );

    useEffect(() => {
      if (
        undefined !== supervisorsList &&
        supervisorsList?.length > 0 &&
        undefined !== supervisorsList[0]?.id
      ) {
        const auxV = supervisorsList.map((supervisorData: any) => {
          return {
            ...supervisorData,
            selected: supervisorData?.id === currentSupervisor?.id,
            status: supervisorData?.id === currentSupervisor?.id
          };
        });

        setFilteredOptions(auxV);
      }
    }, [supervisorsList, currentSupervisor]);
    //  ================================================================================= USEEFFECTS

    const handleSelectedOptionsChange = (event: any, options: any) => {
      if (options) {
        setCurrentSupervisor(options);
        setValues(options);
      } else {
        setCurrentSupervisor(null);
        setValues({ name: '' });
      }
    };

    const getOptionLabelFormatted = (option: any) => {
      let label = '';
      if (option.name && option.name.length > 0) {
        label = option.name;
      } else if (option.firstName && option.lastName) {
        label = `${option.firstName} ${option.lastName}`;
      }
      return label;
    };

    return (
      <AutoCompleteStyled
        disableCloseOnSelect
        disabled={disabled}
        getOptionLabel={(option: any) => getOptionLabelFormatted(option)}
        id="supervisor"
        multiple={multiple}
        onChange={handleSelectedOptionsChange}
        options={filteredOptions || []}
        renderInput={(params) => {
          return (
            <AutoTextField
              {...params}
              InputProps={params.InputProps}
              value={values.supervisor?.name || ''}
              variant="outlined"
            />
          );
        }}
        renderOption={(props, option: any, { selected }) => (
          <li {...props}>
            {/* <Checkbox
              checked={option.selected}
              checkedIcon={checkedIcon}
              icon={icon}
              style={{
                marginRight: 8
              }}
            /> */}
            <Box
              sx={{
                alignItems: 'center',
                display: 'flex',
                justifyContent: 'space-between',
                width: '100%'
              }}
            >
              <Typography variant="body_300">
                {`${option.firstName} ${option.lastName}`}
              </Typography>
            </Box>
          </li>
        )}
        size="small"
        sx={{ margin: 0 }}
        value={currentSupervisor || null}
      />
    );
  };

export default AutoCompleteSupervisor;
