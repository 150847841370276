/* eslint-disable react/no-array-index-key */
import React, { useEffect, useRef, useState } from 'react';
import {
  Container,
  Box,
  Typography,
  Button,
  styled,
  SliderProps,
  useTheme,
  Fab,
  Modal,
  Checkbox,
  Collapse,
  IconButton
} from '@mui/material';
import useSWR from 'swr';
import { useTranslation } from 'react-i18next';
import { isMobile } from 'react-device-detect';
import { useReactToPrint } from 'react-to-print';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import { ChevronLeft, ChevronRight, Close } from '@mui/icons-material';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import Api from '../../../api';
import ExpiredProposalLink from './components/propoosal/ExpiredProposalLink';
import AcceptedProposal from './components/propoosal/AcceptedProposal';
import ProposalTitle from '../../../components/proposal/Title';
import ProposalGeneralInformation from '../../../components/proposal/GeneralInformation';
import SummaryService from './components/SummaryService';
import Loader from '../../../components/shared/Loader';
import TableComponent from '../../../components/shared/TableComponent';
import {
  calculateTimeDifference,
  formatDate,
  getCurrency
} from '../../../utils/general';
import Chat from './components/Chat';
import RejectProposalModal from '../../../components/modals/RejectProposalModal';
import PdfViewerComponent from '../../../components/shared/container/pdfViewerContainer';
import { ModalContainer } from '../../../components/modals/GlobalConfirmModal';
import ProposalNotFound from './components/propoosal/ProposalNotFound';
import RejectedProposal from './components/propoosal/RejectedProposal';
import CounterOfferMain from './components/propoosal/CounterOfferMain';
import useNavigationStore from '../../../store/zustand/navigation';
import SignatureField from '../../../components/input/SignatureInput';
import ServiceDetail from './components/propoosal/ServiceDetail';
import blueBerryLogoMini from '../../../assets/images/bluberry_logo_mini.png';
import {
  Destination,
  Lead,
  Proposal,
  ServiceTime
} from '../../../utils/types/ClientProposalView';

const ButtonsContainer = styled(Box)<SliderProps>(() => ({
  display: 'flex',
  float: 'right',
  justifyContent: 'space-between'
}));

const ButtonsContainerCentered = styled(Box)<SliderProps>(({ theme }) => ({
  alignItems: 'center',
  display: 'flex',
  justifyContent: 'flex-end',
  paddingTop: theme.spacing(3),
  width: '100%'
}));

interface ClientProposalViewProps {
  closeModal?: () => void;
  proposalInfo?: any;
}

const steps = isMobile
  ? ['Brochure', 'General Information', 'Services', 'Billing', 'Legal']
  : ['General Information', 'Services', 'Billing', 'Legal'];

const ClientProposalView: React.FC<ClientProposalViewProps> =
  function ClientProposalView({ proposalInfo, closeModal }) {
    const admin = false;
    const { t } = useTranslation();
    const theme = useTheme();
    const navigation = useNavigationStore((state) => state);
    const [accessByToken, setAccessByToken] = useState<boolean>(false);
    const [sentFlag, setSentFlag] = useState<boolean>(false);
    const [notFound, setNotFound] = useState<boolean>(false);
    const [expiredProposal, setExpiredProposal] = useState<boolean>(false);
    const [accepted, setAccepted] = useState<boolean>(false);
    const [rejected, setRejected] = useState<boolean>(false);
    const [openSuccessful, setOpenSuccessful] = useState<boolean>(false);
    const [showRejectModal, setShowRejectModal] = useState<boolean>(false);
    const [acceptProposal, setAcceptProposal] = useState<boolean>(false);
    const [showChatModal, setShowChatModal] = useState<boolean>(false);
    const [acceptedProposal, setAcceptedProposal] = useState<boolean>(false);
    const [rejectedProposal, setRejectedProposal] = useState<boolean>(false);
    const [proposalId, setProposalId] = useState<string>('');
    const [values, setValues] = useState<any>(false);
    const [loading, setLoading] = useState<boolean>(true);
    const [acceptedTerms, setAcceptTerms] = useState<boolean>(false);
    const [termsPdfURL, setTermsPdfURL] = useState<string>('');
    const [brochurePdfURL, setBrochurePdfURL] = useState<string>('');
    const [wantNewProposal, setWantNewProposal] = useState<boolean>(false);
    const [openCollapse, setOpenCollapse] = useState<boolean>(false);
    const [serviceTimeDetail, setServiceTimeDetail] = useState<any>();
    const [counterOfferMode, setCounterOfferMode] = useState<boolean>(false);
    const [brochureOverlay, setBrochureOverlay] = useState<boolean>(false);
    const [counterOfferSubmitted, setCounterOfferSubmitted] =
      useState<boolean>(false);
    const [signature, setSignature] = useState<string>('');
    const [validSignature, setValidSignature] = useState<boolean>(false);
    const [PDFView, setPDFView] = useState<boolean>(false);

    const tokenParam = window.location.pathname.split('/')[2];

    const { data: proposalData, mutate: proposalMutate } = useSWR(
      sentFlag ? tokenParam : null,
      Api.ProposalApi.getProposalByToken
    ) as any;

    const acceptProposalAction = Api.ProposalApi.acceptProposal;

    const { data: acceptProposalData, error: acceptProposalDataError } = useSWR(
      acceptProposal ? { token: tokenParam } : null,
      acceptProposalAction
    );

    const [activeStep, setActiveStep] = React.useState(0);

    const [completed, setCompleted] = React.useState<{
      [k: number]: boolean;
    }>({});

    const totalSteps = () => {
      return steps.length;
    };

    const completedSteps = () => {
      return Object.keys(completed).length;
    };

    const isLastStep = () => {
      return activeStep === totalSteps() - 1;
    };

    const allStepsCompleted = () => {
      return completedSteps() === totalSteps();
    };

    const handleNext = () => {
      const newActiveStep =
        isLastStep() && !allStepsCompleted()
          ? steps.findIndex((step, i) => !(i in completed))
          : activeStep + 1;
      setActiveStep(newActiveStep);
    };

    const handleBack = () => {
      setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const handleSignatureChange = (value: string) => {
      setSignature(value);
      if (value === values.fullName) {
        setValidSignature(true);
      } else {
        setValidSignature(false);
      }
    };

    const getButtons = () => {
      if (values?.counterOfferSubmitted && accessByToken) {
        return (
          <Box>
            <Typography color="primary" variant="primary">
              {t('LEAD.CREATE.PROPOSAL.counterOfferSubmited')}
            </Typography>
          </Box>
        );
      }
      if (!accessByToken) {
        return (
          <ButtonsContainerCentered>
            <Button
              onClick={() => {
                if (closeModal) {
                  closeModal();
                }
              }}
              sx={{ width: isMobile ? 140 : 200 }}
              variant="cancel"
            >
              {t('GENERAL.closeButton')}
            </Button>
          </ButtonsContainerCentered>
        );
      }
      return (
        <ButtonsContainer width="100%">
          {accepted && !rejected && (
            <Box
              alignItems="center"
              display="flex"
              justifyContent="flex-end"
              mb={4}
              mt={-3}
              width="100%"
            >
              <Typography sx={{ fontSize: 18 }} variant="primary">
                {t('LEAD.CREATE.PROPOSAL.buttonContinueTermsConditions')}
              </Typography>
              <Button
                onClick={() => setOpenSuccessful(true)}
                sx={{ m: 2, width: '24%' }}
                variant="primary"
              >
                {t('LEAD.CREATE.PROPOSAL.buttonNext')}
              </Button>
            </Box>
          )}

          {rejected && !accepted && (
            <Box
              alignItems="left"
              display="flex"
              justifyContent="flex-start"
              width="100%"
            >
              <Typography
                sx={{ fontSize: 18, marginRight: 4 }}
                variant="primary"
              >
                {t('LEAD.CREATE.PROPOSAL.rejectedProposalText')}
              </Typography>
            </Box>
          )}

          {!accepted && !rejected && (
            <Box
              display="flex"
              sx={{
                alignItems: 'center',
                display: 'flex',
                flexDirection: 'column',
                gap: 2,
                justifyContent: 'center'
              }}
              width="100%"
            >
              {counterOfferSubmitted && (
                <Box>
                  <Typography color="primary" variant="primary">
                    {t(
                      'LEAD.CREATE.PROPOSAL.COUNTEROFFER_REVIEW.blockActionByCounterOfferSubtitle'
                    )}
                  </Typography>
                </Box>
              )}
              <Box sx={{ display: 'flex', flexDirection: 'row' }}>
                <Button
                  fullWidth
                  disabled={counterOfferSubmitted}
                  onClick={() => setShowRejectModal(true)}
                  sx={{
                    alignItems: 'center',
                    display: 'flex',
                    gap: 2,
                    m: 1,
                    width: isMobile ? 140 : 200
                  }}
                  variant="cancel"
                >
                  {t('LEAD.CREATE.PROPOSAL.buttonClientDecline')}
                </Button>
                <Button
                  fullWidth
                  disabled={
                    !acceptedTerms || !validSignature || counterOfferSubmitted
                  }
                  onClick={() => setAcceptProposal(true)}
                  sx={{
                    alignItems: 'center',
                    display: 'flex',
                    gap: 2,
                    m: 1,
                    width: isMobile ? 140 : 200
                  }}
                  variant="primary"
                >
                  {t('LEAD.CREATE.PROPOSAL.buttonClientAccept')}
                </Button>
              </Box>
            </Box>
          )}
        </ButtonsContainer>
      );
    };

    const getCounterOfferButton = () => {
      if (
        !values?.allowCounterOffers ||
        values?.counterOfferSubmitted ||
        counterOfferSubmitted
      ) {
        return <Box />;
      }
      return (
        <Button
          color="success"
          onClick={() => {
            setCounterOfferMode(true);
            setOpenCollapse(false);
          }}
          variant="text"
        >
          {t('LEAD.CREATE.PROPOSAL.makeCounterOffer')}
        </Button>
      );
    };

    const getServiceTimeSummary = (
      isPrintable: boolean | undefined = undefined
    ) => {
      const { lead }: { lead: Lead } = values;
      return (
        <Box>
          {lead && lead?.destinations && lead?.destinations.length > 0 && (
            <Box display="flex" flexDirection="column" gap="16px" width="100%">
              {lead?.destinations?.map(
                (destination: Destination, key: number) => (
                  <Box key={destination.id}>
                    <Box
                      display="flex"
                      flexDirection="row"
                      justifyContent="space-between"
                      marginBottom={theme.spacing(2)}
                    >
                      <Box>
                        <Typography variant="secondary-semi">
                          {`${t('LEAD.CREATE.DESTINATIONS.destination')} #${
                            key + 1
                          }`}
                          :{' '}
                          <Typography color="mainText.300" variant="body_300">
                            {destination?.address?.name}
                          </Typography>
                        </Typography>
                      </Box>
                      <Box
                        alignItems="center"
                        color="secondary.main"
                        display="flex"
                        gap={1}
                      >
                        <i
                          className="custom-icon icon-classic-phone"
                          style={{ fontSize: '20px' }}
                        />
                        <Typography variant="body_300">
                          {`${
                            destination?.contact?.phoneNumber ??
                            t('LEAD.CREATE.DESTINATIONS.null')
                          }`}
                        </Typography>
                      </Box>
                    </Box>
                    {destination?.serviceTimes?.map(
                      (serviceTime: ServiceTime, index: number) => {
                        if (typeof serviceTime.id === 'number') {
                          return (
                            <SummaryService
                              clientView
                              customerSummary
                              validQuotePrice
                              destinationIndex={key}
                              isPrintable={isPrintable}
                              key={serviceTime.id}
                              serviceTime={{ ...serviceTime }}
                              serviceTimeIndex={index}
                              setNewMargin={() => {}}
                              setNewMarginCost={() => {}}
                              setOpenDetails={() => {
                                if (openCollapse && serviceTimeDetail) {
                                  setOpenCollapse(false);
                                } else {
                                  setOpenCollapse(true);
                                }
                                setServiceTimeDetail(serviceTime);
                              }}
                              setTotalcostProject={() => {}}
                              setTotalcostProjectCost={() => {}}
                              setTotalcostvisit={() => {}}
                              setValidQuotePrice={() => {}}
                              setValues={() => {}}
                              totalcostProject={0}
                              totalcostProjectCost={serviceTime?.sellPrice}
                              totalvisit={
                                serviceTime.availability?.generalInfo
                                  ?.daysOfServiceCount || 0
                              }
                              type="proposal"
                              values={lead}
                            />
                          );
                        }
                        return null;
                      }
                    )}
                  </Box>
                )
              )}
            </Box>
          )}
        </Box>
      );
    };

    const pricingSummary = () => {
      const { lead } = values;
      let proposalTotal = 0;
      const currentProposalLead = lead?.proposals?.filter(
        (proposal: Proposal) => proposal.id === values?.id
      )[0];
      const dataService = {
        duration: calculateTimeDifference(
          lead?.projectStartDate,
          lead?.projectEndDate
        ),
        endDate: formatDate(lead?.projectEndDate),
        frequency: lead?.proposals[0]?.invoiceFrequency,
        invoiceSendOn: lead?.proposals[0]?.sendOn,
        startDate: formatDate(lead?.projectStartDate)
      };
      const dataServices = lead?.destinations?.flatMap(
        (destination: Destination, destIndex: number) =>
          destination?.serviceTimes?.flatMap(
            (service: ServiceTime, index: number) => {
              const amount = service?.sellPrice || 0;
              proposalTotal += amount;
              if (index === 0) {
                return [
                  {
                    amount: getCurrency(0),
                    details: [
                      <Typography key={service.id} variant="secondary-semi">
                        {`${t('LEAD.CREATE.DESTINATIONS.destination')} #${
                          destIndex + 1
                        }: ${destination.address.name}`}
                      </Typography>
                    ],
                    visits: ''
                  },
                  {
                    amount: getCurrency(amount),
                    details: `${t(
                      'LEAD.CREATE.PROPOSAL.tablePricingBillingService'
                    )} #${index + 1}`,
                    visits: `${service?.availability?.generalInfo?.daysOfServiceCount} visits`
                  }
                ];
              }
              return {
                amount: getCurrency(amount),
                details: `${t(
                  'LEAD.CREATE.PROPOSAL.tablePricingBillingService'
                )} #${index + 1}`,
                visits: `${service?.availability?.generalInfo?.daysOfServiceCount} visits`
              };
            }
          )
      );
      const data = [
        ...dataServices,
        {
          amount: getCurrency(proposalTotal),
          details: t('LEAD.CREATE.PROPOSAL.tableInvoiceTotal')
        }
      ];

      const isItemLoaded = (index: any) => {
        if (undefined !== data && undefined !== data[index]) {
          return data[index];
        }
        return false;
      };

      return (
        <Box
          sx={{
            border: `1px solid ${theme.palette.divider}`,
            borderRadius: theme.spacing(1),
            padding: theme.spacing(3)
          }}
        >
          <Box>
            <Typography variant="h6">
              {t('LEAD.CREATE.PROPOSAL.pricingSummaryTable1')}
            </Typography>
            <Typography
              color="secondary.main"
              fontSize="14px"
              variant="body_500"
            >
              {`This quotation is based on a ${dataService.frequency} billing cycle,
              with invoices sent every ${dataService.invoiceSendOn} of the week. The contract duration
              is ${dataService.duration} (${dataService.startDate} - ${dataService.endDate}).`}
            </Typography>
            <Box mt={2} />
            <Typography
              color="secondary.main"
              fontSize="14px"
              variant="body_500"
            >
              {` Please review the services selected below for the total number of visits`}
            </Typography>
          </Box>
          <Box
            sx={{
              borderBottom: `1px solid ${theme.palette.secondary[100]}`,
              marginTop: 1,
              width: '100%'
            }}
          />
          <Box mt={2}>
            <TableComponent
              disableSort
              fullSize
              action={() => {}}
              headKeys={['details', 'visits', 'amount']}
              isItemLoaded={isItemLoaded}
              itemCount={1}
              loadMoreItems={() => {}}
              valuesArray={{ data } as unknown as { data: any[] }}
            />
          </Box>
          <Box
            alignItems="center"
            bgcolor="surface.300"
            borderRadius={2}
            display="flex"
            justifyContent="space-between"
            marginTop={2}
            maxWidth="100%"
            padding={2}
          >
            <Box width="70%">
              <Typography fontWeight="bold" variant="subtitle2">
                Security Deposit
              </Typography>
              <Typography
                color="secondary.main"
                fontSize="14px"
                variant="body_500"
              >
                This deposit ensures the reservation and will be applied toward
                the final service cost.
              </Typography>
            </Box>
            <Box alignSelf="flex-end">
              <Typography fontWeight="bold" variant="subtitle2">
                {getCurrency(currentProposalLead?.depositAmount || 0)}
              </Typography>
            </Box>
          </Box>
        </Box>
      );
    };

    const parseProposalInformation = (proposalData: any) => {
      const {
        fileBank,
        proposal: data,
        counterOfferSubmitted: blockProposalActions
      } = proposalData.data;
      setSentFlag(false);
      setProposalId(data?.id);
      setCounterOfferSubmitted(blockProposalActions);
      const { status } = data;
      if (!admin && (status === 'approved' || status === 'rejected')) {
        setNotFound(true);
        setLoading(false);
        return;
      }

      const newDestinations =
        data?.lead?.destinations?.length > 0
          ? data?.lead?.destinations?.map((destination: Destination) => {
              const newServiceTimes =
                destination?.serviceTimes?.length > 0
                  ? destination?.serviceTimes?.map(
                      (serviceTime: ServiceTime) => {
                        const newServicesResponse =
                          Api.LeadServiceTimesApi.newServicesResponseFunc(
                            serviceTime.services
                          );
                        let sellPrice = 0;
                        data?.proposalServiceTime.forEach((item: any) => {
                          if (serviceTime.id === item.leadServiceTime?.id) {
                            sellPrice = item.pricePerVisit;
                          }
                        });
                        return {
                          ...serviceTime,
                          projectEnd: data?.lead?.projectEndDate,
                          projectStart: data?.lead?.projectStartDate,
                          sellPrice,
                          services: newServicesResponse.newServs,
                          totalTime: newServicesResponse.totalTime
                        };
                      }
                    )
                  : [];
              return {
                ...destination,
                serviceTimes: newServiceTimes
              };
            })
          : [];
      const auxNewValues = {
        ...data,
        counterOfferSubmitted,
        fullName: `${data?.client?.firstName} ${data?.client?.lastName}`,
        lead: {
          ...data.lead,
          client: {
            ...data.lead.client,
            name: `${data?.lead?.client?.firstName} ${data?.lead?.client?.lastName} from ${data?.lead?.client?.businessName}`
          },
          destinations: newDestinations,
          endDate: data?.lead?.projectEndDate,
          profit: data?.lead?.profitPercentage,
          startDate: data?.lead?.projectStartDate
        }
      };
      setValues(auxNewValues);

      if (fileBank && fileBank.length > 0) {
        const termsPdf = fileBank.filter(
          (item: any) => item.metaDescription === 'termsAndConditions'
        );
        setTermsPdfURL(termsPdf[0]?.fileUrl);

        const brochurePdf = fileBank.filter(
          (item: any) => item.metaDescription === 'companyBrouchure'
        );

        setBrochurePdfURL(brochurePdf[0]?.fileUrl);
      }
      setTimeout(() => {
        setLoading(false);
      }, 1000);
    };

    const getChatModal = () => {
      if (!showChatModal) {
        return (
          <Fab
            aria-label="add"
            color="primary"
            onClick={() => setShowChatModal(true)}
            sx={{ position: 'absolute', right: 40, top: 260 }}
          >
            <i className="custom-icon icon-chat" />
          </Fab>
        );
      }
      return (
        <Container
          sx={{
            alignItems: 'normal',
            margin: '40px'
            // padding: '40px'
          }}
        >
          <Modal
            onClose={() => {
              setShowChatModal(false);
            }}
            open={showChatModal}
            sx={{
              alignItems: 'center'
            }}
          >
            <ModalContainer
              sx={{
                '@media (min-width: 900px)': {
                  minWidth: '900px'
                },
                alignItems: 'center',
                // padding: '7rem',
                position: 'fixed',
                width: 'auto'
              }}
            >
              <Box
                border={`1px solid ${theme.palette.secondary[100]}`}
                borderRadius={4}
                display="flex"
                maxHeight={600}
                pt={4}
                style={{ overflow: 'hidden' }}
                width="90%"
              >
                <Chat
                  clientView={!admin}
                  id={tokenParam || values.id}
                  messages={values.proposalMessages}
                  updateMessages={(proposalMessages: any) => {
                    setValues({ ...values, proposalMessages });
                  }}
                />
              </Box>
            </ModalContainer>
          </Modal>
        </Container>
      );
    };

    const getPageMargins = () => {
      return `
        @page { 
          margin: 30px 30px 30px 30px !important; 
          size: auto; 
        } 
        @media all {
          .page-break {
            display: none;
          }
        }
        @media print {
          html, body {
            height: initial !important;
            overflow: initial !important;
            -webkit-print-color-adjust: exact;
          }
        }
        @media print { 
          .page-break {
            margin-top: 1rem;
            display: block;
            page-break-before: auto;
          }
        }
      `;
    };

    const componentRef = useRef(null);
    const handlePrint = useReactToPrint({
      content: () => componentRef?.current,
      documentTitle: 'BluberryProposal',
      pageStyle: getPageMargins
    });

    const viewPDFTerms = () => {
      setPDFView(true);
      setOpenCollapse(true);
    };

    const getGeneralNavigation = () => {
      return (
        <Box
          sx={{
            alignItems: 'center',
            display: 'flex',
            flexDirection: 'column',
            pb: 4,
            pt: 4
          }}
        >
          <Box
            sx={{
              alignItems: 'center',
              display: 'flex',
              justifyContent: 'space-between',
              width: '100%'
            }}
          >
            <IconButton disabled={activeStep === 0} onClick={handleBack}>
              <ChevronLeft />
            </IconButton>
            <Box
              sx={{
                alignItems: 'center',
                display: 'flex',
                flexDirection: 'column',
                gap: 1
              }}
            >
              <Box sx={{ display: 'flex', gap: 1 }}>
                {steps.map((_, index) => (
                  <Box
                    key={index}
                    sx={{
                      backgroundColor:
                        index === activeStep ? 'grey.800' : 'grey.400',
                      borderRadius: '50%',
                      height: 10,
                      width: 10
                    }}
                  />
                ))}
              </Box>
              <Typography sx={{ color: 'grey.600' }} variant="body2">
                {steps[activeStep]}
              </Typography>
            </Box>

            <IconButton onClick={handleNext}>
              <ChevronRight />
            </IconButton>
          </Box>
        </Box>
      );
    };

    const getDivider = () => {
      return (
        <Box
          sx={{
            borderBottom: `1px solid ${theme.palette.secondary[100]}`,
            marginTop: theme.spacing(4),
            width: '100%'
          }}
        />
      );
    };

    const getProposalBody = () => {
      return (
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
            marginTop: 5,
            paddingBottom: 20
          }}
        >
          {isMobile && activeStep === 0 && (
            <Box
              sx={{
                alignItems: 'center',
                display: 'flex',
                justifyContent: 'center',
                width: '100%'
              }}
            >
              <PdfViewerComponent file={brochurePdfURL} />
            </Box>
          )}
          {values &&
            ((isMobile && activeStep === 1) ||
              (!isMobile && activeStep === 0)) && (
              <ProposalGeneralInformation clientView values={values?.lead} />
            )}
          {values &&
            ((isMobile && activeStep === 2) ||
              (!isMobile && activeStep === 1)) &&
            getServiceTimeSummary()}
          {values &&
            ((isMobile && activeStep === 3) ||
              (!isMobile && activeStep === 2)) &&
            pricingSummary()}
          {((isMobile && activeStep === 4) ||
            (!isMobile && activeStep === 3)) && (
            <Box
              sx={{
                border: `1px solid ${theme.palette.divider}`,
                borderRadius: theme.spacing(1),
                padding: theme.spacing(3)
              }}
            >
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                  width: '100%'
                }}
              >
                <Typography
                  sx={{
                    marginBottom: 2
                  }}
                  variant="h6"
                >
                  {t('LEAD.CREATE.PROPOSAL.termsToProceed')}
                </Typography>
                {isMobile ? (
                  <Button onClick={viewPDFTerms}>
                    <VisibilityOutlinedIcon color="primary" />
                  </Button>
                ) : (
                  <Button onClick={handlePrint}>
                    <FileDownloadOutlinedIcon color="primary" />
                    {t('LEAD.CREATE.PROPOSAL.downloadProposalPDF')}
                  </Button>
                )}
              </Box>
              <Typography color="secondary" fontSize="14px" variant="body_500">
                {t('LEAD.CREATE.PROPOSAL.termsDescription')}
              </Typography>
              <Box
                sx={{
                  borderBottom: `1px solid ${theme.palette.secondary[100]}`,
                  marginBottom: 4,
                  marginTop: 4,
                  width: '100%'
                }}
              />
              <Box alignItems="center" display="flex" flexDirection="row">
                <Checkbox
                  checked={acceptedTerms}
                  onChange={() => setAcceptTerms(!acceptedTerms)}
                  style={{
                    color: theme.palette.primary.main
                  }}
                />
                <Typography>{t('LEAD.CREATE.PROPOSAL.acceptTerms')}</Typography>
              </Box>
              <SignatureField
                onChange={handleSignatureChange}
                placeHolder={values?.fullName}
                valid={validSignature}
                value={signature}
              />
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  pt: 2
                }}
              >
                {getButtons()}
              </Box>
            </Box>
          )}
          <Box
            sx={{
              backgroundColor: 'white',
              bottom: 0,
              position: 'absolute',
              width: isMobile ? '95%' : '45%'
            }}
          >
            {getDivider()}
            {getGeneralNavigation()}
          </Box>
        </Box>
      );
    };

    const getCounterOfferComponent = () => {
      return (
        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
          <Box pb={3} pt={3}>
            {values?.lead?.destinations && (
              <CounterOfferMain
                clientView
                destinations={values?.lead?.destinations}
                mutate={() => {
                  setValues({ ...values, counterOfferSubmitted: true });
                  setCounterOfferSubmitted(true);
                }}
                onClose={() => {
                  setCounterOfferMode(false);
                }}
                token={tokenParam}
              />
            )}
          </Box>
        </Box>
      );
    };

    const getOverlay = (customOverlay: boolean) => {
      if (!customOverlay) {
        return '';
      }
      return (
        <Box
          sx={{
            backgroundColor: 'rgba(0,0,0,0.7)',
            height: '100%',
            left: 0,
            position: 'absolute',
            top: 0,
            width: '100%',
            zIndex: 1
          }}
        />
      );
    };

    const getServiceTimeDetail = () => {
      const { services } = serviceTimeDetail;
      return (
        <Box
          sx={{ display: 'flex', flexDirection: 'column', gap: 3, padding: 5 }}
        >
          <Box
            className="header"
            sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}
          >
            <Typography color="primary" variant="heading_400">
              {t('LEAD.CREATE.PROPOSAL.SERVICE_DETAIL.title')}
            </Typography>
            <Typography color="secondary" variant="body_200">
              {t('LEAD.CREATE.PROPOSAL.SERVICE_DETAIL.description')}
            </Typography>
          </Box>
          <Box display="flex" flexDirection="column" gap="24px">
            {services?.map((service: any) => (
              <ServiceDetail service={service} />
            ))}
          </Box>
        </Box>
      );
    };

    const getPDFView = () => {
      return (
        <PdfViewerComponent
          file={termsPdfURL}
          title={t('LEAD.CREATE.PROPOSAL.titleTerms')}
        />
      );
    };

    // ======================================================================== LIFECYCLE

    useEffect(() => {
      if (acceptProposalData && !acceptProposalDataError) {
        setAcceptProposal(false);
        setAcceptedProposal(true);
      }
    }, [acceptProposalData]);

    useEffect(() => {
      if (proposalData?.data) {
        setAccessByToken(true);
        if (proposalData?.data?.error) {
          setSentFlag(false);
          setNotFound(true);
          setLoading(false);
        } else {
          parseProposalInformation(proposalData);
        }
      }
    }, [proposalData]);

    useEffect(() => {
      if (proposalInfo) {
        parseProposalInformation({ data: proposalInfo });
      }
    }, [proposalInfo]);

    useEffect(() => {
      if (!proposalData && !admin) {
        navigation.setCurrentStepScreen('proposal-client-view');
        setSentFlag(true);
      }
      return () => {
        navigation.setCurrentStepScreen('');
      };
    }, []);

    // ======================================================================== RENDER

    if (loading) {
      return <Loader customColor="primary" customSize={48} />;
    }

    return (
      <Container
        sx={{
          alignItems: 'center',
          display: 'flex',
          justifyContent: 'center'
        }}
      >
        {!notFound &&
          !expiredProposal &&
          !acceptedProposal &&
          !rejectedProposal &&
          !counterOfferSubmitted &&
          values &&
          values?.id && (
            <Box
              sx={{
                backgroundColor: 'white',
                display: 'flex',
                flexDirection: isMobile ? 'column' : 'row',
                height: '100%',
                justifyContent: 'flex-end',
                overflow: 'scroll',
                overflowX: 'hidden',
                width: '100%'
              }}
            >
              {!isMobile && (
                <Box
                  sx={{
                    alignItems: 'center',
                    backgroundColor: theme.palette.surface[200],
                    display: 'flex',
                    flexDirection: 'column',
                    height: '100%',
                    justifyContent: 'center',
                    left: 0,
                    position: 'fixed',
                    top: 0,
                    width: '50vw'
                  }}
                >
                  {getOverlay(brochureOverlay || openCollapse)}
                  <Box sx={{ width: '75%' }}>
                    <PdfViewerComponent
                      file={activeStep === 3 ? termsPdfURL : brochurePdfURL}
                      title={activeStep === 3 ? 'Terms and Conditions' : ''}
                    />
                  </Box>
                </Box>
              )}
              <Box
                sx={{
                  backgroundColor: theme.palette.background.paper,
                  height: '100vh',
                  padding: isMobile ? 2 : 4,
                  right: 0,
                  top: 0,
                  width: isMobile ? '100%' : '50vw',
                  zIndex: 1
                }}
              >
                {counterOfferMode ? (
                  <Box
                    display="flex"
                    flexDirection="row"
                    gap="16px"
                    mt={2}
                    width="100%"
                  >
                    <Box>
                      <img
                        alt="BlueBerry Logo"
                        className="logo-image-mini"
                        src={blueBerryLogoMini}
                      />
                    </Box>
                    <Box
                      display="flex"
                      flexDirection="column"
                      marginLeft="10px"
                    >
                      <Typography
                        color="secondary"
                        sx={{ fontSize: 22 }}
                        variant="heading_300"
                      >
                        {t('LEAD.CREATE.PROPOSAL.counterOfferTitle')}
                      </Typography>
                      <Typography
                        color="secondary.200"
                        sx={{ fontSize: 14 }}
                        variant="body_500"
                      >
                        {t(
                          'LEAD.CREATE.PROPOSAL.COUNTEROFFER_REVIEW.adjustProposal'
                        )}
                      </Typography>
                    </Box>
                  </Box>
                ) : (
                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: 'row',
                      justifyContent: 'space-between'
                    }}
                  >
                    {isMobile && getOverlay(brochureOverlay || openCollapse)}
                    <ProposalTitle
                      clientView
                      previewFlag
                      values={values?.lead}
                    />
                    <Box display="flex" gap={isMobile ? 1 : 3}>
                      <IconButton onClick={handlePrint}>
                        <FileDownloadOutlinedIcon color="primary" />
                      </IconButton>
                      {getCounterOfferButton()}
                      <Button
                        onClick={() => {
                          if (openCollapse && !serviceTimeDetail) {
                            setOpenCollapse(false);
                          } else {
                            setOpenCollapse(true);
                          }
                          setServiceTimeDetail(undefined);
                        }}
                        sx={{ gap: 1 }}
                        variant="contained"
                      >
                        <i className="custom-icon icon-chat" />
                        {!isMobile && t('LEAD.CREATE.PROPOSAL.messageUs')}
                      </Button>
                    </Box>
                  </Box>
                )}
                {!counterOfferMode && getProposalBody()}
                {counterOfferMode && getCounterOfferComponent()}
              </Box>
              <Collapse
                in={openCollapse}
                orientation={isMobile ? 'vertical' : 'horizontal'}
              >
                <Box
                  sx={{
                    backgroundColor: theme.palette.surface[200],
                    borderTopLeftRadius: isMobile ? 12 : 0,
                    borderTopRightRadius: isMobile ? 12 : 0,
                    bottom: isMobile ? 0 : 'auto',
                    height: isMobile ? 'auto' : '100vh',
                    maxHeight: isMobile ? '90vh' : '100vh',
                    overflow: 'auto',
                    position: isMobile ? 'fixed' : 'relative',
                    width: isMobile ? '100%' : 450,
                    zIndex: isMobile ? 1300 : 'auto'
                  }}
                >
                  <IconButton
                    onClick={() => {
                      setOpenCollapse(false);
                      setTimeout(() => {
                        setPDFView(false);
                        setServiceTimeDetail(false);
                      }, 500);
                    }}
                    sx={{ position: 'absolute', right: 2, top: 2 }}
                  >
                    <Close />
                  </IconButton>
                  {!serviceTimeDetail && !PDFView && (
                    <Chat
                      clientView={!admin}
                      id={tokenParam || values.id}
                      messages={values.proposalMessages}
                      updateMessages={(proposalMessages: any) => {
                        setValues({ ...values, proposalMessages });
                      }}
                    />
                  )}
                  {serviceTimeDetail && getServiceTimeDetail()}
                  {PDFView && getPDFView()}
                </Box>
              </Collapse>
            </Box>
          )}
        <Box
          sx={{
            alignItems: 'center',
            display: 'flex',
            height: '100%',
            justifyContent: 'center',
            width: '100%'
          }}
        >
          {notFound && <ProposalNotFound />}
          {expiredProposal && proposalId && (
            <ExpiredProposalLink proposalId={proposalId} />
          )}
          {showRejectModal && (
            <RejectProposalModal
              allowCounterOffers={values?.allowCounterOffers}
              onSubmitCounterOffer={() => {
                setCounterOfferMode(true);
                setOpenCollapse(false);
                setShowRejectModal(false);
              }}
              setRejected={setRejected}
              setRejectedProposal={setRejectedProposal}
              setShowRejectModal={setShowRejectModal}
              setWantNewProposal={setWantNewProposal}
              view="client"
            />
          )}
          {rejectedProposal && (
            <RejectedProposal wantNewProposal={wantNewProposal} />
          )}
          {acceptedProposal && proposalId && (
            <AcceptedProposal proposalId={proposalId} />
          )}
          {counterOfferSubmitted && (
            <AcceptedProposal counterOfferSubmitted proposalId={proposalId} />
          )}
        </Box>
        {
          // PDF view
        }
        <div style={{ display: 'none' }}>
          <Box ref={componentRef}>
            <ProposalTitle clientView previewFlag values={values?.lead} />
            {values && (
              <ProposalGeneralInformation clientView values={values?.lead} />
            )}
            <Box
              sx={{
                borderBottom: `2px solid ${theme.palette.mainText[400]}`,
                marginBottom: theme.spacing(4),
                marginTop: theme.spacing(1),
                width: '100%'
              }}
            />
            {values && getServiceTimeSummary(true)}
            {values && pricingSummary()}
          </Box>
        </div>
      </Container>
    );
  };

ClientProposalView.defaultProps = {
  closeModal: () => {},
  proposalInfo: null
};

export default ClientProposalView;
