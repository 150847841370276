/* eslint-disable react/require-default-props */
import React, { useEffect, useRef, useState } from 'react';
import { Box, Typography, Collapse } from '@mui/material';
import CardContainerServices from './serviceTimes/components/CardContainerServices';
import CollapseBox from './CollapseBox';
import { Destination } from '../../utils/types/ProjectType';

interface Props {
  destinations: Destination[];
  detailProjectId: string;
  projectType: string;
}

const ProjectsTimeList: React.FC<Props> = function ProjectsTimeList({
  detailProjectId,
  projectType,
  destinations
}) {
  const [openDestination, setOpenDestination] = useState<{
    [destinationId: string]: boolean;
  }>({});
  const [openServiceId, setOpenServiceId] = useState<number | null>(null);
  const firstRender = useRef(true);

  useEffect(() => {
    if (firstRender.current) {
      firstRender.current = false;
      return;
    }
    if (destinations?.length > 0) {
      const firstDestinationId = String(destinations[0].id);
      setOpenDestination({ [firstDestinationId]: true });
    }
    if (
      destinations?.length > 0 &&
      destinations[0].serviceTimes[0].services.length > 0
    ) {
      setOpenServiceId(destinations[0].serviceTimes[0].id);
    }
  }, [destinations]);

  // Ensure only one collapse is open at a time
  const toggleDestination = (destinationId: string) => {
    setOpenDestination((prev) => {
      const isCurrentlyOpen = !!prev[destinationId];
      return { [destinationId]: !isCurrentlyOpen }; // Close others, open new one
    });
  };

  const toggleService = (serviceId: number) => {
    setOpenServiceId((prevId) => (prevId === serviceId ? null : serviceId));
  };

  const headerServiceTme = () => {
    return (
      <Typography variant="primary-bold">
        {' '}
        {projectType === 'Regular'
          ? `${'Service Setup'} - ${'Regular Time'}`
          : `${'Service Setup'} ${'Ad-Hoc time'}`}
      </Typography>
    );
  };

  return (
    <Box display="flex" flexDirection="column" gap={2}>
      <Box display="flex" flexDirection="column">
        {headerServiceTme()}
      </Box>
      <Box display="flex" flexDirection="column" gap={2}>
        {destinations.map((destination: Destination) => (
          <CollapseBox
            destination={destination}
            isCollapseOpen={openDestination[destination.id]}
            key={destination.id}
            projectSetupTab="serviceSetup"
            toggleDestination={() => toggleDestination(String(destination.id))}
          >
            <Collapse
              unmountOnExit
              in={!!openDestination[String(destination.id)]}
              timeout="auto"
            >
              <Box
                display="flex"
                flexDirection="column"
                gap={2}
                marginTop={2.5}
              >
                {destination &&
                  destination.serviceTimes?.length > 0 &&
                  destination.serviceTimes.map((serviceTime, index: number) => (
                    <CardContainerServices
                      count={`${index + 1}`}
                      data={serviceTime}
                      dayChecked=""
                      detailProjectId={detailProjectId}
                      isOpen={openServiceId === serviceTime.id}
                      key={serviceTime.id}
                      projectType={projectType}
                      setIsOpen={() => toggleService(serviceTime.id)}
                      type="serviceSetup"
                      typeView=""
                      utcUpadtedAt=""
                    />
                  ))}
              </Box>
            </Collapse>
          </CollapseBox>
        ))}
      </Box>
    </Box>
  );
};

export default ProjectsTimeList;
