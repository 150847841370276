import {
  CompanyApi,
  UpdateCompanyDTO,
  UpdateCurrentCompanyDTO
} from '../api-client/generated/api';

const apiCaller = new CompanyApi();

const getCurrentCompany = async () => {
  const { data } = await apiCaller.companyGetCurrentCompany();
  return data;
};

const updateCurrentCompany = async (
  url: string,
  params: UpdateCurrentCompanyDTO
) => {
  if (!params) {
    return null;
  }
  const { taxes: t } = params;
  // we build tax custom structure
  const taxes = t?.map((tax: any) => {
    return {
      id: tax?.id,
      name: tax?.name,
      tax: tax?.tax,
      type: tax?.type
    };
  });
  const { status, statusText } = await apiCaller.companyUpdateCurrentCompany({
    updateCurrentCompanyDTO: {
      ...params,
      taxes
    }
  });

  return { status, statusText };
};

const deleteTax = async (id: string) => {
  const { data } = await apiCaller.companyDeleteTaxById({ id });
  return data;
};

const getCompanies = async () => {
  const response = await apiCaller.companyGetAll();
  return response.data;
};

const companyArchiveCompany = async (params: any) => {
  const { id } = params;
  const response = await apiCaller.companyArchiveCompany({ id });
  return { ...response.data, status: response.status };
};

const companyUnarchiveCompany = async (params: any) => {
  const { id } = params;
  const response = await apiCaller.companyUnarchiveCompany({ id });
  return { ...response.data, status: response.status };
};

const updateCompanyById = async (id: string, params: UpdateCompanyDTO) => {
  if (!params) {
    return null;
  }
  const { widgetsAllowed, plan } = params;
  const { data } = await apiCaller.companyUpdateOneById({
    id,
    updateCompanyDTO: { plan, widgetsAllowed }
  });

  return { data };
};

export default {
  companyArchiveCompany,
  companyUnarchiveCompany,
  deleteTax,
  getCompanies,
  getCurrentCompany,
  updateCompanyById,
  updateCurrentCompany
};
