import { Box, Divider, Typography, styled, useTheme } from '@mui/material';
import dayjs from 'dayjs';
import React from 'react';
import { isMobile } from 'react-device-detect';
import { useTranslation } from 'react-i18next';
import { secondaryColors } from '../../theme/themeConfig';

const GeneralInfoContainer = styled(Box)<{ clientView?: boolean }>(
  ({ theme, clientView }) => ({
    border: `1px solid ${theme.palette.divider}`,
    borderRadius: theme.spacing(1),
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(3),
    // marginBottom: clientView ? 0 : theme.spacing(4),
    // marginTop: clientView ? 0 : theme.spacing(4),
    padding: theme.spacing(3)
  })
);

interface Props {
  clientView?: boolean;
  values: any;
}
interface InfoRowProps {
  label: string;
  value: string;
}

function InfoRow({ label, value }: InfoRowProps) {
  return (
    <>
      <Typography color="primary" variant="heading_300">
        {label}
      </Typography>
      <Typography
        color="secondary.main"
        textAlign={isMobile ? 'right' : 'left'}
        variant="body_300"
      >
        {value}
      </Typography>
    </>
  );
}

const ProposalGeneralInformation: React.FC<Props> =
  function ProposalGeneralInformation({ values, clientView }) {
    const { t } = useTranslation();
    const theme = useTheme();

    const firstColumnWidth = isMobile ? '40%' : '25%';

    const contractDuration = () => {
      const startDate = values?.startDate
        ? dayjs(values.startDate).format('MM/DD/YYYY')
        : t('LEAD.CREATE.DESTINATIONS.null');
      const endDate = values?.endDate
        ? dayjs(values.endDate).format('MM/DD/YYYY')
        : t('LEAD.CREATE.DESTINATIONS.null');
      return `${startDate} - ${endDate}`;
    };

    return (
      <GeneralInfoContainer clientView={clientView}>
        <Box
          display={isMobile ? 'flex' : 'grid'}
          gridTemplateColumns={`${firstColumnWidth} 1fr`}
          sx={{ gridColumnGap: '4px', gridRowGap: '32px' }}
        >
          <Typography variant={clientView ? 'heading_400' : 'secondary-semi'}>
            {t('LEAD.CREATE.PROPOSAL.generalInfoTitle')}
          </Typography>
          {!isMobile && (
            <Typography color="secondary.main" variant="body_300">
              {t('LEAD.CREATE.PROPOSAL.generalInfoDuration')}{' '}
              {contractDuration()}
            </Typography>
          )}
        </Box>

        <Divider flexItem sx={{ borderColor: secondaryColors.SECONDARY_1 }} />

        <Box
          display="grid"
          gridTemplateColumns={`${firstColumnWidth} 1fr`}
          sx={{ gridColumnGap: '4px', gridRowGap: '32px' }}
        >
          <Typography color="primary" variant="heading_300">
            {t('LEAD.CREATE.PROPOSAL.generalInfoClient')}
          </Typography>
          <Box
            display="flex"
            flexDirection={isMobile ? 'column' : 'row'}
            gap={isMobile ? 1 : 3}
            textAlign={isMobile ? 'right' : 'left'}
          >
            <Typography color="secondary.main" variant="body_300">
              {values?.client?.firstName || values?.client?.lastName
                ? `${values.client.firstName ?? ''} ${
                    values.client.lastName ?? ''
                  }`.trim()
                : t('LEAD.CREATE.DESTINATIONS.null')}
            </Typography>
          </Box>

          <InfoRow
            label={t('LEAD.CREATE.PROPOSAL.generalInfoProjectName')}
            value={values?.name ?? t('LEAD.CREATE.DESTINATIONS.null')}
          />

          {isMobile && (
            <InfoRow
              label={t('LEAD.CREATE.PROPOSAL.generalInfoDuration')}
              value={contractDuration()}
            />
          )}

          {clientView && (
            <InfoRow
              label={t('LEAD.CREATE.PROPOSAL.generalInfoDescription')}
              value={values?.description ?? t('LEAD.CREATE.DESTINATIONS.null')}
            />
          )}
        </Box>
      </GeneralInfoContainer>
    );
  };

ProposalGeneralInformation.defaultProps = {
  clientView: false
};

export default ProposalGeneralInformation;
