import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Divider,
  Stack,
  Typography
} from '@mui/material';
import ExpandMoreRoundedIcon from '@mui/icons-material/ExpandMoreRounded';
import styled from '@emotion/styled';
import { t } from 'i18next';
import { surfaceColors, themePalette } from '../../theme/themeConfig';
import { statusStyle } from '../../utils/general';
import ShowInformationIndicator from '../modals/ShowInformationIndicator';

interface LeadSummaryRowProps {
  title: string;
  value: number | string;
}

const styles = {
  rateContainer: {
    border: '1px solid #DDDDDD',
    borderRadius: '8px',
    boxShadow: 'none'
  },
  rateTitleContainer: {
    backgroundColor: surfaceColors.SURFACE_1,
    boxShadow: 'none'
  },
  rowsContainer: {
    display: 'grid',
    gap: '16px'
  }
};

const DividerContainer = styled(Box)(() => ({
  backgroundColor: themePalette.BACKGROUND
}));

const LeadSummaryRowTitle = styled(Stack)(() => ({
  alignItems: 'center',
  justifyContent: 'space-between',
  marginRight: '8px',
  width: '100%'
}));

function LeadSummaryRow({ title, value }: LeadSummaryRowProps) {
  return (
    <Stack direction="row" justifyContent="space-between">
      <Typography fontWeight="500" variant="body_300">
        {title}
      </Typography>
      <Typography color="gray" fontWeight="500" variant="body_300">
        {value}
      </Typography>
    </Stack>
  );
}

type UserType = {
  email: string;
  firstName: string;
  id: string;
  lastName: string;
  phoneNumber: string;
};

interface ShowInformationIndicatorData {
  closedBy?: string;
  closer?: UserType;
  startedBy?: string;
  starter?: UserType;
  technician?: UserType;
}

interface WorkedHours {
  actualEnd: string;
  actualStart: string;
  scheduled: string;
  variance: string;
}

interface LeadSummaryProps {
  leadName: string;
  showInformationIndicatorData: ShowInformationIndicatorData;
  status: string;
  subtitle: string;
  workedHours: WorkedHours;
}

function LeadSummary({
  leadName,
  showInformationIndicatorData,
  status,
  subtitle,
  workedHours
}: LeadSummaryProps) {
  const { actualEnd, actualStart, scheduled, variance } = workedHours;

  const rows = [
    {
      title: t('TIMESHEET.scheduled'),
      value: scheduled
    },
    {
      title: t('TIMESHEET.actualStart'),
      value: actualStart
    },
    {
      title: t('TIMESHEET.actualEnd'),
      value: actualEnd
    },
    {
      title: t('TIMESHEET.variance'),
      value: variance
    }
  ];

  return (
    <Accordion>
      <AccordionSummary
        expandIcon={<ExpandMoreRoundedIcon />}
        sx={styles.rateTitleContainer}
      >
        <LeadSummaryRowTitle direction="row">
          <Stack direction="column" spacing="0px">
            <Typography
              color="mainText.main"
              fontWeight="500"
              variant="body_300"
            >
              {leadName}
            </Typography>
            <Typography color="mainText.300" variant="body_200">
              {subtitle}
            </Typography>
          </Stack>

          <Typography
            sx={{
              ...statusStyle(status),
              borderRadius: '8px',
              padding: '8px',
              textTransform: 'capitalize'
            }}
            variant="body_200"
          >
            {status}
          </Typography>
          <ShowInformationIndicator
            technicians={[showInformationIndicatorData]}
          />
        </LeadSummaryRowTitle>
      </AccordionSummary>
      <DividerContainer paddingX="16px">
        <Divider />
      </DividerContainer>
      <AccordionDetails sx={{ backgroundColor: surfaceColors.SURFACE_1 }}>
        <Box sx={styles.rowsContainer}>
          {rows.map((row) => (
            <LeadSummaryRow
              key={row.title}
              title={row.title}
              value={row.value}
            />
          ))}
        </Box>
      </AccordionDetails>
    </Accordion>
  );
}

export default LeadSummary;
