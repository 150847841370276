/* eslint-disable react/require-default-props */
import { Box, Button, Typography, useTheme } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import useSWR, { useSWRConfig } from 'swr';
import AssignSupervisor from '../components/AssignSupervisor';
import Banner from '../../../../components/shared/Banner';
import api from '../../../../api';
import useNotificationsStore from '../../../../store/zustand/notifications';

interface Props {
  mutate: () => void;
  saveStepper: ({ next }: any) => void;
  setStep: React.Dispatch<React.SetStateAction<number>>;
  setValues: React.Dispatch<React.SetStateAction<any>>;
  values: any;
}
const Supervision: React.FC<Props> = function Supervision({
  values: lead,
  setValues: setLead,
  saveStepper,
  setStep,
  mutate
}) {
  const { t } = useTranslation();
  const theme = useTheme();
  const { addNotification } = useNotificationsStore((state) => state);
  const [values, setValues] = useState<any>(lead);
  const [removeApi, setRemoveApi] = useState<boolean>(false);
  const [id, setId] = useState<string>();

  const { data: removeSupervisorData, error: removeSupervisorError } = useSWR(
    removeApi ? { id } : null,
    api.leadsApi.leadApi.deleteSupervisor
  );

  const errorValidation = () => {
    if (values?.supervisorEnabled) {
      if (
        !values?.supervisor ||
        !values?.supervisorFrequency ||
        !values?.supervisorTime
      ) {
        return true;
      }
    } else if (!values?.supervisorEnabled && lead?.supervisor) {
      return false;
    }
    return false;
  };

  const continueClick = () => {
    saveStepper({ next: 4 });
  };

  useEffect(() => {
    if (removeSupervisorData) {
      addNotification(
        t('NOTIFICATION.TOAST.deleteSuccess', [t('ROLES.Supervisor')]),
        'success'
      );
      setRemoveApi(false);
      mutate();
      continueClick();
    }
  }, [removeSupervisorData]);

  useEffect(() => {
    if (removeSupervisorError) {
      setRemoveApi(false);
    }
  }, [removeSupervisorError]);

  useEffect(() => {
    if (!values?.supervisor && lead?.supervisor) {
      setId(values?.id);
    }
  }, [values]);

  useEffect(() => {
    if (lead?.supervisor) {
      setValues({ ...lead, supervisorEnabled: true });
    }
    return () => {};
  }, []);

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 4 }}>
      <Box display="flex" flexDirection="column" gap={2}>
        <Typography color="primary.main" variant="heading_500">
          {t('LEAD.CREATE.SUPERVISION.title')}
        </Typography>
        <Typography color="secondary.400" variant="body_200">
          {t('LEAD.CREATE.SUPERVISION.description')}
        </Typography>
      </Box>
      <AssignSupervisor
        saveStepper={() => {}}
        setStep={() => {}}
        setValues={setValues}
        values={values}
      />
      <Box alignItems="center" display="flex" gap="16px" width="100%">
        {errorValidation() && (
          <Banner
            icon
            text={t('LEAD.CREATE.SUPERVISION.warning')}
            textCustomStyle={{ fontSize: '1rem', fontWeight: '400' }}
            type="warning"
          />
        )}
        <Box sx={{ ml: 'auto' }}>
          <Button
            disabled={errorValidation() || values?.readOnly}
            onClick={() => {
              if (values?.supervisorEnabled) {
                setLead({ ...values });
                addNotification(
                  t('NOTIFICATION.TOAST.addedSuccess', [t('ROLES.Supervisor')]),
                  'success'
                );
                continueClick();
              } else if (lead?.supervisor) {
                setRemoveApi(true);
              } else {
                continueClick();
              }
            }}
            sx={{ width: 300 }}
            variant="primary"
          >
            <Typography variant="primary">
              {t('LEAD.CREATE.SUPERVISION.continueButton')}
            </Typography>
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

export default Supervision;
