/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable camelcase */
/* eslint-disable import/prefer-default-export */
/* eslint-disable no-restricted-syntax */
/* eslint-disable eqeqeq */
/* eslint-disable dot-notation */
/* eslint-disable no-unsafe-optional-chaining */
/* eslint-disable operator-assignment */
/* eslint-disable prefer-const */
/* eslint-disable object-shorthand */
/* eslint-disable prefer-template */
/* eslint-disable no-loop-func */

import {
  calculateMaterialCostFromTask,
  getCalculateLaborTaxes,
  getTimeCost,
  getTotalHoursByInterval,
  getTravelCost
} from './calculatorHelper';

export function evaluateFormula(math: any) {
  try {
    // eslint-disable-next-line no-eval
    const result = eval(math);
    return Number.isNaN(result) ? 0 : result;
  } catch (error) {
    return 'Error en la fórmula';
  }
}

const staticCostsPercent = ['OHD'];
const staticCostsNumber = ['UBH', 'PPE', 'EDY', 'LRM'];

export const getCalculatorResults = (lead: any) => {
  const startDate = new Date(lead.projectStartDate);
  const endDate = new Date(lead.projectEndDate);
  const projectDurationInMonths =
    (endDate.getFullYear() - startDate.getFullYear()) * 12 +
      (endDate.getMonth() - startDate.getMonth()) +
      (endDate.getDate() >= startDate.getDate() ? 0 : -1) || 1;
  const staticCosts = lead?.staticCosts.filter((sc: any) => sc.code !== 'OHD');
  const overhead =
    lead?.staticCosts.filter((sc: any) => sc.code === 'OHD')?.[0] || {};

  // ! PREPARE VALUES
  const PROPOSAL_VALUES = {
    Total_Hrs: 0,
    Weekly_Hrs: 40,
    equipmentCosts: 0,
    supplies: 0
  };

  const SUPERVISOR_VALUES = {
    available: !!lead?.supervisor,
    frequency: lead?.supervisorFrequency,
    supervisor: lead?.supervisor,
    time: lead?.supervisorTime
  };

  const { taxes } = lead?.company || {};

  const totalLaborBurden = getCalculateLaborTaxes(taxes);

  // const configStaticValues = taxes.filter((tax: any) => tax?.type == 'static');
  const configStaticValuesMap: any = {};

  const config = {
    Equipment_Depreciation_Years:
      configStaticValuesMap['Equipment_Depreciation_Years'] || 0,
    Labor_Rate_Min: configStaticValuesMap['Labor_Rate_Min'] || 0,
    Payroll_Processing_Per_Employee:
      configStaticValuesMap['Payroll_Processing_Per_Employee'] || 0,
    Supervision_Hours_per_20_Hrs:
      configStaticValuesMap['Supervision_Hours_per_20_Hrs'] || 0,
    Total_Labor_Burden: totalLaborBurden
  };

  let employeesNeeded = 0;
  let employeesLaborRate = 0;
  let employeesCost = 0;
  let travelCost = 0;
  let setupCost = 0;
  let teardownCost = 0;
  let materialsCost = 0;
  let supervisorTime = SUPERVISOR_VALUES.available
    ? getTotalHoursByInterval(
        SUPERVISOR_VALUES.time,
        SUPERVISOR_VALUES.frequency,
        lead.projectStartDate,
        lead.projectEndDate
      )
    : 0;
  let totalMonth = getTotalHoursByInterval(
    1,
    'Monthly',
    lead.projectStartDate,
    lead.projectEndDate
  );
  let staticCostsMonthly = {
    total: 0,
    values: staticCosts?.filter((item: any) => item.periodType === 'Monthly')
  };
  let staticCostsProject = {
    total: 0,
    values: staticCosts?.filter((item: any) => item.periodType === 'PerProject')
  };
  let staticCostsVisit = {
    total: 0,
    values: staticCosts?.filter((item: any) => item.periodType === 'PerVisit')
  };
  let totalVisitsInProject = 0;
  let costPerVisit = [];

  for (const destination of lead.destinations) {
    for (const serviceTime of destination.serviceTimes) {
      const visits = serviceTime?.availability?.generalInfo?.daysOfServiceCount;
      totalVisitsInProject += visits;
      staticCostsVisit?.values?.forEach((sc: any) => {
        staticCostsVisit.total += Number(sc?.value || 0) * visits;
      });
      const singleTravelCost =
        getTravelCost(serviceTime, serviceTime?.travelTime) *
        (1 + config.Total_Labor_Burden / 100);

      travelCost += singleTravelCost * visits;
      PROPOSAL_VALUES.Total_Hrs +=
        serviceTime?.availability?.generalInfo?.serviceTimeDaysOfService
          ?.length *
        serviceTime?.availability?.generalInfo?.serviceTimeHoursPerDay;
      const singleSetupCost =
        getTimeCost([], serviceTime?.services, 'setup') *
        (1 + config.Total_Labor_Burden / 100);
      setupCost += singleSetupCost * visits;
      const singleTeardownCost =
        getTimeCost([], serviceTime?.services, 'teardown') *
        (1 + config.Total_Labor_Burden / 100);
      teardownCost += singleTeardownCost * visits;
      let singleMaterialsCost = 0;
      let singleEmployeesCost = 0;
      for (const service of serviceTime.services) {
        for (const task of service.tasks) {
          singleEmployeesCost += task?.technicians?.reduce(
            (acc: any, value: any) =>
              acc +
              Number(value.rate) *
                task?.totalTime *
                (1 + config.Total_Labor_Burden / 100),
            0
          );
          employeesNeeded += task.resourcesAssigned;
          employeesLaborRate += Number(task.mediaRate);
          singleMaterialsCost += task?.materials?.reduce(
            (acc: any, value: any) =>
              acc +
              evaluateFormula(
                value?.cost * task?.totalTime * task.resourcesAssigned
              ),
            0
          );
        }
      }
      employeesCost +=
        singleEmployeesCost *
        serviceTime?.availability?.generalInfo?.daysOfServiceCount;
      materialsCost +=
        singleMaterialsCost *
        serviceTime?.availability?.generalInfo?.daysOfServiceCount;
      costPerVisit.push({
        cost: evaluateFormula(
          singleEmployeesCost +
            singleMaterialsCost +
            singleSetupCost +
            singleTeardownCost +
            singleTravelCost
        ),
        id: serviceTime?.id,
        visits
      });
    }
  }

  if (staticCostsMonthly.values.length) {
    staticCostsMonthly.values.forEach((sc: any) => {
      staticCostsMonthly.total += Number(sc?.value || 0) * totalMonth;
    });
  }

  if (staticCostsProject.values.length) {
    staticCostsProject.values.forEach((sc: any) => {
      staticCostsProject.total += Number(sc?.value || 0);
    });
  }

  employeesLaborRate = employeesLaborRate / employeesNeeded;
  // TODO : check if this works for all the cases
  const adjustments = {
    'Enter_#_of_Employees_Needed': employeesNeeded,
    Enter_Employee_Labor_Rate: employeesLaborRate,
    Enter_Gross_Profit_Margin: lead.profit,
    Enter_Supervision_Rate: SUPERVISOR_VALUES?.supervisor?.rate,
    Overhead: overhead.value || 0,
    Specialty_Equipment: 0,
    Suggested_Weekly_Supervision_Hours:
      (PROPOSAL_VALUES.Weekly_Hrs / 20) * config.Supervision_Hours_per_20_Hrs,
    Uniforms_Badges_Hiring:
      configStaticValuesMap['Uniforms_Badges_Hiring'] || 0,
    Weekly_Supervision_Hours_Assigned: supervisorTime,
    'Weeks_if_Non-Standard_52_Weeks': projectDurationInMonths * 4
  };

  PROPOSAL_VALUES.Total_Hrs =
    Number(PROPOSAL_VALUES.Total_Hrs.toFixed(0)) *
    adjustments['Weeks_if_Non-Standard_52_Weeks'];

  PROPOSAL_VALUES.equipmentCosts = 0;
  PROPOSAL_VALUES.supplies = 0;

  // ! FORMULAS CALCULATIONS

  const Equipment_Costs = evaluateFormula(
    PROPOSAL_VALUES.equipmentCosts *
      adjustments['Enter_#_of_Employees_Needed'] +
      adjustments.Specialty_Equipment / config.Equipment_Depreciation_Years
  );

  const Supplies = evaluateFormula(materialsCost);

  const Uniforms_Badges_Hiring = evaluateFormula(
    adjustments['Uniforms_Badges_Hiring'] *
      adjustments['Enter_#_of_Employees_Needed']
  );

  const Payroll_Expense = evaluateFormula(
    adjustments['Enter_#_of_Employees_Needed'] *
      config.Payroll_Processing_Per_Employee *
      projectDurationInMonths
  );

  const Supervision_Cost = evaluateFormula(
    supervisorTime *
      SUPERVISOR_VALUES.supervisor?.rate *
      (1 + config.Total_Labor_Burden / 100)
  );

  const Direct_Labor_Cost = evaluateFormula(employeesCost);
  let Total_Costs = Number(
    Equipment_Costs +
      Supplies +
      Uniforms_Badges_Hiring +
      Payroll_Expense +
      Supervision_Cost +
      Direct_Labor_Cost +
      travelCost +
      setupCost +
      teardownCost +
      staticCostsMonthly.total +
      staticCostsVisit.total +
      staticCostsProject.total
  );
  const logisticCost = travelCost + setupCost + teardownCost;
  const overheadCost = Total_Costs * (overhead?.value / 100);
  Total_Costs += overheadCost;

  const configurationCosts =
    staticCostsMonthly.total +
    staticCostsVisit.total +
    staticCostsProject.total +
    overheadCost;

  let Annual_Price =
    Total_Costs * (1 + adjustments.Enter_Gross_Profit_Margin / 100);

  let Annual_Gross_Profit = Annual_Price - Total_Costs;

  let Annual_Net_Profit =
    Annual_Gross_Profit - (Annual_Price * adjustments.Overhead) / 100;

  // if (lead.projectType === 'Ad-Hoc') {
  //   Total_Costs = Math.abs(
  //     leadCalculationsParamsDTO.totalProjectPrice -
  //       leadCalculationsParamsDTO.profit
  //   );
  //   Annual_Price = leadCalculationsParamsDTO.profit;
  //   Annual_Gross_Profit = leadCalculationsParamsDTO.totalProjectPrice;
  //   Annual_Net_Profit = lead.profitPercentage;
  // }

  // const Price_Per_Month = Annual_Price / projectDurationInMonths;
  const Price_Per_Month = Annual_Gross_Profit / projectDurationInMonths;

  const Yearly_Billing = Price_Per_Month * projectDurationInMonths;
  const Direct_Labor = Direct_Labor_Cost / Yearly_Billing;
  const Superivision = Supervision_Cost / Yearly_Billing;
  const Equipment_And_Supplies = (Equipment_Costs + Supplies) / Yearly_Billing;
  const Payroll_Expense_Percentage = Payroll_Expense / Annual_Price;
  const Uniforms_Badges_Hiring_Percentage =
    Uniforms_Badges_Hiring / Annual_Price;
  const Overhead_15_Percent = adjustments.Overhead;
  const Mark_Up_Percent = Annual_Gross_Profit / Total_Costs;
  const Spotless_Hourly_Rate_Not_Counting_Supervision_Hours =
    Annual_Price / PROPOSAL_VALUES.Total_Hrs;
  const Spotless_Hourly_Rate_With_Supervision_Hours =
    Annual_Price /
    (PROPOSAL_VALUES.Total_Hrs +
      adjustments.Weekly_Supervision_Hours_Assigned *
        adjustments['Weeks_if_Non-Standard_52_Weeks']);
  const values = {
    adjustments,
    analytics: {
      Direct_Labor,
      'Equipment_&_Supplies': Equipment_And_Supplies,
      'Gross_Margin_@_Account': lead?.profit,
      'Mark_Up_%': Mark_Up_Percent,
      'Overhead_15%': Overhead_15_Percent,
      Payroll_Expense: Payroll_Expense_Percentage,
      Spotless_Hourly_Rate_Not_Counting_Supervision_Hours,
      'Spotless_Hourly_Rate_w/_Supervision_Hours':
        Spotless_Hourly_Rate_With_Supervision_Hours,
      Superivision,
      Uniforms_Badges_Hiring: Uniforms_Badges_Hiring_Percentage,
      Yearly_Billing
    },
    config,
    pricing: {
      Annual_Gross_Profit,
      Annual_Net_Profit,
      Annual_Price,
      Direct_Labor_Cost,
      Equipment_Costs,
      Payroll_Expense,
      Price_Per_Month,
      Supervision_Cost,
      Supplies,
      Total_Costs,
      Uniforms_Badges_Hiring
    }
  };

  // !! FORMATTING NUMERIC VALUES
  const formatNumericValues = (obj: any) => {
    for (const key in obj) {
      if (typeof obj[key] === 'number') {
        obj[key] = obj[key].toFixed(2);
      } else if (typeof obj[key] === 'object' && obj[key] !== null) {
        formatNumericValues(obj[key]);
      }
    }
  };

  // projectValuesByDuration(values.pricing);
  // projectValuesByDuration(values.analytics);
  formatNumericValues(values.pricing);

  const profitPercent = (
    ((values.pricing['Annual_Price'] - values.pricing['Total_Costs']) /
      values.pricing['Annual_Price']) *
    100
  ).toFixed(2);

  costPerVisit = costPerVisit.map((visit: any) => {
    const { cost: singleCost } = visit;
    const singleSupervisorCost = Supervision_Cost / totalVisitsInProject;
    const cost =
      (singleCost +
        singleSupervisorCost +
        (staticCostsMonthly.total +
          staticCostsProject.total +
          staticCostsVisit.total) /
          totalVisitsInProject) *
      (1 + overhead?.value / 100);
    return {
      ...visit,
      cost
    };
  });

  return {
    analytics: {
      directLabor: (values.analytics['Direct_Labor'] * 100).toFixed(2) + '%',
      equipmentAndSupplies:
        (values.analytics['Equipment_&_Supplies'] * 100).toFixed(2) + '%',
      grossMarginAtAccount:
        Number(values.analytics['Gross_Margin_@_Account'] || 0).toFixed(2) +
        '%',
      markUpPercent: (values.analytics['Mark_Up_%'] * 100).toFixed(2) + '%',
      overheadPercent: values.analytics['Overhead_15%'] + '%',
      payrollExpense:
        (values.analytics['Payroll_Expense'] * 100).toFixed(2) + '%',
      supervision: (values.analytics['Superivision'] * 100).toFixed(2) + '%',
      uniformsBadgesHiring:
        (values.analytics['Uniforms_Badges_Hiring'] * 100).toFixed(2) + '%',
      yearlyBilling: values.analytics['Yearly_Billing'].toFixed(2)
    },
    costPerVisit,
    information: {
      employeesLaborRate: employeesLaborRate,
      employeesNeeded: employeesNeeded,
      supervisorTime: supervisorTime,
      totalSupervisorTime: supervisorTime
    },
    pricing: {
      annualGrossProfit: values.pricing['Annual_Gross_Profit'],
      annualNetProfit: values.pricing['Annual_Net_Profit'],
      annualPrice: values.pricing['Annual_Price'],
      configurationCosts,
      directLaborCost: values.pricing['Direct_Labor_Cost'],
      equipmentCosts: values.pricing['Equipment_Costs'],
      logisticCost,
      payrollExpense: values.pricing['Payroll_Expense'],
      pricePerMonth: values.pricing['Price_Per_Month'],
      profitPercent: profitPercent,
      supervisionCost: values.pricing['Supervision_Cost'],
      supplies: values.pricing['Supplies'],
      totalCosts: values.pricing['Total_Costs'],
      uniformsBadgesHiring: values.pricing['Uniforms_Badges_Hiring']
    }
  };
};
