/* eslint-disable no-nested-ternary */
import React from 'react';
import { Box, BoxProps, Typography } from '@mui/material';
import { styled, useTheme } from '@mui/material/styles';
import { getCurrency } from '../../utils/general';

const BoxContainer = styled(Box)<BoxProps>(({ theme }) => ({
  backgroundColor: theme.palette.background.default,
  borderRadius: theme.spacing(1),
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(2),
  padding: theme.spacing(2)
}));

interface CardWithChildrenProps {
  children: [React.ReactElement, React.ReactElement];
  description: string | undefined;
  isEditActive: boolean;
  labelType: string | undefined;
  showInTextFormat: boolean;
  value: any;
}

function CardWithChildren({
  children,
  description,
  isEditActive,
  labelType,
  showInTextFormat,
  value
}: CardWithChildrenProps) {
  const theme = useTheme();
  const childrenArray = React.Children.toArray(children);
  const labelChild = childrenArray[0];
  const inputChild = childrenArray[1];

  const getValue = (value: any) => {
    switch (labelType) {
      case 'Currency':
        return getCurrency(value);
      case 'Counter':
        return `${value}`;
      case 'Percentage':
        return `${value}%`;
      default:
        return value;
    }
  };

  const renderedInput =
    showInTextFormat && !isEditActive ? (
      <Typography color={theme.palette.secondary[500]} variant="secondary-semi">
        {`${getValue(value)}`}
      </Typography>
    ) : (
      inputChild
    );

  return (
    <BoxContainer>
      <Box display="flex" flexDirection="column" gap={2}>
        {labelChild}
        {description && (
          <Typography color={theme.palette.secondary[400]} variant="body_300">
            {description}
          </Typography>
        )}
      </Box>
      <Box>{renderedInput}</Box>
    </BoxContainer>
  );
}

export default CardWithChildren;
