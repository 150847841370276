/* eslint-disable react/jsx-props-no-spreading */
import {
  Backdrop,
  Box,
  Modal,
  Tooltip,
  Typography,
  useTheme
} from '@mui/material';
import { useCallback, useEffect, useMemo, useState } from 'react';
import useSWR from 'swr';
import moment from 'moment';
import Timeline, {
  DateHeader,
  SidebarHeader,
  TimelineHeaders
} from 'react-calendar-timeline';
import { useTranslation } from 'react-i18next';
import { ExtensionOffRounded, ExtensionRounded } from '@mui/icons-material';
import {
  DrawerButton,
  ModalContainer
} from '../../components/modals/GlobalConfirmModal';
import api from '../../api';
import {
  alertColors,
  mainColors,
  secondaryColors,
  successColors
} from '../../theme/themeConfig';
import RegularInput from '../../components/input/RegularInput';
import { FormLabel } from '../../components/form/parts';
import Loader from '../../components/shared/Loader';
import { getColorByPercent, getCurrency } from '../../utils/general';

const keys = {
  groupIdKey: 'id',
  groupLabelKey: 'title',
  groupRightTitleKey: 'rightTitle',
  groupTitleKey: 'title',
  itemDivTitleKey: 'title',
  itemGroupKey: 'group',
  itemIdKey: 'id',
  itemTimeEndKey: 'end_time',
  itemTimeStartKey: 'start_time',
  itemTitleKey: 'title'
};

interface Props {
  datesList: any[];
  destinationId: number;
  refresh: (data: { groups: any[]; items: any[] }) => void;
  setGetAssignments: React.Dispatch<React.SetStateAction<boolean>>;
  setOpenDetail: React.Dispatch<React.SetStateAction<boolean>>;
  setParentGroups: React.Dispatch<React.SetStateAction<any[]>>;
  setParentItems: React.Dispatch<React.SetStateAction<any[]>>;
  taskData: any;
}

const AssignmentsTask: React.FC<Props> = function AssignmentsTask({
  datesList,
  destinationId,
  refresh,
  setParentItems,
  setParentGroups,
  setOpenDetail,
  taskData
}: Props) {
  const [fireAssignResources, setFireAssignResources] =
    useState<boolean>(false);
  const [auxManualClose, setAuxManualClose] = useState<boolean>(true);
  const [getResources, setGetResources] = useState<boolean>(false);
  const [groups, setGroups] = useState<any[]>([]);
  const [items, setItems] = useState<any[]>([]);
  const [reason, setReason] = useState<string>('');
  const [noResources, setNoResources] = useState<string>('');
  const { t } = useTranslation();
  const theme = useTheme();

  const auxDatesList = datesList.sort((a: any, b: any) =>
    a.start_time
      .format('YYYY-MM-DD')
      .localeCompare(b.start_time.format('YYYY-MM-DD'))
  );

  const [defaultTimeStart] = useState(
    moment(auxDatesList[0]?.start_time)?.startOf('month').toDate()
  );

  const [defaultTimeEnd] = useState(
    moment(auxDatesList[0]?.start_time)?.endOf('month').toDate()
  );

  const minTime = moment(auxDatesList[0]?.start_time)
    ?.startOf('month')
    .valueOf();

  const maxTime = moment(auxDatesList[auxDatesList.length - 1]?.end_time)
    .endOf('month')
    .valueOf();

  const {
    data: resourcesData,
    error: resourcesDataError,
    isValidating: isValidatingResources
  } = useSWR(
    getResources
      ? { itemsList: datesList, projectId: taskData?.projectId }
      : null,
    api.ProjectApi.getResources
  );

  const {
    data: assignResourcesData,
    error: assignResourcesDataError,
    isValidating: isValidatingAssignment
  } = useSWR(
    fireAssignResources
      ? {
          datesList: items.filter(
            (item: any) =>
              item.available &&
              item.group ===
                groups.find((groupInfo: any) => groupInfo.selected)?.id
          ),
          destinationId,
          projectId: taskData?.projectId,
          reason,
          serviceTaskId: taskData.taskId,
          technicianId: groups.find((groupInfo: any) => groupInfo.selected)?.id
        }
      : null,
    api.ProjectApi.assignResources
  );

  const handleClose = useCallback(() => {
    setOpenDetail(false);
    setAuxManualClose(false);
  }, [setOpenDetail]);

  useEffect(() => {
    if (resourcesData) {
      if (
        resourcesData === null ||
        resourcesDataError ||
        resourcesData?.items.length === 0
      ) {
        setGetResources(false);
        setNoResources(`${t('GENERAL.noResources')}`);
      } else {
        setGetResources(false);
        setNoResources('');
        const { groups, items } = resourcesData;
        setGroups(groups);
        setItems(items);
      }
    }
  }, [resourcesData]);

  useEffect(() => {
    if (assignResourcesData) {
      setFireAssignResources(false);
      const { groups: parentGroups, items: parentItems } = assignResourcesData;
      setParentGroups(parentGroups);
      setParentItems(parentItems);
      refresh(assignResourcesData);
      setOpenDetail(false);
      setAuxManualClose(false);
    }
  }, [assignResourcesData]);

  useEffect(() => {
    setGetResources(true);
  }, []);

  const selectGroup = (group: any) => {
    const selectStatus = !group?.selected;

    const auxIndex = groups.findIndex(
      (groupData: any) => groupData.id === group.id
    );
    const auxGroups = groups;
    auxGroups[auxIndex].selected = !auxGroups[auxIndex].selected;

    setGroups(
      groups.map((groupData: any) => {
        return {
          ...groupData,
          selected: groupData.id === group.id ? selectStatus : false
        };
      })
    );
  };

  const itemRenderer = ({ item, getItemProps }: any) => {
    let backGround = `${alertColors.ALERT_1} !important`;
    let auxBorderColor = `${alertColors.ALERT} !important`;

    if (item?.available) {
      backGround = `${successColors.SUCCESS_LIGHT} !important`;
      auxBorderColor = `${successColors.SUCCESS_4} !important`;
    }

    if (item.selected) {
      backGround = `${successColors.SUCCESS_1} !important`;
    }

    const auxProps = getItemProps({
      style: {
        alignItems: 'top',
        background: backGround,
        borderRadius: '4px',
        boxShadow: 'rgba(0, 0, 0, 0.16) 0 0.3rem 0.6rem',
        cursor: 'default',
        display: 'flex',
        // height: 27px;
        marginTop: '1px'
      }
    });

    return (
      <Box
        {...auxProps}
        className="item-box-container-assignments double-item-assignments"
        sx={{
          backgroundColor: backGround,
          borderColor: auxBorderColor
        }}
        title={`${item.title}`}
      >
        <Box
          className="item-box-container-assignments-content"
          sx={{
            border: `1px solid ${auxBorderColor}`,
            display: 'flex',
            justifyContent: 'center',
            left: '0',
            overflow: 'hidden',
            padding: '0',
            position: 'sticky',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
            width: '100%'
          }}
        >
          <Typography fontSize={16} variant="body_200">
            {/* item.selected ? 'si' : 'no' */}
            {/* item?.initials */}
          </Typography>
        </Box>
      </Box>
    );
  };

  const generateNestedView = useCallback(
    (group: any) => {
      let backGroundColor = `${secondaryColors.SECONDARY_6} !important`;
      let auxBorderColor = `${secondaryColors.SECONDARY_6} !important`;

      let auxPaddingLeft = 1;

      if (group.level === 2) {
        auxPaddingLeft = 2;
        backGroundColor = `${mainColors.MAIN_1} !important`;
      }

      if (group.level === 3) {
        auxPaddingLeft = 2;
        backGroundColor = `${successColors.SUCCESS_1} !important`;
      }
      if (group.selected) {
        auxBorderColor = `${successColors.SUCCESS_4} !important`;
        backGroundColor = `${successColors.SUCCESS_1} !important`;
      }

      return (
        <Box
          className={group.level !== 0 ? 'group-service-assignments' : ''}
          onClick={() => {
            if (
              items.filter(
                (item: any) => item.group === group.id && item.available
              ).length > 0
            ) {
              selectGroup(group);
            }
          }}
          sx={{
            alignItems: 'center',
            backgroundColor: backGroundColor,
            border: `1px solid ${auxBorderColor}`,
            borderColor: auxBorderColor,
            cursor:
              items.filter(
                (item: any) => item.group === group.id && item.available
              ).length > 0
                ? 'pointer'
                : 'default',
            display: 'flex',
            height: '28px',
            justifyContent: 'space-between',
            paddingLeft: auxPaddingLeft,
            paddingRight: '1rem'
          }}
          title={`${group.title} Rate: $${group?.rate}`}
        >
          <Box
            sx={{ alignItems: 'center', display: 'flex', gap: 1, width: 200 }}
          >
            <Tooltip
              title={t('TOOLTIP.PROJECT.assignmentsPercent', [group?.percent])}
            >
              <span>
                {group?.percent === 0 && (
                  <ExtensionOffRounded
                    sx={{
                      color: getColorByPercent(group?.percent),
                      fontSize: 16
                    }}
                  />
                )}
                {group?.percent !== 0 && (
                  <ExtensionRounded
                    sx={{
                      color: getColorByPercent(group?.percent),
                      fontSize: 16
                    }}
                  />
                )}
              </span>
            </Tooltip>
            <Typography
              fontFamily={theme.typography.primaryBase.font}
              sx={{
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap'
              }}
              variant="body_200"
            >
              {group.title}
            </Typography>
            {!group?.matchTimezone && (
              <Typography color="warning.main" variant="primary">
                <Tooltip title={t('TOOLTIP.PROJECT.noMatchTimezone')}>
                  <i
                    className="custom-icon icon-warning"
                    style={{ fontSize: 16 }}
                  />
                </Tooltip>
              </Typography>
            )}
          </Box>
          {group?.initials && (
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between'
              }}
            >
              <Typography
                fontFamily={theme.typography.primaryBase.font}
                variant="body_200"
              >
                {group?.rate && `${getCurrency(group?.rate)}`}
              </Typography>
            </Box>
          )}
        </Box>
      );
    },
    [groups]
  );

  const handleTimeChange = (
    visibleTimeStart: any,
    visibleTimeEnd: any,
    updateScrollCanvas: any
  ) => {
    if (visibleTimeStart < minTime && visibleTimeEnd > maxTime) {
      updateScrollCanvas(minTime, maxTime);
    } else if (visibleTimeStart < minTime) {
      updateScrollCanvas(
        minTime,
        minTime + (visibleTimeEnd - visibleTimeStart)
      );
    } else if (visibleTimeEnd > maxTime) {
      updateScrollCanvas(
        maxTime - (visibleTimeEnd - visibleTimeStart),
        maxTime
      );
    } else {
      updateScrollCanvas(visibleTimeStart, visibleTimeEnd);
    }
  };

  const transformedGroups = useMemo(() => {
    return groups.map((group) => {
      return { ...group, title: generateNestedView(group) };
    });
  }, [groups]);

  const getTitleMeaning = (title: string, meaning: string) => {
    return (
      <Box sx={{ alignItems: 'center', display: 'flex' }}>
        <Typography fontSize={16} variant="secondary-bold">
          {title}:
        </Typography>
        &nbsp;
        <Typography color="primary" fontSize={24} variant="primary-bold">
          {meaning}
        </Typography>
      </Box>
    );
  };

  return (
    <Modal
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500
      }}
      onClose={handleClose}
      open={auxManualClose}
      sx={{
        alignItems: 'center',
        maxHeight: '100% !important'
      }}
    >
      <ModalContainer
        sx={{
          maxHeight: '90% !important',
          maxWidth: '95% !important',
          minWidth: 500,
          padding: '2rem'
        }}
      >
        <Box
          key="detail-popup-title"
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            minWidth: 700,
            width: '100%'
          }}
        >
          {getTitleMeaning(t('LISTING_PAGES.task'), taskData.name)}
          {getTitleMeaning(
            t('LISTING_PAGES.mediaRate'),
            getCurrency(taskData.mediaRate)
          )}
          {getTitleMeaning(
            t('PROJECT.ASSIGNMENTS.requiredResources'),
            taskData.requiredAssignments
          )}
        </Box>
        {taskData?.assignmentId && (
          <Box
            key="detail-popup-task-data"
            sx={{ display: 'block', textAlign: 'left', width: '100%' }}
          >
            <FormLabel label={`${t('GENERAL.reassignReazon')}*`} />
            <RegularInput
              multiline
              customStyle={{ width: '100%' }}
              onChange={(description) => setReason(description)}
              value={reason || ''}
            />
          </Box>
        )}
        {(isValidatingResources || isValidatingAssignment) && (
          <Loader customColor="primary" />
        )}
        {!isValidatingResources && !isValidatingAssignment && (
          <Box
            className="assignments-detail-popup-body"
            key="detail-popup-body"
            sx={{ display: 'flex', textAlign: 'left', width: '100%' }}
          >
            {noResources === '' &&
              transformedGroups &&
              items.length > 0 &&
              transformedGroups && (
                <Timeline
                  itemTouchSendsClick
                  stackItems
                  traditionalZoom
                  buffer={1}
                  className="calendar-header-blue-open"
                  defaultTimeEnd={defaultTimeEnd}
                  defaultTimeStart={defaultTimeStart}
                  groups={transformedGroups}
                  itemHeightRatio={1}
                  itemRenderer={itemRenderer}
                  items={items}
                  keys={keys}
                  lineHeight={30}
                  maxZoom={365 * 86400 * 1000}
                  minZoom={30 * 86400 * 1000}
                  onTimeChange={handleTimeChange}
                  scrollRef={(el) => {
                    // console.log('el->', el);
                  }}
                  sidebarWidth={300}
                  timeSteps={{
                    day: 1,
                    hour: 1,
                    minute: 1,
                    month: 1,
                    second: 1,
                    year: 1
                  }}
                >
                  <TimelineHeaders
                    style={{
                      backgroundColor: '#0077c0',
                      fontFamily: theme.typography.primaryBase.font
                    }}
                  >
                    <SidebarHeader>
                      {({ getRootProps }) => {
                        return (
                          <Box
                            {...getRootProps()}
                            sx={{
                              alignItems: 'center',
                              backgroundColor: '#0077c0',
                              display: 'flex',
                              padding: 2
                            }}
                          >
                            <Typography
                              fontFamily={theme.typography.primaryBase.font}
                              sx={{
                                color: 'white',
                                display: 'flex',
                                justifyContent: 'space-between',
                                width: '100%'
                              }}
                              variant="body_200"
                            >
                              <Box>{t('TIMESHEET.technician')}</Box>
                              <Box>{t('INVITATION.rate')}</Box>
                            </Typography>
                          </Box>
                        );
                      }}
                    </SidebarHeader>
                    <DateHeader
                      className="primaryHeader12"
                      height={30}
                      style={{
                        fontFamily: theme.typography.primaryBase.font
                      }}
                      unit="primaryHeader"
                    />
                    <DateHeader className="primaryHeader22" />
                  </TimelineHeaders>
                </Timeline>
              )}
            {noResources !== '' && (
              <Box
                sx={{
                  alignItems: 'center',
                  display: 'flex',
                  height: '4rem',
                  justifyContent: 'center',
                  width: '100%'
                }}
              >
                <Typography
                  color={alertColors.ALERT}
                  fontFamily={theme.typography.primaryBase.font}
                  variant="body_500"
                >
                  {noResources}
                </Typography>
              </Box>
            )}
          </Box>
        )}
        <Box key="detail-popup-buttons">
          <DrawerButton
            onClick={handleClose}
            sx={{ height: 46, width: 200 }}
            variant="cancel"
          >
            <Typography>Cancel</Typography>
          </DrawerButton>
          <DrawerButton
            disabled={
              groups.filter((groupInfo: any) => groupInfo.selected).length ===
                0 ||
              (taskData?.assignmentId && reason === '')
            }
            onClick={() => {
              setFireAssignResources(true);
            }}
            sx={{ height: 46, width: 200 }}
            variant="contained"
          >
            <Typography>Confirm</Typography>
          </DrawerButton>
        </Box>
      </ModalContainer>
    </Modal>
  );
};

export default AssignmentsTask;
