import { Box, Button, Typography, useTheme } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';
import ServiceTimeCalculator from '../../../components/calculator/ServiceTimeCalculator';
import TableComponentEditable from '../../../components/shared/TableComponentEditable';
import DeleteButton from '../../../components/list/DeleteButton';
import { getCurrency, getSalesTaxableItems } from '../../../utils/general';
import {
  getCalculatedMarkup,
  getMargin,
  getMarkup,
  getResourceCost,
  getTotalHoursByInterval,
  getTotalProject
} from '../../../utils/calculatorHelper';
import { secondaryColors } from '../../../theme/themeConfig';

interface Props {
  onCloseDrawer?: () => void;
  // availability: any;
  saveStepper: ({ next }: any) => void;
  setValues: React.Dispatch<React.SetStateAction<any>>;
  values: any;
}

const CostCalculator: React.FC<Props> = function CostCalculator({
  values,
  setValues,
  saveStepper,
  onCloseDrawer
}) {
  const [totalServiceTimeCost, setTotalServiceTimeCost] = useState<number>(0);

  const { t } = useTranslation();
  const [taxes, setTaxes] = useState<number>(0.0);
  const { palette } = useTheme();
  const buyPrice = getTotalProject(values, t);
  const sellPrice = values?.profit
    ? (parseFloat(values.profit || 0) / 100) * buyPrice + buyPrice
    : buyPrice;
  const margin = getMargin(buyPrice, sellPrice);
  const markup = getMarkup(buyPrice, sellPrice);

  const updatedValues = {
    ...values,
    destinations: values?.destinations?.map((destination: any) => {
      return {
        ...destination,
        serviceTimes: destination?.serviceTimes?.map((serviceTime: any) => {
          if (!serviceTime.startDate) {
            serviceTime.startDate = dayjs(values.projectStartDate);
          }
          return serviceTime;
        })
      };
    })
  };

  const getSupervisionCost = () => {
    const transportationTime = [
      {
        cost: getResourceCost(
          values.supervisor,
          getTotalHoursByInterval(
            Number(values?.supervisorTime),
            values?.supervisorFrequency,
            values?.startDate,
            values?.endDate
          )
        ),
        name: t('LEAD.CREATE.COST_CALCULATOR.supervisorTimeCost')
      }
    ];

    return (
      <TableComponentEditable
        disableAdd
        hideTableHeader
        action={() => {}}
        alignRightFields={['cost']}
        handleOpenDraw={() => {}}
        headKeys={['name', 'cost']}
        onChange={() => {}}
        valuesArray={{
          data: transportationTime
        }}
      />
    );
  };

  const getMiscCost = () => {
    let total = 0.0;
    if (values?.Misc?.length > 0) {
      values.Misc.forEach((misc: any) => {
        total += parseFloat(misc.total || 0);
      });
    }
    return total;
  };

  const getTotalInvoiced = () => {
    return sellPrice + getSalesTaxableItems('tax', values?.taxableItems) || 0;
  };

  const marginAsPercentage = () => {
    return margin.percent;
  };

  const markupAsPercentage = () => {
    return markup.percent || 0;
  };

  const summaryData = [
    {
      name: t('LEAD.CREATE.COST_CALCULATOR.SUMMARY.summaryDesireMargin'),
      readOnly: values?.readOnly,
      summaryCost: margin.percent || 0.0
    },
    // {
    //   name: t('LEAD.CREATE.COST_CALCULATOR.SUMMARY.summaryMarkup'),
    //   summaryCost: getCurrency(markup.amount)
    // },
    {
      name: t('LEAD.CREATE.COST_CALCULATOR.SUMMARY.summaryMarkupPercent'),
      summaryCost: `${markupAsPercentage()}%`
    }
    // {
    //   name: t('LEAD.CREATE.COST_CALCULATOR.SUMMARY.summarySuggestedPrice'),
    //   summaryCost: getCurrency(sellPrice)
    // }
    // {
    //   name: (
    //     <Typography variant="primary-bold">
    //       {t('LEAD.CREATE.COST_CALCULATOR.SUMMARY.summarySalesTax')}
    //     </Typography>
    //   ),
    //   summaryCost: getCurrency(
    //     getSalesTaxableItems('tax', values?.taxableItems)
    //   )
    // },
    // {
    //   name: (
    //     <Typography variant="primary-bold">
    //       {t('LEAD.CREATE.COST_CALCULATOR.SUMMARY.summarytotal')}
    //     </Typography>
    //   ),
    //   summaryCost: getCurrency(getTotalInvoiced())
    // }
  ];

  const getServiceTimes = () => {
    if (values?.destinations && values.destinations.length > 0) {
      return (
        <>
          {values.destinations.map((destination: any, key: number) =>
            destination.serviceTimes.map((servTime: any, index: number) => {
              if (servTime?.services?.length > 0) {
                const auxServices =
                  servTime?.services.map((service: any) => {
                    return {
                      ...service,
                      readOnly: values.readOnly
                    };
                  }) || [];
                const indexKey = `calculator-serviceTime-${index}`;

                return (
                  <React.Fragment key={`${indexKey}`}>
                    {index === 0 && (
                      <Typography variant="secondary-semi">
                        {`${t('LEAD.CREATE.DESTINATIONS.destination')} #${
                          key + 1
                        }`}
                        :{' '}
                        <Typography color="mainText.300" variant="body_300">
                          {destination.address.name}
                        </Typography>
                      </Typography>
                    )}
                    <ServiceTimeCalculator
                      index={index}
                      onServiceTimeCostChange={(newCost: number) => {
                        setTotalServiceTimeCost(
                          (prevTotal) => prevTotal + newCost
                        );
                      }}
                      setValues={(newValue: any) => {
                        const { destinations } = values;
                        const destinationIndex = destinations.findIndex(
                          (dest: any) =>
                            dest.serviceTimes.some(
                              (st: any) => st.id === servTime.id
                            )
                        );
                        destinations[destinationIndex].serviceTimes[index] =
                          newValue;
                        updatedValues.destinations[
                          destinationIndex
                        ].serviceTimes[index] = newValue;
                        setValues(updatedValues);
                      }}
                      totalServiceTimeCost={totalServiceTimeCost}
                      values={{
                        company: values.company,
                        ...servTime,
                        readOnly: values.readOnly,
                        services: auxServices
                      }}
                    />
                  </React.Fragment>
                );
              }
              return null;
            })
          )}
        </>
      );
    }
    return false;
  };

  const getHeader = () => {
    return (
      <Box display="flex" flexDirection="column" gap="16px">
        <Typography color="primary.main" variant="heading_500">
          {t('LEAD.CREATE.COST_CALCULATOR.title')}
        </Typography>
        <Typography color={secondaryColors.SECONDARY_4} variant="body_200">
          {t('LEAD.CREATE.COST_CALCULATOR.subtitle')}
        </Typography>
      </Box>
    );
  };

  const getMiscelaneous = () => {
    return (
      <TableComponentEditable
        action={() => {}}
        addType="add"
        editableFields={['misc', 'total']}
        handleOpenDraw={() => {
          const Misc = values.Misc || [];
          Misc.push({
            misc: 'edit miscelaneous here',
            total: ''
          });
          setValues({ ...values, Misc });
        }}
        headKeys={['misc', 'total', 'delete']}
        onChange={(value, key, index) => {
          const Misc = values.Misc || [];
          Misc[index] = {
            ...Misc[index],
            [key]: value
          };
          setValues({ ...values, Misc });
        }}
        valuesArray={{
          data:
            values?.Misc?.length > 0
              ? values.Misc.map((misc: any, index: number) => {
                  return {
                    ...misc,
                    delete: (
                      <DeleteButton
                        deleteFn={() => {
                          const Misc = values.Misc || [];
                          Misc.splice(index, 1);
                          setValues({ ...values, Misc });
                        }}
                      />
                    )
                  };
                })
              : []
        }}
      />
    );
  };

  const getTaxableItems = () => {
    return (
      <Box display="flex" flexDirection="column" pt={2}>
        <TableComponentEditable
          action={() => {}}
          addType="add"
          editableFields={['taxableItems', 'subTotal']}
          handleOpenDraw={() => {
            const taxableItems = values.taxableItems || [];
            taxableItems.push({
              salesTax: 0,
              subtotal: 0,
              taxableItems: 'edit tax item here',
              total: 0
            });
            setValues({ ...values, taxableItems });
          }}
          headKeys={['taxableItems', 'subTotal', 'salesTax', 'total', 'delete']}
          onChange={(value, key, index) => {
            const taxableItems = values.taxableItems || [];
            taxableItems[index] = {
              ...taxableItems[index],
              [key]: value
            };

            taxableItems[index] = {
              ...taxableItems[index],
              salesTax: getCurrency(
                (taxes / 100) * (taxableItems[index].subTotal || 0)
              ),
              subTotal: taxableItems[index].subTotal || 0,
              total:
                (taxes / 100) * (taxableItems[index].subTotal || 0) +
                (taxableItems[index].subTotal || 0)
            };
            setValues({ ...values, taxableItems });
          }}
          valuesArray={{
            data:
              values?.taxableItems?.length > 0
                ? values.taxableItems.map(
                    (taxableItems: any, index: number) => {
                      return {
                        ...taxableItems,
                        company: values.company,
                        delete: (
                          <DeleteButton
                            deleteFn={() => {
                              const taxableItems = values.taxableItems || [];
                              taxableItems.splice(index, 1);
                              setValues({ ...values, taxableItems });
                            }}
                          />
                        )
                      };
                    }
                  )
                : []
          }}
        />
      </Box>
    );
  };

  const getSummary = () => {
    return (
      <Box display="flex" flexDirection="column" gap="24px">
        <Box display="flex" justifyContent="space-between" width={500}>
          <Typography fontWeight={500} variant="body_400">
            {t('LEAD.CREATE.COST_CALCULATOR.SUMMARY.summaryHeader')}
          </Typography>
        </Box>

        <Box display="flex" flexDirection="row" gap="48px">
          <Box minWidth={500}>
            <TableComponentEditable
              disableAdd
              hideTableHeader
              action={() => {}}
              alignRightFields={['summaryCost']}
              editableFields={['profit']}
              handleOpenDraw={() => {}}
              headKeys={['name', 'summaryCost']}
              onChange={(value, key, index) => {
                if (key || index) {
                  const { profit } = getCalculatedMarkup(value);
                  setValues({
                    ...values,
                    profit
                  });
                }
              }}
              successRows={[5]}
              valuesArray={{
                data: summaryData
              }}
              warningRows={[0]}
            />
          </Box>

          <Box
            alignItems="start"
            display="flex"
            flex={1}
            flexDirection="column"
            gap="16px"
          >
            <Typography fontWeight={500} variant="body_400">
              {t('LEAD.CREATE.COST_CALCULATOR.SUMMARY.summaryTitle')}
            </Typography>
            <Typography
              color={secondaryColors.SECONDARY_4}
              variant="body_300"
              whiteSpace="pre-wrap"
            >
              {t('LEAD.CREATE.COST_CALCULATOR.SUMMARY.summaryDescription')}
            </Typography>
          </Box>
        </Box>
      </Box>
    );
  };

  const getErrors = () => {
    const toBack = [];
    const { destinations } = values;
    if (destinations || (destinations && !destinations.services)) {
      toBack.push('No Services Added');
    }
    return toBack;
  };

  const getWarnings = () => {
    return (
      <Box>
        <Box
          border={2}
          borderColor={palette.warning[400]}
          borderRadius={1}
          display="flex"
          flexDirection="column"
          p={2}
          sx={{ background: palette.warning[100] }}
          width={500}
        >
          <Box>
            <Typography variant="body_400">
              <Typography variant="primary-bold">Warnings</Typography>
            </Typography>
          </Box>
          <Box>
            {getErrors().map((error) => {
              return (
                <Typography variant="primary">
                  <li>{error}</li>
                </Typography>
              );
            })}
          </Box>
        </Box>
      </Box>
    );
  };

  useEffect(() => {
    const { company } = values || {};
    const { taxes } = company || {};
    let sales = 0.0;
    if (taxes?.length > 0) {
      taxes.forEach((tax: any) => {
        if (tax.type === 'sales') {
          sales += tax.satax;
        }
      });
    }
    setTaxes(sales);
    if (false) {
      saveStepper(1);
      setValues(values);
    }
  }, []);

  return (
    <Box display="flex" flexDirection="column" gap="48px">
      {getHeader()}

      {false && getWarnings()}

      <Box display="flex" flexDirection="column" gap="24px">
        {getServiceTimes()}
      </Box>

      {/* <Box display="flex" flexDirection="column">
        <Typography variant="body_500">
          {t('LEAD.CREATE.COST_CALCULATOR.otherCostTitle')}
        </Typography>
        {getMiscelaneous()}
        {getTaxableItems()}
      </Box> */}
      {/* {getSupervisionCost()} */}

      {getSummary()}

      <Box display="flex" justifyContent="flex-end" width="100%">
        {values?.drawerMode ? (
          <Button
            onClick={() => {
              if (onCloseDrawer) {
                onCloseDrawer();
              }
            }}
            sx={{ width: 304 }}
            variant="cancel"
          >
            <Typography
              sx={{ 'text-transform': 'capitalize' }}
              variant="primary"
            >
              {t('GENERAL.closeButton')}
            </Typography>
          </Button>
        ) : (
          <Button
            onClick={() => saveStepper({ next: 5 })}
            sx={{ width: 304 }}
            variant="contained"
          >
            <Typography
              sx={{ 'text-transform': 'capitalize' }}
              variant="primary"
            >
              {t('LEAD.CREATE.COST_CALCULATOR.goToAnalysisButton')}
            </Typography>
          </Button>
        )}
      </Box>
    </Box>
  );
};

CostCalculator.defaultProps = {
  onCloseDrawer: undefined
};

export default CostCalculator;
