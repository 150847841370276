/* eslint-disable react/require-default-props */
import React from 'react';
import { Box, styled, Typography, useTheme } from '@mui/material';
import { getCurrency } from '../../../../../utils/general';
import Loader from '../../../../../components/shared/Loader';
import {
  accentColors,
  secondaryColors,
} from '../../../../../theme/themeConfig';


interface tileProps {
  icon?: React.ReactNode;
  isLoading?: boolean;
  tileContainerPropSx?: any;
  title: string;
  type: string;
  value: string;
}

export const TileContainer = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.background.default,
  border: `1px solid ${secondaryColors.SECONDARY_6}`,
  borderRadius: theme.spacing(1),
  padding: theme.spacing(3),
  width: '100%'
}));

const Tile: React.FC<tileProps> = function Tile({
  title,
  value,
  icon,
  tileContainerPropSx,
  type,
  isLoading = false,
}) {
  const theme = useTheme();

  const getValue = () => {
    switch (type) {
      case 'Currency':
        return getCurrency(value);
      case 'Counter':
        return `${value}`;
      case 'Percentage':
        return `${value} %`;
      default:
        return value;
    }
  };

  return (
    <TileContainer sx={tileContainerPropSx}>
      {icon && (
        <Box
          sx={{
            alignItems: 'center',
            backgroundColor: theme.palette.accent?.main,
            borderRadius: '10px',
            display: 'flex',
            height: '40px',
            justifyContent: 'center',
            width: '40px'
          }}
        >
          {icon}
        </Box>
      )}
      <Box
        sx={{
          alignItems: 'flex-start',
          display: 'flex',
          flexDirection: 'column',
          gap: 3,
          height: '100%',
          justifyContent: 'center'
        }}
      >
        <Typography color="black" variant="heading_300">
          {title}
        </Typography>
        {!isLoading ? (
          <Typography
            color={accentColors.ACCENT}
            sx={{ fontSize: 28 }}
            variant="primary-bold"
          >
            {getValue()}
          </Typography>
        ) : (
          <Loader customColor="primary" />
        )}
      </Box>
    </TileContainer>
  );
};
export default Tile;
