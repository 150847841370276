import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Box,
  Button,
  Typography,
  Dialog,
  DialogActions,
  DialogContent
} from '@mui/material';

interface Props {
  client: string;
  handleConfirmation: () => void;
  modalText: string;
  setShowModal: React.Dispatch<React.SetStateAction<boolean>>;
}

const OkModal: React.FC<Props> = function OkModal({
  setShowModal,
  modalText,
  client,
  handleConfirmation
}) {
  const { t } = useTranslation();
  const [open, setOpen] = useState(true);
  // const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
    <Dialog
      fullWidth
      PaperProps={{
        sx: {
          padding: 6
        }
      }}
      maxWidth="xs"
      onClose={() => {
        handleClose();
        setShowModal(false);
      }}
      open={open}
    >
      <DialogContent
        sx={{
          textAlign: 'center'
        }}
      >
        <Box display="flex" flexDirection="column" gap={3}>
          <Typography color="primary" variant="heading_400">
            {modalText}
          </Typography>
          <Typography color="secondary" variant="primary-bold">
            {client}
          </Typography>
        </Box>
      </DialogContent>
      <DialogActions
        sx={{
          justifyContent: 'center'
        }}
      >
        <Button
          onClick={() => {
            handleClose();
            handleConfirmation();
          }}
          sx={{ width: '200px' }}
          variant="primary"
        >
          <Typography>{t('MODAL.okButton')}</Typography>
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default OkModal;
