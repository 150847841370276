/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable react/jsx-props-no-spreading */
import {
  Box,
  Button,
  FormControl,
  FormControlLabel,
  RadioGroup,
  Typography
} from '@mui/material';
import React, { useEffect, useState, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import CustomRadio from '../../../../components/form/CustomRadio';
import DaysOfTheWeek from '../../../../components/input/DaysOfTheWeek';
import useNavigationStore from '../../../../store/zustand/navigation';
import TimeComponent from '../../../../components/input/TimeComponent';
import AddServiceModal from '../../../../components/modals/AddServiceModal';
import Filter from '../../../../components/list/Filter';
import { weekDays } from '../../../../helpers/weekDays';
import { ServiceCollapse } from '../components/ServiceCollapse';
import DatePickerTextField from '../components/DatePickerTextField';
import { FormLabel } from '../../../../components/form/parts';
import { secondaryColors } from '../../../../theme/themeConfig';
import { phoneNumberFormat } from '../../../../utils/general';
import { Service, Task } from '../../../../utils/types/ServiceTimeType';
import useNotificationsStore from '../../../../store/zustand/notifications';

interface Props {
  destinationIndex: number;
  indexServiceTime: number;
  projectEnd: any;
  projectStart: any;
  projectType: string;
  saveStepper: ({ next }: any) => void;
  // eslint-disable-next-line react/require-default-props
  serviceTimeId?: number;
  setCreateServiceTime: React.Dispatch<React.SetStateAction<boolean>>;
  setCurrentServiceTime: React.Dispatch<React.SetStateAction<number>>;
  setServiceTimeUpdate: React.Dispatch<React.SetStateAction<boolean>>;
  setValues: React.Dispatch<React.SetStateAction<any>>;
  values: any;
}

const ServiceTime: React.FC<Props> = function ServiceTime({
  values,
  setValues,
  indexServiceTime,
  projectStart,
  projectEnd,
  setCurrentServiceTime,
  setCreateServiceTime,
  setServiceTimeUpdate,
  projectType,
  saveStepper,
  serviceTimeId,
  destinationIndex
}) {
  const { t } = useTranslation();
  const { setConfirmModal } = useNavigationStore((state) => state);
  const { addNotification } = useNotificationsStore((state) => state);
  const [serviceFromModal, setServiceFromModal] = useState<any>();
  const [addService, setAddService] = useState<boolean>(false);
  const [focus, setFocus] = useState<number>(0);
  const [loadValuesServTime, setLoadValuesServTime] = useState<boolean>(true);

  const serviceTimes = values?.destinations[destinationIndex]?.serviceTimes;

  const [valuesServTime, setValuesServTime] = useState<any>(() => {
    if (
      loadValuesServTime &&
      serviceTimes &&
      serviceTimes[indexServiceTime]?.id
    ) {
      const { services, ...rest } = serviceTimes[indexServiceTime];
      return {
        ...rest,
        services: services?.filter((service: Service) => service?.name)
      };
    }
    return {};
  });

  const areAllTaskQuantitiesValid = (): boolean => {
    if (
      !Array.isArray(valuesServTime?.services) ||
      valuesServTime.services.length === 0
    ) {
      return false;
    }
    return (
      valuesServTime?.services?.every((service: Service) =>
        service?.tasks?.every((task: Task) => (task?.quantity ?? 0) > 0)
      ) ?? false
    );
  };

  useEffect(() => {
    if (valuesServTime?.id) {
      setLoadValuesServTime(false);
    }
  }, [valuesServTime]);

  const weekdays = useMemo(() => {
    return weekDays.map((day: any) => {
      return day.name;
    });
  }, []);

  function ServiceHeader() {
    return (
      <Box display="flex" gap={6} justifyContent="space-between">
        <Box display="flex" flexDirection="column" gap="16px">
          {projectType === 'regular' || projectType === 'Regular' ? (
            <>
              <Typography color="primary.main" variant="heading_500">
                {t('LEAD.CREATE.SERVICES.serviceTimeTitle', [
                  indexServiceTime ? indexServiceTime + 1 : 1
                ])}
              </Typography>
              <Typography color="secondary.400" variant="body_200">
                {t('LEAD.CREATE.SERVICES.serviceTimeDescription')}
              </Typography>
            </>
          ) : (
            <>
              <Typography color="primary.main" variant="heading_500">
                {t('LEAD.CREATE.SERVICES.adhocHeaderTitle')}
              </Typography>
              <Typography color="secondary.400" variant="body_200">
                {t('LEAD.CREATE.SERVICES.adhocHeaderDescription')}
              </Typography>
            </>
          )}
        </Box>
        <Box
          alignSelf="flex-end"
          display="flex"
          flexDirection="column"
          width="100%"
        >
          {values?.destinations[destinationIndex]?.address?.address && (
            <Box textAlign="right">
              <Typography color="primary.400" variant="body_200">{`${t(
                'LEAD.CREATE.DESTINATIONS.address'
              )}: `}</Typography>
              <Typography color="secondary.400" variant="body_200">
                {`${
                  values?.destinations[destinationIndex]?.address?.address ||
                  t('LEAD.CREATE.SERVICES.MANUAL_PRICING.noData')
                } ${
                  values?.destinations[destinationIndex]?.address?.phone
                    ? phoneNumberFormat(
                        values?.destinations[destinationIndex]?.address?.phone
                      )
                    : t('LEAD.CREATE.SERVICES.MANUAL_PRICING.noData')
                }`}
              </Typography>
            </Box>
          )}
          {values?.destinations[destinationIndex]?.contact?.name && (
            <Box textAlign="right">
              <Typography color="primary.400" variant="body_200">
                {`${t('LEAD.CREATE.DESTINATIONS.contact')}: `}
              </Typography>
              <Typography color="secondary.400" variant="body_200">
                {`${
                  values?.destinations[destinationIndex]?.contact?.name ||
                  t('LEAD.CREATE.SERVICES.MANUAL_PRICING.noData')
                } ${
                  values?.destinations[destinationIndex]?.contact?.phoneNumber
                    ? phoneNumberFormat(
                        values?.destinations[destinationIndex]?.contact
                          ?.phoneNumber
                      )
                    : t('LEAD.CREATE.SERVICES.MANUAL_PRICING.noData')
                }`}
              </Typography>
            </Box>
          )}
        </Box>
      </Box>
    );
  }

  function ServiceTimeConfiguration() {
    return (
      <Box display="flex" flexDirection="column" gap="48px">
        {ServiceHeader()}

        <div
          style={{
            backgroundColor: secondaryColors.SECONDARY_3,
            height: '1px',
            width: '100%'
          }}
        />

        {(projectType === 'regular' || projectType === 'Regular') && (
          <Box display="flex" flexDirection="column" gap="64px">
            <Box
              alignItems="center"
              display="grid"
              gridTemplateColumns="200px 1fr"
            >
              <Typography color="primary.main" variant="heading_300">
                {t('LEAD.CREATE.SERVICES.frequency')}:
              </Typography>
              <FormControl>
                <RadioGroup
                  row
                  aria-labelledby="service-frequency"
                  name="service-frequency"
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    const frequency = event.target.value;
                    setValuesServTime({
                      ...valuesServTime,
                      frequency: event.target.value,
                      frequencyDays:
                        frequency === 'Monthly' &&
                        valuesServTime.frequencyDays?.length > 0
                          ? [valuesServTime.frequencyDays[0]]
                          : valuesServTime.frequencyDays
                    });
                  }}
                >
                  <Box display="flex" gap="48px">
                    <FormControlLabel
                      checked={valuesServTime?.frequency === 'Weekly'}
                      control={<CustomRadio />}
                      label={
                        <Typography color="mainText.200" variant="body_300">
                          {t('LEAD.CREATE.SERVICES.weekly')}
                        </Typography>
                      }
                      value="Weekly"
                    />
                    <FormControlLabel
                      checked={valuesServTime?.frequency === 'Biweekly'}
                      control={<CustomRadio />}
                      label={
                        <Typography color="mainText.200" variant="body_300">
                          {t('LEAD.CREATE.SERVICES.biweekly')}
                        </Typography>
                      }
                      value="Biweekly"
                    />
                    <FormControlLabel
                      checked={valuesServTime?.frequency === 'Monthly'}
                      control={<CustomRadio />}
                      label={
                        <Typography color="mainText.200" variant="body_300">
                          {t('LEAD.CREATE.SERVICES.monthly')}
                        </Typography>
                      }
                      value="Monthly"
                    />
                  </Box>
                </RadioGroup>
              </FormControl>
            </Box>

            {valuesServTime?.frequency &&
              valuesServTime.frequency === 'Monthly' && (
                <Box
                  alignItems="center"
                  display="grid"
                  gridTemplateColumns="200px 1fr"
                >
                  <Typography color="primary.main" variant="heading_300">
                    {t('LEAD.CREATE.SERVICES.frequencyWeek')}:
                  </Typography>
                  <Box alignItems="center" display="flex">
                    <Filter
                      arrayInput={['First', 'Second', 'Third', 'Fourth']}
                      selectInput={valuesServTime?.frequencyWeek}
                      setSelectInput={(frequencyWeek) => {
                        setValuesServTime({ ...valuesServTime, frequencyWeek });
                      }}
                      title="Week"
                    />
                    <Filter
                      arrayInput={weekdays}
                      selectInput={valuesServTime?.frequencyDays?.[0]}
                      setSelectInput={(frequencyDays) => {
                        setValuesServTime({
                          ...valuesServTime,
                          frequencyDays: [frequencyDays]
                        });
                      }}
                      title="Day"
                    />
                    <Typography
                      color={secondaryColors.SECONDARY_4}
                      variant="heading_300"
                    >
                      {t('LEAD.CREATE.SERVICES.frequencyWeek2')}
                    </Typography>
                  </Box>
                </Box>
              )}

            {valuesServTime && valuesServTime.frequency !== 'Monthly' && (
              <Box
                alignItems="center"
                display="grid"
                gridTemplateColumns="184px 1fr"
              >
                <Typography color="primary.main" variant="heading_300">
                  {t('LEAD.CREATE.SERVICES.daysOfTheWeek')}:
                </Typography>
                <Box alignItems="center" display="flex" gap="8px">
                  <Button
                    onClick={() => {
                      if (valuesServTime?.frequencyDays.length === 7) {
                        setValuesServTime({
                          ...valuesServTime,
                          frequencyDays: []
                        });
                      } else {
                        setValuesServTime({
                          ...valuesServTime,
                          frequencyDays: weekDays.map((day) => day.name)
                        });
                      }
                    }}
                  >
                    <Typography
                      color="primary.main"
                      textTransform="capitalize"
                      variant="heading_328"
                    >
                      {t('LEAD.CREATE.SERVICES.allDays')}
                    </Typography>
                  </Button>
                  <DaysOfTheWeek
                    disabled={!valuesServTime?.frequency}
                    setValues={(frequencyDays) => {
                      setValuesServTime({ ...valuesServTime, frequencyDays });
                    }}
                    type="box"
                    values={valuesServTime?.frequencyDays || []}
                  />
                </Box>
              </Box>
            )}

            <Box display="grid" gridTemplateColumns="200px 1fr">
              <Typography color="primary.main" variant="heading_300">
                {t('LEAD.CREATE.SERVICES.timePeriod')}:
              </Typography>

              <Box display="flex" gap="48px">
                <Box
                  display="flex"
                  flexDirection="column"
                  gap="4px"
                  width={250}
                >
                  <FormLabel label={t('LEAD.CREATE.startDate')} />
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                      minDate={dayjs(projectStart) || undefined}
                      onChange={(startDate) => {
                        if (
                          (valuesServTime.endDate && startDate) ||
                          projectStart > valuesServTime.endDate
                        ) {
                          setValuesServTime({
                            ...valuesServTime,
                            endDate: projectEnd,
                            startDate
                          });
                        } else {
                          setValuesServTime({
                            ...valuesServTime,
                            startDate
                          });
                        }
                      }}
                      renderInput={(params) => (
                        <DatePickerTextField {...params} />
                      )}
                      value={valuesServTime?.startDate || projectStart}
                    />
                  </LocalizationProvider>
                </Box>

                <Box
                  display="flex"
                  flexDirection="column"
                  gap="4px"
                  width={250}
                >
                  <FormLabel label={t('LEAD.CREATE.endDate')} />
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                      maxDate={dayjs(projectEnd) || undefined}
                      onChange={(endDate) =>
                        setValuesServTime({ ...valuesServTime, endDate })
                      }
                      renderInput={(params) => (
                        <DatePickerTextField {...params} />
                      )}
                      value={dayjs(valuesServTime.endDate || projectEnd)}
                    />
                  </LocalizationProvider>
                </Box>
              </Box>
            </Box>
          </Box>
        )}
        <Box
          alignItems="center"
          display="grid"
          gridTemplateColumns="200px 1fr"
          mt="24px"
        >
          <Box display="flex" flexDirection="column">
            <Typography color="primary.main" variant="heading_300">
              {t('LEAD.CREATE.SERVICES.travelTime')}:
            </Typography>
            <Typography color="primary.main" variant="body_200">
              {`(${t('LEAD.CREATE.SERVICES.roundTrip')})`}
            </Typography>
          </Box>
          <TimeComponent
            reverseOrder
            readOnly={values?.readOnly}
            setTime={(travelTime) =>
              setValuesServTime({
                ...valuesServTime,
                travelTime: parseFloat(travelTime.toFixed(2))
              })
            }
            time={valuesServTime.travelTime || 0}
          />
        </Box>
      </Box>
    );
  }

  function Buttons() {
    const formatTime = (time: string) => {
      const timeTZ = dayjs()
        .hour(parseFloat(time.split(':')[0]))
        .minute(parseFloat(time.split(':')[1]));
      if (
        timeTZ?.hour().toString().length === 1 &&
        timeTZ?.minute().toString().length === 1
      ) {
        return `0${timeTZ?.hour()}:0${timeTZ?.minute()}`;
      }
      if (timeTZ?.hour().toString().length === 1) {
        return `0${timeTZ?.hour()}:${timeTZ?.minute()}`;
      }
      if (timeTZ?.minute().toString().length === 1) {
        return `${timeTZ?.hour()}:0${timeTZ?.minute()}`;
      }
      return `${timeTZ?.hour()}:${timeTZ?.minute()}`;
    };

    return (
      <Box display="flex" gap="16px" justifyContent="flex-end">
        <Button
          fullWidth
          disabled={values?.readOnly}
          onClick={() => {
            if (serviceTimeId === -1) {
              const cleanedServicesTimes = serviceTimes?.filter(
                (item: any) =>
                  item?.id &&
                  typeof item?.id === 'number' &&
                  item?.id !== serviceTimeId
              );
              setValues({
                ...values,
                destinations: values.destinations.map(
                  (destination: any, index: number) =>
                    index === destinationIndex
                      ? { ...destination, serviceTimes: cleanedServicesTimes }
                      : destination
                )
              });
            }
            setValuesServTime({});
            setCurrentServiceTime(-1);
          }}
          sx={{ width: '304px' }}
          variant="cancel"
        >
          {t('GENERAL.cancelButton')}
        </Button>
        <Button
          fullWidth
          disabled={values?.readOnly || !areAllTaskQuantitiesValid()}
          onClick={() => {
            const today = dayjs(projectStart);
            if (typeof valuesServTime.startDate === 'undefined') {
              valuesServTime.startDate = today;
            }

            if (typeof valuesServTime.endDate === 'undefined') {
              valuesServTime.endDate = projectEnd || today;
            }

            if (serviceTimes[indexServiceTime]?.id) {
              setValuesServTime({
                ...valuesServTime,
                id: serviceTimes[indexServiceTime].id
              });
            }

            if (!valuesServTime.startTime) {
              valuesServTime.startTime = formatTime('08:00');
            }
            if (!valuesServTime.endTime) {
              valuesServTime.endTime = formatTime('18:00');
            }

            setValues(valuesServTime);

            if (valuesServTime?.id) {
              setServiceTimeUpdate(true);
            } else {
              setCreateServiceTime(true);
            }
            // setCurrentServiceTime(-1);
            // saveStepper({ next: 2 });
          }}
          sx={{ width: '304px' }}
          variant="primary"
        >
          {t('GENERAL.nextButton')}
        </Button>
      </Box>
    );
  }

  function ServicesHeader() {
    return (
      <Box alignItems="center" display="flex" justifyContent="space-between">
        <Typography color="primary.300" variant="heading_500">
          {t('LEAD.CREATE.SERVICES.services')}
        </Typography>
        <Button
          disabled={values?.readOnly}
          onClick={() => {
            setConfirmModal(
              () => {
                setAddService(true);
                // setCreateServiceTime(true);
              },
              `${t('GENERAL.addServiceButton')}`,
              () => {},
              `${t('GENERAL.cancelButton')}`,
              <AddServiceModal
                setValue={(value) => {
                  setServiceFromModal(value);
                }}
              />
            );
          }}
          sx={{
            flexShrink: 0,
            minWidth: '187px',
            padding: '12px 16px 12px 16px'
          }}
          variant="primary"
        >
          <Box alignItems="center" display="flex" gap="10px">
            <i className="custom-icon icon-add" />
            <Typography variant="heading_328">
              {t('LEAD.CREATE.SERVICES.addService')}
            </Typography>
          </Box>
        </Button>
      </Box>
    );
  }

  useEffect(() => {
    if (!valuesServTime?.length) {
      setLoadValuesServTime(true);
    } else {
      setLoadValuesServTime(false);
    }

    if (valuesServTime?.startDate && valuesServTime?.endDate) {
      const today = dayjs();
      setValuesServTime({
        ...valuesServTime,
        endDate:
          valuesServTime.endDate && dayjs(valuesServTime.endDate) >= today
            ? valuesServTime.endDate
            : projectEnd,
        startDate:
          valuesServTime.startDate && dayjs(valuesServTime.startDate) >= today
            ? valuesServTime.startDate
            : today
      });
    } else {
      const date = dayjs(values?.startDate || values?.projectStartDate).local();
      // date.setDate(date.getDate() + 1);
      const weekDay = weekDays[date.day()];
      setValuesServTime({
        ...valuesServTime,
        endDate: values.projectEnd || values.projectEnd,
        frequency: values?.fequency || 'Weekly',
        frequencyDays: [weekDay.name],
        startDate: values.projectStartDate || values.startDate
      });
    }
  }, []);

  useEffect(() => {
    if (addService) {
      const auxValuesServices =
        valuesServTime?.services?.length > 0
          ? valuesServTime.services.map((service: any) => {
              let tasks = [];

              if (service?.tasks?.length > 0) {
                tasks = service.tasks.map((task: any) => {
                  let materials = [{}];

                  if (task?.materials?.length > 0) {
                    materials = task.materials.map((materialData: any) => {
                      return {
                        cost: parseFloat(materialData.cost),
                        materialId: materialData.materialId,
                        name: materialData.name
                      };
                    });
                  }

                  return {
                    ...task,
                    materials
                  };
                });
              }

              return {
                ...service,
                tasks
              };
            })
          : [];

      setAddService(false);

      setValuesServTime({
        ...valuesServTime,
        services: valuesServTime.services
          ? [...auxValuesServices, serviceFromModal]
          : [serviceFromModal]
      });
      setServiceFromModal(null);
      addNotification(
        t('NOTIFICATION.TOAST.addedSuccess', [
          t('LEAD.CREATE.SERVICES.SUMMARY.service')
        ]),
        'success'
      );
    }
  }, [addService]);

  return (
    <Box>
      {valuesServTime && (
        <Box display="flex" flexDirection="column" gap="48px">
          {ServiceTimeConfiguration()}
          {ServicesHeader()}
          {valuesServTime.services &&
            valuesServTime.services.length > 0 &&
            valuesServTime?.services.map((service: any, indexKey: number) => {
              return (
                <Box
                  display="flex"
                  flexDirection="column"
                  gap="24px"
                  key={service}
                >
                  <ServiceCollapse
                    editableFields={[
                      'name',
                      'quantity',
                      'totalTime',
                      'showDetail',
                      'edit',
                      'delete'
                    ]}
                    headKeys={[
                      'name',
                      'quantity',
                      'totalTime',
                      'showDetail',
                      'edit',
                      'delete'
                    ]}
                    indexST={indexServiceTime}
                    manualPricing={valuesServTime.manualPricing}
                    openInit={indexKey === focus}
                    screen="services"
                    service={{ ...service, readOnly: values?.readOnly }}
                    setIndexOpen={() => {
                      setFocus(indexKey);
                    }}
                    setService={(serviceNewValue: any) => {
                      // in case we got null we should remove the service index
                      if (!serviceNewValue) {
                        const newService = valuesServTime.services;
                        newService.splice(indexKey, 1);
                        setValuesServTime({
                          ...valuesServTime,
                          services: newService
                        });
                      } else {
                        const { readOnly, ...newValue } = serviceNewValue || {};
                        const newService = valuesServTime.services;
                        newService[indexKey] = newValue;
                        if (!newValue) {
                          newService.splice(indexKey, 1);
                        }
                        setValuesServTime({
                          ...valuesServTime,
                          services: newService
                        });
                      }
                    }}
                    totalServices={valuesServTime.services.length}
                  />
                </Box>
              );
            })}
          {Buttons()}
        </Box>
      )}
    </Box>
  );
};

export default ServiceTime;
