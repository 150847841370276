import React from 'react';
import '../../style/App.scss';
import { Box, Button, Tooltip, Typography } from '@mui/material';

interface DeleteButtonProp {
  children?: React.ReactNode;
  customStyle?: object;
  deleteFn: any;
  disabled?: boolean;
  icon?: string;
  tooltip?: string;
}

const DeleteButton: React.FC<DeleteButtonProp> = function DeleteButton({
  deleteFn,
  icon,
  tooltip,
  disabled,
  customStyle,
  children
}) {
  return (
    <Button
      disabled={disabled}
      onClick={() => deleteFn()}
      sx={{ ...customStyle }}
      variant="cancel-text"
    >
      <Box alignItems="center" display="flex" gap="10px">
        <Tooltip title={tooltip}>
          <i className={`custom-icon icon-${icon}`} />
        </Tooltip>
        <Typography variant="heading_328">{children}</Typography>
      </Box>
    </Button>
  );
};

DeleteButton.defaultProps = {
  children: null,
  customStyle: {},
  disabled: false,
  icon: 'delete',
  tooltip: 'delete'
};

export default DeleteButton;
