import { Box, Button, Divider, Typography, useTheme } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import useSWR from 'swr';
import ManualPricing from './services/ManualPricing';
import ServiceTime from './services/ServiceTime';
import useNavigationStore from '../../../store/zustand/navigation';
import { formatDate, getFrequencyDays } from '../../../utils/general';
import Api from '../../../api';
import { CreateLeadSWR, EditLeadSWR } from './components/LeadSWR';
import Banner from '../../../components/shared/Banner';
import { secondaryColors } from '../../../theme/themeConfig';

interface Props {
  destinationIndex: number;
  handleCancel: () => void;
  saveStepper: ({ next }: { next?: number }) => void;
  sendValues: React.Dispatch<React.SetStateAction<any>>;
  setValues: React.Dispatch<React.SetStateAction<any>>;
  values: any;
}

const Services: React.FC<Props> = function Services({
  saveStepper,
  sendValues,
  setValues,
  values,
  handleCancel,
  destinationIndex
}) {
  const theme = useTheme();
  const [serviceTimeId, setServiceTimeId] = useState<number>(0);
  const [currentServiceTime, setCurrentServiceTime] = useState<number>(-1);
  const [showSummary, setShowSummary] = useState<boolean>(true);
  const [createServiceTime, setCreateServiceTime] = useState<boolean>(false);
  const [serviceTimeUpdate, setServiceTimeUpdate] = useState<boolean>(false);
  const [dataResult, setDataResult] = useState<any>(null);
  const [dataResultEdit, setDataResultEdit] = useState<any>(null);
  const [letDeleteServiceTimeId, setLetDeleteServiceTimeId] =
    useState<number>(-1);
  const { t } = useTranslation();
  const { setConfirmModal } = useNavigationStore((state) => state);
  const { id } = values;
  const [serviceTimeApproved, setServiceTimeApproved] =
    useState<boolean>(false);
  const serviceTimes = values?.destinations[destinationIndex]?.serviceTimes;
  const destination = values?.destinations[destinationIndex];

  useEffect(() => {
    setServiceTimeUpdate(false);
    setCreateServiceTime(false);
    /* if (values.manualPricing || values.projectType === 'Ad-Hoc') {
      setCurrentServiceTime(0);
      setServiceTimeId(serviceTimes[0]?.id);
    } else {
      setCurrentServiceTime(-1);
    } */
    setCurrentServiceTime(-1);
    if (serviceTimes?.length > 0) {
      const cleanedServicesTimes = serviceTimes?.filter(
        (item: any) => item?.id && typeof item?.id === 'number'
      );
      setValues({
        ...values,
        destinations: values.destinations.map(
          (destination: any, index: number) =>
            index === destinationIndex
              ? { ...destination, serviceTimes: cleanedServicesTimes }
              : destination
        )
      });
    }
  }, []);

  useEffect(() => {
    if (dataResult && dataResult.id) {
      setCreateServiceTime(false);
      setServiceTimeUpdate(false);
      setServiceTimeId(dataResult.id);
      setCurrentServiceTime(dataResult.id);

      const newAuxServiceTimes = serviceTimes;

      newAuxServiceTimes[currentServiceTime] = dataResult;

      setValues({
        ...values,
        destinations: values.destinations.map(
          (destination: any, index: number) =>
            index === destinationIndex
              ? { ...destination, serviceTimes: newAuxServiceTimes }
              : destination
        )
      });
      setDataResult(null);
      setCurrentServiceTime(-1);
      // saveStepper({ next: 1 });
    }
  }, [dataResult]);

  useEffect(() => {
    if (dataResultEdit && dataResultEdit?.id) {
      setCreateServiceTime(false);
      setServiceTimeUpdate(false);
      // setServiceTimeId(values.serviceTimes[currentServiceTime].id);
      setCurrentServiceTime(-1);
      const newAuxServiceTimes = serviceTimes;
      newAuxServiceTimes[currentServiceTime] = dataResultEdit;

      setValues({
        ...values,
        destinations: values.destinations.map(
          (destination: any, index: number) =>
            index === destinationIndex
              ? { ...destination, serviceTimes: newAuxServiceTimes }
              : destination
        )
      });

      setDataResultEdit(null);
      /* if (values.manualPricing || values.projectType === 'Ad-Hoc') {
        // setServiceTimeId(values.serviceTimes[0].id);
        saveStepper({ next: 2 });
      } else {
        saveStepper({ next: 1 });
      } */
    }
  }, [dataResultEdit]);

  const { data: deleteResult } = useSWR(
    letDeleteServiceTimeId > -1
      ? { id, serviceTimeId: letDeleteServiceTimeId }
      : null,
    (...args) => Api.LeadServiceTimesApi.deleteLeadServicesTime(...args)
  );

  useEffect(() => {
    if (serviceTimes?.length > 0) {
      const cleanedServicesTimes = serviceTimes?.filter(
        (item: any) => item?.id && typeof item?.id === 'number'
      );
      setValues({
        ...values,
        destinations: values.destinations.map(
          (destination: any, index: number) =>
            index === destinationIndex
              ? { ...destination, serviceTimes: cleanedServicesTimes }
              : destination
        )
      });
    }
    setLetDeleteServiceTimeId(-1);
  }, [deleteResult]);

  function getManualPricing() {
    return (
      <ManualPricing
        destinationIndex={destinationIndex}
        saveStepper={saveStepper}
        setValues={setValues}
        values={values}
      />
    );
  }

  function isServiceStartDateWithinRange(serviceTime: any): boolean {
    const projectStartDate =
      typeof values?.startDate === 'string'
        ? values?.startDate
        : values?.startDate.format('YYYY-MM-DD');
    const projectEndDate =
      typeof values?.endDate === 'string'
        ? values?.endDate
        : values?.endDate.format('YYYY-MM-DD');

    if (!projectStartDate || !projectEndDate) return false;

    return (
      serviceTime.startDate >= projectStartDate &&
      serviceTime.startDate <= projectEndDate
    );
  }

  function isServiceEndDateWithinRange(serviceTime: any): boolean {
    const projectStartDate =
      typeof values?.startDate === 'string'
        ? values?.startDate
        : values?.startDate.format('YYYY-MM-DD');
    const projectEndDate =
      typeof values?.endDate === 'string'
        ? values?.endDate
        : values?.endDate.format('YYYY-MM-DD');

    if (!projectStartDate || !projectEndDate) return false;

    return (
      serviceTime.endDate >= projectStartDate &&
      serviceTime.endDate <= projectEndDate
    );
  }

  const getScreen = () => {
    if (currentServiceTime !== -1) {
      if (
        values.manualPricing &&
        (values.projectType === 'adhoc' || values.projectType === 'Ad-Hoc')
      ) {
        return getManualPricing();
      }
      return (
        <ServiceTime
          destinationIndex={destinationIndex}
          indexServiceTime={currentServiceTime}
          projectEnd={values.projectEndDate}
          projectStart={values.projectStartDate}
          projectType={values.projectType || ''}
          saveStepper={saveStepper}
          serviceTimeId={serviceTimeId}
          setCreateServiceTime={setCreateServiceTime}
          setCurrentServiceTime={setCurrentServiceTime}
          setServiceTimeUpdate={setServiceTimeUpdate}
          setValues={(newValues) => {
            const st = serviceTimes;
            st[currentServiceTime] = newValues;
            setValues({
              ...values,
              destinations: values.destinations.map(
                (destination: any, index: number) =>
                  index === destinationIndex
                    ? { ...destination, serviceTimes: st }
                    : destination
              )
            });
          }}
          values={values}
        />
      );
    }
    return <Box />;
  };

  const getServices = (serviceTime: any) => {
    const { services } = serviceTime;

    if (services && services.length > 0) {
      let auxServices = '';
      services.map((service: any) => {
        if (service?.name && service?.name !== '') {
          if (auxServices === '') {
            auxServices = service.name;
          } else {
            auxServices = `${auxServices}, ${service.name}`;
          }
        }

        return null;
      });

      if (auxServices !== '') {
        return auxServices;
      }
    }

    return t('LEAD.CREATE.SERVICES.SUMMARY.noData');
  };

  const getIndividualTasks = (serviceTime: any) => {
    const { individualTasks } = serviceTime || {};
    if (
      individualTasks &&
      individualTasks?.tasks &&
      individualTasks?.tasks?.length > 0
    ) {
      return `${individualTasks.tasks
        .map((task: any) => {
          return task.name;
        })
        .toString()
        .replaceAll(',', ', ')}`;
    }
    return t('LEAD.CREATE.SERVICES.SUMMARY.noData');
  };

  const deleteServiceTime = (index: number) => {
    setConfirmModal(
      () => {
        setLetDeleteServiceTimeId(serviceTimes[index].id);
        serviceTimes.splice(index, 1);
        setValues({
          ...values,
          destinations: values.destinations.map(
            (destination: any, index: number) =>
              index === destinationIndex
                ? { ...destination, serviceTimes }
                : destination
          )
        });
      },
      `${t('GENERAL.confirmButton')}`,
      () => {},
      `${t('GENERAL.cancelButton')}`,
      <Box display="flex" flexDirection="column" pl={8} pr={8}>
        <Box p={2}>
          <i className="custom-icon icon-info" style={{ fontSize: 48 }} />
        </Box>
        <Typography variant="primary">
          {t('LEAD.CREATE.SERVICES.SUMMARY.confirmDelete')}
        </Typography>
      </Box>
    );
  };

  useEffect(() => {
    let auxServiceTimeApproved = true;
    if (serviceTimes && serviceTimes?.length === 0) {
      auxServiceTimeApproved = false;
    }
    setServiceTimeApproved(auxServiceTimeApproved);
  }, [values]);

  const getStatusColor = (status: string) => {
    return values.status !== 'Converted'
      ? `${theme.palette.success.main}`
      : `${theme.palette.success[300]}`;
  };

  const getSummary = () => {
    return (
      <Box display="flex" flexDirection="column" gap="48px" width="100%">
        <Box display="flex" gap="40px" justifyContent="space-between">
          <Box display="flex" flexDirection="column" gap={2}>
            <Typography color="primary.main" variant="heading_500">
              {`${t('LEAD.CREATE.SERVICES.SUMMARY.title')} - ${
                destination?.address?.name
              }`}
            </Typography>
            <Typography color="secondary.400" variant="body_200">
              {t('LEAD.CREATE.SERVICES.SUMMARY.subtitle')}
            </Typography>
          </Box>

          <Box alignItems="flex-end" display="flex" flexShrink={0}>
            <Button
              disabled={values?.readOnly || values.manualPricing}
              onClick={() => {
                if (serviceTimes?.length > 0 && serviceTimes[0]?.id) {
                  serviceTimes.push({});
                }
                setValues({
                  ...values,
                  destinations: values.destinations.map(
                    (destination: any, index: number) =>
                      index === destinationIndex
                        ? { ...destination, serviceTimes }
                        : destination
                  )
                });
                setServiceTimeId(-1);
                setCurrentServiceTime(
                  serviceTimes?.length > 0 && serviceTimes[0]?.id
                    ? (serviceTimes?.length || 0) - 1
                    : 0
                );
              }}
              sx={{ padding: '12px 16px 12px 16px' }}
              variant="primary"
            >
              <Box alignItems="center" display="flex" gap="10px">
                <i className="custom-icon icon-add" />
                <Typography variant="heading_328">
                  {t('LEAD.CREATE.SERVICES.SUMMARY.addServiceTimeButton')}
                </Typography>
              </Box>
            </Button>
          </Box>
        </Box>
        {serviceTimes?.length > 0 && (
          <Box display="flex" flexDirection="column" gap="24px" width="100%">
            {serviceTimes
              .sort((a: any, b: any) => a.id - b.id)
              .map((serviceTime: any, index: number) => {
                if (serviceTime?.id && typeof serviceTime?.id === 'number') {
                  return (
                    <Box
                      display="flex"
                      flexDirection="column"
                      gap="24px"
                      key={`min-box-${index + 1}`}
                      padding="16px 24px 16px 24px"
                      sx={{
                        border: 1,
                        borderColor: secondaryColors.SECONDARY_1,
                        borderRadius: 1
                      }}
                      width="100%"
                    >
                      <Box
                        alignItems="center"
                        display="flex"
                        gap="16px"
                        justifyContent="space-between"
                      >
                        <Box
                          display="grid"
                          gridTemplateColumns="174px 1fr 1fr"
                          sx={{ gridColumnGap: '16px' }}
                        >
                          <Typography variant="secondary-semi">
                            {`${t(
                              'LEAD.CREATE.SERVICES.SUMMARY.serviceTime'
                            )} #${index + 1}`}
                          </Typography>

                          <Typography color="mainText.300" variant="body_300">
                            {serviceTime.frequency}
                          </Typography>
                        </Box>

                        <Box display="flex" gap="24px">
                          <Button
                            disabled={values?.readOnly}
                            onClick={() => {
                              if (!values?.readOnly) {
                                saveStepper({ next: 2 });
                              }
                            }}
                            variant={
                              serviceTime.scheduled || serviceTime.isScheduled
                                ? 'tertiary-text'
                                : 'warning-text'
                            }
                          >
                            <Box alignItems="center" display="flex" gap="10px">
                              <i className="custom-icon icon-profile" />
                              <Typography variant="heading_328">
                                {serviceTime.scheduled ||
                                serviceTime.isScheduled
                                  ? t('LEAD.CREATE.SERVICES.SUMMARY.scheduled')
                                  : t('LEAD.CREATE.SERVICES.SUMMARY.pending')}
                              </Typography>
                            </Box>
                          </Button>
                          <Button
                            disabled={values?.readOnly}
                            onClick={() => {
                              setCreateServiceTime(false);
                              setCurrentServiceTime(index);
                              setServiceTimeId(serviceTime.id);
                              setServiceTimeUpdate(false);
                            }}
                            sx={{ minWidth: '114px' }}
                            variant="text"
                          >
                            <Box alignItems="center" display="flex" gap="10px">
                              <i className="custom-icon icon-edit" />
                              <Typography variant="heading_328">
                                {t('GENERAL.editButton')}
                              </Typography>
                            </Box>
                          </Button>

                          <Button
                            disabled={values?.readOnly}
                            onClick={() => {
                              deleteServiceTime(index);
                            }}
                            variant="cancel-text"
                          >
                            <Box alignItems="center" display="flex" gap="10px">
                              <i className="custom-icon icon-delete" />
                              <Typography variant="heading_328">
                                {t('GENERAL.deleteButton')}
                              </Typography>
                            </Box>
                          </Button>
                        </Box>
                      </Box>

                      <Divider
                        flexItem
                        sx={{ borderColor: secondaryColors.SECONDARY_1 }}
                      />

                      <Box
                        display="grid"
                        gridTemplateColumns="190px 170px 110px 1fr"
                        sx={{ gridRowGap: '32px' }}
                      >
                        <Typography color="primary.main" variant="heading_300">
                          {values.projectType !== 'Ad-Hoc'
                            ? t('LEAD.CREATE.startDate')
                            : 'Service Day'}
                          :
                        </Typography>

                        <Typography
                          color={
                            isServiceStartDateWithinRange(serviceTime) ||
                            values.projectType === 'Ad-Hoc'
                              ? 'mainText.300'
                              : 'alert.400'
                          }
                          variant="body_300"
                        >
                          {formatDate(serviceTime.startDate)}
                        </Typography>

                        <Typography color="primary.main" variant="heading_300">
                          {t('LEAD.CREATE.endDate')}:
                        </Typography>

                        <Typography
                          color={
                            isServiceEndDateWithinRange(serviceTime) ||
                            values.projectType === 'Ad-Hoc'
                              ? 'mainText.300'
                              : 'alert.400'
                          }
                          variant="body_300"
                        >
                          {formatDate(serviceTime.endDate)}
                        </Typography>

                        <Typography color="primary.main" variant="heading_300">
                          {t('LEAD.CREATE.SERVICES.SelectedDays')}:
                        </Typography>

                        <Box
                          gridColumn="span 3"
                          style={{ fontSize: '16px', lineHeight: '20px' }}
                        >
                          <Typography color="mainText.300" variant="body_300">
                            {values.projectType !== 'Ad-Hoc'
                              ? getFrequencyDays(serviceTime)
                              : formatDate(serviceTime.startDate)}
                          </Typography>
                        </Box>

                        {/* Aditional Tasks */}
                        <Typography color="primary.main" variant="heading_300">
                          {t('LEAD.CREATE.SERVICES.SUMMARY.adhocTasks')}:
                        </Typography>

                        <Box
                          gridColumn="span 3"
                          style={{ fontSize: '16px', lineHeight: '20px' }}
                        >
                          <Typography color="mainText.300" variant="body_300">
                            {getIndividualTasks(serviceTime)}
                          </Typography>
                        </Box>

                        <Typography color="primary.main" variant="heading_300">
                          {t('LEAD.CREATE.SERVICES.SUMMARY.services')}:
                        </Typography>

                        <Typography color="mainText.300" variant="body_300">
                          {getServices(serviceTime)}
                        </Typography>
                      </Box>
                    </Box>
                  );
                }

                return null;
              })}
          </Box>
        )}
        {values?.destinations?.[destinationIndex]?.serviceTimes?.length > 0 && (
          <Box
            alignItems="center"
            display="flex"
            gap="16px"
            justifyContent="flex-end"
          >
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'left',
                width: '100%'
              }}
            >
              {!serviceTimeApproved && (
                <Banner
                  icon
                  text={t('LEAD.CREATE.SERVICES.warningServiceTimes')}
                  textCustomStyle={{ fontSize: '1rem', fontWeight: '400' }}
                  type="warning"
                />
              )}
            </Box>

            <Box display="flex" flexDirection="row" gap="16px">
              <Button
                // disabled={!serviceTimeApproved || values?.readOnly}
                onClick={() => {
                  handleCancel();
                }}
                sx={{ width: 300 }}
                variant="cancel"
              >
                {t('GENERAL.cancelButton')}
              </Button>
              <Button
                disabled={!serviceTimeApproved || values?.readOnly}
                onClick={() => {
                  saveStepper({});
                }}
                sx={{ width: 300 }}
                variant="primary"
              >
                <Typography variant="primary">
                  {t('LEAD.CREATE.SERVICES.saveServiceTime')}
                </Typography>
              </Button>
            </Box>
          </Box>
        )}
      </Box>
    );
  };

  useEffect(() => {
    if (currentServiceTime === -1) {
      setShowSummary(true);
    } else {
      setShowSummary(false);
    }
  }, [currentServiceTime]);

  useEffect(() => {
    if (`${showSummary}` === 'true') {
      if (serviceTimes?.length > 0) {
        const cleanedServicesTimes = serviceTimes?.filter(
          (item: any) => item?.id && typeof item?.id === 'number'
        );
        setValues({
          ...values,
          destinations: values.destinations.map(
            (destination: any, index: number) =>
              index === destinationIndex
                ? { ...destination, serviceTimes: cleanedServicesTimes }
                : destination
          )
        });
      }
    }
  }, [showSummary]);

  return (
    <Box>
      {createServiceTime && (
        <CreateLeadSWR
          createServiceTime={createServiceTime}
          currentServiceTime={currentServiceTime}
          destinationId={destination?.id}
          id={id}
          setDataResult={setDataResult}
          values={serviceTimes}
        />
      )}
      {serviceTimeUpdate && (
        <EditLeadSWR
          currentServiceTime={currentServiceTime}
          destinationId={destination?.id}
          editServiceTime={serviceTimeUpdate}
          leadId={id}
          serviceTimeId={serviceTimeId}
          setDataResultEdit={setDataResultEdit}
          values={serviceTimes}
        />
      )}
      <Box>{showSummary ? getSummary() : <Box>{getScreen()}</Box>}</Box>
    </Box>
  );
};

export default Services;
