/* eslint-disable no-loop-func */
/* eslint-disable import/prefer-default-export */

export const getCalculateLaborTaxes = (taxes: Array<any>) => {
  if (taxes?.length > 0) {
    return taxes
      .filter((tax: any) => tax?.type === 'payroll' || tax?.type === 'other')
      .map((tax: any) => Number(tax.tax))
      .reduce((acc: any, value: any) => acc + value, 0);
  }
  return 0;
};

export const getTaskCosts = (material: any, t: any) => {
  const auxResources =
    // eslint-disable-next-line no-nested-ternary
    material?.resources?.length > 0
      ? material?.resources
      : material?.technicians?.length > 0
      ? material.technicians
      : [];

  const { company } = material || {};
  const { taxes } = company || {};
  let payroll = 0.0;
  let other = 0.0;
  if (taxes && Array.isArray(taxes) && taxes.length > 0) {
    taxes.forEach((tax: any) => {
      if (tax.type === 'payroll') {
        payroll += parseFloat(tax.tax);
      } else if (tax.type === 'other') {
        other += parseFloat(tax.tax);
      }
    });
  }

  const data = {
    data: auxResources.map((resource: any) => {
      const quantity = material.quantity || 0;
      const measurePerTime = parseFloat(material.measurePerHour || 0);
      const totalTime =
        material?.measure === 'Unit'
          ? quantity * measurePerTime
          : quantity / measurePerTime;
      // const totalTime = auxTotalTime;
      const totalTimeNumberNF =
        Number(
          (totalTime * (material?.minimumResources || 1)) /
            (auxResources?.length || 1)
        ) || 0;

      const totalTimeNumber = Number(totalTimeNumberNF.toFixed(2));
      const formattedTime = totalTimeNumber.toFixed(2);
      const newValue: any = {
        hours: formattedTime,
        laborCost: resource.rate * totalTimeNumberNF || 0,
        other: totalTimeNumber * (other / 100) * resource.rate,
        payrollTax: totalTimeNumber * (payroll / 100) * resource.rate,
        rate: resource.rate || 0,
        resource: resource.name
      };
      newValue.total =
        newValue.laborCost + newValue.payrollTax + newValue.other;
      return newValue;
    })
  };
  const summary = {
    hours: 0,
    laborCost: 0,
    other: 0,
    payrollTax: 0,
    rate: '-',
    resource: t('LISTING_PAGES.total'),
    total: 0
  };
  data.data.forEach((item: any) => {
    summary.hours += Number(item.hours);
    summary.laborCost += item.laborCost;
    summary.other += item.other;
    summary.payrollTax += item.payrollTax;
    summary.total += item.total;
  });
  data.data.push(summary);
  return data;
};

export const parserServiceCalculator = (service: any, company: any, t: any) => {
  return {
    ...service,
    company,
    tasks: service.tasks.map((task: any) => {
      return { ...task, taskCosts: getTaskCosts({ ...task, company }, t) };
    })
  };
};

export const parserLeadCalculator = (lead: any, t: any) => {
  return {
    ...lead,
    services: lead.services.map((service: any) =>
      parserServiceCalculator(service, lead.company, t)
    )
  };
};

export const calculateMaterialCostFromTask = (material: any) => {
  const measure = material?.task?.measure?.name
    ? material?.task?.measure?.name
    : material?.task?.measure;
  let toBack = 0;
  if (measure === 'Unit') {
    toBack =
      parseFloat(material.task.quantity || 0) * parseFloat(material.cost || 0);
  } else {
    toBack =
      (material.task.quantity / parseFloat(material.task.measurePerHour || 0) ||
        0) * parseFloat(material.cost || 0);
  }
  return toBack;
};

export function getServiceCost(service: any) {
  let cost = 0.0;
  if (service?.materialsAndSupplies?.length > 0) {
    service.materialsAndSupplies.forEach((material: any) => {
      cost += material?.cost ? parseFloat(material.cost) : 0;
    });
  }
  if (service?.tasks?.length > 0) {
    service.tasks.forEach((task: any) => {
      if (task?.materials?.length > 0) {
        task.materials.forEach((material: any) => {
          cost += calculateMaterialCostFromTask({
            ...material,
            task
          });
        });
      }
      if (task?.taskCosts?.data && task?.taskCosts?.data.length > 0) {
        const data = task?.taskCosts?.data;
        cost += data[data.length - 1].total;
      }
    });
  }
  return cost;
}

export const getTotalMaterialCost = (service: any) => {
  let cost = 0.0;
  service.services.forEach((service: any) => {
    service.tasks.forEach((task: any) => {
      task.materials.forEach((material: any) => {
        cost += calculateMaterialCostFromTask({ ...material, task });
      });
    });
  });
  return cost;
};

export const getTravelCost = (serviceTime: any, travelTime: any) => {
  const laborTaxes = getCalculateLaborTaxes(serviceTime?.company?.taxes);
  if (serviceTime) {
    const { services, individualTasks } = serviceTime;

    let totalCost = 0.0;
    const auxResources: any[] = [];
    if (individualTasks?.tasks?.length > 0) {
      individualTasks.tasks.forEach((task: any) => {
        if (task?.resources?.length > 0) {
          task.resources.forEach((resource: any) => {
            if (!auxResources.includes(resource?.id)) {
              totalCost += parseFloat(resource?.rate || 0);
              auxResources.push(resource?.id);
            }
          });
        }
      });
    }

    if (services?.length > 0) {
      services.forEach((service: any) => {
        if (service?.tasks?.length > 0) {
          service.tasks.forEach((task: any) => {
            if (task?.resources?.length > 0) {
              task.resources.forEach((resource: any) => {
                if (!auxResources.includes(resource?.id)) {
                  totalCost += parseFloat(resource?.rate || 0);
                  auxResources.push(resource?.id);
                }
              });
            }
          });
        }
      });
    }

    const travelCost = totalCost * travelTime;
    const laborTaxesCost = (travelCost * laborTaxes) / 100;
    return travelCost + laborTaxesCost;
  }
  return 0;
};

export function getTotalHoursByInterval(
  hours: number | string,
  intervalType: string,
  startDate: string,
  endDate: string
) {
  const hoursPerInverval = Number(hours);
  const start: any = new Date(startDate);
  const end: any = new Date(endDate);

  if (Number.isNaN(start) || Number.isNaN(end) || start > end) {
    throw new Error('Fechas inválidas');
  }

  const msPerDay = 1000 * 60 * 60 * 24;
  const totalDays =
    startDate === endDate ? 1 : Math.ceil((end - start) / msPerDay);

  const getMonthsDifference = () => {
    return (
      (end.getFullYear() - start.getFullYear()) * 12 +
      (end.getMonth() - start.getMonth()) +
      1
    );
  };

  const periodMapping: any = {
    Annually: 365,
    Biannually: 182,
    Biweekly: 14,
    FullTime: totalDays,
    Monthly: 30,
    Quarterly: 91,
    Weekly: 7
  };

  if (!(intervalType in periodMapping)) {
    throw new Error('Tipo de período inválido');
  }
  const daysPerPeriod = periodMapping[intervalType];
  const fullPeriods = Math.floor(totalDays / daysPerPeriod);
  const remainingDays = totalDays % daysPerPeriod;

  const totalHours =
    fullPeriods * hoursPerInverval +
    (remainingDays / daysPerPeriod) * hoursPerInverval;

  return totalHours;
}

export function getResourceCost(resource: any, time: string | number) {
  const rate = parseFloat(resource?.rate || 0);
  const hours = parseFloat(time ? `${time}` : '0');
  return hours * rate;
}

export function getTimeCost(adhocTasks: any, services: any, type: string) {
  const laborTaxes = getCalculateLaborTaxes(services?.[0]?.company?.taxes);
  let totalCost = 0.0;
  let resources: Array<any> = [];
  let resourcesIds: Array<string> = [];
  if (adhocTasks?.tasks?.length > 0) {
    adhocTasks.tasks.forEach((task: any) => {
      if (task?.resources?.length > 0) {
        task.resources.forEach((resource: any) => {
          resources.push(resource);
          resourcesIds.push(resource.id);
        });
      }
    });
  }
  let uniqueResourcesIds = new Set(resourcesIds);
  let totalRate = 0.0;
  let rate = 0.0;
  while (uniqueResourcesIds.size !== 0) {
    resources.forEach((resource: any) => {
      if (uniqueResourcesIds.has(resource.id)) {
        rate = parseFloat(resource?.rate || 0);
        uniqueResourcesIds.delete(resource.id);
        totalRate += rate;
      }
    });
  }
  totalCost +=
    totalRate *
    (type === 'setup'
      ? adhocTasks?.setupTime || 0
      : adhocTasks?.teardownTime || 0);
  if (services?.length > 0) {
    services.forEach((service: any) => {
      resources = [];
      resourcesIds = [];
      if (service?.tasks?.length > 0) {
        service.tasks.forEach((task: any) => {
          if (task?.resources?.length > 0) {
            task.resources.forEach((resource: any) => {
              resources.push(resource);
              resourcesIds.push(resource.id);
            });
          }
        });
      }
      uniqueResourcesIds = new Set(resourcesIds);
      totalRate = 0.0;
      rate = 0.0;
      while (uniqueResourcesIds.size !== 0) {
        resources.forEach((resource: any) => {
          if (uniqueResourcesIds.has(resource.id)) {
            rate = parseFloat(resource?.rate || 0);
            uniqueResourcesIds.delete(resource.id);
            totalRate += rate;
          }
        });
      }
      totalCost +=
        totalRate *
        (type === 'setup' ? service.setupTime : service.teardownTime);
    });
  }
  const laborTaxesCost = (totalCost * laborTaxes) / 100;
  return totalCost + laborTaxesCost;
}

export const getTotalPerVisit = (
  serviceTime: any,
  travelTime: any,
  profitManual = 1
) => {
  let granTotal = 0.0;
  if (serviceTime.services?.length > 0) {
    serviceTime.services.forEach((service: any) => {
      granTotal += getServiceCost(service);
    });
  }
  if (serviceTime.individualTasks?.tasks?.length > 0) {
    granTotal += getServiceCost(serviceTime.individualTasks);
  }
  granTotal +=
    getTravelCost(serviceTime, travelTime) +
    getTimeCost(
      serviceTime.individualTasks !== undefined
        ? serviceTime.individualTasks
        : 0.0,
      serviceTime.services,
      'setup'
    ) +
    getTimeCost(
      serviceTime.individualTasks !== undefined
        ? serviceTime.individualTasks
        : 0.0,
      serviceTime.services,
      'teardown'
    ) +
    getResourceCost(
      serviceTime.supervisor,
      serviceTime.supervisorTime?.toString()
    );
  return granTotal * profitManual || 0;
};

export const totalValueServiceTime = (
  totalvisit: any,
  serviceTime: any,
  travelTime: any,
  validQuotePrice?: boolean,
  quotePrice?: number,
  profitManual = 1
) => {
  return validQuotePrice
    ? (quotePrice || 0) * totalvisit
    : totalvisit * getTotalPerVisit(serviceTime, travelTime, profitManual);
};

export const totalValueServiceTimeCost = (
  totalvisit: any,
  serviceTime: any,
  travelTime: any
) => {
  return totalvisit * getTotalPerVisit(serviceTime, travelTime);
};

export const getTotalProject = (values: any, t: any) => {
  let total = 0.0;
  const { company } = values;
  // const daysServicetime =
  //   serviceTime.availability?.generalInfo?.daysOfServiceCount;
  // total += totalValueServiceTime(daysServicetime, serviceTime, travelTime);
  if (values?.destinations?.length > 0) {
    values.destinations.forEach((destination: any) => {
      if (destination?.serviceTimes?.length > 0) {
        destination.serviceTimes.forEach((serviceTime: any) => {
          const travelTime: any =
            serviceTime.travelTime !== undefined ? serviceTime.travelTime : 0.0;
          total +=
            getTotalPerVisit(
              parserLeadCalculator({ ...serviceTime, company }, t),
              travelTime
            ) *
            parseInt(
              serviceTime?.availability?.generalInfo?.daysOfServiceCount || 1,
              10
            );
        });
      }
    });
  }
  if (values?.supervisor) {
    total += getResourceCost(
      values?.supervisor,
      getTotalHoursByInterval(
        values?.supervisorTime,
        values?.supervisorFrequency,
        values?.startDate,
        values?.endDate
      )
    );
  }
  // total += getMiscCost(values.Misc);
  // total += getSalesTaxableItems('subtotal', values?.taxableItems);
  return total;
};

export const getTotalProjectByServiceTimes = (values: any) => {
  let total = 0.0;
  const travelTime: any =
    values.travelTime !== undefined ? values.travelTime : 0.0;
  const daysServicetime = values.availability?.generalInfo?.daysOfServiceCount;
  total += totalValueServiceTime(daysServicetime, values, travelTime);
  return total;
};

export const getTotalService = (values: any, t: any, travelTime: any) => {
  return (
    getTotalPerVisit(parserLeadCalculator(values, t), travelTime) *
    parseInt(values?.availability?.generalInfo?.daysOfServiceCount, 10)
  );
};

export const getMargin = (buyPrice: number, sellPrice: number) => {
  if (sellPrice === 0 && buyPrice === 0) {
    return {
      amount: 0,
      percent: 0
    };
  }
  const percent = parseFloat(
    (((sellPrice - buyPrice) / sellPrice) * 100).toFixed(2)
  );
  const amount = parseFloat(
    (buyPrice * (parseFloat(percent.toString()) / 100)).toFixed(2)
  );
  return {
    amount,
    percent
  };
};

export const getMarkup = (buyPrice: number, sellPrice: number) => {
  if (sellPrice === 0 && buyPrice === 0) {
    return {
      amount: 0,
      percent: 0
    };
  }
  const percent = parseFloat(
    (((sellPrice - buyPrice) / buyPrice) * 100).toFixed(2)
  );
  const amount = parseFloat(
    (buyPrice * (parseFloat(percent.toString()) / 100)).toFixed(2)
  );
  return {
    amount,
    percent
  };
};

export const getCalculatedMarkup = (marginValue: string) => {
  const sanitizedValue = Math.min(parseFloat(marginValue), 99.99);
  const marginDecimal = sanitizedValue / 100;
  const calculatedMarkup = (marginDecimal / (1 - marginDecimal)) * 100;

  return {
    profit: calculatedMarkup.toFixed(2)
  };
};

export const getWorkedTimeAverageTime = (taskIteration: any[]) => {
  return (
    taskIteration.reduce(
      (counter: any, iteration: any) => counter + (iteration?.workedTime || 0),
      0
    ) / (taskIteration?.length || 1)
  );
};
