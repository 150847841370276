/* eslint-disable react/jsx-props-no-spreading */

import React, { useEffect, useState } from 'react';
import { Box, Typography, useTheme } from '@mui/material';
import { useTranslation } from 'react-i18next';
import dayjs, { Dayjs } from 'dayjs';
import { DesktopTimePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { secondaryColors } from '../../../../theme/themeConfig';
import DatePickerTextField from './DatePickerTextField';

interface Props {
  destinationIndex: number;
  saveStepper: ({ next }: any) => void;
  serviceIndex: number;
  setStep: React.Dispatch<React.SetStateAction<number>>;
  setValues: React.Dispatch<React.SetStateAction<any>>;
  values: any;
}

const ServiceWindow: React.FC<Props> = function ServiceWindow({
  saveStepper,
  serviceIndex: index,
  setValues,
  setStep,
  values,
  destinationIndex
}) {
  const { t } = useTranslation();
  const theme = useTheme();
  const [servicesForm, setServicesForm] = useState<any>({});

  const [startTime, setTimeStart] = React.useState<Dayjs | null>(
    dayjs().hour(8).minute(0)
  );

  const [endTime, setTimeEnd] = React.useState<Dayjs | null>(
    dayjs().hour(18).minute(0)
  );

  const services =
    index >= 0 && values?.destinations[destinationIndex]?.serviceTimes?.[index]
      ? values?.destinations[destinationIndex]?.serviceTimes?.[index]?.services
      : values?.destinations[destinationIndex]?.serviceTimes?.[0]?.services;

  const formatTime = (time: Dayjs | null) => {
    const timeUTC = time;
    if (
      timeUTC?.hour().toString().length === 1 &&
      timeUTC?.minute().toString().length === 1
    ) {
      return `0${timeUTC?.hour()}:0${timeUTC?.minute()}`;
    }
    if (timeUTC?.hour().toString().length === 1) {
      return `0${timeUTC?.hour()}:${timeUTC?.minute()}`;
    }
    if (timeUTC?.minute().toString().length === 1) {
      return `${timeUTC?.hour()}:0${timeUTC?.minute()}`;
    }
    return `${timeUTC?.hour()}:${timeUTC?.minute()}`;
  };

  const getServiceTimeWarning = () => {
    const startHour = startTime?.hour() ?? 0;
    const endHour = endTime?.hour() ?? 0;
    if (endHour < startHour) {
      return (
        <Typography color="warning.main" variant="primary">
          {t('LEAD.CREATE.SERVICES.nextDayWarning')}
        </Typography>
      );
    }
    return null;
  };

  useEffect(() => {
    if (
      typeof values.destinations[destinationIndex]?.serviceTimes[index]
        .startTime === 'string'
    ) {
      const startTime = dayjs()
        .hour(
          parseFloat(
            values.destinations[destinationIndex]?.serviceTimes[
              index
            ]?.startTime?.split(':')[0]
          )
        )
        .minute(
          parseFloat(
            values.destinations[destinationIndex]?.serviceTimes[
              index
            ]?.startTime?.split(':')[1]
          )
        );
      const endTime = dayjs()
        .hour(
          parseFloat(
            values.destinations[destinationIndex]?.serviceTimes[
              index
            ]?.endTime?.split(':')[0]
          )
        )
        .minute(
          parseFloat(
            values.destinations[destinationIndex]?.serviceTimes[
              index
            ]?.endTime?.split(':')[1]
          )
        );
      setTimeStart(startTime);
      setTimeEnd(endTime);
    }
  }, []);

  return (
    <Box
      alignItems="center"
      display="flex"
      gap="40px"
      justifyContent="space-between"
    >
      <Box display="flex" flexDirection="column" gap="8px">
        <Typography
          color={secondaryColors.SECONDARY_5}
          variant="secondary-semi"
        >
          {t('LEAD.CREATE.SERVICES.SCHEDULE.serviceWindowTitle')}
        </Typography>
        <Typography color={secondaryColors.SECONDARY_4} variant="body_300">
          {t('LEAD.CREATE.SERVICES.SCHEDULE.serviceWindowDescription')}
        </Typography>
      </Box>

      {getServiceTimeWarning()}

      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <Box alignItems="center" display="flex" gap="40px">
          <Box display="flex" flexDirection="column" gap="4px">
            <Typography color="primary.main" variant="body_300">
              {values.projectType === 'Regular'
                ? t('LEAD.CREATE.SERVICES.SCHEDULE.starting')
                : t('LEAD.CREATE.SERVICES.SCHEDULE.arriveMsg1')}
            </Typography>
            <DesktopTimePicker
              disabled={values?.readOnly}
              onChange={(newValue) => {
                setTimeStart(newValue);
                const newService = services;
                const newSrt =
                  values?.destinations[destinationIndex]?.serviceTimes;
                if (newSrt) {
                  newSrt[index] = {
                    ...newSrt[index],
                    startTime: formatTime(newValue)
                  };
                  setValues({
                    ...values,
                    destinations: values.destinations.map(
                      (destination: any, index: number) =>
                        index === destinationIndex
                          ? { ...destination, serviceTimes: newSrt }
                          : destination
                    )
                  });
                }
                if (newService && newService[index]) {
                  newService[index].startTime = formatTime(newValue);
                }
                setServicesForm({ ...servicesForm, services: newService });
              }}
              renderInput={(params) => <DatePickerTextField {...params} />}
              value={startTime}
            />
          </Box>

          <Box display="flex" flexDirection="column" gap="4px">
            <Typography color="primary.main" variant="body_300">
              {values.projectType === 'Regular'
                ? t('LEAD.CREATE.SERVICES.SCHEDULE.finishing')
                : t('LEAD.CREATE.SERVICES.SCHEDULE.arriveMsg2')}
            </Typography>
            <DesktopTimePicker
              disabled={values?.readOnly}
              onChange={(newValue) => {
                setTimeEnd(newValue);
                const newService = services;
                const newSrt =
                  values?.destinations[destinationIndex]?.serviceTimes;
                if (newSrt) {
                  newSrt[index] = {
                    ...newSrt[index],
                    endTime: formatTime(newValue)
                  };
                  setValues({
                    ...values,
                    destinations: values.destinations.map(
                      (destination: any, index: number) =>
                        index === destinationIndex
                          ? { ...destination, serviceTimes: newSrt }
                          : destination
                    )
                  });
                }
                if (newService && newService[index]) {
                  newService[index].endTime = formatTime(newValue);
                }
                setServicesForm({ ...servicesForm, services: newService });
              }}
              renderInput={(params) => <DatePickerTextField {...params} />}
              value={endTime}
            />
          </Box>
        </Box>
      </LocalizationProvider>
    </Box>
  );
};

export default ServiceWindow;
