/* eslint-disable react/no-array-index-key */
import { useState, useEffect } from 'react';
import { Box, Grid, Skeleton, Snackbar } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { isMobile } from 'react-device-detect';
import useSWR from 'swr';

import api from '../../api';
import ImageGrid from '../ImageGrid';
import { ClientImages } from '../../utils/types/ClientOnboardingImages';
import { WhiteBoxContainer } from '../form/parts';

function LoadigSkeleton() {
  return (
    <Box>
      <Box alignItems="center" display="flex" justifyContent="space-between">
        <Skeleton variant="text" width="100px" />
        <Skeleton variant="text" width="100px" />
      </Box>
      <Grid container mt={2} spacing={2}>
        {[...Array(4)].map((_, index) => (
          <Grid item key={index} md={3} sm={6} xs={12}>
            <Skeleton height={180} variant="rectangular" width="100%" />
            <Skeleton sx={{ mt: 1 }} variant="text" width="100%" />
          </Grid>
        ))}
      </Grid>
    </Box>
  );
}

function ClientOnboarding() {
  const { t } = useTranslation();

  const [images, setImages] = useState<ClientImages[] | undefined>([]);

  const [deleteId, setDeleteId] = useState<string>('');
  const [file, setFile] = useState<FileList | null>(null);

  const [snackMsgFlag, setSnackMsgFlag] = useState<boolean>(false);
  const [openSnackFile, setOpenSnackFile] = useState<boolean>(false);
  const [getCurrentImages, setGetCurrentImages] = useState<boolean>(true);

  const {
    data: imagesData,
    error: imagesError,
    mutate
  } = useSWR(
    getCurrentImages ? 'client/onboarding/images' : null,
    api.StorageApi.getClientOnboardingImages
  );

  const {
    data: uploadResponse,
    error: errorUpload,
    isValidating: isUploadingPhoto
  } = useSWR(file ? { files: file[0] } : null, async (params) => {
    await api.StorageApi.uploadCompanyClientOnboardingImages(params);
    await mutate(undefined, true);
    setFile(null);
    setOpenSnackFile(true);
    setSnackMsgFlag(true);
    setGetCurrentImages(true);
  });

  const {
    data: deleteResponse,
    error: errorDelete,
    isValidating: isDeletingFile
  } = useSWR(
    deleteId
      ? { fileId: deleteId, metaDescription: 'clientOnboardingImages' }
      : null,
    async (params) => {
      await api.StorageApi.deleteFile(params);
      await mutate(undefined, true);
      setDeleteId('');
      setOpenSnackFile(true);
      setSnackMsgFlag(false);
      setGetCurrentImages(true);
    }
  );

  useEffect(() => {
    if (imagesData) {
      setGetCurrentImages(false);
      const imagesArray: ClientImages[] = imagesData;
      setImages(imagesArray);
    }
  }, [imagesData]);

  useEffect(() => {
    if (imagesError) {
      setImages([]);
      setGetCurrentImages(true);
    }
  }, [imagesError]);

  const handleDeleteImage = async (id: string) => {
    setDeleteId(id);
  };

  return (
    <WhiteBoxContainer isMobile={isMobile}>
      <Grid item justifyContent="center" xs={12}>
        {!images ? (
          <LoadigSkeleton />
        ) : (
          <ImageGrid
            deleteId={deleteId}
            images={images ?? []}
            isDeletingFile={isDeletingFile}
            isUploadingPhoto={isUploadingPhoto}
            onDelete={handleDeleteImage}
            setFile={setFile}
          />
        )}
      </Grid>
      <Snackbar
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
        autoHideDuration={3000}
        key="client-onboarding-images"
        message={
          snackMsgFlag
            ? t('COMPANY_SETTINGS.fileUploaded')
            : t('COMPANY_SETTINGS.fileDeleted')
        }
        onClose={() => setOpenSnackFile(false)}
        open={openSnackFile}
      />
    </WhiteBoxContainer>
  );
}

export default ClientOnboarding;
